import { useState, useEffect, useRef } from 'react'

import * as s from './styles'
import { useNonInitialEffect } from 'hooks/utils'
import { InputToggle, SelectToggle } from './components'
import { isNotEmpty } from 'helpers/validators'
import { useGetPixWS, usePatchPixWS } from 'hooks/pixWS'
import { showToast } from 'components/Toast'
import { PixKeyInput } from './components/PixKeyInput'

interface IProps {
  isOpen: boolean
  handleClose: () => any
  editFormInfo: { id: string; pspProvider: string }
  certList: { id: string; text: string }[]
}

export const EditWSFormTBanks: React.FC<IProps> = ({
  isOpen,
  handleClose,
  editFormInfo,
  certList,
}) => {
  const formDefault = {
    name: { value: '', validator: isNotEmpty, touched: false },
    dict_key: { value: '', validator: isNotEmpty, touched: false },
    tls_certificate: { value: '', validator: null, touched: false },
  }
  const [form, setForm] = useState(formDefault)
  const [formErrors, setFormErrors] = useState<any>(
    Object.keys(formDefault).reduce((acc, cur) => ({ ...acc, [cur]: '' }), {})
  )
  const [onEdit, setOnEdit] = useState(false)
  const { pixWS, loadPixWS } = useGetPixWS()
  const { patchResponse, patchError, editPixWS } = usePatchPixWS()
  const [certSelectList, setCertSelectList] = useState([])
  const [selectedCert, setSelectedCert] = useState({ id: '', name: '' })
  const selectedTypeId = useRef<number>(4)

  useEffect(() => {
    if (certList.length > 0) {
      const _list = certList.map((x) => {
        return {
          ...x,
          onClick: () => {
            setSelectedCert({ id: x.id, name: x.text })
          },
        }
      })

      setCertSelectList(_list)
    }
  }, [certList])

  useEffect(() => {
    _setForm({ tls_certificate: selectedCert.id })
  }, [selectedCert])

  useNonInitialEffect(() => {
    if (patchResponse.code !== -1) {
      showToast({
        type: 'success',
        message: 'Carteira editada com sucesso',
      })
      _handleClose()
    }
  }, [patchResponse])

  useNonInitialEffect(() => {
    if (patchError) {
      showToast({
        type: 'error',
        message: 'Problema ao editar carteira',
      })
    }
  }, [patchError])

  useNonInitialEffect(() => {
    _validateForm()
    setOnEdit(Object.values(form).some((x) => x.touched))
  }, [form])

  useEffect(() => {
    if (editFormInfo && editFormInfo.id) loadPixWS(editFormInfo.id)
  }, [editFormInfo])

  useNonInitialEffect(() => {
    const pixWSEditForm = Object.entries(pixWS).reduce((acc, cur) => {
      const [key, value] = cur
      if (formDefault[key])
        return {
          ...acc,
          [key]: value,
        }
      return acc
    }, {})
    if (pixWS.tls_certificate) {
      const c = certSelectList.find((x) => x.id === pixWS.tls_certificate)
      if (c) setSelectedCert({ id: c.id, name: c.text })
    }
    _setForm(pixWSEditForm)
  }, [pixWS])

  const _setForm = (obj) => {
    const formObj = Object.entries(obj).reduce((acc, cur) => {
      const [key, value] = cur
      return {
        ...acc,
        [key]: {
          value: value,
          validator: form[key].validator,
          touched: form[key].touched,
        },
      }
    }, {})
    setForm({ ...form, ...formObj })
  }

  const _setFormTouched = (field, isTouched) => {
    setForm({
      ...form,
      [field]: {
        value: form[field].value,
        validator: form[field].validator,
        touched: isTouched,
      },
    })
  }

  const _validateForm = () => {
    const invalidFields = Object.entries(form).reduce((acc, cur) => {
      const [key, value] = cur
      if (value.touched && value.validator && !value.validator(value.value)) {
        return {
          ...acc,
          [key]: 'Campo obrigatório',
        }
      }
      return {
        ...acc,
        [key]: '',
      }
    }, {})

    if (invalidFields && Object.values(invalidFields).every((x) => !x))
      return true
    return false
  }

  const type = selectedTypeId.current
  const _submitForm = () => {
    if (_validateForm()) {
      const payload = Object.entries(form).reduce((acc, cur) => {
        const [key, value] = cur
        if (value.touched) {
          if (key === 'dict_key') {
            if (type === 2 || type === 3) {
              form[key].value =
                type === 3
                  ? '+55' + form.dict_key.value.replace(/[^a-zA-Z0-9]/g, '')
                  : form.dict_key.value.replace(/[^a-zA-Z0-9]/g, '')
            }
          }
          return {
            ...acc,
            [key]: value.value,
          }
        }
        return acc
      }, {})
      editPixWS(editFormInfo.id, payload)
    }
  }

  const _handleClose = () => {
    setForm(formDefault)
    setFormErrors(
      Object.keys(formDefault).reduce((acc, cur) => ({ ...acc, [cur]: '' }), {})
    )
    handleClose()
  }

  return (
    <s.Modal
      handleClose={() => _handleClose()}
      isOpen={isOpen}
      data-testid="edit-form-tbanks"
      modalSubtitle="Editar"
    >
      <s.ModalContent>
        <s.InputGroup>
          <s.Text bold color="maincolor" type="headline">
            ID
          </s.Text>
          <s.Text>{editFormInfo.id}</s.Text>
        </s.InputGroup>
        <s.InputGroup>
          <s.InputText
            label="Provedor PSP"
            disabled={true}
            value={editFormInfo.pspProvider}
          />
        </s.InputGroup>
        <s.InputGroup>
          <InputToggle
            label="Conta(E-mail ou Id da conta da carteira)"
            value={form.name.value}
            onChange={(e) => _setForm({ name: e.target.value })}
            error={formErrors.name}
            disabled={!form.name.touched}
            onDisabledChange={(disabled) => {
              if (!disabled) _setFormTouched('name', true)
              else _setFormTouched('name', false)
            }}
          />
        </s.InputGroup>
        {!form.dict_key.touched ? (
          <InputToggle
            label="Chave Pix"
            value={form.dict_key.value}
            onChange={(value) => _setForm({ dict_key: value })}
            error={formErrors.name}
            disabled={!form.dict_key.touched}
            onDisabledChange={(disabled) => {
              if (!disabled) _setFormTouched('dict_key', true)
              else _setFormTouched('dict_key', false)
            }}
          />
        ) : (
          <PixKeyInput
            value={form.dict_key.value}
            pixKeyErr={formErrors.dict_key}
            onPixKeyErr={(err) => {
              setFormErrors({ ...formErrors, dict_key: err })
            }}
            disabled={!form.dict_key.touched}
            onDisabledChange={(disabled) => {
              if (!disabled) _setFormTouched('dict_key', true)
              else _setFormTouched('dict_key', false)
            }}
            selectedTypeId={selectedTypeId}
            onChange={(value) => _setForm({ dict_key: value })}
          />
        )}
        <s.InputGroup>
          <SelectToggle
            label="Certificado TLS"
            options={certSelectList}
            selected={selectedCert}
            disabled={!form.tls_certificate.touched}
            onDisabledChange={(disabled) => {
              if (!disabled) _setFormTouched('tls_certificate', true)
              else _setFormTouched('tls_certificate', false)
            }}
          />
        </s.InputGroup>
        <s.ButtonCenter>
          <s.Button
            color="maincolor"
            onClick={() => _submitForm()}
            disabled={!onEdit}
          >
            <s.Text bold color="whiteshipay">
              Editar
            </s.Text>
          </s.Button>
        </s.ButtonCenter>
      </s.ModalContent>
    </s.Modal>
  )
}
