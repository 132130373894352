export const Types = {
  OPEN_CONNECTION: 'OPEN_CONNECTION',
  CLOSE_CONNECTION: 'CLOSE_CONNECTION',
}

export interface ActionInterface {
  type: keyof typeof Types
  payload: { channel: string; eventSource: any }
}

export interface SSENotificationState {
  opennedConnections: Set<any>
}
