import { ActionInterface, Types, SSENotificationState } from './types'

const INITIAL_STATE: SSENotificationState = {
  opennedConnections: new Set(),
}

const SSENotificationReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): SSENotificationState => {
  switch (action.type) {
    case Types.OPEN_CONNECTION:
      return {
        opennedConnections: new Set<any>([
          ...Array.from(state.opennedConnections),
          { [action.payload.channel]: action.payload.eventSource },
        ]),
      }
    case Types.CLOSE_CONNECTION:
      return {
        opennedConnections: new Set<any>(
          Array.from(state.opennedConnections).filter(
            (x) => x !== action.payload
          )
        ),
      }
    default:
      return state
  }
}

export default SSENotificationReducer
