import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'store/modules/rootState'

import {
  GetPixWS,
  GetPixWSIsLoading,
  GetPixWSError,
} from 'store/modules/pixWalletSettings/action'

import { PixWSState } from 'store/modules/pixWalletSettings/types'

import pixWSService from 'services/pixWalletSettings'

import { Partial } from 'helpers/partial'

interface UseGetPixWS extends PixWSState {
  loadPixWS: (id: string) => Promise<void>,
}

export const useGetPixWS = (): Partial<UseGetPixWS> => {
  const dispatch = useDispatch()
  const { pixWS, getIsLoading, getError } = useTypedSelector((store) => ({
    pixWS: store.pixWS.pixWS,
    getIsLoading: store.pixWS.getIsLoading,
    getError: store.pixWS.getError,
  }))

  const loadPixWS = useCallback(async (id): Promise<void> => {
    dispatch(GetPixWSIsLoading())
    await pixWSService.getPixWS(id)
      .then((response) => {
        dispatch(GetPixWS(response.data))
      })
      .catch(() => {
        dispatch(GetPixWSError())
      })
  }, [dispatch])

  return { pixWS, getIsLoading, getError, loadPixWS}
}
