import {
  INFO_INITIAL_STATE,
  STEP_INITIAL_STATE,
} from './WalletSettingsFormTbanksCashout'
import * as s from './styles'

interface Props {
  setStep: (step) => void
  setInfo: (info) => void
}
const Error: React.FC<Props> = ({ setInfo, setStep }) => {
  return (
    <s.Content width={304} height={330}>
      <s.Text margin="0 0 16px 0" color="graytheme6" bold type="heading4">
        Não foi possível cadastrar sua Conta Pix
      </s.Text>
      <s.Text margin="0 0 16px 0" color="graytheme6" type="headline">
        Por favor, verifique os dados da sua Conta Pix e tente fazer o cadastro
        novamente.
      </s.Text>
      <s.Text margin="0 0 36px 0" color="graytheme6" type="headline">
        Caso continue encontrando problemas com sua Conta Pix entre em contato
        com nosso suporte.
      </s.Text>
      <s.ButtonWrapper>
        <div></div>
        <s.Button
          data-testid="back-to-on-register-step"
          height="42px"
          onClick={() => {
            setStep({ ...STEP_INITIAL_STATE })
            setInfo({ ...INFO_INITIAL_STATE })
          }}
          disabled={false}
          color="maincolor"
        >
          <s.Text color="white">Voltar</s.Text>
        </s.Button>
      </s.ButtonWrapper>
    </s.Content>
  )
}

export default Error
