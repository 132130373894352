import React from 'react'
import { applyCpfCnpjMask, applyPhoneMask, applyDateMask } from 'helpers/masks'
import * as s from '../styles'

const LegalRepresentantativeForm: React.FC<{
  pixFormState?: [any, React.Dispatch<React.SetStateAction<any>>]
  errorFormState?: [any, React.Dispatch<React.SetStateAction<any>>]
}> = ({ pixFormState, errorFormState }) => {
  const [form, setForm] = pixFormState
  const [FormErrors, setFormErrors] = errorFormState
  return (
    <s.LegalRepresentativeFormWrapper>
      <s.AnyBankSubHeaderText>
        <s.Icon name="customer" fill="graytheme6" />
        <s.Text bold type="headline" color="graytheme6">
          Dados do representante legal
        </s.Text>
      </s.AnyBankSubHeaderText>
      <s.Text margin="24px 0 24px 0" type="headline" color="graytheme6">
        Esta pessoa deve ter poderes para realizar a abertura da conta bancária
        em nome da empresa.
      </s.Text>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={
            FormErrors.legal_representative.name ? 'redshipay' : 'graytheme6'
          }
        >
          Nome completo
        </s.Text>
        <s.InputText
          testId="name-test"
          width={260}
          value={form.legal_representative.name}
          onChange={(e) => {
            setForm({
              ...form,
              legal_representative: {
                ...form.legal_representative,
                name: e.target.value,
              },
            })
            if (FormErrors.legal_representative.name) {
              setFormErrors({
                ...FormErrors,
                legal_representative: {
                  ...FormErrors.legal_representative,
                  name: '',
                },
              })
            }
          }}
          error={
            FormErrors.legal_representative.name
              ? {
                  message: FormErrors.legal_representative.name,
                }
              : false
          }
          suffix={
            FormErrors.legal_representative.name ? (
              <s.Icon name="alertcirclefilled" fill="lightred2" />
            ) : (
              <></>
            )
          }
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={
            FormErrors.legal_representative.document_number
              ? 'redshipay'
              : 'graytheme6'
          }
        >
          CPF
        </s.Text>
        <s.InputText
          testId="CPF-test"
          width={260}
          maxLength={14}
          value={form.legal_representative.document_number}
          onChange={(e) => {
            setForm({
              ...form,
              legal_representative: {
                ...form.legal_representative,
                document_number: applyCpfCnpjMask(e.target.value),
              },
            })
            if (FormErrors.legal_representative.document_number) {
              setFormErrors({
                ...FormErrors,
                legal_representative: {
                  ...FormErrors.legal_representative,
                  document_number: '',
                },
              })
            }
          }}
          error={
            FormErrors.legal_representative.document_number
              ? {
                  message: FormErrors.legal_representative.document_number,
                }
              : false
          }
          suffix={
            FormErrors.legal_representative.document_number ? (
              <s.Icon name="alertcirclefilled" fill="lightred2" />
            ) : (
              <></>
            )
          }
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={
            FormErrors.legal_representative.birth_date
              ? 'redshipay'
              : 'graytheme6'
          }
        >
          Data de nascimento
        </s.Text>
        <s.InputText
          testId="birth-date-test"
          width={260}
          maxLength={10}
          value={
            form.legal_representative.birth_date.includes('/')
              ? form.legal_representative.birth_date
              : form.legal_representative.birth_date
                  .replaceAll('-', '/')
                  .split('/')
                  .reverse()
                  .join('/')
          }
          onChange={(e) => {
            setForm({
              ...form,
              legal_representative: {
                ...form.legal_representative,
                birth_date: applyDateMask(e.target.value),
              },
            })
            if (FormErrors.legal_representative.birth_date) {
              setFormErrors({
                ...FormErrors,
                legal_representative: {
                  ...FormErrors.legal_representative,
                  birth_date: '',
                },
              })
            }
          }}
          error={
            FormErrors.legal_representative.birth_date
              ? {
                  message: FormErrors.legal_representative.birth_date,
                }
              : false
          }
          suffix={
            FormErrors.legal_representative.birth_date ? (
              <s.Icon name="alertcirclefilled" fill="lightred2" />
            ) : (
              <></>
            )
          }
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={
            FormErrors.legal_representative.mother_name
              ? 'redshipay'
              : 'graytheme6'
          }
        >
          Nome da mãe
        </s.Text>
        <s.InputText
          testId="mother-name-test"
          width={260}
          value={form.legal_representative.mother_name}
          onChange={(e) => {
            setForm({
              ...form,
              legal_representative: {
                ...form.legal_representative,
                mother_name: e.target.value,
              },
            })
            if (FormErrors.legal_representative.mother_name) {
              setFormErrors({
                ...FormErrors,
                legal_representative: {
                  ...FormErrors.legal_representative,
                  mother_name: '',
                },
              })
            }
          }}
          error={
            FormErrors.legal_representative.mother_name
              ? { message: FormErrors.legal_representative.mother_name }
              : false
          }
          suffix={
            FormErrors.legal_representative.mother_name ? (
              <s.Icon name="alertcirclefilled" fill="lightred2" />
            ) : (
              <></>
            )
          }
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={
            FormErrors.legal_representative.mother_name
              ? 'redshipay'
              : 'graytheme6'
          }
        >
          Telefone
        </s.Text>
        <s.InputText
          testId="phone-test"
          width={260}
          value={form.legal_representative.phone}
          placeholder="(99) 99999-9999"
          onChange={(e) => {
            setForm({
              ...form,
              legal_representative: {
                ...form.legal_representative,
                phone: applyPhoneMask(e.target.value),
              },
            })
            if (FormErrors.legal_representative.phone) {
              setFormErrors({
                ...FormErrors,
                legal_representative: {
                  ...FormErrors.legal_representative,
                  phone: '',
                },
              })
            }
          }}
          error={
            FormErrors.legal_representative.phone
              ? { message: FormErrors.legal_representative.phone }
              : false
          }
          suffix={
            FormErrors.legal_representative.phone ? (
              <s.Icon name="alertcirclefilled" fill="lightred2" />
            ) : (
              <></>
            )
          }
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={
            FormErrors.legal_representative.email ? 'redshipay' : 'graytheme6'
          }
        >
          E-mail
        </s.Text>
        <s.InputText
          testId="email-test"
          width={260}
          value={form.legal_representative.email}
          onChange={(e) => {
            setForm({
              ...form,
              legal_representative: {
                ...form.legal_representative,
                email: e.target.value,
              },
            })
            if (FormErrors.legal_representative.email) {
              setFormErrors({
                ...FormErrors,
                legal_representative: {
                  ...FormErrors.legal_representative,
                  email: '',
                },
              })
            }
          }}
          error={
            FormErrors.legal_representative.email
              ? { message: FormErrors.legal_representative.email }
              : false
          }
          suffix={
            FormErrors.legal_representative.email ? (
              <s.Icon name="alertcirclefilled" fill="lightred2" />
            ) : (
              <></>
            )
          }
        />
      </s.SectionLine>
    </s.LegalRepresentativeFormWrapper>
  )
}

export default LegalRepresentantativeForm
