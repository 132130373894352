import {
  ActionInterface,
  SummaryDetailActionInterface,
  Types,
  SummaryState,
  PayloadInterface,
  SummaryDetailPayloadInferface,
} from './types'

const INITIAL_STATE: SummaryState = {
  summary: { count: 0, data: [], offset: '0', total: -1 },
  isLoading: false,
  hasError: false,
  summaryDetail: {
    balance: null,
    created_at: '',
    expiration_date: '',
    external_id: '',
    items: [],
    order_id: '',
    paid_amount: null,
    payment_date: '',
    pix_psp: '',
    status: '',
    total_order: null,
    transactions: [],
    updated_at: '',
    wallet: '',
    wallet_payment_id: null,
  },
  summaryDetailIsLoading: false,
  summaryDetailHasError: false,
}

const summaryReducer = (
  state = INITIAL_STATE,
  action: ActionInterface | SummaryDetailActionInterface
): SummaryState => {
  switch (action.type) {
    case Types.GET_SUMMARY:
      return {
        ...state,
        summary: action.payload as PayloadInterface,
        isLoading: false,
        hasError: false,
      }
    case Types.GET_SUMMARY_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_SUMMARY_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    case Types.GET_SUMMARY_DETAIL:
      return {
        ...state,
        summaryDetail: { ...action.payload } as SummaryDetailPayloadInferface,
        summaryDetailIsLoading: false,
        summaryDetailHasError: false,
      }
    case Types.GET_SUMMARY_DETAIL_LOADING:
      return {
        ...state,
        summaryDetailIsLoading: true,
        summaryDetailHasError: false,
      }
    case Types.GET_SUMMARY_DETAIL_ERROR:
      return {
        ...state,
        summaryDetailIsLoading: false,
        summaryDetailHasError: true,
      }
    default:
      return state
  }
}

export default summaryReducer
