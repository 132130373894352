import { useState } from 'react'

import * as s from './styles'
import { useNonInitialEffect } from 'hooks/utils'

interface IProps {
  label: string
  value?: string
  onChange?: (e) => void
  onDisabledChange?: (disabled) => void
  disabled?: boolean
  error?: string
}

export const InputToggle: React.FC<IProps> = ({ label, value, onChange, onDisabledChange, disabled, error }) => {
  const [ originalValue, setOriginalValue ] = useState('')

  useNonInitialEffect(() => {
    if(!disabled) {
      setOriginalValue(value)
      onChange({target: {value: ''}})
    }
    else {
      onChange({target: {value: originalValue}})
      setOriginalValue('')
    }
  }, [disabled])

  return (
    <s.InputToggleGroup>
      <s.InputText
        maxLength={200}
        value={value}
        disabled={disabled}
        onChange={onChange}
        label={label}
        error={
          (error) ? { message: error} : false
        } />
      <s.Icon 
        name="pencil" 
        fill="maincolor"
        onClick={() => onDisabledChange(!disabled) } />
    </s.InputToggleGroup>
  )
}
