import React from 'react'

import { reconciliationFormatMoney } from 'helpers/masks'
import { solveElement } from 'helpers/reconciliation'
import { baseURLReconciliation, get } from 'services/api'
import { v4 as uuid } from 'uuid'

import * as s from './styles'

interface IProps {
  isOpen: boolean
  handleClose: () => any
  totals: any[]
  options: {
    start_date: string
    end_date: string
    wallet_setting_id: string
    consolidated_customer_value: string
  }
}

interface response {
  total: number
  data: any
  totalValue: string
}

const statusColor: any = {
  success: 'maincolor',
  warning: 'orange',
  error: 'redshipay',
}

const RenderValue = ({ children }) => {
  return <s.Text>{reconciliationFormatMoney(children)}</s.Text>
}

const ReconciliationDetailsModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  options,
  totals,
}) => {
  const [receiptDetailsData, setReceiptDetailsData] = React.useState<response>()
  const [offset, setOffset] = React.useState(0)

  const getReceiptDetails = async (offset: number, limit: number) => {
    const params = new URLSearchParams()
    params.append(
      'consolidated_customer_value',
      options.consolidated_customer_value
    )
    params.append('end_date', options.end_date)
    params.append('start_date', options.start_date)
    params.append('limit', limit + '')
    params.append('offset', offset + '')
    params.append('wallet_setting_id', options?.wallet_setting_id)
    await get(
      `${baseURLReconciliation}/receipt/details?origin=PSP&origin=Shipay`,
      {
        params: params,
      }
    ).then((res) => {
      setReceiptDetailsData(res.data)
    })
  }

  const getReceiptDetailsRecursive = async function (offset) {
    await getReceiptDetails(offset, 500)
    let data = receiptDetailsData.data
    data.forEach((element) => {
      element.solved = element.solved + ''
    })
    data = data.map(({ id, ...keepAttrs }) => keepAttrs)
    if (offset / 500 < Math.ceil(receiptDetailsData.total / 500) - 1) {
      return data.concat(await getReceiptDetailsRecursive(offset + 500))
    } else {
      return data
    }
  }

  const getColor = (value: number) => {
    if (value != 0) return statusColor.warning

    return statusColor.success
  }

  const formatMoneyWithNegativeValue = (value: string) => {
    if (!value) {
      return
    }
    if (parseFloat(value) < 0) {
      return `- ${reconciliationFormatMoney(value.replace('-', ''))}`
    }
    return reconciliationFormatMoney(value)
  }

  const solvedRender = ({ children }) => {
    let solved = receiptDetailsData?.data?.filter((x) => x.id === children)[0]
      ?.solved
    return (
      <>
        <s.Checkbox
          id={uuid()}
          onChange={() => {
            solveElement(
              receiptDetailsData?.data?.filter((x) => x.id === children)[0],
              options.wallet_setting_id
            ).then(() => getReceiptDetails(offset, 10))
          }}
          checked={solved}
          label={solved ? 'Resolvido' : 'Pendente'}
        />
      </>
    )
  }

  React.useEffect(() => {
    if (options?.wallet_setting_id) {
      getReceiptDetails(offset, 10)
    }
  }, [options, offset])

  return (
    <s.Modal
      modalSubtitle="Conferência com Valor Consolidado"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <s.ModalContainer>
        <s.ExportButton
          title="txIds"
          startDate={options.start_date}
          endDate={options.end_date}
          dataCallback={getReceiptDetailsRecursive}
        />
        <s.Summary>
          <s.Column>
            <br />
            <br />
            <s.TableText bold>Pix Pagos</s.TableText>
            <s.TableText bold>Pix Recebidos</s.TableText>
            <s.TableText bold>Pix Processados</s.TableText>
          </s.Column>
          <s.Column>
            <s.TableText bold>Total</s.TableText>
            <s.TableText>
              {reconciliationFormatMoney(totals[0]?.sum_orders_value)}
            </s.TableText>
            <s.TableText>
              {reconciliationFormatMoney(totals[1]?.sum_orders_value)}
            </s.TableText>
            <s.TableText>
              {reconciliationFormatMoney(totals[2]?.sum_orders_value)}
            </s.TableText>
          </s.Column>
          <s.Column>
            <s.TableText bold>Origem</s.TableText>
            <s.TableText>Shipay</s.TableText>
            <s.TableText>PSP</s.TableText>
            <s.TableText>Processadora</s.TableText>
          </s.Column>
          <s.Column>
            <s.TableText bold>Quantidade</s.TableText>
            <s.TableText>{totals[0]?.count_orders}</s.TableText>
            <s.TableText>{totals[1]?.count_orders}</s.TableText>
            <s.TableText>{totals[2]?.count_orders}</s.TableText>
          </s.Column>
          <s.Column>
            <s.TableText bold>Diferença</s.TableText>
            <s.TableText color={getColor(totals[0]?.value_difference)}>
              {formatMoneyWithNegativeValue(totals[0]?.value_difference)}
            </s.TableText>
            <s.TableText color={getColor(totals[1]?.value_difference)}>
              {formatMoneyWithNegativeValue(totals[1]?.value_difference)}
            </s.TableText>
            <s.TableText color={getColor(totals[2]?.value_difference)}>
              {formatMoneyWithNegativeValue(totals[2]?.value_difference)}
            </s.TableText>
          </s.Column>
        </s.Summary>

        <s.subHeader>Conferência TXIDs</s.subHeader>
        <s.subHeader>
          Foram encontrados {receiptDetailsData?.total} TXIDs de origem única
        </s.subHeader>
        <s.Table
          data={receiptDetailsData ? receiptDetailsData.data : []}
          isLoading={false}
          columns={[
            { headerName: 'Código Transação Pix', objectName: 'txid' },
            { headerName: 'End to End', objectName: 'e2eid' },
            {
              headerName: 'Valor',
              objectName: 'total_order',
              AltRender: RenderValue,
            },
            { headerName: 'Data/Hora Pago', objectName: 'created_at' },
            { headerName: 'Nome do Pagador', objectName: 'payer_name' },
            { headerName: 'CPF/CNPJ', objectName: 'payer_cpf_cnpj' },
            { headerName: 'Origem', objectName: 'origin' },
            {
              headerName: 'PSP Pagador',
              objectName: 'payer_financial_institute_name',
            },
            {
              headerName: 'Situação',
              objectName: 'id',
              AltRender: solvedRender,
            },
          ]}
        />
        <s.Pagination
          count={10}
          offset="0"
          total={receiptDetailsData?.total}
          handleChange={(offset) => {
            setOffset(offset)
          }}
        />
      </s.ModalContainer>
    </s.Modal>
  )
}

export default ReconciliationDetailsModal
