import { baseURLImpersonate, postWithCredentials } from '../api'
import {
  removeValueFromLocalStorage,
  getValueFromLocalStorage,
  setValueToLocalStorage,
} from 'helpers/localStorage'

export const impersonate = (customerId: string) => {
  return postWithCredentials(`${baseURLImpersonate}/${customerId}`, {})
}

export const undoImpersonate = () => {
  const user = getValueFromLocalStorage('adminUser')
  const userSession = getValueFromLocalStorage('adminUserSession')
  if (user || userSession) {
    setValueToLocalStorage('currentUserSession', userSession)
    removeValueFromLocalStorage('adminUserSession')
  }
}
