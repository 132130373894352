import { get, post, patch, remove, baseURLUser, baseURLNasaUser, baseURLCustomer } from '../api'
import { Partial } from 'helpers/partial'

export interface UserInterface {
  cpf: string
  email: string
  first_name: string
  last_name: string
  generate_password: boolean
  password: string
  phone: string
  retail_chain_id?: string
  user_role_id: string
}

const getUsersByCostumer = (customerId: string, params = null) => {
  const { filter, filter_type, ..._params} = params
  if (filter_type)
    _params[filter_type] = filter
  return get(`${baseURLCustomer}/membership/${customerId}`, { params: _params || '' })
}

const getUsers = (level: string, targetId: string, params = null) => {
  const { filter, filter_type, ..._params} = params
  if (filter_type)
    _params[filter_type] = filter
  if (level)
    return get(`${baseURLUser}/${level}/${targetId}`, { params: _params || '' })
  return get(`${baseURLUser}`, { params: _params || '' })
}

const getUser = (userId: string) => {
  return get(`${baseURLNasaUser}/${userId}`)
}

const createUser = (user: UserInterface) => {
  return post(`${baseURLUser}`, user)
}

const editUser = (userId: string, user: Partial<UserInterface>) => {
  return patch(`${baseURLUser}/${userId}`, user)
}

const deleteUser = (userId: string) => {
  return remove(`${baseURLUser}/${userId}`)
}

const userService = {
  getUsers,
  getUser,
  createUser,
  deleteUser,
  editUser,
  getUsersByCostumer,
}

export default userService
