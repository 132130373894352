import { ActionInterface, Types, CustomersState } from './types'

const INITIAL_STATE: CustomersState = {
  customers: { count: 0, data: [], offset: 0, total: -1 },
  isLoading: false,
  hasError: false,
}

const customersReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): CustomersState => {
  switch (action.type) {
    case Types.GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
        isLoading: false,
        hasError: false,
      }
    case Types.GET_CUSTOMERS_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_CUSTOMERS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export default customersReducer
