import { useState } from 'react'
import { showToast } from 'components/Toast'
import { useTranslation } from 'react-i18next'

import geoService from 'services/geo'

interface IUseGetGeoCities {
  loadGeoCities: (uf: string) => Promise<void>
  cities: string[]
  isLoading: boolean
}

export const useGetGeoCities = (): IUseGetGeoCities => {
  const { t } = useTranslation()
  const [cities, setCities] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const loadGeoCities = async (uf: string) => {
    setIsLoading(true)
    await geoService
      .getCitiesByUf(uf)
      .then((response) => {
        setCities(response.data.cities)
      })
      .catch((e) => {
        setCities([])
        showToast({ type: 'error', message: t('Erro na API') })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return { cities, isLoading, loadGeoCities }
}
