import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'store/modules/rootState'

import {
  PostCertificate,
  PostCertificateIsLoading,
  PostCertificateError,
} from 'store/modules/certificate/action'

import { CertificateState } from 'store/modules/certificate/types'

import certificateService, { CreateClientCertInterface } from 'services/clientCert'

import { Partial } from 'helpers/partial'

interface UsePostCertificate extends CertificateState {
  createCertificate: (certificateData: Partial<CreateClientCertInterface> ) => Promise<void>,
}

export const usePostCertificate = (): Partial<UsePostCertificate> => {
  const dispatch = useDispatch()
  const { postResponse, postIsLoading, postHasError } = useTypedSelector((store) => ({
    postResponse: store.certificate.postResponse,
    postIsLoading: store.certificate.postIsLoading,
    postHasError: store.certificate.postHasError,
  }))

  const createCertificate = useCallback(async (certificateData): Promise<void> => {
    dispatch(PostCertificateIsLoading())
    await certificateService.postClientCert(certificateData)
      .then((response) => {
        dispatch(PostCertificate(response.data))
      })
      .catch(() => {
        dispatch(PostCertificateError())
      })
  }, [dispatch])

  return { postResponse, postIsLoading, postHasError, createCertificate}
}
