import React from 'react'

import { useTranslation } from 'react-i18next'

import { parseJwt } from 'helpers/jwt'
import { getValueFromLocalStorage } from 'helpers/localStorage'

import useWalletSettingsSaga from 'hooks/useWalletSettingsSaga'
import { useSSE } from 'hooks/utils/useSSE'
import useWalletsSettings from 'hooks/useWalletsSettings'
import { useNonInitialEffect } from 'hooks/utils'

import customerService from 'services/customer'

import { showToast } from 'components/Toast'

import * as s from '../styles'
import { UserSessionInfo } from 'store/modules/auth/types'

const NinetyninepayConfigWallet: React.FC<{
  handleClose: () => void
  isModalOpen: boolean
  offset?: string
}> = ({ handleClose, isModalOpen, offset }) => {
  const { t } = useTranslation()
  const { loadWalletsSettings } = useWalletsSettings()
  const { postIsLoading, postResponse, postEWalletWalletSettingsSaga } =
    useWalletSettingsSaga()

  const { openConnection, notifications } = useSSE()

  const [customerUUID, setCustomerUUID] = React.useState<string>('')
  const [isSSELoading, setSSEIsLoading] = React.useState<boolean>(false)

  const [input, setInput] = React.useState({
    active: true,
    default: false,
    name: '',
    merchant_id: '',
    private_key: '',
    wallet_name: 'ninetyninepay',
    app_id: '',
  })

  const [inputPressed, setInputPressed] = React.useState<any>({
    ...Object.keys(input).reduce((acc, key) => {
      acc[key] = false
      return acc
    }, {}),
  })

  const handlePost = async () => {
    postEWalletWalletSettingsSaga({ ...input })
  }

  useNonInitialEffect(() => {
    if (postResponse.code !== -1) {
      showToast({
        type: 'success',
        message: t(postResponse.message),
      })
      setSSEIsLoading(true)
      customerService.getCurrentCustomer().then((currentCustomerRes) => {
        setCustomerUUID(currentCustomerRes.data.uuid)
        openConnection(currentCustomerRes.data.uuid)
      })
    }
  }, [postResponse])

  useNonInitialEffect(() => {
    if (notifications) {
      const status = notifications[customerUUID].message
        .toLowerCase()
        .includes('sucesso')
        ? 'success'
        : 'error'
      showToast({
        type: status,
        message: notifications[customerUUID].message,
      })
      setSSEIsLoading(false)
      if (status === 'success') {
        handleClose()
        loadWalletsSettings({
          limit: '10',
          offset: offset,
          filters: window.btoa(
            JSON.stringify({ wallet_type: ['c-wallet', 'e-wallet'] })
          ),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  React.useEffect(() => {
    if (!isModalOpen) {
      setInput({
        active: true,
        default: false,
        name: '',
        merchant_id: '',
        private_key: '',
        wallet_name: 'ninetyninepay',
        app_id: '',
      })
      setInputPressed({
        ...Object.keys(input).reduce((acc, key) => {
          acc[key] = false
          return acc
        }, {}),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen])

  return (
    <>
      <s.FormContainer height={550}>
        <s.InputText
          label="Apelido da Credencial"
          placeholder="Ex: ”Loja Paulista”, ”loja@paulista.com”, etc."
          value={input.name}
          onChange={(e) => {
            setInput({ ...input, name: e.target.value })
            setInputPressed({ ...inputPressed, name: true })
          }}
          error={
            input.name === '' && inputPressed.name
              ? {
                  message: 'Campo Obrigatório',
                }
              : null
          }
        />
        <s.InputText
          label="Merchant ID"
          value={input.merchant_id}
          onChange={(e) => {
            setInput({ ...input, merchant_id: e.target.value })
            setInputPressed({ ...inputPressed, merchant_id: true })
          }}
          error={
            input.merchant_id === '' && inputPressed.merchant_id
              ? {
                  message: 'Campo Obrigatório',
                }
              : null
          }
        />

        <s.InputText
          label="App ID"
          value={input.app_id}
          onChange={(e) => {
            setInput({ ...input, app_id: e.target.value })
            setInputPressed({ ...inputPressed, app_id: true })
          }}
          error={
            input.app_id === '' && inputPressed.app_id
              ? {
                  message: 'Campo Obrigatório',
                }
              : null
          }
        />

        <s.InputTextArea
          label="Private Key"
          value={input.private_key}
          onChange={(e) => {
            setInput({ ...input, private_key: e.target.value })
            setInputPressed({ ...inputPressed, private_key: true })
          }}
        />
        <s.FooterButtonWrapper>
          <s.Button
            outline
            color="white"
            disabled={isSSELoading || postIsLoading}
            onClick={() => handleClose()}
          >
            Cancelar
          </s.Button>
          <s.Button
            color="maincolor"
            disabled={
              input.name === '' ||
              input.merchant_id === '' ||
              input.private_key === '' ||
              input.app_id === '' ||
              isSSELoading ||
              postIsLoading
            }
            onClick={() => handlePost()}
          >
            Salvar
          </s.Button>
          {postIsLoading || isSSELoading ? (
            <s.Loading type="spinner" color="maincolor" />
          ) : null}
        </s.FooterButtonWrapper>
      </s.FormContainer>
    </>
  )
}

export default NinetyninepayConfigWallet
