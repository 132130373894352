import { baseURL } from 'services/apiConfig'
import { get, baseURLGeoAddress } from '../api'

export interface Address {
  city: string
  neighborhood: string
  postal_code: string
  state: string
  street: string
}

const getAddressByZipcode = (zipcode: string) => {
  return get(`${baseURLGeoAddress}/${zipcode}`)
}

const getCitiesByUf = (uf: string) => {
  return get(`${baseURL}/geo/cities/${uf}`)
}

const geoService = {
  getAddressByZipcode,
  getCitiesByUf,
}

export default geoService
