import {
  Types,
  UserInterface,
  GetUsersResponseInterface,
  PostUserResponseInterface,
  PatchUserResponseInterface,
  DelUserResponseInterface,
} from './types'

export function GetUser(
  payload: UserInterface
): {
  type: string
  payload: UserInterface
} {
  return {
    type: Types.GET_USER,
    payload,
  }
}

export function GetUserLoading(): {
  type: string
} {
  return {
    type: Types.GET_USER_LOADING,
  }
}

export function GetUserError(): {
  type: string
} {
  return {
    type: Types.GET_USER_ERROR,
  }
}

//GET USERS
export function GetUsers(
  payload: GetUsersResponseInterface
): {
  type: string
  payload: GetUsersResponseInterface
} {
  return {
    type: Types.GET_USERS,
    payload,
  }
}

export function GetUsersIsLoading(): {
  type: string
} {
  return {
    type: Types.GET_USERS_LOADING,
  }
}

export function GetUsersError(): {
  type: string
} {
  return {
    type: Types.GET_USERS_ERROR,
  }
}

//POST
export function PostUser(
  payload: PostUserResponseInterface
): {
  type: string
  payload: PostUserResponseInterface
} {
  return {
    type: Types.POST_USER,
    payload,
  }
}

export function PostUserIsLoading(): {
  type: string
} {
  return {
    type: Types.POST_USER_LOADING,
  }
}

export function PostUserError(): {
  type: string
} {
  return {
    type: Types.POST_USER_ERROR,
  }
}

//PATCH
export function PatchUser(
  payload: PatchUserResponseInterface
): {
  type: string
  payload: PatchUserResponseInterface
} {
  return {
    type: Types.PATCH_USER,
    payload,
  }
}

export function PatchUserIsLoading(): {
  type: string
} {
  return {
    type: Types.PATCH_USER_LOADING,
  }
}

export function PatchUserError(): {
  type: string
} {
  return {
    type: Types.PATCH_USER_ERROR,
  }
}

//DELETE
export function DelUser(
  payload: DelUserResponseInterface
): {
  type: string
  payload: DelUserResponseInterface
} {
  return {
    type: Types.DEL_USER,
    payload,
  }
}

export function DelUserIsLoading(): {
  type: string
} {
  return {
    type: Types.DEL_USER_LOADING,
  }
}

export function DelUserError(): {
  type: string
} {
  return {
    type: Types.DEL_USER_ERROR,
  }
}
