import styled from 'styled-components'
import TextCmp from 'components/Text'
import InputTextCmp from 'components/Inputs/Text'
import SelectCmp from 'components/Inputs/Select'
import ButtonCmp from 'components/Button'

export const ModalContent = styled.div`
  width: 450px;
`
export const InputText = styled(InputTextCmp)``

export const InputGroup = styled.div`
  margin-bottom: 30px;
`

export const Select = styled(SelectCmp)`
  margin-top: 8px;
  margin-bottom: 16px;
`

export const Text = styled(TextCmp)``

export const Button = styled(ButtonCmp)``

export const ButtonGroup = styled.div`
  text-align: center;
`
