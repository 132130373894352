import styled from 'styled-components'
import IconCmp from 'components/IconExporter'
import SelectCmp from 'components/Inputs/Select'
import TextCmp from 'components/Text'
import InputTextCmp from 'components/Inputs/Text'

export const NewSelect = styled(SelectCmp)`
  width: 260px;
  p {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.graytheme6};
  }
`

export const Select = styled(SelectCmp)`
  width: 304px;
`

export const Icon = styled(IconCmp)`
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: 10px;
  align-self: end;
`

export const InputToggleGroup = styled.div`
  display: flex;
`

export const SelectGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

export const SelectToggleGroup = styled.div`
  display: flex;
`

export const NewInputGroupPixKey = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 32px;
`

export const SectionLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`

export const Text = styled(TextCmp)``

export const InputText = styled(InputTextCmp)`
  margin-top: 8px;
  margin-bottom: 0px;
  svg {
    margin: 0px 0px 2px 0px;
  }
`