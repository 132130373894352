import { Types, RegistrationStatusInterface } from './types'

export function GetRegistrationStatusList(
  payload: RegistrationStatusInterface[]
): {
  type: string
  payload: RegistrationStatusInterface[]
} {
  return {
    type: Types.GET_REGISTRATION_STATUS,
    payload,
  }
}

export function GetRegistrationStatusListIsLoading(): {
  type: string
} {
  return {
    type: Types.GET_REGISTRATION_STATUS_LOADING,
  }
}

export function GetRegistrationStatusListError(): {
  type: string
} {
  return {
    type: Types.GET_REGISTRATION_STATUS_ERROR,
  }
}
