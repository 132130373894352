import { useState } from 'react'
import { get, baseURLPaymentsV1 } from '../services/api'
import { showToast } from 'components/Toast'

interface TransactionData {
  uuid: string
	created_at: string
	event_type: string
	resource: string
	message: Object | null
	info: string
}

interface PayloadTransaction {
  offset: number
  count: number
  data: Array<TransactionData>
}

type Params = {
  limit: string
  offset: string
}

interface UseGetCashoutTransactions {
  transactions: PayloadTransaction
  transactionsIsLoading: boolean
  transactionsHasError: boolean
  loadTransactions: (id: string, params: Params) => Promise<void>
}

const useGetCashoutTransactions = (): UseGetCashoutTransactions => {
  const [transactions, setTransactions] = useState<PayloadTransaction>({
    offset: 0,
    count: 0,
    data: [],
  })
  const [transactionsIsLoading, setTransactionsIsLoading] = useState<boolean>(false)
  const [transactionsHasError, setTransactionsHasError] = useState<boolean>(false)

  const loadTransactions = async (id: string, params: Params) => {
    setTransactionsIsLoading(true)
    await get(`${baseURLPaymentsV1}/${id}/transaction-events`,  {
      params: params || '',
    })
      .then((response) => {
        setTransactions(response.data)
      })
      .catch((e) => {
        showToast({
          type: 'error',
          message: JSON.parse(e.request.response)?.message,
        })
        setTransactionsHasError(true)
      })
      .finally(() => {
        setTransactionsIsLoading(false)
      })
  }
  return { transactions, transactionsIsLoading, transactionsHasError, loadTransactions }
}

export default useGetCashoutTransactions