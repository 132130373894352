import styled from 'styled-components'
import { themeColors } from 'styles/theme'
import TextCmp from 'components/Text'
import InputTextCmp from 'components/Inputs/Text'
import SelectCmp from 'components/Inputs/Select'
import ButtonCmp from 'components/Button'
import IconCmp from 'components/IconExporter'

export const MessageBoxOutline = styled.div`
  border: 1px solid ${themeColors.gray3};
  border-radius: 8px;
  padding: 12px;
  text-align: justify;
  margin-bottom: 20px;
`

export const MessageBoxButton = styled.div`
  display: flex;
  align-items: center;
`

export const WarningBox = styled.div`
  border: 1px solid ${themeColors.darkmaincolor1};
  border-radius: 8px;
  padding: 10px 20px;
  background-color: ${themeColors.lightmaincolor3};
  margin: 10px auto;
  color: ${themeColors.darkmaincolor1};
`

export const InputGroupPixKey = styled.div`
  margin-top: 8px;
  padding-bottom: 8px;
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  grid-column-gap: 20px;
`

export const NewInputGroupPixKey = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 32px;
`

export const InputGroup = styled.div`
  margin-top: 8px;
  padding-bottom: 8px;
`

export const InputText = styled(InputTextCmp)`
  margin-top: 8px;
  margin-bottom: 0;
`

export const Select = styled(SelectCmp)`
  margin-top: 8px;
  margin-bottom: 16px;
`

export const NewSelect = styled(SelectCmp)`
  width: 260px;
  p {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.graytheme6};
  }
`

export const Text = styled(TextCmp)``

export const Button = styled(ButtonCmp)`
  > span {
    text-transform: none;
    color: white;
    font-size: 12px;
  }

  margin-left: 10px;
`

export const Icon = styled(IconCmp)``

export const SectionLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`

export const ExternalLink = styled.a`
  color: ${({ theme }) => theme.colors.maincolor};
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
`
