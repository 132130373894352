import { combineReducers } from 'redux'

import walletsList from './wallets/reducer'
import summary from './summary/reducer'
import systemWallets from './systemWallets/reducer'
import walletSettings from './walletSettings/reducer'
import registrationStatus from './registrationStatus/reducer'
import stores from './stores/reducer'
import user from './user/reducer'
import profile from './profile/reducer'
import store from './store/reducer'
import geoAddress from './geoAddress/reducer'
import pixWalletSettings from './walletSettings/pix/reducer'
import form from './form/reducer'
import customers from './admin/reducer'
import auth from './auth/reducer'
import impersonate from './impersonate/reducer'
import retailChain from './retailChain/reducer'
import storePos from './storePos/reducer'
import storeWallets from './storeWallets/reducer'
import pixWS from './pixWalletSettings/reducer'
import completeRegistration from './completeRegistration/reducer'
import customerRegistration from './customerRegistration/reducer'
import certificate from './certificate/reducer'
import SSENotification from './SSENotification/reducer'
import eWalletWSSaga from './walletSettings/ewallet/reducer'
import ordersSummaryFiles from './ordersSummaryFiles/reducer'
import transactionLogs from './transacionLogs/reducer'
import logo from './logo/reducer'
import theme from './theme/reducer'

const allReducers = combineReducers({
  walletsList,
  summary,
  systemWallets,
  walletSettings,
  pixWalletSettings,
  registrationStatus,
  stores,
  user,
  profile,
  store,
  geoAddress,
  form,
  customers,
  auth,
  impersonate,
  retailChain,
  storePos,
  storeWallets,
  pixWS,
  completeRegistration,
  customerRegistration,
  certificate,
  SSENotification,
  eWalletWSSaga,
  ordersSummaryFiles,
  transactionLogs,
  logo,
  theme,
})

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    state = undefined
  }
  return allReducers(state, action)
}

export default rootReducer
