export const Types = {
  GET_WALLETS_SETTINGS: 'GET_WALLETS_SETTINGS',
  GET_WALLETS_SETTINGS_LOADING: 'GET_WALLETS_SETTINGS_LOADING',
  GET_WALLETS_SETTINGS_ERROR: 'GET_WALLETS_SETTINGS_ERROR',
  PATCH_WALLET_SETTINGS_REQUEST: 'PATCH_WALLET_SETTINGS_REQUEST',
  PATCH_WALLET_SETTINGS_RESPONSE: 'PATCH_WALLET_SETTINGS_RESPONSE',
  PATCH_WALLET_SETTINGS_ERROR: 'PATCH_WALLET_SETTINGS_ERROR',
  GET_WALLET_SETTING: 'GET_WALLET_SETTING',
  GET_WALLET_SETTING_LOADING: 'GET_WALLET_SETTING_LOADING',
  GET_WALLET_SETTING_ERROR: 'GET_WALLET_SETTING_ERROR',
  POST_WALLET_SETTING_LOADING: 'POST_WALLET_SETTING_LOADING',
  POST_WALLET_SETTING_RESPONSE: 'POST_WALLET_SETTING_RESPONSE',
  POST_WALLET_SETTING_ERROR: 'POST_WALLET_SETTING_ERROR',
}

export interface WalletSettingsInterface {
  count: number
  offset: number
  total: number
  data: WalletsSettingsList[]
}

export interface WalletsSettingsList {
  active: boolean
  default: boolean
  id: string
  name: string
  psp_provider: string
  status: string
  tls_certificate: string
  wallet_name: string
  wallet_icon: string
  transaction_type: string
  wallet_friendly_name: string
}

export interface WalletSetting {
  active: boolean
  client_id: string
  client_secret: string
  default: boolean
  enabled_pix: boolean
  id: string
  name: string
  psp_provider: string
  wallet_name: string
  access_key?: string
  collector_id?: string
  picpay_token?: string
  seller_token?: string
  pagseguro_token?: string
  status?: string
  pub_key?: string
  secret_key?: string
  payer_crypto_coin?: string
  receiver_crypto_coin?: string
  ipn_secret?: string
  term_id?: string
  establishment_id?: string
  merchant_id?: string
  app_id?: string
  private_key?: string
  public_key?: string
}

export interface ActionInterface {
  type: keyof typeof Types
  payload: WalletSettingsInterface | WalletSetting
  response: any
  message: string
  id: string
  postResponse: any
  postErrorMessage: string
}

export interface WalletSettingsState {
  walletSettings: {
    count: number
    offset: number
    total: number
    data: WalletsSettingsList[]
  }
  isLoading: boolean
  hasError: boolean
  patchResponse: any
  patchIsLoading: boolean
  patchHasError: boolean
  patchHasErrorMessage: any
  walletSetting: WalletSetting
  walletSettingHasError: boolean
  walletSettingIsLoading: boolean
  postResponse: any
  postIsLoading: boolean
  postHasError: boolean
  postHasErrorMessage: any
}

export interface RequestInterface {
  active: boolean
}

export interface ResponseInterface {}
