import { ActionInterface, Types, RegistrationStatusState } from './types'

const INITIAL_STATE: RegistrationStatusState = {
  registrationStatus: [],
  isLoading: false,
  hasError: false,
}

const registrationStatusReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): RegistrationStatusState => {
  switch (action.type) {
    case Types.GET_REGISTRATION_STATUS:
      return {
        ...state,
        registrationStatus: action.payload.data,
        isLoading: false,
        hasError: false,
      }
    case Types.GET_REGISTRATION_STATUS_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_REGISTRATION_STATUS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export default registrationStatusReducer
