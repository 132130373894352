import { useState } from 'react'

import customerService from 'services/customer'

interface UseGetCustomer {
  loadCustomer: (level: string) => Promise<void>
  customer: any
  isLoading: boolean
}

export const useGetCustomer = (): UseGetCustomer => {
  const [customer, setCustomer] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const loadCustomer = async (userId: string) => {
    const response = await customerService.getCustomer(userId)
    if (response && response.data) {
      setCustomer(response.data)
      setIsLoading(false)
    }
  }
  return { customer, isLoading, loadCustomer }
}
