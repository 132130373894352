import styled from 'styled-components'

import { Container as ContainerCmp } from 'styled-bootstrap-grid'
import TextCmp from 'components/Text'
import CardCmp from 'components/Card'
import InputTextCmp from 'components/Inputs/Text'
import RadioCmp from 'components/Inputs/RadioButton'
import SelectCmp from 'components/Inputs/Select'
import IconCmp from 'components/IconExporter'
import ButtonCmp from 'components/Button'
import LoadingCmp from 'components/Loading'


export const Background = styled.div`
  background-image: url(${({ theme }) => theme.assets.wizardBackground.img});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
`

export const BackgroundFilter = styled.div`
  background: rgba(255, 255, 255, ${({ theme }) => theme.assets.wizardBackground.filter});
  backdrop-filter: blur(64px);
  min-height: 100vh;
`

export const Container = styled(ContainerCmp)`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-height: (100vh);
  padding-bottom: 16px;
  padding-top: 160px;
`

export const Button = styled(ButtonCmp)`

  > span {
    text-transform: none;
  }

  height: 56px;

  > span > svg {
    margin-left: ${({ theme }) => theme.spacing(1)}px;
  }
`

export const Loading = styled(LoadingCmp)``

export const Text = styled(TextCmp)``

export const FootnoteText = styled(TextCmp)`
  font-size: 16px;
`

export const Footnote = styled.div`
  width: 916px;
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(6)}px;

  > button {
    margin-left: auto;
  }
`

export const Card = styled(CardCmp)``

export const StoreInfoWrapper = styled.div`
  margin-top: 25px;
`

export const FirstWrapper = styled.div`
  display: flex;
  margin: 32px 0;
  > div {
    :first-child {
      margin-right: 64px;
    }
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  margin: 0 -24px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};

  > h5 {
    padding: 0 64px;
  }
  > svg {
    margin-right: 64px;
  }
`

export const UpperWrapper = styled.div`
  display: flex;
  height: 100px;
  margin: 0 -64px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};

  > * {
    :first-child {
      margin-left: 64px;
      width: 204px;
    }
    :nth-child(2) {
      width: 440px;
    }
    :nth-child(3) {
      width: 80px;
    }
    margin: 0px 16px;
  }
`

export const Body = styled.div`
  padding: 0 40px;
  > div {
    margin-bottom: 32px;
  }
`

export const InputText = styled(InputTextCmp)`
  color: ${({ theme }) => theme.colors.graytheme6};
`

export const Radio = styled(RadioCmp)`
  height: fit-content;
`

export const RadioWrapper = styled.div``

export const RadioButtonWrapper = styled.div`
  display: flex;
  padding-top: 12px;

  > * {
    :first-child {
      padding-right: 8px;
    }
  }
`

export const BottomWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px 16px;

  > * {
    :nth-child(1),
    :nth-child(2),
    :nth-child(3) {
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }
  }

  > * {
    :first-child,
    :nth-child(4),
    :nth-child(5),
    :nth-child(6) {
      width: 203px;
    }

    :nth-child(2) {
      width: 438px;
    }
    :nth-child(3),
    :nth-child(7) {
      width: 80px;
    }

    margin: 0px 16px;
  }
`

export const Select = styled(SelectCmp)`
  > div > div > p {
    color: ${({ theme }) => theme.colors.graytheme6};
  }
`

export const Icon = styled(IconCmp)``

export const TextWrapper = styled.div`
  width: 100%;
  margin-left: 352px;
`
