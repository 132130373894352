import styled from 'styled-components'

import ButtonCmp from 'components/Button'
import LoadingCmp from 'components/Loading'
import PaginationCmp from 'components/Pagination'
import TableCmp from 'components/Table'
import TextCmp from 'components/Text'

export { Text, Icon, Spacer, SectionHeader, SectionContentWrapper } from '../styles'

export const CreatedAtText = styled(TextCmp)`
  display: flex;
  align-items: center;
  > em {
    font-style: normal;
    color: ${({ theme }) => theme.colors.maincolor};
  }
`

export const Loading = styled(LoadingCmp)`
  margin-left: 16px;
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const FilesModalWrapper = styled.div`
  height: 350px;
`

export const FilesTable = styled(TableCmp)`
  width: 100%;
  & tr {
    height: 48px;
    & * {
      font-size: 13px;
    }
    > th {
      padding-left: 16px;
    }
    th:first-child{
      width: 30%;
    }
    th:nth-child(2) {
      width: 40%;
    }
    th:nth-child(3) {
      width: 10%;
    }
    > td *{
      font-size: 13px;

    }
  }
  & thead tr {
    height: 36px;
  }
`

export const UpdateButton = styled(ButtonCmp)`
  margin-left: 16px;
  border: none;
  box-shadow: none;
`

export const Pagination = styled(PaginationCmp)`
  margin: 0;
  align-items: end;
`

export const DownloadFilesButton = styled.div<{ disabled?: boolean }>`
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(2)}px;
  border-radius: 8px;
  border: 0;
  max-height: 32px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => disabled ? '' : 'pointer'};
  user-select: none;
  > svg, p {
    margin-left: 8px;
  }
`

export const StepWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
