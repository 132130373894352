import {
  Types,
  ResponseInterface,
} from './types'

export function PostCompleteRegistration(
  payload: ResponseInterface
): {
  type: string,
  payload: ResponseInterface
} {
  return {
    type: Types.POST_RESPONSE,
    payload
  }
}

export function PostCompleteRegistrationIsLoading(): {
  type: string,
} {
  return {
    type: Types.POST_LOADING,
  }
}

export function PostCompleteRegistrationError(): {
  type: string,
} {
  return {
    type: Types.POST_ERROR,
  }
}
