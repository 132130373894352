import React from 'react'

import * as s from './styles'

const RenderButtons: React.FC<{ type: string; handleClose: () => void }> = ({
  type,
  handleClose,
}) => {
  const buttons = () => {
    if (type === 'isIdle') {
      return (
        <s.Button
          data-testid="verify-documents-button"
          width="304px"
          height="42px"
          onClick={() => {
            handleClose()
          }}
        >
          <s.Text margin="0 8px 0 0" color="white" type="headline">
            Fechar
          </s.Text>
        </s.Button>
      )
    }

    if (type === 'idleWarning') {
      return (
        <>
          <s.Button
            data-testid="verify-documents-button"
            width="304px"
            height="42px"
            onClick={() => {
              handleClose()
            }}
          >
            <s.Text margin="0 8px 0 0" color="white" type="headline">
              Fechar
            </s.Text>
          </s.Button>{' '}
          <s.Button
            data-testid="verify-documents-button"
            width="304px"
            height="42px"
            onClick={() => {
              handleClose()
            }}
          >
            <s.Text margin="0 8px 0 0" color="white" type="headline">
              Fechar
            </s.Text>
          </s.Button>
        </>
      )
    }
  }

  return (
    <s.PreviousNextButtonContainer>
      <div></div>
      <s.PreviousNextButtonWrapper>{buttons()}</s.PreviousNextButtonWrapper>
    </s.PreviousNextButtonContainer>
  )
}

const IdleModal: React.FC<{
  isOpen: boolean
  handleClose: () => void
  type: 'isIdle' | 'idleWarning'
}> = ({ isOpen, handleClose, type }) => {
  const textBasedOnType = {
    isIdle: {
      title: 'Usuário deslogado por inatividade',
      description:
        'Sua sessão no Painel foi encerrada por inatividade, faça login novamente para continuar.',
    },
    idleWarning: {
      title: 'Sua sessão será expirada em breve devido a inatividade',
      description: 'Clique no botão "Continuar sessão" para utilizar o painel',
    },
  }

  return (
    <s.Modal isOpen={isOpen} handleClose={handleClose}>
      <s.Sections data-testid="content-on-success">
        <s.SucessWrapper>
          <s.Text color="graytheme6" bold type="heading4">
            {textBasedOnType[type].title}
          </s.Text>
          <s.Text margin="32px 0 0 0" color="graytheme6" type="headline">
            {textBasedOnType[type].description}
          </s.Text>
        </s.SucessWrapper>
        <RenderButtons type={type} handleClose={handleClose} />
      </s.Sections>
    </s.Modal>
  )
}

export default IdleModal
