import styled from 'styled-components'
import { Container as ContainerCmp } from 'styled-bootstrap-grid'

export const Container = styled(ContainerCmp)`
  padding: ${({ theme }) => theme.spacing(2)}px;
  height: 100vh;
  overflow-y: auto;

  & > div {
    margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  }
`
