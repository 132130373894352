import React from 'react'

import paitausuccess2 from '../../../../assets/images/pa-itau-success2.png'

import * as s from '../styles'

export const SuccessModal: React.FC<{ handleClose: () => void }> = ({
  handleClose,
}) => {
  return (
    <s.SuccessWrapper>
      <s.Text type="heading4" bold color="graytheme6">
        Cadastro efetuado com sucesso!
      </s.Text>
      <s.InfoSucessBox>
        <s.SuccessIconBox>
          <s.Icon fill="maincolor" name="clock" />
        </s.SuccessIconBox>
        <s.TextWrapper>
          <s.Text fontWeight={500} color="graytheme6" type="headline" bold>
            Abertura de conta em análise
          </s.Text>
          <s.Text fontWeight={500} color="graytheme6" type="headline">
            Suas informações serão analisadas pelo PicPay.
            <br />O resultado da análise poderá levar até 3 dias úteis.
          </s.Text>
        </s.TextWrapper>
      </s.InfoSucessBox>
      <s.InfoSucessBox>
        <s.SuccessIconBox>
          <s.Icon fill="maincolor" name="eyeon" />
        </s.SuccessIconBox>
        <s.TextWrapper>
          <s.Text fontWeight={500} color="graytheme6" type="headline" bold>
            Acompanhe seu cadastro
          </s.Text>
          <s.SuccessPixText fontWeight={500} color="graytheme6" type="headline">
            Você pode acompanhar esse processo na aba{' '}
            <b onClick={() => handleClose()}>Pix↗ </b> <br />
            do Painel, na seção <u>Contas em análise</u>.
          </s.SuccessPixText>
        </s.TextWrapper>
      </s.InfoSucessBox>
      <img src={paitausuccess2} alt="Imagem de Sucesso Pix AnyBank" />
    </s.SuccessWrapper>
  )
}
