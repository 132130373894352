import { Types, PayloadInterface } from './types'

export function GetLogo(
  payload: PayloadInterface
): {
  type: string
  payload: PayloadInterface
} {
  return {
    type: Types.GET_LOGO,
    payload,
  }
}

export function GetLogoLoading(): {
  type: string
} {
  return {
    type: Types.GET_LOGO_LOADING,
  }
}

export function GetLogoError(): {
  type: string
} {
  return {
    type: Types.GET_LOGO_ERROR,
  }
}
