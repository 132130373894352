import Toast from 'components/Toast'
import React from 'react'

import BrandIdentity from './BrandIdentity'

import * as s from './styles'

const Customization: React.FC = () => {
  return (
    <s.Container>
      <Toast />
      <BrandIdentity />
    </s.Container>
  )
}

export default Customization
