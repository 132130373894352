import { useState } from 'react'

import * as s from './styles'
import { useNonInitialEffect } from 'hooks/utils'

interface IProps {
  label: string
  options: { id: string; text: string }[]
  selected: { id: string; name: string }
  onDisabledChange?: (disabled) => void
  disabled?: boolean
}

export const SelectToggle: React.FC<IProps> = ({
  label,
  options,
  selected,
  onDisabledChange,
  disabled,
}) => {
  return (
    <s.SelectGroup>
      <s.SelectLabel bold color="maincolor" type="headline">
        {label}
      </s.SelectLabel>
      <s.SelectToggleGroup>
        <s.Select
          optionBoxSize={90}
          disabled={disabled}
          options={options}
          selected={selected}
        />
        <s.Icon
          name="pencil"
          fill="maincolor"
          onClick={() => onDisabledChange(!disabled)}
        />
      </s.SelectToggleGroup>
    </s.SelectGroup>
  )
}
