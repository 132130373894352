import React from 'react'

import paitausuccess from 'assets/images/pa-itau-success2.png'

import * as s from './styles'

interface IProps {
  walletFriendlyName?: string
  goToEditWS: (isFromSuccess: boolean) => void
}

const SuccessAddingBankSlip: React.FC<IProps> = ({
  goToEditWS,
  walletFriendlyName,
}) => {
  return (
    <s.Sections data-testid="content-on-success">
      <s.Section>
        <s.Text color="graytheme6" bold type="heading4">
          Funcionalidade adicionada com sucesso!
        </s.Text>
        <s.Text color="graytheme6" type="headline">
          Agora sua conta {walletFriendlyName} possui a funcionalidade de Boleto
          híbrido! Consulte o suporte para qualquer dúvida.
        </s.Text>
        <img src={paitausuccess} />
      </s.Section>
      <s.Button
        data-testid="go-to-stores-button"
        onClick={() => goToEditWS(true)}
        disabled={false}
        height="42px"
        color="maincolor"
      >
        <s.Text color="white">Voltar para Configurações da conta</s.Text>
      </s.Button>
    </s.Sections>
  )
}

export default SuccessAddingBankSlip
