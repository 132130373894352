import { 
  Types,
  GetRetailChainsResponseInterface,
  PostRetailChainResponseInterface,
  DelRetailChainResponseInterface,
  PatchRetailChainResponseInterface,
} from './types'

//GET
export function GetRetailChains(
  payload: GetRetailChainsResponseInterface
): {
  type: string
  payload: GetRetailChainsResponseInterface
} {
  return {
    type: Types.GET_RETAIL_CHAINS,
    payload
  }
}

export function GetRetailChainsIsLoading(): {
  type: string
} {
  return {
    type: Types.GET_RETAIL_CHAINS_LOADING
  }
}

export function GetRetailChainsError(): {
  type: string
} {
  return {
    type: Types.GET_RETAIL_CHAINS_ERROR
  }
}

//POST
export function PostRetailChain(
  payload: PostRetailChainResponseInterface
): {
  type: string
  payload: PostRetailChainResponseInterface
} {
  return {
    type: Types.POST_RETAIL_CHAIN,
    payload
  }
}

export function PostRetailChainIsLoading(): {
  type: string
} {
  return {
    type: Types.POST_RETAIL_CHAIN_LOADING
  }
}

export function PostRetailChainError(): {
  type: string
} {
  return {
    type: Types.POST_RETAIL_CHAIN_ERROR
  }
}

//DELETE
export function DelRetailChain(
  payload: DelRetailChainResponseInterface
): {
  type: string
  payload: DelRetailChainResponseInterface
} {
  return {
    type: Types.DEL_RETAIL_CHAIN,
    payload
  }
}

export function DelRetailChainIsLoading(): {
  type: string
} {
  return {
    type: Types.DEL_RETAIL_CHAIN_LOADING
  }
}

export function DelRetailChainError(): {
  type: string
} {
  return {
    type: Types.DEL_RETAIL_CHAIN_ERROR
  }
}

//PATCH
export function PatchRetailChain(
  payload: PatchRetailChainResponseInterface
): {
  type: string
  payload: PatchRetailChainResponseInterface
} {
  return {
    type: Types.PATCH_RETAIL_CHAIN,
    payload
  }
}

export function PatchRetailChainIsLoading(): {
  type: string
} {
  return {
    type: Types.PATCH_RETAIL_CHAIN_LOADING
  }
}

export function PatchRetailChainError(): {
  type: string
} {
  return {
    type: Types.PATCH_RETAIL_CHAIN_ERROR
  }
}
