import React from 'react'

import { getValueFromLocalStorage } from 'helpers/localStorage'
import { checkUserClaim } from 'helpers/claims'

import { dict } from 'components/IconExporter'

import * as s from './styles'
import { UserSessionInfo } from 'store/modules/auth/types'

//TODO: Estilização do botão de forma custom ou com padrões já definidos

interface IProps {
  color?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'hollow'
    | 'whitedetailed'
    | string
  dataTestId?: 'button'
  width?: string
  outline?: boolean
  height?: string
}

const Button: React.FC<
  IProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  children,
  color = 'primary',
  disabled = false,
  width = 'auto',
  outline = false,
  height = '35px',
  ...rest
}) => {
  const [isVisible, setIsVisible] = React.useState(true)
  const buttonClaimBuilder = (pagePath: string, buttonText: string) => {
    const pagePathClean = pagePath.replace(/\//g, '_')
    const buttonTextClean = buttonText
      .normalize('NFD')
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .replace(/\s/g, '_')
      .replace(/^_/g, '')

    return `panel_pages_${pagePathClean}@btn_${buttonTextClean}`
  }

  const getButtonText = (children) => {
    if (typeof children === 'string' || typeof children === 'number')
      return children + ''
    if (Array.isArray(children)) {
      for (const child of children) {
        if (typeof child === 'string' || typeof child === 'number')
          return child + ''
        if (child.props.children && typeof child.props.children === 'string')
          return child.props.children
      }
    }
    return ''
  }

  React.useEffect(() => {
    const pagePath = window.location.href.replace(/.+#\//g, '')
    const buttonText = getButtonText(children).trim()
    const buttonClaim = buttonClaimBuilder(pagePath, buttonText.toLowerCase())
    const currentUserSession: UserSessionInfo = JSON.parse(
      getValueFromLocalStorage('currentUserSession')
    )
    console.debug(`%c${buttonClaim}`, 'background: #f00; color: white;')
    if (checkUserClaim(currentUserSession?.user_claims, buttonClaim)) {
      setIsVisible(false)
    }
  }, [])

  return (
    <>
      {isVisible ? (
        <s.Button
          color={color}
          disabled={disabled}
          width={width}
          height={height}
          outline={outline}
          {...rest}
        >
          <s.Content noPadding={color === 'hollow'}>{children}</s.Content>
        </s.Button>
      ) : (
        ''
      )}
    </>
  )
}

export default Button
