import IconExporter from 'components/IconExporter'
import React from 'react'
import { HexColorPicker, HexColorInput } from 'react-colorful'
import useDebounce from 'hooks/useDebounce'

import * as s from './styles'

interface IColorPicker {
  color: string
  onChange: (value: string) => void
}

export default function ColorPicker({ color, onChange }: IColorPicker) {
  const [isVisible, setIsVisible] = React.useState(false)
  const selectRef = React.useRef(null)
  const [prevColor, setPrevColor] = React.useState(
    color !== '#737373' ? color : '#30C322'
  )

  const debounceColorSlide = useDebounce((color) => onChange(color), 200)
  const debounceColorInput = useDebounce(onChange, 50)

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsVisible(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <s.Container ref={selectRef}>
      <s.HexInput>
        {'#'}
        <HexColorInput
          data-testid={'hex-color-input-color'}
          aria-label="hex-color-input"
          color={color}
          onChange={debounceColorInput}
        />
        <button
          onClick={() => {
            setIsVisible(!isVisible)
            if (color === '#737373') debounceColorInput(prevColor)
          }}
        >
          <IconExporter fill="maincolor" name="colordropper" width={16} />
        </button>
      </s.HexInput>
      {isVisible && (
        <s.Outter>
          <s.Inner>
            <HexColorPicker
              color={color}
              onChange={(color) => {
                setPrevColor(color)
                debounceColorSlide(color)
              }}
            />
          </s.Inner>
        </s.Outter>
      )}
    </s.Container>
  )
}
