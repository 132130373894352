import React from 'react'

import { reconciliationFormatMoney } from 'helpers/masks'
import { solveElement } from 'helpers/reconciliation'
import { baseURLReconciliation, get } from 'services/api'
import { v4 as uuid } from 'uuid'

import * as s from './styles'

interface IProps {
  isOpen: boolean
  handleClose: () => any
  options: {
    start_date: string
    end_date: string
    wallet_setting_id: string
    consolidated_customer_value: string
  }
}

interface response {
  total: number
  data: any
}

const RenderValue = ({ children }) => {
  return <s.Text>{reconciliationFormatMoney(children)}</s.Text>
}

const ReconciliationReturnedModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  options,
}) => {
  const [
    returnedDetailsData,
    setReturnedDetailsData,
  ] = React.useState<response>()

  const getTransferDetails = async (offset: number) => {
    await get(`${baseURLReconciliation}/devolution/details`, {
      params: { ...options, limit: 500, offset: offset },
    }).then((res) => {
      setReturnedDetailsData(res.data)
    })
  }

  const solvedRender = ({ children }) => {
    let solved = returnedDetailsData?.data?.filter((x) => x.id === children)[0]
      .solved
    return (
      <>
        <s.Checkbox
          id={uuid()}
          onChange={() => {
            solveElement(
              returnedDetailsData?.data?.filter((x) => x.id === children)[0],
              options.wallet_setting_id
            ).then(() => getTransferDetails(0))
          }}
          checked={solved}
          label={solved ? 'Resolvido' : 'Pendente'}
        />
      </>
    )
  }

  React.useEffect(() => {
    if (options?.wallet_setting_id) {
      getTransferDetails(0)
    }
  }, [options])

  return (
    <s.Modal
      modalSubtitle="Devoluções"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <s.ModalContainer>
        <s.ExportButton
          title="pix_devolvidos"
          startDate={options.start_date}
          endDate={options.end_date}
          dataCallback={async () => {
            return returnedDetailsData?.data
          }}
        />
        <s.Summary>
          <s.Column>
            <s.Text type="headline" color="maincolor" bold>
              Origem
            </s.Text>
            <s.Text type="headline" color="maincolor">
              PSP
            </s.Text>
          </s.Column>
          <s.Column>
            <s.Text type="headline" color="maincolor" bold>
              Quantidade
            </s.Text>
            <s.Text type="headline" color="maincolor">
              {returnedDetailsData?.total}
            </s.Text>
          </s.Column>
        </s.Summary>

        <s.subHeader>Pendentes</s.subHeader>
        <s.Table
          customEmptyMessage="Não existem pagamentos Pendentes para este período"
          data={
            returnedDetailsData
              ? returnedDetailsData.data.filter((x) => !x.solved)
              : []
          }
          isLoading={false}
          columns={[
            { headerName: 'Código transação Pix', objectName: 'id' },
            {
              headerName: 'Valor',
              objectName: 'total_order',
              AltRender: RenderValue,
            },
            { headerName: 'Data/Hora Pago', objectName: 'created_at' },
            { headerName: 'Nome do Pagador', objectName: 'payer_name' },
            { headerName: 'CPF/CNPJ', objectName: 'payer_cpf_cnpj' },
            { headerName: 'Status', objectName: 'status' },
            {
              headerName: 'PSP Pagador',
              objectName: 'payer_financial_institute_name',
            },
            {
              headerName: 'Situação',
              objectName: 'id',
              AltRender: solvedRender,
            },
          ]}
        />
        <s.subHeader>Resolvidos</s.subHeader>
        <s.Table
          customEmptyMessage="Não existem pagamentos Resolvidos para este período"
          data={
            returnedDetailsData
              ? returnedDetailsData.data.filter((x) => x.solved)
              : []
          }
          isLoading={false}
          columns={[
            { headerName: 'Código transação Pix', objectName: 'id' },
            {
              headerName: 'Valor',
              objectName: 'total_order',
              AltRender: RenderValue,
            },
            { headerName: 'Data/Hora Pago', objectName: 'created_at' },
            { headerName: 'Nome do Pagador', objectName: 'payer_name' },
            { headerName: 'CPF/CNPJbb', objectName: 'payer_cpf_cnpj' },
            { headerName: 'Status', objectName: 'status' },
            {
              headerName: 'PSP Pagador',
              objectName: 'payer_financial_institute_name',
            },
            {
              headerName: 'Situação',
              objectName: 'id',
              AltRender: solvedRender,
            },
          ]}
        />
      </s.ModalContainer>
    </s.Modal>
  )
}

export default ReconciliationReturnedModal
