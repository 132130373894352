import { Types, PayloadInterface, SummaryDetailPayloadInferface } from './types'

export function GetSummaryList(
  payload: PayloadInterface
): {
  type: string
  payload: PayloadInterface
} {
  return {
    type: Types.GET_SUMMARY,
    payload,
  }
}

export function GetSummaryListIsLoading(): {
  type: string
} {
  return {
    type: Types.GET_SUMMARY_LOADING,
  }
}

export function GetSummaryListError(): {
  type: string
} {
  return {
    type: Types.GET_SUMMARY_ERROR,
  }
}

export function GetSummaryDetail(
  payload: SummaryDetailPayloadInferface
): {
  type: string
  payload: SummaryDetailPayloadInferface
} {
  return {
    type: Types.GET_SUMMARY_DETAIL,
    payload,
  }
}

export function GetSummaryDetailIsLoading(): {
  type: string
} {
  return {
    type: Types.GET_SUMMARY_DETAIL_LOADING,
  }
}

export function GetSummaryDetailError(): {
  type: string
} {
  return {
    type: Types.GET_SUMMARY_DETAIL_ERROR,
  }
}
