import React from 'react'
import { useDispatch } from 'react-redux'

import { get, baseURLSummaryTransactionLogs } from '../services/api'
import { useTypedSelector } from '../store/modules/rootState'
import {
  GetTransactionLogs,
  GetTransactionLogsIsLoading,
  GetTransactionLogsError,
} from 'store/modules/transacionLogs/actions'

import { TransactionLogsState } from 'store/modules/transacionLogs/types'

interface IUseGetTransactionLogs extends TransactionLogsState {
  loadTransactionLogs: (
    id: string,
    params?: {
      limit: string
      offset: string
    },
    signal?: AbortSignal
  ) => Promise<void>
}

const useGetTransactionLogs = (): IUseGetTransactionLogs => {
  const dispatch = useDispatch()

  const { transactions, isLoading, hasError } = useTypedSelector((store) => ({
    transactions: store.transactionLogs.transactions,
    isLoading: store.transactionLogs.isLoading,
    hasError: store.transactionLogs.hasError,
  }))

  const loadTransactionLogs = React.useCallback(
    async (id, params, signal): Promise<void> => {
      dispatch(GetTransactionLogsIsLoading())
      await get(`${baseURLSummaryTransactionLogs}/${id}/transaction-logs`, {
        params: params || '',
        signal: signal,
      })
        .then((response) => {
          dispatch(GetTransactionLogs(response.data))
        })
        .catch(() => {
          dispatch(GetTransactionLogsError())
        })
    },
    [dispatch]
  )

  return { transactions, hasError, isLoading, loadTransactionLogs }
}

export default useGetTransactionLogs
