import React, { useEffect, useState } from "react"
import * as s from './styles'

interface IProps {
  isOpen: string
  setCurrentStep: any
  handleClose: ()=> any
}


export const PrePaymentWelcome: React.FC<IProps> = ({ isOpen, handleClose, setCurrentStep })=> {
    return(
      <s.Modal 
        handleClose={handleClose}
        isOpen={(isOpen) ? true : false}
        modalSubtitle={"Antecipação"}>
        <s.Line/>
        <s.ModalContent>
          <s.TextParagraph>
            Agora ficou mais fácil antecipar pagamentos feitos no crédito.
         </s.TextParagraph>
          <s.TextParagraph>
            Faça uma simulação para verificar a taxa de antecipação disponível diretamente no Painel da Shipay.
          </s.TextParagraph>
           <s.TextParagraph>
             Depois prossiga para completar seu cadastro no FIDC.
           </s.TextParagraph>
           <s.IconWrapper>
            <s.Icon
              width={370}
              height={78}
              fill="maincolor"
              name='antecipatioIlustration'
            />
           </s.IconWrapper>
         </s.ModalContent>
         <s.Line/>
         <s.Button onClick={()=> setCurrentStep(2)}
           color="primary">
           <s.Text bold color="white">
             Iniciar Simulação
           </s.Text>
         </s.Button>
      </s.Modal>
    )
}



