import { parseJwt } from 'helpers/jwt'
import { UserSessionInfo } from 'store/modules/auth/types'

const CUSTOMER_CLAIMS = [
  'panel_pages_home',
  'panel_pages_pagamentos',
  'panel_pages_lojas',
  'panel_pages_lojas_<id>',
  'panel_pages_pix',
  'panel_pages_carteiras_painel',
]

export const getRouteClaims = (token: string) => {
  const parsedToken = parseJwt(token)
  if (!isCustomerUser(token))
    return parsedToken.user_claims
      .filter((x) => {
        if (parsedToken.identity.impersonate && x.includes('panel')) return x
        else {
          if (x.includes('panel') && !CUSTOMER_CLAIMS.includes(x)) return x
        }
      })
      .map((x) => x.replace('panel_pages_', ''))
  else return CUSTOMER_CLAIMS.map((x) => x.replace('panel_pages_', ''))
}
export const getRouteUserClaims = (currentUserSession: UserSessionInfo) => {
  if (!hasCustomerUserClaim(currentUserSession?.user_claims))
    return currentUserSession?.user_claims
      .filter((x) => {
        if (currentUserSession.impersonate && x.includes('panel')) return x
        else {
          if (x.includes('panel') && !CUSTOMER_CLAIMS.includes(x)) return x
        }
      })
      .map((x) => x.replace('panel_pages_', ''))
  else return CUSTOMER_CLAIMS.map((x) => x.replace('panel_pages_', ''))
}

export const isCustomerUser = (token: string) => {
  const parsedToken = parseJwt(token)
  if (parsedToken && parsedToken.user_claims.includes('panel_pages_admin'))
    return false
  return true
}

export const hasCustomerUserClaim = (user_claims: Array<string>) => {
  if (user_claims.length > 0 && user_claims.includes('panel_pages_admin'))
    return false
  return true
}

export const isImpersonated = (token: string) => {
  const parsedToken = parseJwt(token)
  if (parsedToken && parsedToken.identity.impersonate) return true
  return false
}

export const hasImpersonateClaim = (currentUserSession: UserSessionInfo) => {
  if (currentUserSession && currentUserSession.impersonate) return true
  return false
}

export const checkClaim = (token: string, claim: string) => {
  const parsedToken = parseJwt(token)
  return parsedToken.user_claims?.includes(claim) ? true : false
}

export const checkUserClaim = (
  user_claims: Array<string>,
  claim: string
): boolean => {
  return user_claims?.includes(claim) ? true : false
}
