import React from 'react'
import { PixSplitAccountList } from './Management/components/PixSplitAccountList'

import { ISplit } from './types'
import * as s from './styles'
import SplitFeeManager from './Management/components/SplitFeeManager'

const Management: React.FC<{ splitFee: ISplit }> = ({ splitFee }) => {
  return (
    <>
      <s.Container>
        <s.Text
          margin="0px 0px 30px 0px"
          color="maincolor"
          type="heading2"
          bold
        >
          Configurações Pix Qualquer Banco Itaú
        </s.Text>
        <PixSplitAccountList />
        <s.SplitFeeManagerCard width={1298}>
          <SplitFeeManager isCustomFee={false} splitFee={splitFee} />
        </s.SplitFeeManagerCard>
      </s.Container>
    </>
  )
}

export default Management
