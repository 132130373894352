import styled from "styled-components"
import TextCmp from 'components/Text'
import InputTextCmp from 'components/Inputs/Text'
import SelectCmp from 'components/Inputs/Select'
import ButtonCmp from 'components/Button'
import ButtonLinkCmp from 'components/Button/ButtonLink'
import InputTextPasswordCmp from 'components/Inputs/Text/Password'
import theme from "styles/theme"
import IconCmp from 'components/IconExporter'
import ModalCmp from "components/Modal"
import CheckboxCmp from 'components/Inputs/Checkbox'
import IconExporter from 'components/IconExporter'
import CardCmp from 'components/Card'
import LoadingCmp from 'components/Loading'

export const Modal = styled(ModalCmp)`
  > div > header > div > h5 {
    padding-left: 30px;
    color:  ${({ theme }) => theme.colors.graytheme6};
  }
`

export const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme2};
  position: absolute;
  left: 0;
  width: 100%;
  z-index: -1;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 370px;
  margin-top:20px;
`

export const Text = styled(TextCmp)`
`
export const Loading = styled(LoadingCmp)``


export const TextParagraph = styled(TextCmp)`
  display:block;
  margin:0 auto;
  max-width: 302px;
  font-weight: 500;
  size: 18px;
  padding-bottom: 20px;
`
export const EmailWrapper = styled.div`
  display:block;
  margin:0 auto;
  max-width: 302px;
  font-weight: 300;
  size: 18px;
`

export const Icon = styled(IconCmp)`
`

export const IconWrapper = styled.div`
  margin-bottom: 30px;
`

export const BackBtn = styled.div`
position: absolute;
top: 32px;
left: 20px;

:hover {
  cursor: pointer;
  color: ${({ theme }) => theme.colors.maincolor};
}
`

export const InputText = styled(InputTextCmp)`
`

export const InputGroup = styled.div`
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
  width: 302px;
`

export const Select = styled(SelectCmp)`
`

export const Button = styled(ButtonCmp)`
  display:block;
  margin:0 auto;
  margin-top: 40px;
  width: 302px;
  height: 40px;

  > span {
      text-transform: none;
  }
`

export const ButtonLink = styled.a`
  text-decoration: underline;
  color: ${(props) => props.theme.colors.maincolor}
`

export const ButtonIcon = styled(IconExporter)`
  margin-right: 8px;
`

export const List = styled.ul`
  margin: 20px;
  color: ${(props) => props.theme.colors.maincolor}
`

export const Item = styled.li`
  color: ${(props) => theme.colors[props.color]}
`

export const InputTextPassword = styled(InputTextPasswordCmp)``

export const ButtonGroup = styled.div`
  text-align: center;
  margin-top: 20px;

  Button:first-child {
    margin-right: 20px;
  }
`

export const Checkbox = styled(CheckboxCmp)`
  position: absolute;
  top:10px;
`

export const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 9fr;
  position:relative;

  // margin-bottom: 26px;
  // display: flex;
  // flex-direction: row;
  // padding-top: 30px;
  // width:302px;
  
  // > div {
  //   // :first-child {
  //   //   width: min-content;
  //   //   margin-bottom:15%;
  //   }
  //}
`
export const FormWrapper = styled.div`
  margin-top:35px;
  // margin-bottom: 30px;
`

export const ParagraphWrapper = styled.div`
    margin-top:20px;
    margin-bottom: 20px;
`

export const Card = styled(CardCmp)`
  display:block;
  margin:0 auto;
  max-width: 302px;  
  height: 46px;
  background-color: ${theme.colors.lightmaincolor3};
  margin-bottom: 20px;
`