import { Types, ActionInterface, EWalletWSSagaState } from './types'

const INITIAL_STATE: EWalletWSSagaState = {
  postResponse: null,
  postIsLoading: false,
  postHasError: false,
  postHasErrorMessage: null,
}

const pixWalletSettingsReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): EWalletWSSagaState => {
  switch (action.type) {
    case Types.POST_EWALLET_WALLET_SETTINGS_SAGA_LOADING:
      return {
        ...state,
        postIsLoading: true,
        postHasError: false,
        postHasErrorMessage: null,
      }
    case Types.POST_EWALLET_WALLET_SETTINGS_SAGA_RESPONSE:
      return {
        ...state,
        postResponse: action.response,
        postIsLoading: false,
        postHasError: false,
        postHasErrorMessage: '',
      }
    case Types.POST_EWALLET_WALLET_SETTINGS_SAGA_ERROR:
      return {
        ...state,
        postIsLoading: false,
        postHasError: true,
        postHasErrorMessage: action.message,
      }
    default:
      return state
  }
}

export default pixWalletSettingsReducer
