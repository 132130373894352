import React from 'react'

import { toast } from 'react-toastify'

import { Toast } from './styles'

export const showToast = ({ type, message }) => {
  switch (type) {
    case 'success':
      toast.success(message)
      break
    case 'warn':
      toast.warn(message)
      break
    case 'error':
      toast.error(message)
      break
    default:
      toast.info(message)
  }
}

export const showCustomErrorToast = (errors: Array<string>) => {

  if(errors.length > 1) {
    return (
      toast.error(
        <ul>
          {errors.map(error => (<li>{error}</li>))}
        </ul>
        ,
        {className: 'CustomToastError'}
      )
    ) 
  }
  
  return toast.error(errors[0])
}

export default function ToastAnimated() {
  return <Toast />
}
