import { Types } from './types'

export function UpdateForm<T>(updatedKeys: T) {
  return {
    type: Types.UPDATE_FORM,
    updatedKeys,
  }
}

export function ResetForm() {
  return {
    type: Types.RESET_FORM,
  }
}
