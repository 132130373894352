import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'store/modules/rootState'

import {
  PatchPixWS,
  PatchPixWSIsLoading,
  PatchPixWSError,
} from 'store/modules/pixWalletSettings/action'

import { PixWSState } from 'store/modules/pixWalletSettings/types'

import pixWSService, { PixWSInterface } from 'services/pixWalletSettings'

import { Partial } from 'helpers/partial'

interface UsePatchPixWS extends PixWSState {
  editPixWS: (id: string, ws: Partial<PixWSInterface> ) => Promise<void>,
}

export const usePatchPixWS = (): Partial<UsePatchPixWS> => {
  const dispatch = useDispatch()
  const { patchResponse, patchIsLoading, patchError } = useTypedSelector((store) => ({
    patchResponse: store.pixWS.patchResponse,
    patchIsLoading: store.pixWS.patchIsLoading,
    patchError: store.pixWS.patchError,
  }))

  const editPixWS = useCallback(async (id, ws): Promise<void> => {
    dispatch(PatchPixWSIsLoading())
    await pixWSService.editPixWS(id, ws)
      .then((response) => {
        dispatch(PatchPixWS(response.data))
      })
      .catch(() => {
        dispatch(PatchPixWSError())
      })
  }, [dispatch])

  return { patchResponse, patchIsLoading, patchError, editPixWS}
}
