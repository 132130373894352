import { removeValueFromLocalStorage } from 'helpers/localStorage'
import { removeCookieValue } from 'helpers/cookies'
import { createHashHistory } from 'history'

const logout = () => {
  const history = createHashHistory()
  // remove user from local storage to log user out
  //wipe everything except posprovider_str
  Object.keys(localStorage).forEach((x) => {
    if (x !== 'POSPROVIDER_STR') localStorage.removeItem(x)
  })

  removeValueFromLocalStorage('currentUserSession')
  removeValueFromLocalStorage('adminUserSession')
  removeCookieValue('auth_token')
  setTimeout(() => {
    history.push('/login')
  }, 2000)
}

export { logout }
