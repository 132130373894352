import styled, { css } from 'styled-components'


import IconCmp from 'components/IconExporter'
import LogoCmp from 'components/LogoExporter'


export const Icon = styled(IconCmp)``

export const NavbarContainer = styled.div`
  position: relative;
  background: rgba(255, 255, 255, 0.75);
  transition: width 0.8s;
  height: 100px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
  box-shadow: 0px 1px 4px #B3B3B3;

  > * {
    :first-child {
      width:10%;
    }
    :nth-child(2) {
      margin-left: 500px;
    }
  }
`

export const Div = styled.div`
  color: red;
  width:500px;
  height:100px;
`

export const LogoExporter = styled(LogoCmp)`
`

