import styled from 'styled-components'

import { Container as ContainerCmp } from 'styled-bootstrap-grid'
import TextCmp from 'components/Text'
import CardCmp from 'components/Card'
import IconCmp from 'components/IconExporter'
import ButtonCmp from 'components/Button'
import WalletCardCmp from 'components/Card/WalletDisplayCard'

import * as pixStyles from 'pages/Pix/styles'


export const Background = styled.div`
  background-image: url(${({ theme }) => theme.assets.wizardBackground.img});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
`

export const BackgroundFilter = styled.div`
background: rgba(255, 255, 255, ${({ theme }) => theme.assets.wizardBackground.filter});
backdrop-filter: blur(64px);
  min-height: 100vh;
`

export const WalletCard = styled(WalletCardCmp)``

export const Loading = styled(pixStyles.Loading)``

export const LoadingWrapper = styled(pixStyles.LoadingWrapper)``

export const PixSimplesCard = styled(pixStyles.PixSimplesCard)`
  height: 128px;
  width: 200px;

  > img {
    height: 65px;
    width: 100px;
  }
`

export const Container = styled(ContainerCmp)`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-height: (100vh);
  padding-bottom: 16px;
  padding-top: 160px;

  > div {
    margin-bottom: ${({ theme }) => theme.spacing(5)}px;
  }

  > p {
    margin-bottom: ${({ theme }) => theme.spacing(5)}px;
  }
`

export const TextWrapper = styled.div`
  width: 100%;
  margin-left: ${({ theme }) => theme.spacing(44)}px;

  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const Text = styled(TextCmp)``

export const SubSectionHeader = styled(TextCmp)`
  margin-top: 64px;
  margin-bottom: 20px;
`

export const Card = styled(CardCmp)`
  padding: 44px 64px 44px 64px;
  height: auto;
`

export const FooterText = styled(TextCmp)`
  margin-top: 40px;
  margin-bottom: 20px;
`

export const Icon = styled(IconCmp)``

export const Button = styled(ButtonCmp)`
  height: 56px;

  > span > svg {
    margin: 0 ${({ theme }) => theme.spacing(1)}px;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  margin: 0 -24px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`

export const Bottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > * {
    margin: 16px;
  }
`

export const Footer = styled.div`
  display: flex;
  padding: 0px ${({ theme }) => theme.spacing(2)}px;
  justify-content: space-between;
  width: 946px;
`

export const List = styled.ul``

export const ListElement = styled.li`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
  > {
    :nth-child(n) {
      margin-right: 5px;
    }
  }
  :last-child {
    margin-bottom: 0px;
  }
`

export const EmailWrapper = styled.div`
  padding-left: 19px;
`

export const IdContainer = styled.div`
  display: flex;
  white-space: nowrap;
  position: relative;
  color: ${({ theme }) => theme.colors.graytheme6};
  padding: ${({ theme }) => theme.spacing(1)}px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.graytheme1};
  align-items: center;
  width: 407px;
  max-height: 40px;
  overflow: hidden;

  > svg {
    margin-left: 8px;
  }
`
export const ButtonText = styled(TextCmp)`
  width: 95%;
`

export const CopyKeyButton = styled.div`
  color: ${({ theme }) => theme.colors.graytheme6};
  padding: ${({ theme }) => theme.spacing(1)}px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.lightmaincolor3};
  display: flex;
  align-items: center;
  width: 100px;
  height: 40px;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.lightmaincolor2};
    color: ${({ theme }) => theme.colors.maincolor};
  }

  :active {
    background-color: ${({ theme }) => theme.colors.lightmaincolor1};
  }

  > svg {
    margin-left: 8px;
  }
`

export const KeyContainer = styled.div`
  display: grid;
  grid-template-columns: 1.15fr 1fr;
  margin-bottom: 20px;
`

export const SubTextLabel = styled.div`
  margin-bottom: 20px;
`
