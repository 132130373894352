import { useState } from 'react'

import clientCertService from 'services/clientCert'

interface ICerts {
  description: string
  expire_date: string
  created_at: string
  id: string
  in_use: boolean
  name: string
  psp_provider: string
  status: string
}

interface IUseGetClientCerts {
  loadClientCerts: (params) => Promise<void>
  certs: { data: ICerts[]; count: number; offset: number; total: number }
  isLoading: boolean
}

export const useGetClientCerts = (): IUseGetClientCerts => {
  const [certs, setCerts] = useState({
    data: [],
    count: -1,
    offset: -1,
    total: -1,
  })
  const [isLoading, setIsLoading] = useState(false)

  const loadClientCerts = async (params) => {
    setIsLoading(true)
    const response = await clientCertService.getClientCerts(params)
    setCerts(response.data)
    setIsLoading(false)
  }

  return { certs, loadClientCerts, isLoading }
}
