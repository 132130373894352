import { css, keyframes } from 'styled-components'

export const cardAnimationBBIn = keyframes`
  to {
      transform: scale(1.05);
      background: linear-gradient(180deg, rgba(245, 216, 0, 0) 0%, #EEAF0C 100%), #F5D800;
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
  }
`

export const cardAnimationBBOut = keyframes`
  from {
      transform: scale(1.05);
      background: linear-gradient(180deg, rgba(245, 216, 0, 0) 0%, #EEAF0C 100%), #F5D800;
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15);
  }
  to {
      transform: scale(1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  }
`

export const cardAnimationBradescoIn = keyframes`
  to {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, rgba(184, 24, 120, 0) 0%, #B81878 100%), #F2003A;
  }
`

export const cardAnimationBradescoOut = keyframes`
  from {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, rgba(184, 24, 120, 0) 0%, #B81878 100%), #F2003A;
  }
  to {
      transform: scale(1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  }
`

export const cardAnimationSantanderIn = keyframes`
  to {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, rgba(247, 107, 28, 0) 0%, #F76B1C 100%), #D30202;
  }
`

export const cardAnimationSantanderOut = keyframes`
  from {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, rgba(247, 107, 28, 0) 0%, #F76B1C 100%), #D30202;
  }
  to {
      transform: scale(1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  }
`

export const cardAnimationMercadopagoIn = keyframes`
  to {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, rgba(60, 87, 205, 0) 27.08%, #1E3495 100%), #17BAEB;
  }
`

export const cardAnimationMercadopagoOut = keyframes`
  from {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, rgba(60, 87, 205, 0) 27.08%, #1E3495 100%), #17BAEB;
  }
  to {
      transform: scale(1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  }
`

export const cardAnimationItauIn = keyframes`
  to {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, #EDC701 0%, rgba(237, 111, 1, 0) 100%), #ED6F01;
  }
`

export const cardAnimationItauOut = keyframes`
  from {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, #EDC701 0%, rgba(237, 111, 1, 0) 100%), #ED6F01;
  }
  to {
      transform: scale(1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  }
`
export const cardAnimationSicrediIn = keyframes`
  to {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, #92E06C 0%, rgba(237, 111, 1, 0) 100%), #439C16;
  }
`

export const cardAnimationSicrediOut = keyframes`
  from {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, #92E06C 0%, rgba(237, 111, 1, 0) 100%), #439C16;
  }
  to {
      transform: scale(1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  }
`

export const cardAnimationPagseguroIn = keyframes`
  to {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, #C2E460 0%, rgba(194, 228, 96, 0) 100%), #00BC99;
  }
`

export const cardAnimationPagseguroOut = keyframes`
  from {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, #C2E460 0%, rgba(194, 228, 96, 0) 100%), #00BC99;
  }
  to {
      transform: scale(1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  }
`

export const cardAnimationPortobankIn = keyframes`
  to {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, #0FC5D0 0%, rgba(194, 228, 96, 0) 100%), #066A95;
  }
`

export const cardAnimationPortobankOut = keyframes`
  from {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, #0FC5D0 0%, rgba(194, 228, 96, 0) 100%), #066A95;
  }
  to {
      transform: scale(1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  }
`

export const cardAnimationShipayIn = keyframes`
  to {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15);
      0% {background-position: 0%}
      100% {background-position: 100%}
  }
`

export const cardAnimationAmeIn = keyframes` 
  to {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, #FA2B60 25.31%, rgba(250, 43, 96, 0) 100%), #320583;
  }
`

export const cardAnimationAmeOut = keyframes` 
  from {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, #FA2B60 25.31%, rgba(250, 43, 96, 0) 100%), #320583;
  }
  to {
      transform: scale(1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  }
`

export const cardAnimationTecBanIn = keyframes`
  to {
    transform: scale(1.05);
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15);
    background: linear-gradient(180deg, #B41214 0%, rgba(180, 18, 20, 0) 100%), #E31A12;
  }
`

export const cardAnimationTecBanOut = keyframes`
  from {
    transform: scale(1.05);
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15);
    background: linear-gradient(180deg, #B41214 0%, rgba(180, 18, 20, 0) 100%), #E31A12;
  }
  to {
      transform: scale(1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  }
`

export const cardAnimationCieloIn = keyframes`
  to {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, #00AEEF 25.31%, rgba(0, 174, 239, 0) 100%), #171D4C
  }
`

export const cardAnimationCieloOut = keyframes`
  from {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, #00AEEF 25.31%, rgba(0, 174, 239, 0) 100%), #171D4C
  }
  to {
      transform: scale(1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  }
`

export const cardAnimationPicpayIn = keyframes`
  to {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, #00F98E 0%, rgba(0, 195, 111, 0) 100%), #009B41;
  }
`

export const cardAnimationPicpayOut = keyframes`
  from {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, #00F98E 0%, rgba(0, 195, 111, 0) 100%), #009B41;
  }
  to {
      transform: scale(1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  }
`

export const cardAnimationFoxbitIn = keyframes`
  to {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, #fca25d 0%, rgba(0, 195, 111, 0) 100%), #f57f25;
  }
`

export const cardAnimationFoxbitOut = keyframes`
  from {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg,  #fca25d 0%, rgba(0, 195, 111, 0) 100%), #f57f25;
  }
  to {
      transform: scale(1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  }
`

export const cardAnimationCoinPaymentsIn = keyframes`
  to {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, #5e97ff 0%, rgba(0, 195, 111, 0) 100%), #0443b8;
  }
`

export const cardAnimationCoinPaymentsOut = keyframes`
  from {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg,  #5e97ff 0%, rgba(0, 195, 111, 0) 100%), #0443b8;
  }
  to {
      transform: scale(1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  }
`

export const cardAnimationNinetyninepayIn = keyframes`
  to {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg, #EBCB2F 0%, rgba(0, 195, 111, 0) 100%), #FFDE33;
  }
`

export const cardAnimationNinetyninepayOut = keyframes`
  from {
      transform: scale(1.05);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px 4px rgba(0, 0, 0, 0.15); 
      background: linear-gradient(180deg,  #EBCB2F 0%, rgba(0, 195, 111, 0) 100%), #FFDE33;
  }
  to {
      transform: scale(1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  }
`

export const iconAnimationIn = keyframes`
  to {
    transform: scale(1.1)
  }
`

export const iconAnimationOut = keyframes`
  from {
    transform: scale(1.1)
  }
  
  to {
    transform: scale(1)
  }
`

export const iconAnimationUp = keyframes`
  from {
      transform: translateY(0);
  }
  to {
      transform: translateY(-15px) scale(.75);
  }
`

export const iconAnimationDown = keyframes`
  from {
      transform: translateY(-15px) scale(.75);
  }
  to {
      transform: translateY(0);
  }
`

export const titleAnimationIn = keyframes`
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
`

export const titleAnimationOut = keyframes`
  to { 
      opacity: 0;
  }
`

export const handleWalletCardAnimation = (name: string) => {
  switch (name) {
    case 'tecban':
      return css`
        animation-name: ${cardAnimationTecBanOut};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        &:hover {
          animation-name: ${cardAnimationTecBanIn};
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
      `
    case 'ninetyninepay':
      return css`
        animation-name: ${cardAnimationNinetyninepayOut};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        &:hover {
          animation-name: ${cardAnimationNinetyninepayIn};
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
      `
    case 'santander':
      return css`
        animation-name: ${cardAnimationSantanderOut};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        &:hover {
          animation-name: ${cardAnimationSantanderIn};
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
      `
    case 'mercadopago':
      return css`
        animation-name: ${cardAnimationMercadopagoOut};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        &:hover {
          animation-name: ${cardAnimationMercadopagoIn};
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
      `
    case 'itau':
      return css`
        animation-name: ${cardAnimationItauOut};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        &:hover {
          animation-name: ${cardAnimationItauIn};
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
      `
    case 'sicredi':
      return css`
        animation-name: ${cardAnimationSicrediOut};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        &:hover {
          animation-name: ${cardAnimationSicrediIn};
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
      `
    case 'bradesco':
      return css`
        animation-name: ${cardAnimationBradescoOut};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        &:hover {
          animation-name: ${cardAnimationBradescoIn};
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
      `
    case 'portobank':
      return css`
        animation-name: ${cardAnimationPortobankOut};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        &:hover {
          animation-name: ${cardAnimationPortobankIn};
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
      `
    case 'pagseguro':
      return css`
        animation-name: ${cardAnimationPagseguroOut};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        &:hover {
          animation-name: ${cardAnimationPagseguroIn};
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
      `
    case 'bb':
      return css`
        animation-name: ${cardAnimationBBOut};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        &:hover {
          animation-name: ${cardAnimationBBIn};
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
      `
    case 'ame':
      return css`
        animation-name: ${cardAnimationAmeOut};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        &:hover {
          animation-name: ${cardAnimationAmeIn};
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
      `
    case 'picpay':
      return css`
        animation-name: ${cardAnimationPicpayOut};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        &:hover {
          animation-name: ${cardAnimationPicpayIn};
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
      `
    case 'cielo':
      return css`
        animation-name: ${cardAnimationCieloOut};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        &:hover {
          animation-name: ${cardAnimationCieloIn};
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
      `

    case 'coinpayments':
      return css`
        animation-name: ${cardAnimationCoinPaymentsOut};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        &:hover {
          animation-name: ${cardAnimationCoinPaymentsIn};
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
      `

    case 'foxbit':
      return css`
        animation-name: ${cardAnimationFoxbitOut};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        &:hover {
          animation-name: ${cardAnimationFoxbitIn};
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
      `
  }
}
