import React, { useEffect, useState } from "react"
import * as s from './styles'

interface IProps {
  isOpen: boolean
  setFilters: any
  handleClose: ()=> any
}


export const ConciliationTasksFilter: React.FC<IProps> = ({ isOpen, handleClose, setFilters })=> {
    
  const [selectedFilter, setSelectedFilter] = useState<any>('')
  const [selectedType, setSelectedType] = React.useState({
    id: 0,
    name: '',
  })

  const [typeOptions] = React.useState([
    {id: 1, text: "TbankS", onClick: () => {
      setSelectedType({ id: 1, name: 'TbankS' })
      _setFiltersForm({ type: 'tbanks'})
    }},
    {id: 2, text: "Original", onClick: () => {
      setSelectedType({ id: 2, name: 'Original' })
      _setFiltersForm({ type: 'original'})
    }}
  ])

  const [filtersForm, setFiltersForm] = useState({
    reconciliation_pid: '',
    executed_at: '',
    start_date: '',
    end_date: '',
    type: ''
  })

  const _setFiltersForm = (obj) => {
    setFiltersForm({ ...filtersForm, ...obj })
  }

  const renderFilters = () => {
    switch(selectedFilter.id){
      case 1:
        return <>
                <s.InputGroup>
                  <s.InputText
                    value={filtersForm.reconciliation_pid}
                    onChange={(e) => _setFiltersForm({ reconciliation_pid: e.target.value })}                    
                    label="Número PID">
                  </s.InputText>
                </s.InputGroup>
               </>
      case 2:
        return <>
                <s.InputGroup>
                    <s.Select
                    label="Tipo de conciliação"
                    options={typeOptions}
                    selected={selectedType}
                    />
                </s.InputGroup>
                <s.InputGroup>
                  <s.Datepicker
                    selected={filtersForm.executed_at}
                    onChange={(date: Date) => {
                      if (date) {
                        _setFiltersForm({ executed_at: date.toLocaleDateString('pt-BR')})
                      }
                    }}
                    placeholder="Data de execução"
                  />
                </s.InputGroup>
              </>
      case 3:
        return <> 
                <s.InputGroup>
                  <s.Select
                    label="Tipo de conciliação"
                    options={typeOptions}
                    selected={selectedType}
                    />
                </s.InputGroup>
                  <s.InputGroup>
                    <s.Datepicker
                      selected={filtersForm.start_date}
                      onChange={(date: Date) => {
                        if (date) {
                          _setFiltersForm({ start_date: date.toLocaleDateString('pt-BR')})
                        }
                      }}
                      placeholder="Data Inicial"
                    />
                  </s.InputGroup>
                  <s.InputGroup>
                    <s.Datepicker
                          selected={filtersForm.end_date}
                          onChange={(date: Date) => {
                            if (date) {
                              _setFiltersForm({ end_date: date.toLocaleDateString('pt-BR')})
                            }
                          }}
                          placeholder="Data Final"
                      />  
                  </s.InputGroup>
                </>
      }
      return
  }

  return(
    <s.Modal 
      handleClose={handleClose}
      isOpen={(isOpen) ? true : false}
      modalSubtitle={"Tasks de conciliação"}>
      <s.ModalContent>
        <s.Text>
          Selecione o tipo de filtro
        </s.Text>

        <s.Select
          label="filtrar por"
          options={[
            {
              id: 1,
              text: 'PID',
              onClick: () => {
                setSelectedFilter({ id: 1, name: 'PID' })
              },
            },
            {
              id: 2,
              text: 'Data de execução e tipo de conciliação',
              onClick: () => {
                setSelectedFilter({ id: 2, name: 'Data de execução e tipo de conciliação' })
              },
            },
            {
              id: 3,
              text: 'Período de execução e tipo de conciliação',
              onClick: () => {
                setSelectedFilter({ id: 3, name: 'Período de execução e tipo de conciliação' })
              },
            },
            {
              id: 4,
              text: 'Ultimas execuções',
              onClick: () => {
                setSelectedFilter({ id: 4, name: 'Ultimas execuções' })
              },
            },
          ]}
          selected={selectedFilter}
          />
          {renderFilters()}
        </s.ModalContent>
        <s.Line/>
        <s.Button onClick={() => {
            Object.keys(filtersForm).forEach((key) => {
              if (filtersForm[key] === '') {
                delete filtersForm[key];
              }
            })
            setFilters(filtersForm)
          }
        }
          color="primary">
          <s.Text bold color="white">
            Buscar execuções
          </s.Text>
        </s.Button>
    </s.Modal>
  )
}



