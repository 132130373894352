import React from 'react'
import { showToast } from 'components/Toast'

import { useNonInitialEffect } from 'hooks/utils'

import * as s from '../styles'
import { baseURLPixAnybankSendDocuments, postFormData } from 'services/api'

const INITIAL_STATE_ERRORS = {
  contractDocFile: false,
  idDocumentFile: false,
}

interface DocumentVerificationProps {
  companyDocumentNumber: string
  legalRepresentativeDocumentNumber: string
  nextStep: () => void
  previousStep: () => void
  callDocumentSubmissionState?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ]
  isLoadingState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  registrationIdState?: [string, React.Dispatch<React.SetStateAction<string>>]
}

const AnyBankItauDocumentsVerificationModal: React.FC<
  DocumentVerificationProps
> = ({
  companyDocumentNumber,
  legalRepresentativeDocumentNumber,
  nextStep,
  previousStep,
  callDocumentSubmissionState,
  isLoadingState,
  registrationIdState,
}) => {
  const [contractDocFile, setContractDocFile] = React.useState<File>()
  const [idDocumentFile, setIdDocumentFile] = React.useState<File>()
  const [callDocumentSubmission, setCallDocumentSubmission] =
    callDocumentSubmissionState
  const [isLoading, setIsLoading] = isLoadingState
  const [registrationId, setRegistrationId] = registrationIdState
  const [formError, setFormError] = React.useState(INITIAL_STATE_ERRORS)

  const validateDocuments = () => {
    if (contractDocFile && idDocumentFile) {
      submitDocuments()
    } else {
      setFormError({
        contractDocFile: !contractDocFile,
        idDocumentFile: !idDocumentFile,
      })
    }
  }

  React.useEffect(() => {
    if (callDocumentSubmission) {
      validateDocuments()
      setCallDocumentSubmission(false)
    }
  }, [callDocumentSubmission])

  useNonInitialEffect(() => {
    if (contractDocFile) {
      setFormError({ ...formError, contractDocFile: !contractDocFile })
    }
    if (idDocumentFile) {
      setFormError({ ...formError, idDocumentFile: !idDocumentFile })
    }
  }, [contractDocFile, idDocumentFile])

  async function submitDocuments() {
    setIsLoading(true)

    const documents = new FormData()
    documents.append(
      'legal_person_document',
      companyDocumentNumber.replace(/[^0-9]/g, '')
    )
    documents.append('legal_person_file', contractDocFile)
    documents.append(
      'natural_person_document',
      legalRepresentativeDocumentNumber.replace(/[^0-9]/g, '')
    )
    documents.append('natural_person_file', idDocumentFile)

    await postFormData(`${baseURLPixAnybankSendDocuments}`, {
      legal_person_document: documents.get('legal_person_document'),
      legal_person_file: documents.get('legal_person_file'),
      natural_person_document: documents.get('natural_person_document'),
      natural_person_file: documents.get('natural_person_file'),
    })
      .then((res) => {
        setIsLoading(false)
        setRegistrationId(res.data.registration_id)
        showToast({
          type: 'success',
          message: 'Usuário verificado com sucesso',
        })
        nextStep()
      })
      .catch((e) => {
        if (window['env']['name'] !== 'production') {
          setIsLoading(false)
          nextStep()
        }
        setIsLoading(false)
        showToast({
          type: 'error',
          message: JSON.parse(e.request.response)?.detail || 'Algo deu errado',
        })
      })
  }

  return (
    <>
      <s.Sections>
        <s.AnyBankItauDocumentsValidationWrapper>
          <s.Section>
            <s.AnyBankSubHeaderText>
              <s.Icon name="company" fill="transparent" />
              <s.Text bold type="headline" color="graytheme6">
                Empresa
              </s.Text>
            </s.AnyBankSubHeaderText>
            <s.SectionLine>
              <s.Text fontWeight={600} type="headline" color="graytheme6">
                CNPJ da empresa
              </s.Text>
              <s.InputText
                testId="CNPJ-test"
                width={260}
                maxLength={14}
                disabled
                value={companyDocumentNumber}
              />
            </s.SectionLine>
            <s.Text margin="32px 0 16px 0" color="graytheme6" type="heading4">
              Contrato social da empresa
            </s.Text>
            <s.UploadDocumentButtonArea
              key={`${contractDocFile}-upload-contract`}
              value={contractDocFile}
              handleChange={(e: File) => {
                setContractDocFile(e)
              }}
              alignFileRules="row"
              data-testid="upload-contract"
              allowedFormats={['pdf']}
              maxFileSize={8500000} // 8.5MB
              required={formError.contractDocFile}
              hasFileAlready={!!contractDocFile}
              clear={() => setContractDocFile(null)}
            />
          </s.Section>
          <s.Line />
          <s.Section>
            <s.AnyBankSubHeaderText>
              <s.Icon name="customer" fill="graytheme6" />
              <s.Text bold type="headline" color="graytheme6">
                Representante legal
              </s.Text>
            </s.AnyBankSubHeaderText>
            <s.SectionLine>
              <s.Text fontWeight={600} type="headline" color="graytheme6">
                CPF do representante
              </s.Text>
              <s.InputText
                testId="CPF-test"
                width={260}
                maxLength={14}
                disabled
                value={legalRepresentativeDocumentNumber}
              />
            </s.SectionLine>

            <s.Text margin="32px 0 16px 0" color="graytheme6" type="heading4">
              RG ou CNH com CPF (frente e verso)
            </s.Text>
            <s.UploadDocumentButtonArea
              key={`${idDocumentFile}-upload-document-id-number`}
              value={idDocumentFile}
              handleChange={(e: File) => {
                setIdDocumentFile(e)
              }}
              data-testid="upload-document-id-number"
              allowedFormats={['pdf', 'png', 'jpg', 'jpeg']}
              maxFileSize={1500000} // 1.5MB
              required={formError.idDocumentFile}
              hasFileAlready={!!idDocumentFile}
              clear={() => setIdDocumentFile(null)}
            />
            <s.Text
              margin="32px 0 0px 32px"
              color="graytheme6"
              type="paragraph"
            >
              <ul>
                <li>retire seu documento do plástico</li>
                <li>coloque-o em um ambiente iluminado</li>
                <li>tire uma foto focada de todo o documento</li>
                <li>verifique se o texto está legível</li>
              </ul>
            </s.Text>
          </s.Section>
        </s.AnyBankItauDocumentsValidationWrapper>
      </s.Sections>
    </>
  )
}

export default AnyBankItauDocumentsVerificationModal
