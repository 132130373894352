import { dict } from 'components/IconExporter'
import { showToast } from 'components/Toast'
import React from 'react'
import {
  bffv2URLGetWalletSettingAnybankConfig,
  bffv2URLGetWalletSettingConfig,
  get,
} from 'services/api'

interface Field {
  key: string
  friendlyName: string
  valueType: string
  inputType: string
  value?: string
  options?: Array<string>
  placeholder?: string
  validators?: string[]
  maxLength?: number
  info?: string
}

interface TextContent {
  value: string | string[]
  value_type: 'plain_text' | 'bullet_list'
}

interface InfoBoxItem {
  icon: string
  title: string
  content: TextContent[]
}

export interface SectionWelcome {
  identifier: string
  texts: {
    main_description: string
    info_box: InfoBoxItem[]
  }
}

export interface SectionForm {
  identifier?: string
  disclaimer?: TextContent
  id?: string
  friendlyName: string
  toggled: boolean
  icon?: keyof typeof dict
  toggleButton?: {
    active: {
      friendlyName: string
      icon: keyof typeof dict
    }
    inactive: {
      friendlyName: string
      icon: keyof typeof dict
    }
  }
  fields: Array<Field>
}

export interface ModalConfig {
  helper_link?: string
  sections: Array<SectionForm | SectionWelcome>
}

interface IUseGetWalletSettingsModalConfig {
  modalConfig: ModalConfig
  setModalConfig: React.Dispatch<React.SetStateAction<any>>
  isLoading: boolean
  hasError: boolean
  hasSuccess: boolean
  getModalConfig: (pspProvider) => void
}

const useGetWalletSettingsAnybankModalConfig =
  (): IUseGetWalletSettingsModalConfig => {
    const [modalConfig, setModalConfig] = React.useState<ModalConfig>({
      sections: [],
    })
    const [isLoading, setIsLoading] = React.useState(false)
    const [hasError, setHasError] = React.useState(false)
    const [hasSuccess, setHasSuccess] = React.useState(false)

    const getModalConfig = async (pspProvider: string) => {
      setIsLoading(true)
      await get(`${bffv2URLGetWalletSettingAnybankConfig}/${pspProvider}`)
        .then((response) => {
          setHasError(false)
          let modalConfig = {
            ...response.data,
            sections: response.data.sections.filter((section) => section),
          }
          setModalConfig(modalConfig)
          setHasSuccess(true)
        })
        .catch((e) => {
          console.log(e)
          showToast({
            type: 'error',
            message: JSON.parse(e.request?.response).message,
          })
          setHasError(true)
          setModalConfig({ sections: [] })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    return {
      modalConfig,
      setModalConfig,
      hasError,
      isLoading,
      getModalConfig,
      hasSuccess,
    }
  }

export default useGetWalletSettingsAnybankModalConfig
