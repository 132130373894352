import React from 'react'

import { reconciliationFormatMoney } from 'helpers/masks'
import { baseURLReconciliation, get } from 'services/api'

import * as s from './styles'

interface IProps {
  isOpen: boolean
  handleClose: () => any
  totalValue: string
  options: {
    start_date: string
    end_date: string
    wallet_setting_id: string
    consolidated_customer_value: string
  }
  countRegisters: number
  countRepetitions: number
}

interface response {
  total: number
  data: any
  totalValue: string
}

const RenderValue = ({ children }) => {
  return <s.Text>{reconciliationFormatMoney(children)}</s.Text>
}

export const getDuplicatedDetails = async (
  offset: number,
  options,
  setDuplicatedDetailsData
) => {
  await get(`${baseURLReconciliation}/duplicated/details`, {
    params: { ...options, limit: 100, offset: offset },
  }).then((res) => {
    setDuplicatedDetailsData(res.data)
  })
}

const ReconciliationDuplicatedModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  options,
  totalValue,
  countRegisters,
  countRepetitions,
}) => {
  const [
    duplicatedDetailsData,
    setDuplicatedDetailsData,
  ] = React.useState<response>()
  const [offset, setOffset] = React.useState(0)

  const getDuplicatedDetailsRecursive = async function (offset) {
    await getDuplicatedDetails(offset, options, setDuplicatedDetailsData)
    let data = duplicatedDetailsData.data
    data.forEach((element) => {
      element.solved = element.solved + ''
    })
    data = data.map(({ id, ...keepAttrs }) => keepAttrs)
    if (offset / 100 < Math.ceil(duplicatedDetailsData.total / 100) - 1) {
      return data.concat(await getDuplicatedDetailsRecursive(offset + 100))
    } else {
      return data
    }
  }

  React.useEffect(() => {
    if (options?.wallet_setting_id) {
      getDuplicatedDetails(offset, options, setDuplicatedDetailsData)
    }
  }, [options, offset])

  return (
    <s.Modal
      modalSubtitle="Pagamentos em Duplicidade "
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <s.ModalContainer>
        <s.ExportButton
          title="pagamentos_duplicados"
          startDate={options.start_date}
          endDate={options.end_date}
          dataCallback={getDuplicatedDetailsRecursive}
        />
        <s.Summary>
          <s.Column>
            <s.Text type="headline" color="maincolor" bold>
              Origem dos Registros
            </s.Text>
            <s.Text type="headline" color="maincolor">
              Shipay
            </s.Text>
          </s.Column>
          {countRegisters ? (
            <s.Column>
              <s.Text type="headline" color="maincolor" bold>
                Quantidade de Registros
              </s.Text>
              <s.Text
                data-testid="count-registers"
                type="headline"
                color="maincolor"
              >
                {countRegisters}
              </s.Text>
            </s.Column>
          ) : (
            <></>
          )}
          <s.Column>
            <s.Text type="headline" color="maincolor" bold>
              Origem dos Pagamentos
            </s.Text>
            <s.Text type="headline" color="maincolor">
              PSP
            </s.Text>
          </s.Column>
          {countRepetitions ? (
            <s.Column>
              <s.Text type="headline" color="maincolor" bold>
                Quantidade de Repetições
              </s.Text>
              <s.Text
                data-testid="count-repetitions"
                type="headline"
                color="maincolor"
              >
                {countRepetitions}
              </s.Text>
            </s.Column>
          ) : (
            <></>
          )}
          <s.Column>
            <s.Text type="headline" color="maincolor" bold>
              Valor Diferença Total
            </s.Text>
            <s.Text type="headline" data-testid="total-value" color="maincolor">
              {totalValue}
            </s.Text>
          </s.Column>
        </s.Summary>
        <s.Table
          data={duplicatedDetailsData ? duplicatedDetailsData.data : []}
          isLoading={false}
          columns={[
            { headerName: 'Código Transação Pix', objectName: 'txid' },
            { headerName: 'End to End ID', objectName: 'e2eid' },
            {
              headerName: 'Valor',
              objectName: 'total_order',
              AltRender: RenderValue,
            },
            { headerName: 'Data/Hora Pago', objectName: 'created_at' },
            { headerName: 'Nome do Pagador', objectName: 'payer_name' },
            { headerName: 'CPF/CNPJ', objectName: 'payer_cpf_cnpj' },
            { headerName: 'Status transação', objectName: 'status' },
            {
              headerName: 'PSP Pagador',
              objectName: 'payer_financial_institute_name',
            },
          ]}
        />
        <s.Pagination
          count={100}
          offset="0"
          total={duplicatedDetailsData?.total}
          handleChange={(offset) => {
            setOffset(offset)
          }}
        />
      </s.ModalContainer>
    </s.Modal>
  )
}

export default ReconciliationDuplicatedModal
