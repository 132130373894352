import { useState } from 'react'

import retailChainService from 'services/retailChain'

interface UseGetRetailChainsByPosProduct {
  loadRetailChains: (level: string) => Promise<void>
  retailChains: any[]
  isLoading: boolean
}

export const useGetRetailChainsByPosProduct = (): UseGetRetailChainsByPosProduct => {
  const [retailChains, setRetailChains] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const loadRetailChains = async (posProductId: string) => {
    setIsLoading(true)
    const response = await retailChainService.getRetailChainsByPosProduct(
      posProductId
    )
    setRetailChains(response.data)
    setIsLoading(false)
  }
  return { retailChains, isLoading, loadRetailChains }
}
