import React, { SetStateAction } from 'react'

import { v4 as uuid } from 'uuid'

import { useTranslation } from 'react-i18next'

import dict from 'components/IconExporter'

import { applyDateMask } from 'helpers/masks'

import {
  getToday,
  getThisWeek,
  getLast7Days,
  getThisMonth,
  getLast6Months,
  getThisYear,
} from 'helpers/date'

import * as s from './styles'

interface IColumn {
  headerName?: string
  headerIcon?: string
  objectName: string
  AltRender?: React.FC<{ children: string }>
  type?: string
  isFilterable?: boolean
  filterName?: string
  fromCollapseRow?: boolean
  range?: string[]
  setRange?: React.Dispatch<SetStateAction<string>>[]
}

export interface ISortedColumnState {
  sortedColumnName: string
  sortedColumnOrder: 'asc' | 'desc'
}

export interface IButtonConfig {
  onClick: (any) => void
  iconName?: keyof typeof dict
  ButtonRender?: React.FC
  actionName: string
}

interface IProps {
  columns: IColumn[]
  data: any
  actionButtonsConfig?: IButtonConfig[]
  onRowHandleClick?: (id: string) => void
  sortedColumnState?: [
    ISortedColumnState,
    React.Dispatch<SetStateAction<ISortedColumnState>>
  ]
  onHeaderClick?: (value: any) => void
  CollapseRender?: any
  isLoading?: boolean
  hasFilterButton?: boolean
  seeMoreButton?: boolean
  filterList?: any
  detailsIdState?: [string, React.Dispatch<SetStateAction<string>>]
  detailsExpirationDateState?: [string, React.Dispatch<SetStateAction<string>>]
  isModalOpenState?: [boolean, React.Dispatch<SetStateAction<boolean>>]
  setFilterList?: () => {}
  accordionState?: [boolean, React.Dispatch<SetStateAction<boolean>>]
  hasError?: boolean
  isHeaderSticky?: boolean
  customEmptyMessage?: string
  rowIdField?: string
}

const FilterDropdownRender = ({
  filterName,
  type,
  filterDropdown,
  setFilterDropdown,
  index,
  filterList,
  setFilterList,
  range = null,
  setRange = null,
}) => {
  const { t } = useTranslation()
  const [filterText, setFilterText] = React.useState('')
  const [newFilterList, setNewFilterList] = React.useState(filterList)
  const [minimumValue, setMinimumValue] = React.useState(
    range ? range[0] : undefined
  )
  const [maximumValue, setMaximumValue] = React.useState(
    range ? range[1] : undefined
  )

  const allChecked = newFilterList[index]
    ? newFilterList[index].every(
        ({ status, registers }) =>
          status === true ||
          registers?.every((register) => register.status === true)
      )
    : false

  return (
    <>
      {filterDropdown === index ? (
        <>
          <s.FilterOptions>
            <s.FilterList>
              {type === 'checkbox' ? (
                <>
                  <s.InputText
                    width={210}
                    suffix={<s.Icon name="search" />}
                    value={filterText}
                    onChange={(e) => {
                      setFilterText(e.target.value)
                    }}
                  />
                  <s.SelectAllCheckbox
                    id={uuid()}
                    checked={allChecked}
                    onChange={() => {
                      const filterAux = newFilterList
                      filterAux[index].forEach((filter) => {
                        filter.status = !allChecked ? true : false
                        if (filter.registers) {
                          filter.registers.forEach((register) => {
                            register.status = !allChecked ? true : false
                          })
                        }
                      })
                      setNewFilterList([...filterAux])
                    }}
                    label="Marcar todas"
                  />
                  {/* Regra especifica para caixas na tela de pagamentos */}
                  {filterName !== 'store_pos_name'
                    ? newFilterList[index]
                        .filter((filter) =>
                          filter.name
                            .toLowerCase()
                            .includes(filterText.toLowerCase())
                        )
                        .map((filter, i) => {
                          return (
                            <s.Checkbox
                              id={uuid()}
                              key={uuid()}
                              checked={filter.status}
                              onChange={() => {
                                const filterAux = newFilterList
                                filterAux[index].splice(i, 1, {
                                  name: filter.name,
                                  status: !filter.status,
                                })
                                setNewFilterList([...filterAux])
                              }}
                              label={t(filter.name)}
                            />
                          )
                        })
                    : newFilterList[index].map((filterStore, i) => {
                        return (
                          <React.Fragment key={uuid()}>
                            {filterStore.registers.map(
                              (register, registerIndex) => {
                                return (
                                  <s.Checkbox
                                    id={uuid()}
                                    key={uuid()}
                                    checked={register.status}
                                    onChange={() => {
                                      const filterAux = newFilterList
                                      filterAux[index][i].registers.splice(
                                        registerIndex,
                                        1,
                                        {
                                          name: register.name,
                                          status: !register.status,
                                        }
                                      )
                                      setNewFilterList([...filterAux])
                                    }}
                                    label={register.name}
                                  />
                                )
                              }
                            )}
                          </React.Fragment>
                        )
                      })}
                </>
              ) : (
                <s.OptionWrapper>
                  {newFilterList[index].map((filter, i) => {
                    return (
                      <s.RadioButton
                        id={`radio-button-${i}`}
                        name={`${filter.name}-table`}
                        key={uuid()}
                        value={filter.status}
                        checked={filter.status}
                        label={filter.name}
                        handleChange={() => {
                          const filterAux = newFilterList
                          filterAux[index].forEach(
                            (filter) => (filter.status = false)
                          )
                          filterAux[index][i].status = !filter.status
                          setNewFilterList([...filterAux])
                          if (type === 'date') {
                            switch (i) {
                              case 0:
                                setMinimumValue(getToday())
                                setMaximumValue(getToday())
                                break
                              case 1:
                                setMinimumValue(getLast7Days())
                                setMaximumValue(getToday())
                                break
                              case 2:
                                setMinimumValue(getThisMonth())
                                setMaximumValue(getToday())
                                break
                              case 3:
                                setMinimumValue(getLast6Months())
                                setMaximumValue(getToday())
                                break
                              case 4:
                                setMinimumValue(getThisYear())
                                setMaximumValue(getToday())
                                break
                            }
                          }
                          if (type === 'value') {
                            switch (i) {
                              case 0:
                                setMinimumValue('0')
                                setMaximumValue('10')
                                break
                              case 1:
                                setMinimumValue('10')
                                setMaximumValue('50')
                                break
                              case 2:
                                setMinimumValue('50')
                                setMaximumValue('200')
                                break
                              case 3:
                                setMinimumValue('200')
                                setMaximumValue('1000')
                                break
                              case 4:
                                setMinimumValue('1000')
                                setMaximumValue('')
                                break
                            }
                          }
                        }}
                      />
                    )
                  })}
                  {type === 'date' ? (
                    <>
                      <s.Datepicker
                        selected={minimumValue}
                        placeholder="Início"
                        onChange={(date: Date) => {
                          if (date) {
                            const filterAux = newFilterList
                            filterAux[index].forEach(
                              (filter) => (filter.status = false)
                            )
                            setNewFilterList([...filterAux])
                            setMinimumValue(date.toLocaleDateString('pt-BR'))
                          }
                        }}
                        type="square"
                      />
                      <s.Datepicker
                        selected={maximumValue}
                        onChange={(date: Date) => {
                          if (date) {
                            const filterAux = newFilterList
                            filterAux[index].forEach(
                              (filter) => (filter.status = false)
                            )
                            setNewFilterList([...filterAux])
                            setMaximumValue(date.toLocaleDateString('pt-BR'))
                          }
                        }}
                        placeholder="Final"
                        type="square"
                      />
                    </>
                  ) : (
                    <>
                      <s.MinimumValueInput
                        placeholder={type === 'date' ? 'Início' : 'Mínimo'}
                        key={`MinimumValueInputDropdown-${index}`}
                        value={minimumValue}
                        name="minimum-value"
                        maxLength={10}
                        onChange={(e) => {
                          var aux =
                            type === 'date'
                              ? applyDateMask(e.target.value)
                              : e.target.value.replace(/,/i, '.')
                          const filterAux = newFilterList
                          filterAux[index].forEach(
                            (filter) => (filter.status = false)
                          )
                          setNewFilterList([...filterAux])
                          setMinimumValue(aux)
                        }}
                      />
                      <s.MaximumValueInput
                        placeholder={type === 'date' ? 'Final' : 'Máximo'}
                        key={`MaximumValueInputDropdown-${index}`}
                        value={maximumValue}
                        name="maximum-value"
                        maxLength={10}
                        onChange={(e) => {
                          var aux =
                            type === 'date'
                              ? applyDateMask(e.target.value)
                              : e.target.value.replace(/,/i, '.')
                          setMaximumValue(aux)
                        }}
                      />
                    </>
                  )}
                </s.OptionWrapper>
              )}
              <s.ApplyButton
                onClick={() => {
                  if (setRange) {
                    setRange[0](minimumValue)
                    setRange[1](maximumValue)
                  } else {
                    setFilterList()
                  }
                }}
              >
                Aplicar
              </s.ApplyButton>
            </s.FilterList>
          </s.FilterOptions>
          <s.Overlay
            data-testid="select-overlay"
            onClick={() => {
              setFilterDropdown(-1)
            }}
          />
        </>
      ) : null}
    </>
  )
}

const HeaderButton = ({
  index,
  headerName,
  headerIcon,
  isFilterable,
  filterName,
  type = 'checkbox',
  range = null,
  setRange = null,
  setFilterList,
  filterList,
}) => {
  const [filterDropdown, setFilterDropdown] = React.useState(-1)
  return (
    <>
      <s.HeaderButton
        isOpen={isFilterable && filterDropdown === index}
        onClick={() => {
          setFilterDropdown(index)
        }}
      >
        {headerIcon ? <s.Icon name={headerIcon} fill="maincolor" /> : null}
        <s.Text type="headline" color="maincolor">
          {headerName}
        </s.Text>
      </s.HeaderButton>
      <FilterDropdownRender
        index={index}
        type={type}
        range={range}
        key={range ? range[0] : filterName}
        setRange={setRange}
        filterName={filterName}
        setFilterList={setFilterList}
        filterList={filterList}
        filterDropdown={filterDropdown}
        setFilterDropdown={setFilterDropdown}
      />
    </>
  )
}

const ExpandRender = ({ index, row, openRow, CollapseRender, columns }) => {
  return (
    <s.TrExpandable
      key={`expandable-row-${index}`}
      isExpanded={openRow === index}
    >
      <s.TdExpandable isExpanded={openRow === index} colSpan={columns.length}>
        <s.ExpandableContainer>
          <CollapseRender data={row} />
        </s.ExpandableContainer>
      </s.TdExpandable>
    </s.TrExpandable>
  )
}

const TBodyTrRender = ({
  index,
  openRowState,
  row,
  CollapseRender,
  actionButtonsConfig,
  rowConfigs,
  seeMoreButton,
  expirationDates,
  setDetailsId,
  setDetailsExpirationDate,
  setIsModalOpen,
  columns,
  isModalOpen,
}) => {
  const [openRow, setOpenRow] = openRowState
  return (
    <React.Fragment key={uuid()}>
      <s.TBodyTr
        data-testid="tbody-tr"
        isCollapsable={!!CollapseRender}
        isExpanded={openRow === index}
        key={uuid()}
        onClick={() => {
          if (CollapseRender) {
            setOpenRow(openRow === index ? -1 : index)
          }
        }}
      >
        {row.map((item) => {
          const { value, AltRender, name, fromCollapseRow } = item
          return !fromCollapseRow ? (
            <s.Td data-testid="tbody-tr-td" key={uuid()}>
              {AltRender ? (
                <AltRender data-testid={name || ''} index={index}>
                  {value}
                </AltRender>
              ) : (
                <s.Text data-testid={name || ''}>{value}</s.Text>
              )}
            </s.Td>
          ) : null
        })}
        {actionButtonsConfig.length
          ? actionButtonsConfig.map(({ onClick, iconName, ButtonRender }) => {
              return (
                <s.ActionButtonsTd
                  key={uuid()}
                  onClick={(e) => {
                    e.stopPropagation()
                    onClick(rowConfigs[index].id)
                  }}
                >
                  {!!ButtonRender ? (
                    <ButtonRender />
                  ) : (
                    <s.ActionItem>
                      <s.Icon
                        name={iconName}
                        fill="maincolor"
                        width={21}
                        height={21}
                      />
                    </s.ActionItem>
                  )}
                </s.ActionButtonsTd>
              )
            })
          : null}
        {seeMoreButton ? (
          <s.Td>
            <s.seeMoreButton
              data-testid="tbody-tr-td-seemore"
              onClick={(e) => {
                e.stopPropagation()
                if (expirationDates.length) {
                  setDetailsId(rowConfigs[index].id)
                  setDetailsExpirationDate(expirationDates[index])
                }
                setDetailsId(rowConfigs[index].id)
                setIsModalOpen(!isModalOpen)
              }}
            >
              <s.Text bold color="maincolor">
                Ver mais
              </s.Text>
            </s.seeMoreButton>
          </s.Td>
        ) : null}
      </s.TBodyTr>
      {CollapseRender ? (
        <ExpandRender
          CollapseRender={CollapseRender}
          columns={columns}
          index={index}
          row={row}
          openRow={openRow}
        />
      ) : null}
    </React.Fragment>
  )
}

const TBodyRender = ({
  rows,
  hasError,
  isLoading,
  columns,
  customEmptyMessage,
  CollapseRender,
  actionButtonsConfig,
  expirationDates,
  isModalOpen,
  rowConfigs,
  seeMoreButton,
  setDetailsExpirationDate,
  setDetailsId,
  setIsModalOpen,
}) => {
  const [openRow, setOpenRow] = React.useState(-1)
  if (isLoading) {
    return (
      <s.TBody data-testid="table-tbody-loading">
        <s.Tr data-testid="table-tbody-tr-loading">
          <s.Td data-testid="table-tbody-tr-td">
            <s.LoadingWrapper>
              <s.Text
                data-testid="loading-text-table"
                color="maincolor"
                type="heading4"
              >
                Carregando...
              </s.Text>
              <s.Loading
                type="spinner"
                data-testid="loading-spinner"
                color="maincolor"
              />
            </s.LoadingWrapper>
          </s.Td>
        </s.Tr>
      </s.TBody>
    )
  }
  if (hasError) {
    return (
      <s.TBody data-testid="table-body-error">
        <s.Tr data-testid="table-body-tr-error">
          <s.Td data-testid="table-body-tr-td-error" colSpan={4}>
            <s.ErrorWrapper>
              <s.Text
                data-testid="error-text-table"
                color="maincolor"
                type="heading4"
              >
                Erro na API, tente novamente mais tarde
              </s.Text>
            </s.ErrorWrapper>
          </s.Td>
        </s.Tr>
      </s.TBody>
    )
  }

  if (rows.length === 0) {
    return (
      <s.TBody data-testid="table-body-empty">
        <s.Tr data-testid="table-body-tr-empty">
          <s.Td data-testid="table-body-tr-td-empty" colSpan={columns.length}>
            <s.ErrorWrapper>
              <s.Text
                data-testid="no-data-text-table"
                color="maincolor"
                type="heading4"
              >
                {customEmptyMessage
                  ? customEmptyMessage
                  : 'Parece que não há registros! Tente novamente'}
              </s.Text>
            </s.ErrorWrapper>
          </s.Td>
        </s.Tr>
      </s.TBody>
    )
  }

  return (
    <s.TBody data-testid="tbody-with-data">
      {rows.map((row, index) => {
        return (
          <TBodyTrRender
            columns={columns}
            CollapseRender={CollapseRender}
            actionButtonsConfig={actionButtonsConfig}
            expirationDates={expirationDates}
            isModalOpen={isModalOpen}
            rowConfigs={rowConfigs}
            seeMoreButton={seeMoreButton}
            setDetailsExpirationDate={setDetailsExpirationDate}
            setDetailsId={setDetailsId}
            setIsModalOpen={setIsModalOpen}
            index={index}
            openRowState={[openRow, setOpenRow]}
            row={row}
            key={uuid()}
          />
        )
      })}
    </s.TBody>
  )
}

const Table: React.FC<IProps> = ({
  columns,
  data,
  onRowHandleClick = () => {},
  onHeaderClick = () => {},
  actionButtonsConfig = [],
  sortedColumnState = [],
  CollapseRender = null,
  hasFilterButton = false,
  seeMoreButton = false,
  accordionState = [],
  isLoading = false,
  filterList = [],
  isHeaderSticky = false,
  detailsIdState = [],
  detailsExpirationDateState = [],
  isModalOpenState = [],
  hasError = false,
  setFilterList,
  customEmptyMessage = '',
  rowIdField = null,
  ...rest
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = accordionState
  const [isModalOpen, setIsModalOpen] = isModalOpenState
  const [, setDetailsId] = detailsIdState
  const [, setDetailsExpirationDate] = detailsExpirationDateState

  //Estruturando as linhas
  const rows = []
  const rowConfigs = []
  const expirationDates = []
  for (let entries of data) {
    const row = []
    for (let column of columns) {
      row.push({
        name: column.objectName,
        value: entries[column.objectName],
        fromCollapseRow: column.fromCollapseRow,
        AltRender: column.AltRender || undefined,
      })
    }
    if (rowIdField) {
      rowConfigs.push({ id: entries[rowIdField] })
    } else if ('id' in entries) {
      rowConfigs.push({ id: entries.id })
    } else if ('uuid' in entries) {
      rowConfigs.push({ id: entries.uuid })
    } else if ('retail_chain_id' in entries) {
      rowConfigs.push({ id: entries.retail_chain_id })
    }
    if ('expiration_date' in entries) {
      expirationDates.push(entries.expiration_date || null)
    }
    rows.push(row)
  }

  return (
    <>
      <s.Table data-testid={`table-component`} key={uuid()} {...rest}>
        <s.THead data-testid={`table-thead`} isSticky={isHeaderSticky}>
          <s.THeadTr data-testid={`table-thead-tr`}>
            {columns.map((column, index) => {
              const {
                headerName,
                headerIcon,
                isFilterable,
                filterName,
                type,
                range,
                setRange,
              } = column
              return headerName ? (
                <s.Th data-testid={`table-thead-tr-th-${index}`} key={uuid()}>
                  {isFilterable ? (
                    <HeaderButton
                      index={index}
                      type={type}
                      isFilterable={isFilterable}
                      headerName={headerName}
                      headerIcon={headerIcon}
                      filterName={filterName}
                      range={range}
                      setRange={setRange}
                      filterList={filterList}
                      setFilterList={setFilterList}
                    />
                  ) : (
                    <s.Header>
                      {headerIcon ? (
                        <s.Icon
                          name={headerIcon as keyof typeof dict}
                          fill="maincolor"
                        />
                      ) : null}
                      <s.Text
                        type="headline"
                        color="maincolor"
                        onClick={() => onHeaderClick(headerName)}
                      >
                        {headerName}
                      </s.Text>
                    </s.Header>
                  )}
                </s.Th>
              ) : null
            })}
            {actionButtonsConfig.length
              ? actionButtonsConfig.map((button) => {
                  return <s.Th key={uuid()}>{button.actionName}</s.Th>
                })
              : null}
            {hasFilterButton ? (
              <s.Th>
                <s.FilterWrapper>
                  <s.FilterButton
                    data-testid="open-filter-btn"
                    onClick={() => {
                      setIsAccordionOpen(!isAccordionOpen)
                    }}
                  >
                    <s.Icon
                      name="options"
                      fill="maincolor"
                      height={18}
                      width={20}
                    />
                    <s.Text type="headline" bold>
                      Filtrar
                    </s.Text>
                  </s.FilterButton>
                </s.FilterWrapper>
              </s.Th>
            ) : null}
            {seeMoreButton && !hasFilterButton ? <s.Th></s.Th> : null}
          </s.THeadTr>
        </s.THead>
        <TBodyRender
          CollapseRender={CollapseRender}
          actionButtonsConfig={actionButtonsConfig}
          expirationDates={expirationDates}
          isModalOpen={isModalOpen}
          rowConfigs={rowConfigs}
          seeMoreButton={seeMoreButton}
          setDetailsExpirationDate={setDetailsExpirationDate}
          setDetailsId={setDetailsId}
          setIsModalOpen={setIsModalOpen}
          columns={columns}
          customEmptyMessage={customEmptyMessage}
          hasError={hasError}
          isLoading={isLoading}
          rows={rows}
        />
      </s.Table>
    </>
  )
}

export default Table
