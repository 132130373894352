import React from 'react'
import { useDispatch } from 'react-redux'

import { showToast } from 'components/Toast'

import { useTranslation } from 'react-i18next'

import { get, patch, post, baseURLWalletsSettings } from '../services/api'
import { useTypedSelector } from '../store/modules/rootState'
import {
  GetWalletsSettingsList,
  GetWalletsSettingsListIsLoading,
  GetWalletsSettingsListError,
  GetWalletSetting,
  GetWalletSettingIsLoading,
  GetWalletSettingError,
  PatchWalletSettingsRequest,
  PatchWalletSettingsResponse,
  PatchWalletSettingsError,
  PostWalletSettingResponse,
  PostWalletSettingError,
  PostWalletSettingLoading,
} from 'store/modules/walletSettings/action'

import { WalletSettingsState } from 'store/modules/walletSettings/types'

interface IUseWalletsSettings extends WalletSettingsState {
  loadWalletsSettings: (params?: {
    limit: string
    offset: string
    filters?: string
  }) => Promise<void>
  patchWalletSettings: (id: string, payload: any) => Promise<string>
  loadWalletSetting: (id: string) => Promise<void>
  postWalletSetting: (id: string, payload: any) => Promise<string>
}

const useWalletsSettings = (): IUseWalletsSettings => {
  const dispatch = useDispatch()
  const {
    walletSettings,
    isLoading,
    hasError,
    patchHasErrorMessage,
    patchHasError,
    patchIsLoading,
    patchResponse,
    walletSetting,
    walletSettingIsLoading,
    walletSettingHasError,
    postResponse,
    postIsLoading,
    postHasError,
    postHasErrorMessage,
  } = useTypedSelector((store) => ({
    walletSettings: store.walletSettings.walletSettings,
    isLoading: store.walletSettings.isLoading,
    hasError: store.walletSettings.hasError,
    patchHasErrorMessage: store.walletSettings.patchHasErrorMessage,
    patchHasError: store.walletSettings.patchHasError,
    patchIsLoading: store.walletSettings.patchIsLoading,
    patchResponse: store.walletSettings.patchResponse,
    walletSetting: store.walletSettings.walletSetting,
    walletSettingIsLoading: store.walletSettings.walletSettingIsLoading,
    walletSettingHasError: store.walletSettings.walletSettingHasError,
    postResponse: store.walletSettings.postResponse,
    postIsLoading: store.walletSettings.postIsLoading,
    postHasError: store.walletSettings.postHasError,
    postHasErrorMessage: store.walletSettings.postHasErrorMessage,
  }))
  const { t } = useTranslation()

  const loadWalletsSettings = React.useCallback(
    async (params): Promise<void> => {
      dispatch(GetWalletsSettingsListIsLoading())
      await get(`${baseURLWalletsSettings}/all`, {
        params: params,
      })
        .then((response) => {
          dispatch(GetWalletsSettingsList(response.data))
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: `${JSON.parse(e.request.response).message}`,
          })
          dispatch(GetWalletsSettingsListError())
        })
    },
    [dispatch]
  )

  const patchWalletSettings = React.useCallback(
    async (id, payload): Promise<string> => {
      let status = ''
      dispatch(PatchWalletSettingsRequest(payload))
      await patch(`${baseURLWalletsSettings}/${id}`, payload)
        .then((response) => {
          status = `${response.status}`
          dispatch(PatchWalletSettingsResponse(response.data))
          showToast({
            type: 'success',
            message: `${t('Wallet Updated')}`,
          })
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: `${t(JSON.parse(e.request.response).message)}`,
          })
          status = JSON.parse(e.request.response).code
          dispatch(
            PatchWalletSettingsError(JSON.parse(e.request.response).message)
          )
        })
      return status
    },
    [dispatch]
  )

  const loadWalletSetting = React.useCallback(
    async (id): Promise<void> => {
      dispatch(GetWalletSettingIsLoading())
      await get(`${baseURLWalletsSettings}/${id}`)
        .then((response) => {
          dispatch(GetWalletSetting(response.data))
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: `${JSON.parse(e.request.response).message}`,
          })
          dispatch(GetWalletSettingError())
        })
    },
    [dispatch]
  )

  const postWalletSetting = React.useCallback(
    async (id, payload): Promise<string> => {
      let status = ''
      dispatch(PostWalletSettingLoading())
      await post(`${baseURLWalletsSettings}/${id}`, payload)
        .then((response) => {
          showToast({
            type: 'success',
            message: 'Alteração realizada',
          })
          status = `${response.status}`
          dispatch(PostWalletSettingResponse(response.data))
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: `${JSON.parse(e.request.response).message}`,
          })
          status = JSON.parse(e.request.response).code
          dispatch(
            PostWalletSettingError(JSON.parse(e.request.response).message)
          )
        })

      return status
    },
    [dispatch]
  )

  return {
    walletSettings,
    hasError,
    isLoading,
    patchHasErrorMessage,
    patchHasError,
    patchIsLoading,
    patchResponse,
    walletSetting,
    walletSettingHasError,
    walletSettingIsLoading,
    postResponse,
    postIsLoading,
    postHasError,
    postHasErrorMessage,
    loadWalletsSettings,
    patchWalletSettings,
    loadWalletSetting,
    postWalletSetting,
  }
}

export default useWalletsSettings
