export const Types = {
  GET_STORES: 'GET_STORES',
  GET_STORES_LOADING: 'GET_STORES_LOADING',
  GET_STORES_ERROR: 'GET_STORES_ERROR',
}

export interface StoreInterface {
  active: boolean
  cnpj_cpf: string
  id: string
  name: string
  destination_account_id?: string
}

export interface PayloadInterface {
  count: number
  data: StoreInterface[]
  offset: number
  total: number
}

export interface ActionInterface {
  type: keyof typeof Types
  payload: PayloadInterface
}

export interface StoresState {
  stores: PayloadInterface
  isLoading: boolean
  hasError: boolean
}
