import { get, post, baseURLOrdersSummaryFiles } from 'services/api'

export const fileExtension = ['xlsx', 'csv', 'json'] as const
export const fileType = ['payments_file', 'sales_file'] as const
export const fileStatus = ['pending', 'running', 'error', 'done'] as const

export type FileExtension = typeof fileExtension[number]
export type FileType = typeof fileType[number]
export type FileStatus = typeof fileStatus[number]

export interface ApiListFileResponse {
    created_at: string
    start_date: string
    end_date: string
    extension: FileExtension
    file_name: string
    file_type: FileType
    part?: number
    status: FileStatus
}

export interface PaginatedResponse<T> {
    data: T[]
    offset: number
    total: number
    count: number
}

export type ApiListFileReference = PaginatedResponse<ApiListFileResponse>

export interface ApiGetFileUrlResponse {
    file_url: string
}

export interface ApiRequestFilePayload {
    format: FileExtension
    filters: string
    orderBy: string
    sort: string
}


export async function listFiles(limit: number, offset: number, type: FileType): Promise<ApiListFileReference> {
    const response = (
        await get<ApiListFileReference>(
            `${baseURLOrdersSummaryFiles}/sales`,
            {
                params: { limit: limit, offset: offset, file_type: type },
            }
        )
    )
    return response.data
}

export async function getFileUrl(type: FileType, file_name: string): Promise<ApiGetFileUrlResponse> {
    const [fileType] = type.split("_")
    return (await get<ApiGetFileUrlResponse>(`${baseURLOrdersSummaryFiles}/${fileType}/${file_name}`)).data
}

export async function requestFile(type: FileType, payload: ApiRequestFilePayload) {
    const _type = type.substring(0, type.indexOf("_"))
    return await post(`${baseURLOrdersSummaryFiles}/${_type}`, payload)
}