import React, { useEffect } from 'react'
import PixSimplesModalContainer from './PixSimplesModalContainer'
import WelcomeStep from './steps/WelcomeStep'
import CompanyForm from './steps/CompanyForm'
import LegalRepresentativeForm from './steps/LegalRepresentativeForm'
import { SuccessModal } from './steps/SuccessModal'
import useGetWalletSettingsAnybankModalConfig, {
  ModalConfig,
  SectionForm,
  SectionWelcome,
} from 'hooks/useGetWalletSettingAnybankModalConfig'

import DestinationAccount from './steps/DestinationAccount'
import { isNotEmpty } from 'helpers/validators'

import Loading from './Loading'
import {
  baseURLPixAnyBankCustomerRegistration,
  baseURLPixAnyBankCustomerValidation,
  post,
} from 'services/api'
import { showToast } from 'components/Toast'
import TokenForm from './steps/TokenForm'

import * as s from './styles'
interface IProps {
  isOpen: string
  handleClose: () => void
  isFrom: string
  fromStep?: number
}

export interface FormProps {
  destination_account: {
    bank_institution: string
    bank_branch: string
    bank_account: string
    document_number: string
  }
  company: {
    document_number: string
    company_name: string
    company_type: string
    income_invoicing: string
    address: {
      street: string
      number: string
      complementary: string
      neighborhood: string
      city: string
      state: string
      zip_code: string
    }
  }
  legal_representative: {
    document_number: string
    name: string
    mother_name: string
    birth_date: string
    phone: string
    email: string
  }
}

const intialFormError: FormProps = {
  destination_account: {
    bank_institution: '',
    bank_branch: '',
    bank_account: '',
    document_number: '',
  },
  company: {
    document_number: '',
    company_name: '',
    company_type: '',
    income_invoicing: '',
    address: {
      street: '',
      number: '',
      complementary: '',
      neighborhood: '',
      city: '',
      state: '',
      zip_code: '',
    },
  },
  legal_representative: {
    document_number: '',
    name: '',
    mother_name: '',
    birth_date: '',
    phone: '',
    email: '',
  },
}

const renderModal = (
  step: number,
  isFrom: string,
  modalConfig: ModalConfig,
  isLoading: boolean,
  registrationId: string | undefined,
  tokenState: [string, React.Dispatch<React.SetStateAction<string>>],
  setCanSendToken: React.Dispatch<React.SetStateAction<boolean>>,
  formState: [FormProps, React.Dispatch<React.SetStateAction<FormProps>>],
  formErrorsState: [FormProps, React.Dispatch<React.SetStateAction<FormProps>>],
  handleClose
) => {
  const [token, setToken] = tokenState
  const [form] = formState

  if (step === 0) {
    return (
      <WelcomeStep
        isLoading={isLoading}
        section={
          {
            ...modalConfig.sections.find(
              (section) => section.identifier === 'welcomeStep'
            ),
          } as SectionWelcome
        }
      />
    )
  }

  if (step === 1 && !isLoading) {
    return (
      <DestinationAccount
        section={
          {
            ...modalConfig.sections.find(
              (section) => section.identifier === 'bankDestinationAccount'
            ),
          } as SectionForm
        }
        formState={formState}
        formErrorState={formErrorsState}
      />
    )
  }

  if (step === 2 && !isLoading) {
    return (
      <CompanyForm pixFormState={formState} errorFormState={formErrorsState} />
    )
  }

  if (step === 3 && !isLoading) {
    return (
      <LegalRepresentativeForm
        pixFormState={formState}
        errorFormState={formErrorsState}
      />
    )
  }

  if (step === 4 && !isLoading) {
    return (
      <TokenForm
        legalRepresentativeName={form.legal_representative.name}
        registrationId={registrationId}
        cnpj={form.company.document_number}
        tokenFormState={[token, setToken]}
        canSendToken={(canSend) => {
          setCanSendToken(canSend)
        }}
      />
    )
  }
  if (step === 5 && !isLoading) {
    return <SuccessModal handleClose={handleClose} />
  }

  if ((step === 1 || step === 3 || step === 4) && isLoading) {
    return <Loading />
  }
}

const renderButtons = (
  step,
  setStep,
  previousStep,
  nextStep,
  isLoading,
  destinationAccountValidation,
  companyValidation,
  representantValidation,
  canSendToken,
  handleAnybankCustomerRegistration,
  handleClose
) => {
  if (step === 0) {
    return (
      <s.PreviousNextButtonContainer>
        <div style={{ height: '54px' }} />
        <s.RightButtonAnybank
          onClick={() => {
            nextStep(step, setStep)
          }}
          width="200px"
          height="40px"
          disabled={isLoading}
        >
          Começar Cadastro
          <s.Icon name="arrowright" fill="whiteshipay" />
        </s.RightButtonAnybank>
      </s.PreviousNextButtonContainer>
    )
  }
  if (step === 1 && !isLoading) {
    return (
      <s.PreviousNextButtonContainer>
        <s.LeftButtonAnybank
          onClick={() => {
            previousStep(step, setStep, handleClose)
          }}
          width="200px"
          height="40px"
          color="whitedetailed"
          disabled={isLoading}
        >
          <s.Icon name="arrowleft" fill="maincolor" />
          <s.Text margin="0 0 0 8px" fontWeight={600} color="graytheme6">
            Voltar
          </s.Text>
        </s.LeftButtonAnybank>
        <s.RightButtonAnybank
          onClick={() => {
            destinationAccountValidation()
          }}
          width="200px"
          height="40px"
          disabled={isLoading}
        >
          <s.Text margin="0 8px 0 0" fontWeight={600} color="white">
            Continuar
          </s.Text>
          <s.Icon name="arrowright" fill="whiteshipay" />
        </s.RightButtonAnybank>
      </s.PreviousNextButtonContainer>
    )
  }
  if (step === 2) {
    return (
      <s.PreviousNextButtonContainer>
        <s.LeftButtonAnybank
          onClick={() => {
            previousStep(step, setStep, handleClose)
          }}
          width="200px"
          height="40px"
          color="whitedetailed"
          disabled={isLoading}
        >
          <s.Icon name="arrowleft" fill="maincolor" />
          <s.Text margin="0 0 0 8px" fontWeight={600} color="graytheme6">
            Voltar
          </s.Text>
        </s.LeftButtonAnybank>
        <s.RightButtonAnybank
          onClick={() => {
            companyValidation()
          }}
          width="200px"
          height="40px"
          disabled={isLoading}
        >
          <s.Text margin="0 8px 0 0" fontWeight={600} color="white">
            Continuar
          </s.Text>
          <s.Icon name="arrowright" fill="whiteshipay" />
        </s.RightButtonAnybank>
      </s.PreviousNextButtonContainer>
    )
  }

  if (step === 3 && !isLoading) {
    return (
      <s.PreviousNextButtonContainer>
        <s.LeftButtonAnybank
          onClick={() => {
            previousStep(step, setStep, handleClose)
          }}
          width="200px"
          height="40px"
          color="whitedetailed"
          disabled={isLoading}
        >
          <s.Icon name="arrowleft" fill="maincolor" />
          <s.Text margin="0 0 0 8px" fontWeight={600} color="graytheme6">
            Voltar
          </s.Text>
        </s.LeftButtonAnybank>
        <s.RightButtonAnybank
          onClick={() => {
            representantValidation()
          }}
          width="200px"
          height="40px"
          disabled={isLoading}
        >
          <s.Text margin="0 8px 0 0" fontWeight={600} color="white">
            Continuar
          </s.Text>
          {isLoading ? (
            <s.Loading type="spinner" color="maincolor" />
          ) : (
            <s.Icon name="arrowright" fill="white" />
          )}
        </s.RightButtonAnybank>
      </s.PreviousNextButtonContainer>
    )
  }

  if (step === 4 && !isLoading) {
    return (
      <s.PreviousNextButtonContainer>
        <s.LeftButtonAnybank
          data-testid="back-button"
          onClick={() => {
            previousStep(step, setStep)
          }}
          width="100%"
          height="42px"
          color="whitedetailed"
        >
          <s.Icon name="arrowleft" fill="maincolor" />
          <s.Text color="graytheme8" type="headline">
            Voltar
          </s.Text>
        </s.LeftButtonAnybank>
        <s.RightButtonAnybank
          data-testid="verify-documents-button"
          width="100%"
          height="42px"
          disabled={isLoading || !canSendToken}
          onClick={() => {
            handleAnybankCustomerRegistration()
          }}
        >
          <s.Text margin="0 8px 0 0" color="white" type="headline">
            Enviar
          </s.Text>
          {isLoading ? <s.Loading type="spinner" color="maincolor" /> : null}
        </s.RightButtonAnybank>
      </s.PreviousNextButtonContainer>
    )
  }
  if (step === 5 && !isLoading) {
    return (
      <s.SuccessButtonWrapper>
        <s.PreviousNextButtonWrapper>
          <s.Button
            data-testid="finish-onboard-button"
            width="100%"
            height="42px"
            onClick={() => handleClose()}
          >
            <s.Text margin="0 8px 0 0" color="white" type="headline">
              Finalizar
            </s.Text>
          </s.Button>
        </s.PreviousNextButtonWrapper>
      </s.SuccessButtonWrapper>
    )
  }

  if ((step === 1 || step === 3 || step === 4) && isLoading) {
    return (
      <s.ButtonHiddenContainer>
        <div style={{ height: '42px' }} />
        <div style={{ height: '42px' }} />
      </s.ButtonHiddenContainer>
    )
  }
}

const WalletSettingsFormAnybank: React.FC<IProps> = ({
  isOpen,
  handleClose,
  isFrom,
  fromStep = 0,
}) => {
  const {
    modalConfig,
    setModalConfig,
    getModalConfig,
    hasError,
    hasSuccess,
    isLoading: isLoadingGetBff,
  } = useGetWalletSettingsAnybankModalConfig()
  const [step, setStep] = React.useState<number>(fromStep)
  const [registrationId, setRegistrationId] = React.useState('')
  const [token, setToken] = React.useState('')
  const [canSendToken, setCanSendToken] = React.useState(false)
  const [form, setForm] = React.useState<FormProps>(intialFormError)
  const [formErrors, setFormErrors] = React.useState<FormProps>(intialFormError)
  const [isLoadingPostRequest, setIsLoadingPostRequest] = React.useState(false)
  const isLoading = isLoadingGetBff || isLoadingPostRequest

  useEffect(() => {
    getModalConfig(isFrom)
  }, [])

  const previousStep = (step, setStep, handleClose) => {
    if (step === 0) {
      handleClose()
    } else if (step === 1 || step === 2 || step === 3 || step === 4) {
      setStep(step - 1)
    }
  }

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1)
  }

  const destinationAccountValidation = async () => {
    const err = { ...formErrors.destination_account }

    for (const [key, value] of Object.entries({
      ...form.destination_account,
    })) {
      if (!err[key]) err[key] = isNotEmpty(value) ? '' : 'Campo Obrigatório'
    }
    setFormErrors({
      ...formErrors,
      destination_account: {
        ...err,
      },
    })

    const errList = Object.values(err).filter((x) => x !== '')
    if (!errList || errList.length === 0) {
      setForm({
        ...form,
        company: {
          ...form.company,
          document_number: form.destination_account.document_number,
        },
      })
      nextStep()
    }
    return
  }

  const handleCompanyRegistration = async (payload) => {
    setIsLoadingPostRequest(true)
    await post(`${baseURLPixAnyBankCustomerValidation}`, payload)
      .then((response) => {
        setIsLoadingPostRequest(false)
        setRegistrationId(response.data.registration_id)
        nextStep()
      })
      .catch((e) => {
        if (window['env']['name'] !== 'production') {
          setIsLoadingPostRequest(false)
          nextStep()
        }
        setIsLoadingPostRequest(false)
        if (e.request.status === 503 || e.request.status === 500) {
          showToast({
            type: 'error',
            message: e.request.response || 'Algo deu errado',
          })
        }
        showToast({
          type: 'error',
          message: JSON.parse(e.request.response)?.detail || 'Algo deu errado',
        })
      })
  }

  const handleAnybankCustomerRegistration = async () => {
    setIsLoadingPostRequest(true)
    await post(`${baseURLPixAnyBankCustomerRegistration}`, {
      psp_provider: 'picpay_anybank',
      document: form.company.document_number,
      code: token,
      registration_id: registrationId,
    })
      .then((response) => {
        setIsLoadingPostRequest(false)
        nextStep()
      })
      .catch((e) => {
        if (window['env']['name'] !== 'production') {
          setIsLoadingPostRequest(false)
          nextStep()
        }
        setIsLoadingPostRequest(false)
        if (e.request.status === 503 || e.request.status === 500) {
          showToast({
            type: 'error',
            message: e.request.response || 'Algo deu errado',
          })
        }
        showToast({
          type: 'error',
          message: JSON.parse(e.request.response)?.detail || 'Algo deu errado',
        })
      })
  }

  const companyValidation = async () => {
    const err = { ...formErrors.company }
    const { address, ...requiredCompanyFields } = form.company
    const { complementary, ...requiredAddressFields } = form.company.address

    for (const [key, value] of Object.entries({
      ...requiredCompanyFields,
      ...requiredAddressFields,
    })) {
      if (!err[key]) err[key] = isNotEmpty(value) ? '' : 'Campo Obrigatório'
    }

    setFormErrors({
      ...formErrors,
      company: { ...err, address: { ...err.address } },
    })

    delete err['address']

    const errList = Object.values(err).filter((x) => x !== '')
    if (!errList || errList.length === 0) {
      const companyInfo = {
        company: {
          document_number: form.company.document_number.replace(/[./-]+/g, ''),
          company_name: form.company.company_name,
          income_invoicing: String(
            parseFloat(
              form.company.income_invoicing
                .replace(/[R$.]/g, '')
                .trim()
                .replace(',', '.')
            )
          ),
          company_type: form.company.company_type,
          address: {
            street: form.company.address.street,
            number: form.company.address.number,
            complementary: form.company.address.complementary
              ? form.company.address.complementary
              : '',
            neighborhood: form.company.address.neighborhood,
            city: form.company.address.city,
            state: form.company.address.state,
            zip_code: form.company.address.zip_code,
          },
        },
      }

      setForm({
        ...form,
        company: {
          ...companyInfo.company,
        },
      })
      nextStep()
    }
    return
  }

  const representantValidation = async () => {
    const err = { ...formErrors.legal_representative }

    for (const [key, value] of Object.entries(form.legal_representative)) {
      if (!err[key]) err[key] = isNotEmpty(value) ? '' : 'Campo Obrigatório'
    }
    setFormErrors({
      ...formErrors,
      legal_representative: { ...err },
    })

    const errList = Object.values(err).filter((x) => x !== '')
    if (!errList || errList.length === 0) {
      const legalRepresentativeInfo = {
        legal_representative: {
          document_number: form.legal_representative.document_number.replace(
            /[./-]+/g,
            ''
          ),
          name: form.legal_representative.name,
          mother_name: form.legal_representative.mother_name,
          birth_date: form.legal_representative.birth_date.includes('/')
            ? form.legal_representative.birth_date
                .replaceAll('/', '-')
                .split('-')
                .reverse()
                .join('-')
            : form.legal_representative.birth_date,
          phone: form.legal_representative.phone,
          email: form.legal_representative.email,
        },
      }

      setForm({
        ...form,
        legal_representative: {
          ...legalRepresentativeInfo.legal_representative,
        },
      })

      const { company, legal_representative } = form

      const legalRepresentativePhoneNumber = legal_representative.phone.replace(
        /\D/g,
        ''
      )
      const payload = {
        psp_provider: 'picpay_anybank',
        ...form,
        destination_account: {
          bank: form.destination_account.bank_institution.match(/\d+/)[0],
          branch: form.destination_account.bank_branch.replace(/[^0-9]/g, ''),
          account: form.destination_account.bank_account.replace(/[^0-9]/g, ''),
        },
        company: {
          ...company,
          document_number: form.company.document_number.replace(/[./-]+/g, ''),
          income_invoicing: String(
            parseFloat(
              form.company.income_invoicing
                .replace(/[R$.]/g, '')
                .trim()
                .replace(',', '.')
            )
          ),
        },
        legal_representative: {
          ...legal_representative,
          document_number: form.legal_representative.document_number.replace(
            /[./-]+/g,
            ''
          ),
          birth_date: form.legal_representative.birth_date.includes('/')
            ? form.legal_representative.birth_date
                .replaceAll('/', '-')
                .split('-')
                .reverse()
                .join('-')
            : form.legal_representative.birth_date,
          phone_ddd: legalRepresentativePhoneNumber
            .replace(/\D/g, '')
            .substring(0, 2),
          phone_number: legalRepresentativePhoneNumber
            .replace(/\D/g, '')
            .substring(2),
        },
      }

      delete payload['company']['phone']
      delete payload['legal_representative']['phone']
      handleCompanyRegistration(payload)
    }
  }

  return (
    <>
      <PixSimplesModalContainer
        step={step}
        isFrom={isFrom}
        isOpen={isOpen}
        handleClose={handleClose}
      >
        {renderModal(
          step,
          isFrom,
          modalConfig,
          isLoading,
          registrationId,
          [token, setToken],
          setCanSendToken,
          [form, setForm],
          [formErrors, setFormErrors],
          handleClose
        )}
        {renderButtons(
          step,
          setStep,
          previousStep,
          nextStep,
          isLoading,
          destinationAccountValidation,
          companyValidation,
          representantValidation,
          canSendToken,
          handleAnybankCustomerRegistration,
          handleClose
        )}
      </PixSimplesModalContainer>
    </>
  )
}

export default WalletSettingsFormAnybank
