import styled, { css } from 'styled-components'
import IconCmp from 'components/IconExporter'
import TextCmp from 'components/Text'
import theme from 'styles/theme'

const inputErrorStyle = css`
  box-shadow: 0px 1px 8px 2px rgba(229, 92, 92, 0.25);
  color: ${(props) => props.theme.colors.lightred1};
`

export const InputGroupWrapper = styled.div<Omit<IGroup, 'hasLabel'>>`
  border-radius: 8px;
  ${(props) => props.theme.fontTypes.headline};
`

interface IInputWrapperProps {
  error?: boolean | { icon?: string; message: string }
  width?: string | number
  disabled?: boolean
  height?: number
}

export const InputWrapper = styled.div<IInputWrapperProps>`
  align-items: center;
  display: flex;
  border-radius: 8px;

  padding: ${({ theme }) => `${theme.spacing(1)}px`};
  transition: all 0.15s ease-in;

  #prefix {
    padding-left: ${({ theme }) => `${theme.spacing(1)}px`};
  }
  #suffix {
    padding-right: ${({ theme }) => `${theme.spacing(1)}px`};
  }
  ${({ error }) =>
    error
      ? css``
      : css`
          :focus-within {
            box-shadow: ${({ theme }) =>
              theme.assets.inputComponent.inputTextShadow};
            #prefix,
            #suffix {
              path {
                transition: all 0.15s ease-in;
                fill: ${(props) => props.theme.colors.maincolor};
              }
            }
          }
        `}
  box-shadow: ${({ disabled, theme }) =>
    disabled ? 'none' : theme.assets.inputComponent.inputTextShadow};
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.graytheme1 : theme.colors.white};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: ${(props) => props.width}px;
  height: ${({ height }) => `${height}px`};
  ${(props) => props.error && inputErrorStyle}

  position: relative;

  :active {
    box-shadow: 0px 1px 8px 2px #a3bacc;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`

export const InputLabel = styled.label`
  color: ${(props) => props.theme.colors.black};
  padding-left: ${({ theme }) => `${theme.spacing(2)}px`};
  padding-top: ${({ theme }) => `${theme.spacing(1)}px`};
`

export const InputAddon = styled.div`
  align-items: center;
  display: flex;
  font: inherit;
  height: 100%;
`

export const ErrorMessage = styled.span`
  bottom: 0;
  padding-top: 2px;
  display: flex;
  width: 100%;
`

export const ErrorIcon = styled(IconCmp)`
  fill: ${(props) => props.theme.colors.redshipay};
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`

export const Icon = styled(IconCmp)``

export const TextError = styled(TextCmp)``

export const Input = styled.input`
  border: none;
  color: ${({ theme }) => theme.colors.graytheme6};
  font: inherit;
  height: 100%;
  padding-left: 2px;
  width: 100%;

  :disabled {
    cursor: not-allowed;
    background-color: inherit;
    color: ${({ theme }) => theme.colors.black};
  }

  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.graytheme3};
    font-weight: normal;
    ${({ theme }) => theme.fontTypes.headline};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const InputArea = styled.textarea`
  border: none;
  color: ${({ theme }) => theme.colors.graytheme6};
  font: inherit;
  height: 100%;
  padding-left: 2px;
  width: 100%;

  :disabled {
    cursor: not-allowed;
    background-color: inherit;
    color: ${({ theme }) => theme.colors.black};
  }

  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.graytheme3};
    font-weight: normal;
    ${({ theme }) => theme.fontTypes.headline};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }
`

interface IGroup {
  disabled?: boolean
  hasLabel?: boolean
  height?: number
}

export const InputFieldGroup = styled.div<IGroup>`
  position: relative;
  height: ${({ hasLabel, height }) =>
    hasLabel ? `${height + 32}px` : `${height + 16}px`};
`

export const Text = styled(TextCmp)`
  margin-bottom: 8px;
`
