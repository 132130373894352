export const Types = {
  GET_SUMMARY: 'GET_SUMMARY',
  GET_SUMMARY_LOADING: 'GET_SUMMARY_LOADING',
  GET_SUMMARY_ERROR: 'GET_SUMMARY_ERROR',
  GET_SUMMARY_DETAIL: 'GET_SUMMARY_DETAIL',
  GET_SUMMARY_DETAIL_LOADING: 'GET_SUMMARY_DETAIL_LOADING',
  GET_SUMMARY_DETAIL_ERROR: 'GET_SUMMARY_DETAIL_ERROR',
}

export interface SummaryInterface {
  id: string
  created_at: string
  expiration_date: string
  payment_date: string
  status: string
  store_id: string
  store_name: string
  store_pos_id: string
  store_pos_name: string
  total_order: number
  updated_at: string
  friendly_name: string
  wallet_display_name: string
  wallet_id: number
  wallet_name: string
  wallet_payment_id: string
  wallet_icon: string
}

export interface PayloadInterface {
  count: number
  data: SummaryInterface[]
  offset: string
  total: number
}

export interface ActionInterface {
  type: keyof typeof Types
  payload: PayloadInterface
}

export interface SummaryDetailItemInterface {
  name: string
  quantity: number
  unit_price: number
}

interface SummaryDetailCalendarInterface {
  criacao: string
  dataDeVencimento: string
  validadeAposVencimento: number
}

interface SummaryDetailDebtorInterface {
  cpf: string
  email: string
  nome: string
}

interface SummaryDetailLocInterface {
  criacao: string
  id: number
  location: string
  tipoCob: string
}

interface SummaryDetailReceiverInterface {
  cep: string
  cidade: string
  cnpj: string
  logradouro: string
  nome: string
  nomeFantasia: string
  uf: string
}

interface SummaryDetailValueInterface {
  abatimento: { modalidade: string; valorPerc: string }
  desconto: {
    descontoDataFixa: { data: string; valorPerc: string }[]
    modalidade: string
  }
  juros: { modalidade: string; valorPerc: string }
  multa: { modalidade: string; valorPerc: string }
  original: string
}

export interface SummaryDetailWalletResponseInterface {
  calendario: SummaryDetailCalendarInterface
  chave: string
  devedor: SummaryDetailDebtorInterface
  loc: SummaryDetailLocInterface
  recebedor: SummaryDetailReceiverInterface
  revisao: number
  status: string
  txid: string
  valor: SummaryDetailValueInterface
}

export interface SummaryDetailTransactionInterface {
  created: string
  transaction_type: string
  wallet_response: SummaryDetailWalletResponseInterface
}
export interface SummaryDetailSplitsInterface {
  entity_type: string
  name?: string
  pix_dict_key?: string
  value?: number | string
  amount?: number | string
}

export interface SummaryDetailPayloadInferface {
  created_at: string
  expiration_date: string
  external_id: string
  items: SummaryDetailItemInterface[]
  order_id: string
  paid_amount: number
  payment_date: string
  pix_psp: string
  splits_fee?: {
    split_fee_type: string
    splits: SummaryDetailSplitsInterface[]
    value?: number
    fee_value?: number
  }
  status: string
  total_order: number
  transactions?: SummaryDetailTransactionInterface[]
  updated_at: string
  wallet: string
  wallet_payment_id: string
  balance: number
}

export interface SummaryDetailActionInterface {
  type: keyof typeof Types
  payload: SummaryDetailPayloadInferface
}

export interface SummaryState {
  summary: PayloadInterface
  isLoading: boolean
  hasError: boolean
  summaryDetail: SummaryDetailPayloadInferface
  summaryDetailIsLoading: boolean
  summaryDetailHasError: boolean
}
