import axios from 'axios'
import {
  baseURL,
  responseInterceptor,
  requestInterceptor,
  errorInterceptor,
} from './apiConfig'

const baseURLWalletsList = process.env.REACT_APP_BASE_URL_WALLETS || ''
const baseURLSummaryList = process.env.REACT_APP_BASE_URL_SUMMARY_ORDERS || ''
const baseURLSummaryDetail =
  process.env.REACT_APP_BASE_URL_SUMMARY_ORDERS_DETAIL || ''
const baseURLSystemWallets = process.env.REACT_APP_BASE_URL_SYSTEM_WALLETS || ''
const bffv2URLSystemWallets = process.env.REACT_APP_BFF_URL_SYSTEM_WALLETS || ''
const baseURLWalletsSettings =
  process.env.REACT_APP_BASE_URL_WALLET_SETTINGS || ''
const baseURLRegistrationStatusList =
  process.env.REACT_APP_BASE_URL_REGISTRATION_STATUS || ''
const baseURLStores = process.env.REACT_APP_BASE_URL_STORES || ''
const baseURLSummaryStoreDestinationAccounts =
  process.env.REACT_APP_BASE_URL_SUMMARY_STORE_DESTINATION_ACCOUNTS || ''
const baseURLUser = process.env.REACT_APP_BASE_URL_USER || ''
const baseURLNasaUser = process.env.REACT_APP_BASE_URL_NASA_USER || ''
const baseURLProfile = process.env.REACT_APP_BASE_URL_PROFILE || ''
const baseURLStore = process.env.REACT_APP_BASE_URL_STORE || ''
const baseURLGeoAddress = process.env.REACT_APP_BASE_URL_GEO_ADDRESS || ''
const baseURLCustomers = process.env.REACT_APP_BASE_URL_CUSTOMERS || ''
const baseURLBffCustomers = process.env.REACT_APP_BFF_URL_CUSTOMERS || ''
const baseURLBffPanelDestinationAccountTransactionData =
  process.env.REACT_APP_BFF_URL_PANEL_DESTINATION_ACCOUNT_TRANSACTION_DATA || ''
const baseURLBffPanelWalletSettings =
  process.env.REACT_APP_BFF_URL_PANEL_WALLET_SETTINGS || ''
const baseURLCustomer = process.env.REACT_APP_BASE_URL_CUSTOMER || ''
const baseURLWalletSettingsSaga =
  process.env.REACT_APP_BASE_URL_WALLET_SETTINGS_SAGA || ''
const baseURLAuthValidate = process.env.REACT_APP_BASE_URL_AUTH_VALIDATE || ''
const baseURLAuth = process.env.REACT_APP_BASE_URL_AUTH || ''
const baseURLResetPasswordEmail =
  process.env.REACT_APP_BASE_URL_RESET_PASSWORD_EMAIL || ''
const baseURLCheckSignature =
  process.env.REACT_APP_BASE_URL_CHECK_SIGNATURE || ''
const baseURLResetPassword = process.env.REACT_APP_BASE_URL_RESET_PASSWORD || ''
const baseURLImpersonate = process.env.REACT_APP_BASE_URL_IMPERSONATE || ''
const baseURLRetailChain = process.env.REACT_APP_BASE_URL_RETAIL_CHAIN || ''
const baseURLPosProduct = process.env.REACT_APP_BASE_URL_POS_PRODUCT || ''
const bffURLRole = process.env.REACT_APP_BFF_URL_ROLE || ''
const bffURLRedirectWizard = process.env.REACT_APP_BFF_URL_REDIRECT_WIZARD || ''

const bffv2URLGetWalletSettingAnybankConfig =
  process.env.REACT_APP_BFF_URL_WALLET_SETTINGS_ANYBANK_CONFIG || ''
const bffv2URLGetWalletSettingConfig =
  process.env.REACT_APP_BFF_URL_WALLET_SETTINGS_CONFIG || ''
const bffv2URLWalletSettings =
  process.env.REACT_APP_BFF_URL_WALLET_SETTINGS || ''
const bffv2URLBankSlipSettings =
  process.env.REACT_APP_BFF_URL_BANK_SLIP_SETTINGS || ''

const baseURLAnybankFees = process.env.REACT_APP_BASE_URL_ANYBANK_FEES || ''
const baseURLStorePosTypes =
  process.env.REACT_APP_BASE_URL_STORE_POS_TYPES || ''
const baseURLStorePos = process.env.REACT_APP_BASE_URL_STORE_POS || ''
const baseURLStorePosPerStoreID =
  process.env.REACT_APP_BASE_URL_STORE_POS_PER_STORE_ID || ''
const baseURLStoreWallets = process.env.REACT_APP_BASE_URL_STORE_WALLETS || ''
const baseURLPublishPubSagaStore =
  process.env.REACT_APP_BASE_URL_PUBLISH_PUB_SAGA_STORE || ''
const baseURLPixWalletSettings =
  process.env.REACT_APP_BASE_URL_PIX_WALLET_SETTINGS || ''
const baseURLClientCertificates =
  process.env.REACT_APP_BASE_URL_CLIENT_CERTIFICATES || ''
const baseURLClientCert =
  process.env.REACT_APP_BASE_URL_CLIENT_CERTIFICATE || ''
const baseURLPosVendor = process.env.REACT_APP_BASE_URL_POS_VENDOR || ''
const baseURLRegistrationPub =
  process.env.REACT_APP_BASE_URL_REGISTRATION_PUB || ''
const baseURLNasaCustomer = process.env.REACT_APP_BASE_URL_NASA_CUSTOMER || ''
const baseURLRegistrationPixAnyBank =
  process.env.REACT_APP_BASE_URL_REGISTRATION_PIX_ANY_BANK || ''
const baseURLRegistrationV1DestinationAccounts =
  process.env.REACT_APP_BASE_URL_REGISTRATION_DESTINATION_ACCOUNTS || ''
const baseURLRegistrationV2DestinationAccounts =
  process.env.REACT_APP_BASE_URL_REGISTRATION_V2_DESTINATION_ACCOUNTS || ''
const baseURLRegistrationV2DestinationAccountsWalletSettings =
  process.env
    .REACT_APP_BASE_URL_REGISTRATION_DESTINATION_ACCOUNTS_WALLET_SETTINGS || ''
const baseURLRegistrationV1SplitFee =
  process.env.REACT_APP_BASE_URL_REGISTRATION_SPLIT_FEE || ''
const baseURLRegistrationV1SplitFeeSimulator =
  process.env.REACT_APP_BASE_URL_REGISTRATION_SPLIT_FEE_SIMULATOR || ''
const baseURLRegistrationV1 =
  process.env.REACT_APP_BASE_URL_REGISTRATION_V1 || ''
const baseURLRegistrationSummarySplitFee =
  process.env.REACT_APP_BASE_URL_REGISTRATION_SUMMARY_SPLIT_FEE || ''
const baseURLRegistrationSummaryPosProductWlComponents =
  process.env
    .REACT_APP_BASE_URL_REGISTRATION_SUMMARY_POS_PRODUCT_WL_COMPONENTS || ''
const baseURLPosProductComponentsWlCustomImages =
  process.env.REACT_APP_BASE_URL_POS_PRODUCT_COMPONENTS_WL_CUSTOM_IMAGES || ''
const baseURLPosProductComponentsWlCustomPanel =
  process.env.REACT_APP_BASE_URL_POS_PRODUCT_COMPONENTS_WL_CUSTOM_PANEL || ''
const baseURLPosProductComponentsWlCustomEmail =
  process.env.REACT_APP_BASE_URL_POS_PRODUCT_COMPONENTS_WL_CUSTOM_EMAIL || ''
const baseURLPosProductComponentsWlCustomDomain =
  process.env.REACT_APP_BASE_URL_POS_PRODUCT_COMPONENTS_WL_CUSTOM_DOMAIN || ''
const baseURLPixAnyBankCustomerUserValidation =
  process.env.REACT_APP_BASE_URL_PIX_ANY_BANK_CUSTOMER_USER_VALIDATION || ''
const baseURLRegistrationPlatformCost =
  process.env.REACT_APP_BASE_URL_REGISTRATION_PLATFORM_COST || ''
const baseURLPixAnyBankCustomerValidation =
  process.env.REACT_APP_BASE_URL_PIX_ANY_BANK_CUSTOMER_VALIDATION || ''
const baseURLPixAnyBankCustomerAddress =
  process.env.REACT_APP_BASE_URL_PIX_ANY_BANK_CUSTOMER_ADDRESS || ''
const baseURLPixAnyBankCustomerRegistration =
  process.env.REACT_APP_BASE_URL_PIX_ANY_BANK_CUSTOMER_REGISTRATION || ''
const baseURLPixAnyBankResendToken =
  process.env.REACT_APP_BASE_URL_PIX_ANY_BANK_RESEND_TOKEN || ''
const baseURLPrepayments = process.env.REACT_APP_BASE_URL_PREPAYMENTS || ''
const baseURLLoadLogo = process.env.REACT_APP_BASE_URL_LOAD_LOGO || ''
const baseURLReconciliationFiles =
  process.env.REACT_APP_BASE_URL_RECONCILIATION_FILES || ''
const baseURLOrdersSummaryFiles =
  process.env.REACT_APP_BASE_URL_ORDERS_SUMMARY_FILES || ''
const baseURLSummaryTransactionLogs =
  process.env.REACT_APP_BASE_URL_SUMMARY_TRANSACTION_LOGS || ''
const baseURLReconciliationWorkflows =
  process.env.REACT_APP_BASE_URL_RECONCILIATION_WORKFLOWS || ''
const baseURLReconciliation =
  process.env.REACT_APP_BASE_URL_RECONCILIATION || ''
const baseURLRegistrationSummaryPosProductComponents =
  process.env.REACT_APP_BASE_URL_REGISTRATION_SUMMARY_POS_PRODUCT_COMPONENTS ||
  ''
const baseURLRegistrationPosProduct =
  process.env.REACT_APP_BASE_URL_REGISTRATION_POS_PRODUCT || ''
const baseURLPaymentsV1 = process.env.REACT_APP_BASE_URL_PAYMENTS_V1 || ''
const baseURLPaymentV1 = process.env.REACT_APP_BASE_URL_PAYMENT_V1 || ''
const baseURLVerifyUsersV1 =
  process.env.REACT_APP_BASE_URL_V1_VERIFY_USERS || ''
const baseURLVerifyUsersAnsweredV1 =
  process.env.REACT_APP_BASE_URL_V1_VERIFY_USERS_ANSWERED || ''
const baseURLPixAnybankOpenAccount =
  process.env.REACT_APP_BASE_URL_PIX_ANY_BANK_OPEN_ACCOUNT || ''
const baseURLPixAnybankSendDocuments =
  process.env.REACT_APP_BASE_URL_PIX_ANY_BANK_SEND_DOCUMENTS || ''
const baseURLBankSlipSettingsV1 =
  process.env.REACT_APP_BASE_URL_BANK_SLIP_SETTINGS_V1 || ''

export type PostPatchOrPut = 'POST' | 'PATCH' | 'PUT'

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'content-type': 'application/json',
  },
})

const axiosInstanceWithCredentials = axios.create({
  baseURL,
  headers: {
    'content-type': 'application/json',
  },
  withCredentials: true,
})

const axiosInstanceFormData = axios.create({
  baseURL,
  headers: {
    'content-type': 'multipart/form-data',
  },
})

const axiosInstanceLocalhostBFF = axios.create({
  baseURL: 'http://localhost:8080',
  headers: {
    'content-type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(requestInterceptor)
axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor)

axiosInstanceLocalhostBFF.interceptors.request.use(requestInterceptor)
axiosInstanceLocalhostBFF.interceptors.response.use(
  responseInterceptor,
  errorInterceptor
)

axiosInstanceWithCredentials.interceptors.request.use(requestInterceptor)
axiosInstanceWithCredentials.interceptors.response.use(
  responseInterceptor,
  errorInterceptor
)

axiosInstanceFormData.interceptors.request.use(requestInterceptor)
axiosInstanceFormData.interceptors.response.use(
  responseInterceptor,
  errorInterceptor
)

axiosInstanceWithCredentials.interceptors.request.use(requestInterceptor)
axiosInstanceWithCredentials.interceptors.response.use(
  responseInterceptor,
  errorInterceptor
)

const get = <ReturnPayload = any, Param = any>(path: string, params?: Param) =>
  axiosInstance<ReturnPayload>(path, params)

const post = <ReturnPayload = any, Data = any>(path: string, data: Data) =>
  axiosInstance<ReturnPayload>(path, { method: 'POST', data })

const put = <ReturnPayload = any, Data = any>(path: string, data: Data) =>
  axiosInstance<ReturnPayload>(path, { method: 'PUT', data })

const patch = <ReturnPayload = any, Data = any>(path: string, data: Data) =>
  axiosInstance<ReturnPayload>(path, { method: 'PATCH', data })

const remove = <ReturnPayload = any, Param = any>(
  path: string,
  params?: Param
) => axiosInstance<ReturnPayload>(path, { method: 'DELETE' })

const putFormData = (path, data) =>
  axiosInstanceFormData(path, { method: 'PUT', data })

const patchFormData = (path, data) =>
  axiosInstanceFormData(path, { method: 'PATCH', data })

const postFormData = (path, data) =>
  axiosInstanceFormData(path, { method: 'POST', data })

const sendFormData = (path, method: PostPatchOrPut, data) =>
  axiosInstanceFormData(path, { method: `${method}`, data })

const getWithCredentials = (path, params?) =>
  axiosInstanceWithCredentials(path, params)

const postWithCredentials = (path, data) =>
  axiosInstanceWithCredentials(path, { method: 'POST', data })

const putWithCredentials = (path, data) =>
  axiosInstanceWithCredentials(path, { method: 'PUT', data })

const patchWithCredentials = (path, data) =>
  axiosInstanceWithCredentials(path, { method: 'PATCH', data })

const removeWithCredentials = (path) =>
  axiosInstanceWithCredentials(path, { method: 'DELETE' })

const getFromLocalhostBFF = <ReturnPayload = any, Param = any>(
  path: string,
  params?: Param
) => axiosInstanceLocalhostBFF<ReturnPayload>(path, params)

const postFromLocalhostBFF = <ReturnPayload = any, Data = any>(
  path: string,
  data: Data
) => axiosInstanceLocalhostBFF<ReturnPayload>(path, { method: 'POST', data })

const putFromLocalhostBFF = <ReturnPayload = any, Data = any>(
  path: string,
  data: Data
) => axiosInstanceLocalhostBFF<ReturnPayload>(path, { method: 'PUT', data })

const patchFromLocalhostBFF = <ReturnPayload = any, Data = any>(
  path: string,
  data: Data
) => axiosInstanceLocalhostBFF<ReturnPayload>(path, { method: 'PATCH', data })

export {
  axiosInstance,
  get,
  put,
  patch,
  remove,
  post,
  getWithCredentials,
  postWithCredentials,
  putWithCredentials,
  patchWithCredentials,
  removeWithCredentials,
  putFormData,
  patchFormData,
  postFormData,
  sendFormData,
  getFromLocalhostBFF,
  putFromLocalhostBFF,
  patchFromLocalhostBFF,
  postFromLocalhostBFF,
  baseURLWalletsList,
  baseURLSummaryList,
  baseURLSummaryDetail,
  baseURLSystemWallets,
  baseURLWalletsSettings,
  baseURLRegistrationStatusList,
  baseURLStores,
  baseURLSummaryStoreDestinationAccounts,
  baseURLUser,
  baseURLNasaUser,
  baseURLProfile,
  baseURLStore,
  baseURLGeoAddress,
  baseURLCustomer,
  baseURLCustomers,
  baseURLBffCustomers,
  baseURLWalletSettingsSaga,
  baseURLAuthValidate,
  baseURLAuth,
  baseURLResetPasswordEmail,
  baseURLCheckSignature,
  baseURLResetPassword,
  baseURLImpersonate,
  bffv2URLSystemWallets,
  baseURLRetailChain,
  baseURLPosProduct,
  bffURLRole,
  bffURLRedirectWizard,
  baseURLBffPanelWalletSettings,
  baseURLBffPanelDestinationAccountTransactionData,
  baseURLStorePosTypes,
  baseURLStorePosPerStoreID,
  baseURLStoreWallets,
  baseURLStorePos,
  baseURLPublishPubSagaStore,
  baseURLPixWalletSettings,
  baseURLClientCert,
  baseURLClientCertificates,
  baseURLPosVendor,
  baseURLRegistrationPub,
  baseURLNasaCustomer,
  baseURLRegistrationPixAnyBank,
  baseURLRegistrationV1DestinationAccounts,
  baseURLRegistrationV2DestinationAccounts,
  baseURLRegistrationV2DestinationAccountsWalletSettings,
  baseURLRegistrationSummarySplitFee,
  baseURLRegistrationV1SplitFee,
  baseURLRegistrationSummaryPosProductWlComponents,
  baseURLPosProductComponentsWlCustomImages,
  baseURLPosProductComponentsWlCustomPanel,
  baseURLPosProductComponentsWlCustomEmail,
  baseURLPosProductComponentsWlCustomDomain,
  baseURLRegistrationV1,
  baseURLRegistrationPlatformCost,
  baseURLRegistrationV1SplitFeeSimulator,
  baseURLPixAnyBankCustomerUserValidation,
  baseURLPixAnyBankCustomerValidation,
  baseURLPixAnyBankCustomerAddress,
  baseURLPixAnyBankCustomerRegistration,
  baseURLPixAnyBankResendToken,
  baseURLPrepayments,
  baseURLLoadLogo,
  baseURLReconciliationFiles,
  baseURLOrdersSummaryFiles,
  baseURLAnybankFees,
  baseURLSummaryTransactionLogs,
  baseURLReconciliationWorkflows,
  baseURLReconciliation,
  baseURLRegistrationSummaryPosProductComponents,
  baseURLRegistrationPosProduct,
  baseURLPaymentsV1,
  baseURLPaymentV1,
  baseURLVerifyUsersV1,
  baseURLVerifyUsersAnsweredV1,
  baseURLPixAnybankOpenAccount,
  baseURLPixAnybankSendDocuments,
  baseURLBankSlipSettingsV1,
  bffv2URLGetWalletSettingConfig,
  bffv2URLGetWalletSettingAnybankConfig,
  bffv2URLWalletSettings,
  bffv2URLBankSlipSettings,
}
