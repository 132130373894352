import { applyCnpjMask } from 'helpers/masks'
import { formatPixKey } from 'helpers/pix'
import React from 'react'

import * as s from './styles'

interface IAccount {
  cpf_cnpj: string
  pix_dict_key: string
  name: string
}

interface IProps {
  account: IAccount
  setAccount: React.Dispatch<React.SetStateAction<IAccount>>
  firstStepValidationSaga: () => void
  checkIfAllInputsAreValid: () => boolean
  isLoading: boolean
  platformCostIsLoading: boolean
}

const DestinationAccountCard: React.FC<IProps> = ({
  account,
  setAccount,
  firstStepValidationSaga,
  checkIfAllInputsAreValid,
  isLoading,
  platformCostIsLoading,
}) => {
  return (
    <s.DestinationAccountCardWrapper>
      <s.DestinationAccountCard width={508} height={514}>
        <s.WrapperText>
          <s.Icon fill="maincolor" name="monitoroutline" />
          <s.Text bold color="maincolor" type="headline">
            Sua Conta de Recebimento
          </s.Text>
        </s.WrapperText>
        <s.Text color="graytheme6">
          Informe uma conta bancária da sua empresa de automação comercial para{' '}
          <b>recebimento das comissões</b> (overpricing) referentes às tarifas
          cobradas dos lojistas, usuários do seu sistema, por Pix liquidado.
        </s.Text>
        <s.InputText
          label="Chave Pix"
          value={account.pix_dict_key}
          onChange={(e) => {
            setAccount({
              ...account,
              pix_dict_key: formatPixKey(e.target.value),
            })
          }}
        />
        <s.InputText
          label="CNPJ da Conta Bancária"
          value={account.cpf_cnpj}
          placeholder="00.000.000/0000-00"
          maxLength={18}
          onChange={(e) =>
            setAccount({
              ...account,
              cpf_cnpj: applyCnpjMask(e.target.value),
            })
          }
        />
        <s.InputText
          label="Apelido da Conta"
          value={account.name}
          onChange={(e) => setAccount({ ...account, name: e.target.value })}
        />
      </s.DestinationAccountCard>
      <s.Button
        onClick={() => {
          firstStepValidationSaga()
        }}
        disabled={
          !checkIfAllInputsAreValid() || isLoading || platformCostIsLoading
        }
        width="508px"
        height="60px"
      >
        <s.Text bold color="white">
          Confirmar Configurações
        </s.Text>
        {isLoading ? (
          <s.Loading type="spinner" color="white" />
        ) : (
          <s.Icon name="arrowright" fill="white" />
        )}
      </s.Button>
    </s.DestinationAccountCardWrapper>
  )
}

export default DestinationAccountCard
