import React from 'react'

import { useGetClientCerts } from 'hooks/clientCert'
import useGetSystemWallets from 'hooks/useGetSystemWallets'
import { useNonInitialEffect } from 'hooks/utils'
import { SystemWalletBff } from 'hooks/useGetSystemWalletsFromBff'

import { NewCertificateModal } from './NewCertificateModal'

import * as s from './styles'

interface IProps {
  isOpen: boolean
  systemWallets: SystemWalletBff[]
  handleClose: () => any
}

export const PixCertificatesModal: React.FC<IProps> = ({
  isOpen,
  systemWallets,
  handleClose,
}) => {
  const [selectedPspProvider, setSelectedPspProvider] = React.useState<any>({})
  const [filteredSystemWallets, setFilteredSystemWallets] = React.useState<
    any[]
  >([])
  const [parsedCerts, setParsedCerts] = React.useState<any[]>([])
  const { certs, isLoading, loadClientCerts } = useGetClientCerts()
  const [createCertModal, setCreateCertModal] = React.useState<boolean>(false)
  const [offset, setOffset] = React.useState<string>('0')
  const paginationUpdateRef = React.useRef(false)

  useNonInitialEffect(() => {
    setFilteredSystemWallets([
      {
        id: '-1',
        text: 'Todos',
        onClick: () => {
          setSelectedPspProvider({
            id: '-1',
            name: '',
          })
          paginationUpdateRef.current = true
        },
      },
      ...systemWallets
        .filter((x) => {
          return x.active && x.wallet_type === 'pix'
        })
        .map((x) => {
          return {
            id: x.id,
            text: x.wallet_name,
            onClick: () => {
              setSelectedPspProvider({
                id: x.id,
                name: x.wallet_name,
              })
              paginationUpdateRef.current = true
            },
          }
        }),
    ])
  }, [systemWallets])

  useNonInitialEffect(() => {
    if (
      isOpen &&
      !createCertModal &&
      (!paginationUpdateRef.current || certs.total === 0)
    ) {
      loadClientCerts({
        limit: 10,
        offset,
        psp_provider: selectedPspProvider.name,
      })
    }
  }, [isOpen, createCertModal, offset, selectedPspProvider])

  useNonInitialEffect(() => {
    setParsedCerts(
      certs.data.map((x) => ({
        id: x.id,
        name: x.name,
        description: x.description,
        created_at: x.created_at,
        expire_date: x.expire_date,
        status:
          x.status === 'queued'
            ? 'Pendente'
            : x.status === 'check_failed'
            ? 'Falha na verificação'
            : x.status === 'ready'
            ? 'Pronto para uso'
            : x.status === 'expired'
            ? 'Expirado'
            : '',
        in_use: x.in_use ? 'sim' : 'não',
        psp_provider: x.psp_provider,
      }))
    )
  }, [certs])

  const renderPagination = () => {
    return (
      <>
        {certs.total > 0 ? (
          <>
            <s.Pagination
              count={10}
              offset={offset}
              total={certs.total}
              handleChange={(offset) => {
                setOffset(offset)
              }}
              paginationUpdateRef={paginationUpdateRef}
            />
          </>
        ) : null}
      </>
    )
  }

  if (certs && certs.total <= 0) {
    return (
      <s.Modal
        handleClose={() => {
          setSelectedPspProvider({ id: '-1', name: '' })
          paginationUpdateRef.current = true
          handleClose()
        }}
        isOpen={isOpen}
        modalSubtitle={'Certificados digitais'}
      >
        <s.ModalContainer>
          <s.SelectWrapper>
            <s.Text bold color="maincolor" type="headline">
              Provedor
            </s.Text>
            <s.Select
              options={filteredSystemWallets}
              selected={selectedPspProvider}
            />
          </s.SelectWrapper>
          <NewCertificateModal
            isOpen={createCertModal}
            handleClose={() => {
              setCreateCertModal(false)
            }}
          />
          {isLoading ? (
            <s.Loading type="spinner" color="maincolor" />
          ) : (
            <s.Text>Você ainda não tem nenhum ambiente configurado...</s.Text>
          )}
          <s.ButtonGroup>
            <s.Button
              onClick={() => {
                setCreateCertModal(true)
              }}
            >
              Novo certificado
            </s.Button>
          </s.ButtonGroup>
        </s.ModalContainer>
      </s.Modal>
    )
  } else {
    return (
      <s.Modal
        handleClose={() => {
          setSelectedPspProvider({ id: '-1', name: '' })
          paginationUpdateRef.current = true
          handleClose()
        }}
        isOpen={isOpen}
        modalSubtitle={`Certificados digitais: ${certs.total}`}
      >
        <NewCertificateModal
          isOpen={createCertModal}
          handleClose={() => setCreateCertModal(false)}
        />

        <s.ModalContainer>
          <s.SelectWrapper>
            <s.Text bold color="maincolor" type="headline">
              Provedor
            </s.Text>
            <s.Select
              options={filteredSystemWallets}
              selected={selectedPspProvider}
            />
          </s.SelectWrapper>
          {isLoading ? (
            <s.Loading type="spinner" color="maincolor" />
          ) : (
            <s.Table
              data={parsedCerts}
              columns={[
                {
                  headerName: 'id',
                  objectName: 'id',
                },
                {
                  headerName: 'Nome',
                  objectName: 'name',
                },
                {
                  headerName: 'Descrição',
                  objectName: 'description',
                },
                {
                  headerName: 'Criação',
                  objectName: 'created_at',
                },
                {
                  headerName: 'Expiração',
                  objectName: 'expire_date',
                },
                {
                  headerName: 'status',
                  objectName: 'status',
                },
                {
                  headerName: 'Em uso',
                  objectName: 'in_use',
                },
              ]}
            />
          )}
          {renderPagination()}
          <s.ButtonGroup>
            <s.Button
              onClick={() => {
                setCreateCertModal(true)
              }}
            >
              Novo certificado
            </s.Button>
          </s.ButtonGroup>
        </s.ModalContainer>
      </s.Modal>
    )
  }
}
