import React from 'react'
import { useDispatch } from 'react-redux'
import { showToast } from 'components/Toast'
import { post, baseURLWalletSettingsSaga } from '../services/api'
import { useTypedSelector } from '../store/modules/rootState'

import {
  PostEWalletLoading,
  PostEWalletResponse,
  PostEWalletError,
} from 'store/modules/walletSettings/ewallet/action'

import {
  EWalletWSSagaState,
  RequestInterface,
} from 'store/modules/walletSettings/ewallet/types'

interface IUseWalletSettingsSaga extends EWalletWSSagaState {
  postEWalletWalletSettingsSaga: (payload: RequestInterface) => Promise<void>
}

const useWalletSettingsSaga = (): IUseWalletSettingsSaga => {
  const dispatch = useDispatch()

  const {
    postResponse,
    postIsLoading,
    postHasError,
    postHasErrorMessage,
  } = useTypedSelector((store) => ({
    postResponse: store.eWalletWSSaga.postResponse,
    postIsLoading: store.eWalletWSSaga.postIsLoading,
    postHasError: store.eWalletWSSaga.postHasError,
    postHasErrorMessage: store.eWalletWSSaga.postHasErrorMessage,
  }))

  const postEWalletWalletSettingsSaga = React.useCallback(
    async (payload): Promise<void> => {
      dispatch(PostEWalletLoading())
      await post(`${baseURLWalletSettingsSaga}`, payload)
        .then((response) => {
          dispatch(PostEWalletResponse(response.data))
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: `${JSON.parse(e.request.response).message}`,
          })
          dispatch(PostEWalletError(JSON.parse(e.request.response).message))
        })
    },
    [dispatch]
  )

  return {
    postResponse,
    postIsLoading,
    postHasError,
    postHasErrorMessage,
    postEWalletWalletSettingsSaga,
  }
}

export default useWalletSettingsSaga
