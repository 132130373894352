export const Types = {
  POST_EWALLET_WALLET_SETTINGS_SAGA_LOADING:
    'POST_EWALLET_WALLET_SETTINGS_SAGA_LOADING',
  POST_EWALLET_WALLET_SETTINGS_SAGA_RESPONSE:
    'POST_EWALLET_WALLET_SETTINGS_SAGA_RESPONSE',
  POST_EWALLET_WALLET_SETTINGS_SAGA_ERROR:
    'POST_EWALLET_WALLET_SETTINGS_SAGA_ERROR',
}

export interface RequestInterface {
  active: boolean
  default: boolean
  name: string
  picpay_token?: string
  seller_token?: string
  wallet_name?: string
  pagseguro_token?: string
  client_id?: string
  client_secret?: string
  access_key?: string
  active_pix?: boolean
  collector_id?: string
  enable_pix?: string
  public_key?: string
  pub_key?: string
  secret_key?: string
  payer_crypto_coin?: string
  receiver_crypto_coin?: string
}

export interface ResponseInterface {
  code: number
  message: string
}

export interface ActionInterface {
  type: keyof typeof Types
  response: ResponseInterface
  message: string
}

export interface EWalletWSSagaState {
  postResponse: ResponseInterface
  postIsLoading: boolean
  postHasError: boolean
  postHasErrorMessage: string
}
