import React, { useState, useEffect } from 'react'

import * as s from './styles'
import { Steps } from './WalletSettingsFormTbanksCashout'
import { baseURLVerifyUsersAnsweredV1, post } from 'services/api'

interface QuestionAndAnswer {
  question_number: number
  answer: string
}

interface QuestionsAndAnswers {
  question_number: number
  question: string
  answers: Array<string>
}

export interface KBAQuestionsAndAnswersProps {
  questionnaire_id: string
  questions: Array<QuestionsAndAnswers>
}

interface KBAQuestionsAndAnswersWithStepProps
  extends KBAQuestionsAndAnswersProps {
  step: Steps
  setStep: (step: Steps) => void
}

const KBAQuestionsAndAnswers: React.FC<KBAQuestionsAndAnswersWithStepProps> = ({
  questionnaire_id,
  questions,
  step,
  setStep,
}) => {
  const [questionNumber, setQuestionNumber] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const numberOfQuestions = questions.length
  const [answerChosen, setAnswerChosen] = useState('')
  const [questionsAndAnswers, setQuestionAndAnswers] = useState<
    Array<QuestionAndAnswer>
  >(
    questions.map((question) => {
      return {
        question_number: question.question_number,
        answer: '',
      }
    })
  )

  async function submitQuestionnaire(id: string) {
    setIsLoading(true)
    await post(`${baseURLVerifyUsersAnsweredV1}`, {
      questionnaire_id: id,
      answers: questionsAndAnswers,
    })
      .then((res) => {
        setIsLoading(false)
        setStep({
          ...step,
          userVerificationDocumentSelection: true,
        })
      })
      .catch((e) => {
        setIsLoading(false)
        setStep({
          ...step,
          KBAUserVerificationFailed: true,
        })
      })
  }

  return (
    <s.Content
      height={609}
      width={520}
      data-testid="content-on-questions-and-answers"
    >
      <s.HeaderStepsContainer>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="graytheme8">
            <s.Icon name="checkmark" fill="white" />
          </s.HeaderStepCircle>
          <s.Text>Introdução</s.Text>
        </s.HeaderStepWrapper>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="navbarvector1">
            <s.NumberStep>2</s.NumberStep>
          </s.HeaderStepCircle>
          <s.Text>Questionário</s.Text>
        </s.HeaderStepWrapper>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="graytheme8">
            <s.NumberStep>3</s.NumberStep>
          </s.HeaderStepCircle>
          <s.Text>Documento</s.Text>
        </s.HeaderStepWrapper>
      </s.HeaderStepsContainer>

      <s.Text
        color="graytheme8"
        type="headline"
        margin="64px 0 0 0"
        data-testid="question-number-counter"
      >
        Pergunta {`${questionNumber + 1}/${numberOfQuestions}`}
      </s.Text>

      <s.Text margin="16px 0 32px 0" color="graytheme8" bold type="heading4">
        {questions[questionNumber]?.question}
      </s.Text>
      <s.OptionsContainer>
        {questions[questionNumber]?.answers.map((answer, index) => {
          return (
            <s.RadioWrapper key={index}>
              <s.RadioButton
                id={`${index}`}
                checked={
                  answerChosen === answer ||
                  questionsAndAnswers[questionNumber].answer === answer
                }
                handleChange={() => {
                  setAnswerChosen(answer)
                  setQuestionAndAnswers(
                    questionsAndAnswers.map((question) => {
                      if (questionNumber === question.question_number) {
                        return {
                          ...question,
                          answer: answer,
                        }
                      }
                      return { ...question }
                    })
                  )
                }}
                name={`answer${index}`}
                value={
                  answerChosen === answer ||
                  questionsAndAnswers[questionNumber].answer === answer
                }
              />
              <s.Text type="headline" fontWeight={600}>
                {answer}
              </s.Text>
            </s.RadioWrapper>
          )
        })}
      </s.OptionsContainer>

      <s.PreviousNextButtonWrapper>
        <s.Button
          data-testid="previous-question-button"
          disabled={isLoading}
          onClick={() => {
            setQuestionNumber((currentValue) => {
              if (currentValue === 0) {
                setStep({
                  ...step,
                  KBAIntroduction: true,
                })
                return currentValue
              }
              return currentValue - 1
            })
          }}
          width="304px"
          height="42px"
          color="whitedetailed"
        >
          <s.Icon name="arrowleft" fill="maincolor" />
          <s.Text color="maincolor" type="headline">
            Voltar
          </s.Text>
        </s.Button>
        {questionNumber + 1 === questions.length ? (
          <s.Button
            data-testid="submit-answers-button"
            width="304px"
            height="42px"
            disabled={
              questionsAndAnswers[questionNumber]?.answer == '' || isLoading
            }
            onClick={() => {
              submitQuestionnaire(questionnaire_id)
            }}
          >
            <s.Text margin="0 8px 0 0" color="white" type="headline">
              Verificar respostas
            </s.Text>
            {isLoading ? <s.Loading type="spinner" color="white" /> : <></>}
          </s.Button>
        ) : (
          <s.Button
            data-testid="next-question-button"
            width="304px"
            height="42px"
            disabled={questionsAndAnswers[questionNumber]?.answer == ''}
            onClick={() => {
              setQuestionNumber((currentValue) => {
                if (currentValue + 1 === questions.length) return currentValue
                return currentValue + 1
              })
            }}
          >
            <s.Text margin="0 8px 0 0" color="white" type="headline">
              Próximo
            </s.Text>
            <s.Icon name="arrowright" fill="white" />
          </s.Button>
        )}
      </s.PreviousNextButtonWrapper>
    </s.Content>
  )
}

export default KBAQuestionsAndAnswers
