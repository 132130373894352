import { get, patch, post } from '../api'

const getOrderStatusChanges = (uuid: string) => {
  return get(`/orders/${uuid}/panel`)
}

const patchOrderStatus = (uuid: string) => {
  return patch(`/orders/${uuid}/panel`, { wallet_payment_id: uuid })
}

const postShipayCallback = (customer_id: number, order_id: string) => {
  return post(`/v1/shipay/callback`, { customer_id, order_id })
}

const panelQueryService = {
  getOrderStatusChanges,
  patchOrderStatus,
  postShipayCallback,
}

export default panelQueryService
