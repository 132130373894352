import styled, { css, keyframes } from 'styled-components'
import { Container as ContainerCmp } from 'styled-bootstrap-grid'

import CardCmp from 'components/Card'
import InputTextCmp from 'components/Inputs/Text'
import InputTextPasswordCmp from 'components/Inputs/Text/Password'
import TextCmp from 'components/Text'
import ButtonCmp from 'components/Button'
import IconExporter from 'components/IconExporter'
import LoadingCmp from 'components/Loading'

export const flip = keyframes`
  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(90deg);
  }
`

export const Background = styled.div`
  background-image: url(${({ theme }) => theme.assets.login.backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
`
export const Container = styled(ContainerCmp)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  @media screen and (max-height: ${(props) =>
      props.theme.grid.breakpoints.md}px) {
    height: auto;
    min-height: 100vh;
    padding: 50px 0;
  }
`

export const ChangePasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > * {
    :first-child {
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }
  }
`
interface ICard {
  isFlipped?: boolean
}

export const Card = styled(CardCmp)<ICard>`
  position: relative;
  transition: all 0.25s ease-in-out;
  margin-top: 24px;
  padding-bottom: 0;

  ${(props) =>
    props.isFlipped
      ? css`
          animation: ${flip} 0.25s linear;
        `
      : css`
          /* animation: ${flip} 1s linear; */
        `};
`

export const InputText = styled(InputTextCmp)``

export const Text = styled(TextCmp)``

export const CardHeaderText = styled(TextCmp)`
  margin: ${({ theme }) => theme.spacing(6)}px auto;
`

export const Button = styled(ButtonCmp)``

export const FrontSideCard = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ theme }) =>
    theme.domain === 'SpacePay'
      ? css`
          > h5 {
            font-weight: 700;
            font-size: 18px;
          }
        `
      : css``};

  > :nth-child(2) {
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`

export const InputTextPassword = styled(InputTextPasswordCmp)``

export const Loading = styled(LoadingCmp)``

export const Image = styled.img`
  max-width: 377px;
`

export const CardWrapper = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const TitleWrapper = styled.div`
  ::after {
    content: '';
    right: 0;
    width: 100%;
    position: absolute;
    height: 1px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
  }
`

export const PasswordWrapper = styled.div`
  padding: 0px 8px;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  ::-webkit-scrollbar-track {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  > div:not(:last-child) {
    margin-top: 32px;
  }
  > div:last-child {
    margin-top: 8px;
  }
`
export const InfoBox = styled.div<{
  error?: boolean
}>`
  display: flex;
  padding: 12px 16px;
  border-radius: 8px;
  flex-direction: column;
  margin-bottom: 8px;
  border: 1px solid ${({ theme }) => theme.colors.graytheme2};
  width: 100%;

  ${({ error, theme }) =>
    error
      ? css`
          background-color: ${theme.colors.cancelledStatusBackground};
          border: 0;
        `
      : css``}
`

export const SectionLine = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 40px;
  width: 100%;
`

export const ButtonWrapper = styled.div`
  min-height: 104px;
  display: flex;

  :has(> :last-child:nth-child(2)) {
    justify-content: space-between;
  }

  > button {
    margin: 32px 0 32px 0;
    height: 40px;
  }

  ::before {
    content: '';
    right: 0;
    width: 100%;
    position: absolute;
    height: 1px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
  }
`

export const Icon = styled(IconExporter)``

export const Align = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const AlignStart = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`

export const PasswordRulesWrapper = styled.div`
  width: 304px;

  /* ul {
    list-style-type: '✔ ✘ •';
    margin-left: 0;
    text-indent: 6px;
  } */
`

export const List = styled.ul`
  list-style-type: '•';
  margin-left: 0;
  text-indent: 6px;
`

interface ItemProps {
  matched?: boolean
}
export const Item = styled.li<ItemProps>`
  color: '${({ theme }) => theme.colors.graytheme6}';
  list-style-type: '•';

  ${({ matched, theme }) => {
    switch (matched) {
      case true:
        return css`
          color: ${theme.colors.green1};
          list-style-type: '✔';
        `
      case false:
        return css`
          color: ${theme.colors.cancelledStatusIconColor};
          list-style-type: '✖';
        `
    }
  }}
`
export const CheckSquare = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 2px solid ${({ theme }) => theme.colors.maincolor};
`
interface AddCheckButtonProps {
  checked: boolean
}

export const AddCheckButton = styled.button<AddCheckButtonProps>`
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 8px;
  border: none;
  :hover {
    cursor: pointer;
  }

  ${({ checked }) =>
    checked
      ? css`
          background: ${({ theme }) => theme.colors.transparent};
          div {
            background: ${({ theme }) => theme.colors.maincolor};
          }
        `
      : css`
          background: inherit;
        `};
`

export const LoadingGradient = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 64px;

  img {
    will-change: transform;
    transition-property: transform;
    animation-name: rotate;
    animation-duration: 1100ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`
