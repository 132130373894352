import styled from 'styled-components'
import { Container as ContainerCmp } from 'styled-bootstrap-grid'
import TableCmp from 'components/Table'
import PaginationCmp from 'components/Pagination'
import ButtonCmp from 'components/Button'
import TextCmp from 'components/Text'
import IconExporter from 'components/IconExporter'
import SelectCmp from 'components/Inputs/Select'
import InputTextCmp from 'components/Inputs/Text'

export const UserControlsGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 110px;
  align-items: end;
`

export const SearchInputsWrapper = styled('div')`
  display: grid;
  grid-template-columns: 228px 400px;
  grid-column-gap: 20px;
  align-items: end;
`

export const SelectWrapper = styled('div')``

export const Container = styled(ContainerCmp)`
  padding: ${({ theme }) => theme.spacing(3)}px;
  height: 100vh;
  max-width: 1380px;

  & > div {
    margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  }
`

export const Table = styled(TableCmp)`
  > thead > tr > th {
    :nth-child(6) {
      padding-left: 24px;
      width: 120px;
    }
    :nth-child(7) {
      padding-left: 24px;
      width: 120px;
    }
  }
`

export const Select = styled(SelectCmp)`
  width: 228px;
  margin-top: 8px;
`

export const InputText = styled(InputTextCmp)`
  margin-top: 16px;
`

export const Pagination = styled(PaginationCmp)``

export const Button = styled(ButtonCmp)``

export const Text = styled(TextCmp)``

export const Icon = styled(IconExporter)``
