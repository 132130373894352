export const Types = {
  POST_CERTIFICATE: 'POST_CERTIFICATE',
  POST_CERTIFICATE_LOADING: 'POST_CERTIFICATE_LOADING',
  POST_CERTIFICATE_ERROR: 'POST_CERTIFICATE_ERROR',
}

export interface ResponseInterface {
  code: number
  message: string
}


export interface ActionInterface {
  type: keyof typeof Types
  payload: ResponseInterface
}

export interface CertificateState {
  postResponse: ResponseInterface
  postIsLoading: boolean
  postHasError: boolean
}