import React from 'react'

import unavailableDocumentVerification from 'assets/images/unavailable-document-verification.png'
import { Steps } from './WalletSettingsFormTbanksCashout'

import * as s from './styles'
interface DocumentVerificationUnavailableProps {
  step: Steps
  setStep: (step: Steps) => void
}

const DocumentVerificationUnavailable: React.FC<DocumentVerificationUnavailableProps> = ({
  step,
  setStep,
}) => {
  return (
    <s.Content
      width={520}
      data-testid="content-on-user-verification-unavailable"
    >
      <s.HeaderStepsContainer>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="graytheme8">
            <s.NumberStep>1</s.NumberStep>
          </s.HeaderStepCircle>
          <s.Text>Introdução</s.Text>
        </s.HeaderStepWrapper>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="graytheme8">
            <s.NumberStep>2</s.NumberStep>
          </s.HeaderStepCircle>
          <s.Text>Questionário</s.Text>
        </s.HeaderStepWrapper>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="navbarvector1">
            <s.NumberStep>3</s.NumberStep>
          </s.HeaderStepCircle>
          <s.Text>Documento</s.Text>
        </s.HeaderStepWrapper>
      </s.HeaderStepsContainer>
      <s.Text margin="64px 0 16px 0" color="graytheme6" bold type="heading4">
        Verificação indisponível no momento
      </s.Text>
      <s.Text margin="0 0 40px 0" color="graytheme6" type="headline">
        Infelizmente este sistema está temporariamente indisponível, tente
        novamente mais tarde através do botão ”Habilitar conta” na listagem de
        contas da aba ”Pix”. Caso o problema continue entre em contato com o
        suporte.
      </s.Text>
      <img
        width={500}
        src={unavailableDocumentVerification}
        color="graytheme6"
      />
      <s.ButtonWrapper margin="92px 0 0 0" data-testid="button-on-restart">
        <s.Button
          data-testid="restart-button"
          onClick={() => {
            setStep({
              ...step,
              userVerificationDocumentSelection: true,
            })
          }}
          height="42px"
          color="maincolor"
        >
          <s.Text
            margin="0 8px 0 0"
            type="headline"
            color="white"
            fontWeight={600}
          >
            Refazer verificação
          </s.Text>
        </s.Button>
      </s.ButtonWrapper>
    </s.Content>
  )
}

export default DocumentVerificationUnavailable
