import { baseURL_LOCAL } from './../environment/env.local'
import { baseURL_PROD, baseURLBFF_PROD } from './../environment/env'

import { logout } from './auth'

const getToken = async (request) => {
  const response = await fetch(`${baseURL}/v1/auth-session/token`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-type': 'application/json',
    },
  })
    .then((response) => response.json())
    .catch((err) => {
      console.log(err)
    })
  request.headers = {
    ...request.headers,
    Authorization: `Bearer ${
      response?.access_token ? response.access_token : undefined
    }`,
    'X-Timezone-Offset': String(new Date().getTimezoneOffset() * -1),
    'X-Language': navigator.language || navigator['userLanguage'],
  }

  return request
}

const baseURL =
  window['env']['name'] === 'local' || window['env']['name'] === 'test'
    ? baseURL_LOCAL
    : baseURL_PROD

const baseURLBff =
  window['env']['name'] === 'local' || window['env']['name'] === 'test'
    ? baseURL_LOCAL
    : baseURLBFF_PROD

const responseInterceptor = (response) => {
  return response
}

const errorInterceptor = (error) => {
  if (error?.response?.status === 401) {
    logout()
  }
  if (error?.request?.response === '' || error?.response?.status === 500) {
    const throwError = {
      request: {
        response:
          '{\n  "code": 500, \n  "message": "Erro no servidor, tente novamente mais tarde"\n}\n',
      },
    }
    return Promise.reject(throwError)
  }
  return Promise.reject(error)
}

const requestInterceptor = async (request) => {
  const requestWithToken = getToken(request)

  return requestWithToken
}

export {
  baseURL,
  baseURLBff,
  requestInterceptor,
  responseInterceptor,
  errorInterceptor,
}
