import React from 'react'

import { applyCpfCnpjMask, applyCEPMask } from 'helpers/masks'
import { getStates } from 'helpers/geo'
import { getValueOrDefault, isConexaoItau } from 'helpers'
import { isValidCpf } from 'helpers/validators'
import { getValueFromLocalStorage } from 'helpers/localStorage'
import { UserSessionInfo } from 'store/modules/auth/types'

import useGetStores from 'hooks/useGetStores'
import useGetUser from 'hooks/useGetUser'
import { usePatchUser } from 'hooks/user'
import useGetProfile from 'hooks/useGetProfile'
import UseStore from 'hooks/useStore'
import useGetGeoAddress from 'hooks/useGetGeoAddress'
import useForm from 'hooks/useForm'
import { useNonInitialEffect } from 'hooks/utils'

import Toast, { showToast } from 'components/Toast'

import { TOptions, TSelected } from 'components/Inputs/Select/types'

import * as t from './types'
import * as s from './styles'

const CheckUser: React.FC<t.ICheckUser> = ({
  firstStepIsValid,
  setFirstStepIsValid,
  nextStep,
}) => {
  const { form, updateForm } = useForm<t.IForm>()
  const { stores, isLoading, hasError, loadStores } = useGetStores()
  const {
    user,
    isLoading: isUserLoading,
    hasError: hasUserError,
    loadUser,
  } = useGetUser()
  const {
    profile,
    isLoading: isProfileLoading,
    hasError: hasProfileError,
    loadProfile,
  } = useGetProfile()
  const {
    store,
    isLoading: isStoreLoading,
    hasError: hasStoreError,
    loadStore,
    postIsLoading,
    postHasError,
    postHasErrorMessage,
    postStore,
  } = UseStore()
  const {
    geoAddress,
    isLoading: isGeoAddressLoading,
    hasError: hasGeoAddressError,
    loadGeoAddress,
    resetGeoAddress,
  } = useGetGeoAddress()

  const states: { id: number; text: string }[] = getStates()
  const { patchError, patchIsLoading, editUser } = usePatchUser()

  const [ufOptions] = React.useState<TOptions[]>(
    states.map((state) => {
      return {
        id: state.id,
        text: state.text,
        onClick: () => {
          setAddress({ ...address, state: state.text })
          setSelectedUf({ id: state.id, name: state.text })
        },
      }
    })
  )

  const [selectedUf, setSelectedUf] = React.useState<TSelected>({
    id: 0,
    name: '',
  })
  const [userInput, setUserInput] = React.useState({
    name: '',
    email: '',
    cpf: '',
  })
  const [account, setAccount] = React.useState({
    businessName: '',
    PDV: '',
    rede: '',
  })
  const [storeInput, setStoreInput] = React.useState({
    cnpj: '',
    storeName: '',
    registers: '',
    headquarters: false,
  })
  const [address, setAddress] = React.useState({
    cep: '',
    streetAddress: '',
    number: '',
    complement: '',
    neighbourhood: '',
    city: '',
    state: '',
  })
  const [inputPressedValidator, setInputPressedValidator] = React.useState<any>(
    {
      ...Object.keys({
        ...address,
        ...storeInput,
        ...account,
        ...userInput,
      }).reduce((acc, key) => {
        acc[key] = false
        return acc
      }, {}),
    }
  )

  const checkIfAllInputsAreValid = React.useCallback(() => {
    const { complement, ...addressExceptComplement } = address
    const allInputs = {
      ...addressExceptComplement,
      ...storeInput,
      ...account,
      ...userInput,
    }
    return Object.values(allInputs).every((item) => {
      return item !== null && item !== ''
    })
  }, [address, storeInput, account, userInput])

  const cpfErrorHandler = () => {
    if (userInput.cpf === '') {
      return { message: 'Campo obrigatório' }
    } else if (!isValidCpf(userInput.cpf)) {
      return { message: 'esse CPF não existe' }
    } else {
      return false
    }
  }

  const handlePost = (formGroup = 'store') => {
    if (firstStepIsValid && store.id && !postIsLoading) {
      updateForm({
        ...userInput,
        ...account,
        ...storeInput,
        ...address,
      })
      if (formGroup === 'store') {
        postStore(
          {
            city_name: address.city,
            cnpj_cpf: storeInput.cnpj,
            headquarter: storeInput.headquarters,
            id: store.id,
            name: storeInput.storeName,
            neighborhood: address.neighbourhood,
            reference: address.complement,
            state_name: address.state,
            street_name: address.streetAddress,
            street_number: address.number,
            zip_code: address.cep,
          },
          store.id
        )
      }
      if (formGroup === 'user') {
        if (userInput.name && userInput.name.split(' ').length > 1) {
          const firstName = userInput.name.split(' ')[0]
          const lastName = userInput.name.replace(/.+? /, '')
          editUser(user.id, {
            email: userInput.email,
            first_name: firstName,
            last_name: lastName,
            cpf: userInput.cpf,
          })
        } else {
          showToast({
            type: 'error',
            message: 'O nome deve ser completo',
          })
        }
      }
    }
  }

  React.useEffect(() => {
    const currentUserSession: UserSessionInfo = JSON.parse(getValueFromLocalStorage('currentUserSession'))
    loadUser(currentUserSession.id)
    loadStores()
    loadProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setFirstStepIsValid(
      checkIfAllInputsAreValid() &&
        !isGeoAddressLoading &&
        !isLoading &&
        !isUserLoading &&
        !isStoreLoading &&
        !isProfileLoading &&
        !postIsLoading
    )
  }, [
    isGeoAddressLoading,
    isLoading,
    isUserLoading,
    isStoreLoading,
    isProfileLoading,
    postIsLoading,
    setFirstStepIsValid,
    checkIfAllInputsAreValid,
  ])

  React.useEffect(() => {
    if (
      hasError ||
      hasStoreError ||
      hasUserError ||
      hasProfileError ||
      patchError
    ) {
      showToast({
        type: 'error',
        message: 'Erro na API, tente novamente mais tarde',
      })
    }
  }, [hasError, hasStoreError, hasUserError, hasProfileError, patchError])

  useNonInitialEffect(() => {
    if (!isLoading && stores.total > 0) {
      loadStore(stores?.data[0]?.id)
    }
  }, [stores, isLoading, loadStores])

  React.useEffect(() => {
    if (store.cnpj_cpf) {
      updateForm({
        cnpj: store.cnpj_cpf,
        storeName: store.name,
        registers: '1',
        headquarters: store.headquarter,
        cep: store.zip_code,
        streetAddress: store.street_name,
        number: store.street_number,
        complement: store.reference,
        city: store.city_name,
        state: store.state_name,
        neighbourhood: store.neighborhood,
      })
    }
  }, [store, updateForm])

  React.useEffect(() => {
    if (user.first_name) {
      updateForm({
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        cpf: user.cpf,
      })
    }
  }, [user, loadUser, updateForm])

  React.useEffect(() => {
    if (profile.customer_name) {
      updateForm({
        businessName: profile.customer_name,
        PDV: profile.pos_product_name,
        rede: profile.retail_chain_name,
      })
    }
  }, [profile, loadProfile, updateForm])

  React.useEffect(() => {
    if (geoAddress.city) {
      updateForm({
        cep: geoAddress?.postal_code,
        city: geoAddress?.city,
        state: geoAddress?.state,
        streetAddress: geoAddress?.street,
        neighbourhood: geoAddress?.neighborhood,
        number: '',
        complement: '',
      })
      resetGeoAddress()
    }
  }, [geoAddress, updateForm, resetGeoAddress])

  React.useEffect(() => {
    if (form.name) {
      setUserInput({
        name: getValueOrDefault(form.name),
        email: getValueOrDefault(form.email),
        cpf: getValueOrDefault(form.cpf),
      })
    }
    if (form.cnpj) {
      setStoreInput({
        cnpj: getValueOrDefault(form.cnpj),
        headquarters: form?.headquarters || false,
        registers: getValueOrDefault(form.registers),
        storeName: getValueOrDefault(form.storeName),
      })
    }
    if (form.cep) {
      setAddress({
        cep: getValueOrDefault(form?.cep),
        streetAddress: getValueOrDefault(form?.streetAddress),
        number: getValueOrDefault(form?.number),
        neighbourhood: getValueOrDefault(form?.neighbourhood),
        complement: getValueOrDefault(form?.complement),
        city: getValueOrDefault(form?.city),
        state: getValueOrDefault(form?.state),
      })
    }
    if (form.PDV) {
      setAccount({
        PDV: getValueOrDefault(form?.PDV),
        businessName: getValueOrDefault(form?.businessName),
        rede: getValueOrDefault(form?.rede),
      })
    }
    if (form.state && ufOptions) {
      setSelectedUf({
        id: ufOptions.find((item) => item.text === form.state).id,
        name: form.state,
      })
    }
  }, [
    form,
    ufOptions,
    setUserInput,
    setStoreInput,
    setAddress,
    setAccount,
    setSelectedUf,
  ])

  const renderLoading = () => {
    if (postIsLoading || patchIsLoading) {
      return <s.Loading type="spinner" color="graytheme5" />
    } else if (postHasError) {
      return <s.Icon fill="redshipay" name="errorcirclefilled" />
    } else {
      return <s.Icon fill="maincolor" name="checkmarkcirclefilled" />
    }
  }

  const storeLoading = () => {
    if (postIsLoading || isGeoAddressLoading) {
      return <s.Loading type="spinner" color="graytheme5" />
    } else if (postHasError || hasGeoAddressError) {
      return <s.Icon fill="redshipay" name="errorcirclefilled" />
    } else {
      return <s.Icon fill="maincolor" name="checkmarkcirclefilled" />
    }
  }

  return (
    <s.Background>
      <Toast />
      <s.BackgroundFilter>
        <s.Container>
          <s.TextWrapper>
            <s.Text bold type="heading3" color="graytheme6">
              Confirme seus dados
            </s.Text>
            <s.Text color="maincolor">
              Se as informações estiverem corretas é só seguir para o próximo
              passo.
            </s.Text>
          </s.TextWrapper>
          <s.FirstWrapper>
            {!isUserLoading ? (
              <s.Card width={426} height={448}>
                <s.Header>
                  <s.Text type="headline" color="graytheme6" bold>
                    Usuário
                  </s.Text>
                  {renderLoading()}
                </s.Header>
                <s.Body>
                  <s.InputText
                    value={userInput.name}
                    onChange={(e) => {
                      setUserInput({ ...userInput, name: e.target.value })
                      setInputPressedValidator({
                        ...inputPressedValidator,
                        name: true,
                      })
                    }}
                    error={
                      userInput.name === '' && inputPressedValidator.name
                        ? { message: 'Campo obrigatório' }
                        : false
                    }
                    label="Nome"
                    onBlur={(e) => {
                      if (e.target.value !== form.name) {
                        handlePost('user')
                      }
                    }}
                  />
                  <s.InputText
                    value={userInput.email}
                    onChange={(e) => {
                      setUserInput({ ...userInput, email: e.target.value })
                      setInputPressedValidator({
                        ...inputPressedValidator,
                        email: true,
                      })
                    }}
                    error={
                      userInput.email === '' && inputPressedValidator.email
                        ? { message: 'Campo obrigatório' }
                        : false
                    }
                    label="E-mail"
                    onBlur={(e) => {
                      if (e.target.value !== form.email) {
                        handlePost('user')
                      }
                    }}
                  />
                  <s.InputText
                    value={applyCpfCnpjMask(userInput.cpf)}
                    onChange={(e) => {
                      setUserInput({ ...userInput, cpf: e.target.value })
                      setInputPressedValidator({
                        ...inputPressedValidator,
                        cpf: true,
                      })
                    }}
                    maxLength={14}
                    disabled={
                      getValueOrDefault(form.cpf) && !inputPressedValidator.cpf
                    }
                    error={cpfErrorHandler()}
                    label="CPF"
                    onBlur={(e) => {
                      if (
                        e.target.value !== form.cpf &&
                        isValidCpf(e.target.value)
                      ) {
                        handlePost('user')
                      }
                    }}
                  />
                </s.Body>
              </s.Card>
            ) : null}
            {!isProfileLoading ? (
              <s.Card width={426} height={448}>
                <s.Header>
                  <s.Text type="headline" color="graytheme6" bold>
                    Conta
                  </s.Text>
                  {renderLoading()}
                </s.Header>
                <s.Body>
                  <s.InputText
                    value={account.businessName}
                    onBlur={() => handlePost()}
                    onChange={(e) => {
                      setAccount({ ...account, businessName: e.target.value })
                      setInputPressedValidator({
                        ...inputPressedValidator,
                        bussinessName: true,
                      })
                    }}
                    label="Nome do Negócio"
                    disabled
                  />
                  <s.InputText
                    value={account.PDV}
                    onChange={(e) => {
                      setAccount({ ...account, PDV: e.target.value })
                      setInputPressedValidator({
                        ...inputPressedValidator,
                        PDV: true,
                      })
                    }}
                    label="Sistema Integrado"
                    disabled
                  />
                  <s.InputText
                    value={account.rede}
                    data-testid="input-rede"
                    onBlur={() => handlePost()}
                    onChange={(e) => {
                      setAccount({ ...account, rede: e.target.value })
                      setInputPressedValidator({
                        ...inputPressedValidator,
                        rede: true,
                      })
                    }}
                    label={isConexaoItau() ? 'Grupo' : 'Rede'}
                    disabled
                  />
                </s.Body>
              </s.Card>
            ) : null}
          </s.FirstWrapper>
          {!isStoreLoading && !isLoading ? (
            <s.StoreInfoWrapper>
              <s.Card width={916} height={456}>
                <s.Header>
                  <s.Text type="headline" color="graytheme6" bold>
                    Primeira Loja*
                  </s.Text>
                  {storeLoading()}
                </s.Header>
                <s.Body>
                  <s.UpperWrapper>
                    <s.InputText
                      maxLength={18}
                      onBlur={(e) => {
                        if (
                          e.target.value.replace(/[-./]/g, '') !== form.cnpj
                        ) {
                          handlePost()
                        }
                      }}
                      value={applyCpfCnpjMask(storeInput.cnpj)}
                      onChange={(e) => {
                        setInputPressedValidator({
                          ...inputPressedValidator,
                          cnpj: true,
                        })
                        setStoreInput({ ...storeInput, cnpj: e.target.value })
                      }}
                      error={
                        (storeInput?.cnpj?.replace(/[-./]/g, '').length < 10 &&
                          inputPressedValidator.cnpj) ||
                        postHasErrorMessage
                          ? { message: 'CPF/CNPJ Invalido' }
                          : false
                      }
                      label="CNPJ/CPF"
                    />
                    <s.InputText
                      onChange={(e) => {
                        setInputPressedValidator({
                          ...inputPressedValidator,
                          storeName: true,
                        })
                        setStoreInput({
                          ...storeInput,
                          storeName: e.target.value,
                        })
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== form.storeName) {
                          handlePost()
                        }
                      }}
                      value={storeInput.storeName}
                      error={
                        storeInput.storeName === '' &&
                        inputPressedValidator.storeName
                          ? { message: 'Campo obrigatório' }
                          : false
                      }
                      label="Nome da Loja"
                    />
                    <s.InputText
                      onChange={() => {}}
                      disabled
                      value={storeInput.registers}
                      error={
                        storeInput.registers === '' &&
                        inputPressedValidator.registers
                          ? { message: 'Campo obrigatório' }
                          : false
                      }
                      label="Caixas"
                    />
                  </s.UpperWrapper>
                  <s.BottomWrapper>
                    <s.InputText
                      onChange={(e) => {
                        setAddress({ ...address, cep: e.target.value })
                        setInputPressedValidator({
                          ...inputPressedValidator,
                          cep: true,
                        })
                      }}
                      value={applyCEPMask(address.cep)}
                      label="CEP"
                      onBlur={() => {
                        if (
                          address?.cep?.replace(/-/g, '').length === 8 &&
                          inputPressedValidator.cep &&
                          address?.cep?.replace(/-/g, '') !==
                            geoAddress?.postal_code
                        ) {
                          loadGeoAddress(address?.cep?.replace(/-/g, ''))
                        }
                      }}
                      maxLength={9}
                      error={
                        (address.cep === '' ||
                          address?.cep?.replace(/-/g, '').length < 8) &&
                        inputPressedValidator.cep
                          ? { message: 'CEP Inválido' }
                          : false
                      }
                    />
                    <s.InputText
                      onChange={(e) => {
                        setInputPressedValidator({
                          ...inputPressedValidator,
                          streetAddress: true,
                        })
                        setAddress({
                          ...address,
                          streetAddress: e.target.value,
                        })
                      }}
                      value={address.streetAddress}
                      error={
                        address.streetAddress === '' &&
                        inputPressedValidator.streetAddress
                          ? { message: 'Campo obrigatório' }
                          : false
                      }
                      label="Endereço"
                      onBlur={(e) => {
                        if (e.target.value !== form.streetAddress) {
                          handlePost()
                        }
                      }}
                    />
                    <s.InputText
                      type="number"
                      onChange={(e) => {
                        setInputPressedValidator({
                          ...inputPressedValidator,
                          number: true,
                        })
                        setAddress({ ...address, number: e.target.value })
                      }}
                      error={
                        address.number === '' && inputPressedValidator.number
                          ? { message: 'Campo obrigatório' }
                          : false
                      }
                      value={address.number}
                      label="Número"
                      onBlur={(e) => {
                        if (e.target.value !== form.number) {
                          handlePost()
                        }
                      }}
                    />
                    <s.InputText
                      onChange={(e) => {
                        setInputPressedValidator({
                          ...inputPressedValidator,
                          complement: true,
                        })
                        setAddress({ ...address, complement: e.target.value })
                      }}
                      value={address.complement}
                      label="Complemento"
                      onBlur={(e) => {
                        if (e.target.value !== form.complement) {
                          handlePost()
                        }
                      }}
                    />
                    <s.InputText
                      onChange={(e) => {
                        setInputPressedValidator({
                          ...inputPressedValidator,
                          neighbourhood: true,
                        })
                        setAddress({
                          ...address,
                          neighbourhood: e.target.value,
                        })
                      }}
                      error={
                        address.neighbourhood === '' &&
                        inputPressedValidator.neighbourhood
                          ? { message: 'Campo obrigatório' }
                          : false
                      }
                      onBlur={(e) => {
                        if (e.target.value !== form.neighbourhood) {
                          handlePost()
                        }
                      }}
                      value={address.neighbourhood}
                      label="Bairro"
                    />
                    <s.InputText
                      onChange={(e) => {
                        setInputPressedValidator({
                          ...inputPressedValidator,
                          city: true,
                        })
                        setAddress({ ...address, city: e.target.value })
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== form.city) {
                          handlePost()
                        }
                      }}
                      value={address.city}
                      error={
                        address.city === '' && inputPressedValidator.city
                          ? { message: 'Campo obrigatório' }
                          : false
                      }
                      label="Cidade"
                    />
                    <s.Select
                      optionBoxDirection="top"
                      optionBoxSize={200}
                      label="Estado"
                      options={ufOptions}
                      selected={selectedUf}
                    />
                  </s.BottomWrapper>
                </s.Body>
              </s.Card>
            </s.StoreInfoWrapper>
          ) : null}
          <s.Footnote>
            <s.FootnoteText color="maincolor">
              *Você poderá cadastrar mais lojas ao completar sua primeira
              integração de loja
            </s.FootnoteText>
            <s.Button
              width="170px"
              disabled={
                isGeoAddressLoading ||
                isLoading ||
                isUserLoading ||
                isStoreLoading ||
                isProfileLoading ||
                postIsLoading ||
                !firstStepIsValid
              }
              onClick={() => {
                updateForm({
                  ...userInput,
                  ...account,
                  ...storeInput,
                  ...address,
                })
                resetGeoAddress()
                nextStep(2)
              }}
            >
              Próximo
              <s.Icon name="arrowright" fill="lightmaincolor3" />
            </s.Button>
          </s.Footnote>
        </s.Container>
      </s.BackgroundFilter>
    </s.Background>
  )
}

export default CheckUser
