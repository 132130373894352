import { ActionInterface, Types, LogoState } from './types'

const INITIAL_STATE: LogoState = {
  logo: {
    is_logo_default: null,
    pos_product_logo: null,
    pos_product_name: null,
  },
  isLoading: false,
  hasError: false,
}

const logoReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): LogoState => {
  switch (action.type) {
    case Types.GET_LOGO:
      return {
        ...state,
        logo: {
          ...action.payload,
          pos_product_logo: action.payload.pos_product_logo
            ? `${action.payload.pos_product_logo}?${Date.now()}`
            : null,
        },
        isLoading: false,
        hasError: false,
      }
    case Types.GET_LOGO_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_LOGO_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export default logoReducer
