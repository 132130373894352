import { dict } from 'components/IconExporter'
import { showToast } from 'components/Toast'
import { useNonInitialEffect } from 'hooks/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { themeColors } from 'styles/theme'

import * as s from '../styles'

interface IChildrenRender {
  children: string | number
  index?: number
}

const DateRender = ({ children }: IChildrenRender) => {
  const dateMaskedFirstPart = (children as string)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{2})(\d{4})(\d{6})/g, `$1/$2/$3`)

  const dateMaskedSecondPart = (children as string)
    .replace(/\D/g, '')
    .replace(/(\d{8})(\d{2})(\d{2})(\d{2})/g, '$2:$3')

  return (
    <s.DateContainer>
      <s.Text color="graytheme6">
        {`${dateMaskedFirstPart} às ${dateMaskedSecondPart}`}
      </s.Text>
    </s.DateContainer>
  )
}

const statusBackgroundColor = (
  statusText: string
): keyof typeof themeColors => {
  switch (statusText) {
    case 'Aprovado':
    case 'approved':
      return 'approvedStatusBackground'
    case 'Pendente':
    case 'pending':
    case 'pendingv':
      return 'graytheme10'
    case 'Cancelado':
    case 'cancelled':
      return 'cancelledStatusBackground'
    case 'Rejeitado':
    case 'rejected':
      return 'cancelledStatusBackground'
    case 'Expirado':
    case 'expired':
      return 'cancelledStatusBackground'
    case 'Devolvido':
    case 'refunded':
      return 'graytheme10'
    case 'Devolução Solicitada':
    case 'refund_pending':
      return 'graytheme10'
    case 'Parcialmente Devolvido':
    case 'partial_refunded':
      return 'cancelledStatusBackground'
    default:
      return 'graytheme6'
  }
}

const statusIconColor = (statusText: string): keyof typeof themeColors => {
  switch (statusText) {
    case 'Aprovado':
    case 'approved':
      return 'approvedStatusIconColor'
    case 'Pendente':
    case 'pending':
    case 'pendingv':
      return 'pendingStatusIconColor'
    case 'Cancelado':
    case 'cancelled':
      return 'cancelledStatusIconColor'
    case 'Rejeitado':
    case 'rejected':
      return 'cancelledStatusIconColor'
    case 'Expirado':
    case 'expired':
      return 'cancelledStatusIconColor'
    case 'Devolvido':
    case 'refunded':
      return 'pendingStatusIconColor'
    case 'Devolução Solicitada':
    case 'refund_pending':
      return 'graytheme10'
    case 'Parcialmente Devolvido':
    case 'partial_refunded':
      return 'cancelledStatusIconColor'
    default:
      return 'graytheme6'
  }
}

const statusIcon = (statusText: string): keyof typeof dict => {
  switch (statusText) {
    case 'Aprovado':
    case 'approved':
      return 'checkmark'
    case 'Pendente':
    case 'pending':
    case 'pendingv':
      return 'dotsHorizontal'
    case 'Cancelado':
    case 'cancelled':
      return 'close'
    case 'Rejeitado':
    case 'rejected':
      return 'close'
    case 'Expirado':
    case 'expired':
      return 'close'
    case 'Devolvido':
    case 'refunded':
      return 'returnarrow'
    case 'Devolução Solicitada':
    case 'refund_pending':
      return 'returnarrow'
    case 'Parcialmente Devolvido':
    case 'partial_refunded':
      return 'returnarrow'
    default:
      return 'dotsHorizontal'
  }
}

const statusTextColor = (statusText: string): keyof typeof themeColors => {
  switch (statusText) {
    case 'Aprovado':
    case 'approved':
      return 'approvedStatusTextColor'
    case 'Pendente':
    case 'pending':
    case 'pendingv':
      return 'pendingStatusTextColor'
    case 'Cancelado':
    case 'cancelled':
      return 'cancelledStatusTextColor'
    case 'Rejeitado':
    case 'rejected':
      return 'cancelledStatusTextColor'
    case 'Expirado':
    case 'expired':
      return 'cancelledStatusTextColor'
    case 'Devolvido':
    case 'refunded':
      return 'pendingStatusTextColor'
    case 'Devolução Solicitada':
    case 'refund_pending':
      return 'pendingStatusTextColor'
    case 'Parcialmente Devolvido':
    case 'partial_refunded':
      return 'cancelledStatusTextColor'
    default:
      return 'graytheme6'
  }
}

const StatusRender = ({ children }: IChildrenRender) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <s.StatusContainer color={statusBackgroundColor(children as string)}>
      <s.Icon
        width="13"
        name={statusIcon(children as string)}
        fill={statusIconColor(children as string)}
      />
      <s.StatusText color={statusTextColor(children as string)}>
        {t(`${children as string}`)}
      </s.StatusText>
    </s.StatusContainer>
  )
}

const StoreRender = ({ children }: IChildrenRender) => {
  return (
    <s.StoreWrapper>
      <s.Text>&nbsp;{children}</s.Text>
    </s.StoreWrapper>
  )
}

const RegisterRender = ({ children }: IChildrenRender) => {
  return (
    <s.StoreWrapper>
      <s.Text>&nbsp;{children}</s.Text>
    </s.StoreWrapper>
  )
}

const IdRender = ({ children }: IChildrenRender) => {
  return (
    <s.IdContainer
      onClick={(e) => {
        e.stopPropagation()
        navigator.clipboard.writeText(children as string)
        showToast({ type: 'success', message: 'ID Copiado!' })
      }}
    >
      <s.IdWrapper>
        <s.Text fontWeight={600} color="maincolor">
          {children}
        </s.Text>
      </s.IdWrapper>
      <s.Icon width={24} height={24} name="clipboard" />
    </s.IdContainer>
  )
}

const CollapseRender = ({ data }: { data: any[] }) => {
  const { t } = useTranslation()

  return (
    <s.CollapseWrapper>
      <s.Text color="graytheme6">
        {`O PEDIDO ESTÁ ${t(
          `${data[5].value as string}`
        )}, ÚLTIMA ATUALIZAÇÃO FEITA EM\u00A0`.toUpperCase()}
      </s.Text>
      <s.Text bold color="graytheme6">
        {`${data[6].value}`}
      </s.Text>
    </s.CollapseWrapper>
  )
}

const Table: React.FC<any> = ({
  isLoading,
  isLoadingStores,
  isLoadingSystemWallets,
  isLoadingWalletSettings,
  isFiltersLoading,
  summary,
  sortedColumn,
  setSortedColumn,
  hasError,
  isAccordionOpen,
  setIsAccordionOpen,
  detailsId,
  setDetailsId,
  detailsExpirationDate,
  setDetailsExpirationDate,
  isModalOpen,
  setIsModalOpen,
  filterList,
  filtersHandler,
  walletRender,
  minimumAmount,
  maximumAmount,
  setMinimumAmount,
  setMaximumAmount,
  valueRender,
  initialDate,
  finalDate,
  setInitialDate,
  setFinalDate,
}) => {
  const [
    initialDateFilterDropdown,
    setInitialDateFilterDropdown,
  ] = React.useState(initialDate)
  const [finalDateFilterDropdown, setFinalDateFilterDropdown] = React.useState(
    finalDate
  )
  const [
    minimumAmountFilterDropdown,
    setMinimumAmountFilterDropdown,
  ] = React.useState(minimumAmount)
  const [
    maximumAmountFilterDropdown,
    setMaximumAmountFilterDropdown,
  ] = React.useState(maximumAmount)

  useNonInitialEffect(() => {
    setInitialDate(initialDateFilterDropdown)
    setFinalDate(finalDateFilterDropdown)
    setMinimumAmount(minimumAmountFilterDropdown)
    setMaximumAmount(maximumAmountFilterDropdown)
    filtersHandler(
      initialDateFilterDropdown,
      finalDateFilterDropdown,
      minimumAmountFilterDropdown,
      maximumAmountFilterDropdown
    )
  }, [
    initialDateFilterDropdown,
    finalDateFilterDropdown,
    minimumAmountFilterDropdown,
    maximumAmountFilterDropdown,
  ])

  const renderTable = React.useMemo(() => {
    if (
      isLoading ||
      isLoadingStores ||
      isLoadingSystemWallets ||
      isLoadingWalletSettings ||
      isFiltersLoading
    ) {
      return (
        <s.LoadingWrapper>
          <s.Text color="maincolor" type="heading3">
            Carregando...
          </s.Text>
          <s.Loading type="spinner" color="maincolor" />
        </s.LoadingWrapper>
      )
    }
    if (summary.data) {
      return (
        <>
          <s.Table
            data={summary.data}
            sortedColumnState={[sortedColumn, setSortedColumn]}
            CollapseRender={CollapseRender}
            hasFilterButton
            seeMoreButton
            isHeaderSticky
            hasError={hasError}
            accordionState={[isAccordionOpen, setIsAccordionOpen]}
            detailsIdState={[detailsId, setDetailsId]}
            detailsExpirationDateState={[
              detailsExpirationDate,
              setDetailsExpirationDate,
            ]}
            isModalOpenState={[isModalOpen, setIsModalOpen]}
            filterList={filterList}
            setFilterList={filtersHandler}
            columns={[
              {
                headerName: 'Data',
                headerIcon: 'calendar',
                objectName: 'created_at',
                filterName: 'created_at',
                isFilterable: true,
                type: 'date',
                range: [initialDateFilterDropdown, finalDateFilterDropdown],
                setRange: [
                  setInitialDateFilterDropdown,
                  setFinalDateFilterDropdown,
                ],
                AltRender: DateRender,
              },

              {
                headerName: 'Valor',
                headerIcon: 'payments',
                objectName: 'total_order',
                filterName: 'total_order',
                isFilterable: true,
                type: 'value',
                range: [
                  minimumAmountFilterDropdown,
                  maximumAmountFilterDropdown,
                ],
                setRange: [
                  setMinimumAmountFilterDropdown,
                  setMaximumAmountFilterDropdown,
                ],
                AltRender: valueRender,
              },

              {
                headerName: 'Loja',
                headerIcon: 'store',
                objectName: 'store_name',
                filterName: 'store_name',
                isFilterable: true,
                AltRender: StoreRender,
              },
              {
                headerName: 'Caixa',
                headerIcon: 'register',
                objectName: 'store_pos_name',
                AltRender: RegisterRender,
              },
              {
                headerName: 'Conta',
                headerIcon: 'pixcard',
                objectName: 'friendly_name',
                filterName: 'wallet_name',
                isFilterable: true,
                AltRender: walletRender,
              },

              {
                headerName: 'Status',
                headerIcon: 'clock',
                objectName: 'status',
                filterName: 'status',
                isFilterable: true,
                AltRender: StatusRender,
              },
              {
                objectName: 'updated_at',
                fromCollapseRow: true,
              },
              {
                headerName: 'Código UUID',
                objectName: 'id',
                AltRender: IdRender,
              },
            ]}
          />
        </>
      )
    }
  }, [
    hasError,
    isLoading,
    isLoadingStores,
    isLoadingSystemWallets,
    isAccordionOpen,
    isFiltersLoading,
    isLoadingWalletSettings,
    summary,
    filterList,
    initialDate,
    finalDate,
    maximumAmount,
    minimumAmount,
    initialDateFilterDropdown,
    finalDateFilterDropdown,
    maximumAmountFilterDropdown,
    minimumAmountFilterDropdown,
  ])

  return renderTable
}

export default Table
