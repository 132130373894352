import styled, { css } from 'styled-components'

import TextCmp from 'components/Text'
import InputCmp from 'components/Inputs/Text'
import SelectCmp from 'components/Inputs/Select'
import InputPasswordCmp from 'components/Inputs/Text/Password'
import ButtonCmp from 'components/Button'
import IconExporter from 'components/IconExporter'
import ModalCmp from 'components/Modal'
import { CloseButton } from 'components/Modal/styles'
import LoadingCmp from 'components/Loading'

export const Icon = styled(IconExporter)``

export const Button = styled(ButtonCmp)``

export const InputPassword = styled(InputPasswordCmp)``

export const Select = styled(SelectCmp)`
  margin-top: 8px;
  margin-bottom: 16px;
`

export const FormSectionTitle = styled(TextCmp)`
  margin-bottom: 15px;
`

export const FormSection = styled.div`
  margin: 20px 0;
  padding-bottom: 25px;
  border-bottom: 1px solid lightgray;
`

export const FormContainer = styled.div`
  margin: 30px 10px;
`

export const InputText = styled(InputCmp)``

export const InputGroup = styled.div`
  width: 100%;
  margin: 10px 0;
`

export const Grid2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  box-sizing: border-box;
`

export const Grid3 = styled.div`
  display: grid;
  grid-template-columns: 0.4fr 0.8fr 0.1fr;
  grid-column-gap: 40px;
  box-sizing: border-box;
`

export const Grid4 = styled.div`
  display: grid;
  grid-template-columns: 0.4fr 0.4fr 0.4fr 0.1fr;
  grid-column-gap: 40px;
  box-sizing: border-box;
`

export const ModalHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  line-height: 100px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme2};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 3;
`

export const PillItem = styled.div<{ isSelected }>`
  display: inline-block;
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin: ${({ theme }) => theme.spacing(2)}px;
  border-radius: 8px;

  ${(props) =>
    props.isSelected
      ? css`
          background-color: ${({ theme }) => theme.colors.lightmaincolor3};

          > h5 {
            color: ${({ theme }) => theme.colors.maincolor};
          }
        `
      : css``}

  :hover {
    > h5 {
      color: ${({ theme }) => theme.colors.maincolor};
      cursor: pointer;
    }
  }

  > h5 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Text = styled(TextCmp)``

export const ModalContent = styled.div`
  width: 850px;
  padding: 0 16px;
`

export const Modal = styled(ModalCmp)`
  ${CloseButton} {
    z-index: 4;
  }

  > div > div {
    margin-top: 40px;
  }
`

export const Loading = styled(LoadingCmp)`
  margin-right: 16px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const TooltipText = styled.span`
  visibility: hidden;
  width: 290px;
  background-color: ${({ theme }) => theme.colors.maincolor};
  color: white;
  max-height: 40px;
  text-align: end;
  border-radius: 6px;
  padding: 8px 8px;
  position: absolute;
  z-index: 4;
  bottom: 115%;
  margin-left: -200px;
  ::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 95%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.maincolor} transparent
      transparent transparent;
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 8px;

  > svg {
    margin-left: 8px;
  }

  :hover {
    ${TooltipText} {
      visibility: visible;
    }
  }
`

export const InputGroupStorePos = styled.div`
  width: 100%;
  margin: 10px 0;
`

export const InputTextStorePos = styled(InputCmp)``
