import styled from 'styled-components'
import ButtonCmp from 'components/Button'
import IconCmp from 'components/IconExporter'
interface IButtonProps {
  color?: string
  selected?: boolean
}
export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 50px;
  margin: 24px 0 36px 0;
`

export const Button = styled(ButtonCmp)<IButtonProps>`
  background-color: ${({ color, theme }) =>
    color ? theme.colors[color] : 'white'};
  font-weight: bold;
  display: flex;
  justify-content: center;
  color: ${({ selected, theme }) =>
    selected ? theme.colors.graytheme6 : theme.colors.maincolor};
  width: 32px;
  margin-inline: ${({ theme }) => theme.spacing(1)}px; ;
`
export const SideButton = styled(ButtonCmp)<IButtonProps>`
  background-color: ${(props) => (props.color ? props.color : null)};
  margin-inline: 24px;
  color: ${({ theme }) => theme.colors.graytheme6};
  font-weight: bolder;
  padding: ${({ theme }) => theme.spacing(1)}px;
  box-shadow: 0px 1px 8px 2px ${({ theme }) => theme.colors.graytheme3};

  * > svg {
    margin: 0 8px;
  }
`
export const Icon = styled(IconCmp)``
