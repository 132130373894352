export const Types = {
  GET_STORE: 'GET_STORE',
  GET_STORE_LOADING: 'GET_STORE_LOADING',
  GET_STORE_ERROR: 'GET_STORE_ERROR',
  POST_STORE_REQUEST: 'POST_STORE_REQUEST',
  POST_STORE_RESPONSE: 'POST_STORE_RESPONSE',
  POST_STORE_ERROR: 'POST_STORE_ERROR',
  DELETE_STORE_REQUEST: 'DELETE_STORE_REQUEST',
  DELETE_STORE_RESPONSE: 'DELETE_STORE_RESPONSE',
}

export interface RequestInterface {
  city_name: string
  cnpj_cpf: string
  headquarter?: boolean
  id?: string
  name: string
  neighborhood: string
  reference: string
  state_name: string
  street_name: string
  street_number: string
  zip_code: string
}

export interface ResponseInterface {
  id?: string
  code?: number
  message?: string
}

export interface PayloadInterface {
  active: boolean
  city_name: string
  cnpj_cpf: string
  headquarter: boolean
  id: string
  meta_data: any
  name: string
  neighborhood: string
  pos: [{ active: boolean; category: string; id: string; name: string }]
  reference: string
  state_name: string
  street_name: string
  street_number: string
  zip_code: string
}

export interface ActionInterface {
  type: keyof typeof Types
  payload: PayloadInterface | ResponseInterface
  response: ResponseInterface
  message: string
}

export interface StoreState {
  store: PayloadInterface
  postResponse: ResponseInterface
  isLoading: boolean
  hasError: boolean
  postIsLoading: boolean
  postHasError: boolean
  postHasErrorMessage: string
  deleteIsLoading: boolean
  deleteResponse: ResponseInterface
}
