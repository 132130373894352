export const getValueFromLocalStorage = (key: string) => {
  return localStorage.getItem(key)
}

export const setValueToLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value)
}

export const removeValueFromLocalStorage = (key: string) => {
  localStorage.removeItem(key)
}
