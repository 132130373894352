import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const Toast = styled(ToastContainer)`
  .Toastify__toast--info {
    background: 'rgb(51, 102, 255)';
  }
  .Toastify__toast--success {
    background: ${({ theme }) => theme.colors.maincolor};
  }
  .Toastify__toast--warning {
    background: 'rgb(254, 255, 20)';
  }
  .Toastify__toast--error {
    background: '#D21414';
  }
  .CustomToastError {
    ul {
      list-style: none;
    }

    ul li:before {
      content: '•';
      font-size: 110%;
      padding-right: 2px;
    }
  }
`
