import { baseURLPaymentsV1, get } from '../api'

const getSummaryCashout = (params) => {
  return get(`${baseURLPaymentsV1}`, { params: params.params })
}

const summaryCashoutService = {
  getSummaryCashout,
}

export default summaryCashoutService
