import styled, { css, keyframes } from 'styled-components'

import WalletCardCmp from 'components/Card/WalletDisplayCard'
import TextCmp from 'components/Text'
import LoadingCmp from 'components/Loading'
import IconCmp from 'components/IconExporter'

import * as a from 'animations'

interface WalletCard {
  disabled?: boolean
}

const gradient = keyframes`
  0% {background-position: 0%}
  100% {background-position: 100%}
`

export const TBanksCard = styled.div<WalletCard>`
  position: relative;
  height: 128px;
  width: 320px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
    0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.gray3
      : css`
        linear-gradient(180deg, #bc5def 0%, #5e217f 100%);
  `};
  background-size: 200% 100%;
  ${({ disabled }) =>
    disabled
      ? css``
      : css`
          animation: ${gradient} 16s linear infinite;
        `}
  animation-direction: alternate;

  & > img {
    position: absolute;
    top: 0;
    margin: 22px 0px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${({ disabled }) =>
      disabled
        ? css``
        : css`
            animation: ${gradient} 16s linear infinite,
              ${a.cardAnimationShipayIn} 0.5s infinite alternate;
          `}
  }
`
export const TBanksCardCashout = styled.div<WalletCard>`
  position: relative;
  height: 128px;
  width: 320px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
    0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.gray3
      : css`
        linear-gradient(180deg, #bc5def 0%, #5e217f 100%);
  `};
  background-size: 200% 100%;
  ${({ disabled }) =>
    disabled
      ? css``
      : css`
          animation: ${gradient} 16s linear infinite;
        `}
  animation-direction: alternate;

  & > img {
    position: absolute;
    top: 0;
    margin: 32px 0px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${({ disabled }) =>
      disabled
        ? css``
        : css`
            animation: ${gradient} 16s linear infinite,
              ${a.cardAnimationShipayIn} 0.5s infinite alternate;
          `}
  }
`

export const OriginalCard = styled.div<WalletCard>`
  position: relative;
  height: 128px;
  width: 320px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
    0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.gray3
      : css`
        linear-gradient(180deg, #3cdd6f 0%, #18ad47 100%);
  `};
  background-size: 200% 100%;
  ${({ disabled }) =>
    disabled
      ? css``
      : css`
          animation: ${gradient} 16s linear infinite;
        `}
  animation-direction: alternate;

  & > img {
    position: absolute;
    top: 0;
    margin: 22px 0px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${({ disabled }) =>
      disabled
        ? css``
        : css`
            animation: ${gradient} 16s linear infinite,
              ${a.cardAnimationShipayIn} 0.5s infinite alternate;
          `}
  }
`

export const PicPayCard = styled.div<WalletCard>`
  position: relative;
  height: 128px;
  width: 320px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
    0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.gray3
      : css`
        linear-gradient(180deg, #60a969 0%, #3f9349 100%);
  `};
  background-size: 200% 100%;
  ${({ disabled }) =>
    disabled
      ? css``
      : css`
          animation: ${gradient} 16s linear infinite;
        `}
  animation-direction: alternate;

  & > img {
    position: absolute;
    top: 0;
    margin: 8px 0px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${({ disabled }) =>
      disabled
        ? css``
        : css`
            animation: ${gradient} 16s linear infinite,
              ${a.cardAnimationShipayIn} 0.5s infinite alternate;
          `}
  }
`

export const ItauCard = styled.div<WalletCard>`
  position: relative;
  height: 128px;
  width: 320px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 4px #a3bacc;

  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.gray3
      : css`
        linear-gradient(180deg, #f98f53 0%, #e55f13 100%);
  `};
  background-size: 200% 100%;
  ${({ disabled }) =>
    disabled
      ? css``
      : css`
          animation: ${gradient} 16s linear infinite;
        `}
  animation-direction: alternate;

  & > img {
    position: absolute;
    top: 0;
    margin: 12px 0px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${({ disabled }) =>
      disabled
        ? css``
        : css`
            animation: ${gradient} 16s linear infinite,
              ${a.cardAnimationShipayIn} 0.5s infinite alternate;
          `}
  }
`

export const RedeCard = styled.div<WalletCard>`
  position: relative;
  height: 128px;
  width: 320px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
    0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.gray3
      : css`
        linear-gradient(180deg, #595959 0%, #282828 100%);
  `};
  background-size: 200% 100%;
  ${({ disabled }) =>
    disabled
      ? css``
      : css`
          animation: ${gradient} 16s linear infinite;
        `}
  animation-direction: alternate;

  & > img {
    position: absolute;
    top: 0;
    margin: 8px 0px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${({ disabled }) =>
      disabled
        ? css``
        : css`
            animation: ${gradient} 16s linear infinite,
              ${a.cardAnimationShipayIn} 0.5s infinite alternate;
          `}
  }
`

export const WalletCard = styled(WalletCardCmp)``

interface IText {
  withGradient?: boolean
}

export const Text = styled(TextCmp)<IText>`
  ${(props) =>
    props.withGradient
      ? css`
          background: linear-gradient(
            ${({ theme }) => theme.colors.lightmaincolor4},
            ${({ theme }) => theme.colors.maincolor}
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        `
      : css``};
`

export const Loading = styled(LoadingCmp)`
  margin-left: 16px;
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const FooterWrapper = styled.div`
  background: rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: absolute;
  margin: 10px 0px;
  padding: 4px 0px;
  width: 90%;
  bottom: 0px;
`
export const TextCardFooter = styled(TextCmp)`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 149%;
  text-align: center;
  color: #ffffff;

  & > img {
    display: inline;
  }
`

export const Icon = styled(IconCmp)``
