import {
  Types,
  ResponseInterface,
} from './types'

export function PostCustomerRegistration(
  payload: ResponseInterface
): {
  type: string,
  payload: ResponseInterface
} {
  return {
    type: Types.POST_RESPONSE,
    payload
  }
}

export function PostCustomerRegistrationIsLoading(): {
  type: string,
} {
  return {
    type: Types.POST_LOADING,
  }
}

export function PostCustomerRegistrationError(): {
  type: string,
} {
  return {
    type: Types.POST_ERROR,
  }
}
