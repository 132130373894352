import React from 'react'
import { v4 as uuid } from 'uuid'

import useGetSystemWallets from 'hooks/useGetSystemWallets'
import useWalletsSettings from 'hooks/useWalletsSettings'

import Toast from 'components/Toast'

import { SystemWalletsInterface } from 'store/modules/systemWallets/types'

import { themeColors } from 'styles/theme'

import EditWalletModal from './EditWalletModal'
import ConfigWalletModal from './ConfigWalletModal'

import * as s from './styles'

const Wallets: React.FC = () => {
  const { systemWallets, isLoading, hasError, loadSystemWallets } =
    useGetSystemWallets()

  const {
    walletSettings,
    isLoading: isLoadingWalletSettings,
    hasError: hasErrorWalletSettings,
    loadWalletsSettings,
    patchWalletSettings,
    patchIsLoading,
  } = useWalletsSettings()

  const [eWallets, setEWallets] = React.useState([])
  const [detailsId, setDetailsId] = React.useState<string>('')
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
  const controller = new AbortController()
  const [isConfigModalOpen, setIsConfigModalOpen] =
    React.useState<boolean>(false)
  const [selectedEWallet, setSelectedEWallet] = React.useState<string>('')
  const [offset, setOffset] = React.useState<string>('0')

  React.useEffect(() => {
    loadSystemWallets()
  }, [loadSystemWallets])

  React.useEffect(() => {
    loadWalletsSettings({
      limit: '10',
      offset: offset,
      filters: window.btoa(
        unescape(
          encodeURIComponent(
            JSON.stringify({ wallet_type: ['c-wallet', 'e-wallet'] })
          )
        )
      ),
    })
  }, [offset, loadWalletsSettings])

  React.useEffect(() => {
    if (systemWallets.length) {
      let filteredEWallets: SystemWalletsInterface[] = systemWallets.filter(
        (wallet) =>
          wallet.wallet_type === 'e-wallet' || wallet.wallet_type === 'c-wallet'
      )
      setEWallets([...filteredEWallets])
    }
  }, [loadSystemWallets, systemWallets])

  const handlePatch = (uuid) => {
    const index = walletSettings.data.findIndex((wallet) => wallet.id === uuid)
    const newWallets = walletSettings.data
    newWallets[index].active = !newWallets[index].active
    patchWalletSettings(uuid, { active: newWallets[index].active }).then(
      (status) => {
        if (status !== '200') {
          loadWalletsSettings({
            limit: '20',
            offset: offset,
            filters: window.btoa(
              unescape(
                encodeURIComponent(
                  JSON.stringify({ wallet_type: ['c-wallet', 'e-wallet'] })
                )
              )
            ),
          })
        }
      }
    )
  }

  const renderSwitch = ({ children, index }) => {
    const switchIds = walletSettings.data.map((item) => item.id)
    const uuidObj = switchIds[index]
    const data = Object.assign(
      {},
      { children: children, index: index },
      uuidObj
    )

    return (
      <>
        <s.ToggleSwitch
          disabled={patchIsLoading}
          handleChange={() => {
            if (!patchIsLoading) {
              handlePatch(uuidObj)
            }
          }}
          data={data}
        />
      </>
    )
  }

  const renderWalletName = ({ children }) => {
    return (
      <s.Text color="graytheme6" bold>
        {children}
      </s.Text>
    )
  }

  const renderName = ({ children, index }) => {
    const wallets = walletSettings.data.map((item) => {
      return { name: item.wallet_name, icon: item.wallet_icon }
    })
    const walletName = wallets[index].name
    const walletIcon = wallets[index].icon
    return (
      <s.WalletWrapper>
        <img alt={`Ícone da carteira ${walletName}`} src={walletIcon} />
        <s.Text
          margin="0px 0px 0px 8px"
          bold
          color={walletName as keyof typeof themeColors}
        >
          {children}
        </s.Text>
      </s.WalletWrapper>
    )
  }

  const memoizedWallets = React.useMemo(
    () => (
      <s.WalletsWrapper>
        {eWallets
          .sort((x, y) => {
            if (x.wallet_name > y.wallet_name) return 1
            if (x.wallet_name < y.wallet_name) return -1
            return 0
          })
          .map((wallet) => {
            return (
              <s.WalletCard
                key={uuid()}
                name={wallet.wallet_name}
                color={wallet.wallet_name}
                disabled={!wallet.active}
                check={wallet.check}
                clickCallBack={() => {
                  setIsConfigModalOpen(true)
                  setSelectedEWallet(wallet.wallet_name)
                }}
                type="ewallet"
                height={128}
                width={128}
              />
            )
          })}
      </s.WalletsWrapper>
    ),
    [eWallets]
  )

  const memoizedWalletSettings = React.useMemo(
    () => (
      <s.TableWrapper>
        {!hasErrorWalletSettings ? (
          <s.Table
            data={walletSettings.data}
            seeMoreButton
            detailsIdState={[detailsId, setDetailsId]}
            isModalOpenState={[isModalOpen, setIsModalOpen]}
            isLoading={isLoadingWalletSettings}
            columns={[
              {
                headerName: 'Apelido da Conta',
                objectName: 'name',
                AltRender: renderName,
              },
              {
                headerName: 'Provedor',
                objectName: 'wallet_name',
                AltRender: renderWalletName,
              },
              {
                headerName: 'Status',
                objectName: 'active',
                AltRender: renderSwitch,
              },
            ]}
          />
        ) : (
          <s.Text color="maincolor" data-testid="error-text" type="heading3">
            Erro na API, tente novamente mais tarde
          </s.Text>
        )}
      </s.TableWrapper>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      walletSettings.data,
      isLoadingWalletSettings,
      hasErrorWalletSettings,
      isModalOpen,
      detailsId,
    ]
  )

  const handleContent = () => {
    if (hasError) {
      return (
        <s.Text color="maincolor" data-testid="error-text" type="heading3">
          Erro na API, tente novamente mais tarde
        </s.Text>
      )
    }
    if (isLoading) {
      return (
        <s.LoadingWrapper>
          <s.Text
            data-testid="loading-text-wallets"
            color="maincolor"
            type="heading3"
          >
            Carregando...
          </s.Text>
          <s.Loading
            type="spinner"
            data-testid="loading-spinner1"
            color="maincolor"
          />
        </s.LoadingWrapper>
      )
    }
    if (systemWallets.length > 0) {
      return (
        <>
          {memoizedWallets}
          {memoizedWalletSettings}
        </>
      )
    }
  }

  return (
    <s.Container>
      <Toast />
      <EditWalletModal
        isModalOpen={isModalOpen}
        handleClose={() => {
          controller.abort()
          setIsModalOpen(!isModalOpen)
        }}
        detailsId={detailsId}
        offset={offset}
      />
      <ConfigWalletModal
        isModalOpen={isConfigModalOpen}
        offset={offset}
        handleClose={() => setIsConfigModalOpen(!isConfigModalOpen)}
        wallet={selectedEWallet}
      />
      <s.Text type="heading3" bold color="maincolor">
        Qual Carteira Digital você deseja cadastrar ?
      </s.Text>
      {handleContent()}
      <s.PaginationWrapper>
        {walletSettings.total > 0 ? (
          <s.Pagination
            count={10}
            offset={offset}
            total={walletSettings.total}
            handleChange={(offset) => {
              setOffset(offset)
            }}
          />
        ) : null}
      </s.PaginationWrapper>
    </s.Container>
  )
}

export default Wallets
