import styled from "styled-components";


interface iColorSquare {
  color?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: center;
  justify-content: center;
  width: 234px;
  height: 40px;
  background: #FFFFFF;
  box-shadow: 0px 1px 8px 2px #A3BACC;
  border-radius: 8px;
`

export const Wrapper = styled.div`
  display: flex;
  width: 224px;
  height: 32px;
  border-radius: 4px;

  div:first-child {
     border-radius: 4px 0px 0px 4px;
  }
    
  div:last-child {
     border-radius: 0px 4px 4px 0px;
  }
    
`

export const ColorSquare = styled.div<iColorSquare>`
  display: flex;
  width: 32px;
  height: 32px;
  background: ${props => props.color ? props.color : "grey"};
`
