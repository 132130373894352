import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'store/modules/rootState'

import {
  PatchUser,
  PatchUserError,
  PatchUserIsLoading,
} from 'store/modules/user/action'

import { UserState } from 'store/modules/user/types'

import userService, { UserInterface } from 'services/user'

import { Partial } from 'helpers/partial'

import { showToast } from 'components/Toast'

interface UsePatchUser extends UserState {
  editUser: (userId: string, user: Partial<UserInterface>) => Promise<void>
}

export const usePatchUser = (): Partial<UsePatchUser> => {
  const dispatch = useDispatch()
  const { patchResponse, patchIsLoading, patchError } = useTypedSelector(
    (store) => ({
      patchResponse: store.user.patchResponse,
      patchIsLoading: store.user.patchIsLoading,
      patchError: store.user.patchError,
    })
  )

  const editUser = useCallback(
    async (userId, user): Promise<void> => {
      dispatch(PatchUserIsLoading())
      await userService
        .editUser(userId, user)
        .then((response) => {
          dispatch(PatchUser(response.data))
        })
        .catch(() => {
          dispatch(PatchUserError())
        })
    },
    [dispatch]
  )

  return { patchResponse, patchIsLoading, patchError, editUser }
}
