import React from 'react'

import * as s from './styles'

interface IProps {
  disabled?: boolean
}

const ButtonLink: React.FC<
  IProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ children, disabled = false, ...rest }) => {
  return (
    <s.Link disabled={disabled} {...rest}>
      {children}
    </s.Link>
  )
}

export default ButtonLink
