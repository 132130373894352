import {
  ActionInterface,
  Types,
  CompleteRegistrationState,
} from './types'

const INITIAL_STATE: CompleteRegistrationState = {
  error: false,
  isLoading: false,
  response: { code: -1, message: ''},
}

const CompleteRegistrationReducer = (
  state = INITIAL_STATE,
  action: ActionInterface,
): CompleteRegistrationState => {
  switch (action.type) {
    case Types.POST_RESPONSE:
      return {
        error: false,
        isLoading: false,
        response: action.payload,
      }
    case Types.POST_LOADING:
      return {
        ...state,
        error: false,
        isLoading: true,
      }
    case Types.POST_ERROR:
      return {
        ...state,
        error: true,
        isLoading: false,
      }
    default:
      return state
  }
}

export default CompleteRegistrationReducer
