import { CSVLink } from "react-csv";
import styled from "styled-components";
import ButtonCmp from 'components/Button'
import TextCmp from 'components/Text'


export const Csv = styled(CSVLink)``;

export const ExportButton = styled(ButtonCmp)`
  position: absolute;
  width: 100px;
  top: 30px;
  right: 80px;
`;

export const Text = styled(TextCmp)`
  font-size: 18px;
`