export const Types = {
  GET_STORE_WALLETS: 'GET_STORE_WALLETS',
  GET_STORE_WALLETS_LOADING: 'GET_STORE_WALLETS_LOADING',
  GET_STORE_WALLETS_ERROR: 'GET_STORE_WALLETS_ERROR',
  DELETE_STORE_WALLET_SUCCESS: 'DELETE_STORE_WALLET_SUCESS',
  DELETE_STORE_WALLET_LOADING: 'DELETE_STORE_WALLET_LOADING',
  DELETE_STORE_WALLET_ERROR: 'DELETE_STORE_WALLET_ERROR',
  PATCH_STORE_WALLET: 'PATCH_STORE_WALLET',
  PATCH_STORE_WALLET_LOADING: 'PATCH_STORE_WALLET_LOADING',
  PATCH_STORE_WALLET_ERROR: 'PATCH_STORE_WALLET_ERROR',
  POST_STORE_WALLET: 'POST_STORE_WALLET',
  POST_STORE_WALLET_LOADING: 'POST_STORE_WALLET_LOADING',
  POST_STORE_WALLET_ERROR: 'POST_STORE_WALLET_ERROR',
}

export interface StoreWalletsInterface {
  is_default: boolean
  store_id: string
  wallet_name: string
  wallet_setting_id: string
  wallet_setting_name: string
  psp_provider: string
}

export interface ActionInterface {
  type: keyof typeof Types
  payload: {
    count: number
    data: StoreWalletsInterface[]
    offset: number
    total: number
  }
}

export interface StoreWalletsState {
  storeWallets: {
    count: number
    data: StoreWalletsInterface[]
    offset: number
    total: number
  }
  isLoading: boolean
  hasError: boolean
  deleteIsLoading: boolean
  deleteHasError: boolean
  patchIsLoading: boolean
  patchHasError: boolean
  postIsLoading: boolean
  postHasError: boolean
}
