import { useState, useEffect } from 'react'

import { applyPhoneMask } from 'helpers/masks'
import { isNotEmpty, isValidEmail, isValidPhone } from 'helpers/validators'
import { useNonInitialEffect } from 'hooks/utils'
import { useGetRoles } from 'hooks/role'

import * as s from './styles'

interface IProps {
  onSubmit: (formData: any) => void
  submit: boolean
  clearForm: boolean
}

const UserForm: React.FC<IProps> = ({ submit, onSubmit, clearForm }) => {
  const formDefault = {
    user_full_name: { value: '', validator: isNotEmpty, touched: false },
    user_email: { value: '', validator: isValidEmail, touched: false },
    user_role_id: { value: '', validator: isNotEmpty, touched: false },
    user_phone: { value: '', validator: isValidPhone, touched: false },
  }
  const [form, setForm] = useState(formDefault)
  const [formErrors, setFormErrors] = useState<any>(
    Object.keys(formDefault).reduce((acc, cur) => ({ ...acc, [cur]: '' }), {})
  )

  const { roles, isLoading, loadRoles } = useGetRoles()
  const [selectedRole, setSelectedRole] = useState<any>({})
  const [roleSelectList, setRoleSelectList] = useState<any[]>([])

  useEffect(() => {
    loadRoles('Customer')
  }, [])

  useNonInitialEffect(() => {
    if (_validateFormForce()) onSubmit(form)
    else onSubmit(null)
  }, [submit])

  useNonInitialEffect(() => {
    setForm(formDefault)
  }, [clearForm])

  useNonInitialEffect(() => {
    setRoleSelectList(
      roles.map((r) => {
        return {
          id: r.uuid,
          text: r.name,
          onClick: () => {
            setSelectedRole({ id: r.uuid, name: r.name })
          },
        }
      })
    )
  }, [roles])

  useNonInitialEffect(() => {
    _validateForm()
  }, [form])

  useNonInitialEffect(() => {
    _setForm({ user_role_id: selectedRole.id })
  }, [selectedRole])

  const _validateForm = () => {
    const invalidFields = Object.entries(form).reduce((acc, cur) => {
      const [key, value] = cur
      if (value.touched && value.validator && !value.validator(value.value)) {
        return {
          ...acc,
          [key]: ' ',
        }
      }
      return {
        ...acc,
        [key]: '',
      }
    }, {})

    setFormErrors({ ...formErrors, ...invalidFields })
    if (invalidFields && Object.values(invalidFields).every((x) => !x))
      return true
    return false
  }

  const _validateFormForce = () => {
    const invalidFields = Object.entries(form).reduce((acc, cur) => {
      const [key, value] = cur
      if (value.validator && !value.validator(value.value)) {
        return {
          ...acc,
          [key]: ' ',
        }
      }
      return {
        ...acc,
        [key]: '',
      }
    }, {})

    setFormErrors({ ...formErrors, ...invalidFields })
    if (invalidFields && Object.values(invalidFields).every((x) => !x))
      return true
    return false
  }

  const _setFormTouched = () => {
    Object.keys(formDefault).forEach((field) => {
      setForm({
        ...form,
        [field]: {
          value: form[field].value,
          validator: form[field].validator,
          touched: true,
        },
      })
    })
  }

  const _setForm = (obj) => {
    const formObj = Object.entries(obj).reduce((acc, cur) => {
      const [key, value] = cur
      return {
        ...acc,
        [key]: {
          value: value,
          validator: form[key].validator,
          touched: true,
        },
      }
    }, {})
    setForm({ ...form, ...formObj })
  }

  return (
    <s.FormSection>
      <s.FormSectionTitle type="headline" bold color="black">
        Usuário
      </s.FormSectionTitle>
      <s.Grid2>
        <s.InputGroup>
          <s.InputText
            label="Nome Completo"
            value={form.user_full_name.value}
            onChange={(e) => _setForm({ user_full_name: e.target.value })}
            error={
              formErrors.user_full_name
                ? { message: formErrors.user_full_name }
                : false
            }
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputText
            label="E-mail"
            value={form.user_email.value}
            placeholder="user@email.com"
            onChange={(e) => _setForm({ user_email: e.target.value })}
            error={
              formErrors.user_email ? { message: formErrors.user_email } : false
            }
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputText
            label="Telefone"
            placeholder="(XX)XXXX-XXXXX"
            value={form.user_phone.value}
            onChange={(e) =>
              _setForm({ user_phone: applyPhoneMask(e.target.value) })
            }
            error={
              formErrors.user_phone ? { message: formErrors.user_phone } : false
            }
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.Text bold color="maincolor" type="headline">
            Permissão
          </s.Text>
          <s.Select
            isLoading={isLoading}
            options={roleSelectList}
            selected={selectedRole}
            error={
              formErrors.user_role_id
                ? { message: formErrors.user_role_id }
                : false
            }
          />
        </s.InputGroup>
      </s.Grid2>
    </s.FormSection>
  )
}

export default UserForm
