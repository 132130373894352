import { Types, ResponseInterface } from './types'

export function PostEWalletLoading(): {
  type: string
} {
  return {
    type: Types.POST_EWALLET_WALLET_SETTINGS_SAGA_LOADING,
  }
}

export function PostEWalletResponse(response: ResponseInterface) {
  return {
    type: Types.POST_EWALLET_WALLET_SETTINGS_SAGA_RESPONSE,
    response,
  }
}

export function PostEWalletError(message: string) {
  return {
    type: Types.POST_EWALLET_WALLET_SETTINGS_SAGA_ERROR,
    message,
  }
}
