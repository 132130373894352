import styled from 'styled-components'
import CardCmp from 'components/Card'
import InputTextCmp from 'components/Inputs/Text'
import ButtonCmp from 'components/Button'
import UserTableCmp from 'components/UserTable'
import ModalCmp from 'components/Modal'
import LoadingCmp from 'components/Loading'

export const ModalContent = styled.div`
  width: 800px;
  max-height: 90vh;
`

export const Card = styled(CardCmp)`
  width: 60%;
  max-height: unset;
  height: unset;
  padding: 20px;
  margin-bottom: 40px;
`

export const InputText = styled(InputTextCmp)``

export const InputGroup = styled.div`
  margin-bottom: 30px;
`

export const Button = styled(ButtonCmp)``

export const ButtonGroup = styled.div`
  text-align: center;
`

export const UserTable = styled(UserTableCmp)``

export const Modal = styled(ModalCmp)``

export const Loading = styled(LoadingCmp)``
