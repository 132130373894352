import React from 'react'

import CheckUser from './CheckUser'
import RegisterWallets from './RegisterWallets'

import Stepper from 'components/Stepper'
import SystemIntegration from './SystemIntegration'
import NavbarWizard from 'components/NavbarWizard'

const Wizard: React.FC = () => {
  const [currentStep, setCurrentStep] = React.useState<number>(1)
  const [firstStepIsValid, setFirstStepIsValid] = React.useState<boolean>(false)
  const [secondStepIsValid, setSecondStepIsValid] = React.useState<boolean>(
    false
  )

  const renderWizard = React.useMemo(() => {
    switch (currentStep) {
      case 1:
        return (
          <CheckUser
            firstStepIsValid={firstStepIsValid}
            setFirstStepIsValid={setFirstStepIsValid}
            nextStep={setCurrentStep}
          />
        )
      case 2:
        return (
          <RegisterWallets
            secondStepIsValid={secondStepIsValid}
            setSecondStepIsValid={setSecondStepIsValid}
            nextStep={setCurrentStep}
            previousStep={setCurrentStep}
          />
        )
      case 3:
        return (
          <SystemIntegration
            nextStep={setCurrentStep}
            previousStep={setCurrentStep}
          />
        )
    }
  }, [
    currentStep,
    firstStepIsValid,
    secondStepIsValid,
    setFirstStepIsValid,
    setSecondStepIsValid,
    setCurrentStep,
  ])

  return (
    <>
    <NavbarWizard>
      <Stepper
        numberOfSteps={3}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        stepValidators={[firstStepIsValid]}
      />
    </NavbarWizard>
      {renderWizard}
    </>
  )
}

export default Wizard
