import { get, bffURLRole } from '../api'

export interface RoleInterface {
  description: string
  name: string
  uuid: string
}

const getRoles = (level: string) => {
  return get(`${bffURLRole}?levels=${level}`)
}

const roleService = {
  getRoles,
}

export default roleService
