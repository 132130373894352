import styled from 'styled-components'

import TextCmp from 'components/Text'
import IconExporter from 'components/IconExporter'
import IconCmp from 'components/IconExporter'
import ModalCmp from 'components/Modal'
import ButtonCmp from 'components/Button'

export const Modal = styled(ModalCmp)`
  padding: 0em;
  > div {
    padding: 0;
    > div {
      padding-top: 0;
      
    }
  }
  header {
    border-bottom: 1px solid black;
    width: 100%;
    color: ${({ theme }) => theme.colors.gray1} !important;
    * {
      color: inherit;
    }
  }
`

export const ModalContainer = styled.div`
  width: 600px;
  padding: .2em 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Text = styled(TextCmp)`
  line-height: normal;
  > em {
    font-style: normal;
    color: ${({ theme }) => theme.colors.maincolor};
  }
`

export const Icon = styled(IconCmp)``

export const SalesFilesButton = styled(ButtonCmp)`
  height: 40px;
  margin: auto 0 auto auto;
  top: 8px;
`

export const ParagraphWrapper = styled.div`
  > h5,
  svg {
  vertical-align: middle;
  display: inline;
  margin: 0 2px;
  white-space: normal;
}
`

export const ButtonIcon = styled(IconExporter)`
  margin-right: 8px;
`

export const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};
  width: 100%;
  z-index: 0;
`

export const Overlay = styled.div`
  height: 100%;
  display: ${({ hidden }) => hidden ? 'none' : 'block'};
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #ff000044;
  z-index: 999;
  cursor: wait
`

export const Spacer = styled.div`
  flex-grow: 1;
  flex-shrink: 0
`

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: .33em;
  height: 50px;
  > h5, p {
    margin-inline: 8px;
  }
`


export const SectionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 50px);
`