import React from 'react'

import loadingbigger from 'assets/images/loading-bigger.png'

import * as s from './styles'

const Loading: React.FC = () => {
  return (
    <s.Content height={659} width={304} data-testid="content-on-loading">
      <s.Text margin="141px 0px 0px 0px" textAlign='center' color="graytheme6" bold type="heading4">
        Cadastrando conta...
      </s.Text>
      <s.Text margin="16px 0px 0px 0px" textAlign='center' color="graytheme6" type="headline">
        Deve levar alguns segundos.
      </s.Text>
      <s.LoadingGradient>
        <img width={114} src={loadingbigger} />
      </s.LoadingGradient>
    </s.Content>
  )
}

export default Loading
