import { Types, RequestInterface, ResponseInterface } from './types'

export function PostImpersonate(
  request: RequestInterface
): {
  type: string
  request: RequestInterface
} {
  return {
    type: Types.POST_IMPERSONATE,
    request,
  }
}

export function PostImpersonateResponse(
  response: ResponseInterface
): {
  type: string
  response: ResponseInterface
} {
  return {
    type: Types.POST_IMPERSONATE_RESPONSE,
    response,
  }
}

export function PostImpersonateError(
  message: { code: number, message: string}
) {
  return {
    type: Types.POST_IMPERSONATE_ERROR,
    message,
  }
}

export function RemoveImpersonate() {
  return {
    type: Types.IMPERSONATE_REMOVED,
  }
}
