import { useState, useEffect } from 'react'

import * as s from './styles'

import { useNonInitialEffect } from 'hooks/utils'
import { useGetRetailChainsByPosProduct } from 'hooks/retailChain'
import { isNotEmpty, isValidEmail } from 'helpers/validators'
import { isConexaoItau } from 'helpers'
import { useTheme } from 'styled-components'
import useGetRegistrationPosProducts from 'hooks/useGetPosProducts'

interface IProps {
  onSubmit: (formData: any) => void
  submit: boolean
  clearForm: boolean
}

const ShipayAccountForm: React.FC<IProps> = ({
  submit,
  onSubmit,
  clearForm,
}) => {
  const formDefault = {
    customer_name: { value: '', validator: isNotEmpty, touched: false },
    customer_email: { value: '', validator: isValidEmail, touched: false },
    pos_product_id: { value: '', validator: isNotEmpty, touched: false },
    retail_chain_id: { value: '', validator: isNotEmpty, touched: false },
  }
  const [form, setForm] = useState(formDefault)
  const [formErrors, setFormErrors] = useState<any>(
    Object.keys(formDefault).reduce((acc, cur) => ({ ...acc, [cur]: '' }), {})
  )

  const { posProducts, isLoading, getPosProducts } =
    useGetRegistrationPosProducts()
  const [selectedPosProduct, setSelectedPosProduct] = useState<any>({})
  const [posProductSelectList, setPosProductSelectList] = useState<any[]>([])

  const {
    retailChains,
    isLoading: isLoadingRetailChains,
    loadRetailChains,
  } = useGetRetailChainsByPosProduct()
  const [selectedRetailChain, setSelectedRetailChain] = useState<any>({})
  const [retailChainSelectList, setRetailChainSelectList] = useState<any[]>([])

  useEffect(() => {
    getPosProducts()
  }, [])

  useNonInitialEffect(() => {
    if (_validateFormForce()) onSubmit(form)
    else onSubmit(null)
  }, [submit])

  useNonInitialEffect(() => {
    setForm(formDefault)
  }, [clearForm])

  useNonInitialEffect(() => {
    setPosProductSelectList(
      posProducts.map((posProduct) => {
        return {
          id: posProduct.pos_product_uuid,
          text: posProduct.pos_product_name,
          onClick: () => {
            setSelectedPosProduct({
              id: posProduct.pos_product_uuid,
              name: posProduct.pos_product_name,
            })
            loadRetailChains(posProduct.pos_product_uuid)
          },
        }
      })
    )
  }, [posProducts])

  useNonInitialEffect(() => {
    setRetailChainSelectList(
      retailChains
        .filter((x) => x)
        .map((retailChain) => {
          return {
            id: retailChain.retail_chain_id,
            text: retailChain.retail_chain_name || retailChain.retail_name,
            onClick: () => {
              setSelectedRetailChain({
                id: retailChain.retail_chain_id,
                name: retailChain.retail_chain_name || retailChain.retail_name,
              })
            },
          }
        })
    )
  }, [retailChains])

  useNonInitialEffect(() => {
    _validateForm()
  }, [form])

  useNonInitialEffect(() => {
    _setForm({ pos_product_id: selectedPosProduct.id })
  }, [selectedPosProduct])

  useNonInitialEffect(() => {
    _setForm({ retail_chain_id: selectedRetailChain.id })
  }, [selectedRetailChain])

  const _validateForm = () => {
    const invalidFields = Object.entries(form).reduce((acc, cur) => {
      const [key, value] = cur
      if (value.touched && value.validator && !value.validator(value.value)) {
        return {
          ...acc,
          [key]: ' ',
        }
      }
      return {
        ...acc,
        [key]: '',
      }
    }, {})

    setFormErrors({ ...formErrors, ...invalidFields })
    if (invalidFields && Object.values(invalidFields).every((x) => !x))
      return true
    return false
  }

  const theme = useTheme()

  const _validateFormForce = () => {
    const invalidFields = Object.entries(form).reduce((acc, cur) => {
      const [key, value] = cur
      if (value.validator && !value.validator(value.value)) {
        return {
          ...acc,
          [key]: ' ',
        }
      }
      return {
        ...acc,
        [key]: '',
      }
    }, {})

    setFormErrors({ ...formErrors, ...invalidFields })
    if (invalidFields && Object.values(invalidFields).every((x) => !x))
      return true
    return false
  }

  const _setForm = (obj) => {
    const formObj = Object.entries(obj).reduce((acc, cur) => {
      const [key, value] = cur
      return {
        ...acc,
        [key]: {
          value: value,
          validator: form[key].validator,
          touched: true,
        },
      }
    }, {})
    setForm({ ...form, ...formObj })
  }

  return (
    <s.FormSection>
      <s.FormSectionTitle type="headline" bold color="black">
        Conta{' '}
        {theme.domain === 'ConexãoItaú'
          ? 'Conexão Itaú'
          : theme.domain === 'SpacePay'
          ? 'NDD Space Pay'
          : 'Shipay'}
      </s.FormSectionTitle>
      <s.Grid2>
        <s.InputGroup>
          <s.InputText
            label="Nome da Conta"
            value={form.customer_name.value}
            onChange={(e) => _setForm({ customer_name: e.target.value })}
            error={
              formErrors.customer_name
                ? { message: formErrors.customer_name }
                : false
            }
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.Text bold color="maincolor" type="headline">
            Sistema Integrado
          </s.Text>
          <s.Select
            error={
              formErrors.pos_product_id
                ? { message: formErrors.pos_product_id }
                : false
            }
            isLoading={isLoading}
            options={posProductSelectList}
            selected={selectedPosProduct}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputText
            label="E-mail"
            placeholder="user@email.com"
            value={form.customer_email.value}
            onChange={(e) => _setForm({ customer_email: e.target.value })}
            error={
              formErrors.customer_email
                ? { message: formErrors.customer_email }
                : false
            }
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.Text bold color="maincolor" type="headline">
            {theme.domain === 'ConexãoItaú' ? 'Grupo' : 'Rede'}
          </s.Text>
          <s.Select
            error={
              formErrors.retail_chain_id
                ? { message: formErrors.retail_chain_id }
                : false
            }
            isLoading={isLoadingRetailChains}
            options={retailChainSelectList}
            disabled={retailChainSelectList.length <= 0}
            selected={selectedRetailChain}
          />
        </s.InputGroup>
      </s.Grid2>
    </s.FormSection>
  )
}

export default ShipayAccountForm
