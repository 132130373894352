import styled, { css } from 'styled-components'

export const RadioInput = styled.input`
  height: 20px;
  margin-right: 10px;
  position: relative;
  min-width: 20px;

  &:before {
    box-shadow: 0px 1px 8px 1px #cce9ff;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.white};
    border: 2px solid
      ${(props) =>
        props.disabled
          ? props.theme.colors.graytheme2
          : props.theme.colors.lightmaincolor2};
  }
  &:after {
    content: '';
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    border-radius: 50%;
    background-color: transparent;
  }
  &:checked {
    &:after {
      background-color: ${(props) => props.theme.colors.maincolor};
    }
  }
  &:hover {
    ${({ disabled }) =>
      disabled
        ? css`
            cursor: not-allowed;
          `
        : css`
            cursor: pointer;
            &:before {
              background-color: ${(props) =>
                props.theme.colors.lightmaincolor3};
            }
          `}
  }
`

export const RadioLabel = styled.label<{
  disabled: boolean
}>`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  margin: 8px 16px 8px 0;

  ${({ theme, disabled }) => `
    color: ${disabled ? theme.colors.graytheme5 : theme.colors.maincolor}};
    pointer-events: ${disabled ? 'none' : 'all'};
  `}

  &:last-of-type {
    margin-right: 0;
  }
`

export const RadioInputWrapper = styled.div`
  display: flex;
  align-items: center;
`
