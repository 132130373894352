import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'store/modules/rootState'

import {
  DelUser,
  DelUserError,
  DelUserIsLoading,
} from 'store/modules/user/action'

import { UserState } from 'store/modules/user/types'

import userService from 'services/user'

import { Partial } from 'helpers/partial'

interface UseDelUser extends UserState {
  deleteUser: (userId: string) => Promise<void>,
}

export const useDelUser = (): Partial<UseDelUser> => {
  const dispatch = useDispatch()
  const { delResponse, delIsLoading, delError } = useTypedSelector((store) => ({
    delResponse: store.user.delResponse,
    delIsLoading: store.user.delIsLoading,
    delError: store.user.delError,
  }))

  const deleteUser = useCallback(async (userId): Promise<void> => {
    dispatch(DelUserIsLoading())
    await userService.deleteUser(userId)
      .then((response) => {
        dispatch(DelUser(response.data))
      })
      .catch(() => {
        dispatch(DelUserError())
      })
  }, [dispatch])

  return { delResponse, delIsLoading, delError, deleteUser}
}
