import {
  ActionInterface,
  Types,
  UserState,
  UserInterface,
  GetUsersResponseInterface,
  PostUserResponseInterface,
  PatchUserResponseInterface,
  DelUserResponseInterface,
} from './types'

const INITIAL_STATE: UserState = {
  user: {
    cpf: null,
    degree: null,
    email: null,
    first_name: null,
    id: null,
    last_name: null,
    level: null,
    phone: null,
    role_id: null,
  },
  isLoading: false,
  hasError: false,
  users: { count: 0, data: [], offset: 0, total: -1 },
  getUsersIsLoading: false,
  getUsersError: false,
  postResponse: { code: -1, message: '' },
  postIsLoading: false,
  postError: false,
  patchResponse: { code: -1, message: '' },
  patchIsLoading: false,
  patchError: false,
  delResponse: { code: -1, message: '' },
  delIsLoading: false,
  delError: false,
}

const userReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): UserState => {
  switch (action.type) {
    case Types.GET_USER:
      return {
        ...state,
        user: action.payload as UserInterface,
        isLoading: false,
        hasError: false,
      }
    case Types.GET_USER_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    case Types.GET_USERS:
      return {
        ...state,
        users: action.payload as GetUsersResponseInterface,
        getUsersIsLoading: false,
        getUsersError: false,
      }
    case Types.GET_USERS_LOADING:
      return {
        ...state,
        getUsersIsLoading: true,
        getUsersError: false,
      }
    case Types.GET_USERS_ERROR:
      return {
        ...state,
        getUsersIsLoading: false,
        getUsersError: true,
      }
    case Types.POST_USER:
      return {
        ...state,
        postResponse: action.payload as PostUserResponseInterface,
        postIsLoading: false,
        postError: false,
      }
    case Types.POST_USER_LOADING:
      return {
        ...state,
        postIsLoading: true,
        postError: false,
      }
    case Types.POST_USER_ERROR:
      return {
        ...state,
        postIsLoading: false,
        postError: true,
      }

    case Types.PATCH_USER:
      return {
        ...state,
        patchResponse: action.payload as PatchUserResponseInterface,
        patchIsLoading: false,
        patchError: false,
      }
    case Types.PATCH_USER_LOADING:
      return {
        ...state,
        patchIsLoading: true,
        patchError: false,
      }
    case Types.PATCH_USER_ERROR:
      return {
        ...state,
        patchIsLoading: false,
        patchError: true,
      }
    case Types.DEL_USER:
      return {
        ...state,
        delResponse: action.payload as DelUserResponseInterface,
        delIsLoading: false,
        delError: false,
      }
    case Types.DEL_USER_LOADING:
      return {
        ...state,
        delIsLoading: true,
        delError: false,
      }
    case Types.DEL_USER_ERROR:
      return {
        ...state,
        delIsLoading: false,
        delError: true,
      }
    default:
      return state
  }
}

export default userReducer
