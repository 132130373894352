import { Types, RequestInterface, ResponseInterface } from './types'

export function PostAuth(request: RequestInterface): {
  type: string
  request: RequestInterface
} {
  return {
    type: Types.POST_AUTH,
    request,
  }
}

export function PostAuthResponse(response: ResponseInterface): {
  type: string
  response: ResponseInterface
} {
  return {
    type: Types.POST_AUTH_RESPONSE,
    response,
  }
}

export function PostAuthError(message: string) {
  return { type: Types.POST_AUTH_ERROR, message }
}
