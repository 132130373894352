import styled from 'styled-components'
import CardCmp from 'components/Card'
import ButtonCmp from 'components/Button'
import TextCmp from 'components/Text'
import ModalCmp from 'components/Modal'
import TableCmp from 'components/Table'
import IconCmp from 'components/IconExporter'

export const Card = styled(CardCmp)`
  justify-content: center;
  width: 750px;
  padding: 30px;
  margin-bottom: 1rem;
`

export const ExportButton = styled(ButtonCmp)`
  position: absolute;
  top: 25px;
  right: 80px;
`

export const Icon = styled(IconCmp)``

export const ContentWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  flex-direction: row;
`

export const Button = styled(ButtonCmp)`
  position: absolute;
  right: 10px;
  bottom: -6px;
`

export const Text = styled(TextCmp)`
  font-size: 18px;
`

export const Modal = styled(ModalCmp)`
  > div > div {
    overflow-y: hidden;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const Table = styled(TableCmp)``

export const Summary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  width: 100%;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`
