import { get, baseURLPosProduct, baseURLPosVendor } from '../api'

const getPosProducts = () => {
  return get(`${baseURLPosProduct}`, {})
}

const getPosProductHierarchy = () => {
  return get(`${baseURLPosVendor}/hierarchy`)
}

const PosProductService = {
  getPosProducts,
  getPosProductHierarchy,
}

export default PosProductService
