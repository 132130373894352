import styled, { css } from 'styled-components'

import CardCmp from 'components/Card'
import TextCmp from 'components/Text'
import InputTextCmp from 'components/Inputs/Text'
import ColorPickerCmp from 'components/ColorPicker'
import ColorPalleteCmp from 'components/ColorPallete'
import UploadImageFileCmp from 'components/UploadFile'
import LoadingCmp from 'components/Loading'
import IconCmp from 'components/IconExporter'

import ButtonCmp from 'components/Button'

export const Card = styled(CardCmp)`
  min-height: auto;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 40px 64px 64px;
  > div {
    :last-child {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }
  }
`

export const Text = styled(TextCmp)``

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const InputText = styled(InputTextCmp)``

export const ColorWrapper = styled.div`
  display: flex;
  gap: 16px;
`

export const ColorPicker = styled(ColorPickerCmp)``

export const ColorPallete = styled(ColorPalleteCmp)``

export const UploadImageFile = styled(UploadImageFileCmp)``

export const TextWrapper = styled.div`
  * {
    :first-child {
      margin-bottom: 8px;
    }
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
`

export const Button = styled(ButtonCmp)`
  height: 40px;
  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
`

export const Loading = styled(LoadingCmp)`
  margin-left: 16px;
`

export const RoundedSquareContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const ShadesOfGrayCheckButton = styled.button<{ checked: boolean }>`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 10px;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.maincolor};
  :hover {
    cursor: pointer;
  }

  ${({ checked }) =>
    checked
      ? css`
          background: ${({ theme }) => theme.colors.lightmaincolor3};
          div {
            background: ${({ theme }) => theme.colors.maincolor};
          }
        `
      : css`
          background: inherit;
        `};
`

export const RoundedSquareWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 8px;
  margin-top: 16px;
  border: 2px solid ${({ theme }) => theme.colors.maincolor};
`

export const CheckSquare = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 2px solid ${({ theme }) => theme.colors.maincolor};
  svg {
    width: 10px;
    height: 10px;
  }
`

export const Icon = styled(IconCmp)`
  display: inline;
`
