import React, { useState, useEffect } from 'react'

import { getValueFromLocalStorage } from 'helpers/localStorage'

import * as s from './styles'
import { applyCnpjMask, applyCpfCnpjMask } from 'helpers/masks'
import { Info } from './WalletSettingsFormTbanksCashout'
import { baseURLUser, get } from 'services/api'
import { showToast } from 'components/Toast'
import { UserSessionInfo } from 'store/modules/auth/types'

const RepresentantConfirmation: React.FC<{
  info: Info
  setInfo: (info: Info) => void
  answer: (agree: boolean) => void
}> = ({ answer, info, setInfo }) => {
  const currentUserSession: UserSessionInfo = JSON.parse(getValueFromLocalStorage('currentUserSession'))
  const [hasCPF, setHasCPF] = useState<boolean>(false)
  const [isLoading, setIsloading] = useState<boolean>(false)

  const getUserCPF = async (id: string) => {
    setIsloading(true)
    await get(`${baseURLUser}/${id}`)
      .then((res) => {
        if (res.data?.cpf) {
          setHasCPF(true)
          setInfo({
            ...info,
            cpf: res.data.cpf,
          })
        } else {
          setHasCPF(false)
        }
      })
      .catch((e) => {
        showToast({
          type: 'error',
          message:
            JSON.parse(e.request.response)?.detail ??
            'Erro na API, insira o CPF manualmente.',
        })
      })
      .finally(() => {
        setIsloading(false)
      })
  }
  useEffect(() => {
    getUserCPF(currentUserSession.id)
  }, [])

  return (
    <s.Content
      height={648}
      width={304}
      data-testid="content-on-representant-confirmation"
    >
      <s.Text margin="0 0 16px 0" color="graytheme6" bold type="heading4">
        Prossiga com o CPF do usuário societário
      </s.Text>
      <s.Text color="graytheme6" type="headline">
        Somente um{' '}
        <s.UnderlineText>
          representante legal do CNPJ titular da conta bancária
        </s.UnderlineText>{' '}
        pode habilitar esta conta Pix. Faça login com outro usuário caso este
        não esteja no quadro societário.
      </s.Text>
      <s.InputsWrapper>
        <s.InputText
          width={304}
          labelColor="graytheme6"
          label="CNPJ da conta bancária"
          value={applyCnpjMask(info.document_number)}
          onChange={() => {}}
          disabled
        />
        <s.Text
          margin="32px 0px -20px 0px"
          bold
          type="headline"
          color="graytheme6"
        >
          Nome do usuário societário
        </s.Text>
        <s.UserWrapper data-testid="user-name-info">
          <s.Icon fill="darkmaincolor1" name="circlefilleduser" />
          <s.Text bold type="headline" color="darkmaincolor1">
            {currentUserSession.name}
          </s.Text>
        </s.UserWrapper>
        <s.InputText
          suffix={
            isLoading ? <s.Loading type="spinner" color="maincolor" /> : null
          }
          width={304}
          maxLength={14}
          placeholder={isLoading ? '' : '000.000.000-00'}
          label="CPF do usuário societário"
          disabled={hasCPF || isLoading}
          value={applyCpfCnpjMask(info.cpf)}
          onChange={(e) => {
            setInfo({
              ...info,
              cpf: e.target.value,
            })
          }}
        />
      </s.InputsWrapper>

      <s.ButtonWrapper margin="56px 0px 0px 0px">
        <s.Button
          data-testid="representant-button"
          height="42px"
          disabled={info.cpf?.replace(/[^0-9]/g, '').length < 11}
          onClick={() => answer(true)}
        >
          <s.Text color="white" type="headline">
            Sou um representante
          </s.Text>
        </s.Button>
        <s.Button
          data-testid="not-representant-button"
          onClick={() => answer(false)}
          height="42px"
          color="whitedetailed"
        >
          <s.Text color="maincolor" type="headline">
            Não sou um representante
          </s.Text>
        </s.Button>
      </s.ButtonWrapper>
    </s.Content>
  )
}

export default RepresentantConfirmation
