import React from 'react'
import { useDispatch } from 'react-redux'

import { get, baseURLBffCustomers } from '../services/api'
import { useTypedSelector } from '../store/modules/rootState'
import {
  GetCustomers,
  GetCustomersIsLoading,
  GetCustomersError,
} from 'store/modules/admin/action'

import { CustomersState } from 'store/modules/admin/types'

interface UseGetCustomers extends CustomersState {
  loadCustomers: (params?: {
    filter?: string
    filter_type?: string
    limit: string
    offset: string
  }) => Promise<void>
}

const useGetCustomers = (): UseGetCustomers => {
  const dispatch = useDispatch()

  const { customers, isLoading, hasError } = useTypedSelector((store) => ({
    customers: store.customers.customers,
    isLoading: store.customers.isLoading,
    hasError: store.customers.hasError,
  }))

  const loadCustomers = React.useCallback(
    async (params): Promise<void> => {
      dispatch(GetCustomersIsLoading())
      await get(`${baseURLBffCustomers}/summary`, {
        params: params || '',
      })
        .then((response) => {
          dispatch(GetCustomers(response.data))
        })
        .catch(() => {
          dispatch(GetCustomersError())
        })
    },
    [dispatch]
  )

  return { customers, hasError, isLoading, loadCustomers }
}

export default useGetCustomers
