export const Types = {
  GET_RECONCILIATION_SALES_FILES: 'GET_RECONCILIATION_SALES_FILES',
  GET_RECONCILIATION_SALES_FILES_LOADING:
    'GET_RECONCILIATION_SALES_FILES_LOADING',
  GET_RECONCILIATION_SALES_FILES_ERROR: 'GET_RECONCILIATION_SALES_FILES_ERROR',
}

export interface OrdersSummaryFilesInterface {
  total: number
  count: number
  data: [
    {
      created_at: string
      end_date: string
      file_name: string
      start_date: string
      extension: string
      file_type: string
      status: string
      part?: string
    }
  ]
  offset: number
}

export interface ActionInterface {
  type: keyof typeof Types
  payload: OrdersSummaryFilesInterface
}

export interface OrdersSummaryFilesState {
  ordersSummaryFiles: OrdersSummaryFilesInterface
  isLoading: boolean
  hasError: boolean
}
