import React, { createRef, forwardRef, InputHTMLAttributes } from 'react'
import { dict } from 'components/IconExporter'

import * as s from '../styles'

interface IProps {
  prefix?: React.ReactNode
  error?: boolean | { icon?: keyof typeof dict; message: string }
  disabled?: boolean
  width?: number | string
  height?: number
  label?: string
  testId?: string
  placeholder?: string
  maxLength?: number
  type?: string
  enableShowPasswordOuterButton?: {
    visibled: boolean
    onClick?: () => void
  }
  hideEyeIcon?: boolean
}

const InputText = forwardRef<
  HTMLInputElement,
  IProps & Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'>
>(
  (
    {
      prefix,
      error,
      width,
      height = 40,
      onChange,
      maxLength,
      value,
      label,
      testId = '',
      placeholder,
      disabled,
      type,
      enableShowPasswordOuterButton = null,
      hideEyeIcon = false,
      ...rest
    },
    ref
  ) => {
    const inputRef = (ref || createRef()) as React.RefObject<HTMLInputElement>
    const [visiblePassword, setVisiblePassword] = React.useState<boolean>(
      enableShowPasswordOuterButton
        ? enableShowPasswordOuterButton.visibled
        : false
    )

    return (
      <s.InputFieldGroup height={height} hasLabel={!!label} disabled={disabled}>
        <s.InputGroupWrapper disabled={disabled}>
          {label ? (
            <s.Text
              bold
              type="headline"
              color={error ? 'lightred1' : 'maincolor'}
            >
              {label}
            </s.Text>
          ) : null}
          <s.InputWrapper
            error={error}
            height={height}
            width={width}
            disabled={disabled}
            onClick={() => {
              inputRef && inputRef.current && inputRef.current.focus()
            }}
            {...rest}
          >
            {prefix && <s.InputAddon id="prefix">{prefix}</s.InputAddon>}
            <s.Input
              ref={inputRef}
              maxLength={maxLength}
              type={
                visiblePassword || enableShowPasswordOuterButton?.visibled
                  ? 'text'
                  : 'password'
              }
              disabled={disabled}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              data-testid={label?.toLowerCase() || testId || 'input-password'}
            />
            <s.InputAddon
              onClick={() => {
                if (enableShowPasswordOuterButton) {
                  enableShowPasswordOuterButton?.onClick?.()
                }
                setVisiblePassword(!visiblePassword)
              }}
              id="suffix"
            >
              {!hideEyeIcon && (
                <s.Icon
                  name={
                    enableShowPasswordOuterButton?.visibled || visiblePassword
                      ? 'eyeon'
                      : 'eyeoff'
                  }
                  fill="graytheme4"
                />
              )}
            </s.InputAddon>
          </s.InputWrapper>
        </s.InputGroupWrapper>
        {error && typeof error === 'object' && (
          <s.ErrorMessage>
            {error.icon ? <s.ErrorIcon name={error.icon} width="16px" /> : null}
            <s.TextError color="lightred1">{error.message}</s.TextError>
          </s.ErrorMessage>
        )}
      </s.InputFieldGroup>
    )
  }
)

export default InputText
