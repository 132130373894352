import React from 'react'

import { formatMoney } from 'helpers/masks'

import * as s from './styles'

interface IProps {
  label: string
  status: string
  value: string
  openModal: () => void
}

const statusColor: any = {
  success: 'maincolor',
  warning: 'orange',
  error: 'redshipay',
}

const MainStatusCard: React.FC<IProps> = ({
  label,
  status,
  value,
  openModal,
}) => {
  return (
    <>
      <s.Card height={100}>
        <s.ContentWrapper>
          <s.Icon
            name={
              status === 'success'
                ? 'checkmarkcirclefilled'
                : 'errorcirclefilled'
            }
            fill={statusColor[status]}
          />
          <s.Text color={statusColor[status]} bold>
            {label}
          </s.Text>
          {value !== 'R$ 0,00' ? (
            <s.Text color={statusColor[status]} bold>
              {formatMoney(value)}
            </s.Text>
          ) : (
            <></>
          )}
          {value !== 'R$ 0,00' ? (
            <s.Button onClick={() => openModal()} color="secondary" outline>
              <s.Text color="maincolor" bold>
                Detalhes
              </s.Text>
            </s.Button>
          ) : (
            <></>
          )}
        </s.ContentWrapper>
      </s.Card>
    </>
  )
}

export default MainStatusCard
