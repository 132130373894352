import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './translations/i18n'
import App from 'App'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'G-0EB4DBTLDP',
}

TagManager.initialize(tagManagerArgs)

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container!)
root.render(<App />)
