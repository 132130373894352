
import React, { useEffect } from "react"
import { getValueFromLocalStorage, setValueToLocalStorage } from "helpers/localStorage"
import * as s from './styles'
import useGetUser from 'hooks/useGetUser'
import { UserSessionInfo } from "store/modules/auth/types"


interface IProps {
  isOpen: string
  setCurrentStep: any
  handleClose: ()=> any
}


export const PrePaymentConclusion: React.FC<IProps> = ({ isOpen, handleClose, setCurrentStep })=> {

  const {
    user,
    hasError: userHasError,
    addressIsLoading: useraddressIsLoading,
    loadUser,
  } = useGetUser()
  const [email, setEmail] = React.useState<string>('')

  useEffect(() => {
    const currentUserSession: UserSessionInfo = JSON.parse(getValueFromLocalStorage('currentUserSession'))
    loadUser(currentUserSession.id)
    setEmail(user.email)
  }, [])

  useEffect(() => {
    setEmail(user.email)
  }, [user])
  
  return(
    <s.Modal 
      handleClose={handleClose}
      isOpen={(isOpen) ? true : false}
      modalSubtitle={"Simular taxa de antecipação"}>
      <s.Line/>
      <s.ModalContent>
        <s.FormWrapper>
          <s.TextParagraph textAlign="justify">
            Você deverá continuar o processo
            através dos links enviados para o seu endereço de e-mail
          </s.TextParagraph>
          <s.EmailWrapper>
            <s.InputText
              value={email}
              maxLength={100}
              disabled={true}
            />
          </s.EmailWrapper>
          
          <s.TextParagraph textAlign="justify"> 
            1. Defina sua senha através do e-mail enviado pela TbankS
          </s.TextParagraph>

          <s.TextParagraph textAlign="justify">
            2. Continue seu cadastro através do e-mail enviado pelo Quasar Flash
          </s.TextParagraph>
          <s.Line/>
        </s.FormWrapper>
        <s.Button onClick={()=> handleClose()}
          color="primary">
          <s.Text bold color="white">
          Concluir
          </s.Text>
        </s.Button>
        <s.Button onClick={()=> {
          setCurrentStep(2)
          setValueToLocalStorage('hasAntecipationInProgress', 'false')
        }}
          color="secondary" outline>
          <s.Text bold color="maincolor">
            Fazer Outra Simulação
          </s.Text>
        </s.Button>
      </s.ModalContent>
    </s.Modal>
  )
}





