import { ActionInterface, Types, OrdersSummaryFilesState } from './types'

const INITIAL_STATE: OrdersSummaryFilesState = {
  ordersSummaryFiles: {
    total: -1,
    count: -1,
    data: null,
    offset: 0,
  },
  isLoading: false,
  hasError: false,
}

const reconciliationFilesReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): OrdersSummaryFilesState => {
  switch (action.type) {
    case Types.GET_RECONCILIATION_SALES_FILES:
      return {
        ...state,
        ordersSummaryFiles: action.payload,
        isLoading: false,
        hasError: false,
      }
    case Types.GET_RECONCILIATION_SALES_FILES_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_RECONCILIATION_SALES_FILES_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export default reconciliationFilesReducer
