import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'store/modules/rootState'

import {
  PostPixWS,
  PostPixWSIsLoading,
  PostPixWSError,
} from 'store/modules/pixWalletSettings/action'

import { PixWSState } from 'store/modules/pixWalletSettings/types'

import pixWSService, { PixWSInterface } from 'services/pixWalletSettings'
import customerService from 'services/customer'
import { useSSE, useNonInitialEffect } from 'hooks/utils'

import { Partial } from 'helpers/partial'

interface UsePostPixWS extends PixWSState {
  createPixWS: (ws: Partial<PixWSInterface>) => Promise<void>
}

export const usePostPixWS = (): Partial<UsePostPixWS> => {
  const dispatch = useDispatch()
  const { postResponse, postIsLoading, postError } = useTypedSelector(
    (store) => ({
      postResponse: store.pixWS.postResponse,
      postIsLoading: store.pixWS.postIsLoading,
      postError: store.pixWS.postError,
    })
  )

  const createPixWS = useCallback(
    async (ws): Promise<void> => {
      dispatch(PostPixWSIsLoading())
      await pixWSService
        .createPixWS(ws)
        .then((response) => {
          dispatch(PostPixWS(response.data))
        })
        .catch(() => {
          dispatch(PostPixWSError())
        })
    },
    [dispatch]
  )

  return {
    postResponse,
    postIsLoading,
    postError,
    createPixWS,
  }
}
