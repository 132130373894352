import React from 'react'
import * as s from './styles'

interface ISwitchProps {
  data: any
  handleChange: (event?: React.FormEvent<HTMLInputElement>) => void
  disabled?: boolean
}

const ToggleSwitch: React.FC<ISwitchProps> = ({
  data,
  handleChange,
  disabled = false,
}) => {
  let statusValue: boolean = data.children

  const changeStatus = () => {
    if (!disabled) {
      statusValue = !statusValue
      handleChange()
    }
  }
  let id = data.index
  return (
    <s.SwitchContainer>
      <s.CheckBox
        aria-checked={!!statusValue}
        defaultChecked={statusValue}
        id={id}
        onClick={changeStatus}
        disabled={disabled}
        type="checkbox"
      />
      <s.CheckBoxLabel htmlFor={id} disabled={disabled} />
    </s.SwitchContainer>
  )
}

export default ToggleSwitch
