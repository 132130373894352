import styled from "styled-components"
import TextCmp from 'components/Text'
import InputTextCmp from 'components/Inputs/Text'
import SelectCmp from 'components/Inputs/Select'
import ButtonCmp from 'components/Button'
import InputTextPasswordCmp from 'components/Inputs/Text/Password'
import theme from "styles/theme"
import IconCmp from 'components/IconExporter'
import ModalCmp from "components/Modal"
import IconExporter from 'components/IconExporter'
import LoadingCmp from 'components/Loading'
import DatepickerCmp from 'components/Datepicker'

export const Modal = styled(ModalCmp)`
`

export const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme2};
  position: absolute;
  left: 0;
  width: 100%;
  z-index: -1;
`

export const ModalContent = styled.div`
  width: 370px;
  height: 400px;
  margin-top:20px;
`

export const ModalContentView = styled.div`
width: 870px;
height: 600px;
  margin-top:20px;
`

export const Text = styled(TextCmp)`
  color: ${({ theme }) => theme.colors.white}

`

export const Loading = styled(LoadingCmp)``


export const Icon = styled(IconCmp)`
`

export const IconWrapper = styled.div`
  margin-bottom: 30px;
`

export const InputText = styled(InputTextCmp)`
`

export const InputGroup = styled.div`
  display: block;
  margin-top: 30px;
  margin-botton: 30px;

  width: 302px;
`

export const InputGroupDate = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-botton: 30px;
  > * {
    :first-child {
      margin-right: ${({ theme }) => theme.spacing(1)}px;
    }
  }

`

export const Select = styled(SelectCmp)`
`

export const Button = styled(ButtonCmp)`
  display:block;
  margin:0 auto;
  margin-top: 40px;
  width: 302px;
  height: 40px;

  > span {
      text-transform: none;
  }
`

export const ButtonLink = styled.a`
  text-decoration: underline;
  color: ${(props) => props.theme.colors.maincolor}
`

export const ButtonIcon = styled(IconExporter)`
  margin-right: 8px;
`

export const List = styled.ul`
  margin: 20px;
  color: ${(props) => props.theme.colors.maincolor}
`

export const Item = styled.li`
  color: ${(props) => theme.colors[props.color]}
`

export const InputTextPassword = styled(InputTextPasswordCmp)``

export const ButtonGroup = styled.div`
  text-align: center;
  margin-top: 20px;

  Button:first-child {
    margin-right: 20px;
  }
`

export const Card = styled.div`
  margin: 0 auto;
  background-color: ${theme.colors.darkmaincolor1};
  
  border-radius: 10px;
  padding: 10px;
  max-width: 902px;  
  margin-bottom: 20px;
`

export const InnerCard = styled.div`
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px;
  max-width: 702px;  
  background-color: ${theme.colors.darkmaincolor2};
  margin-bottom: 20px;
`

  export const Detail = styled(TextCmp)`
  background: ${({ theme }) => theme.colors.darkmaincolor3};
  border-radius: 8px;
  padding: 8px;
  margin 0 auto;
  margin-bottom: 3px;
  color: ${({ theme }) => theme.colors.white}
`

export const Datepicker = styled(DatepickerCmp)`

  .react-datepicker-popper {
    padding-top: 4px;
    position: fixed !important;
  }
`
