export const applyNumberOnlyMask = (value) => {
  if (!value) return ''
  return value.replace(/\D/g, '')
}

export const applyDateMask = (value) => {
  const strippedValue = value.replace(/\D/g, '')

  if (strippedValue.length < 5) {
    value = strippedValue.replace(/(\d{2})(\d{1})/g, '$1/$2')
  } else {
    value = strippedValue.replace(/(\d{2})(\d{2})(\d{1})/g, '$1/$2/$3')
  }
  return value
}

export const applyCEPMask = (value) => {
  if (!value) return ''
  const strippedValue = value.replace(/\D/g, '').slice(0, 8)
  if (strippedValue.length >= 8) {
    return strippedValue.replace(/(\d{5})(\d{3})/g, '$1-$2')
  }
  return strippedValue
}

export const applyCpfCnpjMask = (value) => {
  if (value) {
    const strippedValue = value.replace(/\D/g, '')
    if (strippedValue.length < 12) {
      value = strippedValue.replace(
        /(\d{1,3})(\d{1,3})(\d{1,3})(\d{1,2})/g,
        '$1.$2.$3-$4'
      )
    } else {
      value = strippedValue
        .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
        .slice(0, 18)
    }
    return value
  }

  return ''
}

export const applyCnpjMask = (value) => {
  if (value) {
    const strippedValue = value.replace(/\D/g, '')
    value = strippedValue
      .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
      .slice(0, 18)
    return value
  }

  return ''
}

//Technical resource to be able to mask a cnpj with XX at the end, needed it when 2 stores have the same cnpj
export const applyCpfCnpjWithXXMask = (value) => {
  if (value) {
    if (value.endsWith('XX')) {
      value = value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\D{2})/g,
        '$1.$2.$3/$4-$5'
      )
      return value
    }
    const strippedValue = value.replace(/\D/g, '')
    if (strippedValue.length < 12) {
      value = strippedValue.replace(
        /(\d{1,3})(\d{1,3})(\d{1,3})(\d{1,2})/g,
        '$1.$2.$3-$4'
      )
    } else {
      value = strippedValue.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5'
      )
    }
    return value
  }

  return ''
}

export const applyPhoneMask = (value) => {
  if (!value) return ''

  const strippedValue = value.replace(/\D/g, '')
  if (strippedValue.length < 9) {
    return strippedValue.replace(/(\d{4})(\d+)/g, '$1-$2')
  }
  if (strippedValue.length === 9) {
    return strippedValue.replace(/(\d{5})(\d+)/g, '$1-$2')
  }
  if (strippedValue.length === 10) {
    return strippedValue.replace(/(\d{2})(\d{4})(\d+)/, '($1) $2-$3')
  }

  return strippedValue.replace(/(\d{2})(\d{5})(\d+)/, '($1) $2-$3').slice(0, 15)
}

export const applyPhoneMaskWithoutSlice = (value) => {
  if (!value) return ''

  const strippedValue = value.replace(/\D/g, '')
  if (strippedValue.length < 9) {
    return strippedValue.replace(/(\d{4})(\d+)/g, '$1-$2')
  }
  if (strippedValue.length === 10) {
    return strippedValue.replace(/(\d{2})(\d{4})(\d+)/, '($1) $2-$3')
  }

  return strippedValue.replace(/(\d{2})(\d{5})(\d+)/, '($1) $2-$3')
}

export const applyPhoneMaskWithoutSliceForItau = (value) => {
  if (!value) return ''

  const strippedValue = value.replace(/\D/g, '')
  if (strippedValue.length < 9) {
    return strippedValue.replace(/(\d{4})(\d+)/g, '$1-$2')
  }
  if (strippedValue.length === 10) {
    return strippedValue.replace(/(\d{2})(\d{4})(\d+)/, '($1) $2-$3')
  }
  if (strippedValue.length === 11) {
    return strippedValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
  }

  if (strippedValue.length === 12) {
    return strippedValue.replace(/(\d{2})(\d{2})(\d{4})(\d+)/, '+$1 ($2) $3-$4')
  }

  return strippedValue.replace(/(\d{2})(\d{2})(\d{5})(\d+)/, '+$1 ($2) $3-$4')
}

export const applyAccountMask = (value) => {
  if (!value) return ''

  value = value.replace(/[^0-9]/g, '')
  if (value.length > 12) {
    value = value.slice(0, 12)
    return `${value.slice(0, value.length - 1)}-${value.slice(
      value.length - 1
    )}`
  }

  if (value.length >= 2) {
    value = value.replace('-', '')
    return `${value.slice(0, value.length - 1)}-${value.slice(
      value.length - 1
    )}`
  } else return value
}

export const applyBankAccountItauMask = (value) => {
  if (!value) return ''

  value = value.replace(/[^0-9]/g, '')
  if (value.length > 8) {
    value = value.slice(0, 8)
    return `${value.slice(0, value.length - 1)}-${value.slice(
      value.length - 1
    )}`
  }

  if (value.length >= 2) {
    value = value.replace('-', '')
    return `${value.slice(0, value.length - 1)}-${value.slice(
      value.length - 1
    )}`
  } else return value
}

export const formatMoney = (value: string) => {
  if (!value) return ''
  value = value.replace(/[^0-9-]+/g, '')
  value = `R$ ${value.replace(/([0-9]{2})$/g, ',$1')}`
  if (value.length > 6) value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  return value
}

export const reconciliationFormatMoney = (value: string) => {
  if (!value) return ''
  value = value.replace(/[^0-9-.]+/g, '')
  value = value.replace('.', ',')
  value = `R$ ${value.replace(/([0-9]{2})$/g, '$1')}`
  if (value.length > 6) value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  return value
}

export const formatPercentage = (value: string) => {
  if (!value) return ''
  value = value.replace(/[^0-9-]+/g, '')

  if (value.length > 2) {
    value = `${value.replace(/([0-9]{2})$/g, ',$1')}`
  }

  return `${value}`
}

export const unformatMoney = (value: string) => {
  if (!value) return
  return value
    .replace(/[R$]+/g, '')
    .trim()
    .replace(/[.]+/g, '')
    .replace(/[,]+/g, '.')
}
