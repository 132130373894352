import React from 'react'
import { v4 as uuid } from 'uuid'

import {
  baseURLPixAnyBankCustomerRegistration,
  baseURLPixAnyBankResendToken,
  post,
} from 'services/api'

import { showToast } from 'components/Toast'

import useWalletsSettings from 'hooks/useWalletsSettings'
import useGetRegistrationStatus from 'hooks/useGetRegistrationStatus'

import { applyCpfCnpjMask } from 'helpers/masks'

import originalClauses from 'assets/files/original_clauses.pdf'
import originalConditions from 'assets/files/original_conditions.pdf'
import originalTerms from 'assets/files/original_terms.pdf'
import tbanksTerms from 'assets/files/tbanks_terms.pdf'

import * as s from '../styles'

const TokenForm: React.FC<{
  previousStep?: () => void
  isFrom: string
  handleClose: () => void
  pixFormState: any
}> = ({ previousStep, isFrom, handleClose, pixFormState }) => {
  const form = pixFormState
  const { loadWalletsSettings } = useWalletsSettings()
  const { loadRegistrationStatus } = useGetRegistrationStatus()
  const [isOriginalClausesChecked, setIsOriginalClausesChecked] =
    React.useState(false)
  const [isConditionsChecked, setIsConditionsChecked] = React.useState(false)
  const [isOriginalTermsChecked, setIsOriginalTermsChecked] =
    React.useState(false)
  const [isResendTokenLoading, setIsResendTokenLoading] = React.useState(false)
  const [isCustomerRegistrationLoading, setIsCustomerRegistrationLoading] =
    React.useState(false)
  const [isExitButtonVisible, setIsExitButtonVisible] = React.useState(false)
  const [token, setToken] = React.useState('')
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    React.useState(false)

  const customerRegistration = async () => {
    setIsCustomerRegistrationLoading(true)
    const uuid = form.uuid || ''
    await post(`${baseURLPixAnyBankCustomerRegistration}`, {
      uuid: uuid,
      tokenList: [
        {
          cpfNumber: form.cpf,
          tokenCode: token.toUpperCase(),
        },
      ],
      pix_any_bank_identifier: {
        order_type: isFrom === 'original' ? 'cob' : 'cobv',
        psp_provider: isFrom,
      },
    })
      .then(() => {
        setIsSuccessModalVisible(true)
        loadWalletsSettings({
          limit: '10',
          offset: '0',
          filters: window.btoa(
            unescape(
              encodeURIComponent(
                JSON.stringify({ wallet_type: ['c-wallet', 'e-wallet'] })
              )
            )
          ),
        })
        loadRegistrationStatus()
      })
      .catch((error) => {
        if (window['env']['name'] !== 'production') {
          setIsSuccessModalVisible(true)
        }
        showToast({
          type: 'error',
          message: `${JSON.parse(error.request.response).message}`,
        })
      })
      .finally(() => {
        setIsExitButtonVisible(true)
        setIsCustomerRegistrationLoading(false)
      })
  }

  const resendToken = async () => {
    setIsResendTokenLoading(true)
    const uuid = form.uuid || ''
    await post(`${baseURLPixAnyBankResendToken}`, {
      uuid: uuid,
      pix_any_bank_identifier: {
        order_type: isFrom === 'original' ? 'cob' : 'cobv',
        psp_provider: isFrom,
      },
    })
      .then(() => {
        showToast({
          type: 'success',
          message: 'Token Re-enviado',
        })
      })
      .catch((error) => {
        showToast({
          type: 'error',
          message: `${JSON.parse(error.request.response).message}`,
        })
      })
      .finally(() => {
        setIsResendTokenLoading(false)
      })
  }

  return (
    <>
      <s.SuccessModal
        placeModalOnCenter
        isOpen={isSuccessModalVisible}
        handleClose={() => {
          setIsSuccessModalVisible(false)
          handleClose()
        }}
        noChildrenPadding
        modalSubtitle="Cadastro efetuado com sucesso"
      >
        <s.SuccessModalWrapper>
          <s.Text>
            Suas informações serão analisadas pelo
            {isFrom === 'original' ? ' Banco Original' : ' TBanks'}. O resultado
            da análise leva até 2 dias úteis.
          </s.Text>
          <s.Text>
            Você pode acompanhar esse processo na seção Pix do Painel, na aba
            "Contas em análise".
          </s.Text>
        </s.SuccessModalWrapper>
      </s.SuccessModal>
      <s.MessageBox>
        <s.IconRound>
          <s.Icon width={36} fill="white" name="emailoutline" />
        </s.IconRound>
        <s.Text>
          Ao solicitar a abertura da Conta de Pagamento de Uso Exclusivo para
          Recebimento Pix("Conta
          {isFrom === 'original' ? ' Original' : ' TBankS'}"), você declara que:
        </s.Text>
        {isFrom === 'original' ? (
          <ul>
            <li>
              <s.Text>
                É representante legal da empresa com poderes para solicitar a
                abertura da Conta Original e a contratação do Serviço de
                cobrança Pix
              </s.Text>
            </li>
            <li>
              <s.Text>
                Concorda com as Condições Gerais de Proposta de Abertura de
                Conta de Pagamento Pré-Paga PJ de Uso Exclusivo para Recebimento
                Pix
              </s.Text>
            </li>
            <li>
              <s.Text>
                Concorda com as condições comerciais e com as Condições Gerais
                do Serviço de Cobrança Pix
              </s.Text>
            </li>
            <li>
              <s.Text>
                Autoriza o cadastro de uma chave aleatória para identificar a
                Conta Original e está ciente e de acordo Com o Termo de
                Consentimento
              </s.Text>
            </li>
          </ul>
        ) : (
          <ul>
            <li>
              <s.Text>
                É representante legal da empresa com poderes para solicitar a
                abertura da Conta TBankS e a contratação do Serviço de cobrança
                Pix
              </s.Text>
            </li>
            <li>
              <s.Text>
                Concorda com as condições comerciais e com os Termos e Condições
                de Uso da Conta TBankS
              </s.Text>
            </li>
            <li>
              <s.Text>
                Autoriza o cadastro de uma chave aleatória para identificar a
                Conta TBankS
              </s.Text>
            </li>
          </ul>
        )}
      </s.MessageBox>
      <s.Text bold>REPRESENTANTE LEGAL</s.Text>
      <s.TokenFormData>
        <s.TokenFormCheck>
          <s.TokenFormCpf>
            <s.Text>CPF</s.Text>
            <s.Text>{applyCpfCnpjMask(form.cpf)}</s.Text>
          </s.TokenFormCpf>
          <s.TokenFormName>
            <s.Text>Nome</s.Text>
            <s.Text>{form.name}</s.Text>
          </s.TokenFormName>
        </s.TokenFormCheck>
        <s.InputText
          label="Token"
          value={token}
          onChange={(e) => setToken(e.target.value)}
        />
      </s.TokenFormData>
      <s.CheckBoxContainer>
        <s.Text>Ao solicitar a abertura de sua conta PIX, você declara:</s.Text>
        {isFrom === 'original' ? (
          <>
            <s.CheckboxWrapper>
              <s.Checkbox
                onChange={(e) => {
                  setIsOriginalClausesChecked(!isOriginalClausesChecked)
                }}
                id={uuid()}
                checked={isOriginalClausesChecked}
              />
              <s.Text>
                Concorda com as
                <s.ButtonLink href={originalConditions} target="_blank">
                  Condições Gerais e Proposta de Abertura da Conta de Pagamento
                  Pré-Paga
                </s.ButtonLink>
              </s.Text>
            </s.CheckboxWrapper>
            <s.CheckboxWrapper>
              <s.Checkbox
                onChange={(e) => {
                  setIsConditionsChecked(!isConditionsChecked)
                }}
                id={uuid()}
                checked={isConditionsChecked}
              />
              <s.Text>
                Concorda com as condições comerciais e com as
                <s.ButtonLink href={originalClauses} target="_blank">
                  Condições Gerais dos Serviços de Cobrança Pix
                </s.ButtonLink>
              </s.Text>
            </s.CheckboxWrapper>
            <s.CheckboxWrapper>
              <s.Checkbox
                onChange={(e) => {
                  setIsOriginalTermsChecked(!isOriginalTermsChecked)
                }}
                id={uuid()}
                checked={isOriginalTermsChecked}
              />
              <s.Text>
                Autoriza o cadastro de uma chave aleatória para identificar a
                Conta Original e está ciente e de acordo com o
                <s.ButtonLink href={originalTerms} target="_blank">
                  Termo de Consentimento
                </s.ButtonLink>
              </s.Text>
            </s.CheckboxWrapper>
          </>
        ) : (
          <s.CheckboxWrapper>
            <s.Checkbox
              onChange={(e) => {
                setIsConditionsChecked(!isConditionsChecked)
              }}
              id={uuid()}
              checked={isConditionsChecked}
            />
            <s.Text>
              Concorda com os
              <s.ButtonLink href={tbanksTerms} target="_blank">
                Termos e Condições de Uso da Conta TbankS
              </s.ButtonLink>
            </s.Text>
          </s.CheckboxWrapper>
        )}
      </s.CheckBoxContainer>
      <s.ButtonGroup>
        {previousStep ? (
          <s.Button
            onClick={() => {
              previousStep()
            }}
            color="white"
            outline
            width="150px"
          >
            Voltar
          </s.Button>
        ) : null}
        <s.Button
          disabled={
            isResendTokenLoading ||
            isCustomerRegistrationLoading ||
            (isFrom === 'original'
              ? !isOriginalTermsChecked ||
                !isOriginalClausesChecked ||
                !isOriginalTermsChecked ||
                isCustomerRegistrationLoading
              : !isConditionsChecked)
          }
          onClick={() => {
            customerRegistration()
          }}
          width="200px"
        >
          Continuar
          {isCustomerRegistrationLoading ? (
            <s.Loading type="spinner" color="white" />
          ) : null}
        </s.Button>
        <s.Button
          disabled={isCustomerRegistrationLoading || isResendTokenLoading}
          onClick={() => {
            resendToken()
          }}
          width="200px"
        >
          Re-enviar Token
          {isResendTokenLoading ? (
            <s.Loading type="spinner" color="white" />
          ) : null}
        </s.Button>
        {isExitButtonVisible ? (
          <s.Button
            onClick={() => {
              handleClose()
            }}
            width="200px"
          >
            Sair
          </s.Button>
        ) : null}
      </s.ButtonGroup>
    </>
  )
}

export default TokenForm
