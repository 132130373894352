import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useTypedSelector } from 'store/modules/rootState'

import {
  GetRetailChains,
  GetRetailChainsIsLoading,
  GetRetailChainsError,
} from 'store/modules/retailChain/action'

import { RetailChainState } from 'store/modules/retailChain/types'

import retailChainService from 'services/retailChain'

import { Partial } from 'helpers/partial'

interface UseGetRetailChains extends RetailChainState {
  loadRetailChains: (params?: {
    limit: string
    offset: string
    orderby: string,
    sort: string,
    filter?: string,
    filter_type?: string
  }) => Promise<void>,
}


export const useGetRetailChains = (): Partial<UseGetRetailChains> => {
  const dispatch = useDispatch()

  const { retailChains, getIsLoading, getError} = useTypedSelector((store) => ({
    retailChains: store.retailChain.retailChains,
    getIsLoading: store.retailChain.getIsLoading,
    getError: store.retailChain.getError,
  }))

  const loadRetailChains = useCallback(async (params): Promise<void> => {
    dispatch(GetRetailChainsIsLoading())
    await retailChainService.getRetailChains(params)
      .then((response) => {
        dispatch(GetRetailChains(response.data))
      })
      .catch(() => {
        dispatch(GetRetailChainsError())
      })
  }, [dispatch])

  return { retailChains, getIsLoading, getError, loadRetailChains }
}
