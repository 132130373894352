import styled from 'styled-components'

import InputTextCmp from 'components/Inputs/Text'
import IconCmp from 'components/IconExporter'
import SelectCmp from 'components/Inputs/Select'
import TextCmp from 'components/Text'

export const SelectLabel = styled(TextCmp)`
  width: 100%;
  margin-bottom: 8px;
`

export const Select = styled(SelectCmp)`
  width: 400px;
`

export const SelectPixKey = styled(SelectCmp)`
  margin-top: 8px;
  margin-bottom: 16px;
`

export const Icon = styled(IconCmp)`
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: 10px;
  align-self: end;
`


export const IconPixKey = styled(IconCmp)`
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: 10px;
  margin-top:25px;
  align-self: center;

`


export const InputToggleGroup = styled.div`
  display: flex;
`

export const SelectGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

export const SelectToggleGroup = styled.div`
  display: flex;
`

export const InputText = styled(InputTextCmp)`
  width: 400px;
`

export const InputTextPixKey = styled(InputTextCmp)`
  width: 270px;
`

export const Text = styled(TextCmp)`
`

export const InputGroup = styled.div`
  margin-top: 8px;
  padding-bottom: 8px;
`

export const InputGroupPixKey = styled.div`
  margin-top: 8px;
  padding-bottom: 8px;
  display: grid;
  grid-template-columns: .4fr 1fr;
  grid-column-gap: 20px;
`
