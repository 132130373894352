import styled from 'styled-components'

interface IProps {
  height: number
  width: number
}

export const CardWrapper = styled.div<IProps>`
  background-color: ${({ theme }) => theme.colors.whiteshipay};
  border: 1px solid #dedede;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(3)}px;
  box-shadow: 0 4px 20px 10px rgb(0 0 0 / 3%);
  width: ${({ width }) => width}px;
  min-height: ${({ height }) => height}px;
`
