export const Types = {
  GET_RETAIL_CHAINS: 'GET_RETAIL_CHAINS',
  GET_RETAIL_CHAINS_LOADING: 'GET_RETAIL_CHAINS_LOADING',
  GET_RETAIL_CHAINS_ERROR: 'GET_RETAIL_CHAINS_ERROR',
  POST_RETAIL_CHAIN: 'POST_RETAIL_CHAIN',
  POST_RETAIL_CHAIN_LOADING: 'POST_RETAIL_CHAIN_LOADING',
  POST_RETAIL_CHAIN_ERROR: 'POST_RETAIL_CHAIN_ERROR',
  DEL_RETAIL_CHAIN: 'DEL_RETAIL_CHAIN',
  DEL_RETAIL_CHAIN_LOADING: 'DEL_RETAIL_CHAIN_LOADING',
  DEL_RETAIL_CHAIN_ERROR: 'DEL_RETAIL_CHAIN_ERROR',
  PATCH_RETAIL_CHAIN: 'PATCH_RETAIL_CHAIN',
  PATCH_RETAIL_CHAIN_LOADING: 'PATCH_RETAIL_CHAIN_LOADING',
  PATCH_RETAIL_CHAIN_ERROR: 'PATCH_RETAIL_CHAIN_ERROR',
}

export interface ActionInterface {
  type: keyof typeof Types
  payload:
    | GetRetailChainsResponseInterface
    | PostRetailChainResponseInterface
    | PatchRetailChainResponseInterface
    | DelRetailChainResponseInterface
}

export interface RetailChainState {
  retailChains: GetRetailChainsResponseInterface
  getIsLoading: boolean
  getError: boolean
  postResponse: PostRetailChainResponseInterface
  postIsLoading: boolean
  postError: boolean
  delResponse: DelRetailChainResponseInterface
  delIsLoading: boolean
  delError: boolean
  patchResponse: PatchRetailChainResponseInterface
  patchIsLoading: boolean
  patchError: boolean
}

//entity interface
export interface RetailChainInterface {
  pos_product_id: string
  pos_product_name: string
  retail_chain_created_at: string
  retail_chain_email: string
  retail_chain_id: string
  retail_chain_name: string
  retail_chain_updated_at: string
  external_crm_id?: string
}

//GET
export interface GetRetailChainsResponseInterface {
  count: number
  data: RetailChainInterface[]
  offset: number
  total: number
}

// POST
export interface PostRetailChainResponseInterface {
  code: number
  message: string
}

// DELETE
export interface DelRetailChainResponseInterface {
  code: number
  message: string
}

// PATCH
export interface PatchRetailChainResponseInterface {
  code: number
  message: string
}
