import { ActionInterface, Types, AuthState } from './types'

const INITIAL_STATE: AuthState = {
  request: { auth_token: 0, password: '', username: '' },
  errorMessage: '',
  isLoading: false,
  hasError: false,
  response: {
    access_token: '',
    email: '',
    id: '',
    name: '',
    refresh_token: '',
    roles: [],
  },
}

const authReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): AuthState => {
  switch (action.type) {
    case Types.POST_AUTH:
      return {
        ...state,
        request: action.request,
        isLoading: true,
        hasError: false,
        errorMessage: '',
      }
    case Types.POST_AUTH_RESPONSE:
      return {
        ...state,
        request: INITIAL_STATE.request,
        response: action.response,
        isLoading: false,
        hasError: false,
        errorMessage: '',
      }
    case Types.POST_AUTH_ERROR:
      return {
        ...state,
        request: INITIAL_STATE.request,
        errorMessage: action.message,
        isLoading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export default authReducer
