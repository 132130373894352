import React from 'react'

import { useTranslation } from 'react-i18next'

import { applyCpfCnpjMask, applyPhoneMask, applyCEPMask } from 'helpers/masks'

import { getStates } from 'helpers/geo'

import useGetUser from 'hooks/useGetUser'
import { getValueFromLocalStorage } from 'helpers/localStorage'
import { useNonInitialEffect } from 'hooks/utils'

import { TSelected } from 'components/Inputs/Select/types'
import { showToast } from 'components/Toast'

import * as s from '../styles'
import {
  get,
  baseURLPixAnyBankCustomerAddress,
  baseURLPixAnyBankCustomerValidation,
  post,
} from 'services/api'
import { removeNumbers } from 'helpers'
import { formatDate } from 'helpers/date'
import { UserSessionInfo } from 'store/modules/auth/types'

const RepresentantForm: React.FC<{
  nextStep: () => void
  previousStep: () => void
  isFrom: string
  pixFormState: [any, React.Dispatch<React.SetStateAction<any>>]
}> = ({ nextStep, previousStep, isFrom, pixFormState }) => {
  const {
    user,
    hasError: userHasError,
    addressIsLoading: useraddressIsLoading,
    loadUser,
  } = useGetUser()
  const { t } = useTranslation()
  const [form, setForm] = pixFormState

  const [representant, setRepresentant] = React.useState({
    cpf: '',
    phone: '',
    birthday: '',
    cep: '',
    streetName: '',
    complement: '',
    number: '',
    uf: '',
    city: '',
    momName: '',
    email: '',
    name: '',
    cityId: '',
    countryId: '',
    neighborhood: '',
    neighborhoodId: '',
    stateId: '',
  })

  const [selectedUf, setSelectedUf] = React.useState<TSelected>({
    id: 0,
    name: '',
  })

  const [addressIsLoading, setAddressIsLoading] = React.useState(false)

  const states: { id: number; text: string }[] = getStates()
  const ufOptions = states.map((state) => {
    return {
      id: state.id,
      text: state.text,
      onClick: () => {
        setRepresentant({ ...representant, uf: state.text })
        setSelectedUf({ id: state.id, name: state.text })
      },
    }
  })

  const [isDisabled, setIsDisabled] = React.useState(true)

  const [
    isValidateCustomerLoading,
    setIsValidateCustomerLoading,
  ] = React.useState(false)

  const checkIfAllInputsAreValid = React.useCallback(() => {
    return Object.values(representant).every((item) => {
      return item !== null && item !== ''
    })
  }, [representant])

  const getCustomerAddress = async (cep) => {
    setAddressIsLoading(true)
    await get(
      `${baseURLPixAnyBankCustomerAddress}?zip_code=${cep.replace(
        /-/g,
        ''
      )}&order_type=${
        isFrom === 'original' ? 'cob' : 'cobv'
      }&psp_provider=${isFrom}`
    )
      .then((res) => {
        setRepresentant({
          ...representant,
          city: res.data.city,
          cityId: res.data.cityCode,
          countryId: res.data.countryCode,
          neighborhood: res.data.neighborhood,
          neighborhoodId: res.data.neighborhoodCode,
          uf: res.data.state,
          stateId: res.data.stateCode,
          streetName: res.data.streetName,
        })
        setSelectedUf({
          id: ufOptions.find((item) => item.text === res.data.state).id,
          name: res.data.state,
        })
        if (!res.data.neighborhood || res.data.neighborhood === '') {
          setIsDisabled(false)
        } else {
          setIsDisabled(true)
        }
      })
      .catch((e) => {
        setIsDisabled(false)
        showToast({
          type: 'error',
          message: t(`${JSON.parse(e.request.response).message}`),
        })
      })
    setAddressIsLoading(false)
  }

  const customerValidation = async () => {
    setIsValidateCustomerLoading(true)
    const payload: any = {
      companyDocumentNumber: form.cnpj.replace(/[./-]+/g, ''),
      monthRevenue: parseFloat(
        form.monthlyRevenue
          .replace(/[R$.]+/g, '')
          .trim()
          .replace(/[,]+/g, '.')
      ),
      numberOfEmployees: form.employeesNumber,
      representatives: [
        {
          isPrincipal: true,
          documentNumber: representant.cpf,
          birthDate: representant.birthday.replace(
            /(\d{2}).(\d{2}).(\d{4})/g,
            '$3-$2-$1'
          ),
          name: representant.name,
          motherName: representant.momName,
          addresses: [
            {
              isPrincipal: true,
              street: representant.streetName,
              type: 'COMERCIAL',
              number: representant.number,
              city: representant.city,
              cityCode: representant.cityId,
              neighborhood: representant.neighborhood,
              state: representant.uf,
              stateCode: representant.stateId,
              zipCode: applyCEPMask(representant.cep),
              complement: representant.complement,
            },
          ],
          contacts: [
            {
              isPrincipal: true,
              countryCode: '+55',
              phoneNumber: representant.phone
                .replace(/[()-]+/g, '')
                .slice(2)
                .trim(),
              phoneCode: representant.phone
                .replace(/[()-]+/g, '')
                .trim()
                .slice(0, 2),
              phoneType: 'CELULAR',
              email: representant.email,
              mailType: 'PESSOAL',
            },
          ],
        },
      ],
      optIn: true,
      optInKeyPIX: true,
      paymentInformation: {
        bank: form.bank.match(/\d+/)[0],
        branch: parseInt(form.agency),
        account: parseInt(form.account.replace(/-/g, '')),
      },
      pix_any_bank_identifier: {
        order_type: isFrom === 'original' ? 'cob' : 'cobv',
        psp_provider: isFrom,
      },
    }

    const errorHandler = (err) => {
      let errorMsgToParse = JSON.parse(err.request.response)
        .message.replace(/\'/g, '"')
        .replace(/{0\:/g, '{"value": ')

      let errorObj = errorMsgToParse
      //Verifies if errorObj is parseable
      if (errorMsgToParse.includes('{"value": ')) {
        errorObj = JSON.parse(errorMsgToParse)
      } else {
        showToast({
          type: 'error',
          message: errorObj,
        })
      }
      if (errorObj?.representatives) {
        errorObj = errorObj.representatives.value
        _handleAddressError(errorObj)
        _handleContactsError(errorObj)
        _handleBirthDateError(errorObj)
        _handleDocumentsError(errorObj)
      }
      if (
        JSON.parse(err.request.response).message ===
        'User is not the company owner'
      ) {
        showToast({
          type: 'error',
          message: `O usuário ${
            JSON.parse(getValueFromLocalStorage('currentUserSession')).name
          } não consta no QSA do CNPJ selecionado. Entre em contato com o representante do seu sistema de PDV ou com o time comercial da Shipay para mais detalhes`,
        })
      }
    }
    await post(`${baseURLPixAnyBankCustomerValidation}`, payload)
      .then((response) => {
        setForm({
          ...form,
          ...representant,
          uuid: response.data.uuid,
        })
        nextStep()
      })
      .catch((err) => {
        if (window['env']['name'] !== 'production') {
          nextStep()
        }
        errorHandler(err)
      })
      .finally(() => {
        setIsValidateCustomerLoading(false)
      })
  }

  const _handleAddressError = (err: any): void => {
    if (
      err.addresses &&
      err.addresses.value &&
      (err.addresses.value.complement || err.addresses.value.number)
    ) {
      const message =
        err.addresses.value.complement || err.addresses.value.number
      showToast({
        type: 'error',
        message: _getErrorTranslation(message[0]),
      })
    }
  }

  const _handleBirthDateError = (err: any): void => {
    if (err.birthDate) {
      showToast({
        type: 'error',
        message: _getErrorTranslation(err.birthDate[0]),
      })
    }
  }

  const _handleContactsError = (err: any): void => {
    if (err.contacts && err.contacts.value && err.contacts.value.phoneNumber) {
      showToast({
        type: 'error',
        message: _getErrorTranslation(err.contacts.value.phoneNumber[0]),
      })
    }
  }

  const _handleDocumentsError = (err: any): void => {
    if (err.documentNumber) {
      showToast({
        type: 'error',
        message: _getErrorTranslation(err.documentNumber[0]),
      })
    }
  }

  const _getErrorTranslation = (err: string): string => {
    const errorObj = {
      'The CNPJ/CPF is not valid.': 'CNPJ/CPF inválido',
      'Length must be between 9 and 9.':
        'Telefone deve conter exatamente 9 dígitos',
      'Not a valid date.': 'Data inválida',
      'Length must be between 5 and 200.':
        'Complemento deve possuir mais do que 5 caracteres',
      'Not a valid integer.': 'Número não deve possuir letras',
    }

    return errorObj[err]
  }

  React.useEffect(() => {
    const currentUserSession: UserSessionInfo = JSON.parse(getValueFromLocalStorage('currentUserSession'))
    loadUser(currentUserSession.id)
  }, [])

  React.useEffect(() => {
    if (form.cpf) {
      setSelectedUf({
        id: ufOptions.find((item) => item.text === form.uf).id,
        name: form.uf,
      })
      setRepresentant({ ...form })
    }
  }, [])

  useNonInitialEffect(() => {
    if (user.first_name && user.last_name && user.email) {
      setRepresentant({
        ...representant,
        cpf: user.cpf,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
      })
    }
  }, [user])

  return (
    <>
      <s.ModalWrapper>
        <s.Text bold type="headline">
          Insira os dados do representante legal da empresa.
        </s.Text>
        <s.RepresentantFormWrapper>
          <s.InputWrapper>
            <s.InputText
              width="273"
              label="CPF"
              disabled={!!user.cpf}
              value={applyCpfCnpjMask(representant.cpf)}
              onChange={(e) => {
                setRepresentant({ ...representant, cpf: e.target.value })
              }}
            />
            <s.InputText
              width="273"
              label="Celular Pessoal"
              value={applyPhoneMask(representant.phone)}
              onChange={(e) => {
                if (e.target.value.length < 16) {
                  setRepresentant({ ...representant, phone: e.target.value })
                }
              }}
            />
            <s.InputText
              width="273"
              label="Data de nascimento"
              value={representant.birthday}
              onChange={(e) => {
                if (e.target.value.length < 11) {
                  setRepresentant({
                    ...representant,
                    birthday: formatDate(e.target.value),
                  })
                }
              }}
            />
            <s.InputText
              width="273"
              label="CEP"
              disabled={addressIsLoading}
              value={applyCEPMask(representant.cep)}
              onChange={(e) => {
                if (e.target.value.length <= 9) {
                  setRepresentant({ ...representant, cep: e.target.value })
                }
              }}
              onBlur={() => {
                if (representant?.cep?.replace(/-/g, '').length === 8) {
                  getCustomerAddress(representant?.cep)
                }
              }}
              suffix={
                addressIsLoading ? (
                  <s.Loading type="spinner" color="maincolor" />
                ) : null
              }
            />
            <s.InputText
              width="273"
              label="Logradouro"
              disabled={isDisabled}
              value={representant.streetName}
              onChange={(e) => {
                setRepresentant({ ...representant, streetName: e.target.value })
              }}
              suffix={
                addressIsLoading ? (
                  <s.Loading type="spinner" color="maincolor" />
                ) : null
              }
            />
            <s.InputText
              width="273"
              label="Complemento"
              value={representant.complement}
              onChange={(e) => {
                setRepresentant({ ...representant, complement: e.target.value })
              }}
            />
            <s.SelectState
              optionBoxDirection="top"
              optionBoxSize={200}
              disabled={isDisabled}
              isLoading={addressIsLoading}
              label="UF"
              suffix={
                addressIsLoading ? (
                  <s.Loading type="spinner" color="maincolor" />
                ) : null
              }
              options={ufOptions}
              selected={selectedUf}
            />
          </s.InputWrapper>
          <s.InputWrapper>
            <s.InputText
              width="273"
              label="Nome"
              disabled
              value={representant.name}
              onChange={(e) => {
                setRepresentant({ ...representant, name: e.target.value })
              }}
            />
            <s.InputText
              width="273"
              label="E-mail"
              disabled
              value={representant.email}
              onChange={(e) => {
                setRepresentant({ ...representant, email: e.target.value })
              }}
            />
            <s.InputText
              width="273"
              label="Nome completo da mãe"
              value={representant.momName}
              onChange={(e) => {
                setRepresentant({
                  ...representant,
                  momName: removeNumbers(e.target.value),
                })
              }}
            />
            <s.InputText
              width="273"
              label="Número"
              value={representant.number}
              onChange={(e) => {
                setRepresentant({ ...representant, number: e.target.value })
              }}
            />
            <s.InputText
              width="273"
              label="Bairro"
              disabled={isDisabled}
              value={representant.neighborhood}
              suffix={
                addressIsLoading ? (
                  <s.Loading type="spinner" color="maincolor" />
                ) : null
              }
              onChange={(e) => {
                setRepresentant({
                  ...representant,
                  neighborhood: e.target.value,
                })
              }}
            />
            <s.InputText
              width="273"
              label="Cidade"
              disabled={isDisabled}
              value={representant.city}
              suffix={
                addressIsLoading ? (
                  <s.Loading type="spinner" color="maincolor" />
                ) : null
              }
              onChange={(e) => {
                setRepresentant({ ...representant, city: e.target.value })
              }}
            />
          </s.InputWrapper>
        </s.RepresentantFormWrapper>
      </s.ModalWrapper>
      <s.Footer>
        <s.LeftButtonAnybank
          onClick={() => {
            previousStep()
          }}
          color="whitedetailed"
          width="150px"
        >
          <s.Icon name="arrowleft" fill="maincolor" />
          <s.Text bold color="graytheme6">
            Voltar
          </s.Text>
        </s.LeftButtonAnybank>
        <s.RightButtonAnybank
          disabled={!checkIfAllInputsAreValid() || isValidateCustomerLoading}
          onClick={() => {
            customerValidation()
          }}
          width="150px"
        >
          <s.Text bold color="white">
            Continuar
          </s.Text>
          <s.Icon name="arrowright" fill="white" />
          {isValidateCustomerLoading ? (
            <s.Loading type="spinner" color="white" />
          ) : null}
        </s.RightButtonAnybank>
      </s.Footer>
    </>
  )
}

export default RepresentantForm
