import {
  Types,
  ResponseInterface,
  GetPixWSResponseInterface
} from './types'

//get pixWS
export function GetPixWS(
  payload: GetPixWSResponseInterface
): {
  type: string,
  payload: GetPixWSResponseInterface,
} {
  return {
    type: Types.GET_PIX_WS,
    payload
  }
}

export function GetPixWSIsLoading(): {
  type: string,
} {
  return {
    type: Types.GET_PIX_WS_LOADING,
  }
}

export function GetPixWSError(): {
  type: string,
} {
  return {
    type: Types.GET_PIX_WS_ERROR,
  }
}

//PATCH
export function PatchPixWS(
  payload: ResponseInterface,
): {
  type: string,
  payload: ResponseInterface,
} {
  return {
    type: Types.PATCH_PIX_WS,
    payload,
  }
}

export function PatchPixWSIsLoading(): {
  type: string,
} {
  return {
    type: Types.PATCH_PIX_WS_LOADING,
  }
}

export function PatchPixWSError(): {
  type: string,
} {
  return {
    type: Types.PATCH_PIX_WS_ERROR,
  }
}

//POST
export function PostPixWS(
  payload: ResponseInterface,
): {
  type: string,
  payload: ResponseInterface,
} {
  return {
    type: Types.POST_PIX_WS,
    payload,
  }
}

export function PostPixWSIsLoading(): {
  type: string,
} {
  return {
    type: Types.POST_PIX_WS_LOADING,
  }
}

export function PostPixWSError(): {
  type: string,
} {
  return {
    type: Types.POST_PIX_WS_ERROR,
  }
}
