import {
  Types,
  PerStoreIdPayloadInterface,
  StorePosPayloadInterface,
} from './types'

export function GetStorePosPerStoreId(
  perStoreIdPayload: PerStoreIdPayloadInterface
): {
  type: string
  perStoreIdPayload: PerStoreIdPayloadInterface
} {
  return {
    type: Types.GET_STORE_POS_PER_STORE_ID,
    perStoreIdPayload,
  }
}

export function GetStorePosPerStoreIdIsLoading(): {
  type: string
} {
  return {
    type: Types.GET_STORE_POS_PER_STORE_ID_LOADING,
  }
}

export function GetStorePosPerStoreIdError(): {
  type: string
} {
  return {
    type: Types.GET_STORE_POS_PER_STORE_ID_ERROR,
  }
}

export function GetStorePos(
  storePosPayload: StorePosPayloadInterface
): {
  type: string
  storePosPayload: StorePosPayloadInterface
} {
  return {
    type: Types.GET_STORE_POS,
    storePosPayload,
  }
}

export function GetStorePosIsLoading(): {
  type: string
} {
  return {
    type: Types.GET_STORE_POS_LOADING,
  }
}

export function GetStorePosError(): {
  type: string
} {
  return {
    type: Types.GET_STORE_POS_ERROR,
  }
}

export function PostStorePos(): {
  type: string
} {
  return {
    type: Types.POST_STORE_POS,
  }
}

export function PostStorePosIsLoading(): {
  type: string
} {
  return {
    type: Types.POST_STORE_POS_LOADING,
  }
}

export function PostStorePosHasError(): {
  type: string
} {
  return {
    type: Types.POST_STORE_POS_ERROR,
  }
}
