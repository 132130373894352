import React from 'react'
import * as s from './styles'
import { v4 as uuid } from 'uuid'

interface IProps {
  json: Object,
  header: string,
  headerInfo?: string,
}

const BeautifyJson: React.FC<IProps> = ({
  json, 
  header, 
  headerInfo
}) => {
  
  const [visible, setVisible] = React.useState(true)
  return(
      <>
        <s.Header
          type='headline'
          bold
          color='detailsModalHeaderInfoColor'
          onClick={() => setVisible(!visible)}>
            {header}
            {headerInfo && <span>{headerInfo}</span>}
        </s.Header>
        {(visible && json)? 
          <>
            {'{'}
              <s.Wrapper>
                {
                  Object.entries(json).map(([key, value]) => (
                    <s.KeyValue key={uuid()}>
                      <s.Key type='headline' color={'detailsModalKeyMessageColor'}>{`"${key}"`}: </s.Key>  
                      <s.Value type='headline' color={'white'}>{JSON.stringify(value, null, 2)+','}</s.Value>  
                    </s.KeyValue>
                  ))
                }
              </s.Wrapper>
            {'}'}
          </> : <></>
        }
      </>
  )
}

export default BeautifyJson