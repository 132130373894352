import styled, { css } from 'styled-components'

import { Container as ContainerCmp } from 'styled-bootstrap-grid'

export const StepperContainer = styled(ContainerCmp)`
  display: flex;
  z-index: 1;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  left: calc((100% - 914px)/2);
  text-align: center;
`

export const StepperWrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  height: 100%;
`

interface IStepCircle {
  isCurrentStep: boolean
}

export const StepCircle = styled.div<IStepCircle>`
  display: flex;
  width: 64px;
  height: 64px;
  background-color: ${({ theme }) => (theme.domain === 'SpacePay')? theme.colors.maincolor : theme.colors.whiteshipay};
  margin-right: 42px;
  border-radius: 50%;
  font-weight: 900;
  font-size: 29px;
  align-items: center;
  justify-content: center;
  ${({ isCurrentStep }) =>
    isCurrentStep
      ? css`
          background-color: ${({ theme }) => theme.colors.lightmaincolor1};
          color: ${({ theme }) => (theme.domain === 'SpacePay')? theme.colors.maincolor : theme.colors.white};
          box-shadow: 0px 1px 8px 4px  ${({ theme }) => theme.colors.lightmaincolor2};
        `
      : css`
          box-shadow: 0px 1px 8px 2px ${({ theme }) => theme.colors.lightmaincolor3};
          color: ${({ theme }) => theme.colors.lightmaincolor1};
          :hover {
            cursor: pointer;
          }
        `}
`

interface IPathLine {
  isActive: boolean
}

export const PathLine = styled.div<IPathLine>`
  width: 500px;
  height: 2px;
  margin: 8px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.lightmaincolor3 : theme.colors.graytheme1};
`
