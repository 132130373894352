import React from 'react'
import useWalletsSettings from 'hooks/useWalletsSettings'
import useGetRegistrationStatus from 'hooks/useGetRegistrationStatus'
import { useGetClientCerts } from 'hooks/clientCert'
import { useNonInitialEffect } from 'hooks/utils'
import { useSSE } from 'hooks/utils'
import useGetUser from 'hooks/useGetUser'
import { usePostPixWS } from 'hooks/pixWS'

import { getValueFromLocalStorage } from 'helpers/localStorage'

import customerService from 'services/customer'

import Toast, { showToast } from 'components/Toast'

import { themeColors } from 'styles/theme'
import * as s from './styles'

import {
  WalletSettingsFormMP,
  WalletSettingsFormPagSeg,
} from 'components/PixWalletForms'

import WalletSettingsFormPixSimples, {
  ItauAnyBankOnboardStatusType,
} from 'components/PixWalletForms/WalletSettingsFormPixSimples'
import { WalletSettingsFormTbanksCashout } from 'components/PixWalletForms/WalletSettingsFormCashout'
import PixSimplesModalContainer from 'components/PixWalletForms/WalletSettingsFormPixSimples/PixSimplesModalContainer'
import TokenForm from 'components/PixWalletForms/WalletSettingsFormPixSimples/TokenForm'

import {
  EditWSFormBradesco,
  EditWSFormMP,
  EditWSFormPagSeg,
  EditWSFormOriginal,
  EditWSFormTBanks,
  EditWSFormTbanksCashout,
} from './EditWSForms'

import { PixCertificatesModal } from './PixCertificatesModal'
import PixAnyBankErrorMessageModal from './PixAnyBankErrorMessageModal'
import { SettingsWSFormItauAnybank } from './SettingsWSForms/EditItauAnybank'
import { useTranslation } from 'react-i18next'
import { WalletSettingsFormDefault } from 'components/PixWalletForms/PixWalletSettingsFormDefault'
import SettingsWSFormDefault from './SettingsWSForms/EditDefault'
import FilterCards from './FilterComponents/FilterCards'
import useGetSystemWalletsFromBff, {
  SystemWalletBff,
} from 'hooks/useGetSystemWalletsFromBff'
import SystemWallets from './SystemWallets'
import WalletSettingsFormAnybank from 'components/PixWalletForms/WalletSettingsFormAnybank'

interface IChildrenRender {
  children: string | number
  index?: number
}

const TransactionTypeRender = ({ children, index }: IChildrenRender) => {
  const { t } = useTranslation()

  return (
    <s.Text bold data-testid={`${index}-${children}`}>
      {t(`${children as string}`)}
    </s.Text>
  )
}

const Pix: React.FC = () => {
  const {
    systemWalletsFromBff: systemWallets,
    systemWalletsFromBffIsLoading: isLoading,
    systemWalletsFromHasError: hasError,
    getSystemWalletsFromBff,
  } = useGetSystemWalletsFromBff()

  const { postResponse, postError } = usePostPixWS()

  const {
    walletSettings,
    loadWalletsSettings,
    isLoading: isLoadingWalletSettings,
    hasError: hasErrorWalletSettings,
    patchWalletSettings,
    patchIsLoading,
  } = useWalletsSettings()
  const {
    registrationStatus,
    isLoading: isLoadingRegistrationStatus,
    hasError: hasErrorRegistrationStatus,
    loadRegistrationStatus,
  } = useGetRegistrationStatus()

  const {
    user,
    hasError: userHasError,
    addressIsLoading: useraddressIsLoading,
    loadUser,
  } = useGetUser()

  const [certificatesModal, setCertificatesModal] =
    React.useState<boolean>(false)
  const [wallets, setWallets] = React.useState<SystemWalletBff[]>([])
  const [limitedTableDataRegistered, setLimitedTableDataRegistered] =
    React.useState([])
  const [tableInAnalysis, setTableInAnalysis] = React.useState([])
  const [walletSettingsModal, setWalletSettingsModal] =
    React.useState<string>('')
  const [editFormInfo, setEditFormInfo] = React.useState<{
    id: string
    pspProvider: string
    pixProviderName
  }>({
    id: '',
    pspProvider: '',
    pixProviderName: '',
  })

  const { certs, loadClientCerts } = useGetClientCerts()

  const [certSelectList, setCertSelectList] = React.useState<
    { id: string; text: string }[]
  >([])
  const { openConnection, notifications } = useSSE()
  const [selectedPixIdChangeStatus, setSelectedPixIdChangeStatus] =
    React.useState('')
  const [offset, setOffset] = React.useState<string>('0')
  const [selectedPixInAnalysisId, setSelectedPixInAnalysisId] =
    React.useState<string>('')
  const [customerUUID, setCustomerUUID] = React.useState<string>('')
  const [pixAnyBankErrorMessage, setPixAnyBankErrorMessage] =
    React.useState<string>('')
  const [isFilterOpen, setIsFilterOpen] = React.useState<boolean>(false)
  const [options, setOptions] = React.useState<
    { value: string; checked: boolean }[]
  >([
    { value: 'cashin', checked: false },
    { value: 'cashout', checked: false },
    { value: 'bank_slip_settings', checked: false },
    { value: 'anybank', checked: false },
  ])
  const [searchSystemWalletText, setSearchSystemWalletText] =
    React.useState<string>('')

  const filterWallets = React.useCallback(() => {
    if (systemWallets?.length > 0) {
      let filteredWallets = systemWallets.filter(
        (wallet) => wallet.wallet_type === 'pix' && wallet.features?.length > 0
      )
      return filteredWallets
    }
    return []
  }, [systemWallets])

  const statusTranslation = (status, pspProvider) => {
    if (pspProvider?.includes('itau_anybank')) {
      switch (status) {
        case 'pending-onboarding':
          return 'Aguardando envio de dados'
        case 'rejected':
          return 'Pedido de abertura de conta negado'
        case 'finished':
          return 'Pedido de abertura de conta aprovado'
        case 'in-progress':
          return 'Analisando pedido de abertura de conta'
      }
    } else {
      switch (status) {
        case 'pending-token':
          return 'Enviar Token'
        case 'in-progress':
          return 'Aguardando aprovação'
        case 'finished':
          return 'Aprovada para utilização'
        default:
          return 'Recusada'
      }
    }
  }

  useNonInitialEffect(() => {
    if (notifications) {
      const status = notifications[customerUUID].message
        .toLowerCase()
        .includes('sucesso')
        ? 'success'
        : 'error'
      showToast({
        type: status,
        message: notifications[customerUUID].message,
      })
      loadWalletsSettings({
        limit: '10',
        offset: offset,
        filters: window.btoa(
          unescape(encodeURIComponent(JSON.stringify({ wallet_type: ['pix'] })))
        ),
      })
    }
  }, [notifications])

  React.useEffect(() => {
    const filteredWallets = filterWallets()
    if (filteredWallets.length > 0) {
      if (options.every((option) => option.checked === false)) {
        if (searchSystemWalletText.length > 2) {
          const walletsCopy = filteredWallets.filter(
            (wallet) =>
              wallet.wallet_friendly_name
                .toLowerCase()
                .startsWith(searchSystemWalletText.toLowerCase()) ||
              wallet.wallet_name
                .toLowerCase()
                .startsWith(searchSystemWalletText.toLowerCase())
          )
          setWallets(walletsCopy)
        } else {
          setWallets(filteredWallets)
        }
      } else {
        const selectedOptions = options.filter((option) => option.checked)
        let walletsCopy = filteredWallets.filter((wallet) =>
          selectedOptions
            .map((option) => option.value)
            .every((value) => wallet.features.includes(value))
        )
        if (searchSystemWalletText.length > 2) {
          walletsCopy = walletsCopy.filter(
            (wallet) =>
              wallet.wallet_friendly_name
                .toLowerCase()
                .startsWith(searchSystemWalletText.toLowerCase()) ||
              wallet.wallet_name
                .toLowerCase()
                .startsWith(searchSystemWalletText.toLowerCase())
          )
          setWallets(walletsCopy)
        }
        setWallets(walletsCopy)
      }
    }
  }, [options, searchSystemWalletText])

  useNonInitialEffect(() => {
    if (certs && certs.data) {
      setCertSelectList(certs.data.map((x) => ({ id: x.id, text: x.name })))
    }
  }, [certs])

  useNonInitialEffect(() => {
    if (postResponse.code !== -1) {
      showToast({
        type: 'success',
        message: 'Criação de carteira em processamento',
      })
      customerService.getCurrentCustomer().then((currentCustomerRes) => {
        setCustomerUUID(currentCustomerRes.data.uuid)
        openConnection(currentCustomerRes.data.uuid)
      })
    }
  }, [postResponse])

  useNonInitialEffect(() => {
    if (postError) {
      showToast({
        type: 'error',
        message: 'Problema ao editar carteira',
      })
    }
  }, [postError])

  React.useEffect(() => {
    loadRegistrationStatus()
    loadClientCerts({ limit: 1000 })
    loadUser(JSON.parse(getValueFromLocalStorage('currentUserSession'))?.id)
  }, [])

  React.useEffect(() => {
    if (registrationStatus) {
      setTableInAnalysis(
        registrationStatus.map((item) => {
          return {
            ...item,
            id: item.registration_id,
            store_cnpj_cpf: item.store_cnpj_cpf,
            psp_provider: item.psp_provider,
            status: item.status,
            friendly_status_name: statusTranslation(
              item.status,
              item.psp_provider
            ),
          }
        })
      )
    }
  }, [registrationStatus])

  React.useEffect(() => {
    loadWalletsSettings({
      limit: '10',
      offset: offset,
      filters: window.btoa(
        unescape(encodeURIComponent(JSON.stringify({ wallet_type: ['pix'] })))
      ),
    })
  }, [offset])

  React.useEffect(() => {
    if (walletSettings) {
      setLimitedTableDataRegistered(
        walletSettings.data
          .filter((x) => x.wallet_name === 'pix')
          .map((item) => {
            return {
              name: item.name,
              psp_provider: item.psp_provider,
              active: item.active,
              id: item.id,
              icon: item.wallet_icon,
              transaction_type: item.transaction_type,
              wallet_friendly_name: item.wallet_friendly_name,
            }
          })
      )
    }
  }, [walletSettings])

  React.useEffect(() => {
    getSystemWalletsFromBff()
  }, [])

  React.useEffect(() => {
    if (systemWallets) {
      setWallets(filterWallets())
    }
  }, [systemWallets])

  React.useEffect(() => {
    if (hasError) {
      showToast({
        type: 'error',
        message: 'Erro na API, tente novamente mais tarde',
      })
    }
  }, [hasError])

  React.useEffect(() => {
    if (hasErrorWalletSettings) {
      showToast({
        type: 'error',
        message: 'Erro na API de wallet settings, tente novamente mais tarde',
      })
    }
  }, [hasErrorWalletSettings])

  React.useEffect(() => {
    if (hasErrorRegistrationStatus) {
      showToast({
        type: 'error',
        message:
          'Erro na API de registration status, tente novamente mais tarde',
      })
    }
  }, [hasErrorRegistrationStatus])

  const handleOptionChange = (value) => {
    setOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.value === value
          ? { ...option, checked: !option.checked }
          : option
      )
    )
  }

  const handlePatch = (uuid: string) => {
    const index = limitedTableDataRegistered.findIndex(
      (wallet) => wallet.id === uuid
    )
    const newWallets = limitedTableDataRegistered
    newWallets[index].active = !newWallets[index].active
    patchWalletSettings(uuid, {
      active: newWallets[index].active,
    }).then((status) => {
      if (status !== '200') {
        newWallets[index].active = !newWallets[index].active
        setLimitedTableDataRegistered(() => {
          return [...newWallets]
        })
      }
    })
  }

  interface IChildrenRender {
    children: string | number
    index?: number
  }

  const walletRender = ({ children, index }: IChildrenRender) => {
    const wallets = limitedTableDataRegistered.map((item) => {
      return { name: item.psp_provider, icon: item.icon }
    })
    const walletName = `pix${wallets[index].name}`
    const walletIcon = wallets[index].icon
    return (
      <s.WalletWrapper>
        <img width={34} height={34} src={walletIcon} />
        <s.Text
          margin="0px 0px 0px 8px"
          bold
          color={walletName as keyof typeof themeColors}
        >
          {children}
        </s.Text>
      </s.WalletWrapper>
    )
  }

  const IdRender = ({ children }: IChildrenRender) => {
    return (
      <s.IdContainer
        onClick={(e) => {
          e.stopPropagation()
          navigator.clipboard.writeText(children as string)
          showToast({ type: 'success', message: 'ID Copiado!' })
        }}
      >
        <s.IdWrapper>
          <s.Text fontWeight={600} color="maincolor">
            {children}
          </s.Text>
        </s.IdWrapper>
        <s.Icon width={24} height={24} name="clipboard" />
      </s.IdContainer>
    )
  }

  const renderSwitch = ({ children, index }) => {
    const switchIds = limitedTableDataRegistered.map((item) => item.id)
    const uuidObj = switchIds[index]
    const data = Object.assign(
      {},
      { children: children, index: index },
      uuidObj
    )

    return (
      <s.SwitchWrapper>
        <s.ToggleSwitch
          disabled={patchIsLoading}
          handleChange={() => {
            if (!patchIsLoading) {
              handlePatch(uuidObj)
              setSelectedPixIdChangeStatus(uuidObj)
            }
          }}
          data={data}
        />
        {patchIsLoading && selectedPixIdChangeStatus === uuidObj ? (
          <s.Loading
            type="spinner"
            data-testid="loading-spinner-patch-switch"
            color="maincolor"
          />
        ) : null}
      </s.SwitchWrapper>
    )
  }

  const renderAnyBankButton = ({ children, index }: IChildrenRender) => {
    const registration = tableInAnalysis[index]

    if (
      registration?.status === 'pending-onboarding' &&
      registration?.psp_provider?.includes('itau_anybank')
    ) {
      return (
        <s.Button
          data-testid={`analysis-account-list-button-${registration?.status}`}
          color="maincolor"
          onClick={() => {
            setWalletSettingsModal(
              `${registration.psp_provider}/3/${registration.status}/${registration.store_cnpj_cpf}`
            )
            setSelectedPixInAnalysisId(registration.registration_id)
          }}
        >
          <s.Text color="white" bold>
            Resolver
          </s.Text>
        </s.Button>
      )
    }

    if (
      (registration?.status === 'rejected' ||
        registration?.status === 'finished' ||
        registration?.status === 'in-progress') &&
      registration?.psp_provider?.includes('itau_anybank')
    ) {
      return (
        <s.Button
          color="transparent"
          data-testid={`analysis-account-list-button-${registration?.status}`}
          onClick={() => {
            setWalletSettingsModal(
              `${registration.psp_provider}/5/${registration.status}`
            )
            setSelectedPixInAnalysisId(registration.registration_id)
          }}
        >
          <s.Text color="maincolor" bold>
            Ver mais
          </s.Text>
        </s.Button>
      )
    }

    if (registration?.status === 'pending-token') {
      return (
        <s.Button
          color="maincolor"
          onClick={() => {
            setWalletSettingsModal(`${registration.psp_provider}-token`)
            setSelectedPixInAnalysisId(registration.registration_id)
          }}
        >
          <s.Text color="white" bold>
            Finalizar
          </s.Text>
        </s.Button>
      )
    }

    if (registration) {
      return (
        <s.Button
          color="maincolor"
          onClick={() => {
            setPixAnyBankErrorMessage(registration.error_message)
          }}
        >
          <s.Text color="white" bold>
            Acessar
          </s.Text>
        </s.Button>
      )
    }
    return null
  }

  const renderInAnalysisTable = () => {
    if (tableInAnalysis.length === 0) return <></>
    return (
      <>
        <s.TextTableTitle type="headline" bold color="maincolor">
          Contas em análise
        </s.TextTableTitle>
        <s.TableInAnalysis
          data={tableInAnalysis}
          isLoading={isLoadingRegistrationStatus}
          columns={[
            {
              headerName: 'ID do processo',
              objectName: 'id',
            },
            {
              headerName: 'PSP',
              objectName: 'wallet_friendly_name',
            },
            {
              headerName: 'CNPJ',
              objectName: 'store_cnpj_cpf',
            },
            {
              headerName: 'Situação',
              objectName: 'friendly_status_name',
            },
            {
              headerName: ' ',
              objectName: '',
              AltRender: renderAnyBankButton,
            },
          ]}
        />
      </>
    )
  }

  const renderWalletSettingsModal = React.useMemo(() => {
    const pspProvider = walletSettingsModal
    const walletInfo = wallets.find(
      (wallet) => wallet.wallet_name === pspProvider
    )

    if (
      pspProvider.match(
        /^(bb|bnb|itau|santander|bs2|sicredi|portobank|bradesco|sicoob|mercadopago)$/
      )
    ) {
      return (
        <WalletSettingsFormDefault
          isOpen={walletSettingsModal}
          handleClose={() => setWalletSettingsModal('')}
          wallet={walletInfo}
        />
      )
    }

    if (pspProvider === 'picpay_anybank') {
      return (
        <WalletSettingsFormAnybank
          isOpen={walletSettingsModal}
          isFrom="picpay_anybank"
          handleClose={() => setWalletSettingsModal('')}
        />
      )
    }
    if (pspProvider === 'pagseguro') {
      return (
        <WalletSettingsFormPagSeg
          isOpen={walletSettingsModal}
          handleClose={() => setWalletSettingsModal('')}
        />
      )
    }

    if (pspProvider === 'original') {
      return (
        <WalletSettingsFormPixSimples
          isOpen={walletSettingsModal}
          handleClose={() => setWalletSettingsModal('')}
          isFrom="original"
        />
      )
    }

    if (pspProvider === 'tbanks') {
      return (
        <WalletSettingsFormPixSimples
          isOpen={walletSettingsModal}
          isFrom="tbanks"
          handleClose={() => setWalletSettingsModal('')}
        />
      )
    }

    if (pspProvider.includes('tbanks-cashout')) {
      if (pspProvider === 'wallet-tbanks-cashout') {
        return (
          <WalletSettingsFormTbanksCashout
            isOpen={walletSettingsModal}
            handleClose={() => setWalletSettingsModal('')}
          />
        )
      }

      return (
        <WalletSettingsFormTbanksCashout
          isOpen={walletSettingsModal}
          handleClose={() => setWalletSettingsModal('')}
          userVerificationStatus={user.verification_status}
        />
      )
    }

    if (pspProvider === 'conexaoItau') {
      return (
        <WalletSettingsFormPixSimples
          isOpen={walletSettingsModal}
          isFrom="conexaoItau"
          handleClose={() => setWalletSettingsModal('')}
        />
      )
    }

    if (pspProvider?.includes('itau_anybank')) {
      const [pspProviderName, step, status, cnpj] = pspProvider.split('/')
      return (
        <WalletSettingsFormPixSimples
          isOpen={pspProviderName}
          isFrom="conexaoItau"
          fromStep={parseInt(step)}
          fromStatus={status as ItauAnyBankOnboardStatusType}
          companyDocument={cnpj}
          handleClose={() => setWalletSettingsModal('')}
        />
      )
    }

    if (pspProvider === 'original-token') {
      return (
        <PixSimplesModalContainer
          isOpen={walletSettingsModal}
          isFrom="original"
          handleClose={() => {
            setWalletSettingsModal('')
            setSelectedPixInAnalysisId('')
          }}
        >
          <TokenForm
            isFrom="original"
            handleClose={() => {
              setWalletSettingsModal('')
              setSelectedPixInAnalysisId('')
            }}
            pixFormState={{
              uuid: selectedPixInAnalysisId,
              cpf: user.cpf,
              name: `${user.first_name} ${user.last_name}`,
            }}
          />
        </PixSimplesModalContainer>
      )
    }

    if (pspProvider === 'tbanks-token') {
      return (
        <PixSimplesModalContainer
          isOpen={walletSettingsModal}
          isFrom="tbanks"
          handleClose={() => {
            setWalletSettingsModal('')
            setSelectedPixInAnalysisId('')
          }}
        >
          <TokenForm
            isFrom="tbanks"
            handleClose={() => {
              setWalletSettingsModal('')
              setSelectedPixInAnalysisId('')
            }}
            pixFormState={{
              uuid: selectedPixInAnalysisId,
              cpf: user.cpf,
              name: `${user.first_name} ${user.last_name}`,
            }}
          />
        </PixSimplesModalContainer>
      )
    }
  }, [walletSettingsModal, user, selectedPixInAnalysisId])

  const settingsButton = () => {
    return (
      <s.SettingsButton color="whitedetailed" data-testid="settings-button">
        <s.Icon name="gear" fill="maincolor" />
        <s.Text margin="0 0 0 8px" bold color="graytheme6">
          Configurações
        </s.Text>
      </s.SettingsButton>
    )
  }

  const renderEditFormModal = React.useMemo(() => {
    if (
      editFormInfo.pspProvider.match(
        /^(bb|bnb|itau|santander|bs2|sicredi|portobank|bradesco|sicoob|mercadopago)$/
      )
    ) {
      return (
        <SettingsWSFormDefault
          isOpen={!!editFormInfo.id && !!editFormInfo.pspProvider}
          handleClose={() =>
            setEditFormInfo({ id: '', pspProvider: '', pixProviderName: '' })
          }
          editFormInfo={editFormInfo}
        />
      )
    }

    if (editFormInfo.pspProvider === 'bradesco') {
      return (
        <EditWSFormBradesco
          certList={certSelectList}
          editFormInfo={editFormInfo}
          isOpen={!!editFormInfo.id && !!editFormInfo.pspProvider}
          handleClose={() =>
            setEditFormInfo({ id: '', pspProvider: '', pixProviderName: '' })
          }
        />
      )
    }
    if (editFormInfo.pspProvider === 'mercadopago') {
      return (
        <EditWSFormMP
          certList={certSelectList}
          editFormInfo={editFormInfo}
          isOpen={!!editFormInfo.id && !!editFormInfo.pspProvider}
          handleClose={() =>
            setEditFormInfo({ id: '', pspProvider: '', pixProviderName: '' })
          }
        />
      )
    }
    if (editFormInfo.pspProvider === 'pagseguro') {
      return (
        <EditWSFormPagSeg
          certList={certSelectList}
          editFormInfo={editFormInfo}
          isOpen={!!editFormInfo.id && !!editFormInfo.pspProvider}
          handleClose={() =>
            setEditFormInfo({ id: '', pspProvider: '', pixProviderName: '' })
          }
        />
      )
    }
    if (editFormInfo.pspProvider === 'original') {
      return (
        <EditWSFormOriginal
          certList={certSelectList}
          editFormInfo={editFormInfo}
          isOpen={!!editFormInfo.id && !!editFormInfo.pspProvider}
          handleClose={() =>
            setEditFormInfo({ id: '', pspProvider: '', pixProviderName: '' })
          }
        />
      )
    }
    if (editFormInfo.pspProvider === 'tbanks') {
      return (
        <EditWSFormTBanks
          certList={certSelectList}
          editFormInfo={editFormInfo}
          isOpen={!!editFormInfo.id && !!editFormInfo.pspProvider}
          handleClose={() =>
            setEditFormInfo({ id: '', pspProvider: '', pixProviderName: '' })
          }
        />
      )
    }
    if (editFormInfo.pspProvider === 'tbanks-cashout') {
      return (
        <EditWSFormTbanksCashout
          certList={certSelectList}
          editFormInfo={editFormInfo}
          isOpen={!!editFormInfo.id && !!editFormInfo.pspProvider}
          handleClose={() =>
            setEditFormInfo({ id: '', pspProvider: '', pixProviderName: '' })
          }
        />
      )
    }
    if (
      ['itau_anybank', 'itau_anybank_v1', 'itau_anybank_v2'].includes(
        editFormInfo.pspProvider
      )
    ) {
      return (
        <SettingsWSFormItauAnybank
          editSettingsInfo={editFormInfo}
          certList={certSelectList}
          isOpen={!!editFormInfo.id && !!editFormInfo.pspProvider}
          handleClose={() =>
            setEditFormInfo({ id: '', pspProvider: '', pixProviderName: '' })
          }
        />
      )
    }
  }, [editFormInfo])

  const memoizedPixList = React.useMemo(
    () => (
      <s.Table
        data={limitedTableDataRegistered}
        isLoading={isLoadingWalletSettings}
        columns={[
          {
            headerName: 'Apelido da Conta',
            objectName: 'name',
            AltRender: walletRender,
          },
          {
            headerName: 'Provedor',
            objectName: 'wallet_friendly_name',
          },
          {
            headerName: 'Modalidade',
            objectName: 'transaction_type',
            AltRender: TransactionTypeRender,
          },
          {
            headerName: 'Código UUID',
            objectName: 'id',
            AltRender: IdRender,
          },
          {
            headerName: 'Status',
            objectName: 'active',
            AltRender: renderSwitch,
          },
        ]}
        actionButtonsConfig={[
          {
            onClick: (value) => {
              const selectedWallet = walletSettings.data.find(
                (x) => x.id === value
              )
              setEditFormInfo({
                id: value,
                pspProvider:
                  selectedWallet.transaction_type === 'cashout'
                    ? `${selectedWallet.psp_provider}-cashout`
                    : selectedWallet.psp_provider,
                pixProviderName: selectedWallet.psp_provider,
              })
            },
            ButtonRender: settingsButton,
            actionName: '',
          },
        ]}
      />
    ),
    [
      limitedTableDataRegistered,
      isLoadingWalletSettings,
      walletSettings,
      patchIsLoading,
    ]
  )

  return (
    <s.Container>
      <PixAnyBankErrorMessageModal
        isOpen={!!pixAnyBankErrorMessage}
        handleClose={() => {
          setPixAnyBankErrorMessage('')
        }}
        message={pixAnyBankErrorMessage}
      />
      <PixCertificatesModal
        isOpen={certificatesModal}
        systemWallets={filterWallets()}
        handleClose={() => setCertificatesModal(false)}
      />

      {renderWalletSettingsModal}
      {renderEditFormModal}
      <Toast />
      <s.PixHeader>
        <s.Text type="heading3" fontWeight={500} color="graytheme6">
          Selecione uma instituição financeira para receber ou enviar pagamentos
        </s.Text>
        <s.FilterWrapper>
          <s.InputText
            width={544}
            placeholder="Buscar"
            suffix={<s.Icon fill="maincolor" name="search" />}
            value={searchSystemWalletText}
            onChange={(e) => {
              setSearchSystemWalletText(e.target.value)
            }}
          />
          {isFilterOpen ? (
            <>
              <s.Button
                height="40px"
                color="whitedetailed"
                onClick={() => setIsFilterOpen(false)}
              >
                <s.Icon name="settings" fill="maincolor" />
                <s.Text
                  margin="0 0 0 8px"
                  fontWeight={600}
                  color="graytheme6"
                  type="headline"
                >
                  Ocultar filtros
                </s.Text>
              </s.Button>
              <s.Button
                height="40px"
                color="whitedetailed"
                onClick={() => {
                  setOptions([
                    { value: 'cashin', checked: false },
                    { value: 'cashout', checked: false },
                    { value: 'bank_slip_settings', checked: false },
                    { value: 'anybank', checked: false },
                  ])
                  setSearchSystemWalletText('')
                }}
              >
                <s.Icon name="trash" fill="maincolor" />
                <s.Text
                  margin="0 0 0 8px"
                  fontWeight={600}
                  color="graytheme6"
                  type="headline"
                >
                  Limpar filtros
                </s.Text>
              </s.Button>
            </>
          ) : (
            <s.Button
              color="whitedetailed"
              height="40px"
              onClick={() => setIsFilterOpen(true)}
            >
              <s.Icon name="settings" fill="maincolor" />
              <s.Text
                margin="0 0 0 8px"
                fontWeight={600}
                color="graytheme6"
                type="headline"
              >
                Filtrar por funcionalidade
              </s.Text>
            </s.Button>
          )}
        </s.FilterWrapper>
        {isFilterOpen ? (
          <>
            <s.Text type="headline" fontWeight={600} color="graytheme6">
              Filtre por funcionalidades
            </s.Text>
            <s.FilterCardsContainer>
              {options.map((option, index) => (
                <FilterCards
                  key={index}
                  featureName={option.value}
                  checked={option.checked}
                  onCheck={() => handleOptionChange(option.value)}
                />
              ))}
            </s.FilterCardsContainer>
          </>
        ) : (
          <></>
        )}
      </s.PixHeader>
      {options.some((option) => option.checked) ||
      searchSystemWalletText.length > 2 ? (
        <s.Text fontWeight={600} type="headline" margin="0 0 16px 0">
          Instituições disponíveis
        </s.Text>
      ) : (
        <></>
      )}
      <s.Content>
        <SystemWallets
          options={options}
          hasError={hasError}
          isLoading={isLoading}
          wallets={wallets}
          setWalletSettingsModal={setWalletSettingsModal}
        />
      </s.Content>
      <s.CertificatesContainer>
        <s.ManageCertificatesButton
          onClick={() => {
            setCertificatesModal(true)
          }}
        >
          gerenciar certificados
        </s.ManageCertificatesButton>
      </s.CertificatesContainer>

      {renderInAnalysisTable()}
      <s.TextTableTitle type="headline" bold color="maincolor">
        Contas cadastradas
      </s.TextTableTitle>
      {memoizedPixList}
      <s.PaginationWrapper>
        {walletSettings.total > 0 ? (
          <s.Pagination
            count={10}
            offset={offset}
            total={walletSettings.total}
            handleChange={(offset) => {
              setOffset(offset)
            }}
          />
        ) : null}
      </s.PaginationWrapper>
    </s.Container>
  )
}

export default React.memo(Pix)
