import React from 'react'
import * as s from './styles'
import { applyCnpjMask } from 'helpers/masks'

interface Props {
  cnpj: string
  closeModal: () => void
}

const UnableToEnableAccount: React.FC<Props> = ({ cnpj, closeModal }) => {
  return (
    <s.Content
      width={304}
      height={360}
      data-testid="content-on-unable-to-enable-account"
    >
      <s.Text margin="0 0 16px 0" color="graytheme6" bold type="heading4">
        Seu usuário não pode habilitar esta conta.
      </s.Text>
      <s.Text margin="0 0 33px 0" color="graytheme6" type="headline">
        Somente um <u>representante legal do CNPJ titular da conta bancária</u>{' '}
        pode habilitar esta conta Pix. Faça login com outro usuário caso este
        não esteja no quadro societário.
      </s.Text>
      <s.InputText
        width={304}
        labelColor="graytheme8"
        label="CNPJ da conta bancária"
        value={applyCnpjMask(cnpj)}
        onChange={() => {}}
        disabled
      />
      <s.ButtonWrapper margin="40px 0 0 0" data-testid="button-on-register">
        <s.Button
          data-testid="unable-to-enable-close-modal-button"
          onClick={() => closeModal()}
          color="white"
          height="42px"
        >
          <s.Text type="headline" color="maincolor" bold>
            Fechar
          </s.Text>
        </s.Button>
      </s.ButtonWrapper>
    </s.Content>
  )
}

export default UnableToEnableAccount
