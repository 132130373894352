import React from 'react'

import CashoutWelcomeImage from 'assets/images/cashout-welcome.png'
import tbanksIcon from 'assets/pix/tbanks-card-icon.svg'
import originalRoundedLogo from 'assets/pix/original-rounded-icon.svg'
import santanderRoundedLogo from 'assets/pix/santander-rounded-icon.svg'
import itauRoundedLogo from 'assets/pix/itau-rounded-icon.svg'
import UseGetSystemWallets from 'hooks/useGetSystemWallets'
import { WalletSettingsFormTbanksCashout } from 'components/PixWalletForms/WalletSettingsFormCashout'

import * as s from './styles'
import { checkUserClaim } from 'helpers/claims'
import { getValueFromLocalStorage } from 'helpers/localStorage'
import { UserSessionInfo } from 'store/modules/auth/types'

const CashoutFlowArrow: React.FC = () => {
  return (
    <s.FlowArrow>
      <s.Icon fill="graytheme6" name="moneysign" />
      <s.Icon width={78} name="largearrow" />
    </s.FlowArrow>
  )
}

const CashoutInitialPage: React.FC = () => {
  const {
    systemWallets,
    isLoading: isLoadingSystemWallets,
    hasError: hasErrorSystemWallets,
  } = UseGetSystemWallets()

  const [walletSettingsModal, setWalletSettingsModal] = React.useState<string>(
    ''
  )

  const tbanksCashoutCardClaim = 'panel_pages_pix@card_tbanks_cashout'  
  const currentUserSession: UserSessionInfo = JSON.parse(getValueFromLocalStorage('currentUserSession'))

  return (
    <s.Container>
      <WalletSettingsFormTbanksCashout
        isOpen={walletSettingsModal}
        handleClose={() => setWalletSettingsModal('')}
      />
      <s.MainCard width={1188}>
        <s.HeaderText
          margin="0 0 40px 0"
          color="graytheme6"
          bold
          type="heading2"
        >
          Cadastre sua conta para envios de pagamentos
        </s.HeaderText>
        <s.Wrapper>
          <s.Image src={CashoutWelcomeImage} />
          <div>
            <s.Card height={104} width={434}>
              <s.CashoutFlow>
                <s.CashoutStep>
                  <s.Icon fill="graytheme6" name="pixcard" />
                </s.CashoutStep>
                <CashoutFlowArrow />
                <s.CashoutStep blue>
                  <s.Icon fill="white" name="register" />
                </s.CashoutStep>
                <CashoutFlowArrow />
                <s.CashoutStep>
                  <s.Icon fill="graytheme6" name="bank" />
                </s.CashoutStep>
              </s.CashoutFlow>
            </s.Card>
            <s.Text color="graytheme6" type="headline" margin="32px 0">
              Além de receber pagamentos, também é possível fazer transferências
              via Pix para outras contas através do seu sistema.
            </s.Text>
            <s.Card height={81} width={434}>
              <s.Text color="graytheme6">
                A habilitação deste tipo de conta só pode ser feita através do{' '}
                <b>usuário de um representante legal</b> do CNPJ da conta
                bancária cadastrada.
              </s.Text>
            </s.Card>
          </div>
        </s.Wrapper>
      </s.MainCard>
      <s.MainCard width={1188}>
        <s.Text color="maincolor" bold type="heading3">
          Qual conta Pix você deseja cadastrar?
        </s.Text>
        <s.CashoutCardsWrapper>
          {checkUserClaim(currentUserSession?.user_claims, tbanksCashoutCardClaim) ? (
            ''
          ) : (
            <>
              {systemWallets.find((wallet) => wallet.wallet_name === 'tbanks')
                .cashout_active ? (
                <s.TBanksCardCashout
                  onClick={() => {
                    setWalletSettingsModal('tbanks-cashout')
                  }}
                >
                  <img src={tbanksIcon} alt="imagem pix TBankS Envios" />
                  <s.FooterWrapper>
                    <s.TextCardFooter>
                      <s.Icon name="cashoutarrowup" color="white" />
                      envio
                    </s.TextCardFooter>
                  </s.FooterWrapper>
                </s.TBanksCardCashout>
              ) : (
                <></>
              )}
            </>
          )}

          <s.ComingSoonCard>
            <s.Image width={34} height={34} src={originalRoundedLogo} />
            <s.Image width={34} height={34} src={santanderRoundedLogo} />
            <s.Image width={34} height={34} src={itauRoundedLogo} />
            <s.Message>
              <s.Icon fill="pendingStatusIconColor" name="clock" />
              <s.Text bold color="pendingStatusTextColor">
                em breve
              </s.Text>
            </s.Message>
          </s.ComingSoonCard>
        </s.CashoutCardsWrapper>
      </s.MainCard>
    </s.Container>
  )
}

export default CashoutInitialPage
