import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'store/modules/rootState'

import {
  PostUser,
  PostUserError,
  PostUserIsLoading,
} from 'store/modules/user/action'

import { UserState } from 'store/modules/user/types'

import userService, { UserInterface } from 'services/user'

import { Partial } from 'helpers/partial'

interface UsePostUser extends UserState {
  createUser: (user: UserInterface) => Promise<void>,
}

export const usePostUser = (): Partial<UsePostUser> => {
  const dispatch = useDispatch()
  const { postResponse, postIsLoading, postError } = useTypedSelector((store) => ({
    postResponse: store.user.postResponse,
    postIsLoading: store.user.postIsLoading,
    postError: store.user.postError,
  }))

  const createUser = useCallback(async (user): Promise<void> => {
    dispatch(PostUserIsLoading())
    await userService.createUser(user)
      .then((response) => {
        dispatch(PostUser(response.data))
      })
      .catch(() => {
        dispatch(PostUserError())
      })
  }, [dispatch])

  return { postResponse, postError, postIsLoading, createUser}
}
