import React, { useCallback, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { dict } from 'components/IconExporter'

import * as s from './styles'
import * as t from './types'

export default function Select({
  selected,
  options,
  label,
  testId,
  width,
  disabled = false,
  isLoading = false,
  optionBoxSize = 400,
  optionBoxDirection = 'bottom',
  emptyText = 'Não há opções disponíveis',
  suffix = undefined,
  handlerSuffix,
  error,
  onSelectedOption = () => {},
  ...rest
}: t.IProps) {
  const [isOptionsOpen, setOptionsOpen] = useState(false)

  const renderOptions = useCallback(() => {
    if (!options.length) {
      return (
        <s.OptionsItem data-testid="option-empty" key={uuid()}>
          <s.Text type="body" color="maincolor" bold>
            {emptyText}
          </s.Text>
        </s.OptionsItem>
      )
    }
    return options.map((option) => {
      return (
        <s.OptionsItem
          selected={option.id === selected.id}
          onClick={() => {
            if (option.onClick) {
              onSelectedOption({
                ...option,
              })
              option.onClick()
            }
            setOptionsOpen(false)
          }}
          data-testid={`option-${option.text.replace(/\s/gs, '-')}`}
          key={uuid()}
        >
          {option.icon?.toString().includes('s3') ? (
            <img alt="icone" src={option.icon} />
          ) : (
            <s.Icon
              name={option.icon as keyof typeof dict}
              color="gray2"
              width={21}
              height={21}
            />
          )}
          <s.Text type="body" data-testid="option-text" color="maincolor" bold>
            {option.text}
          </s.Text>
        </s.OptionsItem>
      )
    })
  }, [options, selected, emptyText])

  const suffixRender = () => {
    if (isLoading) {
      return <s.Loading type="spinner" color="maincolor" />
    }

    if (suffix) {
      return suffix
    }

    return (
      <s.Icon
        name={isOptionsOpen ? 'arrowup' : 'arrowdown'}
        fill={disabled ? 'gray4' : 'maincolor'}
        width={21}
        height={21}
      />
    )
  }
  return (
    <s.Container
      width={width}
      disabled={disabled}
      isOptionsOpen={isOptionsOpen}
      {...rest}
    >
      {label ? (
        <s.LabelText
          bold
          type="headline"
          color={error ? 'lightred1' : 'maincolor'}
        >
          {label}
        </s.LabelText>
      ) : null}
      <s.InputContainer
        disabled={disabled}
        onClick={() => {
          if (!isLoading && !disabled) {
            setOptionsOpen((currentState) => !currentState)
          }
          if (suffix && handlerSuffix) {
            handlerSuffix()
          }
        }}
        data-testid={`select-container`}
      >
        <s.SelectedItem
          error={error}
          disabled={disabled}
          isOptionsOpen={isOptionsOpen}
        >
          <s.Text data-testid={testId} type="body" bold color="maincolor">
            {selected.name}
          </s.Text>
          {disabled ? '' : suffixRender()}
        </s.SelectedItem>
        {error && typeof error === 'object' && (
          <s.ErrorMessage>
            {error.icon ? <s.ErrorIcon name={error.icon} width="16px" /> : null}
            <s.TextError color="lightred1">{error.message}</s.TextError>
          </s.ErrorMessage>
        )}
      </s.InputContainer>

      {!suffix && isOptionsOpen && (
        <>
          <s.OptionsWrapper
            optionBoxDirection={optionBoxDirection}
            optionBoxSize={optionBoxSize}
            hasLabel={!!label}
          >
            {renderOptions()}
          </s.OptionsWrapper>
          <s.Overlay
            data-testid="select-overlay"
            onClick={() => {
              setOptionsOpen(false)
            }}
          />
        </>
      )}
    </s.Container>
  )
}
