import styled, { css, keyframes } from 'styled-components'
import { Container as ContainerCmp } from 'styled-bootstrap-grid'

import CardCmp from 'components/Card'
import InputTextCmp from 'components/Inputs/Text'
import InputTextPasswordCmp from 'components/Inputs/Text/Password'
import TextCmp from 'components/Text'
import ButtonCmp from 'components/Button'
import ButtonLinkCmp from 'components/Button/ButtonLink'
import LoadingCmp from 'components/Loading'
import IconExporter from 'components/IconExporter'

export const flip = keyframes`
  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(90deg);
  }
`

export const Background = styled.div`
  background-image: url(${({ theme }) => theme.assets.login.backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
`

export const Container = styled(ContainerCmp)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  @media screen and (max-height: ${(props) =>
      props.theme.grid.breakpoints.md}px) {
    height: auto;
    min-height: 100vh;
    padding: 50px 0;
  }
`

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1000px;
`
interface ICard {
  isFlipped?: boolean
}

export const Card = styled(CardCmp)<ICard>`
  position: relative;
  transition: all 0.25s ease-in-out;
  margin-top: ${({ theme }) => theme.assets.login.marginCardTop};
  padding-bottom: 0;
  ${(props) =>
    props.isFlipped
      ? css`
          animation: ${flip} 0.25s linear;
        `
      : css`
          /* animation: ${flip} 1s linear; */
        `};
`

export const InputText = styled(InputTextCmp)``

export const Text = styled(TextCmp)``

export const Button = styled(ButtonCmp)``

export const CardWrapper = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const ButtonLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const ButtonLink = styled(ButtonLinkCmp)``

export const InputTextPassword = styled(InputTextPasswordCmp)``

export const Loading = styled(LoadingCmp)``

export const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme2};
  position: absolute;
  top: 85px;
  left: 0;
  width: 100%;
`

export const Image = styled.img`
  max-width: 377px;
`

export const TitleWrapper = styled.div`
  ::after {
    content: '';
    right: 0;
    width: 100%;
    position: absolute;
    height: 1px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
  }
`

export const EmailWrapper = styled.div`
  padding: 24px 8px;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-items: center;
  ::-webkit-scrollbar-track {
    margin-bottom: 32px;
  }
`

export const ButtonWrapper = styled.div`
  min-height: 104px;
  display: flex;

  :has(> :last-child:nth-child(2)) {
    justify-content: space-between;
  }

  > button {
    margin: 32px 0 32px 0;
    height: 40px;
  }

  ::before {
    content: '';
    right: 0;
    width: 100%;
    position: absolute;
    height: 1px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
  }
`

export const SectionLine = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 40px;
  width: 100%;
`

export const Icon = styled(IconExporter)``

export const InfoBox = styled.div<{
  error?: boolean
}>`
  display: flex;
  padding: 12px 16px;
  border-radius: 8px;
  flex-direction: column;
  margin-bottom: 8px;
  border: 1px solid ${({ theme }) => theme.colors.graytheme2};
  width: 100%;

  ${({ error, theme }) =>
    error
      ? css`
          background-color: ${theme.colors.cancelledStatusBackground};
          border: 0;
        `
      : css``}
`

export const PasswordWrapper = styled.div`
  padding: 24px 8px;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-items: center;
  ::-webkit-scrollbar-track {
    margin-bottom: 32px;
  }
`

export const Align = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const AlignStart = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`
