import { useState } from 'react'
import { get, baseURLRegistrationPosProduct } from '../services/api'
import { showToast } from 'components/Toast'

export interface PosProducts {
  pos_product_uuid: string
  pos_product_name: string
  external_crm_id: string | null
}

interface UseGetRegistrationPosProducts {
  posProducts: PosProducts[]
  isLoading: boolean
  hasError: boolean
  getPosProducts: () => Promise<void>
}

const useGetRegistrationPosProducts = (): UseGetRegistrationPosProducts => {
  const [posProducts, setPosProducts] = useState<PosProducts[] | []>([])
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  const getPosProducts = async () => {
    setIsLoading(true)
    await get(`${baseURLRegistrationPosProduct}`)
      .then((response) => {
        setPosProducts(response.data)
      })
      .catch((e) => {
        showToast({
          type: 'error',
          message: JSON.parse(e.request.response)?.message,
        })
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  return { posProducts, isLoading, hasError, getPosProducts }
}

export default useGetRegistrationPosProducts
