import styled from 'styled-components'
import { Container as ContainerCmp } from 'styled-bootstrap-grid'

import CardCmp from 'components/Card'
import TextCmp from 'components/Text'
import ButtonCmp from 'components/Button'
import InputTextCmp from 'components/Inputs/Text'
import SelectCmp from 'components/Inputs/Select'
import DatepickerCmp from 'components/Datepicker'
import IconCmp from 'components/IconExporter'
import { OptionsItem } from 'components/Inputs/Select/styles'

export const Icon = styled(IconCmp)``

export const Card = styled(CardCmp)`
  padding: 40px 48px 40px 48px;
  height: 504px;
  transition: all ease 0.5s;
  width: 400px;
`

export const OneColGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
`

export const InputWrapper = styled.div``

export const Text = styled(TextCmp)`
  font-size: 18px;
`

export const GradientText = styled(TextCmp)`
  background: linear-gradient(
    ${({ theme }) => theme.colors.lightmaincolor4},
    ${({ theme }) => theme.colors.maincolor}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const Button = styled(ButtonCmp)`
  margin-top: 16px;
`

export const DateInputWrapper = styled.div``

export const Select = styled(SelectCmp)`
  margin-bottom: 20px;

  ${OptionsItem} {
    height: 60px;
    img {
      margin-right: 16px;
    }
  }
`
export const InputText = styled(InputTextCmp)`
  margin-bottom: 20px;
`

export const Datepicker = styled(DatepickerCmp)`
  margin-bottom: 20px;
  display: inline-block;
  width: auto;
`
