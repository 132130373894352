import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  text-align: center;
  max-width: 304px;
`

export const HexInput = styled.div`
  ${({ theme }) => theme.fontTypes.headline}
  display: flex;
  align-items: center;
  min-width: 304px;
  padding: 9px 10px;
  width: 100%;
  box-shadow: 0px 1px 8px 2px ${({ theme }) => theme.colors.graytheme3};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.graytheme3};

  > button {
    text-decoration: none;
    padding: 0px;
    text-align: right;
    border: none;
    width: 100%;
    background: inherit;
  }

  > input {
    text-decoration: none;
    border: none;
    width: 250px;
    text-align: left;
    margin-left: 2px;
    width: 250px;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.graytheme6};
  }
`

export const Outter = styled.div`
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 304px !important;
  height: 64px !important;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 8px 2px #a3bacc;
  top: 40px;
`

// removing pre-defined styles
export const Inner = styled.div`
  display: inline-block;
  margin-top: 20px;

  .react-colorful {
    width: 268px !important;
    height: 24px;
  }

  .react-colorful__hue {
    height: 24px;
    border-radius: 0px !important;
  }

  .react-colorful__saturation {
    display: none !important;
  }

  .react-colorful__saturation-pointer {
    display: none !important;
  }

  .react-colorful__hue-pointer {
    width: 36px !important;
    height: 36px !important;
    border-radius: auto !important;
  }
`
