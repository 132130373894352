import styled from 'styled-components'
import TextCmp from 'components/Text'
import InputTextCmp from 'components/Inputs/Text'
import SelectCmp from 'components/Inputs/Select'
import ButtonCmp from 'components/Button'
import InputTextPasswordCmp from 'components/Inputs/Text/Password'
import theme from 'styles/theme'
import TableCmp from 'components/Table'
import ModalCmp from 'components/Modal'
import PaginationCmp from 'components/Pagination'
import LoadingCmp from 'components/Loading'

export const ModalContent = styled.div`
  width: 450px;
`

export const Text = styled(TextCmp)`
  margin-top: auto;
  margin-bottom: auto;
`

export const InputText = styled(InputTextCmp)``

export const InputTextArea = styled.textarea`
  width: 450px;
  border: 2px solid ${({ theme }) => theme.colors.maincolor};
  border-radius: 4px;
  height: 100px;
  margin-bottom: 32px;
`

export const InputGroup = styled.div`
  margin-bottom: 30px;
`

export const Select = styled(SelectCmp)``

export const Button = styled(ButtonCmp)`
  > span {
    text-transform: none;
  }
`
export const List = styled.ul`
  margin: 20px;
  color: ${(props) => props.theme.colors.maincolor};
`

export const Item = styled.li`
  color: ${(props) => theme.colors[props.color]};
`

export const InputTextPassword = styled(InputTextPasswordCmp)``

export const ButtonGroup = styled.div`
  text-align: center;
  margin-top: 20px;

  Button:first-child {
    margin-right: 20px;
  }
`

export const Table = styled(TableCmp)`
  * > th {
    :first-child {
      width: 25%;
    }
    :nth-child(2) {
      width: 20%;
    }
    :nth-child(3) {
      width: 25%;
    }
  }
`
export const Modal = styled(ModalCmp)``

export const EditModal = styled(ModalCmp)`
  z-index: 100;
`

export const ModalContainer = styled.div`
  width: 1400px;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SelectWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 5fr;
  margin-bottom: ${theme.spacing(5)}px;
`

export const Pagination = styled(PaginationCmp)``

export const Loading = styled(LoadingCmp)`
  margin-top: auto;
  margin-bottom: auto;
`
