import React from 'react'
import * as s from './styles'
import { applyCpfCnpjMask, applyPhoneMask } from 'helpers/masks'
import {
  isValidCnpj,
  isValidCpf,
  isValidEmail,
  isValidPhone,
  isNotEmpty,
} from 'helpers/validators'

export interface PixKeyType {
  id: number
  type?: 'cpf' | 'cnpj' | 'phone' | 'email' | 'random_key'
  name: string
}

interface IProps {
  hasConfimationField?: boolean
  value: string
  onChange?: (e) => void
  pixKeyErr?: string
  testId?: string
  onPixKeyErr?: (v) => void
  typesFilter?: string[]
  selectedType?: React.MutableRefObject<PixKeyType>
  selectSize?: number
}

export const NewPixKeyInput: React.FC<IProps> = ({
  hasConfimationField,
  value,
  onChange,
  pixKeyErr,
  testId,
  onPixKeyErr,
  selectSize = 400,
  typesFilter = ['cpf', 'cnpj', 'telefone', 'email', 'aleatória'],
  selectedType,
}) => {
  const [placeholder, setPlaceholder] = React.useState(
    'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX'
  )
  const [pixKeyConfirmation, setPixKeyConfirmation] = React.useState<string>('')
  const [pixKeyConfirmationError, setPixKeyConfirmationError] =
    React.useState<string>('')
  const [selected, setSelected] = React.useState<PixKeyType>({
    id: 5,
    type: 'random_key',
    name: 'Aleatória',
  })
  const resetMask = (selectedOption: PixKeyType) => {
    setSelected((prev) => ({ ...prev, ...selectedOption }))
    onChange('')
    onPixKeyErr('')
    setPixKeyConfirmation('')
    if (selectedOption.id === 1) setPlaceholder('XXX.XXX.XXX-XX')
    if (selectedOption.id === 2) setPlaceholder('XX.XXX.XXX/XXXX-XX')
    if (selectedOption.id === 3) setPlaceholder('(XX) XXXX-XXXX')
    if (selectedOption.id === 4) setPlaceholder('shipay@email.com')
    if (selectedOption.id === 5)
      setPlaceholder('XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX')
  }

  const validate = (v) => {
    if (selected.id === 5) {
      const err = isNotEmpty(v) ? '' : 'Campo Obrigatório'
      onPixKeyErr(err)
    }

    if (selected.id === 4) {
      const err = isValidEmail(v) ? '' : 'Email Inválido'
      onPixKeyErr(err)
    }

    if (selected.id === 3) {
      const err = isValidPhone(v) ? '' : 'Telefone Inválido'
      onPixKeyErr(err)
    }

    if (selected.id === 2) {
      const err = isValidCnpj(v) ? '' : 'CNPJ Inválido'
      onPixKeyErr(err)
    }

    if (selected.id === 1) {
      const err = isValidCpf(v) ? '' : 'CPF Inválido'
      onPixKeyErr(err)
    }
  }

  const validateConfimation = (v1, v2 = undefined) => {
    const errMsg = 'Chaves Diferentes'
    if (!v1) {
      setPixKeyConfirmationError(errMsg)
      onPixKeyErr(errMsg)
      return
    }

    if (v2) {
      const err = v1 === v2 ? '' : errMsg
      setPixKeyConfirmationError(err)
      onPixKeyErr(err)
    } else {
      const err = v1 === value ? '' : errMsg
      setPixKeyConfirmationError(err)
      onPixKeyErr(err)
    }
  }

  return (
    <>
      <s.NewInputGroupPixKey>
        <s.SectionLine>
          <s.Text fontWeight={600} type="headline" color="graytheme6">
            Tipo de Chave Pix
          </s.Text>
          <s.NewSelect
            options={[
              {
                id: 1,
                text: 'CPF',
                onClick: () => {
                  selectedType.current = {
                    id: 1,
                    type: 'cpf',
                    name: 'CPF',
                  }
                  resetMask({ id: 1, type: 'cpf', name: 'CPF' })
                },
              },
              {
                id: 2,
                text: 'CNPJ',
                onClick: () => {
                  selectedType.current = {
                    id: 2,
                    type: 'cnpj',
                    name: 'CNPJ',
                  }
                  resetMask({ id: 2, type: 'cnpj', name: 'CNPJ' })
                },
              },
              {
                id: 3,
                text: 'Telefone',
                onClick: () => {
                  selectedType.current = {
                    id: 3,
                    type: 'phone',
                    name: 'Telefone',
                  }
                  resetMask({ id: 3, type: 'phone', name: 'Telefone' })
                },
              },
              {
                id: 4,
                text: 'Email',
                onClick: () => {
                  selectedType.current = {
                    id: 4,
                    type: 'email',
                    name: 'Email',
                  }
                  resetMask({ id: 4, type: 'phone', name: 'Email' })
                },
              },
              {
                id: 5,
                text: 'Aleatória',
                onClick: () => {
                  selectedType.current = {
                    id: 5,
                    type: 'random_key',
                    name: 'Aleatória',
                  }
                  resetMask({ id: 5, type: 'random_key', name: 'Aleatória' })
                },
              },
            ].filter((x) => {
              if (typesFilter.includes(x.text.toLowerCase())) {
                return x
              }
            })}
            selected={selected}
            optionBoxSize={selectSize}
          />
        </s.SectionLine>
        <s.SectionLine>
          <s.Text
            fontWeight={600}
            type="headline"
            color={pixKeyErr ? 'redshipay' : 'graytheme6'}
          >
            Chave Pix
          </s.Text>
          <s.InputText
            maxLength={100}
            width={260}
            testId={testId}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              const inputValue = e.target.value
              if (selected.id < 3) {
                const maskedInputValue = applyCpfCnpjMask(inputValue)
                onChange(maskedInputValue)
                validate(maskedInputValue)
                if (hasConfimationField)
                  validateConfimation(pixKeyConfirmation, maskedInputValue)
              } else if (selected.id === 3) {
                const maskedInputValue = applyPhoneMask(inputValue)
                onChange(maskedInputValue)
                validate(maskedInputValue)
                if (hasConfimationField)
                  validateConfimation(pixKeyConfirmation, maskedInputValue)
              } else {
                onChange(inputValue)
                validate(inputValue)
                if (hasConfimationField)
                  validateConfimation(pixKeyConfirmation, inputValue)
              }
            }}
            error={pixKeyErr ? { message: pixKeyErr } : false}
            suffix={
              pixKeyErr ? (
                <s.Icon name="alertcirclefilled" fill="lightred2" />
              ) : (
                <></>
              )
            }
          />
        </s.SectionLine>
      </s.NewInputGroupPixKey>
    </>
  )
}
