export const Types = {
  GET_PROFILE: 'GET_PROFILE',
  GET_PROFILE_LOADING: 'GET_PROFILE_LOADING',
  GET_PROFILE_ERROR: 'GET_PROFILE_ERROR',
}

export interface PayloadInterface {
  access_key: string
  customer_name: string
  impersonate: boolean
  pos_product_name: string
  secret_key: string
  rede: string
  pos_product_email?: string
  pos_product_logo?: string
  retail_chain_name?: string
}

export interface ActionInterface {
  type: keyof typeof Types
  payload: PayloadInterface
}

export interface ProfileState {
  profile: PayloadInterface
  isLoading: boolean
  hasError: boolean
}
