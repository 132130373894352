export const Types = {
  POST_AUTH: 'POST_AUTH',
  POST_AUTH_RESPONSE: 'POST_AUTH_RESPONSE',
  POST_AUTH_ERROR: 'POST_AUTH_ERROR',
}

export interface RequestInterface {
  auth_token: number
  password: string
  username: string
}

export interface ResponseInterface {
  access_token: string
  email: string
  id: string
  name: string
  refresh_token: string
  roles: string[]
}

export interface ActionInterface {
  type: keyof typeof Types
  request: RequestInterface
  response: ResponseInterface
  message: string
}

export interface AuthState {
  request: RequestInterface
  isLoading: boolean
  hasError: boolean
  errorMessage: string
  response: ResponseInterface
}

export interface UserSessionData {
  id: string
  name: string
  access_token: string
}

export interface AccessTokenJSON {
  identity: {
    customer_id: number | null
    impersonate: boolean
  }
  user_claims: Array<string>
}

export type UserSessionInfo = Omit<UserSessionData, 'access_token'> &
  Omit<AccessTokenJSON, 'identity'> & {
    customer_id: number | null
    impersonate: boolean
  }
