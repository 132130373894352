import styled from 'styled-components'

import ModalCmp from 'components/Modal'
import TextCmp from 'components/Text'
import ButtonCmp from 'components/Button'
import InputTextCmp from 'components/Inputs/Text'

export const InputText = styled(InputTextCmp)`
  width: 400px;
`

export const ButtonCenter = styled.div`
  text-align: center;
`

export const ButtonFloatRight = styled.div`
  text-align: right;
  > :first-child {
    padding: 20px 30px;
    margin-right: 20px;
  }  
`

export const Button = styled(ButtonCmp)``

export const InputToggleGroup = styled.div`
  display: flex;
`

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

export const Text = styled(TextCmp)``

export const Modal = styled(ModalCmp)``

export const ModalContent = styled.div`
  width: 450px;
`

export const ModalContentSplits = styled.div`
  width: 1100px;
`
