export const Types = {
  GET_WALLETS: "GET_WALLETS",
  GET_WALLETS_LOADING: "GET_WALLETS_LOADING",
  GET_WALLETS_ERROR: "GET_WALLETS_ERROR",
};

export interface WalletInterface {
  id: string;
  name: string;
}

export interface ActionInterface {
  type: keyof typeof Types;
  payload: WalletInterface[];
}

export interface WalletState {
  wallets: WalletInterface[];
  isLoading: boolean;
  hasError: boolean;
}
