import styled, { css } from 'styled-components'

import { Container as ContainerCmp } from 'styled-bootstrap-grid'
import TextCmp from 'components/Text'
import InputTextCmp from 'components/Inputs/Text'
import IconExporter from 'components/IconExporter'
import CardCmp from 'components/Card'
import ButtonCmp from 'components/Button'
import LoadingCmp from 'components/Loading'
import RadioComponent from 'components/Inputs/RadioButton'
import TableCmp from 'components/Table'
import PaginationCmp from 'components/Pagination'
import { Input } from 'components/Inputs/Text/styles'
import ModalCmp from 'components/Modal'
import SelectCmp from 'components/Inputs/Select'
import ModalButtonFooterCmp from 'components/Button/ModalButtonFooter'

export const Container = styled(ContainerCmp)`
  align-items: flex-start;
  justify-content: flex-start;
  display: block;
  flex-direction: column;
  height: 100vh;
  max-width: 1800px;
  padding: 32px;
`
export const ManagementContainer = styled(ContainerCmp)`
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 1200px;
  padding: 32px;
  margin-left: 40px;
`

export const Text = styled(TextCmp)``

export const ClickableText = styled(TextCmp)`
  margin-top: 8px;
  b {
    color: ${({ theme }) => theme.colors.maincolor};
    cursor: pointer;
  }
`

export const Pagination = styled(PaginationCmp)`
  position: absolute;
  bottom: 0px;
  margin-bottom: 0px;
`

export const Card = styled(CardCmp)`
  padding: 32px;
`

export const DestinationAccountCard = styled(Card)`
  margin-bottom: 32px;
  gap: 32px;
`

export const SplitFeeCard = styled(Card)`
  margin-bottom: 32px;
  gap: 24px;
`

export const SplitFeeManagerCard = styled(Card)`
  margin-bottom: 32px;
  display: inline-table;
`

export const InputText = styled(InputTextCmp)``

export const Icon = styled(IconExporter)``

export const WrapperText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
export const ContainerBoxText = styled.div`
  width: 528px;
  height: 51px;
`

export const Button = styled(ButtonCmp)``

export const Loading = styled(LoadingCmp)``

export const Wrapper = styled.div`
  display: flex;
  gap: 32px;
  padding-top: 32px;
  height: 250px;
`

export const DestinationAccountCardWrapper = styled.div``

export const RadioButton = styled(RadioComponent)``

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 120px;
  height: 40px;

  border: 2px solid ${({ theme }) => theme.colors.maincolor};
  border-radius: 8px;
`

export const DisabledRadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 120px;
  height: 40px;

  border: 2px solid ${({ theme }) => theme.colors.graytheme2};
  border-radius: 8px;
`

export const ComissionType = styled.div`
  display: flex;
  gap: 32px;
`

export const Row = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 20px;
`

export const AddAccountButton = styled(ButtonCmp)`
  position: absolute;
  right: 0px;
`

export const Table = styled(TableCmp)`
  * > th {
    :first-child {
      width: 20%;
    }
    :nth-child(2) {
      width: 25%;
    }
    :nth-child(3) {
      width: 20%;
    }
  }
`
export const TableSplits = styled(TableCmp)`
  > thead > tr > th {
    padding-left: 16px;
    :nth-child(3) {
      width: 100px;
    }
    :nth-child(4) {
      width: 120px;
    }
    :nth-child(5) {
      width: 10%;
    }
  }

  > tbody > tr {
    height: 50px;
  }
`

export const SplitsWrapper = styled.div`
  margin-top: 48px;
`

export const FeeInputText = styled(InputText)`
  ${Input} {
    font-weight: bold;
  }
`

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

export const ButtonGroup = styled.div`
  text-align: right;
  margin-top: 20px;

  > :first-child {
    margin-right: 40px;
  }
`

export const ConfirmationModalContent = styled.div`
  width: 554px;
`
export const ConfirmationModal = styled(ModalCmp)`
  z-index: 14;
`

export const Modal = styled(ModalCmp)``

export const SimulatePercentageModal = styled(ModalCmp)`
  z-index: 14;
`

export const ModalContainer = styled.div`
  display: flex;
  width: 508px;
  height: 460px;
  flex-direction: column;

  > ${Text} {
    width: 400px;
  }
`

export const ModalContainerSimulate = styled.div`
  display: flex;
  width: 508px;
  height: 640px;
  flex-direction: column;

  > ${Text} {
    width: 400px;
  }

  > * {
    margin-bottom: 32px;
  }
`

export const MiniModalContainer = styled.div`
  display: flex;
  width: 400px;
  height: 180px;
  flex-direction: column;

  > ${Text} {
    :first-child {
      margin-bottom: 16px;
    }
  }
  > ${Button} {
    height: 50px;
    margin-top: 64px;
    width: 100px;
    margin-left: auto;
  }
`

export const Select = styled(SelectCmp)`
  margin-top: 32px;
  margin-bottom: 32px;
`

export const ButtonWrapper = styled.div`
  margin-top: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  gap: 8px;
`

export const ModalButtonFooter = styled(ModalButtonFooterCmp)`
  margin-top: 0;
`

export const DeleteAccountWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  :hover {
    cursor: pointer;
  }
`

export const Results = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0;

  > div {
    margin-bottom: 32px;
  }
`

export const Divisor = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray2};
`

export const TooltipText = styled.span`
  visibility: hidden;
  width: 300px;
  background-color: ${({ theme }) => theme.colors.graytheme6};
  color: white;
  max-height: 80px;
  font-size: 14px;
  text-align: center;
  border-radius: 6px;
  padding: 8px 8px;
  position: absolute;
  z-index: 10;
  bottom: -24px;
`

export const Hover = styled.div`
  position: relative;
  :hover {
    cursor: help;
    ${TooltipText} {
      visibility: visible;
    }
  }
`

export const Align = styled.div`
  display: flex;
  align-items: baseline;
`

export const LoadingWrapper = styled.div`
  overflow-y: hidden;
  height: 500px;
`

export const PreviousNextButtonContainer = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: stretch;
  align-content: space-between;
  justify-content: space-between;
  gap: 32px;

  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: white;
  padding: 0 32px 32px 32px;

  ::before {
    content: '';
    right: 0;
    width: 100%;
    position: absolute;
    height: 1px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
  }

  > button {
    margin-top: 32px;
    height: 40px;
    width: 100%;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const SucessWrapper = styled.div`
  height: 500px;
  padding-top: 16px;
  img {
    padding-top: 88px;
  }

  @media ${(props) => props.theme.mediaQueries.lgUp} {
    img {
      width: 90%;
    }
  }
`

export const InfoBox = styled.div<{
  error?: boolean
}>`
  display: flex;
  padding: 12px 16px;
  border-radius: 8px;
  flex-direction: column;
  margin-bottom: 8px;
  border: 1px solid ${({ theme }) => theme.colors.graytheme2};
  width: 100%;
  margin-bottom: 32px;

  ${({ error, theme }) =>
    error
      ? css`
          background-color: ${theme.colors.cancelledStatusBackground};
          border: 0;
        `
      : css``}
`

export const Section = styled.div`
  display: flex;
  height: 270px;
  flex-direction: column;
  gap: 32px;
  padding-top: 32px;

  :first-child {
    padding-top: 0px;
  }

  :last-child {
    padding-bottom: 32px;
  }
`

export const SectionLine = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 40px;
`
