import React from 'react'
import MainStatusCard from '../components/MainStatusCard'
import { formatMoney } from 'helpers/masks'
import { baseURLReconciliation, get } from 'services/api'
import ReconciliationTransferModal from './ReconciliationTransferModal'

interface IProps {
  options: {
    start_date: string
    end_date: string
    wallet_setting_id: string
    consolidated_customer_value: string
  }
}

interface ConsolidatedValue {
  value_difference: string
  sum_orders_value: string
  discrepancy: boolean
}

const ReconciliationTransfered: React.FC<IProps> = ({ options }) => {
  const [transferDetailsModal, setTransferDetailsModal] = React.useState(false)
  const [transferData, setTransferData] = React.useState<ConsolidatedValue>()

  const getConsolidated = async (origin: string) => {
    await get(`${baseURLReconciliation}/consolidated`, {
      params: { ...options, origin: 'Transferencia' },
    }).then((res) => {
      setTransferData(res.data)
    })
  }

  React.useEffect(() => {
    if (options?.wallet_setting_id) {
      getConsolidated('Transferencia')
    }
  }, [options])

  return (
    <>
      <ReconciliationTransferModal
        options={options}
        isOpen={transferDetailsModal}
        handleClose={() => {
          setTransferDetailsModal(false)
        }}
      />
      <MainStatusCard
        label={'Pix Transferência'}
        status={transferData?.discrepancy ? 'warning' : 'success'}
        value={formatMoney(transferData?.sum_orders_value)}
        openModal={() => {
          setTransferDetailsModal(true)
        }}
      />
    </>
  )
}

export default ReconciliationTransfered
