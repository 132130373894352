import React from 'react'
import * as s from './styles'

interface IProps {
  children: React.ReactNode | React.ReactNode[]
}

const NavbarWizard: React.FC<IProps> = ({ children }) => {
  return (
    <s.NavbarContainer data-testid="navbar-container">
      {children}
      <s.LogoExporter />
    </s.NavbarContainer>
  )
}

export default NavbarWizard
