import React from 'react'
import { useDispatch } from 'react-redux'

import { showToast } from 'components/Toast'

import {
  get,
  post,
  remove,
  baseURLStoreWallets,
  patch,
  baseURLStorePos,
} from '../services/api'
import { useTypedSelector } from 'store/modules/rootState'
import {
  GetStoreWallets,
  GetStoreWalletsError,
  GetStoreWalletsIsLoading,
  DeleteStoreWallet,
  DeleteStoreWalletError,
  DeleteStoreWalletLoading,
  PatchStoreWallet,
  PatchStoreWalletError,
  PatchStoreWalletIsLoading,
  PostStoreWallet,
  PostStoreWalletError,
  PostStoreWalletIsLoading,
} from 'store/modules/storeWallets/actions'

import { StoreWalletsState } from 'store/modules/storeWallets/types'

interface IUseStoreWallets extends StoreWalletsState {
  loadStoreWallets: (id: string, params?: any) => Promise<void>
  deleteStoreWallet: (id: string, wallet_id: string) => Promise<void>
  patchStoreWallet: (id: string, wallet_id: string) => Promise<void>
  postStoreWallet: (data: {
    store_id: string
    wallet_name: string
    wallet_setting_id: string
    wallet_setting_name: string
  }) => Promise<void>
}

const UseStoreWallets = (): IUseStoreWallets => {
  const dispatch = useDispatch()

  const {
    storeWallets,
    isLoading,
    hasError,
    deleteIsLoading,
    deleteHasError,
    patchIsLoading,
    patchHasError,
    postIsLoading,
    postHasError,
  } = useTypedSelector((store) => ({
    storeWallets: store.storeWallets.storeWallets,
    isLoading: store.storeWallets.isLoading,
    hasError: store.storeWallets.hasError,
    deleteIsLoading: store.storeWallets.deleteIsLoading,
    deleteHasError: store.storeWallets.deleteHasError,
    patchIsLoading: store.storeWallets.patchIsLoading,
    patchHasError: store.storeWallets.patchHasError,
    postIsLoading: store.storeWallets.postIsLoading,
    postHasError: store.storeWallets.postHasError,
  }))

  const loadStoreWallets = React.useCallback(
    async (id, params): Promise<void> => {
      dispatch(GetStoreWalletsIsLoading())
      await get(`${baseURLStoreWallets}/${id}`, (params)? { params: params || '' } : null)
        .then((response) => {
          dispatch(GetStoreWallets(response.data))
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: `${JSON.parse(e.request.response)}`,
          })
          dispatch(GetStoreWalletsError())
        })
    },
    [dispatch]
  )

  const deleteStoreWallet = React.useCallback(
    async (id, wallet_id): Promise<void> => {
      dispatch(DeleteStoreWalletLoading())
      await remove(`${baseURLStoreWallets}/${id}/${wallet_id}`)
        .then(() => {
          dispatch(DeleteStoreWallet())
          showToast({
            type: 'success',
            message: 'Removido com sucesso',
          })
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: `${JSON.parse(e.request.response).message}`,
          })
          dispatch(DeleteStoreWalletError())
        })
    },
    [dispatch]
  )

  const patchStoreWallet = React.useCallback(
    async (id, wallet_id): Promise<void> => {
      dispatch(PatchStoreWalletIsLoading())
      await patch(`${baseURLStoreWallets}/${id}/${wallet_id}`, {
        is_default: true,
      })
        .then(() => {
          dispatch(PatchStoreWallet())
          showToast({
            type: 'success',
            message: 'Alterado com sucesso',
          })
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: `${JSON.parse(e.request.response).message}`,
          })
          dispatch(PatchStoreWalletError())
        })
    },
    [dispatch]
  )

  const postStoreWallet = React.useCallback(
    async (data): Promise<void> => {
      dispatch(PostStoreWalletIsLoading())
      await post(baseURLStoreWallets, data)
        .then(() => {
          dispatch(PostStoreWallet())
          showToast({
            type: 'success',
            message: 'Carteira associada com sucesso',
          })
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: `${JSON.parse(e.request.response).message}`,
          })
          dispatch(PostStoreWalletError())
        })
    },
    [dispatch]
  )

  return {
    storeWallets,
    isLoading,
    hasError,
    deleteIsLoading,
    deleteHasError,
    patchIsLoading,
    patchHasError,
    postIsLoading,
    postHasError,
    loadStoreWallets,
    deleteStoreWallet,
    patchStoreWallet,
    postStoreWallet,
  }
}

export default UseStoreWallets
