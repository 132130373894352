export const Types = {
  GET_STORE_POS_PER_STORE_ID: 'GET_STORE_POS_PER_STORE_ID',
  GET_STORE_POS_PER_STORE_ID_LOADING: 'GET_STORE_POS_PER_STORE_ID_LOADING',
  GET_STORE_POS_PER_STORE_ID_ERROR: 'GET_STORE_POS_PER_STORE_ID_ERROR',
  GET_STORE_POS: 'GET_STORE_POS',
  GET_STORE_POS_LOADING: 'GET_STORE_POS_LOADING',
  GET_STORE_POS_ERROR: 'GET_STORE_POS_ERROR',
  POST_STORE_POS: 'POST_STORE_POS',
  POST_STORE_POS_LOADING: 'POST_STORE_POS_LOADING',
  POST_STORE_POS_ERROR: 'POST_STORE_POS_ERROR',
}

export interface PerStoreIdInterface {
  active: boolean
  category: string
  id: string
  meta_data: any
  name: string
}

export interface PerStoreIdPayloadInterface {
  count: number
  data: PerStoreIdInterface[]
  offset: number
  total: number
}

export interface StorePosPayloadInterface {
  active: boolean
  client_id: string
  id: string
  meta_data: any
  name: string
  store_id: string
}

export interface ActionInterface {
  type: keyof typeof Types
  perStoreIdPayload: PerStoreIdPayloadInterface
  storePosPayload: StorePosPayloadInterface
}

export interface StorePosState {
  storePosPerStoreId: PerStoreIdPayloadInterface
  storePosPerStoreIdIsLoading: boolean
  storePosPerStoreIdHasError: boolean
  storePos: StorePosPayloadInterface
  storePosIsLoading: boolean
  storePosHasError: boolean
  postStorePosIsLoading: boolean
  postStorePosHasError: boolean
}
