export function getValueOrDefault(value) {
  return value || ''
}

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

export function utf8_to_b64(str) {
  return window.btoa(unescape(encodeURIComponent(str)))
}

export function b64_to_utf8(str) {
  return decodeURIComponent(escape(window.atob(str)))
}

export function isConexaoItau() {
  return window.location.href.includes('conexaoitau')
}

export function isSpacePay() {
  return window.location.href.includes('space')
}

export function isWhiteLabel() {
  return window.location.href.includes('.wl.shipay.')
}

export function isStaging() {
  return window.location.href.includes('staging')
}

export function isQuality() {
  return window.location.href.includes('quality')
}

export function isSandbox() {
  return window.location.href.includes('sandbox')
}

export function isLocalHost() {
  return window.location.href.includes('localhost')
}

export function isTotvs() {
  return isWhiteLabel() && window.location.href.includes('totvs')
}

export const isHTML = (source: string) => {
  if (!source) return false
  return /^(<!DOCTYPE\s+HTML[^>]*>\s*)(<html>[\s\S]*<\/html>\s*)$/i.test(source)
}

export function errorTranslatorHandler(error: string) {
  const getErrorTranslation = (err: string): string => {
    const errorObj = {
      'The CNPJ/CPF is not valid.':
        'Os CPF e/ou CNPJ informados estã incorretos. Por favor, verifica as informações inseridas e confirme o envio novamente.',
      'Length must be between 9 and 9.':
        'Telefone deve conter exatamente 9 dígitos',
      'Not a valid date.': 'Data inválida',
      'Length must be between 5 and 200.':
        'Complemento deve possuir mais do que 5 caracteres',
      'Not a valid integer.': 'Número não deve possuir letras',
      'CNPJ/CPF not valid or not well formed':
        'CPF e/ou CNPJ inválido. Verificar novamente.',
      'Invalid phone number format.':
        'Telefone inválido. Digite um número válido.',
      'Invalid phone number format. It must has at least 8 digits.':
        'Telefone inválido. O Número deve conter 8 dígitos ou mais.',
      'Invalid phone type format.':
        'Tipo de telefone inválido. As opções são: móvel ou fixo.',
      'Invalid ddd format.': 'DDD inválido ou inexistente.',
      'Invalid partner name format.':
        'Nome inválido. Tamanho mínimo deve conter 2 ou mais letras.',
      'Invalid company name format.':
        'Razão Social inválido. Tamanho mínimo deve conter 2 ou mais letras.',
      'Invalid fantasy name format.':
        'Nome fantasia inválido. Tamanho mínimo deve conter 2 ou mais letras.',
      'Invalid zip code format.':
        'CEP inválido. Tamanho deve conter exatamente 8 dígitos.',
      'Invalid street address format.':
        'Endereço inválido. Tamanho mínimo deve conter 1 ou mais letras.',
      'Invalid UF format.': 'UF inválido ou inexistente.',
      'Invalid neighborhood format.':
        'Bairro inválido. Tamanho mínimo deve conter 1 ou mais letras.',
      'Invalid city format.':
        'Cidade inválida. Tamanho mínimo deve conter 1 ou mais letras.',
      'Income invoice value is not a valid decimal.':
        'Erro no campo faturamento mensal. Preencha com um valor numérico.',
      'CNPJ/CPF not valid or not well formed.':
        'CPF e/ou CNPJ inválido. Verificar novamente.',
      'The pix key seems to be invalid.':
        'Erro na chave pix. Verifique se a chave está completa.',
    }

    return errorObj[err] ?? err
  }

  return getErrorTranslation(error)
}

interface IErrorObj {
  field?: string
  message?: string
  msg?: string
  type?: string
  loc?: Array<string>
}

export function errorObjectHandler(error: string) {
  const jsonError = JSON.parse(error)

  const jsonErrorArray = Array.isArray(jsonError?.detail)
    ? jsonError
    : {
        detail: [
          {
            loc: ['body', `${jsonError?.detail}`],
            msg: jsonError?.detail,
            type: jsonError?.detail,
          },
        ],
      }

  const errorMessages: Array<string> = jsonErrorArray.detail.map(
    (err: IErrorObj | any) => {
      if ('loc' in err) {
        let errorMessage = ''

        switch (err.loc[1]) {
          case 'income_invoicing':
            errorMessage = 'Income invoice value is not a valid decimal.'
            break
          case 'partner_document_number':
            errorMessage = 'CNPJ/CPF not valid or not well formed.'
            break
          case 'pix_dict_key':
            errorMessage = 'The pix key seems to be invalid.'
            break
          case 'cpf_cnpj':
            errorMessage = 'CNPJ/CPF not valid or not well formed.'
            break

          default:
            errorMessage = `${err.loc[1]}`
        }
        return errorTranslatorHandler(errorMessage)
      }

      if ('field' in err) {
        let errorMessage = ''

        switch (err.field) {
          case 'phone.number':
            errorMessage = 'Invalid phone number format.'
            break
          case 'customer.phones.number':
            errorMessage =
              'Invalid phone number format. It must has at least 8 digits.'
            break
          case 'customer.phones.type':
            errorMessage = 'Invalid phone type format.'
            break
          case 'customer.phones.ddd':
            errorMessage = 'Invalid ddd format.'
            break
          case 'customer.partners.name':
            errorMessage = 'Invalid partner name format.'
            break
          case 'customer.partners':
            errorMessage = 'Invalid partner name format.'
            break
          case 'customer.name':
            errorMessage = 'Invalid company name format.'
            break
          case 'customer.fantasy_name':
            errorMessage = 'Invalid fantasy name format.'
            break
          case 'customer.addresses.zip_code':
            errorMessage = 'Invalid zip code format.'
            break
          case 'customer.addresses.street_address':
            errorMessage = 'Invalid street address format.'
            break
          case 'customer.addresses.state':
            errorMessage = 'Invalid UF format.'
            break
          case 'customer.addresses.neighborhood':
            errorMessage = 'Invalid neighborhood format.'
            break
          case 'customer.addresses.city':
            errorMessage = 'Invalid city format.'
            break

          default:
            errorMessage = `${err.field}.`
        }

        return errorTranslatorHandler(errorMessage)
      }
    }
  )

  return errorMessages
}

export const removeAccents = (text: string) => {
  const accentsMapWithOutCedilla = {
    A: 'Á|À|Ã|Â',
    a: 'á|à|ã|â',
    E: 'É|È|Ê',
    e: 'é|è|ê',
    I: 'Í|Ì|Î',
    i: 'í|ì|î',
    O: 'Ó|Ò|Ô|Õ',
    o: 'ó|ò|ô|õ',
    U: 'Ú|Ù|Û',
    u: 'ú|ù|û',
    N: 'Ñ',
    n: 'ñ',
  }

  if (text) {
    return Object.keys(accentsMapWithOutCedilla).reduce(
      (acc, cur) =>
        acc.replace(new RegExp(accentsMapWithOutCedilla[cur], 'g'), cur),
      text
    )
  }
  return ''
}

export const removeAccentsAndDiacritics = (text: string) => {
  if (text) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }
  return ''
}

export const removeNumbers = (text: string) => {
  if (text) {
    return text.replace(/[0-9]/g, '')
  }
  return ''
}

export const safeParseJSON = (jsonString: string) => {
  try {
    return JSON.parse(jsonString)
  } catch (e) {
    console.error('Failed to parse JSON:', e)
  }
}
