export const Types = {
  POST_PIX_WALLET_SETTINGS_REQUEST: 'POST_WALLET_SETTINGS_REQUEST',
  POST_PIX_WALLET_SETTINGS_RESPONSE: 'POST_WALLET_SETTINGS_RESPONSE',
  POST_PIX_WALLET_SETTINGS_ERROR: 'POST_WALLET_SETTINGS_ERROR',
}

export interface ResponseInterface {
  code: number
  message: string
}

//WalletSettins = WS
export interface PixWSInterface {
  apelido: string
  chave_pix: string
  client_id?: string
  client_secret?: string
}

export interface PixActionInterface {
  type: keyof typeof Types
  payload: PixWSInterface
  response: ResponseInterface
  message: string
}

export interface PixWSState {
  walletSettings: PixWSInterface
  postResponse: ResponseInterface
  isLoading: boolean
  hasError: boolean
  postIsLoading: boolean
  postHasError: boolean
  postHasErrorMessage: string
}
