import React from 'react'
import { v4 as uuid } from 'uuid'

import { useTranslation } from 'react-i18next'

import useGetSummary from 'hooks/useGetSummary'
import useDebounce from 'hooks/useDebounce'
import useGetStores from 'hooks/useGetStores'
import useGetSystemWallets from 'hooks/useGetSystemWallets'
import useWalletsSettings from 'hooks/useWalletsSettings'

import Toast, { showToast } from 'components/Toast'

import {
  getToday,
  getThisWeek,
  getThisMonth,
  getLast6Months,
  getThisYear,
  getLast7Days,
} from 'helpers/date'

import { utf8_to_b64 } from 'helpers'
import SummaryModal from './SummaryDetailModal'
import { ISortedColumnState } from 'components/Table'
import { SystemWalletsInterface } from 'store/modules/systemWallets/types'
import { FilesModalButton } from 'blocks/FilesModal'
import { useTheme } from 'styled-components'
import Table from './Table'
import { useNonInitialEffect } from 'hooks/utils'

import * as s from '../styles'

interface IFilteredWallet extends SystemWalletsInterface {
  check?: boolean
}

const initialFilterList = [
  [
    { name: 'Hoje', status: false },
    { name: 'Últimos 7 dias', status: true },
    { name: 'Últimos 30 dias', status: false },
    { name: 'Últimos 6 meses', status: false },
    { name: 'Este ano', status: false },
  ],
  [
    { name: 'Até 10 reais', status: false },
    { name: 'Entre 10 e 50', status: false },
    { name: 'Entre 50 e 200', status: false },
    { name: 'Entre 200 e 1000', status: false },
    { name: 'Acima de 1000', status: false },
  ],
  [],
  [],
  [],
  [
    { name: 'pending', status: false },
    { name: 'expired', status: false },
    { name: 'cancelled', status: false },
    { name: 'refunded', status: false },
    { name: 'partial_refunded', status: false },
    { name: 'refund_pending', status: false },
    { name: 'approved', status: false },
  ],
]

const SummaryCashin: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { summary, loadSummary, loadSummaryDetail, isLoading, hasError } =
    useGetSummary()
  const {
    stores,
    hasError: HasErrorStores,
    isLoading: isLoadingStores,
    loadStores,
  } = useGetStores()
  const {
    systemWallets,
    isLoading: isLoadingSystemWallets,
    hasError: hasErrorSystemWallets,
    loadSystemWallets,
  } = useGetSystemWallets()

  const {
    walletSettings,
    isLoading: isLoadingWalletSettings,
    hasError: hasErrorWalletSettings,
    loadWalletsSettings,
  } = useWalletsSettings()

  const [filterHeaders] = React.useState<string[]>([
    'Data do Pedido',
    'Valor',
    'Loja',
    '',
    'Conta',
    'Status',
  ])
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
  const inputRef = React.useRef<React.RefObject<HTMLInputElement>[]>([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ])
  const [decodedFilters, setDecodedFilters] = React.useState<any>({})
  const [encondedFilters, setEncodedFilters] = React.useState<string>('')
  const indexFilter = React.useRef<number>(-1)
  const [isFiltersLoading, setIsFiltersLoading] = React.useState<boolean>(false)
  const [isAccordionOpen, setIsAccordionOpen] = React.useState<boolean>(false)
  const [minimumAmount, setMinimumAmount] = React.useState<string>('')
  const [maximumAmount, setMaximumAmount] = React.useState<string>('')
  const [initialDate, setInitialDate] = React.useState<string>(getLast7Days())
  const [finalDate, setFinalDate] = React.useState<string>(getToday())
  const debouncedMinimumAmountChange = useDebounce(setMinimumAmount, 1000)
  const debouncedMaximumAmountChange = useDebounce(setMaximumAmount, 1000)
  const debouncedInitialDateChange = useDebounce(setInitialDate, 1000)
  const debouncedFinalDateChange = useDebounce(setFinalDate, 1000)
  const [detailsId, setDetailsId] = React.useState<string>('')
  const [detailsExpirationDate, setDetailsExpirationDate] =
    React.useState<string>('')
  const [searchText, setSearchText] = React.useState<string>('')
  const [searchTextType, setSearchTextType] = React.useState<string>('order_uuid')
  const [displaySearchText, setDisplaySearchText] =
    React.useState<string>(searchText)
  const debouncedSearchTextChange = useDebounce(setSearchText, 1000)
  const [selected, setSelected] = React.useState({ id: 1, name: 'Codigo UUID' })
  const [selectedOrder, setSelectedOrder] = React.useState({
    id: 1,
    name: 'Mais Recente',
  })
  const [filterList, setFilterList] = React.useState(initialFilterList)
  const [offset, setOffset] = React.useState<string>('0')

  const [sortedColumn, setSortedColumn] = React.useState<ISortedColumnState>({
    sortedColumnName: 'created_at',
    sortedColumnOrder: 'desc',
  })
  const [showHelper, setShowHelper] = React.useState<boolean>(true)
  const abortControllerRef = React.useRef<AbortController>(
    new AbortController()
  )

  const onFilterInputChange = (value, index) => {
    indexFilter.current = index
    switch (index) {
      case 0:
        debouncedInitialDateChange(value)
        break
      case 1:
        debouncedFinalDateChange(value)
        break
      case 2:
        debouncedMinimumAmountChange(value)
        break
      case 3:
        debouncedMaximumAmountChange(value)
        break
    }
  }

  const handleSearchText = (e) => {
    setDisplaySearchText(e.target.value)
    debouncedSearchTextChange(e.target.value)
  }

  React.useEffect(() => {
    if (hasError) {
      showToast({
        type: 'error',
        message: 'Erro na API, tente novamente mais tarde',
      })
    }
  }, [hasError])

  React.useEffect(() => {
    loadSystemWallets()
    loadWalletsSettings({ limit: '500', offset: '0' })
    loadStores({
      limit: '100',
      offset: '0',
      active: true,
    })
  }, [])

  React.useEffect(() => {
    if (walletSettings.total > 0 && systemWallets.length && stores.total > 0) {
      setIsFiltersLoading(true)
      const wallets: IFilteredWallet[] = systemWallets
      wallets.forEach((wallet) => {
        wallet.check =
          walletSettings.data.filter(
            (walletSetting) => walletSetting.wallet_name === wallet.wallet_name
          ).length > 0
            ? true
            : false
      })
      const walletsChecked = wallets.filter(
        (wallet) => wallet.check === true && wallet.wallet_type !== 'pix'
      )
      const optionsWallets = walletsChecked.map((wallet) => {
        return {
          name: `${wallet.wallet_name}`,
          status: true,
        }
      })
      const storesFilters = stores.data.map((store) => {
        return { name: store.name, status: true }
      })
      setFilterList([
        [
          { name: 'Hoje', status: false },
          { name: 'Últimos 7 dias', status: true },
          { name: 'Últimos 30 dias', status: false },
          { name: 'Últimos 6 meses', status: false },
          { name: 'Este ano', status: false },
        ],
        [
          { name: 'Até 10 reais', status: false },
          { name: 'Entre 10 e 50', status: false },
          { name: 'Entre 50 e 200', status: false },
          { name: 'Entre 200 e 1000', status: false },
          { name: 'Acima de 1000', status: false },
        ],
        storesFilters,
        [],
        optionsWallets,
        [
          { name: 'pending', status: false },
          { name: 'expired', status: false },
          { name: 'cancelled', status: false },
          { name: 'refunded', status: false },
          { name: 'partial_refunded', status: false },
          { name: 'refund_pending', status: false },
          { name: 'approved', status: false },
        ],
      ])
    }
    setIsFiltersLoading(false)
  }, [systemWallets, walletSettings, stores])

  const filtersHandler = function (
    initialDateAux = null,
    finalDateAux = null,
    minimumAmountAux = null,
    maximumAmountAux = null
  ) {
    if (!isLoading) {
      applyFilters(
        initialDateAux ?? initialDate,
        finalDateAux ?? finalDate,
        minimumAmountAux ?? minimumAmount,
        maximumAmountAux ?? maximumAmount
      )
    } else {
      showToast({
        type: 'success',
        message: 'Aguarde o filtro anterior',
      })
    }
  }

  const filtersHandlerTable = function (
    initialDateAux = null,
    finalDateAux = null,
    minimumAmountAux = null,
    maximumAmountAux = null
  ) {
    setShowHelper(false)
    filtersHandler(
      initialDateAux,
      finalDateAux,
      minimumAmountAux,
      maximumAmountAux
    )
  }

  const applyFilters = (
    initialDate = null,
    finalDate = null,
    minimumAmount = null,
    maximumAmount = null
  ) => {
    if (sortedColumn) {
      const params = new URLSearchParams()
      params.append('limit', '20')
      params.append('offset', offset)
      params.append('sort', sortedColumn.sortedColumnName)
      params.append('orderby', sortedColumn.sortedColumnOrder)
      const filtersToBase64 = {} as any
      const walletDisplayNameFilters = []
      const statusFilters = []
      const storeNameFilters = []
      const storePosNameFilters = []
      filterList.forEach((filters, index) => {
        if (index === 4 || index === 5 || index === 2) {
          const hasFilterChanged = filters.some(
            (filter) => filter.status === false
          )
          if (hasFilterChanged) {
            filters.forEach((filter, i) => {
              if (filter.status) {
                switch (index) {
                  case 4:
                    walletDisplayNameFilters.push(filter.name)
                    break
                  case 5:
                    //We have 2 types of pending status, pending and pendingv, to the user, is the same name.
                    //So we must filter using this condition to add both
                    if (filter.name === 'pending') {
                      statusFilters.push(filter.name)
                      statusFilters.push('pendingv')
                    } else {
                      statusFilters.push(filter.name)
                    }
                    break
                  case 2:
                    storeNameFilters.push(filter.name)
                    break
                }
              }
            })
          }
        }
      })
      filtersToBase64.stores = storeNameFilters
      filtersToBase64.wallet_name = walletDisplayNameFilters
      filtersToBase64.store_pos = storePosNameFilters
      filtersToBase64.status = statusFilters
      if (searchText) {
        if(searchTextType == 'order_uuid') {
          filtersToBase64.order_id = searchText || ''
        } else if(searchTextType == 'wallet_payment_id') {
          filtersToBase64.wallet_payment_id = searchText || ''
        }
      }

      if (initialDate) {
        filtersToBase64.start_date = initialDate
          .split('/')
          .reverse()
          .join('-')
          .concat(' 00:00:00')
      }
      if (finalDate) {
        filtersToBase64.end_date = finalDate
          .split('/')
          .reverse()
          .join('-')
          .concat(' 23:59:59')
      }
      if (minimumAmount) {
        filtersToBase64.min_value = minimumAmount.replace(/,/i, '.')
      }
      if (maximumAmount) {
        filtersToBase64.max_value = maximumAmount.replace(/,/i, '.')
      }
      const encondedFilters = utf8_to_b64(JSON.stringify(filtersToBase64))
      setDecodedFilters(filtersToBase64)
      setEncodedFilters(encondedFilters)
      params.append('filters', encondedFilters)
      loadSummary({
        params,
      })
    }
  }

  React.useEffect(() => {
    filtersHandler()
  }, [sortedColumn, searchText, offset])

  useNonInitialEffect(() => {
    if (detailsId && isModalOpen) {
      abortControllerRef.current = new AbortController()
      loadSummaryDetail(
        detailsId,
        detailsExpirationDate,
        abortControllerRef.current.signal
      )
    } else {
      abortControllerRef.current.abort()
    }
  }, [detailsId, isModalOpen, loadSummaryDetail])

  interface IChildrenRender {
    children: string | number
    index?: number
  }

  const walletRender = ({ children, index }: IChildrenRender) => {
    const walletNames = summary.data.map((item) => item.wallet_name)
    const walletName = walletNames[index]
    const walletIcon = summary?.data[index].wallet_icon
    return (
      <s.WalletWrapper>
        <s.Image src={walletIcon} width={36} height={36} />
        <s.Text
          margin="0px 0px 0px 8px"
          bold
          color={walletName as keyof typeof theme.colors}
        >
          {children}
        </s.Text>
      </s.WalletWrapper>
    )
  }

  const valueRender = ({ children }: IChildrenRender) => {
    const valueMaskedToBrl = children
      .toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
      .split(' ')
    return (
      <s.Text color="graytheme6">
        {valueMaskedToBrl[0]}
        <b> {valueMaskedToBrl[1]}</b>
      </s.Text>
    )
  }

  const filterTypeRender = (index, i, name, status, filterList) => {
    if (index === 0 || index === 1) {
      return (
        <s.RadioButton
          id={`${index}-${i}`}
          name={`${name}-summary`}
          key={uuid()}
          value={status}
          checked={status}
          //Using onClick to mark off checked radiobutton, due to design proposal.
          //HTML don't allow this using onChange event.
          onClick={() => {
            const filterAux = filterList
            filterAux[index].forEach((filter) => (filter.status = false))
            filterAux[index][i].status = !status
            if (index === 1 && !status) {
              switch (i) {
                case 0:
                  setMinimumAmount('0')
                  setMaximumAmount('10')
                  break
                case 1:
                  setMinimumAmount('10')
                  setMaximumAmount('50')
                  break
                case 2:
                  setMinimumAmount('50')
                  setMaximumAmount('200')
                  break
                case 3:
                  setMinimumAmount('200')
                  setMaximumAmount('1000')
                  break
                case 4:
                  setMinimumAmount('1000')
                  setMaximumAmount('')
                  break
              }
            } else if (index === 1 && status) {
              setMinimumAmount('')
              setMaximumAmount('')
            }
            if (index === 0 && !status) {
              switch (i) {
                case 0:
                  setInitialDate(getToday())
                  setFinalDate(getToday())
                  break
                case 1:
                  setInitialDate(getLast7Days())
                  setFinalDate(getToday())
                  break
                case 2:
                  setInitialDate(getThisMonth())
                  setFinalDate(getToday())
                  break
                case 3:
                  setInitialDate(getLast6Months())
                  setFinalDate(getToday())
                  break
                case 4:
                  setInitialDate(getThisYear())
                  setFinalDate(getToday())
                  break
              }
            } else if (index === 0 && status) {
              setInitialDate('')
              setFinalDate('')
            }
            setFilterList([...filterAux])
          }}
          label={name}
          handleChange={() => {}}
        />
      )
    } else {
      return (
        <s.Checkbox
          id={uuid()}
          key={uuid()}
          checked={status}
          onChange={() => {
            const filterAux = filterList
            filterAux[index].splice(i, 1, {
              name: name,
              status: !status,
            })
            setFilterList([...filterAux])
          }}
          label={`${t(name)}`}
        />
      )
    }
  }

  const FilterAccordion = ({
    isFilterAccordionOpen,
    filterList,
    initialDate,
    setInitialDate,
    finalDate,
    minimumAmount,
    maximumAmount,
  }) => {
    const [displayMinimumAmount, setDisplayMinimumAmount] =
      React.useState<string>(minimumAmount)
    const [displayMaximumAmount, setDisplayMaximumAmount] =
      React.useState<string>(maximumAmount)
    const [displayInitialDate, setDisplayInitialDate] =
      React.useState<string>(initialDate)
    const [displayFinalDate, setDisplayFinalDate] =
      React.useState<string>(finalDate)

    return (
      <>
        <s.FilterAccordionContainer
          isFilterAccordionOpen={isFilterAccordionOpen}
        >
          {filterList.map((filter, index) => {
            return (
              <s.FilterWrapper key={`filters-${index}`}>
                {theme.domain === 'SpacePay' ? (
                  <s.FilterHeader>{filterHeaders[index]}</s.FilterHeader>
                ) : (
                  <></>
                )}
                {filter.map(({ name, status }, i) => {
                  if (i === 8) {
                    return (
                      <s.Text key={uuid()} color="maincolor">{`+ ${
                        filter.length - i
                      } itens...`}</s.Text>
                    )
                  }
                  if (i > 8) {
                    return null
                  }
                  return (
                    <div key={`filter-${i}`}>
                      <>
                        {filterTypeRender(index, i, name, status, filterList)}
                        {i === filter.length - 1 ? (
                          <>
                            {index === 1 ? (
                              <>
                                <s.ValueInput
                                  ref={inputRef.current[2]}
                                  placeholder="Mínimo"
                                  value={displayMinimumAmount}
                                  onChange={(e) => {
                                    const filterAux = filterList
                                    filterAux[1].forEach(
                                      (filter) => (filter.status = false)
                                    )
                                    setFilterList(filterAux)
                                    setDisplayMinimumAmount(e.target.value)
                                    onFilterInputChange(e.target.value, 2)
                                  }}
                                />
                                <s.ValueInput
                                  ref={inputRef.current[3]}
                                  placeholder="Máximo"
                                  value={displayMaximumAmount}
                                  onChange={(e) => {
                                    const filterAux = filterList
                                    filterAux[1].forEach(
                                      (filter) => (filter.status = false)
                                    )
                                    setFilterList(filterAux)
                                    setDisplayMaximumAmount(e.target.value)
                                    onFilterInputChange(e.target.value, 3)
                                  }}
                                />
                              </>
                            ) : null}
                            {index === 0 ? (
                              <>
                                <s.Datepicker
                                  selected={displayInitialDate}
                                  placeholder="Início"
                                  onChange={(date: Date) => {
                                    if (date) {
                                      setDisplayInitialDate(
                                        date.toLocaleDateString('pt-BR')
                                      )
                                      setInitialDate(
                                        date.toLocaleDateString('pt-BR')
                                      )
                                      const filterAux = filterList
                                      filterAux[0].forEach(
                                        (filter) => (filter.status = false)
                                      )
                                      setFilterList(filterAux)
                                    }
                                  }}
                                  type="square"
                                ></s.Datepicker>
                                <s.Datepicker
                                  selected={displayFinalDate}
                                  onChange={(date: Date) => {
                                    if (date) {
                                      setDisplayFinalDate(
                                        date.toLocaleDateString('pt-BR')
                                      )
                                      setFinalDate(
                                        date.toLocaleDateString('pt-BR')
                                      )
                                      const filterAux = filterList
                                      filterAux[0].forEach(
                                        (filter) => (filter.status = false)
                                      )
                                      setFilterList(filterAux)
                                    }
                                  }}
                                  placeholder="Final"
                                  type="square"
                                />
                                <s.ClearDatepicker
                                  onClick={() => {
                                    setInitialDate('')
                                    setFinalDate('')
                                  }}
                                >
                                  <s.IconClearDate
                                    width="15px"
                                    name="trash"
                                    fill="graytheme5"
                                  />
                                </s.ClearDatepicker>
                              </>
                            ) : null}
                          </>
                        ) : null}
                      </>
                    </div>
                  )
                })}
              </s.FilterWrapper>
            )
          })}
          <s.ButtonsWrapper>
            <s.FilterApplyButton
              disabled={isLoading}
              onClick={() => {
                setShowHelper(false)
                filtersHandler()
              }}
            >
              <s.Icon fill="white" name="search" />
              <s.Text color="white" type="headline">
                Aplicar filtros
              </s.Text>
            </s.FilterApplyButton>

            <s.FilterButton
              onClick={() => {
                const filtersListReseted = filterList
                filtersListReseted.forEach((filters, index) => {
                  if (index === 2 || index === 4) {
                    filters.forEach((filter) => {
                      filter.status = true
                    })
                  } else if (index === 0 || index === 1 || index === 5) {
                    filters.forEach((filter) => {
                      filter.status = false
                    })
                  }
                })
                setMinimumAmount('')
                setMaximumAmount('')
                setInitialDate('')
                setFinalDate('')
                setFilterList([...filtersListReseted])
              }}
            >
              <s.Icon name="trash" fill="graytheme5" />
              <s.Text type="headline">Limpar filtros</s.Text>
            </s.FilterButton>
          </s.ButtonsWrapper>
        </s.FilterAccordionContainer>
      </>
    )
  }

  return (
    <s.Wrapper data-testid="cashin-payments">
      <Toast />
      {isModalOpen ? (
        <SummaryModal
          isOpen={isModalOpen}
          handleClose={() => setIsModalOpen(!isModalOpen)}
          detailsId={detailsId}
          onRealoadOrder={() => {
            loadSummaryDetail(
              detailsId,
              detailsExpirationDate,
              abortControllerRef.current.signal
            )
          }}
        />
      ) : (
        <></>
      )}
      <s.OrdersHeader>
        {showHelper ? (
          <s.Hover>
            <s.Text type="headline" bold color="maincolor">
              Pedidos dos últimos 7 dias
              <s.Icon name="questionmarkcircle" fill="maincolor" />
            </s.Text>
            <s.TooltipText>
              Utilize o botão Filtrar para visualizar pedidos feitos em outro
              período
            </s.TooltipText>
          </s.Hover>
        ) : (
          <></>
        )}
      </s.OrdersHeader>
      <s.SearchWrapper>
        <s.SearchTextWrapper>
          <s.Text bold color="graytheme7">
            Busque por:
          </s.Text>
          <s.InputsWrapper>
            <s.Select
              options={[
                {
                  id: 1,
                  text: 'Codigo UUID',
                  onClick: () => {
                    setSearchTextType('order_uuid')
                    setSelected({id: 1, name: 'Codigo UUID'})
                    setDisplaySearchText('')
                    debouncedSearchTextChange('')
                  },
                },
                {
                  id: 2,
                  text: 'Codigo do pagamento',
                  onClick: () => {
                    setSearchTextType('wallet_payment_id')
                    setSelected({id: 2, name: 'Codigo do pagamento'})
                    setDisplaySearchText('')
                    debouncedSearchTextChange('')
                  },
                }
              ]}
              selected={selected}
            />
            <s.InputText
              width={300}
              suffix={<s.Icon fill="maincolor" name="search"/>}
              value={displaySearchText}
              onChange={(e) => {
                handleSearchText(e)
              }}
            />
          </s.InputsWrapper>
        </s.SearchTextWrapper>
        <s.SearchSelectWrapper>
          <s.Text bold color="graytheme7">
            Ordenar por
          </s.Text>
          <s.Select
            options={[
              {
                id: 1,
                text: 'Mais Recente',
                onClick: () => {
                  setSortedColumn({
                    sortedColumnName: 'created_at',
                    sortedColumnOrder: 'desc',
                  })
                  setSelectedOrder({id: 1, name: 'Mais Recente'})
                },
              },
              {
                id: 2,
                text: 'Mais Antiga',
                onClick: () => {
                  setSortedColumn({
                    sortedColumnName: 'created_at',
                    sortedColumnOrder: 'asc',
                  })
                  setSelectedOrder({id: 2, name: 'Mais Antiga'})
                },
              },
              {
                id: 3,
                text: 'Menor Valor',
                onClick: () => {
                  setSortedColumn({
                    sortedColumnName: 'total_order',
                    sortedColumnOrder: 'asc',
                  })
                  setSelectedOrder({id: 3, name: 'Menor Valor'})
                },
                },
                {
                  id: 4,
                  text: 'Maior Valor',
                  onClick: () => {
                    setSortedColumn({
                      sortedColumnName: 'total_order',
                      sortedColumnOrder: 'desc',
                    })
                    setSelectedOrder({id: 4, name: 'Maior Valor' })
                },
              },
            ]}
            selected={selectedOrder}
          />
        </s.SearchSelectWrapper>
        <s.ButtonsWrapperHeader>
          <FilesModalButton
            color="whitedetailed"
            iconName="paper"
            iconColor="white"
            iconFill="maincolor"
            textType="headline"
            textColor="graytheme6"
            fileType="sales_file"
            filters={decodedFilters}
            orderBy={sortedColumn.sortedColumnOrder}
            sort={sortedColumn.sortedColumnName}
            ordersCount={summary.count}
            initialDate={initialDate}
            finalDate={finalDate}
            rowsCount={4}
            format={'xlsx'}
          >
            Arquivos de venda
          </FilesModalButton>
        </s.ButtonsWrapperHeader>
      </s.SearchWrapper>
      <FilterAccordion
        key={uuid()}
        filterList={filterList}
        isFilterAccordionOpen={isAccordionOpen}
        minimumAmount={minimumAmount}
        initialDate={initialDate}
        setInitialDate={setInitialDate}
        finalDate={finalDate}
        maximumAmount={maximumAmount}
      />
      <Table
        key={uuid()}
        isLoading={isLoading}
        isLoadingStores={isLoadingStores}
        isLoadingSystemWallets={isLoadingSystemWallets}
        isLoadingWalletSettings={isLoadingWalletSettings}
        isFiltersLoading={isFiltersLoading}
        summary={summary}
        sortedColumn={sortedColumn}
        setSortedColumn={setSortedColumn}
        hasError={hasError}
        isAccordionOpen={isAccordionOpen}
        setIsAccordionOpen={setIsAccordionOpen}
        detailsId={detailsId}
        setDetailsId={setDetailsId}
        detailsExpirationDate={detailsExpirationDate}
        setDetailsExpirationDate={setDetailsExpirationDate}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        filterList={filterList}
        filtersHandler={filtersHandlerTable}
        walletRender={walletRender}
        minimumAmount={minimumAmount}
        maximumAmount={maximumAmount}
        setMinimumAmount={setMinimumAmount}
        setMaximumAmount={setMaximumAmount}
        valueRender={valueRender}
        initialDate={initialDate}
        finalDate={finalDate}
        setInitialDate={setInitialDate}
        setFinalDate={setFinalDate}
      />
      {!hasError ? (
        <s.CursorPagination
          count={20}
          offset="0"
          dataCount={summary.count}
          handleChange={(offset) => {
            setOffset(offset)
          }}
        />
      ) : null}
    </s.Wrapper>
  )
}

export default React.memo(SummaryCashin)
