import { Types } from './types'

export function OpenConnection(payload: {
  channel: string
  eventSource: any
}): {
  type: string
  payload: { channel: string; eventSource: any }
} {
  return {
    type: Types.OPEN_CONNECTION,
    payload,
  }
}

export function CloseConnection(
  payload: string
): {
  type: string
  payload: string
} {
  return {
    type: Types.CLOSE_CONNECTION,
    payload,
  }
}
