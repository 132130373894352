import { useState, useEffect } from 'react'

import * as s from './styles'

import { useNonInitialEffect } from 'hooks/utils'
import { usePostCompleteRegistration } from 'hooks/registration'

import ShipayAccountForm from './ShipayAccountForm'
import UserForm from './UserForm'
import StoreForm from './StoreForm'
import { showToast } from 'components/Toast'

interface IProps {
  onSuccess: () => void
  onClose: boolean
}

const CompleteRegistration: React.FC<IProps> = ({ onSuccess, onClose }) => {
  const [action, setAction] = useState(false)
  const [clearForm, setClearForm] = useState(false)
  const [_userForm, _setUserForm] = useState<any>()
  const [_shipayAccountForm, _setShipayAccountForm] = useState<any>()
  const [_storeForm, _setStoreForm] = useState<any>()
  const {
    response,
    isLoading,
    error,
    createUser,
  } = usePostCompleteRegistration()

  useNonInitialEffect(() => {
    setClearForm(!clearForm)
  }, [onClose])

  useNonInitialEffect(() => {
    if (error) {
      showToast({
        type: 'error',
        message: 'Problema ao criar cadastro completo',
      })
    }
  }, [error])

  useNonInitialEffect(() => {
    if (response.code !== -1) {
      showToast({
        type: 'success',
        message: 'Usuário criado com sucesso',
      })
      onSuccess()
      setClearForm(!clearForm)
    }
  }, [response])

  useEffect(() => {
    if (_userForm && _shipayAccountForm && _storeForm) {
      const { store_pos_names, ...restStoreForm } = _storeForm

      const payload = {
        ...Object.entries(_shipayAccountForm).reduce((acc, cur: any) => {
          const [key, value] = cur
          return {
            ...acc,
            [key]: value.value,
          }
        }, {}),
        ...Object.entries(_userForm).reduce((acc, cur: any) => {
          const [key, value] = cur
          return {
            ...acc,
            [key]: value.value,
          }
        }, {}),
        ...Object.entries(restStoreForm).reduce((acc, cur: any) => {
          const [key, value] = cur
          return {
            ...acc,
            [key]: value.value,
          }
        }, {}),
        terms_accepted: true,
        store_pos_names: [...Array(parseInt(store_pos_names.value))].map(
          (_, index) => {
            return `Caixa ${index + 1}`
          }
        ),
      } as any

      createUser(payload)
    }
  }, [_userForm, _shipayAccountForm, _storeForm])

  const onUserSubmit = (userForm) => {
    _setUserForm(userForm)
  }

  const onShipayAccountSubmit = (shipayAccountForm) => {
    _setShipayAccountForm(shipayAccountForm)
  }

  const onStoreSubmit = (storeForm) => {
    _setStoreForm(storeForm)
  }

  const submitForm = () => {
    setAction(!action)
  }

  return (
    <s.FormContainer>
      <ShipayAccountForm
        onSubmit={onShipayAccountSubmit}
        submit={action}
        clearForm={clearForm}
      />
      <UserForm onSubmit={onUserSubmit} submit={action} clearForm={clearForm} />
      <StoreForm
        onSubmit={onStoreSubmit}
        submit={action}
        clearForm={clearForm}
      />
      <s.Footer>
        {isLoading ? (
          <s.Loading
            type="spinner"
            data-testid="loading-spinner2"
            color="maincolor"
          />
        ) : null}
        <s.Button onClick={() => submitForm()}>
          <s.Icon name="personify" color="white"></s.Icon>
          <s.Text bold color="white">
            Cadastrar Cliente
          </s.Text>
        </s.Button>
      </s.Footer>
    </s.FormContainer>
  )
}

export default CompleteRegistration
