import React from 'react'

import originalBank from 'assets/images/original-bank.svg'
import tbanksBank from 'assets/images/tbanks-bank.svg'
import redeBank from 'assets/pix/rede-card-icon.svg'
import conexaoItauBank from 'assets/images/conexao-itau-bank.svg'

import { useTheme } from 'styled-components'
import * as s from '../styles'
import { isWhiteLabel } from 'helpers'

interface IProps {
  step?: number
  isOpen: string
  handleClose: () => void
  isFrom: string
  children: React.ReactNode | React.ReactNode[]
}

const PixSimplesModalContainer: React.FC<IProps> = ({
  step,
  isOpen,
  handleClose,
  isFrom,
  children,
}) => {
  const theme = useTheme()

  const headerImage = () => {
    switch (isFrom) {
      case 'original':
        return originalBank
      case 'conexaoItau':
        return conexaoItauBank
      case 'tbanks':
        return tbanksBank
    }
  }

  const renderModalByPsp = () => {
    if (isFrom === 'conexaoItau') {
      return (
        <s.Modal
          isFromConexaoItau
          isHeaderComponent={step > 0 ? true : false}
          modalSubtitle={step > 0 ? 'Adicionar meio de pagamento' : null}
          noChildrenPadding={step > 0 ? false : true}
          isOpen={!!isOpen}
          handleClose={() => handleClose()}
        >
          {step > 0 ? (
            <s.ItauModalContent>{children}</s.ItauModalContent>
          ) : (
            <>
              {theme.domain === 'Shipay' || isWhiteLabel() ? (
                <s.PixSimplesModalContainer provider={isFrom} step={step}>
                  <s.PixAnyBankHeader provider={isFrom}>
                    <img src={headerImage()} />
                    <s.Text color="white" bold type="headline">
                      Receba Pix em Qualquer Banco
                    </s.Text>
                  </s.PixAnyBankHeader>
                  <s.Wrapper>{children}</s.Wrapper>
                </s.PixSimplesModalContainer>
              ) : (
                <s.PixSimplesModalContainer provider={isFrom} step={step}>
                  <s.PixAnyBankHeaderRede provider={isFrom}>
                    <img src={redeBank} />
                    <s.Text color="white" bold type="headline">
                      Receba Pix em Qualquer Banco
                    </s.Text>
                  </s.PixAnyBankHeaderRede>
                  <s.Wrapper>{children}</s.Wrapper>
                </s.PixSimplesModalContainer>
              )}
            </>
          )}
        </s.Modal>
      )
    }
    return (
      <s.Modal
        isHeaderComponent={false}
        noChildrenPadding={true}
        isOpen={!!isOpen}
        handleClose={() => handleClose()}
      >
        {theme.domain === 'Shipay' || isWhiteLabel() ? (
          <s.PixSimplesModalContainer provider={isFrom} step={step}>
            <s.PixAnyBankHeader provider={isFrom}>
              <img src={headerImage()} />
              <s.Text color="white" bold type="headline">
                Receba Pix em Qualquer Banco
              </s.Text>
            </s.PixAnyBankHeader>
            <s.Wrapper>{children}</s.Wrapper>
          </s.PixSimplesModalContainer>
        ) : (
          <s.PixSimplesModalContainer provider={isFrom} step={step}>
            <s.PixAnyBankHeaderRede provider={isFrom}>
              <img
                src={
                  isFrom === 'original' ||
                  isFrom === 'conexaoItau' ||
                  isFrom === 'tbanks'
                    ? redeBank
                    : tbanksBank
                }
              />
              <s.Text color="white" bold type="headline">
                Receba Pix em Qualquer Banco
              </s.Text>
            </s.PixAnyBankHeaderRede>
            <s.Wrapper>{children}</s.Wrapper>
          </s.PixSimplesModalContainer>
        )}
      </s.Modal>
    )
  }

  return <>{renderModalByPsp()}</>
}

export default PixSimplesModalContainer
