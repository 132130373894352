import React from 'react'

import { isConexaoItau } from 'helpers'
import useGetLogo from 'hooks/useGetLogo'
import { useTheme } from 'styled-components'

import * as s from './styles'

const BanklineItauTutorial: React.FC<{ alreadyExists?: boolean }> = ({
  alreadyExists = false,
}) => {
  const theme = useTheme()
  const { logo } = useGetLogo()
  return (
    <s.Sections data-testid="content-on-bankline-tutorial">
      <s.BanklineWrapper>
        <s.Text
          color={alreadyExists ? 'redshipay' : 'graytheme6'}
          bold
          type="heading4"
        >
          {alreadyExists
            ? `Parece que você ainda não solicitou sua vinculação ${
                isConexaoItau() || theme.domain === 'ConexãoItaú'
                  ? ''
                  : 'com a Shipay'
              }`
            : `Solicite a vinculação${
                isConexaoItau() || theme.domain === 'ConexãoItaú'
                  ? ''
                  : ' com a Shipay'
              } através do Bankline Itaú`}
        </s.Text>
        <s.Text margin="32px 0 32px 0" type="headline" color="graytheme6">
          {`Para cadastrar uma Conta Pix Itaú ${
            isConexaoItau() || theme.domain === 'ConexãoItaú'
              ? 'no Painel Conexão Itaú'
              : 'na Shipay'
          } é necessário antes vincular a ${
            isConexaoItau() || theme.domain === 'ConexãoItaú'
              ? logo.pos_product_name
              : 'Shipay'
          } como parceira Pix através do Bankline Itaú.`}
        </s.Text>

        <s.OrderedList>
          <s.ListItem>
            <s.Text type="headline" color="graytheme6">
              Acesse sua conta pelo{' '}
              <s.ExternalLink href="https://www.itau.com.br/" target="_blank">
                Bankline Itaú ↗
              </s.ExternalLink>
            </s.Text>
          </s.ListItem>
          <s.ListItem>
            <s.Text type="headline" color="graytheme6">
              Clique no item <b>Pix</b>
            </s.Text>
          </s.ListItem>
          <s.ListItem>
            <s.Text type="headline" color="graytheme6">
              Clique na opção de <b>Integrador Pix</b>
            </s.Text>
          </s.ListItem>
          <s.ListItem>
            <s.Text type="headline" color="graytheme6">
              Selecione a <b>Chave Pix</b>
            </s.Text>
          </s.ListItem>
          <s.ListItem>
            <s.Text type="headline" color="graytheme6">
              {`Busque por ${
                isConexaoItau() || theme.domain === 'ConexãoItaú'
                  ? logo.pos_product_name.toUpperCase()
                  : 'SHIPAY TECNOLOGIA S/A'
              }`}{' '}
              e selecione a opção de acesso <b>completo</b>
            </s.Text>
          </s.ListItem>
          <s.ListItem>
            <s.Text type="headline" color="graytheme6">
              Conclua a validação para solicitar o acesso
            </s.Text>
          </s.ListItem>
          <s.ListItem>
            <s.Text type="headline" color="graytheme6">
              {`Retorne ao Painel ${
                isConexaoItau() || theme.domain === 'ConexãoItaú'
                  ? ''
                  : 'Shipay'
              } para fazer o cadastro`}
            </s.Text>
          </s.ListItem>
        </s.OrderedList>
      </s.BanklineWrapper>
    </s.Sections>
  )
}

export default BanklineItauTutorial
