import React from 'react'
import { useDispatch } from 'react-redux'

import { get, baseURLProfile } from '../services/api'
import { useTypedSelector } from '../store/modules/rootState'
import {
  GetProfile,
  GetProfileLoading,
  GetProfileError,
} from 'store/modules/profile/actions'

import { ProfileState } from 'store/modules/profile/types'

interface UseGetProfile extends ProfileState {
  loadProfile: () => Promise<void>
}

const useGetProfile = (): UseGetProfile => {
  const dispatch = useDispatch()

  const { profile, isLoading, hasError } = useTypedSelector((store) => ({
    profile: store.profile.profile,
    isLoading: store.profile.isLoading,
    hasError: store.profile.hasError,
  }))

  const loadProfile = React.useCallback(async (): Promise<void> => {
    dispatch(GetProfileLoading())
    await get(`${baseURLProfile}`)
      .then((response) => {
        dispatch(GetProfile(response.data))
      })
      .catch(() => {
        dispatch(GetProfileError())
      })
  }, [dispatch])

  return { profile, hasError, isLoading, loadProfile }
}

export default useGetProfile
