import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { WalletState } from './wallets/types'
import { SummaryState } from './summary/types'
import { SystemWalletsState } from './systemWallets/types'
import { WalletSettingsState } from './walletSettings/types'
import { PixWSState } from './walletSettings/pix/types'
import { RegistrationStatusState } from './registrationStatus/types'
import { StoresState } from './stores/types'
import { UserState } from './user/types'
import { ProfileState } from './profile/types'
import { StoreState } from './store/types'
import { GeoAddressState } from './geoAddress/types'
import { formState } from './form/types'
import { CustomersState } from './admin/types'
import { AuthState } from './auth/types'
import { ImpersonateState } from './impersonate/types'
import { RetailChainState } from './retailChain/types'
import { StorePosState } from './storePos/types'
import { StoreWalletsState } from './storeWallets/types'
import { PixWSState as PixWSStateNew } from './pixWalletSettings/types'
import { CompleteRegistrationState } from './completeRegistration/types'
import { CustomerRegistrationState } from './customerRegistration/types'
import { CertificateState } from './certificate/types'
import { SSENotificationState } from './SSENotification/types'
import { EWalletWSSagaState } from './walletSettings/ewallet/types'
import { OrdersSummaryFilesState } from './ordersSummaryFiles/types'
import { TransactionLogsState } from './transacionLogs/types'
import { LogoState } from './logo/types'
import { ThemeState } from './theme/types'

export interface RootState {
  walletsList: WalletState
  summary: SummaryState
  systemWallets: SystemWalletsState
  walletSettings: WalletSettingsState
  pixWalletSettings: PixWSState
  registrationStatus: RegistrationStatusState
  stores: StoresState
  user: UserState
  profile: ProfileState
  logo: LogoState
  store: StoreState
  geoAddress: GeoAddressState
  formManagement: formState
  customers: CustomersState
  auth: AuthState
  impersonate: ImpersonateState
  retailChain: RetailChainState
  storePos: StorePosState
  storeWallets: StoreWalletsState
  pixWS: PixWSStateNew
  completeRegistration: CompleteRegistrationState
  customerRegistration: CustomerRegistrationState
  certificate: CertificateState
  SSENotification: SSENotificationState
  eWalletWSSaga: EWalletWSSagaState
  ordersSummaryFiles: OrdersSummaryFilesState
  transactionLogs: TransactionLogsState
  theme: ThemeState
}

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
