import styled, { css } from 'styled-components'
import ModalCmp from 'components/Modal'
import IconCmp from 'components/IconExporter'
import InputTextCmp from 'components/Inputs/Text'
import TextCmp from 'components/Text'
import ButtonCmp from 'components/Button'
import { ModalContainer, ChildrenWrapper } from 'components/Modal/styles'
import LoadingCmp from 'components/Loading'
import { themeColors } from 'styles/theme'
import RadioComponent from 'components/Inputs/RadioButton'
import UploadImageFileCmp from 'components/UploadFile/UploadFileButtonArea'

export const Modal = styled(ModalCmp)`
  header {
    background: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
    padding: 32px;

    h5 {
      color: ${({ theme }) => theme.colors.graytheme8};
    }
  }

  ${ChildrenWrapper} {
    overflow: auto;
    ::-webkit-scrollbar-track {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  ${ModalContainer} {
    padding: 0px;
  }
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 520px;
  margin: 16px;
`

interface PropsContent {
  width: number
  height?: number
}

export const Loading = styled(LoadingCmp)``

export const Content = styled.div<PropsContent>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
`

export const Icon = styled(IconCmp)`
  display: inline;
`

export const InputsWrapper = styled.div`
  > div {
    margin: 32px 0px;
  }
  div:last-child {
    margin-bottom: 0px;
  }
`
export const InputText = styled(InputTextCmp)``

export const UnderlineText = styled.span`
  text-decoration: underline;
`

interface ButtonWrapperProps {
  margin?: string
}
export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: ${({ margin }) => margin};
`

export const ImageWrapper = styled.div`
  margin: 36px 0px;
`

export const Button = styled(ButtonCmp)`
  width: 100%;
  box-shadow: 0px 1px 4px #b3b3b3;
  svg {
    margin-right: 8px;
  }
`
export const BackButton = styled(ButtonCmp)`
  height: 42px;
  box-shadow: 0px 1px 4px #b3b3b3;
  svg {
    margin-right: 8px;
  }
`

export const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Text = styled(TextCmp)``

export const WarningCard = styled.div`
  border: 1px solid #dadada;
  border-radius: 8px;
  width: 100%;
`

export const Wrapper = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;

  > p {
    align-self: flex-start;
  }

  > img {
    margin-top: 90px;
  }

  > button {
    :first-of-type {
      margin-top: auto;
      margin-bottom: 16px;
    }
  }
`

export const RepresentantModalWrapper = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;

  > p {
    align-self: flex-start;
  }

  > img {
    margin-top: 90px;
  }

  > button {
    :first-of-type {
      margin-top: auto;
      margin-bottom: 16px;
    }
  }
`

export const Container = styled.div`
  p {
    margin-bottom: 40px;
  }

  h5 {
    margin: 20px auto;
  }
`

export const LoadingGradient = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 64px;

  img {
    will-change: transform;
    transition-property: transform;
    animation-name: rotate;
    animation-duration: 1100ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`

export const UserWrapper = styled.div`
  margin: 20px 0px 32px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.graytheme1};
  border-radius: 8px;

  > svg {
    margin-right: 8px;
  }
`

export const HeaderStepsContainer = styled.div`
  margin: -38px -32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 92px;
  padding: 16px 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
`

export const HeaderStepWrapper = styled.div`
  display: flex;
  align-items: left;
  justify-content: center;
  gap: 4px;
`

interface HeaderStepCircleProps {
  backgroundColor?: keyof typeof themeColors
  padding?: number
}

export const HeaderStepCircle = styled.div<HeaderStepCircleProps>`
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors[props.backgroundColor]};
  padding: ${({ padding }) => `${padding}px`};
`

export const NumberStep = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  color: white;

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const PreviousNextButtonWrapper = styled.div<ButtonWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: ${({ margin }) => margin};
`

interface OptionsContainerProps {
  margin?: string
}
export const OptionsContainer = styled.div<OptionsContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
  height: 436px;
  gap: 32px;

  margin: ${({ margin }) => margin};
`

export const RadioButton = styled(RadioComponent)``

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 8px;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.maincolor};
`

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
`

export const CardWrapper = styled.div`
  width: 520px;
  height: 226px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const CardIconFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 68px;
  border-radius: 8px;
  padding: 16px;
  border: 4px solid ${({ theme }) => theme.colors.maincolor};

  svg {
    width: 100%;
  }
`
export const UploadImageFile = styled(UploadImageFileCmp)`
  margin-bottom: 32px;
`
