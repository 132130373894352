import React from 'react'

import { useTranslation } from 'react-i18next'

import customerService from 'services/customer'

import { getCryptos } from 'helpers/crypto'

import useWalletSettingsSaga from 'hooks/useWalletSettingsSaga'
import { useSSE } from 'hooks/utils/useSSE'
import { useNonInitialEffect } from 'hooks/utils'

import { showToast } from 'components/Toast'

import { isSpacePay } from 'helpers'

import * as s from '../styles'
import CoinPaymentsConfigIPNWallet from './CoinPaymentsConfigIPNWallet'

const CoinPaymentsConfigWallet: React.FC<{
  handleClose: () => void
  isModalOpen: boolean
  setTitle: React.Dispatch<React.SetStateAction<string>>
}> = ({ handleClose, isModalOpen, setTitle }) => {
  const { t } = useTranslation()
  const { postIsLoading, postResponse, postEWalletWalletSettingsSaga } =
    useWalletSettingsSaga()

  const { openConnection, notifications } = useSSE()

  const [customerUUID, setCustomerUUID] = React.useState<string>('')

  const [input, setInput] = React.useState({
    name: '',
    default: true,
    active: true,
    wallet_name: 'coinpayments',
    pub_key: '',
    secret_key: '',
    payer_crypto_coin: '',
    receiver_crypto_coin: '',
  })

  const [selectedDefaultCrypto, setSelectedDefaultCrypto] = React.useState({
    id: -1,
    name: '',
  })

  const [selectedReceivementCoin] = React.useState({
    id: 18,
    name: 'BRL - Real',
  })

  const [inputPressed, setInputPressed] = React.useState<any>({
    ...Object.keys(input).reduce((acc, key) => {
      acc[key] = false
      return acc
    }, {}),
  })

  const [step, setStep] = React.useState(1)

  const [ipn, setIpn] = React.useState('')

  const handlePost = async () => {
    postEWalletWalletSettingsSaga({
      ...input,
      payer_crypto_coin: selectedDefaultCrypto.name
        .replace(' ', '')
        .split('-')[0],
      receiver_crypto_coin: selectedReceivementCoin.name
        .replace(' ', '')
        .split('-')[0],
    })
  }

  useNonInitialEffect(() => {
    if (postResponse.code !== -1) {
      showToast({
        type: 'success',
        message: t(postResponse.message),
      })
      setTitle('Configurar Segredo do IPN')
      setStep(2)
      // setSSEIsLoading(true)
      customerService.getCurrentCustomer().then((currentCustomerRes) => {
        setCustomerUUID(currentCustomerRes.data.uuid)
        openConnection(currentCustomerRes.data.uuid)
      })
    }
  }, [postResponse])

  React.useEffect(() => {
    if (notifications) {
      const status = notifications[customerUUID].message
        .toLowerCase()
        .includes('sucesso')
        ? 'success'
        : 'error'
      showToast({
        type: status,
        message: notifications[customerUUID].message,
      })
      setIpn(notifications[customerUUID].wallet_settings_uuid)
      // loadWalletsSettings()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  React.useEffect(() => {
    if (!isModalOpen) {
      setTitle('Configurar Carteira')
      setStep(1)
      setIpn('')
      setInput({
        name: '',
        default: true,
        active: true,
        wallet_name: 'coinpayments',
        pub_key: '',
        secret_key: '',
        payer_crypto_coin: '',
        receiver_crypto_coin: '',
      })
      setInputPressed({
        ...Object.keys(input).reduce((acc, key) => {
          acc[key] = false
          return acc
        }, {}),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen])

  const renderForm = (height: number) => {
    if (step === 1) {
      return (
        <s.FormContainer height={height}>
          {!isSpacePay() ? (
            <s.HelperWrapper>
              <s.Text>
                Para ajudar você nesta tarefa preparamos um vídeo explicativo!
              </s.Text>
              <s.Button width="200px">Clique aqui</s.Button>
            </s.HelperWrapper>
          ) : (
            <></>
          )}
          <s.InputText
            label="Apelido da Credencial"
            placeholder="Ex: ”Loja Paulista”, ”loja@paulista.com”, etc."
            value={input.name}
            onChange={(e) => {
              setInput({ ...input, name: e.target.value })
              setInputPressed({ ...inputPressed, name: true })
            }}
            error={
              input.name === '' && inputPressed.name
                ? {
                    message: 'Campo Obrigatório',
                  }
                : null
            }
          />
          <s.InputText
            label="Chave Pública"
            value={input.pub_key}
            onChange={(e) => {
              setInput({ ...input, pub_key: e.target.value })
              setInputPressed({ ...inputPressed, pub_key: true })
            }}
            error={
              input.pub_key === '' && inputPressed.pub_key
                ? {
                    message: 'Campo Obrigatório',
                  }
                : null
            }
          />
          <s.InputText
            label="Chave Privada"
            value={input.secret_key}
            onChange={(e) => {
              setInput({ ...input, secret_key: e.target.value })
              setInputPressed({ ...inputPressed, secret_key: true })
            }}
            error={
              input.secret_key === '' && inputPressed.secret_key
                ? {
                    message: 'Campo Obrigatório',
                  }
                : null
            }
          />
          <s.SearchSelect
            label="Cripto padrão"
            optionBoxSize={100}
            options={[
              ...getCryptos().map((crypto) => {
                return {
                  id: crypto.id,
                  text: crypto.text,
                  onClick: () => {
                    setSelectedDefaultCrypto({
                      id: crypto.id,
                      name: crypto.text,
                    })
                  },
                }
              }),
            ]}
            selectedState={[selectedDefaultCrypto, setSelectedDefaultCrypto]}
          />
          <s.FooterButtonWrapper>
            <s.Button outline color="white" onClick={() => handleClose()}>
              Cancelar
            </s.Button>
            <s.Button
              color="maincolor"
              disabled={
                input.name === '' ||
                input.pub_key === '' ||
                input.secret_key === '' ||
                selectedDefaultCrypto.name === '' ||
                selectedReceivementCoin.name === ''
              }
              onClick={() => handlePost()}
            >
              Salvar
            </s.Button>
            {postIsLoading ? (
              <s.Loading type="spinner" color="maincolor" />
            ) : null}
          </s.FooterButtonWrapper>
        </s.FormContainer>
      )
    }
    if (step === 2) {
      return (
        <CoinPaymentsConfigIPNWallet
          handleClose={handleClose}
          isModalOpen={isModalOpen}
          ipn={ipn}
        />
      )
    }
  }

  return <>{renderForm(isSpacePay() ? 500 : 600)}</>
}

export default CoinPaymentsConfigWallet
