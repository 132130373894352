import styled from 'styled-components'

import CloseIcon from 'components/IconExporter'
import TextCmp from 'components/Text'

import { isSpacePay } from 'helpers'

interface IProps {
  noChildrenPadding?: boolean
  placeModalOnCenter?: boolean
}

export const ModalOverlay = styled.div<{ isOpen: boolean }>`
  background-color: rgba(39, 39, 39, 0.63);
  backdrop-filter: blur(5px);
  height: 100%;
  position: fixed;
  transition: all ease 0.3s;
  width: 100%;
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  left: 0;
  top: 0;
`

export const ChildrenWrapper = styled.div<IProps>`
  overflow-y: auto;
  padding: ${(props) => !props.noChildrenPadding && props.theme.spacing(3)}px
    ${(props) => !props.noChildrenPadding && props.theme.spacing(2)}px;

  @media ${(props) => props.theme.mediaQueries.sm} {
    padding: ${(props) => !props.noChildrenPadding && props.theme.spacing(3)}px
      ${(props) => !props.noChildrenPadding && props.theme.spacing(2)}px;
  }
  /* width */
  ::-webkit-scrollbar {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.graytheme1};
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.graytheme3};
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.graytheme4};
  }

  ::-webkit-scrollbar-thumb:active {
    background: ${({ theme }) => theme.colors.graytheme5};
  }
`

export const ModalContainer = styled.div<IProps>`
  padding: ${(props) => props.theme.assets.modalComponent.padding};
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadows.gray2};
  border-radius: 8px;
  position: ${({ placeModalOnCenter }) =>
    placeModalOnCenter ? 'relative' : 'fixed'};
  margin: ${(props) => props.theme.spacing(4)}px 0;
  max-height: calc(100vh - (28px * 2));
  overflow: hidden;
  display: flex;
  flex-direction: column;
  top: 0;

  @media ${(props) => props.theme.mediaQueries.xs} {
    min-width: unset;
    width: calc(100vw - 28px);
    top: auto;
    margin: 0;
    max-height: calc(100vh - (14px * 2));
  }
`

export const ModalHeader = styled.header`
  ${({ theme }) => theme.assets.modalComponent.modalHeader};
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  p {
    margin-top: 4px;
  }
`

export const ModalTitle = styled.h3`
  ${(props) => props.theme.fontTypes.heading2}
  color: ${(props) => props.theme.colors.gray4};
  font-weight: normal;
  flex: 1;
`

export const CloseButton = styled(CloseIcon)`
  cursor: pointer;
  transition: all ease 0.3s;
  text-align: right;

  &:hover {
    fill: ${(props) =>
      !isSpacePay() ? props.theme.colors.maincolor : props.theme.colors.black};
  }
`

interface IModalWrapperProps {
  isOpen: boolean
}

export const ModalWrapper = styled.div<IModalWrapperProps>`
  align-items: 'center';
  display: flex;
  height: ${(props) => (props.isOpen ? '100%' : 0)};
  justify-content: center;
  left: 0;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  position: fixed;
  top: 0;
  transition: all ease 0.1s;
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  width: 100vw;
  z-index: 5;
`

export const ModalFooter = styled.footer`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`

export const Text = styled(TextCmp)``
