export const getCryptos = (): { id: number; text: string }[] => {
  return [
    { id: 1, text: 'BTC.LN - Bitcoin Lightning (recomendado)' },
    { id: 2, text: 'BTC - Bitcoin' },
    { id: 3, text: 'BCH - Bitcoin Cash' },
    { id: 4, text: 'LTC - Litecoin' },
    { id: 5, text: 'BNB.BSC - BNB Coin (BSC Chain)' },
    { id: 6, text: 'BUSD.BEP20 - BUSD Token (BSC Chain)' },
    { id: 7, text: 'DASH - Dash' },
    { id: 8, text: 'DOGE - Dogecoin' },
    { id: 9, text: 'ETC - Ether Classic' },
    { id: 10, text: 'ETH - Ether' },
    { id: 11, text: 'TRX - TRON' },
    { id: 12, text: 'USDC - USD Coin (ERC20)' },
    { id: 13, text: 'USDT.TRC20 - Tether USD (Tron/TRC20)' },
    { id: 14, text: 'XMR - Monero' },
    { id: 15, text: 'XRP - Ripple' },
    { id: 16, text: 'ZEC - ZCash' },
    { id: 17, text: 'SOL - Solana' },
  ]
}
