import React from 'react'
import * as s from './styles'
import { applyCpfCnpjMask, applyPhoneMask } from 'helpers/masks'
import {
  isValidCnpj,
  isValidEmail,
  isValidPhone,
  isNotEmpty,
} from 'helpers/validators'

interface IProps {
  hasConfimationField?: boolean
  value?: string //FIXME: remover opcionalidade
  onChange?: (e) => void
  pixKeyErr?: string
  onPixKeyErr?: (v) => void
  typesFilter?: string[]
  selectedTypeId?: React.MutableRefObject<number>
  selectSize?: number
}

interface Option {
  id: number
  name: string
}
export const PixKeyInput: React.FC<IProps> = ({
  hasConfimationField,
  value,
  onChange,
  pixKeyErr,
  onPixKeyErr,
  selectSize = 400,
  typesFilter = ['email', 'cnpj', 'telefone', 'aleatoria'],
  selectedTypeId = { current: 4 },
}) => {
  const [placeholder, setPlaceholder] = React.useState(
    'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
  )
  const [pixKeyConfirmation, setPixKeyConfirmation] = React.useState<string>('')
  const [
    pixKeyConfirmationError,
    setPixKeyConfirmationError,
  ] = React.useState<string>('')
  const [selected, setSelected] = React.useState({
    id: 4,
    name: 'Aleatória',
  })
  const resetMask = (selectedOption: Option) => {
    setSelected(selectedOption)
    onChange('')
    setPixKeyConfirmation('')
    if (selectedOption.id === 1) setPlaceholder('shipay@email.com')
    if (selectedOption.id === 2) setPlaceholder('XX.XXX.XXX/XXXX-XX')
    if (selectedOption.id === 3) setPlaceholder('(XX) XXXX-XXXX')
    if (selectedOption.id === 4)
      setPlaceholder('xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx')
  }

  const validate = (v) => {
    if (selected.id === 4) {
      const err = isNotEmpty(v) ? '' : 'Campo Obrigatório'
      onPixKeyErr(err)
    }
    if (selected.id === 3) {
      const err = isValidPhone(v) ? '' : 'Telefone Inválido'
      onPixKeyErr(err)
    }
    if (selected.id === 2) {
      const err = isValidCnpj(v) ? '' : 'CNPJ Inválido'
      onPixKeyErr(err)
    }
    if (selected.id === 1) {
      const err = isValidEmail(v) ? '' : 'Email Inválido'
      onPixKeyErr(err)
    }
  }

  const validateConfimation = (v1, v2 = undefined) => {
    const errMsg = 'Chaves Diferentes'
    if (!v1) {
      setPixKeyConfirmationError(errMsg)
      onPixKeyErr(errMsg)
      return
    }

    if (v2) {
      const err = v1 === v2 ? '' : errMsg
      setPixKeyConfirmationError(err)
      onPixKeyErr(err)
    } else {
      const err = v1 === value ? '' : errMsg
      setPixKeyConfirmationError(err)
      onPixKeyErr(err)
    }
  }

  return (
    <>
      <s.InputGroup>
        <s.InputGroupPixKey>
          <div>
            <s.Text bold color="maincolor" type="headline">
              Tipo
            </s.Text>
            <s.Select
              options={[
                {
                  id: 1,
                  text: 'Email',
                  onClick: () => {
                    selectedTypeId.current = 1
                    resetMask({ id: 1, name: 'Email' })
                  },
                },
                {
                  id: 2,
                  text: 'CNPJ',
                  onClick: () => {
                    selectedTypeId.current = 2
                    resetMask({ id: 2, name: 'CNPJ' })
                  },
                },
                {
                  id: 3,
                  text: 'Telefone',
                  onClick: () => {
                    selectedTypeId.current = 3
                    resetMask({ id: 3, name: 'Telefone' })
                  },
                },
                {
                  id: 4,
                  text: 'Aleatória',
                  onClick: () => {
                    selectedTypeId.current = 4
                    resetMask({ id: 4, name: 'Aleatória' })
                  },
                },
              ].filter((x) => {
                if (
                  typesFilter.includes(
                    x.text
                      .normalize('NFD')
                      .replace(/\p{Diacritic}/gu, '')
                      .toLowerCase()
                  )
                ) {
                  return x
                }
              })}
              selected={selected}
              optionBoxSize={selectSize}
            />
          </div>
          <div>
            <s.InputText
              maxLength={100}
              label="Chave Pix"
              placeholder={placeholder}
              value={value}
              onChange={(e) => {
                const inputValue = e.target.value
                if (selected.id === 2) {
                  const maskedInputValue = applyCpfCnpjMask(inputValue)
                  onChange(maskedInputValue)
                  validate(maskedInputValue)
                  if (hasConfimationField)
                    validateConfimation(pixKeyConfirmation, maskedInputValue)
                } else if (selected.id === 3) {
                  const maskedInputValue = applyPhoneMask(inputValue)
                  onChange(maskedInputValue)
                  validate(maskedInputValue)
                  if (hasConfimationField)
                    validateConfimation(pixKeyConfirmation, maskedInputValue)
                } else {
                  onChange(inputValue)
                  validate(inputValue)
                  if (hasConfimationField)
                    validateConfimation(pixKeyConfirmation, inputValue)
                }
              }}
              error={pixKeyErr ? { message: pixKeyErr } : false}
            />
          </div>
        </s.InputGroupPixKey>
      </s.InputGroup>
      {hasConfimationField ? (
        <s.InputGroup>
          <s.InputText
            maxLength={100}
            label="Confirmação da Chave Pix"
            placeholder={placeholder}
            value={pixKeyConfirmation}
            onChange={(e) => {
              const inputValue = e.target.value
              if (selected.id === 2) {
                setPixKeyConfirmation(applyCpfCnpjMask(inputValue))
                validateConfimation(inputValue)
              } else if (selected.id === 3) {
                setPixKeyConfirmation(applyPhoneMask(inputValue))
                validateConfimation(inputValue)
              } else {
                setPixKeyConfirmation(inputValue)
                validateConfimation(inputValue)
              }
            }}
            error={
              pixKeyConfirmationError
                ? { message: pixKeyConfirmationError }
                : false
            }
          />
        </s.InputGroup>
      ) : (
        ''
      )}
    </>
  )
}
