import { Types, SystemWalletsInterface } from './types'

export function GetSystemWallets(
  payload: SystemWalletsInterface[]
): {
  type: string
  payload: SystemWalletsInterface[]
} {
  return {
    type: Types.GET_SYSTEM_WALLETS,
    payload,
  }
}

export function GetSystemWalletsIsLoading(): {
  type: string
} {
  return {
    type: Types.GET_SYSTEM_WALLETS_LOADING,
  }
}

export function GetSystemWalletsError(): {
  type: string
} {
  return {
    type: Types.GET_SYSTEM_WALLETS_ERROR,
  }
}
