import {
  ActionInterface,
  Types,
  StoreState,
  ResponseInterface,
  PayloadInterface,
} from './types'

const INITIAL_STATE: StoreState = {
  store: {
    active: null,
    city_name: null,
    cnpj_cpf: null,
    headquarter: null,
    id: null,
    meta_data: null,
    name: null,
    neighborhood: null,
    pos: null,
    reference: null,
    state_name: null,
    street_name: null,
    street_number: null,
    zip_code: null,
  },
  isLoading: false,
  hasError: false,
  postResponse: null,
  postIsLoading: false,
  postHasError: false,
  postHasErrorMessage: null,
  deleteIsLoading: false,
  deleteResponse: null,
}

const storeReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): StoreState => {
  switch (action.type) {
    case Types.GET_STORE:
      return {
        ...state,
        store: action.payload as PayloadInterface,
        isLoading: false,
        hasError: false,
      }
    case Types.GET_STORE_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_STORE_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    case Types.POST_STORE_REQUEST:
      return {
        ...state,
        postIsLoading: true,
        postHasError: false,
      }
    case Types.POST_STORE_RESPONSE:
      return {
        ...state,
        postResponse: action.payload as ResponseInterface,
        postIsLoading: false,
        postHasError: false,
        postHasErrorMessage: '',
      }
    case Types.POST_STORE_ERROR:
      return {
        ...state,
        postIsLoading: false,
        postHasError: true,
        postHasErrorMessage: action.message,
      }
    case Types.DELETE_STORE_REQUEST:
      return {
        ...state,
        deleteIsLoading: true,
      }
    case Types.DELETE_STORE_RESPONSE:
      return {
        ...state,
        deleteIsLoading: false,
        deleteResponse: action.response,
      }
    default:
      return state
  }
}

export default storeReducer
