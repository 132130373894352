import React from 'react'
import { useDispatch } from 'react-redux'

import { get, baseURLSystemWallets } from '../services/api'
import { useTypedSelector } from '../store/modules/rootState'
import {
  GetSystemWallets,
  GetSystemWalletsIsLoading,
  GetSystemWalletsError,
} from 'store/modules/systemWallets/actions'

import { SystemWalletsState } from 'store/modules/systemWallets/types'

interface IUseGetSystemWallets extends SystemWalletsState {
  loadSystemWallets: () => Promise<void>
}

const UseGetSystemWallets = (): IUseGetSystemWallets => {
  const dispatch = useDispatch()

  const { systemWallets, isLoading, hasError } = useTypedSelector((store) => ({
    systemWallets: store.systemWallets.systemWallets,
    isLoading: store.systemWallets.isLoading,
    hasError: store.systemWallets.hasError,
  }))

  const loadSystemWallets = React.useCallback(async (): Promise<void> => {
    dispatch(GetSystemWalletsIsLoading())
    await get(baseURLSystemWallets)
      .then((response) => {
        dispatch(GetSystemWallets(response.data))
      })
      .catch(() => {
        dispatch(GetSystemWalletsError())
      })
  }, [dispatch])

  return { systemWallets, hasError, isLoading, loadSystemWallets }
}

export default UseGetSystemWallets
