import React from 'react'

import { applyCpfCnpjMask, applyPhoneMask } from 'helpers/masks'

import * as s from '../styles'
import { baseURLPixAnybankOpenAccount, post } from 'services/api'
import { showCustomErrorToast, showToast } from 'components/Toast'
import { removeAccentsAndDiacritics } from 'helpers'
import { FormProps } from '.'

interface ErrorMessage {
  code: number
  message: string
}

function handleErrorMessages({ message }: ErrorMessage): Array<string> {
  const errorMessages = []
  if (message) {
    if (message.includes('CNPJ/CPF not valid')) {
      errorMessages.push('CPF ou CNPJ inválido')
    }

    if (message.includes('Could not find store')) {
      errorMessages.push('Loja não localizada')
    }

    if (message.includes('phone_number')) {
      errorMessages.push('Telefone inválido')
    }

    if (message.includes('email')) {
      errorMessages.push('Email inválido')
    }

    if (message.includes('created_at')) {
      errorMessages.push('Data Inválida')
    }
  }

  if (errorMessages.length === 0) return ['Houve algum erro, tente novamente.']
  return errorMessages
}

const AnyBankTbanksRepresentantForm: React.FC<{
  nextStep: () => void
  previousStep: () => void
  isFrom: string
  pixFormState: [FormProps, React.Dispatch<React.SetStateAction<FormProps>>]
  closeModal: () => void
}> = ({ nextStep, previousStep, isFrom, pixFormState, closeModal }) => {
  const [form, setForm] = pixFormState
  const [isLoading, setIsLoading] = React.useState(false)

  const [representant, setRepresentant] = React.useState({
    document_number: '',
    name: '',
    email: '',
    phone_number: '',
  })

  const checkIfAllInputsAreValid = React.useCallback(() => {
    return Object.entries(representant).every((item) => {
      return item[0] !== 'complement'
        ? item[1] !== null && item[1] !== ''
        : true
    })
  }, [representant])

  const customerValidation = async () => {
    setIsLoading(true)
    const phoneNumberOnly = representant.phone_number.replace(/\D/g, '')

    const payload: any = {
      psp_provider: 'tbanks',
      company: {
        ...form.company,
        document_number: form.company.document_number.replace(/[./-]+/g, ''),
        phone_number: form.company.phone_number.replace(/[^0-9]/g, ''),
        created_at: form.company.created_at.split('/').reverse().join('-'),
      },
      representative: {
        ...representant,
        phone_number: phoneNumberOnly,
        document_number: representant.document_number.replace(/[./-]+/g, ''),
      },
      destination_account: {
        ...form.destinationAccount,
        bank: form.destinationAccount.bank.match(/\d+/)[0],
        account: form.destinationAccount.account.replace(/-/g, ''),
      },
    }

    setForm({
      ...form,
      ...payload,
    })

    await post(`${baseURLPixAnybankOpenAccount}`, payload)
      .then((response) => {
        showToast({
          type: 'success',
          message: 'Conta cadastrada com sucesso',
        })
      })
      .catch((e) => {
        showCustomErrorToast(
          handleErrorMessages(JSON.parse(e.request.response))
        )
      })
      .finally(() => {
        setIsLoading(false)
        closeModal()
      })
  }

  return (
    <>
      <s.AnyBankSubHeaderText>
        <s.Text bold type="headline" color="graytheme6">
          Insira os dados do representante legal da empresa.
        </s.Text>
      </s.AnyBankSubHeaderText>
      <s.AnyBankTbanksRepresentantFormWrapper>
        <s.Text bold margin="0 0 24px 0" type="headline" color="graytheme6">
          Dados do representante legal
        </s.Text>
        <s.AnyBankTbanksRepresentantInputsWrapper>
          <s.InputText
            width="300"
            label="Nome"
            value={representant.name}
            onChange={(e) => {
              setRepresentant({
                ...representant,
                name: e.target.value,
              })
            }}
          />
          <s.InputText
            width="300"
            label="E-mail"
            value={representant.email}
            onChange={(e) => {
              setRepresentant({
                ...representant,
                email: e.target.value,
              })
            }}
          />

          <s.InputText
            width="300"
            label="CPF"
            maxLength={14}
            value={applyCpfCnpjMask(representant.document_number)}
            onChange={(e) => {
              setRepresentant({
                ...representant,
                document_number: removeAccentsAndDiacritics(
                  e.target.value
                ).replace(/[@&#$~`'´"^/]/g, ''),
              })
            }}
          />

          <s.InputText
            width="300"
            label="Telefone"
            maxLength={15}
            value={applyPhoneMask(representant.phone_number)}
            onChange={(e) => {
              setRepresentant({
                ...representant,
                phone_number: removeAccentsAndDiacritics(
                  e.target.value
                ).replace(/[@&#$~`'´"^/]/g, ''),
              })
            }}
          />
        </s.AnyBankTbanksRepresentantInputsWrapper>
      </s.AnyBankTbanksRepresentantFormWrapper>
      <s.TbanksButtonWrapper>
        <s.LeftButtonTbanksAnybank
          data-testid="previous-button-step"
          onClick={() => {
            previousStep()
          }}
          color="whitedetailed"
          width="150px"
        >
          <s.Icon name="arrowleft" fill="maincolor" />
          <s.Text bold color="graytheme6">
            Voltar
          </s.Text>
        </s.LeftButtonTbanksAnybank>
        <s.RightButtonTbanksAnybank
          onClick={() => {
            customerValidation()
          }}
          disabled={!checkIfAllInputsAreValid() || isLoading}
          width="150px"
        >
          <s.Text bold color="white">
            Finalizar
          </s.Text>
          {isLoading ? <s.Loading type="spinner" color="white" /> : <></>}
        </s.RightButtonTbanksAnybank>
      </s.TbanksButtonWrapper>
    </>
  )
}

export default AnyBankTbanksRepresentantForm
