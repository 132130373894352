import React from 'react'

import { reconciliationFormatMoney } from 'helpers/masks'
import { solveElement } from 'helpers/reconciliation'
import { baseURLReconciliation, get, post } from 'services/api'
import { v4 as uuid } from 'uuid'

import * as s from './styles'

interface IProps {
  isOpen: boolean
  handleClose: () => any
  options: {
    start_date: string
    end_date: string
    wallet_setting_id: string
    consolidated_customer_value: string
  }
}

interface response {
  total: number
  data: any
}

const RenderValue = ({ children }) => {
  return <s.Text>{reconciliationFormatMoney(children)}</s.Text>
}

const ReconciliationTransferModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  options,
}) => {
  const [
    transferDetailsData,
    setTransferDetailsData,
  ] = React.useState<response>()
  const [offset, setOffset] = React.useState(0)

  const getTransferDetails = async (offset: number) => {
    await get(`${baseURLReconciliation}/transfer/details`, {
      params: {
        ...options,
        origin: 'Transferencia',
        limit: 100,
        offset: offset,
      },
    }).then((res) => {
      setTransferDetailsData(res.data)
    })
  }

  const getTransferDetailsRecursive = async function (offset) {
    await getTransferDetails(offset)
    let data = transferDetailsData.data
    data.forEach((element) => {
      element.solved = element.solved + ''
    })
    data = data.map(({ id, ...keepAttrs }) => keepAttrs)
    if (offset / 100 < Math.ceil(transferDetailsData.total / 100) - 1) {
      return data.concat(await getTransferDetailsRecursive(offset + 100))
    } else {
      return data
    }
  }

  const solvedRender = ({ children }) => {
    let solved = transferDetailsData?.data?.filter((x) => x.id === children)[0]
      .solved
    return (
      <>
        <s.Checkbox
          id={uuid()}
          onChange={() => {
            solveElement(
              transferDetailsData?.data?.filter((x) => x.id === children)[0],
              options.wallet_setting_id
            ).then(() => getTransferDetails(offset))
          }}
          checked={solved}
          label={solved ? 'Resolvido' : 'Pendente'}
        />
      </>
    )
  }

  React.useEffect(() => {
    if (options?.wallet_setting_id) {
      getTransferDetails(offset)
    }
  }, [options, offset])

  return (
    <s.Modal
      modalSubtitle="Pix Transferência"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <s.ModalContainer>
        <s.ExportButton
          title="transferencias"
          startDate={options.start_date}
          endDate={options.end_date}
          dataCallback={getTransferDetailsRecursive}
        />
        <s.Summary>
          <s.Column>
            <s.Text type="headline" color="maincolor" bold>
              Origem
            </s.Text>
            <s.Text type="headline" color="maincolor">
              PSP
            </s.Text>
          </s.Column>
          <s.Column>
            <s.Text type="headline" color="maincolor" bold>
              Quantidade
            </s.Text>
            <s.Text type="headline" color="maincolor">
              {transferDetailsData?.total}
            </s.Text>
          </s.Column>
        </s.Summary>
        <s.Table
          data={transferDetailsData ? transferDetailsData.data : []}
          isLoading={false}
          columns={[
            { headerName: 'End to End', objectName: 'e2eid' },
            {
              headerName: 'Valor',
              objectName: 'total_order',
              AltRender: RenderValue,
            },
            { headerName: 'Data/Hora Pago', objectName: 'created_at' },
            { headerName: 'Nome do Pagador', objectName: 'payer_name' },
            { headerName: 'CPF/CNPJ', objectName: 'payer_cpf_cnpj' },
            {
              headerName: 'PSP Pagador',
              objectName: 'payer_financial_institute_name',
            },
            {
              headerName: 'Situação',
              objectName: 'id',
              AltRender: solvedRender,
            },
          ]}
        />
        <s.Pagination
          count={100}
          offset="0"
          total={transferDetailsData?.total}
          handleChange={(offset) => {
            setOffset(offset)
          }}
        />
      </s.ModalContainer>
    </s.Modal>
  )
}

export default ReconciliationTransferModal
