import styled, { css } from 'styled-components'

import { Container as ContainerCmp } from 'styled-bootstrap-grid'

import TextCmp from 'components/Text'
import LoadingCmp from 'components/Loading'
import ButtonCmp from 'components/Button'
import SelectCmp from 'components/Inputs/Select'
import InputTextCmp from 'components/Inputs/Text'
import CardCmp from 'components/Card'
import PaginationCmp from 'components/Pagination'
import ButtonLinkCmp from 'components/Button/ButtonLink'
import ModalCmp from 'components/Modal'

import { InputFieldGroup } from 'components/Inputs/Text/styles'

export const Container = styled(ContainerCmp)`
  height: 100vh;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  > table {
    margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  }
`

export const Loading = styled(LoadingCmp)`
  margin-left: 16px;
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Text = styled(TextCmp)``

export const ClickableText = styled(TextCmp)`
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const Button = styled(ButtonCmp)``

export const StoreActiveButton = styled(ButtonCmp)`
  height: 40px;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const SearchWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  ${InputFieldGroup} {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
    padding-top: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const Select = styled(SelectCmp)`
  width: 200px;
`

export const SearchInputText = styled(InputTextCmp)``

interface ICard {
  isActive: boolean
}

export const Card = styled(CardCmp)<ICard>`
  ${({ isActive, theme }) =>
    isActive
      ? null
      : css`
          background-color: ${theme.colors.graytheme1};
        `}
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  justify-content: space-around;
  &:nth-child(3n) {
    margin-right: 0px;
  }
  &:nth-child(n + 3) {
    margin-top: 0px;
  }
  &:nth-child(n + 4) {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const StoresWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  padding: 48px 0;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;

  > button {
    :first-child {
      margin-right: 16px;
    }
  }
`

export const Pagination = styled(PaginationCmp)``

export const ButtonLink = styled(ButtonLinkCmp)``

export const Modal = styled(ModalCmp)`
  > div > div {
    overflow-y: hidden;
  }
`

export const ModalContainer = styled.div`
  width: 800px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${ButtonsWrapper} {
    margin-top: 56px;
    width: 30%;
  }
`

export const UpperWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  margin: 0 -64px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};

  > * {
    :first-child {
      width: 370px;
      margin-right: 32px;
    }
    :nth-child(2) {
      width: 390px;
    }
  }
`

export const InputText = styled(InputTextCmp)`
  color: ${({ theme }) => theme.colors.graytheme6};
`

export const RadioButtonWrapper = styled.div`
  display: flex;
  padding-top: 12px;

  > * {
    :first-child {
      padding-right: 8px;
    }
  }
`

export const BottomWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px 16px;

  > * {
    :nth-child(1),
    :nth-child(2),
    :nth-child(3) {
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }
  }

  > * {
    :first-child,
    :nth-child(4),
    :nth-child(5),
    :nth-child(6) {
      width: 203px;
    }

    :nth-child(2) {
      width: 438px;
    }
    :nth-child(3),
    :nth-child(7) {
      width: 80px;
    }

    margin: 0px 16px;
  }
`

export const SelectState = styled(SelectCmp)`
  > div > div > p {
    color: ${({ theme }) => theme.colors.graytheme6};
  }
`
