import { useNonInitialEffect } from 'hooks/utils'
import React, { useEffect } from 'react'
import { ConciliationTasksFilter, ConciliationTasksView } from './index'

interface IProps {
  isOpen: boolean
  handleClose: () => any
}

export const ConciliationTasksModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
}) => {
  const _handleClose = () => {
    setCurrentStep(1)
    handleClose()
  }
  const [currentStep, setCurrentStep] = React.useState<number>(1)
  const [filters, setFilters] = React.useState()

  useNonInitialEffect(() => {
    setCurrentStep(2)
  }, [filters])

  if (currentStep === 1)
    return (
      <ConciliationTasksFilter
        handleClose={_handleClose}
        setFilters={setFilters}
        isOpen={isOpen}
      />
    )
  else if (currentStep === 2)
    return (
      <ConciliationTasksView
        handleClose={_handleClose}
        filters={filters}
        isOpen={isOpen}
      />
    )
}
