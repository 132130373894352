import styled, { css } from 'styled-components'
import IconCmp from 'components/IconExporter'
import TextCmp from 'components/Text'

const inputDefaultStyle = css`
  align-items: center;
  display: flex;
  border-radius: 8px;

  height: 100%;
  padding: ${({ theme }) => `0 ${theme.spacing(1)}px`};
  transition: all 0.15s ease-in;

  #prefix {
    padding-left: ${({ theme }) => `${theme.spacing(1)}px`};
  }
  #suffix {
    padding-right: ${({ theme }) => `${theme.spacing(1)}px`};
  }
  :focus-within {
    box-shadow: 0px 1px 8px 3px #5caae550;
    #prefix,
    #suffix {
      path {
        transition: all 0.15s ease-in;
        fill: ${(props) => props.theme.colors.maincolor};
      }
    }
  }
`

const inputErrorStyle = css`
  border: 2px solid ${(props) => props.theme.colors.lightred1};
  color: ${(props) => props.theme.colors.lightred1};
  :focus-within {
    border: 2px solid ${(props) => props.theme.colors.lightred1};
  }
`

export const Icon = styled(IconCmp)``

export const Container = styled.div`
  .react-datepicker-popper {
    z-index: 3;
    padding-top: 4px;
  }
  .react-datepicker__header {
    background-color: #fbfcfd;
    border-radius: 16px 16px 0 0;
  }

  .react-datepicker {
    background-color: #fbfcfd;
    box-shadow: 0px 1px 8px 2px rgba(92, 170, 229, 0.3);
    border-radius: 16px;
    padding: 5px;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    color: #6b8599;
    font-size: 16px;
    width: 1.8rem;
  }

  .react-datepicker__day--today,
  .react-datepicker__current-month {
    color: #475966;
    font-weight: bold;
    font-size: 18px;
  }

  .react-datepicker__day--selected {
    background-color: #027ad3;
    color: white;
  }

  .react-datepicker__triangle {
    display: none;
  }
`

interface IDatepickerProps {
  disabled?: boolean
  width?: string | number
  error?: boolean | { icon?: string; message: string }
}
export const DatepickerWrapper = styled.div<IDatepickerProps>`
  ${inputDefaultStyle}
  height: 40px;
  width: ${(props) => (props.width ? props.width + 'px' : '18rem')};
  border-radius: 8px;
  font: ${({ theme }) => theme.fontTypes.headline};
  box-shadow: ${({ disabled }) =>
    disabled ? 'none' : '0px 1px 8px 2px #B8CBD9'};
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.graytheme1 : theme.colors.white};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: ${(props) => props.width}px;
  ${(props) => props.error && inputErrorStyle}
  position: relative;
  :hover {
    box-shadow: ${({ disabled }) =>
      disabled ? 'none' : '0px 1px 8px 3px rgba(92, 170, 229, 0.5);'};
  }
`
export const DatepickerInput = styled.input`
  font: inherit;
  border: none;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  padding: ${({ theme }) => `0 ${theme.spacing(1)}px`};

  &:-webkit-autofill {
    box-shadow: none;
    -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.colors.gray1} inset;
  }

  :disabled {
    cursor: not-allowed;
    background-color: inherit;
    color: ${({ theme }) => theme.colors.black};
  }
`

export const ErrorMessage = styled.span`
  bottom: 0;
  color: ${(props) => props.theme.colors.yellow1};
  display: flex;
  width: 100%;
  background-color: blue;
  position: absolute;
  top: 40px;
`

export const ErrorIcon = styled(IconCmp)`
  fill: ${(props) => props.theme.colors.yellow1};
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`

export const TextError = styled(TextCmp).attrs(() => ({
  type: 'title',
}))`
  height: 10px;
  margin-bottom: 0.2em;
  font-size: 10px;
  font-weight: 400;
`

export const SquareDateInput = styled.input`
  width: 80px;
  height: 20px;
  border: none;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.lightmaincolor2};
  box-shadow: 0px 1px 8px 1px #cce9ff;

  :hover {
    box-shadow: 0px 1px 8px 2px rgba(92, 170, 229, 0.3);
  }

  :focus {
    border: 2px solid ${({ theme }) => theme.colors.maincolor};
  }

  ::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`

export const LabelText = styled(TextCmp)`
  margin-bottom: 8px;
`
