import { getValueFromLocalStorage } from "helpers/localStorage";
import React, { useEffect, useState } from "react"
import { PrePaymentPreRegisterTwo, 
         PrePaymentWelcome,
         PrePaymentSimulation,
         PrePaymentConclusion,
         PrePaymentPreRegisterOne } from "./index"

interface IProps {
  isOpen: string
  handleClose: ()=> any
}


export const PrePaymentModal: React.FC<IProps> = ({ isOpen, handleClose })=> {
 
  const [currentStep, setCurrentStep] = React.useState<number>(1)
  const [simulationData, setSimulationData] = React.useState<number>()
  const _setCurrentStep = (nextStep) => {
    setCurrentStep(nextStep)
  }

    
  useEffect(() => {
    if(getValueFromLocalStorage('hasAntecipationInProgress') === 'true')
      _setCurrentStep(5)
  }, [])

  if(currentStep === 1) 
    return(<PrePaymentWelcome 
      handleClose={handleClose} 
      setCurrentStep={_setCurrentStep} 
      isOpen={isOpen}/>
    )
  else if(currentStep === 2) {
    return(
      <PrePaymentSimulation
        handleClose={handleClose} 
        setCurrentStep={_setCurrentStep} 
        setSimulationData={setSimulationData}
        isOpen={isOpen}/>
    )
  } if(currentStep === 3) {
    return(
      <PrePaymentPreRegisterOne
        handleClose={handleClose} 
        setCurrentStep={_setCurrentStep} 
        simulationData={simulationData}
        isOpen={isOpen}/>
    )
  } if(currentStep === 4) {
    return(
      <PrePaymentPreRegisterTwo
        handleClose={handleClose} 
        setCurrentStep={_setCurrentStep} 
        isOpen={isOpen}/>
    )
  } if(currentStep === 5) {
    return(
      <PrePaymentConclusion
        handleClose={handleClose} 
        setCurrentStep={_setCurrentStep} 
        isOpen={isOpen}/>
    )
   }
}



