import {
  baseURLRegistrationV2DestinationAccounts,
  get,
  patch,
} from 'services/api'
import { showToast } from 'components/Toast'
import React from 'react'
import { applyCpfCnpjMask } from 'helpers/masks'

import * as s from '../styles'
import { AddSplitAccount } from './AddSplitAccount'
import { EditSplitAccount } from './EditSplitAccount'

interface IAccountList {
  total: number
  data: IAccount[]
}

interface IAccount {
  id: string
  name: string
  pix_dict_key: string
  entity_type: string
}

export interface IEditAccountFields {
  id: string
  name: string
  cpf_cnpj: string
  pix_dict_key: string
}

export const PixSplitAccountList: React.FC = () => {
  const [splitAccounts, setSplitAccounts] = React.useState<IAccountList>({
    total: -1,
    data: [],
  })
  const [offset, setOffset] = React.useState('0')
  const [newAccountModal, setNewAccountModal] = React.useState(false)
  const [editAccountModal, setEditAccountModal] = React.useState(false)
  const [editSplitAccount, setEditSplitAccount] = React.useState(
    {} as IEditAccountFields
  )
  const [isLoading, setIsLoading] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)

  const getSplitAccounts = async (offset: string) => {
    setIsLoading(true)
    await get(
      `${baseURLRegistrationV2DestinationAccounts}?limit=5&offset=${offset}`
    )
      .then((res) => {
        setSplitAccounts(res.data)
      })
      .catch((e) => {
        setHasError(true)
        showToast({
          type: 'error',
          message: JSON.parse(e.request.response).detail,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getReceivingAccount = async (accountId: string) => {
    if (accountId) {
      await get(`${baseURLRegistrationV2DestinationAccounts}/${accountId}`)
        .then((res) => {
          setEditSplitAccount(res.data)
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: JSON.parse(e.request.response).detail,
          })
        })
    }
  }

  const deleteSplitAccount = async (accountId: string) => {
    await patch(`${baseURLRegistrationV2DestinationAccounts}/${accountId}`, {
      active: false,
    })
      .then((res) => {
        showToast({
          type: 'success',
          message: 'Conta removida',
        })
        getSplitAccounts(offset)
      })
      .catch((e) => {
        showToast({
          type: 'error',
          message: JSON.parse(e.request.response).detail,
        })
      })
  }

  React.useEffect(() => {
    getSplitAccounts(offset)
  }, [offset])

  const showFeeSplitterEditModal = (accountId: string) => {
    setEditAccountModal(true)
  }

  const editAccountButton = ({ children, index }) => {
    const selectedAccount = splitAccounts.data[index] || null
    return (
      <s.Button
        onClick={(evt) => {
          evt.stopPropagation()
          showFeeSplitterEditModal(selectedAccount.id)
          getReceivingAccount(selectedAccount.id)
        }}
        color="maincolor"
      >
        <s.Icon name="pencil" fill="white" />
        <s.Text color="white" bold>
          Editar
        </s.Text>
      </s.Button>
    )
  }

  const renderType = ({ children }) => {
    switch (children) {
      case 'pos':
        return <s.Text>Automação</s.Text>
      case 'reseller':
        return <s.Text>Revenda</s.Text>
      default:
        return <s.Text>{children}</s.Text>
    }
  }

  const removeAccountButtonRender = ({ children, index }) => {
    const selectedAccount = splitAccounts.data[index] || null
    return selectedAccount.entity_type !== 'pos' ? (
      <s.Button
        onClick={(evt) => {
          evt.stopPropagation()
          deleteSplitAccount(selectedAccount.id)
        }}
        color="graytheme4"
      >
        <s.Text color="white" bold>
          Deletar
        </s.Text>
      </s.Button>
    ) : null
  }

  const renderSplitAccounts = () => {
    if (splitAccounts.total > -1) {
      return (
        <>
          <AddSplitAccount
            isOpen={newAccountModal}
            handleClose={() => {
              setNewAccountModal(false)
              getSplitAccounts(offset)
            }}
          />
          <s.PixSplitAccountListCard>
            <s.Row>
              <s.Text type="heading3" bold>
                Contas de recebimento Cadastradas
              </s.Text>
              <s.AddAccountButton
                height={'40px'}
                width={'150px'}
                color="white"
                outline
                onClick={() => {
                  setNewAccountModal(true)
                }}
              >
                <s.Icon name="addcustomer" color="maincolor" />
                <s.Text bold color="maincolor">
                  Nova Conta
                </s.Text>
              </s.AddAccountButton>
            </s.Row>
            <s.Table
              data={splitAccounts.data}
              isLoading={isLoading}
              columns={[
                { headerName: 'Conta', objectName: 'name' },
                { headerName: 'Chave Pix', objectName: 'pix_dict_key' },
                {
                  headerName: 'CNPJ',
                  objectName: 'cpf_cnpj',
                  AltRender: (x: {
                    children: React.ReactNode | React.ReactNode[]
                  }) => {
                    return <s.Text>{applyCpfCnpjMask(x.children)}</s.Text>
                  },
                },
                {
                  headerName: 'Tipo',
                  objectName: 'entity_type',
                  AltRender: renderType,
                },
                {
                  headerName: 'Editar',
                  objectName: '',
                  AltRender: editAccountButton,
                },
                {
                  headerName: 'Deletar',
                  objectName: '',
                  AltRender: removeAccountButtonRender,
                },
              ]}
              customEmptyMessage="Não há contas cadastradas! Cadastre uma nova conta de recebimento para continuar"
            />
            <EditSplitAccount
              data={editSplitAccount}
              isOpen={editAccountModal}
              handleClose={() => {
                setEditAccountModal(false)
                getSplitAccounts(offset)
              }}
            />
            {splitAccounts.total > 0 ? (
              <s.Pagination
                count={5}
                offset="0"
                total={splitAccounts.total}
                handleChange={(offset) => {
                  setOffset(offset)
                }}
              />
            ) : null}
          </s.PixSplitAccountListCard>
        </>
      )
    } else if (hasError) {
      return (
        <s.Container>
          <s.WrapperText>
            <s.Text bold type="heading3" color="maincolor">
              Ocorreu um erro, tente novamente mais tarde
            </s.Text>
          </s.WrapperText>
        </s.Container>
      )
    }
  }

  return <>{renderSplitAccounts()}</>
}
