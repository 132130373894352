import styled from 'styled-components'
import TextCmp from 'components/Text'

export const Wrapper = styled.div`
  padding-left: 10px;
`

export const KeyValue = styled.div``

export const Key = styled(TextCmp)`
  margin-top: 10px;
`
export const Value = styled(TextCmp)``

export const Header = styled(TextCmp)`
  padding: 12px;
  cursor: pointer;
  > span {
    display: block;
    font-weight: 500;
    font-size: 18px;
  }
`