import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { get, patch } from 'services/api'
import { baseURLCheckSignature, baseURLResetPassword } from 'services/api'
import { isValidPassword } from 'helpers/validators'
import { isConexaoItau, isSpacePay, isTotvs } from 'helpers'
import { useTheme } from 'styled-components'
import loadingbigger from 'assets/images/loading-bigger.png'

import * as s from './styles'

const ResetPassword: React.FC = () => {
  const { id }: { id: string } = useParams()
  const history = useHistory()
  const [newPassword, setNewPassword] = React.useState('')
  const [confirmNewPassword, setConfirmNewPassword] = React.useState('')
  const [step, setStep] = React.useState<'form' | 'loading' | 'success'>(
    'loading'
  )
  const theme = useTheme()

  const [infoError, setInfoError] = React.useState({
    title: '',
    message: <></>,
  })

  const [emptyInputError, setEmptyInputError] = React.useState({
    newPassword: false,
    confirmNewPassword: false,
  })
  const [visiblePasswords, setVisiblePasswords] = useState(false)

  const handleTogglePasswordVisibility = () => {
    setVisiblePasswords((prev) => !prev)
  }

  const InfoBox: React.FC<{
    error?: { title: string; message: React.ReactElement }
  }> = ({ error }) => {
    if (error?.title) {
      return (
        <s.InfoBox error>
          <s.Text bold type="paragraph" color="redshipay">
            {error.title}
          </s.Text>
          <s.Text type="paragraph" color="cancelledStatusTextColor">
            {error.message}
          </s.Text>
        </s.InfoBox>
      )
    }
    return <></>
  }

  const checkSignature = async (id) => {
    setStep('loading')
    await get(`${baseURLCheckSignature}/${id}`)
      .then(() => {
        setInfoError({
          title: '',
          message: <></>,
        })
        setStep('form')
      })
      .catch((e) => {
        setStep('form')
        setInfoError({
          title: 'Algo deu errado',
          message: <>{JSON.parse(e.request.response).message}</>,
        })
        setTimeout(() => {
          history.push('/login')
        }, 2000)
      })
  }

  const resetPassword = async (newPassword, id) => {
    if (
      isValidPassword(newPassword) &&
      arePasswordsMatching(newPassword, confirmNewPassword)
    ) {
      setStep('loading')
      await patch(baseURLResetPassword, {
        password: newPassword,
        signature: id,
      })
        .then(() => {
          setInfoError({
            title: '',
            message: <></>,
          })
          setStep('success')
        })
        .catch((e) => {
          setStep('form')
          setInfoError({
            title: 'Algo deu errado',
            message: <>{JSON.parse(e.request.response).message}</>,
          })
        })
    }
  }

  const formatAnyPasswordMatchErrors = (
    messages: string[],
    numberOfErrorMsgs: number
  ) => {
    return (
      <>
        {`É necessário que sua senha tenha no mínimo `}
        {messages.map((message, index) => {
          if (index + 1 === numberOfErrorMsgs && numberOfErrorMsgs > 1)
            return (
              <React.Fragment key={index}>
                {' '}
                e <u>{message}</u>.
              </React.Fragment>
            )
          if (index + 1 === numberOfErrorMsgs && numberOfErrorMsgs === 1)
            return (
              <React.Fragment key={index}>
                <u>{message}</u>.
              </React.Fragment>
            )
          if (index === 0)
            return (
              <React.Fragment key={index}>
                <u>{message}</u>
              </React.Fragment>
            )
          return (
            <React.Fragment key={index}>
              , <u>{message}</u>
            </React.Fragment>
          )
        })}
      </>
    )
  }

  const isFormValid = () => {
    let errorTitle = ''
    let errorMessage = <></>
    let newPasswordEmptyError = false
    let confirmNewPasswordEmptyError = false
    let anyPasswordMatchError = false
    let errorMessages = []

    const bothPasswordsAreEmpty = !newPassword && !confirmNewPassword

    const validatePassword = () => {
      if (!newPassword) {
        errorTitle = 'Campo obrigatório não preenchido'
        errorMessage = (
          <>
            Preencha o campo de <u>Nova senha</u> para prosseguir.
          </>
        )
        newPasswordEmptyError = true
      }

      if (!confirmNewPassword) {
        errorTitle = 'Campo obrigatório não preenchido'
        errorMessage = (
          <>
            Preencha o campo de <u>Confirmar senha</u> para prosseguir.
          </>
        )

        confirmNewPasswordEmptyError = true
      }

      if (bothPasswordsAreEmpty) {
        errorTitle = 'Campos obrigatórios não preenchidos'
        errorMessage = (
          <>
            Preencha os campos de <u>Nova senha</u> e <u>Confirmar senha</u>{' '}
            para prosseguir.
          </>
        )
        newPasswordEmptyError = true
        confirmNewPasswordEmptyError = true
        return
      }

      if (newPassword && confirmNewPassword && newPassword.length < 6) {
        errorTitle = 'Critérios de senha não atendidos.'
        errorMessages.push('6 caracteres')
        anyPasswordMatchError = true
      }

      if (newPassword && confirmNewPassword && !/[A-Z]/.test(newPassword)) {
        errorTitle = 'Critérios de senha não atendidos.'
        errorMessages.push('1 caracter em caixa alta')
        anyPasswordMatchError = true
      }

      if (newPassword && confirmNewPassword && !/[\d\W]/.test(newPassword)) {
        errorTitle = 'Critérios de senha não atendidos.'
        errorMessages.push('1 caracter especial ou número')
        anyPasswordMatchError = true
      }

      if (newPasswordEmptyError || confirmNewPasswordEmptyError) {
        return
      } else if (
        !arePasswordsMatching(newPassword, confirmNewPassword) &&
        !bothPasswordsAreEmpty
      ) {
        errorMessage = <></>
        errorTitle = 'Senhas diferentes.'
        errorMessage = <>Confira as informações e tente novamente.</>
        return
      } else if (
        !bothPasswordsAreEmpty &&
        arePasswordsMatching(newPassword, confirmNewPassword) &&
        anyPasswordMatchError
      ) {
        const numberOfErrorMsgs = errorMessages.length
        errorMessage = formatAnyPasswordMatchErrors(
          errorMessages,
          numberOfErrorMsgs
        )
      } else {
        errorTitle = ''
        errorMessage = <></>
        newPasswordEmptyError = false
        confirmNewPasswordEmptyError = false
      }
    }

    validatePassword()

    setInfoError({
      title: errorTitle,
      message: errorMessage,
    })

    setEmptyInputError({
      ...emptyInputError,
      newPassword: newPasswordEmptyError,
      confirmNewPassword: confirmNewPasswordEmptyError,
    })

    return (
      !newPasswordEmptyError &&
      !confirmNewPasswordEmptyError &&
      !anyPasswordMatchError
    )
  }

  const arePasswordsMatching = (password: string, confirmPassword: string) => {
    return password === confirmPassword
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (isFormValid()) {
        resetPassword(newPassword, id)
      }
    }
  }

  React.useEffect(() => {
    checkSignature(id)
  }, [])

  const handleHeader = () => {
    if (isSpacePay()) {
      return <s.Image src={theme.assets.mainLogo} alt="ndd logo" />
    }
    return (
      <>
        {isTotvs() ? (
          <div style={{ height: '100px' }}></div>
        ) : (
          <>
            <img src={theme.assets.mainLogo} alt={`${theme.domain} Logo`} />
            <s.Text>
              <b>{isConexaoItau() ? 'Painel Conexão Itaú' : 'Painel Shipay'}</b>{' '}
              para melhorar a sua experiência com
              <b> o Pix e múltiplas carteiras digitais.</b>
            </s.Text>
          </>
        )}
      </>
    )
  }

  const handleCardContent = () => {
    if (step === 'loading') {
      return (
        <s.Card data-testid="content-on-loading" width={584} height={469}>
          <s.CardWrapper>
            <s.TitleWrapper>
              <s.Text
                margin="24px 0 32px 8px"
                textAlign="left"
                color="graytheme6"
                bold
                type="headline"
              >
                Alterar senha
              </s.Text>
            </s.TitleWrapper>
            <s.PasswordWrapper>
              <s.LoadingGradient>
                <img width={114} src={loadingbigger} />
              </s.LoadingGradient>
            </s.PasswordWrapper>
            <s.ButtonWrapper></s.ButtonWrapper>
          </s.CardWrapper>
        </s.Card>
      )
    }
    if (step === 'success') {
      return (
        <s.Card data-testid="content-on-success" width={584} height={469}>
          <s.CardWrapper>
            <s.TitleWrapper>
              <s.Text
                margin="24px 0 32px 8px"
                textAlign="left"
                color="graytheme6"
                bold
                type="headline"
              >
                Alterar senha
              </s.Text>
            </s.TitleWrapper>
            <s.PasswordWrapper>
              <s.AlignStart>
                <s.Text
                  fontWeight={600}
                  type="heading4"
                  bold
                  color="graytheme6"
                >
                  Senha alterada com sucesso!
                </s.Text>
              </s.AlignStart>

              {isTotvs() || isSpacePay() ? (
                <div style={{ height: '100px' }}></div>
              ) : (
                <s.Text margin="32px 0 0 0" type="paragraph">
                  Agora é possível fazer login com sua nova senha para acessar o
                  {isConexaoItau()
                    ? ' Painel Conexão Itaú.'
                    : ' Painel Shipay.'}
                </s.Text>
              )}
            </s.PasswordWrapper>
            <s.ButtonWrapper>
              <s.Button
                onClick={(e) => {
                  e.preventDefault()
                  history.push('/login')
                }}
                data-testid="button-go-to-login"
                color="primary"
                width="100%"
              >
                <s.Text margin="0 8px 0 0" fontWeight={600} color="white">
                  Fazer login
                </s.Text>
                <s.Icon name="arrowright" color="white" />
              </s.Button>
            </s.ButtonWrapper>
          </s.CardWrapper>
        </s.Card>
      )
    }
    return (
      <s.Card
        data-testid="content-on-form"
        isFlipped={step === 'form'}
        width={584}
        height={469}
      >
        <s.CardWrapper>
          <s.TitleWrapper>
            <s.Text
              margin="24px 0 32px 8px"
              textAlign="left"
              color="graytheme6"
              bold
              type="headline"
            >
              Alterar senha
            </s.Text>
          </s.TitleWrapper>
          <s.PasswordWrapper>
            {infoError.title ? <InfoBox error={infoError} /> : <></>}
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                data-testid="new-password"
                color={emptyInputError.newPassword ? 'redshipay' : 'graytheme6'}
              >
                Nova senha
              </s.Text>
              <s.InputTextPassword
                testId="input-new-password"
                height={40}
                width={304}
                value={newPassword}
                hideEyeIcon={true}
                onKeyDown={handleKeyDown}
                enableShowPasswordOuterButton={{
                  visibled: visiblePasswords,
                  onClick: handleTogglePasswordVisibility,
                }}
                error={!!emptyInputError.newPassword}
                onChange={(e) => {
                  setEmptyInputError({
                    ...emptyInputError,
                    newPassword: false,
                  })

                  setNewPassword(e.target.value)
                }}
                placeholder="Nova senha"
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  emptyInputError.confirmNewPassword
                    ? 'redshipay'
                    : 'graytheme6'
                }
              >
                Confirmar senha
              </s.Text>
              <s.InputTextPassword
                testId="input-confirm-new-password"
                height={40}
                width={304}
                value={confirmNewPassword}
                hideEyeIcon={true}
                onKeyDown={handleKeyDown}
                enableShowPasswordOuterButton={{
                  visibled: visiblePasswords,
                  onClick: handleTogglePasswordVisibility,
                }}
                error={!!emptyInputError.confirmNewPassword}
                onChange={(e) => {
                  setEmptyInputError({
                    ...emptyInputError,
                    confirmNewPassword: false,
                  })
                  setConfirmNewPassword(e.target.value)
                }}
                placeholder="Confirme a nova senha"
              />
            </s.SectionLine>
            <s.SectionLine>
              <div></div>
              <s.PasswordRulesWrapper>
                <s.Text margin="0 0 0 16px" color="graytheme6" type="body">
                  <s.List>
                    <s.Item matched={newPassword && newPassword.length >= 6}>
                      Mínimo de 6 caracteres
                    </s.Item>
                    <s.Item matched={newPassword && /[A-Z]/.test(newPassword)}>
                      1 caracter em caixa alta
                    </s.Item>
                    <s.Item matched={newPassword && /[\d\W]/.test(newPassword)}>
                      1 caracter especial ou número
                    </s.Item>
                    <s.Item
                      matched={
                        newPassword &&
                        arePasswordsMatching(newPassword, confirmNewPassword)
                      }
                    >
                      Confirmação de senha igual
                    </s.Item>
                  </s.List>
                </s.Text>
                <s.AddCheckButton
                  data-testid="enable-password-visibility-button"
                  checked={visiblePasswords}
                  onClick={(e) => {
                    e.preventDefault()
                    handleTogglePasswordVisibility()
                  }}
                >
                  <s.CheckSquare>
                    <s.Icon name="checkmark" fill="white" />
                  </s.CheckSquare>
                  <s.Text fontWeight={600} type="body" color="maincolor">
                    Mostrar senha
                  </s.Text>
                </s.AddCheckButton>
              </s.PasswordRulesWrapper>
            </s.SectionLine>
          </s.PasswordWrapper>
          <s.ButtonWrapper>
            <s.Button
              onClick={(e) => {
                e.preventDefault()
                if (isFormValid()) {
                  resetPassword(newPassword, id)
                }
              }}
              data-testid="button-confirm-new-password"
              color="primary"
              width="100%"
            >
              <s.Text margin="0 8px 0 0" fontWeight={600} color="white">
                Confirmar nova senha
              </s.Text>
            </s.Button>
          </s.ButtonWrapper>
        </s.CardWrapper>
      </s.Card>
    )
  }

  return (
    <s.Background>
      <s.Container>
        <s.ChangePasswordWrapper>
          {handleHeader()}
          {handleCardContent()}
        </s.ChangePasswordWrapper>
      </s.Container>
    </s.Background>
  )
}

export default ResetPassword
