import React from 'react'
import { baseURLVerifyUsersV1, post } from 'services/api'

import * as s from './styles'
import { Steps } from './WalletSettingsFormTbanksCashout'

const INITIAL_STATE_ERRORS = {
  frontImage: false,
  backImage: false,
}

interface DocumentVerificationProps {
  step: Steps
  setStep: (step: Steps) => void
  docType: 'rg' | 'cnh' | ''
  submitDocuments: (front: File, back: File) => void
}

const KBADocumentVerification: React.FC<DocumentVerificationProps> = ({
  step,
  setStep,
  docType,
  submitDocuments,
}) => {
  const [frontImage, setFrontImage] = React.useState<File>()
  const [backImage, setBackImage] = React.useState<File>()
  const [brandIdFormError, setBrandIdFormError] =
    React.useState(INITIAL_STATE_ERRORS)

  return (
    <s.Content width={520} data-testid="content-on-user-document-verification">
      <s.HeaderStepsContainer>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="graytheme8">
            <s.NumberStep>1</s.NumberStep>
          </s.HeaderStepCircle>
          <s.Text>Introdução</s.Text>
        </s.HeaderStepWrapper>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="graytheme8">
            <s.NumberStep>2</s.NumberStep>
          </s.HeaderStepCircle>
          <s.Text>Questionário</s.Text>
        </s.HeaderStepWrapper>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="navbarvector1">
            <s.NumberStep>3</s.NumberStep>
          </s.HeaderStepCircle>
          <s.Text>Documento</s.Text>
        </s.HeaderStepWrapper>
      </s.HeaderStepsContainer>
      <s.Text margin="64px 0 16px 0" color="graytheme6" bold type="heading4">
        Selecione arquivos de imagem
        {docType === 'rg' ? ' do seu RG' : ' da sua CNH'}
      </s.Text>
      <s.Text margin="0 0 32px 32px" color="graytheme6" type="headline">
        <ul>
          <li>tire seu documento do plástico</li>
          <li>coloque-o em um ambiente iluminado</li>
          <li>verifique se o texto está legível</li>
          <li>certifique-se de que a foto não está cortada ou tremida</li>
        </ul>
      </s.Text>
      <s.Text margin="0 0 16px 0" type="heading4" color="graytheme6">
        Frente {docType === 'rg' ? 'do RG' : 'da CNH'}
      </s.Text>
      <s.UploadImageFile
        key={`${frontImage}-upload-front-image`}
        value={frontImage}
        handleChange={(e: File) => {
          setFrontImage(e)
        }}
        type="logo"
        data-testid="upload-front-logo"
        required={brandIdFormError.frontImage}
        hasFileAlready={!!frontImage}
        clear={() => setFrontImage(null)}
      />
      <s.Text margin="0 0 16px 0" type="heading4" color="graytheme6">
        Verso {docType === 'rg' ? 'do RG' : 'da CNH'}
      </s.Text>
      <s.UploadImageFile
        key={`${backImage}-upload-back-image`}
        value={backImage}
        handleChange={(e: File) => {
          setBackImage(e)
        }}
        type="logo"
        data-testid="upload-back-logo"
        required={brandIdFormError.backImage}
        hasFileAlready={!!backImage}
        clear={() => setBackImage(null)}
      />
      <s.PreviousNextButtonWrapper>
        <s.Button
          data-testid="back-button"
          onClick={() => {
            setStep({ ...step, userVerificationDocumentSelection: true })
          }}
          width="304px"
          height="42px"
          color="whitedetailed"
        >
          <s.Icon name="arrowleft" fill="maincolor" />
          <s.Text color="graytheme8" type="headline">
            Voltar
          </s.Text>
        </s.Button>
        <s.Button
          data-testid="verify-documents-button"
          width="304px"
          height="42px"
          disabled={!!!frontImage?.size || !!!backImage?.size}
          onClick={() => {
            submitDocuments(frontImage, backImage)
          }}
        >
          <s.Text margin="0 8px 0 0" color="white" type="headline">
            Verificar documento
          </s.Text>
        </s.Button>
      </s.PreviousNextButtonWrapper>
    </s.Content>
  )
}

export default KBADocumentVerification
