import {
  ActionInterface,
  Types,
  PixWSState,
  ResponseInterface,
  GetPixWSResponseInterface,
} from './types'

const INITIAL_STATE: PixWSState = {
  pixWS: {
    active: false,
    client_id: '',
    client_secret: '',
    default: false,
    dict_key: '',
    enabled_pix: false,
    id: '',
    name: '',
    pspProvider: '',
    tls_certificate: null,
    wallet_name: '',
  },
  getIsLoading: false,
  getError: false,
  patchResponse: { code: -1, message: '' },
  patchIsLoading: false,
  patchError: false,
  postResponse: { code: -1, message: '' },
  postIsLoading: false,
  postError: false,
}

const pixWSReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): PixWSState => {
  switch (action.type) {
    case Types.GET_PIX_WS:
      return {
        ...state,
        pixWS: action.payload as GetPixWSResponseInterface,
        getIsLoading: false,
        getError: false,
      }
    case Types.GET_PIX_WS_LOADING:
      return {
        ...state,
        getIsLoading: true,
        getError: false,
      }
    case Types.GET_PIX_WS_ERROR:
      return {
        ...state,
        getIsLoading: false,
        getError: true,
      }
    case Types.PATCH_PIX_WS:
      return {
        ...state,
        patchResponse: action.payload as ResponseInterface,
        patchIsLoading: false,
        patchError: false,
      }
    case Types.PATCH_PIX_WS_LOADING:
      return {
        ...state,
        patchIsLoading: true,
        patchError: false,
      }
    case Types.PATCH_PIX_WS_ERROR:
      return {
        ...state,
        patchIsLoading: false,
        patchError: true,
      }
    case Types.POST_PIX_WS:
      return {
        ...state,
        postResponse: action.payload as ResponseInterface,
        postIsLoading: false,
        postError: false,
      }
    case Types.POST_PIX_WS_LOADING:
      return {
        ...state,
        postIsLoading: true,
        postError: false,
      }
    case Types.POST_PIX_WS_ERROR:
      return {
        ...state,
        postIsLoading: false,
        postError: true,
      }
    default:
      return state
  }
}

export default pixWSReducer
