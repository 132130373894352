import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import useGetLogo from 'hooks/useGetLogo'

import * as s from './styles'

const RenderLogo = () => {
  const { logo } = useGetLogo()

  const theme = useTheme()

  const defaultImage = () => {
    if (theme.images.mainlogo || logo.pos_product_logo) {
      return <s.Image src={theme.images.mainlogo ?? logo.pos_product_logo} />
    }
    return (
      <s.ShipayLogo>
        <s.Icon name="shipaylogo" width={40} height={40} />
        <s.Icon name="shipaylogotitle" width={100} height={40} />
      </s.ShipayLogo>
    )
  }

  if (theme.domain === 'Shipay' && logo.is_logo_default) {
    return (
      <s.LogoWrapper>
        <s.ShipayLogo>
          <s.Icon name="shipaylogo" width={40} height={40} />
          <s.Icon name="shipaylogotitle" width={100} height={40} />
        </s.ShipayLogo>
      </s.LogoWrapper>
    )
  }
  if (theme.domain === 'Shipay' && !logo.is_logo_default) {
    return (
      <s.LogoWrapper>
        <s.Image src={logo.pos_product_logo} />
        <s.PoweredWrapper>
          <s.Text bold>Powered by</s.Text>
          <s.Icon name="fullShipayLogo" width={65} height={20} />
        </s.PoweredWrapper>
      </s.LogoWrapper>
    )
  }
  if (theme.domain === 'ConexãoItaú' && logo.is_logo_default) {
    return (
      <s.LogoWrapper>
        <s.Icon name="logoRede" width={195} height={40} />
      </s.LogoWrapper>
    )
  }
  if (theme.domain === 'ConexãoItaú' && !logo.is_logo_default) {
    return (
      <s.LogoWrapper>
        <s.Image src={logo.pos_product_logo} />
        <s.PoweredWrapper>
          <s.Text bold>Powered by</s.Text>
          <s.Icon name="logoRede" width={80} height={19} />
        </s.PoweredWrapper>
      </s.LogoWrapper>
    )
  }
  if (theme.domain === 'SpacePay') {
    return (
      <s.LogoWrapper>
        <s.Icon name="logoSpacePay" fill="black" width={195} height={40} />
      </s.LogoWrapper>
    )
  }
  return <s.LogoWrapper>{defaultImage()}</s.LogoWrapper>
}

export default () => {
  return <RenderLogo />
}
