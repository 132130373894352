import React from 'react'

import * as s from './Styles'

interface IProps {
  startDate: string
  endDate: string
  title: string
  dataCallback: (offset: number) => any
}


const ExportCsv: React.FC<IProps> = ({title, endDate, startDate, dataCallback}) => {
  const csvElement = React.useRef(null);
  const [data, setData] = React.useState<any>([]);

  return(
    <>
      <s.Csv
          filename={`${title}_de_${startDate}_ate_${endDate}`}
          style={{ display: "none" }}
          ref={csvElement}
          data={data}
      >
      </s.Csv>
      <s.ExportButton
        outline={true}
        color="blueshipay"
        onClick={async () => {
          setData(await dataCallback(0))
          csvElement.current.link.click();
        }}
      >   
        <s.Text color='maincolor'>exportar</s.Text>
      </s.ExportButton>   
    </>
  )
}

export default ExportCsv