import React, { forwardRef, useState } from 'react'
import * as s from './styles'
import ptBR from 'date-fns/locale/pt-BR'
import { parseISO } from 'date-fns'

import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

registerLocale('ptBR', ptBR)

const Datepicker = ({
  disabled = false,
  width = undefined,
  error = undefined,
  selected,
  onChange,
  type = 'rounded',
  placeholder = '',
  label,
  ...rest
}) => {
  const selectedDateToISO = selected
    ? parseISO(selected.split('/').reverse().join('-'))
    : ''
  const CustomDatepickerInput = forwardRef<HTMLInputElement>((props, ref) => {
    if (type === 'rounded') {
      return (
        <>
          <s.DatepickerWrapper disabled={disabled} width={width}>
            <s.DatepickerInput {...props} ref={ref} />
            <s.Icon name="calendar" width={30} height={30} fill="maincolor" />
          </s.DatepickerWrapper>
          {error && typeof error === 'object' && (
            <s.ErrorMessage>
              {error.icon ? (
                <s.ErrorIcon name={error.icon} width="16px" />
              ) : null}
              <s.TextError color="lightred1">{error.message}</s.TextError>
            </s.ErrorMessage>
          )}
        </>
      )
    } else if (type === 'square') {
      return (
        <>
          <s.SquareDateInput {...props} ref={ref} />
          {error && typeof error === 'object' && (
            <s.ErrorMessage>
              {error.icon ? (
                <s.ErrorIcon name={error.icon} width="16px" />
              ) : null}
              <s.TextError color="lightred1">{error.message}</s.TextError>
            </s.ErrorMessage>
          )}
        </>
      )
    }
  })

  return (
    <s.Container {...rest}>
      {label ? (
        <s.LabelText
          bold
          type="headline"
          color={error ? 'lightred1' : 'maincolor'}
        >
          {label}
        </s.LabelText>
      ) : null}
      <DatePicker
        selected={selectedDateToISO}
        onChange={onChange}
        dateFormat="dd/MM/yyyy"
        disabled={disabled}
        locale="ptBR"
        placeholderText={placeholder}
        customInput={<CustomDatepickerInput />}
      />
    </s.Container>
  )
}

export default Datepicker
