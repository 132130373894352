import styled from 'styled-components'

import { Container as ContainerCmp } from 'styled-bootstrap-grid'
import TextCmp from 'components/Text'
import InputTextCmp from 'components/Inputs/Text'
import IconExporter from 'components/IconExporter'
import CardCmp from 'components/Card'
import ButtonCmp from 'components/Button'
import LoadingCmp from 'components/Loading'
import RadioComponent from 'components/Inputs/RadioButton'
import TableCmp from 'components/Table'
import PaginationCmp from 'components/Pagination'
import ModalCmp from 'components/Modal'

export const Container = styled(ContainerCmp)`
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  max-width: 1800px;
  padding: 32px;
`
export const ManagementContainer = styled(ContainerCmp)`
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 1200px;
  padding: 32px;
  margin-left: 40px;
`

export const Text = styled(TextCmp)``

export const Pagination = styled(PaginationCmp)`
  bottom: 0px;
  margin-bottom: 0px;
`

export const Card = styled(CardCmp)`
  padding: 32px;
  display: inline-table;
`

export const PixSplitAccountListCard = styled(Card)`
  margin-bottom: 32px;
`

export const DestinationAccountCard = styled(Card)`
  margin-bottom: 32px;
  gap: 32px;
`

export const SplitFeeCard = styled(Card)`
  margin-bottom: 32px;
  gap: 24px;
`

export const InputGroup = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`

export const InputText = styled(InputTextCmp)``

export const Icon = styled(IconExporter)`
  margin-right: 8px;
`

export const WrapperText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Button = styled(ButtonCmp)``

export const ButtonGroup = styled.div`
  text-align: right;
  margin-top: 20px;
  > :first-child {
    margin-right: 40px;
  }
`
export const Loading = styled(LoadingCmp)``

export const Wrapper = styled.div`
  display: flex;
  gap: 32px;
  padding-top: 32px;
`

export const DestinationAccountCardWrapper = styled.div``

export const RadioButton = styled(RadioComponent)``

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 120px;
  height: 40px;

  border: 2px solid #ec7000;
  border-radius: 8px;
`

export const ComissionType = styled.div`
  display: flex;
  gap: 32px;
`

export const SplitListContainer = styled(ContainerCmp)`
  position: relative;
  padding: ${({ theme }) => theme.spacing(3)}px;
  height: 500px;
  border: 2px solid ${(props) => props.theme.colors.gray2};
  border-radius: 8px;
`
export const Row = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 20px;
`

export const AddAccountButton = styled(ButtonCmp)`
  position: absolute;
  right: 0px;
`

export const Table = styled(TableCmp)`
  * > th {
    :first-child {
      width: 20%;
    }
    :nth-child(2) {
      width: 25%;
    }
    :nth-child(3) {
      width: 20%;
    }
  }
`

export const Modal = styled(ModalCmp)`
  > div > div {
    overflow-y: hidden;
  }
`

export const ModalContainer = styled.div`
  width: 450px;
`
