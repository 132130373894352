import { showToast } from 'components/Toast'
import { useEffect, useState } from 'react'
import { baseURLReconciliationWorkflows, get } from 'services/api'
import { ConciliationWorkflowResponse } from 'services/conciliation'
import * as s from './styles'

interface IProps {
  isOpen: boolean
  filters: any
  handleClose: () => any
}

export const ConciliationTasksView: React.FC<IProps> = ({
  isOpen,
  handleClose,
  filters,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadedWorkflows, setLoadedWorkflows] =
    useState<ConciliationWorkflowResponse>()

  const getConciliationWorkflows = async (params) => {
    setIsLoading(true)
    await get(`${baseURLReconciliationWorkflows}`, {
      params: params || '',
    })
      .then((res) => {
        setLoadedWorkflows(res.data)
        setIsLoading(false)
      })
      .catch((e) => {
        showToast({
          type: 'error',
          message: `${JSON.parse(e.request.response).message}`,
        })
      })
  }

  useEffect(() => {
    if (isOpen) {
      Object.keys(filters).forEach((key) => {
        if (key === 'start_date' || key === 'end_date' || key === 'executed_at')
          filters[key] = filters[key].split('/').reverse().join('-')
      })
      getConciliationWorkflows(filters)
    }
  }, [isOpen])

  return (
    <s.Modal
      handleClose={handleClose}
      isOpen={isOpen ? true : false}
      modalSubtitle={'Tasks de conciliação'}
    >
      <s.ModalContentView>
        {!isLoading && loadedWorkflows && loadedWorkflows.executions ? (
          loadedWorkflows.executions.map((exec) => {
            return (
              <s.Card>
                <s.Text bold>PID: {exec.reconciliation_pid}</s.Text>
                <s.Text bold>PSP: {exec.pix_psp}</s.Text>
                <s.Text bold>Início: {exec.start_date}</s.Text>
                <s.Text bold>Fim: {exec.end_date}</s.Text>
                <>
                  {exec.content.map((step) => {
                    return (
                      <s.InnerCard>
                        <s.Text bold>Passo: {step.step}</s.Text>
                        <s.Text bold>Executado em: {step.executed_at}</s.Text>
                        <s.Text bold>
                          Detalhes:{' '}
                          {step.details.map((detail) => {
                            return <s.Detail>{detail}</s.Detail>
                          })}
                        </s.Text>
                      </s.InnerCard>
                    )
                  })}
                </>
              </s.Card>
            )
          })
        ) : (
          <s.Text>Loading...</s.Text>
        )}
      </s.ModalContentView>
    </s.Modal>
  )
}
