import React from 'react'
import MainStatusCard from '../components/MainStatusCard'
import { formatMoney } from 'helpers/masks'
import { baseURLReconciliation, get } from 'services/api'
import ReconciliationDuplicatedModal from './ReconciliationDuplicatedModal'

interface IProps {
  options: {
    start_date: string
    end_date: string
    wallet_setting_id: string
    consolidated_customer_value: string
  }
}

interface ConsolidatedValue {
  value_difference: string
  sum_orders_value: string
  discrepancy: boolean
  count_registers: number
  count_repetitions: number
  additional_payments_value: string
}

const ReconciliationDuplicated: React.FC<IProps> = ({ options }) => {
  const [detailsModal, setDetailsModal] = React.useState(false)
  const [
    duplicatedData,
    setDuplicatedData,
  ] = React.useState<ConsolidatedValue>()

  const getConsolidated = async (origin: string) => {
    await get(`${baseURLReconciliation}/consolidated`, {
      params: { ...options, origin: 'Duplicado' },
    }).then((res) => {
      setDuplicatedData(res.data)
    })
  }

  React.useEffect(() => {
    if (options?.wallet_setting_id) {
      getConsolidated('Duplicado')
    }
  }, [options])

  return (
    <>
      <ReconciliationDuplicatedModal
        totalValue={`+ ${formatMoney(
          duplicatedData?.additional_payments_value
        )}`}
        countRegisters={duplicatedData?.count_registers}
        countRepetitions={duplicatedData?.count_repetitions}
        options={options}
        isOpen={detailsModal}
        handleClose={() => {
          setDetailsModal(false)
        }}
      />
      <MainStatusCard
        label={'Pgtos. em Duplicidade'}
        status={duplicatedData?.discrepancy ? 'warning' : 'success'}
        value={formatMoney(duplicatedData?.sum_orders_value)}
        openModal={() => {
          setDetailsModal(true)
        }}
      />
    </>
  )
}

export default ReconciliationDuplicated
