import React, { useEffect } from 'react'
import Modal from 'components/Modal'
import { MessageBox, PixKeyInput } from './components'
import { isNotEmpty } from 'helpers/validators'
import { useNonInitialEffect } from 'hooks/utils'
import { PixWSInterface } from 'services/pixWalletSettings'
import { Partial } from 'helpers/partial'
import { usePostPixWS } from 'hooks/pixWS'
import { showToast } from 'components/Toast'
import { useGetClientCerts } from 'hooks/clientCert'
import * as s from './styles'
import { isSpacePay } from 'helpers'

interface IProps {
  isOpen: string
  handleClose: () => any
}

const PAG_SEG_DOC_URL =
  'https://shipay.freshdesk.com/support/solutions/articles/154000127011-pagseguro-pix-'

export const WalletSettingsFormPagSeg: React.FC<IProps> = ({
  isOpen,
  handleClose,
}) => {
  const [walletSettingsForm, setWalletSettingsForm] = React.useState({
    apelido: '',
    tls_certificate: '',
    chave_pix: '',
    client_id: '',
    client_secret: '',
  })

  const [walletSettingsFormErrors, setWalletSettingsFormErrors] =
    React.useState({
      apelido: '',
      tls_certificate: '',
      chave_pix: '',
      client_id: '',
      client_secret: '',
    })

  const { postResponse, postError, createPixWS } = usePostPixWS()
  const { certs, loadClientCerts } = useGetClientCerts()

  const [certSelectList, setCertSelectList] = React.useState<
    { id: string; text: string }[]
  >([])
  const [selectedCert, setSelectedCert] = React.useState({ id: '', name: '' })

  const selectedTypeId = React.useRef<number>(4)

  useEffect(() => {
    loadClientCerts({ limit: 1000 })
  }, [])

  useEffect(() => {
    if (certs && certs.data) {
      setCertSelectList(
        certs.data.map((x) => ({
          id: x.id,
          text: x.name,
          onClick: () => {
            setSelectedCert({ id: x.id, name: x.name })
          },
        }))
      )
    }
  }, [certs])

  useEffect(() => {
    if (selectedCert) {
      _setWalletSettingsForm({ tls_certificate: selectedCert.id })
    }
  }, [selectedCert])

  useNonInitialEffect(() => {
    handleClose()
  }, [postResponse])
  const handlePost = () => {
    const err = { ...walletSettingsFormErrors }
    for (const [key, value] of Object.entries(walletSettingsForm)) {
      if (!err[key]) err[key] = isNotEmpty(value) ? '' : 'Campo Obrigatório'
    }
    setWalletSettingsFormErrors(err)

    const errList = Object.values(err).filter((x) => x !== '')
    if (!errList || errList.length == 0) {
      let pixKey = walletSettingsForm.chave_pix.trim()
      const type = selectedTypeId.current

      // 2: CNPJ, 3: PhoneNumber
      if (type === 2 || type === 3) {
        pixKey =
          type === 3
            ? '+55' + pixKey.replace(/[^a-zA-Z0-9]/g, '')
            : pixKey.replace(/[^a-zA-Z0-9]/g, '')
      }

      const payload = {
        active: true,
        client_id: walletSettingsForm.client_id,
        client_secret: walletSettingsForm.client_secret,
        default: false,
        dict_key: pixKey,
        name: walletSettingsForm.apelido,
        psp_provider: 'pagseguro',
        tls_certificate: walletSettingsForm.tls_certificate,
        wallet_name: 'pix',
      } as Partial<PixWSInterface>
      createPixWS(payload)
    }
  }

  const _setWalletSettingsForm = (obj) => {
    setWalletSettingsForm({ ...walletSettingsForm, ...obj })
    Object.keys(obj).forEach((key) => {
      obj[key] = ''
      setWalletSettingsFormErrors({ ...walletSettingsFormErrors, ...obj })
    })
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={!!isOpen}
      modalSubtitle="Conta Pix"
    >
      <s.ModalContent>
        {!isSpacePay() ? <MessageBox url={PAG_SEG_DOC_URL} /> : <></>}
        <s.InputGroup>
          <s.InputText
            maxLength={100}
            label="Apelido"
            value={walletSettingsForm.apelido}
            onChange={(e) =>
              _setWalletSettingsForm({ apelido: e.target.value })
            }
            error={
              walletSettingsFormErrors.apelido
                ? { message: walletSettingsFormErrors.apelido }
                : false
            }
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.SelectTLS
            label="Certificado TLS"
            options={certSelectList}
            selected={selectedCert}
          />
        </s.InputGroup>
        <PixKeyInput
          value={walletSettingsForm.chave_pix}
          pixKeyErr={walletSettingsFormErrors.chave_pix}
          onPixKeyErr={(err) => {
            setWalletSettingsFormErrors({
              ...walletSettingsFormErrors,
              chave_pix: err,
            })
          }}
          selectedTypeId={selectedTypeId}
          onChange={(value) => _setWalletSettingsForm({ chave_pix: value })}
        />
        <s.InputGroup>
          <s.InputText
            maxLength={100}
            label="Client ID"
            value={walletSettingsForm.client_id}
            onChange={(e) =>
              _setWalletSettingsForm({ client_id: e.target.value })
            }
            error={
              walletSettingsFormErrors.client_id
                ? { message: walletSettingsFormErrors.client_id }
                : false
            }
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputText
            maxLength={100}
            label="Client Secret"
            value={walletSettingsForm.client_secret}
            onChange={(e) =>
              _setWalletSettingsForm({ client_secret: e.target.value })
            }
            error={
              walletSettingsFormErrors.client_secret
                ? { message: walletSettingsFormErrors.client_secret }
                : false
            }
          />
        </s.InputGroup>
        <s.ButtonGroup>
          <s.Button onClick={() => handlePost()}>Cadastrar</s.Button>
          <s.Button color="tertiary" onClick={() => handleClose()}>
            Cancelar
          </s.Button>
        </s.ButtonGroup>
      </s.ModalContent>
    </Modal>
  )
}
