import React, { SetStateAction } from 'react'

import * as s from './styles'

interface IStepper {
  numberOfSteps: number
  currentStep: number
  setCurrentStep: React.Dispatch<SetStateAction<number>>
  stepValidators: boolean[]
}

const Stepper: React.FC<IStepper> = ({
  numberOfSteps,
  currentStep,
  setCurrentStep,
  stepValidators,
}) => {
  const renderSteps = () => {
    let steps = Array.from(Array(numberOfSteps), (step, index) => index + 1)
    return (
      <>
        {steps.map((step) => (
          <React.Fragment key={step}>
            <s.StepCircle
              data-testid={`step-circle-${step}`}
              onClick={() => {
                if (currentStep !== step) {
                  setCurrentStep(step)
                }
              }}
              isCurrentStep={currentStep === step}
            >
              {step}
            </s.StepCircle>
          </React.Fragment>
        ))}
      </>
    )
  }

  return (
    <s.StepperContainer>
      <s.StepperWrapper>{renderSteps()}</s.StepperWrapper>
    </s.StepperContainer>
  )
}

export default Stepper
