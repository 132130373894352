import { ActionInterface, Types, GeoAddressState } from './types'

const INITIAL_STATE: GeoAddressState = {
  geoAddress: {
    city: '',
    neighborhood: '',
    postal_code: '',
    state: '',
    street: '',
  },
  isLoading: false,
  hasError: false,
}

const geoAddressReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): GeoAddressState => {
  switch (action.type) {
    case Types.GET_GEO_ADDRESS:
      return {
        ...state,
        geoAddress: action.payload,
        isLoading: false,
        hasError: false,
      }
    case Types.GET_GEO_ADDRESS_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_GEO_ADDRESS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    case Types.RESET_GEO_ADDRESS:
      return {
        ...state,
        ...INITIAL_STATE,
      }
    default:
      return state
  }
}

export default geoAddressReducer
