import React from 'react'
import { useTheme } from 'styled-components'

import * as s from './styles'

const PixRegisteredWarningModal: React.FC<{
  handleClose: () => void
  isOpen: boolean
}> = ({ handleClose, isOpen }) => {
  const theme = useTheme()

  return (
    <s.Modal placeModalOnCenter handleClose={handleClose} isOpen={isOpen}>
      <s.ModalContainer>
        <s.Text type="heading4" color="maincolor">
          Você já cadastrou uma conta Pix para a sua primeira loja!
        </s.Text>
        <s.Text type="heading4" color="maincolor">
          Só é possível cadastrar uma conta por loja nesse momento.
        </s.Text>
        <s.Text type="heading4" color="maincolor">
          Você poderá cadastrar novas contas assim que acessar seu Painel
          {theme.domain === 'Shipay' ? ' Shipay' : ' Conexão Itaú'}.
        </s.Text>
        <s.Button width="200px" onClick={handleClose}>
          Ok
        </s.Button>
      </s.ModalContainer>
    </s.Modal>
  )
}

export default PixRegisteredWarningModal
