import { generateColorsPallete } from 'components/ColorPallete'
import { showToast } from 'components/Toast'
import { isNotEmpty, isNotEmptyStringOrFile } from 'helpers/validators'
import faviIconShipay from '../../../assets/images/shipay.png'

import React from 'react'
import {
  baseURLPosProductComponentsWlCustomDomain,
  baseURLPosProductComponentsWlCustomEmail,
  baseURLPosProductComponentsWlCustomImages,
  baseURLPosProductComponentsWlCustomPanel,
  get,
  patch,
  patchFormData,
  post,
  postFormData,
} from 'services/api'
import { useTheme } from 'styled-components'
import { useHistory } from 'react-router'

import * as s from './styles'
import { updateCustomBrandEmailAndPanel, updateFaviconAndLogo } from './helper'

const INITIAL_STATE_ERRORS = {
  color: false,
  url: false,
  tabName: false,
  logoImage: false,
}

const BrandIdentity: React.FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const hasTheme = !!theme.components_id

  const [color, setColor] = React.useState<string>(
    hasTheme ? theme.colors.maincolor : '#30C322'
  )
  const [url, setUrl] = React.useState<string>(
    hasTheme
      ? theme.domain.replace('www.', '').replace('.wl.shipay.com.br', '')
      : ''
  )
  const [tabName, setTabName] = React.useState<string>(
    hasTheme ? theme.pageTitle : ''
  )
  const [isDefaultFavicon, setIsDefaultFavicon] = React.useState<boolean>(false)
  const [isInitialDefaultFavicon, setInitialIsDefaultFavicon] =
    React.useState<boolean>(false)
  const [faviconImage, setFaviconImage] = React.useState<File>()
  const [initialFaviconImage, setInitialFaviconImage] = React.useState<File>()
  const [logoImage, setLogoImage] = React.useState<File>()
  const [initialLogoImage, setInitialLogoImage] = React.useState<File>()
  const [isLoading, setIsLoading] = React.useState(false)
  const [brandIdFormError, setBrandIdFormError] =
    React.useState(INITIAL_STATE_ERRORS)

  const checkIfHasChanged = React.useCallback(():
    | {
        color: boolean
        faviconImage: boolean
        logoImage: boolean
        tabName: boolean
        url: boolean
      }
    | { changed: boolean } => {
    const favicon = faviconImage || undefined
    const newMainColor = generateColorsPallete(color).hex.maincolor

    const hasChanged = {
      color: newMainColor !== theme.colors.maincolor,
      faviconImage:
        favicon?.size !== initialFaviconImage?.size ||
        favicon?.name !== initialFaviconImage?.name ||
        favicon?.type !== initialFaviconImage?.type,
      logoImage:
        logoImage?.size !== initialLogoImage?.size ||
        logoImage?.name !== initialLogoImage?.name ||
        logoImage?.type !== initialLogoImage?.type,
      tabName: tabName !== theme.pageTitle,
      url:
        url !==
        theme.domain.replace('www.', '').replace('.wl.shipay.com.br', ''),
    }

    return hasChanged
  }, [
    color,
    faviconImage,
    logoImage,
    initialFaviconImage,
    initialLogoImage,
    tabName,
    url,
  ])

  async function createFileForLogo() {
    let response = await fetch(theme.images.mainlogo)
    let data = await response.blob()
    let metadata = {
      type: `image/${theme.images.mainlogo.split('.').slice(-1)}`,
    }
    let file = new File(
      [data],
      `${theme.images.mainlogo.split('/').slice(-1)}`,
      metadata
    )
    setLogoImage(file)
    setInitialLogoImage(file)
  }

  async function createFileForFavicon() {
    let response = await fetch(theme.images.favicon)
    let data = await response.blob()
    let metadata = {
      type: `image/${theme.images.favicon.split('.').slice(-1)}`,
    }
    let file = new File(
      [data],
      `${theme.images.favicon.split('/').slice(-1)}`,
      metadata
    )
    if (file.name.includes('icon_shipay_default')) {
      setIsDefaultFavicon(true)
      setInitialIsDefaultFavicon(true)
    }
    setFaviconImage(file)
    setInitialFaviconImage(file)
  }

  React.useEffect(() => {
    if (hasTheme) {
      createFileForFavicon()
      createFileForLogo()
    }
  }, [])

  const createCustomBrand = async (payload) => {
    const { color, faviconImage, logoImage, tabName, url } = payload

    let images = new FormData()

    images.append('mainlogo', logoImage)

    if (isNotEmptyStringOrFile(faviconImage)) {
      images.append('favicon', faviconImage)
    }

    setIsLoading(true)

    await post(`${baseURLPosProductComponentsWlCustomDomain}`, {
      domain: url,
    })
      .then((res) => {
        const domainId = res.data.id

        get(`${baseURLPosProductComponentsWlCustomDomain}/${domainId}`)
          .then((res) => {
            const domainUrl = res.data.domain

            postFormData(`${baseURLPosProductComponentsWlCustomImages}`, {
              mainlogo: images.get('mainlogo'),
              favicon: images.get('favicon') || undefined,
              use_favicon_default: !!!images.get('favicon'),
            })
              .then((res) => {
                const faviconUrl = res.data.favicon || undefined
                const mainlogoUrl = res.data.mainlogo

                post(`${baseURLPosProductComponentsWlCustomPanel}`, {
                  domain: domainUrl,
                  images: {
                    mainlogo: mainlogoUrl,
                    favicon: faviconUrl,
                  },
                  pagetitle: tabName,
                  colors: {
                    ...generateColorsPallete(color).hex,
                  },
                })
                  .then((res) => {
                    const { maincolor, darkmaincolor1, lightmaincolor1 } =
                      generateColorsPallete(color).hex

                    post(`${baseURLPosProductComponentsWlCustomEmail}`, {
                      images: {
                        mainlogo: mainlogoUrl,
                      },
                      colors: {
                        maincolor,
                        darkmaincolor1,
                        lightmaincolor1,
                      },
                      support_contacts: {
                        faq: '',
                        phone: '',
                        email: '',
                      },
                      social_contacts: {
                        instagram: '',
                        facebook: '',
                        youtube: '',
                        linkedin: '',
                      },
                    })
                      .then((res) => {
                        setTimeout(() => history.go(0), 1000)
                      })
                      .catch((e) => {
                        showToast({
                          type: 'error',
                          message: JSON.parse(e.request.response).detail,
                        })
                      })

                    showToast({
                      type: 'success',
                      message: 'Tema Criado com sucesso',
                    })
                  })
                  .catch((e) => {
                    showToast({
                      type: 'error',
                      message: JSON.parse(e.request.response).detail,
                    })
                  })
              })
              .catch((e) => {
                showToast({
                  type: 'error',
                  message:
                    e.response.data.detail[0].msg || e.response.data.detail,
                })
              })
          })
          .catch((e) => {
            showToast({
              type: 'error',
              message: e.response.data.detail[0].msg || e.response.data.detail,
            })
          })
      })
      .catch((e) => {
        showToast({
          type: 'error',
          message: e.response.data.detail[0].msg || e.response.data.detail,
        })
      })

    setIsLoading(false)
  }

  const updateCustomBrand = async (payload: {
    color: string
    faviconImage: File
    logoImage: File
    tabName: string
    url: string
  }) => {
    const { color, faviconImage, logoImage, tabName, url } = payload

    const hasChanged = checkIfHasChanged() as {
      color: boolean
      faviconImage: boolean
      logoImage: boolean
      tabName: boolean
      url: boolean
    }

    let images = new FormData()
    images.append('mainlogo', logoImage)
    if (isNotEmptyStringOrFile(faviconImage)) {
      images.append('favicon', faviconImage)
    }

    setIsLoading(true)

    if (hasChanged.url) {
      await patch(
        `${baseURLPosProductComponentsWlCustomDomain}/${theme.components_id.domain}`,
        {
          domain: url,
        }
      )
        .then((res) => {
          get(
            `${baseURLPosProductComponentsWlCustomDomain}/${theme.components_id.domain}`
          )
            .then((res) => {
              const domainUrl = res.data.domain

              updateFaviconAndLogo({
                color: color,
                images,
                domainUrl,
                hasChanged,
                tabName: tabName,
                theme,
                history: history,
              })
            })

            .catch((e) => {
              showToast({
                type: 'error',
                message:
                  e.response.data.detail[0].msg || e.response.data.detail,
              })
            })
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: e.response.data.detail[0].msg || e.response.data.detail,
          })
        })
    } else if (hasChanged.faviconImage || hasChanged.logoImage) {
      updateFaviconAndLogo({
        color,
        domainUrl: '',
        hasChanged,
        images,
        tabName,
        theme,
        history: history,
      })
    } else if (hasChanged.color || hasChanged.tabName) {
      await updateCustomBrandEmailAndPanel({
        domainUrl: '',
        mainlogoUrl: '',
        faviconUrl: '',
        color: color ? color : '',
        tabName: tabName ? tabName : '',
        theme: theme,
        history: history,
      })
    }

    setIsLoading(false)
  }

  const handleSubmit = () => {
    const err = { ...brandIdFormError }
    for (const [key, value] of Object.entries({
      color,
      url,
      tabName,
      logoImage,
    })) {
      if (!isNotEmptyStringOrFile(value)) {
        err[key] = true
      } else {
        err[key] = false
      }
    }

    setBrandIdFormError(err)
    const errList = Object.values(err).filter((x) => x !== false)
    if (!errList || errList.length === 0) {
      const payload = {
        color,
        url,
        tabName,
        faviconImage,
        logoImage,
      }

      hasTheme ? updateCustomBrand(payload) : createCustomBrand(payload)
    }
  }

  return (
    <s.Wrapper>
      <s.Text
        data-testid="header-text-card"
        bold
        type="heading3"
        color="maincolor"
      >
        Identidade da Marca
      </s.Text>
      <s.Card width={696}>
        <s.TextWrapper>
          <s.Text data-testid="cores-title" bold type="headline">
            Cores
          </s.Text>
          <s.Text>
            Selecione uma cor de referência para a criação de uma paleta de
            cores que será aplicada no seu painel e em emails institucionais.
          </s.Text>
        </s.TextWrapper>
        <s.ColorWrapper>
          <s.ColorPicker
            color={color}
            onChange={(color) => {
              setColor(color)
            }}
          />
          <s.ColorPallete inputedColor={color} />
        </s.ColorWrapper>
        <s.ShadesOfGrayCheckButton
          data-testid="shades-of-gray-check-btn-test"
          checked={color === '#737373'}
          onClick={(e) => {
            if (color === '#737373') {
              setColor('#30C322')
            } else {
              setColor('#737373')
            }
          }}
        >
          <s.CheckSquare>
            <s.Icon name="checkmark" fill="white" />
          </s.CheckSquare>
          <s.Text fontWeight={600} type="headline" color="maincolor">
            Usar tons de cinza
          </s.Text>
        </s.ShadesOfGrayCheckButton>
        <s.TextWrapper>
          <s.Text data-testid="logo-title" bold type="headline">
            Logo
          </s.Text>
          <s.Text>
            Faça upload de um arquivo de sua logo em uma versão
            preferencialmente horizontal com fundo transparente e sem grandes
            margens para melhor visualização nas aplicações na barra de
            navegação do painel e no cabeçalho de emails institucionais. Caso
            não selecione um arquivo será apresentada a logo da Shipay.
          </s.Text>
        </s.TextWrapper>
        <s.UploadImageFile
          key={`${logoImage}-upload-icon`}
          value={logoImage}
          handleChange={(e: File) => {
            setLogoImage(e)
          }}
          title="Arquivo da logo horizontal"
          type="logo"
          data-testid="upload-logo"
          required={brandIdFormError.logoImage}
          hasFileAlready={!!logoImage}
          clear={() => setLogoImage(null)}
        />
        <s.TextWrapper>
          <s.Text data-testid="favicon-title" bold type="headline">
            Ícone (favicon)
          </s.Text>
          <s.Text>
            Faça upload de um arquivo de imagem do ícone que ficará visível na
            aba do navegador ao acessar o site do seu painel, idealmente em um
            formato quadrado e simplificado.
          </s.Text>
        </s.TextWrapper>
        <s.UploadImageFile
          key={`${faviconImage}-${isDefaultFavicon}-upload-favicon`}
          value={faviconImage}
          handleChange={(e: File) => {
            setIsDefaultFavicon(false)
            setFaviconImage(e)
          }}
          title="Arquivo do símbolo"
          type="icon"
          data-testid="upload-favicon"
          required={false}
          hasFileAlready={isDefaultFavicon ? false : !!faviconImage}
          clear={() => {
            setIsDefaultFavicon(true)
            setFaviconImage(
              isInitialDefaultFavicon ? initialFaviconImage : null
            )
          }}
        />
        <s.TextWrapper>
          <s.Text data-testid="title-url-title" bold type="headline">
            Título e endereço do site (URL)
          </s.Text>
          <s.Text>
            Defina um título para ser exibido na aba do navegador e um endereço
            do site para acessar o seu painel, o prefixo ”.wl.shipay.com.br” é
            obrigatório.
          </s.Text>
        </s.TextWrapper>
        <s.InputText
          data-testid="tabname"
          value={tabName}
          maxLength={40}
          placeholder="Título na aba"
          label="Título da aba do navegador"
          onChange={(e) => setTabName(e.target.value)}
          error={
            !brandIdFormError.tabName || tabName != ''
              ? false
              : { message: 'Campo obrigatório' }
          }
        />
        <s.InputText
          data-testid="urlname"
          value={url}
          prefix={
            <s.Text
              type="headline"
              color="graytheme4"
              data-testid="url-preffix"
            >
              https://
            </s.Text>
          }
          suffix={
            <s.Text type="headline" color="graytheme4">
              .wl.shipay.com.br
            </s.Text>
          }
          maxLength={40}
          placeholder="endereço"
          label="Endereço do site (URL)"
          onChange={(e) => {
            setUrl(
              e.target.value
                .normalize('NFD')
                .replace(/[^a-zA-Z]/g, '')
                .toLowerCase()
            )
          }}
          error={
            !brandIdFormError.url || url != ''
              ? false
              : { message: 'Campo obrigatório' }
          }
        />
        <s.ButtonWrapper>
          <s.Button
            disabled={!hasTheme || isLoading}
            onClick={() => {
              setColor(theme.colors.maincolor)
              setUrl(
                theme.domain
                  .replace('www.', '')
                  .replace('.wl.shipay.com.br', '')
              )
              setTabName(theme.pageTitle)
              setFaviconImage(initialFaviconImage)
              setLogoImage(initialLogoImage)
            }}
            width="276px"
            outline
            color="white"
            data-testid="recuperar-dados-button"
          >
            <s.Text color="maincolor" bold>
              Restaurar dados
            </s.Text>
          </s.Button>

          <s.Button
            onClick={() => {
              handleSubmit()
            }}
            disabled={
              Object.values(checkIfHasChanged()).every(
                (key) => key === false
              ) || isLoading
            }
            width="276px"
            color="maincolor"
          >
            <s.Text color="white" bold>
              Salvar alterações
            </s.Text>
            {isLoading ? <s.Loading type="spinner" color="maincolor" /> : null}
          </s.Button>
        </s.ButtonWrapper>
      </s.Card>
    </s.Wrapper>
  )
}

export default BrandIdentity
