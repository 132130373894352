

import { formatMoney } from "helpers/masks"
import * as s from './styles'

interface IProps {
  isOpen: string
  setCurrentStep: any
  simulationData: any
  handleClose: ()=> any
}


export const PrePaymentPreRegisterOne: React.FC<IProps> = ({ isOpen, handleClose, setCurrentStep, simulationData })=> {
 
  return(
    <s.Modal 
      handleClose={handleClose}
      isOpen={(isOpen) ? true : false}
      modalSubtitle={"Simular taxa de antecipação"}>
      <s.BackBtn>
        <s.Icon onClick={(x) =>{setCurrentStep(2)}} name="backbtn" fill="white" />
      </s.BackBtn>   
      <s.Line/>
      <s.ModalContent>
        <s.InputGroup>
          <s.Text color="graytheme6">
            As taxas e valores apresentados são
            apenas uma simulação sem compromisso.
          </s.Text>
        </s.InputGroup>
        <s.FormWrapper>
          <s.InputGroup>
              <s.InputText
                disabled
                label="Valor estimado a ser antecipado"
                value={formatMoney(simulationData.amount+'')}
              />
          </s.InputGroup>
          <s.InputGroup>
              <s.InputText
                disabled
                label="Taxa de antecipação"
                value={simulationData.rate+'%'}
              />
          </s.InputGroup>
          <s.InputGroup>
              <s.InputText
                disabled
                label="Valor a receber D+1"
                value={formatMoney(simulationData.receivables+'')}
              />
          </s.InputGroup>
          <s.Line/>
  
        </s.FormWrapper>
        <s.Button onClick={()=> setCurrentStep(4)}
          color="primary"
        >
          <s.Text bold color="white">
            Continuar
          </s.Text>
        </s.Button>
        <s.Button onClick={()=> setCurrentStep(2)}
          color="secondary" outline
        >
          <s.Text bold color="maincolor">
            Fazer outra simulação
          </s.Text>
        </s.Button>
      </s.ModalContent>
    </s.Modal>
  )  
}



