import styled from 'styled-components'

import TableCmp from 'components/Table'
import ButtonCmp from 'components/Button'
import TextCmp from 'components/Text'
import IconExporter from 'components/IconExporter'
import ModalCmp from 'components/Modal'
import InputTextCmp from 'components/Inputs/Text'
import SelectCmp from 'components/Inputs/Select'
import SwitchCmp from 'components/Switch'
import InputPasswordCmp from 'components/Inputs/Text/Password'
import PaginationCmp from 'components/Pagination'

export const Pagination = styled(PaginationCmp)``

export const ButtonCenter = styled.div`
  text-align: center;
`

export const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const InputPassword = styled(InputPasswordCmp)``

export const SwitchWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
`

export const Switch = styled(SwitchCmp)``

export const Select = styled(SelectCmp)`
  margin-top: 8px;
  margin-bottom: 16px;
`

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

export const Grid2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
`

export const Grid3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 40px;
`

export const Table = styled(TableCmp)`
  max-width: 1700px;
  > thead > tr > th {
    padding-left: ${({ theme }) => theme.spacing(2)}px;
    :nth-child(5) {
      width: 120px;
    }
    :nth-child(6) {
      width: 180px;
    }
    :nth-child(7) {
      width: 120px;
    }
    :nth-child(8) {
      width: 120px;
    }
  }

  > tbody > tr > td {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`

export const Button = styled(ButtonCmp)``

export const Text = styled(TextCmp)``

export const Icon = styled(IconExporter)``

export const ModalContent = styled.div`
  width: 800px;
`

export const Modal = styled(ModalCmp)`
  z-index: 14;
`

export const CreateButton = styled.div`
  text-align: right;
  margin-bottom: 20px;
`

export const InputText = styled(InputTextCmp)``

export const IdContainer = styled.div`
  ${({ theme }) => theme.assets.orders.columns.uuidContainer};
`

export const IdWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75%;
`
