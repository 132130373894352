import styled from 'styled-components'
import LoadingCmp from '..'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Loading = styled(LoadingCmp)`
  width: 100px;
  height: 100px;
`
