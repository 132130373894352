import styled, { css, keyframes } from 'styled-components'

import { Container as ContainerCmp } from 'styled-bootstrap-grid'
import TextCmp from 'components/Text'
import CardCmp from 'components/Card'
import IconCmp from 'components/IconExporter'
import ButtonCmp from 'components/Button'
import WalletCardCmp from 'components/Card/WalletDisplayCard'
import ModalCmp from 'components/Modal'

import * as pixStyles from 'pages/Pix/styles'

import * as a from 'animations'

export const Background = styled.div`
  background-image: url(${({ theme }) => theme.assets.wizardBackground.img});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
`

export const BackgroundFilter = styled.div`
  background: rgba(
    255,
    255,
    255,
    ${({ theme }) => theme.assets.wizardBackground.filter}
  );
  backdrop-filter: blur(64px);
  min-height: 100vh;
`

export const WalletCard = styled(WalletCardCmp)``

export const Loading = styled(pixStyles.Loading)``

export const LoadingWrapper = styled(pixStyles.LoadingWrapper)``

export const PixSimplesCard = styled(pixStyles.PixSimplesCard)`
  height: 128px;
  width: 200px;

  > img {
    height: 65px;
    width: 100px;
  }
`

export const Container = styled(ContainerCmp)`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-height: (100vh);
  padding-bottom: 16px;
  padding-top: 160px;

  > div {
    margin-bottom: ${({ theme }) => theme.spacing(5)}px;
  }

  > p {
    margin-bottom: ${({ theme }) => theme.spacing(5)}px;
  }
`

export const TextWrapper = styled.div`
  width: 100%;
  margin-left: ${({ theme }) => theme.spacing(44)}px;

  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const Text = styled(TextCmp)``

export const Card = styled(CardCmp)``

export const Icon = styled(IconCmp)``

export const Button = styled(ButtonCmp)`
  height: 56px;
  > span > svg {
    margin: 0 ${({ theme }) => theme.spacing(1)}px;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  margin: 0 -24px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`

export const Bottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > * {
    margin: 10px;
  }
`

export const Footer = styled.div`
  display: flex;
  padding: 0px ${({ theme }) => theme.spacing(2)}px;
  justify-content: space-between;
  width: 1000px;
`

const gradient = keyframes`
  0% {background-position: 0%}
  100% {background-position: 100%}
`

export const FooterWrapper = styled.div`
  background: rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: absolute;
  margin: 10px 0px;
  padding: 4px 0px;
  width: 90%;
  bottom: 0px;
`

export const TextCardFooter = styled(TextCmp)`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 149%;
  text-align: center;
  color: #ffffff;

  & > img {
    display: inline;
  }
`

interface WalletAnyBankCard {
  disabled?: boolean
}

export const ItauCard = styled.div<WalletAnyBankCard>`
  position: relative;
  height: 128px;
  width: 220px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 4px #a3bacc;

  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.gray3
      : css`
        linear-gradient(180deg, #f98f53 0%, #e55f13 100%);
  `};
  background-size: 200% 100%;
  ${({ disabled }) =>
    disabled
      ? css``
      : css`
          animation: ${gradient} 16s linear infinite;
        `}
  animation-direction: alternate;

  & > img {
    position: absolute;
    top: 0;
    margin: 12px 0px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${({ disabled }) =>
      disabled
        ? css``
        : css`
            animation: ${gradient} 16s linear infinite,
              ${a.cardAnimationShipayIn} 0.5s infinite alternate;
          `}
  }
`

export const OriginalCard = styled.div<WalletAnyBankCard>`
  position: relative;
  height: 128px;
  width: 220px;
  border-radius: 8px;
  display: flex;
  position: relative;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
    0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.gray3
      : css`
        linear-gradient(180deg, #3cdd6f 0%, #18ad47 100%);
  `};
  background-size: 200% 100%;
  ${({ disabled }) =>
    disabled
      ? css``
      : css`
          animation: ${gradient} 16s linear infinite;
        `}
  animation-direction: alternate;

  > svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  & > img {
    position: absolute;
    top: 0;
    margin: 22px 0px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${({ disabled }) =>
      disabled
        ? css``
        : css`
            animation: ${gradient} 16s linear infinite,
              ${a.cardAnimationShipayIn} 0.5s infinite alternate;
          `}
  }
`

export const TBanksCard = styled.div<WalletAnyBankCard>`
  position: relative;
  height: 128px;
  width: 220px;
  border-radius: 8px;
  display: flex;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
    0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  align-items: center;
  position: relative;
  justify-content: center;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.gray3
      : css`
        linear-gradient(180deg, #bc5def 0%, #5e217f 100%);
  `};
  background-size: 200% 100%;
  ${({ disabled }) =>
    disabled
      ? css``
      : css`
          animation: ${gradient} 16s linear infinite;
        `}
  animation-direction: alternate;

  & > img {
    position: absolute;
    top: 0;
    margin: 28px 0px;
  }

  > svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${({ disabled }) =>
      disabled
        ? css``
        : css`
            animation: ${gradient} 16s linear infinite,
              ${a.cardAnimationShipayIn} 0.5s infinite alternate;
          `}
  }
`

export const RedeCard = styled.div<WalletAnyBankCard>`
  position: relative;
  height: 128px;
  width: 220px;
  border-radius: 8px;
  display: flex;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
    0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  align-items: center;
  position: relative;
  justify-content: center;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.gray3
      : css`
        linear-gradient(180deg, #595959 0%, #282828 100%);
  `};
  background-size: 200% 100%;
  ${({ disabled }) =>
    disabled
      ? css``
      : css`
          animation: ${gradient} 16s linear infinite;
        `}
  animation-direction: alternate;

  & > img {
    position: absolute;
    top: 0;
    margin: 8px 0px;
  }

  > svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${({ disabled }) =>
      disabled
        ? css``
        : css`
            animation: ${gradient} 16s linear infinite,
              ${a.cardAnimationShipayIn} 0.5s infinite alternate;
          `}
  }
`

export const Modal = styled(ModalCmp)``

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  > p {
    margin-bottom: 16px;
  }

  ${Button} {
    margin-top: 16px;
    height: 40px;
    margin-left: auto;
  }
`
