import {
  baseURLPosProductComponentsWlCustomEmail,
  baseURLPosProductComponentsWlCustomImages,
  baseURLPosProductComponentsWlCustomPanel,
  patch,
  patchFormData,
} from 'services/api'
import { generateColorsPallete } from 'components/ColorPallete'
import { showToast } from 'components/Toast'

export const updateCustomBrandEmailAndPanel = async ({
  domainUrl,
  mainlogoUrl,
  faviconUrl,
  color,
  tabName,
  theme,
  history,
}: {
  domainUrl: string
  mainlogoUrl: string
  faviconUrl: string
  color: string
  tabName: string
  theme: any
  history?: any
}) => {
  await patch(
    `${baseURLPosProductComponentsWlCustomPanel}/${theme.components_id.panel}`,
    {
      domain: domainUrl ? domainUrl : undefined,
      images:
        mainlogoUrl || faviconUrl
          ? {
              mainlogo: mainlogoUrl ? mainlogoUrl : undefined,
              favicon: faviconUrl ? faviconUrl : undefined,
            }
          : undefined,
      pagetitle: tabName ? tabName : undefined,
      colors: color
        ? {
            ...generateColorsPallete(color).hex,
          }
        : undefined,
    }
  )
    .then(async (res) => {
      if (mainlogoUrl || color) {
        const {
          maincolor,
          darkmaincolor1,
          lightmaincolor1,
        } = generateColorsPallete(color).hex

        await patch(
          `${baseURLPosProductComponentsWlCustomEmail}/${theme.components_id.email}`,
          {
            images: {
              mainlogo: mainlogoUrl,
            },
            colors: {
              maincolor,
              darkmaincolor1,
              lightmaincolor1,
            },
            support_contacts: {
              faq: '',
              phone: '',
              email: '',
            },
            social_contacts: {
              instagram: '',
              facebook: '',
              youtube: '',
              linkedin: '',
            },
          }
        )
          .then((res) => {
            setTimeout(() => history.go(0), 1000)
          })
          .catch((e) => {
            showToast({
              type: 'error',
              message: JSON.parse(e.request.response).detail,
            })
          })
      }

      showToast({
        type: 'success',
        message: 'Tema Criado com sucesso',
      })
    })
    .catch((e) => {
      showToast({
        type: 'error',
        message: JSON.parse(e.request.response).detail,
      })
    })
}

export const updateFaviconAndLogo = async ({
  images,
  domainUrl,
  hasChanged,
  color,
  tabName,
  theme,
  history,
}) => {
  if (hasChanged.faviconImage || hasChanged.logoImage) {
    patchFormData(
      `${baseURLPosProductComponentsWlCustomImages}/${theme.components_id.images}`,
      {
        mainlogo: images.get('mainlogo'),
        favicon: images.get('favicon') || undefined,
        use_favicon_default: !!!images.get('favicon'),
      }
    )
      .then((res) => {
        const faviconUrl = res.data.favicon || undefined
        const mainlogoUrl = res.data.mainlogo

        updateCustomBrandEmailAndPanel({
          domainUrl: domainUrl,
          mainlogoUrl: mainlogoUrl,
          faviconUrl: faviconUrl,
          color: hasChanged.color ? color : '',
          tabName: hasChanged.tabName ? tabName : '',
          theme,
          history,
        })
      })

      .catch((e) => {
        showToast({
          type: 'error',
          message: e.response?.data.detail[0].msg,
        })
      })
  } else {
    updateCustomBrandEmailAndPanel({
      domainUrl: domainUrl,
      faviconUrl: '',
      mainlogoUrl: '',
      color: hasChanged.color ? color : '',
      tabName: hasChanged.tabName ? tabName : '',
      theme,
      history,
    })
  }
}
