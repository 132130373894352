import React from 'react'

import * as s from '../styles'
import { isValidEmail } from 'helpers/validators'

interface IProps {
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  validateAuth: (email: string) => void
  isLoading: boolean
}

const Email: React.FC<IProps> = ({
  email,
  setEmail,
  validateAuth,
  isLoading,
}) => {
  const [emailError, setEmailError] = React.useState(false)
  const [infoError, setInfoError] = React.useState({
    title: '',
    message: <></>,
  })

  const InfoBox: React.FC<{
    error?: { title: string; message: React.ReactElement }
  }> = ({ error }) => {
    if (error?.title) {
      return (
        <s.InfoBox error>
          <s.Text bold type="paragraph" color="redshipay">
            {error.title}
          </s.Text>
          <s.Text type="paragraph" color="cancelledStatusTextColor">
            {error.message}
          </s.Text>
        </s.InfoBox>
      )
    }

    return <></>
  }

  const validateErrors = () => {
    if (email === '') {
      setInfoError({
        title: 'Campo obrigatório não preenchido',
        message: (
          <>
            Preencha o campo de <u>E-mail</u> para prosseguir.
          </>
        ),
      })
      setEmailError(true)
      return true
    } else if (!isValidEmail(email)) {
      setInfoError({
        title: 'Campo incorreto',
        message: (
          <>
            Preencha o campo de <u>E-mail</u> corretamente para prosseguir.
          </>
        ),
      })
      setEmailError(true)
      return true
    }
    setInfoError({
      title: '',
      message: <></>,
    })
    setEmailError(false)
    return false
  }

  return (
    <s.CardWrapper>
      <s.TitleWrapper>
        <s.Text margin="24px 0 32px 8px" textAlign="left" bold type="headline">
          Acesso ao painel do cliente
        </s.Text>
      </s.TitleWrapper>
      <s.EmailWrapper>
        {isLoading ? (
          <s.Loading width={90} type="bigspinner" />
        ) : (
          <>
            <InfoBox error={infoError} />
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={emailError ? 'redshipay' : 'graytheme6'}
              >
                E-mail
              </s.Text>
              <s.InputText
                testId={`email-test`}
                width={304}
                type="email"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                  if (emailError) {
                    setEmailError(false)
                  }
                }}
                error={emailError}
              />
            </s.SectionLine>
          </>
        )}
      </s.EmailWrapper>
      <s.ButtonWrapper>
        {isLoading ? (
          <></>
        ) : (
          <s.Button
            onClick={(e) => {
              e.preventDefault()
              if (!validateErrors()) {
                validateAuth(email)
              }
            }}
            data-testid="button-entrar-1"
            color="primary"
            width="100%"
          >
            <s.Text margin="0 8px 0 0" fontWeight={600} color="white">
              Continuar
            </s.Text>
            <s.Icon name="arrowright" fill="whiteshipay" />
          </s.Button>
        )}
      </s.ButtonWrapper>
    </s.CardWrapper>
  )
}

export default Email
