import { useState } from 'react'

import * as s from './styles'
import { useNonInitialEffect } from 'hooks/utils'

interface IProps {
  options: { id: string; text: string }[]
  selected: { id: string; name: string }
  onDisabledChange?: (disabled) => void
  disabled?: boolean
}

export const SelectToggle: React.FC<IProps> = ({
  options,
  selected,
  onDisabledChange,
  disabled,
}) => {
  return (
    <s.SelectGroup>
      <s.SelectToggleGroup>
        <s.Select
          optionBoxSize={150}
          disabled={disabled}
          options={options}
          selected={selected}
        />
        {onDisabledChange && (
          <s.Icon
            name="pencil"
            fill="maincolor"
            onClick={() => onDisabledChange(!disabled)}
         />
        )
        }
      </s.SelectToggleGroup>
    </s.SelectGroup>
  )
}
