import React from 'react'
import { useDispatch } from 'react-redux'

import { showToast } from 'components/Toast'
import { useTranslation } from 'react-i18next'

import { get, baseURLStore, post, remove } from '../services/api'
import { useTypedSelector } from '../store/modules/rootState'
import {
  GetStore,
  GetStoreLoading,
  GetStoreError,
  PostStoreRequest,
  PostStoreResponse,
  PostStoreError,
  DeleteStoreRequest,
  DeleteStoreResponse,
} from 'store/modules/store/action'

import { StoreState, RequestInterface } from 'store/modules/store/types'

interface IUseStore extends StoreState {
  loadStore: (string) => Promise<void>
  postStore: (payload: RequestInterface, id?: string) => Promise<void>
  deleteStore: (id: string) => Promise<void>
  reactivateStore: (id: string) => Promise<void>
}

const UseStore = (): IUseStore => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const {
    store,
    isLoading,
    hasError,
    postResponse,
    postIsLoading,
    postHasError,
    postHasErrorMessage,
    deleteIsLoading,
    deleteResponse,
  } = useTypedSelector((reduxStore) => ({
    store: reduxStore.store.store,
    isLoading: reduxStore.store.isLoading,
    hasError: reduxStore.store.hasError,
    postResponse: reduxStore.store.postResponse,
    postIsLoading: reduxStore.store.postIsLoading,
    postHasErrorMessage: reduxStore.store.postHasErrorMessage,
    postHasError: reduxStore.store.postHasError,
    deleteIsLoading: reduxStore.store.deleteIsLoading,
    deleteResponse: reduxStore.store.deleteResponse,
  }))

  const loadStore = React.useCallback(
    async (id): Promise<void> => {
      dispatch(GetStoreLoading())
      await get(`${baseURLStore}/${id}`)
        .then((response) => {
          dispatch(GetStore(response.data))
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: `${JSON.parse(e.request.response).message}`,
          })
          dispatch(GetStoreError())
        })
    },
    [dispatch]
  )

  const postStore = React.useCallback(
    async (payload, id): Promise<void> => {
      dispatch(PostStoreRequest(payload))
      await post(`${baseURLStore}${id ? `/${id}` : ''}`, payload)
        .then((response) => {
          dispatch(PostStoreResponse(response.data))
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: t(`${JSON.parse(e.request.response).message}`),
          })
          dispatch(PostStoreError(JSON.parse(e.request.response).message))
        })
    },
    [dispatch]
  )

  const deleteStore = React.useCallback(
    async (id): Promise<void> => {
      dispatch(DeleteStoreRequest(id))
      await remove(`${baseURLStore}/${id}`)
        .then((response) => {
          showToast({
            type: 'success',
            message: response.data.message,
          })
          dispatch(DeleteStoreResponse(response.data))
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: `${JSON.parse(e.request.response).message}`,
          })
          dispatch(DeleteStoreResponse(JSON.parse(e.request.response).data))
        })
    },
    [dispatch]
  )

  const reactivateStore = React.useCallback(async (id): Promise<void> => {
    await get(`${baseURLStore}/${id}`)
      .then((response) => {
        const newStore = response.data
        newStore.active = true
        postStore(newStore, id)
      })
      .catch((e) => {
        showToast({
          type: 'error',
          message: `${JSON.parse(e.request.response).message}`,
        })
      })
  }, [])

  return {
    store,
    hasError,
    isLoading,
    loadStore,
    postResponse,
    postIsLoading,
    postHasError,
    postHasErrorMessage,
    postStore,
    deleteIsLoading,
    deleteResponse,
    deleteStore,
    reactivateStore,
  }
}

export default UseStore
