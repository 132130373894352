import React from 'react'

import useWalletsSettings from 'hooks/useWalletsSettings'
import { showToast } from 'components/Toast'

import { WalletSetting } from 'store/modules/walletSettings/types'

import * as s from './styles'

interface IProps {
  data: WalletSetting
  handleClose: () => void
  offset: string
}

const EditNinetyninepayWallet: React.FC<IProps> = ({
  data,
  handleClose,
  offset,
}) => {
  const {
    patchIsLoading,
    patchWalletSettings,
    loadWalletsSettings,
  } = useWalletsSettings()
  const [inputEditable, setInputEditable] = React.useState<any>({
    ...Object.keys(data).reduce((acc, key) => {
      acc[key] = false
      return acc
    }, {}),
  })

  const [newWallet, setNewWallet] = React.useState<WalletSetting>(data)

  return (
    <s.FormContainer>
      <s.InputText
        label="ID"
        disabled={true}
        value={data.id}
      />
      <s.InputText
        label="Apelido"
        disabled={!inputEditable.name}
        onChange={(e) => {
          setNewWallet({ ...newWallet, name: e.target.value })
        }}
        placeholder={data.name}
        value={newWallet.name}
        suffix={
          <s.EditButtonWrapper
            onClick={() => {
              setInputEditable({
                ...inputEditable,
                name: !inputEditable.name,
              })
              if (inputEditable.name) {
                setNewWallet({
                  ...newWallet,
                  name: data.name,
                })
              } else {
                setNewWallet({
                  ...newWallet,
                  name: '',
                })
              }
            }}
          >
            <s.Icon name="pencil" fill="maincolor" />
          </s.EditButtonWrapper>
        }
      />
      <s.InputText
        label="Merchant ID"
        disabled={!inputEditable.merchant_id}
        onChange={(e) => {
          setNewWallet({ ...newWallet, merchant_id: e.target.value })
        }}
        suffix={
          <s.EditButtonWrapper
            onClick={() => {
              setInputEditable({
                ...inputEditable,
                merchant_id: !inputEditable.merchant_id,
              })
              if (inputEditable.merchant_id) {
                setNewWallet({
                  ...newWallet,
                  merchant_id: data.merchant_id,
                })
              } else {
                setNewWallet({
                  ...newWallet,
                  merchant_id: '',
                })
              }
            }}
          >
            <s.Icon name="pencil" fill="maincolor" />
          </s.EditButtonWrapper>
        }
        placeholder={data.merchant_id}
        value={newWallet.merchant_id}
      />
      <s.InputText
        label="App ID"
        disabled={!inputEditable.app_id}
        onChange={(e) => {
          setNewWallet({ ...newWallet, app_id: e.target.value })
        }}
        suffix={
          <s.EditButtonWrapper
            onClick={() => {
              setInputEditable({
                ...inputEditable,
                app_id: !inputEditable.app_id,
              })
              if (inputEditable.app_id) {
                setNewWallet({
                  ...newWallet,
                  app_id: data.app_id,
                })
              } else {
                setNewWallet({
                  ...newWallet,
                  app_id: '',
                })
              }
            }}
          >
            <s.Icon name="pencil" fill="maincolor" />
          </s.EditButtonWrapper>
        }
        placeholder={data.app_id}
        value={newWallet.app_id}
      />
      <s.InputTextArea
        label="Private Key"
        disabled={!inputEditable.private_key}
        onChange={(e) => {
          setNewWallet({ ...newWallet, private_key: e.target.value })
        }}
        suffix={
          <s.EditButtonWrapper
            onClick={() => {
              setInputEditable({
                ...inputEditable,
                private_key: !inputEditable.private_key,
              })
              if (inputEditable.private_key) {
                setNewWallet({
                  ...newWallet,
                  private_key: data.private_key,
                })
              } else {
                setNewWallet({
                  ...newWallet,
                  private_key: '',
                })
              }
            }}
          >
            <s.Icon name="pencil" fill="maincolor" />
          </s.EditButtonWrapper>
        }
        placeholder={data.private_key}
        value={newWallet.private_key}
      />
      {Object.values(inputEditable).some((input) => input === true) ? (
        <s.FooterButtonWrapper>
          <s.Button
            disabled={patchIsLoading}
            width="100px"
            color="transparent"
            outline
            onClick={() => handleClose()}
          >
            Cancelar
          </s.Button>
          <s.Button
            width="120px"
            disabled={
              patchIsLoading ||
              (newWallet.name === data.name &&
                newWallet.merchant_id === data.merchant_id &&
                newWallet.private_key === data.private_key &&
                newWallet.app_id === data.app_id)
            }
            onClick={() => {
              if (
                newWallet.name === '' ||
                newWallet.merchant_id === '' ||
                newWallet.private_key === '' ||
                newWallet.app_id === ''
              ) {
                showToast({
                  type: 'error',
                  message: 'Você não pode deixar campos em branco',
                })
              } else {
                patchWalletSettings(newWallet.id, {
                  ...Object.keys(inputEditable).reduce((acc, key) => {
                    if (inputEditable[key] === true) {
                      acc[key] = newWallet[key]
                    }
                    return acc
                  }, {}),
                }).then((status) => {
                  if (status === '200') {
                    loadWalletsSettings({
                      limit: '10',
                      offset: offset,
                      filters: window.btoa(
                        unescape(
                          encodeURIComponent(
                            JSON.stringify({
                              wallet_type: ['c-wallet', 'e-wallet'],
                            })
                          )
                        )
                      ),
                    })
                    handleClose()
                  }
                })
              }
            }}
            color="maincolor"
          >
            Salvar
          </s.Button>
          {patchIsLoading ? (
            <s.Loading type="spinner" color="maincolor" />
          ) : null}
        </s.FooterButtonWrapper>
      ) : null}
    </s.FormContainer>
  )
}

export default EditNinetyninepayWallet
