import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { showToast } from 'components/Toast'

import { impersonate, undoImpersonate } from '../services/impersonate'

import { useTypedSelector } from 'store/modules/rootState'
import {
  PostImpersonate,
  PostImpersonateResponse,
  PostImpersonateError,
  RemoveImpersonate,
} from 'store/modules/impersonate/action'
import { ImpersonateState } from 'store/modules/impersonate/types'
import {
  setValueToLocalStorage,
  getValueFromLocalStorage,
} from 'helpers/localStorage'
import { parseUserSessionData } from './usePostAuth'
import { postWithCredentials } from 'services/api'

interface IUseImpersonate extends ImpersonateState {
  postImpersonate: (customerId: string) => Promise<void>
  removeImpersonate: () => void
}

const useImpersonate = (): IUseImpersonate => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { request, isLoading, hasError, response } = useTypedSelector(
    (store) => ({
      request: store.impersonate.request,
      isLoading: store.impersonate.isLoading,
      hasError: store.impersonate.hasError,
      response: store.impersonate.response,
    })
  )

  const postImpersonate = React.useCallback(
    async (customerId): Promise<void> => {
      dispatch(PostImpersonate({}))
      await impersonate(customerId)
        .then((response: any) => {
          //move the currentUser to adminUser and set the impersonated as the currentUser
          const currentUserSession =
            getValueFromLocalStorage('currentUserSession')

          if (!getValueFromLocalStorage('adminUserSession')) {
            setValueToLocalStorage('adminUserSession', currentUserSession)
          }
          setValueToLocalStorage(
            'currentUserSession',
            parseUserSessionData(response.data)
          )
          dispatch(PostImpersonateResponse(response.data))
          setTimeout(() => {
            history.push('/')
            // window.location.reload()
          }, 500)
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: `${JSON.parse(e.request.response)?.message}`,
          })
          dispatch(PostImpersonateError(JSON.parse(e.request.response)))
        })
    },
    [dispatch]
  )

  const removeImpersonate = React.useCallback(async (): Promise<void> => {
    undoImpersonate()
    await postWithCredentials('/depersonalize', {})
      .then((res) => {
        dispatch(RemoveImpersonate())
      })
      .catch((e) => {
        showToast({ type: 'error', message: e.response.data.message })
      })
  }, [dispatch])

  return {
    request,
    response,
    isLoading,
    hasError,
    postImpersonate,
    removeImpersonate,
  }
}

export default useImpersonate
