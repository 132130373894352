import React from 'react'
import WhiteLabelLoading from 'components/Loading/WhiteLabel'
import { useHistory } from 'react-router'
import useGetTheme from 'hooks/useGetTheme'
import { useTheme } from 'styled-components'
import { isWhiteLabel } from 'helpers'

const LoadingTheme: React.FC<{
  setTheme: React.Dispatch<React.SetStateAction<any>>
}> = ({ setTheme }) => {
  const history = useHistory()
  const theme = useTheme()
  const { userTheme, getTheme, hasError, hasSuccess, isLoading } = useGetTheme()

  React.useEffect(() => {
    if (isWhiteLabel() && theme.domain === 'Shipay') {
      getTheme()
    } else {
      history.push('/')
    }
  }, [])

  React.useEffect(() => {
    if (hasSuccess) {
      setTheme(userTheme)
      history.push('/')
    } else if (hasError) {
      setTheme(theme)
      history.push('/')
    }
  }, [hasSuccess, hasError])

  return <WhiteLabelLoading />
}

export default LoadingTheme
