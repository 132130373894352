export const Types = {
  POST_RESPONSE: 'POST_RESPONSE',
  POST_LOADING: 'POST_LOADING',
  POST_ERROR: 'POST_ERROR',
}

export interface ActionInterface {
  type: keyof typeof Types
  payload: ResponseInterface
}

export interface ResponseInterface {
  code: number
  message: string
}

export interface CompleteRegistrationState {
  error: boolean,
  isLoading: boolean,
  response: ResponseInterface
}
