import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'store/modules/rootState'

import {
  PatchRetailChain,
  PatchRetailChainError,
  PatchRetailChainIsLoading,
} from 'store/modules/retailChain/action'

import { RetailChainState } from 'store/modules/retailChain/types'

import retailChainService from 'services/retailChain'

import { Partial } from 'helpers/partial'

interface UsePatchRetailChain extends RetailChainState {
  editRetailChain: (retailChainId: string, 
    retailChain: { name:string, email:string }) => Promise<void>,
}

export const usePatchRetailChain = (): Partial<UsePatchRetailChain> => {
  const dispatch = useDispatch()
  const { patchResponse, patchIsLoading, patchError } = useTypedSelector((store) => ({
    patchResponse: store.retailChain.patchResponse,
    patchIsLoading: store.retailChain.patchIsLoading,
    patchError: store.retailChain.patchError,
  }))

  const editRetailChain = useCallback(async (retailChainId, retailChain): Promise<void> => {
    dispatch(PatchRetailChainIsLoading())
    await retailChainService.editRetailChain(retailChainId, retailChain)
      .then((response) => {
        dispatch(PatchRetailChain(response.data))
      })
      .catch(() => {
        dispatch(PatchRetailChainError())
      })
  }, [dispatch])

  return { patchResponse, patchIsLoading, patchError, editRetailChain}
}
