import React from 'react'
import { useDispatch } from 'react-redux'
import { get, baseURLLoadLogo } from '../services/api'
import { useTypedSelector } from '../store/modules/rootState'
import {
  GetLogo,
  GetLogoLoading,
  GetLogoError,
} from 'store/modules/logo/actions'

import { LogoState } from 'store/modules/logo/types'

interface UseGetLogo extends LogoState {
  loadLogo: () => Promise<void>
}

const useGetLogo = (): UseGetLogo => {
  const dispatch = useDispatch()
  const { logo, isLoading, hasError } = useTypedSelector((store) => ({
    logo: store.logo.logo,
    isLoading: store.logo.isLoading,
    hasError: store.logo.hasError,
  }))

  const loadLogo = React.useCallback(async (): Promise<void> => {
    dispatch(GetLogoLoading())
    await get(`${baseURLLoadLogo}`)
      .then((response) => {
        dispatch(GetLogo(response.data))
      })
      .catch(() => {
        dispatch(GetLogoError())
      })
  }, [dispatch])

  return { logo, hasError, isLoading, loadLogo }
}

export default useGetLogo
