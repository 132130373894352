import React from 'react'

import useWalletsSettings from 'hooks/useWalletsSettings'
import { showToast } from 'components/Toast'

import { WalletSetting } from 'store/modules/walletSettings/types'

import * as s from './styles'

interface IProps {
  data: WalletSetting
  handleClose: () => void
  offset: string
}

const EditCieloWallet: React.FC<IProps> = ({ data, handleClose, offset }) => {
  const {
    patchIsLoading,
    patchWalletSettings,
    loadWalletsSettings,
  } = useWalletsSettings()
  const [inputEditable, setInputEditable] = React.useState<any>({
    ...Object.keys(data).reduce((acc, key) => {
      acc[key] = false
      return acc
    }, {}),
  })

  const [newWallet, setNewWallet] = React.useState<WalletSetting>(data)

  return (
    <s.FormContainer>
      <s.InputText label="ID" disabled value={data.id} />
      <s.InputText
        label="Apelido"
        disabled={!inputEditable.name}
        onChange={(e) => {
          setNewWallet({ ...newWallet, name: e.target.value })
        }}
        placeholder={data.name}
        value={newWallet.name}
        suffix={
          <s.EditButtonWrapper
            onClick={() => {
              setInputEditable({
                ...inputEditable,
                name: !inputEditable.name,
              })
              if (inputEditable.name) {
                setNewWallet({
                  ...newWallet,
                  name: data.name,
                })
              } else {
                setNewWallet({
                  ...newWallet,
                  name: '',
                })
              }
            }}
          >
            <s.Icon name="pencil" fill="maincolor" />
          </s.EditButtonWrapper>
        }
      />
      <s.InputText
        label="Cielo Merchant Key"
        disabled={!inputEditable.client_secret}
        onChange={(e) => {
          setNewWallet({ ...newWallet, client_secret: e.target.value })
        }}
        suffix={
          <s.EditButtonWrapper
            onClick={() => {
              setInputEditable({
                ...inputEditable,
                client_secret: !inputEditable.client_secret,
              })
              if (inputEditable.client_secret) {
                setNewWallet({
                  ...newWallet,
                  client_secret: data.client_secret,
                })
              } else {
                setNewWallet({
                  ...newWallet,
                  client_secret: '',
                })
              }
            }}
          >
            <s.Icon name="pencil" fill="maincolor" />
          </s.EditButtonWrapper>
        }
        placeholder={data.client_secret}
        value={newWallet.client_secret}
      />
      <s.InputText
        label="Cielo Merchant ID"
        disabled={!inputEditable.client_id}
        onChange={(e) => {
          setNewWallet({ ...newWallet, client_id: e.target.value })
        }}
        suffix={
          <s.EditButtonWrapper
            onClick={() => {
              setInputEditable({
                ...inputEditable,
                client_id: !inputEditable.client_id,
              })
              if (inputEditable.client_id) {
                setNewWallet({
                  ...newWallet,
                  client_id: data.client_id,
                })
              } else {
                setNewWallet({
                  ...newWallet,
                  client_id: '',
                })
              }
            }}
          >
            <s.Icon name="pencil" fill="maincolor" />
          </s.EditButtonWrapper>
        }
        placeholder={data.client_id}
        value={newWallet.client_id}
      />
      {Object.values(inputEditable).some((input) => input === true) ? (
        <s.FooterButtonWrapper>
          <s.Button
            disabled={patchIsLoading}
            width="100px"
            color="transparent"
            outline
            onClick={() => handleClose()}
          >
            Cancelar
          </s.Button>
          <s.Button
            width="120px"
            disabled={
              patchIsLoading ||
              (newWallet.name === data.name &&
                newWallet.client_id === data.client_id &&
                newWallet.client_secret === data.client_secret)
            }
            onClick={() => {
              if (
                newWallet.name === '' ||
                newWallet.client_id === '' ||
                newWallet.client_secret === ''
              ) {
                showToast({
                  type: 'error',
                  message: 'Você não pode deixar campos em branco',
                })
              } else {
                patchWalletSettings(newWallet.id, {
                  ...Object.keys(inputEditable).reduce((acc, key) => {
                    if (inputEditable[key] === true) {
                      acc[key] = newWallet[key]
                    }
                    return acc
                  }, {}),
                }).then((status) => {
                  if (status === '200') {
                    loadWalletsSettings({
                      limit: '10',
                      offset: offset,
                      filters: window.btoa(
                        unescape(
                          encodeURIComponent(
                            JSON.stringify({
                              wallet_type: ['c-wallet', 'e-wallet'],
                            })
                          )
                        )
                      ),
                    })
                    handleClose()
                  }
                })
              }
            }}
            color="maincolor"
          >
            Salvar
          </s.Button>
          {patchIsLoading ? (
            <s.Loading type="spinner" color="maincolor" />
          ) : null}
        </s.FooterButtonWrapper>
      ) : null}
    </s.FormContainer>
  )
}

export default EditCieloWallet
