import styled, { css } from 'styled-components'

import CardCmp from 'components/Card'
import TextCmp from 'components/Text'
import IconCmp from 'components/IconExporter'
import {
  TBanksCardCashout as TBanksCardCashoutCmp,
  FooterWrapper as FooterWrapperCmp,
  TextCardFooter as TextCardFooterCmp,
} from 'pages/Pix/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-height: calc(100vh - 100px);
  max-width: 1500px;
  position: relative;
  padding-bottom: 32px;

  margin-top: ${({ theme }) => theme.spacing(12)}px;
`

export const MainCard = styled(CardCmp)`
  padding: 32px;
`

export const Card = styled(CardCmp)`
  align-items: center;
  justify-content: center;
`

export const Image = styled.img`
  max-width: 584px;
  max-height: 319px;
`

export const HeaderText = styled(TextCmp)`
  font-size: 48px;
`

export const Text = styled(TextCmp)``

export const Wrapper = styled.div`
  display: flex;

  gap: 74px;
`

export const CashoutStep = styled.div<{ blue?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 52px;
  height: 52px;
  border-radius: 32px;

  ${({ blue }) =>
    blue
      ? css`
          background: ${({ theme }) => theme.colors.maincolor};
        `
      : css`
          background: ${({ theme }) => theme.colors.white};
          box-shadow: inset 0px 0px 4px
            ${({ theme }) => theme.colors.graytheme4};
        `}
`

export const Icon = styled(IconCmp)``

export const FlowArrow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  width: 78px;
  height: 60px;
`

export const CashoutFlow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  min-height: inherit;
`

export const TBanksCardCashout = styled(TBanksCardCashoutCmp)`
  width: 208px;
`

export const FooterWrapper = styled(FooterWrapperCmp)``

export const TextCardFooter = styled(TextCardFooterCmp)``

export const CashoutCardsWrapper = styled.div`
  display: flex;
  gap: 32px;
  padding-top: 32px;
  align-items: center;
`

export const ComingSoonCard = styled(CardCmp)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 302px;
  min-height: 86px;
  max-height: 86px;
  padding: 32px;
`

export const Message = styled.div`
  display: flex;
  gap: 8px;
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.graytheme10};
  border-radius: 8px;
`
