import { ActionInterface, Types, WalletState } from "./types";

const INITIAL_STATE: WalletState = {
  wallets: [],
  isLoading: false,
  hasError: false,
};

const walletsReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): WalletState => {
  switch (action.type) {
    case Types.GET_WALLETS:
      return {
        ...state,
        wallets: action.payload,
        isLoading: false,
        hasError: false,
      };
    case Types.GET_WALLETS_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case Types.GET_WALLETS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    default:
      return state;
  }
};

export default walletsReducer;
