import React from 'react'
import * as s from './styles'

interface IProps {
  url?: string
  testId?: string
}

export const MessageBoxDefault: React.FC<IProps> = ({ url, testId }) => {
  return (
    <s.MessageBoxOutline>
      <s.Text color="gray1">Está com dúvidas para cadastrar sua conta?</s.Text>
      <s.Text>
        Preparamos um material para lhe ajudar:{' '}
        <s.ExternalLink href={url} target="_blank">
          Acesse Aqui ↗
        </s.ExternalLink>
      </s.Text>
    </s.MessageBoxOutline>
  )
}
