import { post, baseURLRegistrationPub, baseURLCustomer } from '../api'

export interface CompleteUserRegistration {
  store_postal_code: string
  customer_name: string
  customer_email: string
  store_cnpj_cpf: string
  store_name: string
  store_state: string
  store_street: string
  terms_accepted: boolean
  user_email: string
  user_full_name: string
  user_role_id: string
  user_phone: string
  store_pos_names: string[]
  pos_product_id: string
  store_reference?: string
  store_street_number: string
  store_neighborhood: string
  retail_chain_id: string
}

export interface CustomerRegistration {
  name: string
  email: string
  pos_product_id: string
  retail_chain_id: string
}

const createUser = (user: CompleteUserRegistration) => {
  return post(`${baseURLRegistrationPub}`, user)
}

const createCustomer = (customer: CustomerRegistration) => {
  return post(`${baseURLCustomer}`, customer)
}

const registrationService = {
  createUser,
  createCustomer,
}

export default registrationService
