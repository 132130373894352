import { formatMoney, unformatMoney } from 'helpers/masks'
import React from 'react'
import { baseURLReconciliation, get } from 'services/api'
import ReconciliationDetailsModal from './ReconciliationDetailsModal'

import * as s from './styles'

interface IProps {
  options: {
    start_date: string
    end_date: string
    wallet_setting_id: string
    consolidated_customer_value: string
  }
}

interface ConsolidatedValue {
  value_difference: string
  sum_orders_value: string
  discrepancy: boolean
}

const statusColor: any = {
  success: 'maincolor',
  warning: 'orange',
  error: 'redshipay',
}

const ReconciliationDetails: React.FC<IProps> = ({ options }) => {
  const [detailsModal, setDetailsModal] = React.useState(false)
  const [pspData, setPspData] = React.useState<ConsolidatedValue>()
  const [shipayData, setShipayData] = React.useState<ConsolidatedValue>()
  const [procesorData, setProcessorData] = React.useState<ConsolidatedValue>()

  const getColor = (discrepancy: boolean) => {
    if (discrepancy) return statusColor.warning

    return statusColor.success
  }

  const getIcon = (discrepancy: boolean) => {
    if (discrepancy) return 'errorcirclefilled'

    return 'checkmarkcirclefilled'
  }

  const getConsolidated = async (origin: string) => {
    await get(`${baseURLReconciliation}/consolidated`, {
      params: { ...options, origin: origin },
    }).then((res) => {
      switch (origin) {
        case 'Shipay':
          setShipayData(res.data)
          break
        case 'PSP':
          setPspData(res.data)
          break
        case 'Processadora':
          setProcessorData(res.data)
          break
      }
    })
  }

  React.useEffect(() => {
    if (options?.wallet_setting_id) {
      getConsolidated('Shipay')
      getConsolidated('PSP')
      getConsolidated('Processadora')
    }
  }, [options])

  return (
    <>
      <ReconciliationDetailsModal
        totals={[shipayData, pspData, procesorData]}
        options={options}
        isOpen={detailsModal}
        handleClose={() => {
          setDetailsModal(false)
        }}
      />
      <s.Card height={364}>
        <s.Button
          color="secondary"
          outline
          onClick={(e) => {
            setDetailsModal(true)
          }}
        >
          <s.Text type="headline" color="maincolor" bold>
            Detalhes
          </s.Text>
        </s.Button>
        <s.ContentWrapper>
          <s.Icon name="payments" fill="maincolor" />
          <s.Text type="headline" color="maincolor" bold>
            Valor Consolidado
          </s.Text>
          <s.Text type="headline" color="maincolor" bold>
            {formatMoney(options.consolidated_customer_value)}
          </s.Text>

          <s.Icon
            name={getIcon(shipayData?.discrepancy)}
            fill={getColor(shipayData?.discrepancy)}
          />
          <s.Text
            type="headline"
            color={getColor(shipayData?.discrepancy)}
            bold
          >
            Pix Pagos
          </s.Text>
          <s.Text
            type="headline"
            color={getColor(shipayData?.discrepancy)}
            bold
          >
            {formatMoney(shipayData?.sum_orders_value)}
          </s.Text>

          <s.Icon
            name={getIcon(pspData?.discrepancy)}
            fill={getColor(pspData?.discrepancy)}
          />
          <s.Text type="headline" color={getColor(pspData?.discrepancy)} bold>
            Pix Recebidos
          </s.Text>
          <s.Text type="headline" color={getColor(pspData?.discrepancy)} bold>
            {formatMoney(pspData?.sum_orders_value)}
          </s.Text>

          <s.Icon
            name={getIcon(procesorData?.discrepancy)}
            fill={getColor(procesorData?.discrepancy)}
          />
          <s.Text
            type="headline"
            color={getColor(procesorData?.discrepancy)}
            bold
          >
            Pix Processados
          </s.Text>
          <s.Text
            type="headline"
            color={getColor(procesorData?.discrepancy)}
            bold
          >
            {formatMoney(procesorData?.sum_orders_value)}
          </s.Text>
        </s.ContentWrapper>
      </s.Card>
    </>
  )
}

export default ReconciliationDetails
