import React, { useEffect } from 'react'
import { v4 as uuid } from 'uuid'

import { useNonInitialEffect } from 'hooks/utils'

import * as s from './styles'

interface IProps {
  count: number
  dataCount?: number
  offset: string
  total: number
  handleChange: any
  paginationUpdateRef?: React.MutableRefObject<boolean>
}

const renderButton = (page, setPage) => {
  return (
    <s.Button
      color="transparent"
      onClick={() => {
        setPage(page)
      }}
    >
      {page}
    </s.Button>
  )
}

const renderMidbuttons = (
  currentPage,
  setCurrentPage,
  lastPage,
  total,
  count
) => {
  if (currentPage > 4 && currentPage < lastPage - 3) {
    return (
      <>
        {renderButton(1, setCurrentPage)}
        <s.Button color="white">...</s.Button>
        {renderButton(currentPage - 1, setCurrentPage)}
        <s.Button selected color="graytheme1">
          {currentPage}
        </s.Button>
        {renderButton(currentPage + 1, setCurrentPage)}
        <s.Button color="white">...</s.Button>
        {renderButton(lastPage, setCurrentPage)}
      </>
    )
  }
  if (currentPage === 1) {
    const nPages = Math.ceil(total / (currentPage * count))
    if (nPages <= 3) {
      const buttons = Array(nPages - 1)
        .fill(1)
        .map((_, index, arr) => {
          return (
            <React.Fragment key={uuid()}>
              {renderButton(index + 2, setCurrentPage)}
            </React.Fragment>
          )
        })
      return (
        <>
          <s.Button selected color="graytheme1">
            1
          </s.Button>
          {buttons}
        </>
      )
    }
    return (
      <>
        <s.Button selected color="graytheme1">
          1
        </s.Button>
        {total <= currentPage * count ? null : (
          <>
            {renderButton(2, setCurrentPage)}
            {renderButton(3, setCurrentPage)}
            <s.Button color="white">...</s.Button>
            {renderButton(lastPage, setCurrentPage)}
          </>
        )}
      </>
    )
  }
  if (currentPage === 2) {
    return (
      <>
        {renderButton(1, setCurrentPage)}
        <s.Button selected color="graytheme1">
          2
        </s.Button>
        {total <= currentPage * count ? null : (
          <>
            {renderButton(3, setCurrentPage)}
            {currentPage + 1 >= lastPage ? null : (
              <>
                <s.Button color="white">...</s.Button>
                {renderButton(lastPage, setCurrentPage)}
              </>
            )}
          </>
        )}
      </>
    )
  }
  if (currentPage === 3) {
    return (
      <>
        {renderButton(1, setCurrentPage)}
        {renderButton(2, setCurrentPage)}
        <s.Button selected color="graytheme1">
          3
        </s.Button>
        {total <= currentPage * count ? null : (
          <>
            {renderButton(4, setCurrentPage)}
            {currentPage + 2 > lastPage ? null : (
              <>
                <s.Button color="white">...</s.Button>
                {renderButton(lastPage, setCurrentPage)}
              </>
            )}
          </>
        )}
      </>
    )
  }
  if (currentPage === 4) {
    return (
      <>
        {renderButton(1, setCurrentPage)}
        {renderButton(2, setCurrentPage)}
        {renderButton(3, setCurrentPage)}
        <s.Button selected color="graytheme1">
          4
        </s.Button>
        {total <= currentPage * count ? null : (
          <>
            {renderButton(5, setCurrentPage)}
            {currentPage + 2 > lastPage ? null : (
              <>
                <s.Button color="white">...</s.Button>
                {renderButton(lastPage, setCurrentPage)}
              </>
            )}
          </>
        )}
      </>
    )
  }
  if (currentPage === lastPage) {
    return (
      <>
        {renderButton(1, setCurrentPage)}
        <s.Button color="white">...</s.Button>
        {renderButton(currentPage - 2, setCurrentPage)}
        {renderButton(currentPage - 1, setCurrentPage)}
        <s.Button selected color="graytheme1">
          {currentPage}
        </s.Button>
      </>
    )
  }
  if (currentPage === lastPage - 1) {
    return (
      <>
        {renderButton(1, setCurrentPage)}
        <s.Button color="white">...</s.Button>
        {renderButton(currentPage - 1, setCurrentPage)}
        <s.Button selected color="graytheme1">
          {currentPage}
        </s.Button>
        {renderButton(lastPage, setCurrentPage)}
      </>
    )
  }
  if (currentPage === lastPage - 2) {
    return (
      <>
        {renderButton(1, setCurrentPage)}
        <s.Button color="white">...</s.Button>
        {renderButton(currentPage - 1, setCurrentPage)}
        <s.Button selected color="graytheme1">
          {currentPage}
        </s.Button>
        {renderButton(currentPage + 1, setCurrentPage)}
        {renderButton(lastPage, setCurrentPage)}
      </>
    )
  }
  if (currentPage === lastPage - 3) {
    return (
      <>
        {renderButton(1, setCurrentPage)}
        <s.Button color="white">...</s.Button>
        {renderButton(currentPage - 1, setCurrentPage)}
        <s.Button selected color="graytheme1">
          {currentPage}
        </s.Button>
        {renderButton(lastPage - 2, setCurrentPage)}
        {renderButton(lastPage - 1, setCurrentPage)}
        {renderButton(lastPage, setCurrentPage)}
      </>
    )
  }
}

const checkBorder = (currentPage, lastPage, setCurrentPage, next) => {
  if (currentPage === 1) {
    if (next) {
      setCurrentPage(currentPage + 1)
    } else {
      setCurrentPage(lastPage)
    }
  } else if (currentPage === lastPage) {
    if (next) {
      setCurrentPage(1)
    } else setCurrentPage(currentPage - 1)
  } else if (next) {
    setCurrentPage(currentPage + 1)
  } else setCurrentPage(currentPage - 1)
}

const Pagination: React.FC<IProps> = ({
  count,
  offset,
  total,
  handleChange,
  paginationUpdateRef,
  ...rest
}) => {
  const [currentPage, setCurrentPage] = React.useState(1)
  const [currentOffset, setCurrentOffset] = React.useState(parseInt(offset))
  const paginationUpdate = paginationUpdateRef || { current: false }

  useEffect(() => {
    if (paginationUpdate.current) {
      if (currentPage === 1) {
        paginationUpdate.current = false
      } else {
        setCurrentPage(1)
      }
    }
  }, [paginationUpdate.current])

  useEffect(() => {
    let newOffset = (currentPage - 1) * count
    if (newOffset < total) {
      paginationUpdate.current = false
      setCurrentOffset(newOffset)
    }
  }, [currentPage, count])

  useEffect(() => {
    if (paginationUpdate.current) {
      setCurrentPage(1)
      setCurrentOffset(0)
    }
  }, [paginationUpdate.current])

  useNonInitialEffect(() => {
    handleChange('' + currentOffset)
  }, [currentOffset])

  let lastPage

  if (total <= count) {
    lastPage = 1
  } else {
    lastPage =
      total % count === 0
        ? Math.floor(total / count)
        : Math.floor(total / count) + 1
  }

  return (
    <>
      {total > 0 ? (
        <s.PaginationContainer {...rest}>
          {currentPage != 1 ? (
            <s.SideButton
              color="white"
              onClick={() => {
                checkBorder(currentPage, lastPage, setCurrentPage, false)
              }}
            >
              <s.Icon name="arrowleft" fill="maincolor"></s.Icon> Anterior
            </s.SideButton>
          ) : null}
          {renderMidbuttons(
            currentPage,
            setCurrentPage,
            lastPage,
            total,
            count
          )}
          {currentPage != lastPage ? (
            <s.SideButton
              color="white"
              onClick={() => {
                checkBorder(currentPage, lastPage, setCurrentPage, true)
              }}
            >
              Próxima <s.Icon name="arrowright" fill="maincolor"></s.Icon>
            </s.SideButton>
          ) : null}
        </s.PaginationContainer>
      ) : null}
    </>
  )
}

export const CursorPagination: React.FC<Omit<IProps, "total">> = ({
  count,
  offset,
  dataCount,
  handleChange,
  paginationUpdateRef,
  ...rest
}) => {
  const [currentPage, setCurrentPage] = React.useState(1)
  const [currentOffset, setCurrentOffset] = React.useState(parseInt(offset))
  const paginationUpdate = paginationUpdateRef || { current: false }

  useEffect(() => {
    if (paginationUpdate.current) {
      if (currentPage === 1) {
        paginationUpdate.current = false
      } else {
        setCurrentPage(1)
      }
    }
  }, [paginationUpdate.current])

  useEffect(() => {
    let newOffset = (currentPage - 1) * count
    setCurrentOffset(newOffset)
  }, [currentPage, count])

  useEffect(() => {
    if (paginationUpdate.current) {
      setCurrentPage(1)
      setCurrentOffset(0)
    }
  }, [paginationUpdate.current])

  useNonInitialEffect(() => {
    handleChange('' + currentOffset)
  }, [currentOffset])
  
  return (
    <>
      <s.PaginationContainer {...rest}>
        {currentPage != 1 && dataCount != 1 ?  (
          <s.SideButton
            color="white"
            onClick={() => {
              setCurrentPage(currentPage - 1)
            }}
          >
            <s.Icon name="arrowleft" fill="maincolor"></s.Icon> Anterior
          </s.SideButton>
        ) : null}
        {dataCount === count ? (
          <s.SideButton
            color="white"
            onClick={() => {
              setCurrentPage(currentPage + 1)
            }}
          >
            Próxima <s.Icon name="arrowright" fill="maincolor"></s.Icon>
          </s.SideButton>
        ) : null}
      </s.PaginationContainer>
    </>
  )
}
export default Pagination
