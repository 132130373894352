import React, { Dispatch, SetStateAction, useState } from 'react'
import { NewPixKeyInput, PixKeyType } from '../components/NewPixKeyInput'
import {
  applyBankAccountItauMask,
  applyCnpjMask,
  applyCpfCnpjMask,
  applyNumberOnlyMask,
  applyPhoneMask,
} from 'helpers/masks'
import { ModalConfig } from 'hooks/useGetWalletSettingModalConfig'
import { dict as dictIcons } from 'components/IconExporter'
import loadingbigger from 'assets/images/loading-bigger.png'
import { MessageBoxDefault } from '../components/MessageBoxDefault'

import * as s from './styles'

const InfoBox: React.FC<{
  error?: { title: string; message: React.ReactElement }
}> = ({ error }) => {
  if (error?.title) {
    return (
      <s.InfoBox error>
        <s.Text bold type="paragraph" color="redshipay">
          {error.title}
        </s.Text>
        <s.Text type="paragraph" color="cancelledStatusTextColor">
          {error.message}
        </s.Text>
      </s.InfoBox>
    )
  }

  return <></>
}

interface IProps {
  pixProviderName: string
  modalConfigState: [
    ModalConfig,
    React.Dispatch<React.SetStateAction<ModalConfig>>
  ]
  formState: [any, React.Dispatch<React.SetStateAction<any>>]
  formErrorState: [any, React.Dispatch<React.SetStateAction<any>>]
  infoErrorState?: [
    { title: string; message: React.ReactElement },
    Dispatch<SetStateAction<{ title: string; message: React.ReactElement }>>
  ]
  toggleSection: (sectionName: string) => void
  walletIconUrl: string
  isFormLoading: boolean
}

const RegisterFormDefault: React.FC<IProps> = ({
  modalConfigState,
  formState,
  formErrorState,
  isFormLoading,
  infoErrorState,
  walletIconUrl,
  pixProviderName,
  toggleSection,
}) => {
  const [modalConfig, setModalConfig] = modalConfigState
  const [form, setForm] = formState
  const [selectedWalletCode, setSelectWalletCode] = useState({
    id: '',
    name: '',
  })
  const [selectedVisibleCertificate, setSelectedVisibleCertificate] = useState({
    id: '',
    name: '',
  })
  const [selectedCertificate, setSelectedCertificate] = useState('')
  const [selectedCNPJ, setSelectedCNPJ] = useState({
    id: '',
    name: '',
  })
  const [formErrors, setFormErrors] = formErrorState
  const [infoError, setInfoError] = infoErrorState

  const selectedType = React.useRef<PixKeyType>({
    id: 5,
    type: 'random_key',
    name: 'Aleatória',
  })

  const applyFieldMask = (field, value) => {
    if (field.validators) {
      if (field.validators.includes('isValidCpfCnpj'))
        return applyCpfCnpjMask(value)
      if (field.validators.includes('isValidCnpj')) return applyCnpjMask(value)
      if (field.validators.includes('isValidPhone'))
        return applyPhoneMask(value)
      if (field.validators.includes('isNumberOnly'))
        return applyNumberOnlyMask(value)
      if (field.validators.includes('isBankAccountItau'))
        return applyBankAccountItauMask(value)
    }
    return value
  }

  const displaySelectValue = (field) => {
    switch (field) {
      case 'wallet_code':
        return selectedWalletCode
      case 'tls_certificate':
        return selectedVisibleCertificate
      case 'document_number':
        return selectedCNPJ
    }
  }

  const InputElement: React.FC<{
    key: string
    friendlyName: string
    valueType: string
    value?: string
    options?: Array<string> | Array<{ visible: string; selectable: string }>
    inputType: string
    placeholder: string
    maxLength: number
    validators: string[]
  }> = (field) => {
    switch (field.inputType) {
      case 'text':
        return (
          <s.SectionLine>
            <s.Text
              fontWeight={600}
              type="headline"
              color={formErrors[field.key] ? 'redshipay' : 'graytheme6'}
            >
              {field.friendlyName}
            </s.Text>
            <s.InputText
              placeholder={field.placeholder}
              maxLength={field.maxLength ?? undefined}
              testId={`${field.key}-test`}
              width={260}
              value={form[field.key] || ''}
              onChange={(e) => {
                setForm({
                  ...form,
                  [field.key]: applyFieldMask(field, e.target.value),
                })
                if (formErrors[field.key]) {
                  setFormErrors({ ...formErrors, [field.key]: '' })
                }
              }}
              error={
                formErrors[field.key]
                  ? { message: formErrors[field.key] }
                  : false
              }
              suffix={
                formErrors[field.key] ? (
                  <s.Icon name="alertcirclefilled" fill="lightred2" />
                ) : (
                  <></>
                )
              }
            />
          </s.SectionLine>
        )

      case 'select':
        let list = []
        if (field.options.length > 0) {
          if (field.key === 'tls_certificate') {
            if (
              typeof field.options[0] === 'object' &&
              'visible' in field.options[0] &&
              'selectable' in field.options[0]
            ) {
              list = field.options.map((option, index) => {
                return {
                  id: option.selectable,
                  text: option.visible,
                  onClick: () => {
                    setSelectedVisibleCertificate({
                      id: option.selectable,
                      name: option.visible,
                    })
                    setSelectedCertificate(option.selectable)
                  },
                }
              })
            }
          } else {
            const options = field.options.map((value, index) => {
              return {
                id: String(index + 1),
                text: value,
              }
            })
            switch (field.key) {
              case 'wallet_code':
                list = options.map((x) => {
                  return {
                    ...x,
                    onClick: () => {
                      setSelectWalletCode({ id: x.id, name: x.text })
                    },
                  }
                })
                break

              case 'document_number':
                list = options.map((x) => {
                  return {
                    ...x,
                    onClick: () => {
                      setSelectedCNPJ({ id: x.id, name: x.text })
                    },
                  }
                })
                break
            }
          }
        }
        return (
          <s.SectionLine>
            <s.Text
              fontWeight={600}
              type="headline"
              color={formErrors[field.key] ? 'redshipay' : 'graytheme6'}
            >
              {field.friendlyName}
            </s.Text>
            <s.Select
              optionBoxSize={150}
              width={260}
              testId={`${field.key}-test`}
              optionBoxDirection="top"
              options={list}
              error={
                formErrors[field.key]
                  ? { message: formErrors[field.key] }
                  : false
              }
              selected={displaySelectValue(field.key)}
              onSelectedOption={(selectedOption) => {
                if (selectedOption.id) {
                  if (field.key === 'tls_certificate') {
                    setForm({
                      ...form,
                      [field.key]: applyFieldMask(field, selectedOption.id),
                    })
                  } else {
                    setForm({
                      ...form,
                      [field.key]: applyFieldMask(field, selectedOption.text),
                    })
                  }
                  if (formErrors[field.key]) {
                    setFormErrors({ ...formErrors, [field.key]: '' })
                  }
                }
              }}
            />
          </s.SectionLine>
        )

      case 'dictKey':
        return (
          <NewPixKeyInput
            selectSize={190}
            testId={`${field.key}-test`}
            value={form[field.key] || ''}
            selectedType={selectedType}
            pixKeyErr={formErrors[field.key]}
            onChange={(value) => {
              setForm({
                ...form,
                [field.key]: value,
                pix_dict_key_type: selectedType.current.type,
              })
            }}
            onPixKeyErr={(err) => {
              setFormErrors({
                ...formErrors,
                [field.key]: err,
              })
            }}
          />
        )
      default:
        return <></>
    }
  }
  return (
    <s.Sections data-testid="content-on-register-form">
      <s.SectionsRegisterFormWrapper>
        <s.Section>
          {modalConfig.helper_link ? (
            <MessageBoxDefault url={modalConfig.helper_link} />
          ) : (
            <></>
          )}
          <InfoBox error={infoError} />
          <s.ProviderContainer margin="8px 0 0 0">
            <s.Text
              fontWeight={600}
              type="headline"
              margin="0 106px 0 0"
              color="graytheme8"
            >
              Provedor Pix
            </s.Text>
            <img src={walletIconUrl} width={34} />
            <s.Text bold type="headline" margin="0 0 0 8px" color="graytheme8">
              {pixProviderName}
            </s.Text>
          </s.ProviderContainer>
          <s.SectionLine>
            <s.Text
              fontWeight={600}
              type="headline"
              color={formErrors.name ? 'redshipay' : 'graytheme6'}
            >
              Apelido da conta
            </s.Text>
            <s.InputText
              width={260}
              testId="form-name"
              value={form.name}
              onChange={(e) => {
                setForm({ ...form, name: e.target.value })
                if (formErrors.name) {
                  setFormErrors({ ...formErrors, name: '' })
                }
              }}
              error={formErrors.name ? { message: formErrors.name } : false}
              suffix={
                formErrors.name ? (
                  <s.Icon name="alertcirclefilled" fill="lightred2" />
                ) : (
                  <></>
                )
              }
            />
          </s.SectionLine>
        </s.Section>
        {isFormLoading ? (
          <>
            <s.Line />
            <s.Section>
              <s.SmallerLoadingGradient>
                <img
                  width={100}
                  src={loadingbigger}
                  alt="ícone de carregamento"
                  data-testid="bigger-loading-spinner"
                />
              </s.SmallerLoadingGradient>
            </s.Section>
          </>
        ) : (
          <>
            {modalConfig.sections?.map((section) => (
              <React.Fragment key={section?.key}>
                <s.Line />
                <s.Section>
                  <s.WrapperSectionHeader>
                    <s.Align>
                      <s.Icon
                        name={section?.icon as keyof typeof dictIcons}
                        fill="graytheme6"
                      />
                      <s.Text
                        margin="0 0 0 8px"
                        type="headline"
                        fontWeight={600}
                        color="graytheme6"
                      >
                        {section?.friendlyName}
                      </s.Text>
                    </s.Align>
                    {section?.toggleButton ? (
                      <s.AddBankSlipButton
                        onClick={() => toggleSection(section.key)}
                        data-testid="add-bank-slip-button"
                      >
                        {section.toggled ? (
                          <s.Icon
                            name={
                              section.toggleButton?.active
                                .icon as keyof typeof dictIcons
                            }
                            fill="maincolor"
                          />
                        ) : (
                          <s.Icon
                            name={
                              section.toggleButton?.inactive
                                .icon as keyof typeof dictIcons
                            }
                            fill="maincolor"
                          />
                        )}
                        <s.Text
                          margin="0 0 0 8px"
                          type="headline"
                          fontWeight={500}
                          color="maincolor"
                        >
                          {section.toggled
                            ? section.toggleButton?.active.friendlyName
                            : section.toggleButton?.inactive.friendlyName}
                        </s.Text>
                      </s.AddBankSlipButton>
                    ) : (
                      <></>
                    )}
                  </s.WrapperSectionHeader>
                  {section?.toggled ? (
                    section.fields.map((field) => {
                      return (
                        <React.Fragment key={field.key}>
                          {InputElement({
                            friendlyName: field.friendlyName,
                            inputType: field.inputType,
                            key: field.key,
                            value: field.value,
                            options: field.options,
                            maxLength: field.maxLength,
                            placeholder: field.placeholder,
                            valueType: field.valueType,
                            validators: field.validators,
                          })}
                        </React.Fragment>
                      )
                    })
                  ) : (
                    <></>
                  )}
                </s.Section>
              </React.Fragment>
            ))}
          </>
        )}
      </s.SectionsRegisterFormWrapper>
    </s.Sections>
  )
}

export default RegisterFormDefault
