import { Types, PayloadInterface } from './types'

export function GetGeoAddress(
  payload: PayloadInterface
): {
  type: string
  payload: PayloadInterface
} {
  return {
    type: Types.GET_GEO_ADDRESS,
    payload,
  }
}

export function GetGeoAddressLoading(): {
  type: string
} {
  return {
    type: Types.GET_GEO_ADDRESS_LOADING,
  }
}

export function GetGeoAddressError(): {
  type: string
} {
  return {
    type: Types.GET_GEO_ADDRESS_ERROR,
  }
}

export function ResetGeoAddress(): {
  type: string
} {
  return {
    type: Types.RESET_GEO_ADDRESS,
  }
}
