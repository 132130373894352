import {
  Types,
  WalletSettingsInterface,
  RequestInterface,
  WalletSetting,
} from './types'

export function GetWalletsSettingsList(
  payload: WalletSettingsInterface[]
): {
  type: string
  payload: WalletSettingsInterface[]
} {
  return {
    type: Types.GET_WALLETS_SETTINGS,
    payload,
  }
}

export function GetWalletsSettingsListIsLoading(): {
  type: string
} {
  return {
    type: Types.GET_WALLETS_SETTINGS_LOADING,
  }
}

export function GetWalletsSettingsListError(): {
  type: string
} {
  return {
    type: Types.GET_WALLETS_SETTINGS_ERROR,
  }
}

export function PatchWalletSettingsRequest(
  payload
): {
  payload: RequestInterface
  type: string
} {
  return {
    type: Types.PATCH_WALLET_SETTINGS_REQUEST,
    payload,
  }
}

export function PatchWalletSettingsResponse(payload) {
  return {
    type: Types.PATCH_WALLET_SETTINGS_RESPONSE,
    payload,
  }
}

export function PatchWalletSettingsError(message: string) {
  return {
    type: Types.PATCH_WALLET_SETTINGS_ERROR,
    message,
  }
}

export function GetWalletSetting(
  payload: WalletSetting
): {
  type: string
  payload: WalletSetting
} {
  return {
    type: Types.GET_WALLET_SETTING,
    payload,
  }
}

export function GetWalletSettingIsLoading(): {
  type: string
} {
  return {
    type: Types.GET_WALLET_SETTING_LOADING,
  }
}

export function GetWalletSettingError(): {
  type: string
} {
  return {
    type: Types.GET_WALLET_SETTING_ERROR,
  }
}

export function PostWalletSettingLoading(): {
  type: string
} {
  return {
    type: Types.POST_WALLET_SETTING_LOADING,
  }
}

export function PostWalletSettingResponse(postResponse) {
  return {
    type: Types.POST_WALLET_SETTING_RESPONSE,
    postResponse,
  }
}

export function PostWalletSettingError(postErrorMessage: string) {
  return {
    type: Types.POST_WALLET_SETTING_ERROR,
    postErrorMessage,
  }
}
