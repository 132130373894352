import * as s from './styles'

interface IProps {
  isOpen: boolean,
  handleClose: (res: boolean) => void
  newStatus: string
}

const PanelQueryConfirmModal: React.FC<IProps> = ({ isOpen, handleClose, newStatus }) => {
  return (
    <s.PanelQueryModal
      isOpen={isOpen}
      modalSubtitle={`Deseja alterar o status para ${newStatus} ?`}
      handleClose={() => handleClose(false)}>
      <s.PanelQueryModalContent>
        <s.PanelQueryButton onClick={() => handleClose(true)}>
          Continuar
        </s.PanelQueryButton>
        <s.Button color="tertiary" onClick={() => handleClose(false) }>
          Cancelar
        </s.Button>
      </s.PanelQueryModalContent>
    </s.PanelQueryModal>
  )
}

export default PanelQueryConfirmModal
