import React, { createRef, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'

import * as s from './styles'

export type LogoOrFavicon = 'logo' | 'icon'

interface IProps {
  title?: string
  type: LogoOrFavicon
  componentId?: string
  required?: boolean
  value: File
  hasFileAlready?: boolean
  handleChange: (e: File) => void
  clear: () => void
}

interface ISummaryImages {
  mainlogo?: string
  favicon?: string
  component_id?: string
}

const UploadImageFile: React.FC<IProps> = ({
  title,
  type,
  hasFileAlready = false,
  componentId,
  required,
  value,
  handleChange,
  clear,
  ...rest
}) => {
  const inputFile = createRef<HTMLInputElement | null>()

  const [hasImage, setHasImage] = useState<boolean>(hasFileAlready)
  const [errorMessages, setErrorMessages] = useState<Map<string, string>>(
    new Map()
  )
  const [errorIsEmpty, setErrorIsEmpty] = useState<boolean>(required)

  const maxLogoSize = 100000 // 100kbytes
  const maxIconSize = 12000 // 12kbytes

  const fileSizeLimit = type == 'logo' ? maxLogoSize : maxIconSize

  const validateAndSelectFile = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: 'logo' | 'icon'
  ) => {
    if (event && event.target.files.length > 0) {
      const file = event.target.files[0]

      const newErrorMessages: Map<string, string> = new Map()
      const errorMapper = {
        format: 'Formato de arquivo não compatível',
        size: `Arquivo maior que o tamanho máximo de ${(
          fileSizeLimit / 1000
        ).toFixed(1)} kB`,
      }

      const isInvalidFormat =
        type === 'logo'
          ? file.type !== 'image/png' &&
            file.type !== 'image/jpeg' &&
            file.type !== 'image/jpg' &&
            file.type !== 'image/svg'
          : file.type !== 'image/png' &&
            file.type !== 'image/ico' &&
            file.type !== 'image/x-icon'

      const isInvalidSize =
        type === 'logo' ? file.size >= maxLogoSize : file.size >= maxIconSize

      if (isInvalidFormat) {
        newErrorMessages.set('format', errorMapper['format'])
      }

      if (isInvalidSize) {
        newErrorMessages.set('size', errorMapper['size'])
      }

      setErrorMessages(newErrorMessages)

      const isValidFile = newErrorMessages.size === 0

      if (isValidFile) {
        setHasImage(true)
        handleChange(file)
      } else {
        setHasImage(false)
      }

      event.target.value = ''
      return isValidFile
    }

    return false
  }

  const onFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      validateAndSelectFile(event, type)
    }
  }

  const onButtonClick = () => {
    inputFile.current.click()
  }

  useEffect(() => {
    setErrorIsEmpty(required)
  }, [required])

  useEffect(() => {
    setHasImage(hasFileAlready)
  }, [hasFileAlready])

  return (
    <div {...rest}>
      {title ? (
        <s.Title color={'maincolor'} bold>
          {title}
        </s.Title>
      ) : null}
      {hasImage && value ? (
        <>
          <s.UploadWrapper>
            <s.Icon width={20} height={20} fill="maincolor" name="paper" />
            <s.UploadContent>
              <s.InputUploadedImage
                data-testid={`input-uploaded-image-${type}`}
                ref={inputFile}
                type="file"
                accept={
                  type === 'logo' ? '.png, .jpeg, .jpg, .svg' : '.png, .ico'
                }
                title="Clique para fazer Upload."
                onChange={onFileSelect}
              />
              <s.InputText
                data-testid={`input-uploaded-image-name-${type}`}
                onClick={onButtonClick}
              >
                {value.name}
              </s.InputText>
            </s.UploadContent>
            <s.Icon
              width={20}
              height={20}
              name="closecircle"
              onClick={() => {
                setHasImage(false)
                setErrorIsEmpty(false)
                clear()
              }}
            />
          </s.UploadWrapper>
        </>
      ) : (
        <>
          <s.UploadContainer>
            <s.InputUploadImage
              data-testid={`input-upload-image-${type}`}
              ref={inputFile}
              type="file"
              accept={
                type === 'logo' ? '.png, .jpeg, .jpg, .svg' : '.png, .ico'
              }
              onChange={onFileSelect}
            />
            <s.Button
              width="304px"
              height="40px"
              hasError={errorMessages.size > 0}
              outline
              title="Clique para fazer upload."
              color="white"
              disabled={false}
              onClick={onButtonClick}
            >
              <s.Icon
                width={20}
                height={20}
                name="uploadimage"
                fill={
                  errorMessages.size > 0
                    ? 'cancelledStatusTextColor'
                    : 'maincolor'
                }
              />
              <s.Text
                color={
                  errorMessages.size > 0
                    ? 'cancelledStatusTextColor'
                    : 'maincolor'
                }
                bold
              >
                Selecionar Arquivo
              </s.Text>
            </s.Button>
          </s.UploadContainer>
        </>
      )}
      <s.UploadFormatErrors>
        {errorMessages.size > 0 ? (
          Array.from(errorMessages).map((errorMsgs) => (
            <s.TextError data-testid={`invalid-file-type-${type}`} key={uuid()}>
              {errorMsgs[1]}
            </s.TextError>
          ))
        ) : (
          <></>
        )}
        {errorIsEmpty ? <s.TextError>Campo Obrigatório</s.TextError> : <></>}
        {errorMessages.size > 0 || errorIsEmpty ? <s.Space /> : <></>}
      </s.UploadFormatErrors>
      <s.UploadFormatRules>
        <s.Text
          color={
            errorMessages.has('format')
              ? 'cancelledStatusTextColor'
              : 'graytheme6'
          }
        >
          Formatos de arquivo:
          <strong>
            {type == 'logo'
              ? ' [.png] [.jpg] [.jpeg] [.svg]'
              : ' [.png] [.ico]'}
          </strong>
        </s.Text>
        <s.Text
          color={
            errorMessages.has('size')
              ? 'cancelledStatusTextColor'
              : 'graytheme6'
          }
        >
          Tamanho máximo: <strong>{fileSizeLimit / 1000} kB</strong>
        </s.Text>
        <s.Text>
          Dimensão recomendada:
          <strong>{type == 'logo' ? ' 158px x 30px' : ' 32px x 32px'}</strong>
        </s.Text>
      </s.UploadFormatRules>
    </div>
  )
}

export default UploadImageFile
