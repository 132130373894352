import * as s from './styles'
import Toast, { showToast } from 'components/Toast'
import React, { SetStateAction } from 'react'
import useGetProfile from 'hooks/useGetProfile'
import useStorePos from 'hooks/useStorePos'
import useGetStores from 'hooks/useGetStores'
import { useHistory } from 'react-router'
import { useTheme } from 'styled-components'

interface ISystemIntegration {
  secondStepIsValid?: boolean
  setSecondStepIsValid?: React.Dispatch<SetStateAction<boolean>>
  nextStep?: React.Dispatch<React.SetStateAction<number>>
  previousStep?: React.Dispatch<React.SetStateAction<number>>
}

const SystemIntegration: React.FC<ISystemIntegration> = ({
  nextStep,
  previousStep,
}) => {
  const history = useHistory()

  const [
    displayStorePosPerStoreId,
    setDisplayStorePosPerStoreId,
  ] = React.useState([])

  const {
    profile,
    isLoading: isProfileLoading,
    hasError: hasProfileError,
  } = useGetProfile()

  const {
    storePos,
    storePosIsLoading,
    loadStorePos,
    storePosPerStoreId,
    storePosPerStoreIdHasError,
    storePosPerStoreIdIsLoading,
    loadStorePosPerStoreId,
  } = useStorePos()

  const { stores, hasError, isLoading, loadStores } = useGetStores()
  const [posProduct, setPosProduct] = React.useState<string>('')

  const loadStoresAndPerStoreId = React.useCallback(() => {
    loadStores({
      limit: '1',
      offset: '0',
      active: true,
    })
    loadStorePosPerStoreId(stores.data[0].id, {
      limit: 100,
      offset: 0,
    })
  }, [loadStores, loadStorePosPerStoreId, stores])

  React.useEffect(() => {
    loadStoresAndPerStoreId()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (storePosPerStoreId.total > 0) {
      let storePosPerStoreIdWithoutClientId = storePosPerStoreId.data.map(
        (storePos) => {
          return {
            ...storePos,
            isVisible: false,
            isLoading: false,
            client_id: '',
          }
        }
      )
      setDisplayStorePosPerStoreId([...storePosPerStoreIdWithoutClientId])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storePosPerStoreId])

  React.useEffect(() => {
    if (storePos.client_id) {
      let storePosPerStoreIdWithClientId = displayStorePosPerStoreId
      let index = displayStorePosPerStoreId.findIndex(
        (store) => store.id === storePos.id
      )
      storePosPerStoreIdWithClientId[index].client_id = storePos.client_id
      storePosPerStoreIdWithClientId[index].isLoading = false
      setDisplayStorePosPerStoreId([...storePosPerStoreIdWithClientId])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storePos])

  React.useEffect(() => {
    if (profile.pos_product_name) setPosProduct(profile.pos_product_name)
  }, [isProfileLoading, hasProfileError, profile])

  const renderPosList = () => {
    if (hasError || storePosPerStoreIdHasError) {
      return (
        <s.Text color="maincolor" data-testid="error-text" type="heading3">
          Erro na API, tente novamente mais tarde
        </s.Text>
      )
    }

    if (isLoading || storePosPerStoreIdIsLoading) {
      return (
        <s.LoadingWrapper>
          <s.Text
            data-testid="loading-text-wallets"
            color="maincolor"
            type="heading3"
          >
            Carregando...
          </s.Text>
          <s.Loading
            type="spinner"
            data-testid="loading-spinner1"
            color="maincolor"
          />
        </s.LoadingWrapper>
      )
    }

    if (displayStorePosPerStoreId) {
      return (
        <>
          {displayStorePosPerStoreId.map((singleStorePos) => {
            return (
              <>
                <s.ListElement key={singleStorePos.id}>
                  <s.Icon name="register" fill="white" />
                  <s.Text bold>{singleStorePos.name}</s.Text>
                  <s.Text color="maincolor" bold>
                    Client ID{' '}
                  </s.Text>
                  <s.Text color="maincolor">(ID do Cliente/ID Caixa)</s.Text>
                </s.ListElement>
                <s.KeyContainer>
                  <s.IdContainer>
                    {storePosIsLoading && singleStorePos.isLoading ? (
                      <>
                        <s.ButtonText color="graytheme6">
                          Carregando...
                        </s.ButtonText>
                        <s.Loading type="spinner" color="maincolor" />
                      </>
                    ) : (
                      <s.ButtonText truncate color="graytheme6">
                        {singleStorePos.isVisible
                          ? singleStorePos.client_id
                          : '*****************'}
                      </s.ButtonText>
                    )}

                    <s.Icon
                      onClick={() => {
                        if (singleStorePos.isVisible) {
                          setDisplayStorePosPerStoreId(
                            displayStorePosPerStoreId.map((displayStore) =>
                              displayStore.id === singleStorePos.id
                                ? { ...displayStore, isVisible: false }
                                : displayStore
                            )
                          )
                        } else if (
                          !singleStorePos.isVisible &&
                          singleStorePos.client_id
                        ) {
                          setDisplayStorePosPerStoreId(
                            displayStorePosPerStoreId.map((displayStore) =>
                              displayStore.id === singleStorePos.id
                                ? { ...displayStore, isVisible: true }
                                : displayStore
                            )
                          )
                        } else if (
                          !singleStorePos.isVisible &&
                          !singleStorePos.client_id
                        ) {
                          loadStorePos(singleStorePos.id)
                          setDisplayStorePosPerStoreId(
                            displayStorePosPerStoreId.map((displayStore) =>
                              displayStore.id === singleStorePos.id
                                ? {
                                    ...displayStore,
                                    isVisible: true,
                                    isLoading: true,
                                  }
                                : displayStore
                            )
                          )
                        }
                      }}
                      width={24}
                      height={24}
                      fill="maincolor"
                      name={singleStorePos.isVisible ? 'eyeoff' : 'eyeon'}
                    />
                  </s.IdContainer>

                  <s.CopyKeyButton
                    onClick={() => {
                      copyToClipboard(singleStorePos)
                    }}
                  >
                    Copiar
                    <s.Icon
                      width={24}
                      height={24}
                      fill="maincolor"
                      name="clipboard"
                    />
                  </s.CopyKeyButton>
                </s.KeyContainer>
              </>
            )
          })}
        </>
      )
    }
  }

  const copyToClipboard = async (storePosSelected) => {
    if (storePosSelected.client_id) {
      navigator.clipboard.writeText(storePosSelected.client_id)
      showToast({ type: 'success', message: 'ID Copiado!' })
    } else {
      await loadStorePos(storePosSelected.id, true).then(() => {
        showToast({ type: 'success', message: 'ID Copiado!' })
      })
    }
  }

  const theme = useTheme()

  return (
    <s.Background>
      <Toast />
      <s.BackgroundFilter>
        <s.Container>
          <s.TextWrapper>
            <s.Text bold type="heading3" color="graytheme6">
              Integração com o Sistema {posProduct}
            </s.Text>
            <s.Text color="maincolor">
              Configure seus códigos na plataforma do seu Sistema Integrado para
              poder transacionar.
            </s.Text>
          </s.TextWrapper>

          <s.Card width={916}>
            <s.List>
              <s.ListElement>
                <s.Text color="maincolor" bold>
                  1.
                </s.Text>
                <s.Text color="graytheme6">
                  Acesse a plataforma do seu sistema
                </s.Text>
                <s.Text color="graytheme6" bold>
                  {posProduct}
                </s.Text>
              </s.ListElement>
              <s.ListElement>
                <s.Text color="maincolor" bold>
                  2.
                </s.Text>
                <s.Text color="graytheme6">Busque as</s.Text>
                <s.Text color="graytheme6" bold>
                  configurações de integração
                </s.Text>
                <s.Text color="graytheme6">com a</s.Text>
                <s.Text color="graytheme6" bold>
                  {theme.domain}
                </s.Text>
              </s.ListElement>
              <s.ListElement>
                <s.Text color="maincolor" bold>
                  3.
                </s.Text>
                <s.Text color="graytheme6" bold>
                  Copie
                </s.Text>
                <s.Text color="graytheme6">os códigos abaixo e</s.Text>
                <s.Text color="graytheme6" bold>
                  insira
                </s.Text>
                <s.Text color="graytheme6">
                  nos campos solicitados pelo seu sistema
                </s.Text>
              </s.ListElement>
              <s.ListElement>
                <s.Text color="maincolor" bold>
                  4.
                </s.Text>
                <s.Text color="graytheme6">
                  Em caso de dúvidas, entre em contato com o
                </s.Text>
                <s.Text color="graytheme6">suporte</s.Text>
                <s.Text color="graytheme6">do seu sistema por</s.Text>
                <s.Text color="graytheme6" bold>
                  e-mail
                </s.Text>
              </s.ListElement>
            </s.List>

            {!isProfileLoading &&
            !hasProfileError &&
            profile.pos_product_email ? (
              <s.EmailWrapper>
                <s.Text color="maincolor" bold>
                  {profile.pos_product_email}
                </s.Text>
              </s.EmailWrapper>
            ) : null}

            <s.SubSectionHeader bold>Códigos da Conta</s.SubSectionHeader>

            <s.SubTextLabel>
              <s.Text>
                Insira ambos os códigos abaixo ao cadastrar cada caixa em seu
                sistema.
              </s.Text>
              <s.Text>
                Eles são sempre os mesmos e servem para todos os Códigos dos
                Caixas.
              </s.Text>
            </s.SubTextLabel>

            <s.List>
              {!isProfileLoading && !hasProfileError && profile.access_key ? (
                <>
                  <s.ListElement>
                    <s.Text color="maincolor" bold>
                      Access Key
                    </s.Text>
                    <s.Text color="maincolor">(Chave de Acesso)</s.Text>
                  </s.ListElement>
                  <s.KeyContainer>
                    <s.IdContainer>{profile.access_key}</s.IdContainer>
                    <s.CopyKeyButton
                      onClick={() => {
                        navigator.clipboard.writeText(profile.access_key)
                        showToast({
                          type: 'success',
                          message: 'Chave Copiada!',
                        })
                      }}
                    >
                      Copiar
                      <s.Icon
                        width={24}
                        height={24}
                        fill="maincolor"
                        name="clipboard"
                      />
                    </s.CopyKeyButton>
                  </s.KeyContainer>
                </>
              ) : null}
              {!isProfileLoading && !hasProfileError && profile.secret_key ? (
                <>
                  <s.ListElement>
                    <s.Text color="maincolor" bold>
                      Secret Key
                    </s.Text>
                    <s.Text color="maincolor">(Chave Secreta)</s.Text>
                  </s.ListElement>
                  <s.KeyContainer>
                    <s.IdContainer>{profile.secret_key}</s.IdContainer>
                    <s.CopyKeyButton
                      onClick={() => {
                        navigator.clipboard.writeText(profile.secret_key)
                        showToast({
                          type: 'success',
                          message: 'Chave Copiada!',
                        })
                      }}
                    >
                      Copiar
                      <s.Icon
                        width={24}
                        height={24}
                        fill="maincolor"
                        name="clipboard"
                      />
                    </s.CopyKeyButton>
                  </s.KeyContainer>
                </>
              ) : null}

              <s.SubSectionHeader bold>Códigos dos Caixas</s.SubSectionHeader>
              {renderPosList()}
            </s.List>

            <s.FooterText color="redshipay" bold>
              Não compartilhe esses códigos com pessoas fora da {theme.domain}{' '}
              ou {posProduct ? posProduct : 'do seu Sistema Integrado'}.
            </s.FooterText>
            <s.ListElement>
              <s.Text bold>
                Esses códigos também podem ser encontrados no Painel{' '}
                {theme.domain} na aba
              </s.Text>
              <s.Icon name="store" fill="maincolor" />
              <s.Text color="maincolor" bold>
                Lojas
              </s.Text>
            </s.ListElement>
          </s.Card>

          <s.Footer>
            <s.Button color="transparent" onClick={() => previousStep(2)}>
              <s.Icon name="arrowleft" fill="maincolor" />
              <s.Text color="maincolor">Anterior</s.Text>
            </s.Button>
            <s.Button onClick={() => history.push('/pagamentos')}>
              Próximo
              <s.Icon name="arrowright" fill="lightmaincolor3" />
            </s.Button>
          </s.Footer>
        </s.Container>
      </s.BackgroundFilter>
    </s.Background>
  )
}

export default SystemIntegration
