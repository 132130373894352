import React from 'react'

import userVerificationImage from 'assets/images/user-verification-illustration.png'

import * as s from './styles'
import { baseURLVerifyUsersV1, get } from 'services/api'
import { showToast } from 'components/Toast'
import { Steps } from './WalletSettingsFormTbanksCashout'

interface IProps {
  cpf: string
  setQuestions: React.Dispatch<React.SetStateAction<any>>
  step: Steps
  setStep: (step: Steps) => void
}

const UserVerificationIntroduction: React.FC<IProps> = ({
  cpf,
  setQuestions,
  step,
  setStep,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const requestQuestionnaire = async () => {
    setIsLoading(true)
    await get(`${baseURLVerifyUsersV1}/questions?cpf=${cpf}`)
      .then((res) => {
        setIsLoading(false)
        setQuestions(res.data)
        setStep({
          ...step,
          KBAQuestionsAndAnswers: true,
        })
      })
      .catch((e) => {
        setIsLoading(false)
        showToast({
          type: 'error',
          message:
            JSON.parse(e.request.response).detail ??
            'Erro na API, tente novamente mais tarde',
        })
      })
  }

  return (
    <s.Content
      width={520}
      data-testid="content-on-user-verification-introduction"
    >
      <s.HeaderStepsContainer>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="navbarvector1">
            <s.NumberStep>1</s.NumberStep>
          </s.HeaderStepCircle>
          <s.Text>Introdução</s.Text>
        </s.HeaderStepWrapper>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="graytheme8">
            <s.NumberStep>2</s.NumberStep>
          </s.HeaderStepCircle>
          <s.Text>Questionário</s.Text>
        </s.HeaderStepWrapper>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="graytheme8">
            <s.NumberStep>3</s.NumberStep>
          </s.HeaderStepCircle>
          <s.Text>Documento</s.Text>
        </s.HeaderStepWrapper>
      </s.HeaderStepsContainer>
      <s.Text margin="64px 0 16px 0" color="graytheme6" bold type="heading4">
        Verificação do usuário
      </s.Text>
      <s.Text margin="0 0 40px 0" color="graytheme6" type="headline">
        Para sua segurança é necessário verificar seu usuário por meio de um
        <b> questionário</b> (KBA) e da <b>análise de documento pessoal</b> (RG
        ou CNH).
      </s.Text>
      <img width={500} src={userVerificationImage} color="graytheme6" />
      <s.Text margin="40px 0 32px 0">
        O questionário é feito a partir de consultas em bases de dados oficiais
        externas e suas respostas não serão armazenados, de acordo com o
        Princípio da Finalidade Específica da Lei Geral de Proteção de Dados
        (LGPD).
      </s.Text>
      <s.ButtonWrapper data-testid="button-on-register">
        <s.Button
          data-testid="register-button"
          onClick={() => {
            requestQuestionnaire()
          }}
          height="64px"
          disabled={isLoading}
          color="maincolor"
        >
          <s.Text
            margin="0 8px 0 0"
            type="headline"
            color="white"
            fontWeight={600}
          >
            Começar verificação
          </s.Text>
          {isLoading ? (
            <s.Loading type="spinner" color="white" />
          ) : (
            <s.Icon name="arrowright" fill="white" />
          )}
        </s.Button>
      </s.ButtonWrapper>
    </s.Content>
  )
}

export default UserVerificationIntroduction
