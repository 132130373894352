import styled from 'styled-components'

import ModalCmp from 'components/Modal'
import LoadingCmp from 'components/Loading'
import TextCmp from 'components/Text'
import SelectCmp from 'components/Inputs/Select'
import InputTextCmp from 'components/Inputs/Text'
import InputTextAreaCmp from 'components/Inputs/Text/Area'

import IconExporterCmp from 'components/IconExporter'
import ButtonCmp from 'components/Button'
import SearchSelectCmp from 'components/Inputs/SearchSelect'

export const Modal = styled(ModalCmp)``

export const Loading = styled(LoadingCmp)`
  margin-left: 16px;
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 500px;
`

export const Text = styled(TextCmp)``

export const FormContainer = styled.div`
  width: 500px;
  height: 600px;

  > div {
    margin-bottom: 24px;
  }
`

export const Select = styled(SelectCmp)``

export const InputText = styled(InputTextCmp)``

export const InputTextArea = styled(InputTextAreaCmp)``

export const EditButtonWrapper = styled.div`
  justify-content: center;
  display: flex;
  :hover {
    cursor: pointer;
  }
`

export const Icon = styled(IconExporterCmp)``

export const Button = styled(ButtonCmp)``

export const FooterButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;

  > button {
    :first-child {
      margin-right: 16px;
    }
  }
`

export const SearchSelect = styled(SearchSelectCmp)``
