import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'store/modules/rootState'

import registrationService, { CompleteUserRegistration } from 'services/registration'

import {
  PostCompleteRegistration,
  PostCompleteRegistrationIsLoading,
  PostCompleteRegistrationError,
} from 'store/modules/completeRegistration/action'

import { CompleteRegistrationState } from 'store/modules/completeRegistration/types'

interface UsePostCompleteRegistration extends CompleteRegistrationState {
  createUser: (user: CompleteUserRegistration) => Promise<void>
}

export const usePostCompleteRegistration = (): UsePostCompleteRegistration => {
  const dispatch = useDispatch()
  const { response, isLoading, error } = useTypedSelector((store) => ({
    response: store.completeRegistration.response,
    isLoading: store.completeRegistration.isLoading,
    error: store.completeRegistration.error,
  }))

  const createUser = useCallback(async (user) => {
    dispatch(PostCompleteRegistrationIsLoading())
    await registrationService.createUser(user)
      .then((response) => {
        dispatch(PostCompleteRegistration(response.data))
      })
      .catch(() => {
        dispatch(PostCompleteRegistrationError())
      })
  }
  , [dispatch])


  return { response, isLoading, error, createUser}
}

