import { 
  ActionInterface, 
  Types, 
  RetailChainState,
  GetRetailChainsResponseInterface,
  PostRetailChainResponseInterface,
  DelRetailChainResponseInterface,
  PatchRetailChainResponseInterface,
} from './types'

const INITIAL_STATE: RetailChainState = {
  retailChains: { count: 0, data: [], offset: 0, total: -1},
  getIsLoading: false,
  getError: false,
  postResponse: { code: -1, message: '' },
  postIsLoading: false,
  postError: false,
  delResponse: { code: -1, message: ''},
  delIsLoading: false,
  delError: false,
  patchResponse: { code: -1, message: ''},
  patchIsLoading: false,
  patchError: false,
}

const retailChainsReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): RetailChainState => {
  switch (action.type) {
    case Types.GET_RETAIL_CHAINS:
      return {
        ...state,
        retailChains: action.payload as GetRetailChainsResponseInterface,
        getIsLoading: false,
        getError: false,
      }
    case Types.GET_RETAIL_CHAINS_LOADING:
      return {
        ...state,
        getIsLoading: true,
        getError: false,
      }
    case Types.GET_RETAIL_CHAINS_ERROR:
      return {
        ...state,
        getIsLoading: false,
        getError: true,
      }
    case Types.POST_RETAIL_CHAIN:
      return {
        ...state,
        postResponse: action.payload as PostRetailChainResponseInterface,
        postIsLoading: false,
        postError: false,
      }
    case Types.POST_RETAIL_CHAIN_LOADING:
      return {
        ...state,
        postIsLoading: true,
        postError: false,
      }
    case Types.POST_RETAIL_CHAIN_ERROR:
      return {
        ...state,
        postIsLoading: false,
        postError: true,
      }
    case Types.DEL_RETAIL_CHAIN:
      return {
        ...state,
        delResponse: action.payload as DelRetailChainResponseInterface,
        delIsLoading: false,
        delError: false,
      }
    case Types.DEL_RETAIL_CHAIN_LOADING:
      return {
        ...state,
        delIsLoading: true,
        delError: false,
      }
    case Types.DEL_RETAIL_CHAIN_ERROR:
      return {
        ...state,
        delIsLoading: false,
        delError: true,
      }
    case Types.PATCH_RETAIL_CHAIN:
      return {
        ...state,
        patchResponse: action.payload as PatchRetailChainResponseInterface,
        patchIsLoading: false,
        patchError: false,
      }
    case Types.PATCH_RETAIL_CHAIN_LOADING:
      return {
        ...state,
        patchIsLoading: true,
        patchError: false,
      }
    case Types.PATCH_RETAIL_CHAIN_ERROR:
      return {
        ...state,
        patchIsLoading: false,
        patchError: true,
      }
    default:
      return state
  }
}

export default retailChainsReducer
