import { Types, StoreWalletsInterface } from './types'

export function GetStoreWallets(payload: {
  count: number
  data: StoreWalletsInterface[]
  offset: number
  total: number
}): {
  type: string
  payload: {
    count: number
    data: StoreWalletsInterface[]
    offset: number
    total: number
  }
} {
  return {
    type: Types.GET_STORE_WALLETS,
    payload,
  }
}

export function GetStoreWalletsIsLoading(): {
  type: string
} {
  return {
    type: Types.GET_STORE_WALLETS_LOADING,
  }
}

export function GetStoreWalletsError(): {
  type: string
} {
  return {
    type: Types.GET_STORE_WALLETS_ERROR,
  }
}

export function DeleteStoreWallet(): {
  type: string
} {
  return {
    type: Types.DELETE_STORE_WALLET_SUCCESS,
  }
}

export function DeleteStoreWalletError(): {
  type: string
} {
  return {
    type: Types.DELETE_STORE_WALLET_ERROR,
  }
}

export function DeleteStoreWalletLoading(): {
  type: string
} {
  return {
    type: Types.DELETE_STORE_WALLET_LOADING,
  }
}

export function PatchStoreWallet(): {
  type: string
} {
  return {
    type: Types.PATCH_STORE_WALLET,
  }
}

export function PatchStoreWalletIsLoading() {
  return {
    type: Types.PATCH_STORE_WALLET_LOADING,
  }
}

export function PatchStoreWalletError() {
  return {
    type: Types.PATCH_STORE_WALLET_ERROR,
  }
}

export function PostStoreWallet(): {
  type: string
} {
  return {
    type: Types.POST_STORE_WALLET,
  }
}

export function PostStoreWalletIsLoading() {
  return {
    type: Types.POST_STORE_WALLET_LOADING,
  }
}

export function PostStoreWalletError() {
  return {
    type: Types.POST_STORE_WALLET_ERROR,
  }
}
