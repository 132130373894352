import {
  Types,
  PayloadInterface,
  RequestInterface,
  ResponseInterface,
} from './types'

export function GetStore(
  payload: PayloadInterface
): {
  type: string
  payload: PayloadInterface
} {
  return {
    type: Types.GET_STORE,
    payload,
  }
}

export function GetStoreLoading(): {
  type: string
} {
  return {
    type: Types.GET_STORE_LOADING,
  }
}

export function GetStoreError(): {
  type: string
} {
  return {
    type: Types.GET_STORE_ERROR,
  }
}

export function PostStoreRequest(
  payload
): {
  payload: RequestInterface
  type: string
} {
  return {
    type: Types.POST_STORE_REQUEST,
    payload,
  }
}

export function PostStoreResponse(payload: ResponseInterface) {
  return {
    type: Types.POST_STORE_RESPONSE,
    payload,
  }
}

export function PostStoreError(message: string) {
  return {
    type: Types.POST_STORE_ERROR,
    message,
  }
}

export function DeleteStoreRequest(
  id
): {
  id: string
  type: string
} {
  return {
    type: Types.DELETE_STORE_REQUEST,
    id,
  }
}

export function DeleteStoreResponse(
  response
): {
  response: {
    code: number
    message: string
  }
  type: string
} {
  return {
    type: Types.DELETE_STORE_RESPONSE,
    response,
  }
}
