import React from 'react'
import { useDispatch } from 'react-redux'

import { showToast } from 'components/Toast'

import {
  get,
  post,
  baseURLStorePos,
  baseURLStorePosPerStoreID,
} from '../services/api'
import { useTypedSelector } from 'store/modules/rootState'
import {
  GetStorePos,
  GetStorePosIsLoading,
  GetStorePosError,
  GetStorePosPerStoreId,
  GetStorePosPerStoreIdError,
  GetStorePosPerStoreIdIsLoading,
  PostStorePos,
  PostStorePosIsLoading,
  PostStorePosHasError,
} from 'store/modules/storePos/actions'

import { StorePosState } from 'store/modules/storePos/types'

interface IUseStorePos extends StorePosState {
  loadStorePos: (id: string, copyToClipboard?: boolean) => Promise<any | void>
  loadStorePosPerStoreId: (id: string, params: any) => Promise<void>
  postStorePos: (
    id: string,
    data: {
      store_id?: string
      category?: string
      id?: string
      active: boolean
      description: string
      name: string
    }
  ) => Promise<number>
}

const UseStorePos = (): IUseStorePos => {
  const dispatch = useDispatch()

  const {
    storePos,
    storePosIsLoading,
    storePosHasError,
    storePosPerStoreId,
    storePosPerStoreIdIsLoading,
    storePosPerStoreIdHasError,
    postStorePosIsLoading,
    postStorePosHasError,
  } = useTypedSelector((store) => ({
    storePos: store.storePos.storePos,
    storePosIsLoading: store.storePos.storePosIsLoading,
    storePosHasError: store.storePos.storePosHasError,
    storePosPerStoreId: store.storePos.storePosPerStoreId,
    storePosPerStoreIdIsLoading: store.storePos.storePosPerStoreIdIsLoading,
    storePosPerStoreIdHasError: store.storePos.storePosPerStoreIdHasError,
    postStorePosIsLoading: store.storePos.postStorePosIsLoading,
    postStorePosHasError: store.storePos.postStorePosHasError,
  }))

  const loadStorePos = React.useCallback(
    async (id, copyToClipboard = false): Promise<any | void> => {
      let response = ''
      dispatch(GetStorePosIsLoading())
      await get(`${baseURLStorePos}/${id}`)
        .then((response) => {
          if (copyToClipboard) {
            navigator.clipboard.writeText(response.data.client_id)
          }
          dispatch(GetStorePos(response.data))
          response = response.data.client_id
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: `${JSON.parse(e.request.response)}`,
          })
          dispatch(GetStorePosError())
        })
      return response
    },
    [dispatch]
  )

  const loadStorePosPerStoreId = React.useCallback(
    async (id, params): Promise<void> => {
      dispatch(GetStorePosPerStoreIdIsLoading())
      await get(`${baseURLStorePosPerStoreID}/${id}`, { params: params || '' })
        .then((response) => {
          dispatch(GetStorePosPerStoreId(response.data))
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: `${JSON.parse(e.request.response).message}`,
          })
          dispatch(GetStorePosPerStoreIdError())
        })
    },
    [dispatch]
  )

  const postStorePos = React.useCallback(
    async (id, data): Promise<number> => {
      let statusCode = 0
      dispatch(PostStorePosIsLoading())
      await post(`${baseURLStorePos}/${id}`, data)
        .then((response) => {
          statusCode = response.status
          dispatch(PostStorePos())
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: `${JSON.parse(e.request.response).message}`,
          })
          statusCode = JSON.parse(e.request.response).code
          dispatch(PostStorePosHasError())
        })
      return statusCode
    },
    [dispatch]
  )

  return {
    storePos,
    storePosIsLoading,
    storePosHasError,
    storePosPerStoreId,
    storePosPerStoreIdIsLoading,
    storePosPerStoreIdHasError,
    postStorePosIsLoading,
    postStorePosHasError,
    loadStorePos,
    loadStorePosPerStoreId,
    postStorePos,
  }
}

export default UseStorePos
