import { showToast } from 'components/Toast'
import { getValueFromLocalStorage } from 'helpers/localStorage'
import { useState } from 'react'
import panelQueryService from 'services/panelQuery'
import { UserSessionInfo } from 'store/modules/auth/types'

interface IHook {
  newStatus: string
  isStatusChanged: boolean
  refresh: boolean
  patchResponse: boolean
  isLoading: boolean
  checkPanelQuery: (orderUuid: string) => void
  patchPanelQuery: (orderUuid: string) => void
  postShipayCallback: (orderId: string) => void
}

export const usePanelQuery = (): IHook => {
  const [newStatus, setNewStatus] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [patchResponse, setPatchResponse] = useState(false)
  const [isStatusChanged, setIsStatusChanged] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const checkPanelQuery = async (orderUuid: string) => {
    setIsLoading(true)
    const response = await panelQueryService
      .getOrderStatusChanges(orderUuid)
      .finally(() => setIsLoading(false))
    setIsStatusChanged(response.data.is_status_changed)

    if (response.data.is_status_changed) {
      setNewStatus(response.data.new_status)
      setRefresh(!refresh)
    } else {
      setRefresh(!refresh)
    }
  }

  const patchPanelQuery = async (orderUuid: string) => {
    const response = await panelQueryService.patchOrderStatus(orderUuid)
    if (response.data) setPatchResponse(true)
    else setPatchResponse(false)
  }

  const postShipayCallback = async (orderId: string) => {
    setIsLoading(true)
    const currentUserSession: UserSessionInfo = JSON.parse(getValueFromLocalStorage('currentUserSession'))
    if (currentUserSession && currentUserSession.customer_id) {
      await panelQueryService
        .postShipayCallback(Number(currentUserSession.customer_id), orderId)
        .then((res) => {
          showToast({
            type: 'success',
            message: 'Callback realizado com sucesso!',
          })
        })
        .catch((err) => {
          showToast({
            type: 'error',
            message: 'Erro na API, tente novamente mais tarde.',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return {
    newStatus,
    isStatusChanged,
    refresh,
    patchResponse,
    isLoading,
    checkPanelQuery,
    patchPanelQuery,
    postShipayCallback,
  }
}
