import React, { useEffect } from 'react'
import { isNotEmpty } from 'helpers/validators'
import { formatPixKey, getPixKeyType } from 'helpers/pix'
import {
  baseURLRegistrationV2DestinationAccounts,
  patch,
  post,
} from 'services/api'

import { showToast } from 'components/Toast'

import * as s from '../styles'
import { applyCpfCnpjMask } from 'helpers/masks'
import { IEditAccountFields } from './PixSplitAccountList'
import { errorTranslatorHandler } from 'helpers'

interface IProps {
  data: IEditAccountFields
  isOpen: boolean
  handleClose: () => any
}

const INITIAL_VALUE = {
  name: '',
  cpf_cnpj: '',
  pix_dict_key: '',
}

export const EditSplitAccount: React.FC<IProps> = ({
  data,
  isOpen,
  handleClose,
}) => {

  const [isLoading, setIsLoading] = React.useState(false)
  const [splitAccountForm, setSplitAccountForm] = React.useState(INITIAL_VALUE)
  const [splitAccountFormErrors, setSplitAccountFormErrors] = React.useState(
    INITIAL_VALUE
  )

  useEffect(() => {
    setSplitAccountForm({
      name: data.name,
      cpf_cnpj: data.cpf_cnpj,
      pix_dict_key: data.pix_dict_key,
    })
  }, [data])

  useEffect(() => {
    if (!isOpen) {
      setSplitAccountForm(INITIAL_VALUE)
      setSplitAccountFormErrors(INITIAL_VALUE)
    }
  }, [isOpen])

  const updateSplitAccount = async (payload) => {
    setIsLoading(true)
    await patch(
      `${baseURLRegistrationV2DestinationAccounts}/${data.id}`,
      payload
    )
      .then((res) => {
        showToast({
          type: 'success',
          message: 'Conta cadastrada com sucesso',
        })
        handleClose()
      })
      .catch((e) => {
        const error = JSON.parse(e.request.response).detail
        if (error[0].msg) {
          showToast({
            type: 'error',
            message: errorTranslatorHandler(error[0].msg),
          })
        } else {
          showToast({
            type: 'error',
            message: JSON.parse(e.request.response).detail,
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handlePatch = () => {
    const err = { ...splitAccountFormErrors }
    for (const [key, value] of Object.entries(splitAccountForm)) {
      if (!err[key]) err[key] = isNotEmpty(value) ? '' : 'Campo Obrigatório'
    }

    setSplitAccountFormErrors(err)
    const errList = Object.values(err).filter((x) => x !== '')

    if (!errList || errList.length === 0) {
      const payload = {
        name: splitAccountForm.name,
        cpf_cnpj: splitAccountForm.cpf_cnpj,
        pix_dict_key: splitAccountForm.pix_dict_key.trim(),
        pix_dict_key_type: getPixKeyType(splitAccountForm.pix_dict_key.trim()),
      }
      updateSplitAccount(payload)
    }
  }

  const _setSplitAccountForm = (obj) => {
    setSplitAccountForm({ ...splitAccountForm, ...obj })
    Object.keys(obj).forEach((key) => {
      obj[key] = ''
      setSplitAccountFormErrors({ ...splitAccountFormErrors, ...obj })
    })
  }

  return (
    <s.Modal
      headerIcon="display"
      modalSubtitle="Editar Conta Automação"
      handleClose={handleClose}
      isOpen={isOpen}
    >
      <s.ModalContainer>
        <s.Text textAlign="justify">
          Informe uma conta bancária da sua empresa de automação comercial para
          recebimento das comissões (overpricing) referentes às tarifas cobradas
          dos lojistas, usuários do seu sistema, por Pix liquidado.
        </s.Text>
        <s.InputGroup>
          <s.InputText
            label="Apelido da Conta"
            value={splitAccountForm.name}
            onChange={(e) => _setSplitAccountForm({ name: e.target.value })}
            error={
              splitAccountFormErrors.name
                ? { message: splitAccountFormErrors.name }
                : false
            }
          />
        </s.InputGroup>

        <s.InputGroup>
          <s.InputText
            label="Chave Pix"
            value={splitAccountForm.pix_dict_key}
            onChange={(e) =>
              _setSplitAccountForm({
                pix_dict_key: formatPixKey(e.target.value),
              })
            }
            error={
              splitAccountFormErrors.pix_dict_key
                ? { message: splitAccountFormErrors.pix_dict_key }
                : false
            }
          />
        </s.InputGroup>

        <s.InputGroup>
          <s.InputText
            label="CPF ou CNPJ da Conta Bancária"
            value={splitAccountForm.cpf_cnpj}
            onChange={(e) =>
              _setSplitAccountForm({
                cpf_cnpj: applyCpfCnpjMask(e.target.value),
              })
            }
            error={
              splitAccountFormErrors.cpf_cnpj
                ? { message: splitAccountFormErrors.cpf_cnpj }
                : false
            }
          />
        </s.InputGroup>

        <s.ButtonGroup>
          <s.Button
            onClick={() => {
              handleClose()
            }}
            color="white"
          >
            <s.Text color="maincolor" bold>
              Cancelar
            </s.Text>
          </s.Button>
          <s.Button disabled={isLoading} onClick={() => handlePatch()}>
            <s.Text color="white" bold>
              Confirmar
            </s.Text>
          </s.Button>
          {isLoading ? <s.Loading type="spinner" color="white" /> : null}
        </s.ButtonGroup>
      </s.ModalContainer>
    </s.Modal>
  )
}
