import React from 'react'

import * as s from './styles'

interface IProps {
  width?: number
  height?: number
}

const Card: React.FC<IProps & React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  width = 1300,
  height = 200,
  ...rest
}) => {
  return (
    <s.CardWrapper width={width} height={height} {...rest}>
      {children}
    </s.CardWrapper>
  )
}

export default Card
