import jwt_decode from 'jwt-decode'

let tokenInfo = {
  token: '',
  parsedToken: null,
}

export const parseJwt = (token: string)=> {
  if(!token) return {}
  if(token === tokenInfo.token) return tokenInfo.parsedToken

  try {
    tokenInfo.token = token
    tokenInfo.parsedToken = jwt_decode(token)
    return tokenInfo.parsedToken
  }
  catch(e) {
    return {};
  }
}
