import { ActionInterface, Types, SystemWalletsState } from './types'

const INITIAL_STATE: SystemWalletsState = {
  systemWallets: [],
  isLoading: false,
  hasError: false,
}

const systemWalletsReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): SystemWalletsState => {
  switch (action.type) {
    case Types.GET_SYSTEM_WALLETS:
      return {
        ...state,
        systemWallets: action.payload.wallets,
        isLoading: false,
        hasError: false,
      }
    case Types.GET_SYSTEM_WALLETS_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_SYSTEM_WALLETS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export default systemWalletsReducer
