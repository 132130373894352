export const Types = {
  GET_THEME: 'GET_THEME',
  GET_THEME_LOADING: 'GET_THEME_LOADING',
  GET_THEME_ERROR: 'GET_THEME_ERROR',
}

export interface PayloadInterface {
  domain: string
  pagetitle: string
  images: Images
  colors: Colors
}

export interface Images {
  mainlogo: any
  favicon: string
}

export interface Colors {
  navbarvector1: string
  lightmaincolor4: string
  lightmaincolor3: string
  lightmaincolor2: string
  lightmaincolor1: string
  maincolor: string
  transparentmaincolor: string
  darkmaincolor1: string
  darkmaincolor2: string
  darkmaincolor3: string
}

export interface ActionInterface {
  type: keyof typeof Types
  payload: PayloadInterface
}

export interface ThemeState {
  theme: PayloadInterface
  isLoading: boolean
  hasError: boolean
}
