import styled, { css, keyframes } from 'styled-components'

import { themeColors } from 'styles/theme'

import IconCmp from 'components/IconExporter'
import TextCmp from 'components/Text'

import * as a from 'animations'

interface IWalletCard {
  color?: keyof typeof themeColors
  name?: string
  type?: string
  disabled?: boolean
  height?: number
  width?: number
}

const gradient = keyframes`
  0% {background-position: 0%}
  100% {background-position: 100%}
`

export const PixWalletCard = styled.div<IWalletCard>`
  position: relative;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
    0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.gray3
      : props.theme.colors[props.color]};

  img {
    position: absolute;
    top: 35%;
    left: 50%;
    margin-bottom: 58px;
    transform: translate(-50%, -50%);
  }

  & > svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  & > h5 {
    display: block;
  }

  &:hover {
    cursor: ${(props) =>
      props.disabled
        ? 'not-allowed'
        : props.color === 'graytheme1'
        ? 'default'
        : 'pointer'};

    ${({ disabled }) =>
      disabled
        ? css``
        : css`
            animation: ${gradient} 16s linear infinite,
              ${a.cardAnimationShipayIn} 0.5s infinite alternate;
          `}
  }
`

export const EWalletCard = styled.div<IWalletCard>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  ${(props) =>
    ` box-shadow: inset 0px 1px 4px ${props.theme.colors.graytheme4}`};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.gray3
      : props.theme.colors[props.color]};
  ${({ disabled, name }) =>
    disabled ? null : a.handleWalletCardAnimation(name)};

  & > img {
    height: ${({ height, name }) =>
      name === 'mercadopago' ? height / 1.3 : height / 1.5}px;
    width: 100px;
    animation-name: ${({ type }) =>
      type === 'pix' ? a.iconAnimationDown : a.iconAnimationOut};
    animation-duration: 0.5s;
  }

  & > svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  & > h5 {
    animation-name: ${a.titleAnimationOut};
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    bottom: 30px;
    position: absolute;
    line-height: 0;
    display: block;
  }

  &:hover {
    cursor: ${(props) =>
      props.disabled
        ? 'not-allowed'
        : props.color === 'graytheme1'
        ? 'default'
        : 'pointer'};

    & > img {
      ${({ type, disabled }) =>
        type === 'pix'
          ? disabled
            ? css``
            : css`
                animation-name: a.iconAnimationUp;
                animation-duration: 0.5s;
                animation-fill-mode: forwards;
              `
          : disabled
          ? css``
          : css`
              animation-name: a.iconAnimationIn;
              animation-duration: 0.5s;
              animation-fill-mode: forwards;
            `}

      & > h5 {
        animation-name: ${a.titleAnimationIn};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
      }
    }
  }
`

export const Icon = styled(IconCmp)``

export const Text = styled(TextCmp)``

interface IImageContainer {
  margin?: string
}

export const ImageContainer = styled.div<IImageContainer>`
  & > img {
    margin: ${({ margin }) => margin};
  }
`

export const FooterWrapper = styled.div`
  background: rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: absolute;
  margin: 10px 0px;
  padding: 4px 0px;
  width: 90%;
  bottom: 0px;
`
export const TextCardFooter = styled(TextCmp)`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 149%;
  text-align: center;
  color: #ffffff;

  & > img {
    display: inline;
  }
`
