import { css } from 'styled-components'
import { mediaQueries } from 'styles/theme'

export const assetsHandler = (
  themeColors,
  whiteLabelColors,
  logo,
  loginBackground,
  wizardBackground
) => {
  const colors = {
    ...themeColors,
    graytheme3: '#CCCCCC',
    graytheme6: '#595959',
    tableColor7: '#F7F7F7',
    tableColor8: '#FCFCFC',
    approvedStatusBackground: '#E1EEFA',
    approvedStatusIconColor: '#027ad3',
    approvedStatusTextColor: '#2B5D82',
    graytheme10: '#EDEDED',
    pendingStatusIconColor: '#808080',
    pendingStatusTextColor: '#575757',
    cancelledStatusBackground: '#F5E6E6',
    cancelledStatusIconColor: '#E55C5C',
    cancelledStatusTextColor: '#822B2B',
    ...whiteLabelColors,
  }
  const assets = {
    mainLogo: logo,
    login: {
      backgroundImg: loginBackground,
      subtitle: false,
      marginCardTop: '0px',
    },
    wizardBackground: {
      img: wizardBackground,
      filter: '0.75',
    },
    header: {
      background1: `
            radial-gradient(
              50% 50% at 50% 50%,
              ${colors.navbarvector1} 10.89%,
              rgba(41, 122, 204, 0) 100%
            );`,
      background2: `
            linear-gradient(
              108.21deg,
              #ffffff 16.57%,
              rgba(255, 255, 255, 0) 86.15%
            ), ${colors.maincolor};
            `,
      borderBottom: `
              1px solid ${colors.lightmaincolor2}
            `,
      listItem: {
        selected: `
                background-color: ${colors.lightmaincolor3};
                box-shadow: inset 0px 1px 8px 2px
                  ${colors.lightmaincolor2};
        
                > a > h5 {
                  color: ${colors.maincolor};
                }
              `,
      },
      listItemHover: `
              > a > h5 {
                color: ${colors.maincolor};
              }
              * > svg {
                fill: ${colors.maincolor};
              }
            `,
    },
    buttonComponent: {
      shadow: `
      0px 1px 4px ${themeColors.graytheme3};;
            `,
    },
    inputComponent: {
      inputTextShadow: `
              0px 1px 8px 2px #B8CBD9
            `,
      inputTextShadowHover: `
              0px 1px 8px 3px rgba(92, 170, 229, 0.5);
            `,
    },
    modalComponent: {
      padding: '10px 16px',
      modalHeader: `
              align-items: center;
              display: flex;
              padding: 16px;
            `,
    },
    tableComponent: {
      tableHeader: `
            height: 60px;
            background: linear-gradient(
              180deg,
              ${colors.tableColor1} 0%,
              ${colors.tableColor2}28.57%,
              ${colors.tableColor2}71.42%,
              ${colors.tableColor1} 100%
            );
            color: ${colors.gray1}
          `,
    },
    orders: {
      bold: true,
      registerIcon: 'register',
      storeIcon: 'store',
      pageHeader: css`
        display: flex;
        align-items: flex-start;
        height: 50px;

        /* & > p {
          :first-child {
            margin-right: ${({ theme }) => theme.spacing(2)}px;
            background: linear-gradient(
              ${colors.lightmaincolor4},
              ${colors.maincolor}
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        } */
      `,
      tableBorderColor: colors.graytheme3,
      table: `
      > tbody > tr > td > * > p {
       :not(:nth-child(7), :last-child) {
         font-weight: 500;
       }
      }
       * > th {
         :first-child {
           width: 14%;
         }
         :nth-child(2) {
           width: 12%;
         }
         :nth-child(3) {
           width: 14%;
         }
         :nth-child(4) {
           width: 14%;
         }
         :nth-child(5) {
           width: 14%;
         }
         :nth-child(6){
           width: 10%
         }
         :nth-child(7) {
           width: 12%;
            h5 {
             color: ${themeColors.graytheme6};
           }
         }
         :nth-child(8) {
           padding-left: 16px;
         }
       }
     `,
      columns: {
        seeMoreButton: `
            padding: 8px;
            border-radius: 8px;
            border: 0;
            width: 80px;
            background-color: transparent;
            
          
            :hover {
              cursor: pointer;
              background-color: ${colors.lightmaincolor3};
            }
          
            :active {
              background-color: ${colors.lightmaincolor2};
            }
            
            @media ${mediaQueries.lgUp} {
              width: 93px;
            }
          `,
        uuidContainer: `
                color: ${colors.graytheme6};
                padding: 8px;
                border-radius: 8px;
                background-color: ${colors.lightmaincolor3};
                display: flex;
                align-items: center;
        
                :hover {
                  background-color: ${colors.lightmaincolor2};
                  color: ${colors.maincolor};
                }
                
                :active {
                  background-color: ${colors.lightmaincolor1};
                }
        
                > svg {
                  fill: ${colors.maincolor};
                  weight: 200;
                }
              `,
      },
    },
  }

  return assets
}
