export const getBanks = (): { code: string; name: string }[] => {
  return [
    {
      code: '001',
      name: 'Banco do Brasil S.A.',
    },
    {
      code: '003',
      name: 'Banco da Amazônia S.A.',
    },
    {
      code: '004',
      name: 'Banco do Nordeste do Brasil S.A.',
    },
    {
      code: '010',
      name: 'CREDICOAMO CREDITO RURAL COOPERATIVA',
    },
    {
      code: '012',
      name: 'Banco Inbursa S.A.',
    },
    {
      code: '016',
      name: 'COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI',
    },
    {
      code: '021',
      name: 'BANESTES S.A. Banco do Estado do Espírito Santo',
    },
    {
      code: '025',
      name: 'Banco Alfa S.A.',
    },
    {
      code: '033',
      name: 'BANCO SANTANDER (BRASIL) S.A.',
    },
    {
      code: '037',
      name: 'Banco do Estado do Pará S.A.',
    },
    {
      code: '041',
      name: 'Banco do Estado do Rio Grande do Sul S.A.',
    },
    {
      code: '047',
      name: 'Banco do Estado de Sergipe S.A.',
    },
    {
      code: '069',
      name: 'Banco Crefisa S.A.',
    },
    {
      code: '070',
      name: 'BRB - Banco de Brasília S.A.',
    },
    {
      code: '077',
      name: 'Banco Inter S.A.',
    },
    {
      code: '081',
      name: 'BancoSeguro S.A.',
    },
    {
      code: '082',
      name: 'BANCO TOPÁZIO S.A.',
    },
    {
      code: '084',
      name: 'UNIPRIME NORTE DO PARANÁ - COOPERATIVA DE CRÉDITO LTDA',
    },
    {
      code: '085',
      name: 'Cooperativa Central de Crédito - Ailos',
    },
    {
      code: '089',
      name: 'CREDISAN COOPERATIVA DE CRÉDITO',
    },
    {
      code: '093',
      name: 'PÓLOCRED   SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT',
    },
    {
      code: '097',
      name: 'Credisis - Central de Cooperativas de Crédito Ltda.',
    },
    {
      code: '098',
      name: 'Credialiança Cooperativa de Crédito Rural',
    },
    {
      code: '099',
      name: 'UNIPRIME CENTRAL - CENTRAL INTERESTADUAL DE COOPERATIVAS DE CREDITO LTDA.',
    },
    {
      code: '104',
      name: 'Caixa Econômica Federal',
    },
    {
      code: '121',
      name: 'Banco Agibank S.A.',
    },
    {
      code: '124',
      name: 'Banco Woori Bank do Brasil S.A.',
    },
    {
      code: '125',
      name: 'BANCO GENIAL S.A.',
    },
    {
      code: '130',
      name: 'CARUANA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    },
    {
      code: '133',
      name: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E',
    },
    {
      code: '136',
      name: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI',
    },
    {
      code: '144',
      name: 'BEXS BANCO DE CÂMBIO S/A',
    },
    {
      code: '159',
      name: 'Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor',
    },
    {
      code: '174',
      name: 'PEFISA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    },
    {
      code: '197',
      name: 'Stone Pagamentos S.A.',
    },
    {
      code: '208',
      name: 'Banco BTG Pactual S.A.',
    },
    {
      code: '212',
      name: 'Banco Original S.A.',
    },
    {
      code: '213',
      name: 'Banco Arbi S.A.',
    },
    {
      code: '218',
      name: 'Banco BS2 S.A.',
    },
    {
      code: '224',
      name: 'Banco Fibra S.A.',
    },
    {
      code: '237',
      name: 'Banco Bradesco S.A.',
    },
    {
      code: '243',
      name: 'BANCO MASTER S/A',
    },
    {
      code: '246',
      name: 'Banco ABC Brasil S.A.',
    },
    {
      code: '254',
      name: 'PARANÁ BANCO S.A.',
    },
    {
      code: '260',
      name: 'Nu Pagamentos S.A.',
    },
    {
      code: '273',
      name: 'Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi/São Miguel',
    },
    {
      code: '274',
      name: 'MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT',
    },
    {
      code: '276',
      name: 'BANCO SENFF S.A.',
    },
    {
      code: '281',
      name: 'Cooperativa de Crédito Rural Coopavel',
    },
    {
      code: '286',
      name: 'COOPERATIVA DE CRÉDITO RURAL DE OURO   SULCREDI/OURO',
    },
    {
      code: '290',
      name: 'Pagseguro Internet S.A.',
    },
    {
      code: '301',
      name: 'BPP Instituição de Pagamento S.A.',
    },
    {
      code: '318',
      name: 'Banco BMG S.A.',
    },
    {
      code: '323',
      name: 'MERCADOPAGO.COM REPRESENTACOES LTDA.',
    },
    {
      code: '324',
      name: 'CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.',
    },
    {
      code: '326',
      name: 'PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
    },
    {
      code: '329',
      name: 'QI Sociedade de Crédito Direto S.A.',
    },
    {
      code: '330',
      name: 'BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.',
    },
    {
      code: '332',
      name: 'Acesso Soluções de Pagamento S.A.',
    },
    {
      code: '335',
      name: 'Banco Digio S.A.',
    },
    {
      code: '336',
      name: 'Banco C6 S.A.',
    },
    {
      code: '340',
      name: 'Super Pagamentos e Administração de Meios Eletrônicos S.A.',
    },
    {
      code: '341',
      name: 'ITAÚ UNIBANCO S.A.',
    },
    {
      code: '342',
      name: 'Creditas Sociedade de Crédito Direto S.A.',
    },
    {
      code: '348',
      name: 'Banco XP S.A.',
    },
    {
      code: '349',
      name: 'AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    },
    {
      code: '355',
      name: 'ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.',
    },
    {
      code: '362',
      name: 'CIELO S.A.',
    },
    {
      code: '364',
      name: 'GERENCIANET S.A.',
    },
    {
      code: '374',
      name: 'REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
    },
    {
      code: '380',
      name: 'PICPAY SERVICOS S.A.',
    },
    {
      code: '383',
      name: 'BOLETOBANCÁRIO.COM TECNOLOGIA DE PAGAMENTOS LTDA.',
    },
    {
      code: '384',
      name: 'GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO',
    },
    {
      code: '389',
      name: 'Banco Mercantil do Brasil S.A.',
    },
    {
      code: '396',
      name: 'HUB PAGAMENTOS S.A',
    },
    {
      code: '397',
      name: 'LISTO SOCIEDADE DE CREDITO DIRETO S.A.',
    },
    {
      code: '401',
      name: 'IUGU INSTITUIÇÃO DE PAGAMENTO S.A.',
    },
    {
      code: '403',
      name: 'CORA SOCIEDADE DE CRÉDITO DIRETO S.A.',
    },
    {
      code: '404',
      name: 'SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.',
    },
    {
      code: '408',
      name: 'BONUSPAGO SOCIEDADE DE CRÉDITO DIRETO S.A.',
    },
    {
      code: '411',
      name: 'Via Certa Financiadora S.A. - Crédito, Financiamento e Investimentos',
    },
    {
      code: '413',
      name: 'BANCO BV S.A.',
    },
    {
      code: '422',
      name: 'Banco Safra S.A.',
    },
    {
      code: '529',
      name: 'PINBANK BRASIL INSTITUIÇÃO DE PAGAMENTO S.A.',
    },
    {
      code: '604',
      name: 'Banco Industrial do Brasil S.A.',
    },
    {
      code: '610',
      name: 'Banco VR S.A.',
    },
    {
      code: '612',
      name: 'Banco Guanabara S.A.',
    },
    {
      code: '613',
      name: 'Omni Banco S.A.',
    },
    {
      code: '623',
      name: 'Banco Pan S.A.',
    },
    {
      code: '630',
      name: 'Banco Smartbank S.A.',
    },
    {
      code: '633',
      name: 'Banco Rendimento S.A.',
    },
    {
      code: '634',
      name: 'BANCO TRIANGULO S.A.',
    },
    {
      code: '637',
      name: 'BANCO SOFISA S.A.',
    },
    {
      code: '643',
      name: 'Banco Pine S.A.',
    },
    {
      code: '653',
      name: 'BANCO VOITER S.A.',
    },
    {
      code: '654',
      name: 'BANCO DIGIMAIS S.A.',
    },
    {
      code: '655',
      name: 'Banco Votorantim S.A.',
    },
    {
      code: '707',
      name: 'Banco Daycoval S.A.',
    },
    {
      code: '712',
      name: 'Banco Ourinvest S.A.',
    },
    {
      code: '739',
      name: 'Banco Cetelem S.A.',
    },
    {
      code: '741',
      name: 'BANCO RIBEIRAO PRETO S.A.',
    },
    {
      code: '746',
      name: 'Banco Modal S.A.',
    },
    {
      code: '748',
      name: 'BANCO COOPERATIVO SICREDI S.A.',
    },
    {
      code: '756',
      name: 'BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB',
    },
  ]
}
