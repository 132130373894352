import styled from 'styled-components'
import CardCmp from 'components/Card'
import ButtonCmp from 'components/Button'
import TextCmp from 'components/Text'
import IconCmp from 'components/IconExporter'
import ExportCsv from 'components/ExportCsv'
import ModalCmp from 'components/Modal'
import TableCmp from 'components/Table'
import CheckboxCmp from 'components/Inputs/Checkbox'

export const Table = styled(TableCmp)`
  max-width: 1650px;
`

export const subHeader = styled.h3`
  width: 100%;
  color: ${({ theme }) => theme.colors.maincolor};
  padding: 40px 0px 20px;
`

export const Summary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  width: 100%;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  > h5 {
    padding-bottom: 20px;
  }
`

export const Modal = styled(ModalCmp)`
  position: relative;
  > div > div {
    overflow-y: scroll;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;

  > button {
    :first-child {
      margin-right: 16px;
    }
  }
`

export const Checkbox = styled(CheckboxCmp)``

export const ExportButton = styled(ExportCsv)``

export const ModalContainer = styled.div`
  max-width: 1700px;
  height: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${ButtonsWrapper} {
    margin-top: 56px;
    width: 30%;
  }
`

export const Card = styled(CardCmp)`
  position: relative;
  justify-content: center;
  width: 750px;
  padding: 30px;
  margin-bottom: 2rem;
`

export const Icon = styled(IconCmp)`
  margin-bottom: 45px;
`

export const Text = styled(TextCmp)``

export const ContentWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  flex-direction: row;
`

export const Button = styled(ButtonCmp)`
  position: absolute;
  right: 30px;
  top: 40px;
`
