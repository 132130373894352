import React from 'react'

import paitausuccess2 from '../../../assets/images/pa-itau-success2.png'

import * as s from './styles'

const Success: React.FC<{ hasSplitFee: boolean }> = ({ hasSplitFee }) => {
  return (
    <s.SuccessWrapper data-testid="success-modal">
      <s.Text margin="32px 0 32px 0" type="heading4" bold color="graytheme6">
        {`Tarifa ${hasSplitFee ? 'atualizada' : 'cadastrada'} com sucesso!`}
      </s.Text>

      <s.Text
        margin="0 0 32px 0"
        fontWeight={500}
        type="headline"
        color="graytheme6"
      >
        {`A tarifa da sua automação foi ${
          hasSplitFee ? 'atualizada' : 'cadastrada'
        } com sucesso!`}
        {<br />}
        {hasSplitFee
          ? ''
          : `Seus lojistas estão aptos a solicitar abertura de conta Itaú qualquer banco.`}
      </s.Text>

      <img src={paitausuccess2} alt="Imagem de Sucesso Pix AnyBank" />
    </s.SuccessWrapper>
  )
}
export default Success
