import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useLocation } from 'react-router-dom'

import { post } from 'services/api'
import { baseURLAuthValidate, baseURLResetPasswordEmail } from 'services/api'

import { useAuth } from 'hooks/auth'
import useResetStore from 'hooks/useResetStore'

import { removeValueFromLocalStorage } from 'helpers/localStorage'

import Toast, { showToast } from 'components/Toast'

import * as s from './styles'
import { useTheme } from 'styled-components'
import { isConexaoItau, isHTML, isTotvs } from 'helpers'
import Email from './Steps/Email'
import Password from './Steps/Password'

const Login: React.FC = () => {
  const [flippedCard, setFlippedCard] = React.useState<boolean>(false)
  const [email, setEmail] = React.useState<string>('')
  const [accessCode, setAccessCode] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')
  const [authValidateIsLoading, setAuthValidateIsLoading] =
    React.useState<boolean>(false)
  const { rUrl } = useAuth()
  const history = useHistory()
  const { resetStore } = useResetStore()
  const theme = useTheme()

  useEffect(() => {
    resetStore()
    removeValueFromLocalStorage('currentUserSession')
    removeValueFromLocalStorage('adminUserSession')
  }, [])

  useEffect(() => {
    if (rUrl) history.push(rUrl)
  }, [rUrl])

  const validateAuth = async (email) => {
    setAuthValidateIsLoading(true)
    await post(baseURLAuthValidate, {
      username: email,
    })
      .then(() => {
        setFlippedCard(!flippedCard)
      })
      .catch((e) => {
        if (!isHTML(e.request.response) && e.request.status < 500) {
          showToast({
            type: 'error',
            message: JSON.parse(e.request.response).message,
          })
        } else {
          showToast({
            type: 'error',
            message: `Erro no servidor, tente novamente mais tarde.`,
          })
        }
      })
      .finally(() => {
        setAuthValidateIsLoading(false)
      })
  }

  const resendAccessCode = (email) => {
    return post(baseURLAuthValidate, {
      username: email,
    })
  }

  return (
    <s.Background>
      <s.Container>
        <Toast />
        <s.LoginWrapper>
          {isTotvs() ? (
            <div style={{ height: '100px' }}></div>
          ) : isConexaoItau() ? (
            <div style={{ margin: '0px 0px 24px 0px' }}>
              <s.Image src={theme.assets.mainLogo} alt="conexão Itaú logo" />
            </div>
          ) : (
            <s.Image src={theme.assets.mainLogo} alt="shipay logo" />
          )}
          {theme.assets.login.subtitle && !isTotvs() ? (
            <s.Text margin="24px 0px">
              <b>Painel Shipay</b> para melhorar a sua experiência com
              <b> o Pix e múltiplas carteiras digitais.</b>
            </s.Text>
          ) : (
            <></>
          )}

          <s.Card isFlipped={flippedCard} width={584} height={337}>
            {!flippedCard ? (
              <Email
                email={email}
                setEmail={setEmail}
                isLoading={authValidateIsLoading}
                validateAuth={validateAuth}
              />
            ) : (
              <Password
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                accessCode={accessCode}
                setAccessCode={setAccessCode}
                resendCode={resendAccessCode}
                setFlippedCard={setFlippedCard}
              />
            )}
          </s.Card>
        </s.LoginWrapper>
      </s.Container>
    </s.Background>
  )
}

export default Login
