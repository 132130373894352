import { get, bffURLRedirectWizard } from '../api'

export interface wizardInterface {
  should_init_wizard: boolean
}

const getShouldRedirect = () => {
  return get(`${bffURLRedirectWizard}`)
}

const roleService = {
    getShouldRedirect,
}

export default roleService
