import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  applyCpfCnpjMask,
  applyCEPMask,
  applyCpfCnpjWithXXMask,
} from 'helpers/masks'
import { getStates } from 'helpers/geo'
import { isValidCnpj, isValidCpf } from 'helpers/validators'

import useGetGeoAddress from 'hooks/useGetGeoAddress'
import UseStore from 'hooks/useStore'
import { useNonInitialEffect } from 'hooks/utils'

import { showToast } from 'components/Toast'

import { TOptions, TSelected } from 'components/Inputs/Select/types'

import * as s from './styles'

interface IProps {
  isOpen: boolean
  storeId: string
  handleClose: () => any
}

const EditStoreModal: React.FC<IProps> = ({ isOpen, storeId, handleClose }) => {
  const {
    geoAddress,
    isLoading: isGeoAddressLoading,
    hasError: hasGeoAddressError,
    loadGeoAddress,
    resetGeoAddress,
  } = useGetGeoAddress()
  const { t } = useTranslation()
  const {
    store,
    hasError,
    isLoading,
    loadStore,
    postIsLoading,
    postResponse,
    postStore,
  } = UseStore()
  const [storeInput, setStoreInput] = React.useState({
    cnpj: '',
    storeName: '',
  })
  const [address, setAddress] = React.useState({
    cep: '',
    streetAddress: '',
    number: '',
    complement: '',
    neighbourhood: '',
    city: '',
    state: '',
  })
  const [selectedUf, setSelectedUf] = React.useState<TSelected>({
    id: 0,
    name: '',
  })

  const states: { id: number; text: string }[] = getStates()
  const [ufOptions] = React.useState<TOptions[]>(
    states.map((state) => {
      return {
        id: state.id,
        text: state.text,
        onClick: () => {
          setSelectedUf({ id: state.id, name: state.text })
        },
      }
    })
  )

  const [inputPressedValidator, setInputPressedValidator] = React.useState<any>(
    {
      ...Object.keys({
        ...address,
        ...storeInput,
      }).reduce((acc, key) => {
        acc[key] = false
        return acc
      }, {}),
    }
  )

  const createStore = () => {
    postStore(
      {
        cnpj_cpf: storeInput.cnpj,
        name: storeInput.storeName,
        city_name: address.city,
        neighborhood: address.neighbourhood,
        reference: address.complement,
        state_name: address.state,
        street_name: address.streetAddress,
        street_number: address.number,
        zip_code: address.cep,
      },
      storeId
    )
  }

  const cpfCnpjErrorHandler = () => {
    if (storeInput.cnpj === '' && inputPressedValidator.cnpj) {
      return { message: 'Campo obrigatório' }
    } else if (
      inputPressedValidator.cnpj &&
      !isValidCpf(storeInput.cnpj) &&
      !isValidCnpj(storeInput.cnpj)
    ) {
      return { message: 'esse CPF/CNPJ não existe' }
    } else {
      return false
    }
  }

  const checkIfAllInputsAreValid = React.useCallback(() => {
    const { complement, ...addressExceptComplement } = address
    const allInputs = {
      ...addressExceptComplement,
      ...storeInput,
    }
    return (
      (isValidCnpj(storeInput.cnpj) || isValidCpf(storeInput.cnpj)) &&
      Object.values(allInputs).every((item) => {
        return item !== null && item !== ''
      })
    )
  }, [address, storeInput])

  useNonInitialEffect(() => {
    if (postResponse?.message?.includes('Updated')) {
      showToast({
        type: 'success',
        message: t('Loja atualizada com sucesso'),
      })

      handleClose()
    }
  }, [postResponse])

  useNonInitialEffect(() => {
    if (!isLoading && store.name) {
      setStoreInput({
        cnpj: store.cnpj_cpf,
        storeName: store.name,
      })
      setAddress({
        cep: store.zip_code,
        streetAddress: store.street_name,
        number: store.street_number,
        complement: store.reference,
        neighbourhood: store.neighborhood,
        city: store.city_name,
        state: store.state_name,
      })
      setSelectedUf({
        id: ufOptions.find((item) => item.text === store.state_name).id,
        name: store.state_name,
      })
    }
  }, [store, isLoading])

  React.useEffect(() => {
    if (!isOpen) {
      setStoreInput({
        cnpj: '',
        storeName: '',
      })
      setAddress({
        cep: '',
        streetAddress: '',
        number: '',
        complement: '',
        neighbourhood: '',
        city: '',
        state: '',
      })
      setSelectedUf({
        id: 0,
        name: '',
      })
      setInputPressedValidator({
        ...Object.keys({
          ...address,
          ...storeInput,
        }).reduce((acc, key) => {
          acc[key] = false
          return acc
        }, {}),
      })
    } else {
      loadStore(storeId)
    }
  }, [isOpen])

  React.useEffect(() => {
    if (geoAddress.city) {
      setAddress({
        cep: geoAddress?.postal_code,
        city: geoAddress?.city,
        state: geoAddress?.state,
        streetAddress: geoAddress?.street,
        neighbourhood: geoAddress?.neighborhood,
        number: '',
        complement: '',
      })
      setSelectedUf({
        id: ufOptions.find((item) => item.text === geoAddress.state).id,
        name: geoAddress.state,
      })
      resetGeoAddress()
    }
  }, [geoAddress, resetGeoAddress])

  React.useEffect(() => {
    setAddress({ ...address, state: selectedUf.name })
  }, [selectedUf.name])

  return (
    <s.Modal
      modalSubtitle="Editar Loja"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <s.ModalContainer>
        <s.UpperWrapper>
          <s.InputText
            onChange={(e) => {
              setStoreInput({ ...storeInput, storeName: e.target.value })
              setInputPressedValidator({
                ...inputPressedValidator,
                storeName: true,
              })
            }}
            error={
              storeInput.storeName === '' && inputPressedValidator.storeName
                ? { message: 'Campo obrigatório' }
                : false
            }
            value={storeInput.storeName}
            label="Nome da Loja"
            disabled={isLoading}
            suffix={
              isLoading ? <s.Loading type="spinner" color="maincolor" /> : null
            }
          />
          <s.InputText
            maxLength={18}
            value={applyCpfCnpjWithXXMask(storeInput.cnpj)}
            onChange={(e) => {
              setStoreInput({ ...storeInput, cnpj: e.target.value })
              setInputPressedValidator({
                ...inputPressedValidator,
                cnpj: true,
              })
            }}
            error={cpfCnpjErrorHandler()}
            label="CNPJ/CPF"
            disabled={isLoading}
            suffix={
              isLoading ? <s.Loading type="spinner" color="maincolor" /> : null
            }
          />
        </s.UpperWrapper>
        <s.BottomWrapper>
          <s.InputText
            onChange={(e) => {
              setAddress({ ...address, cep: e.target.value.trim() })
              setInputPressedValidator({
                ...inputPressedValidator,
                cep: true,
              })
            }}
            disabled={isLoading}
            onBlur={() => {
              if (
                address?.cep?.replace(/-/g, '').length === 8 &&
                inputPressedValidator.cep &&
                address?.cep?.replace(/-/g, '') !== geoAddress?.postal_code
              ) {
                loadGeoAddress(address?.cep?.replace(/-/g, ''))
              }
            }}
            error={
              address.cep === '' && inputPressedValidator.cep
                ? { message: 'Campo obrigatório' }
                : false
            }
            value={applyCEPMask(address.cep)}
            label="CEP"
            suffix={
              isGeoAddressLoading || isLoading ? (
                <s.Loading type="spinner" color="maincolor" />
              ) : null
            }
            maxLength={9}
          />

          <s.InputText
            onChange={(e) => {
              setAddress({ ...address, streetAddress: e.target.value })
              setInputPressedValidator({
                ...inputPressedValidator,
                streetAddress: true,
              })
            }}
            error={
              address.streetAddress === '' &&
              inputPressedValidator.streetAddress
                ? { message: 'Campo obrigatório' }
                : false
            }
            value={address.streetAddress}
            label="Rua"
            suffix={
              isGeoAddressLoading || isLoading ? (
                <s.Loading type="spinner" color="maincolor" />
              ) : null
            }
            disabled={isLoading}
          />
          <s.InputText
            onChange={(e) => {
              setAddress({ ...address, number: e.target.value })
              setInputPressedValidator({
                ...inputPressedValidator,
                number: true,
              })
            }}
            error={
              address.number === '' && inputPressedValidator.number
                ? { message: 'Campo obrigatório' }
                : false
            }
            type="number"
            value={address.number}
            label="Nº"
            disabled={isLoading}
            suffix={
              isLoading ? <s.Loading type="spinner" color="maincolor" /> : null
            }
          />
          <s.InputText
            onChange={(e) => {
              setAddress({ ...address, complement: e.target.value })
            }}
            value={address.complement}
            label="Complemento"
            disabled={isLoading}
            suffix={
              isLoading ? <s.Loading type="spinner" color="maincolor" /> : null
            }
          />
          <s.InputText
            onChange={(e) => {
              setAddress({ ...address, neighbourhood: e.target.value })
              setInputPressedValidator({
                ...inputPressedValidator,
                neighbourhood: true,
              })
            }}
            suffix={
              isGeoAddressLoading || isLoading ? (
                <s.Loading type="spinner" color="maincolor" />
              ) : null
            }
            error={
              address.neighbourhood === '' &&
              inputPressedValidator.neighbourhood
                ? { message: 'Campo obrigatório' }
                : false
            }
            value={address.neighbourhood}
            label="Bairro"
            disabled={isLoading}
          />
          <s.InputText
            onChange={(e) => {
              setAddress({ ...address, city: e.target.value })
              setInputPressedValidator({
                ...inputPressedValidator,
                city: true,
              })
            }}
            error={
              address.city === '' && inputPressedValidator.city
                ? { message: 'Campo obrigatório' }
                : false
            }
            value={address.city}
            suffix={
              isGeoAddressLoading || isLoading ? (
                <s.Loading type="spinner" color="maincolor" />
              ) : null
            }
            label="Cidade"
            disabled={isLoading}
          />
          <s.SelectState
            optionBoxDirection="top"
            optionBoxSize={200}
            label="Estado"
            suffix={
              isGeoAddressLoading || isLoading ? (
                <s.Loading type="spinner" color="maincolor" />
              ) : null
            }
            options={ufOptions}
            selected={selectedUf}
            disabled={isLoading}
          />
        </s.BottomWrapper>
        <s.ButtonsWrapper>
          <s.Button onClick={handleClose} width="100px" outline color="white">
            Cancelar
          </s.Button>
          <s.Button
            width="100px"
            disabled={
              !checkIfAllInputsAreValid() ||
              postIsLoading ||
              isLoading ||
              isGeoAddressLoading
            }
            onClick={() => {
              createStore()
            }}
          >
            Editar
          </s.Button>
          {postIsLoading ? (
            <s.Loading type="spinner" color="maincolor" />
          ) : null}
        </s.ButtonsWrapper>
      </s.ModalContainer>
    </s.Modal>
  )
}

export default EditStoreModal
