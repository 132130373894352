import React, { createRef, forwardRef, InputHTMLAttributes } from 'react'
import { dict } from 'components/IconExporter'

import * as s from './styles'
import { themeColors } from 'styles/theme'

interface IProps {
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  error?: boolean | { icon?: keyof typeof dict; message: string }
  disabled?: boolean
  width?: number | string
  labelColor?: keyof typeof themeColors
  height?: number
  label?: string
  name?: string
  placeholder?: string
  maxLength?: number
  type?: string
  testId?: string
}

const InputText = forwardRef<
  HTMLInputElement,
  IProps & Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'>
>(
  (
    {
      prefix,
      suffix,
      error,
      width,
      height = 40,
      onChange,
      maxLength,
      value,
      name = '',
      label,
      labelColor,
      placeholder,
      disabled,
      testId = '',
      type,
      ...rest
    },
    ref
  ) => {
    const inputRef = (ref || createRef()) as React.RefObject<HTMLInputElement>

    const handleColor = (error, labelColor): typeof labelColor => {
      if (error) return 'lightred1'
      if (labelColor) return labelColor
      return 'maincolor'
    }

    return (
      <s.InputFieldGroup height={height} hasLabel={!!label} disabled={disabled}>
        <s.InputGroupWrapper disabled={disabled}>
          {label ? (
            <s.Text bold type="headline" color={handleColor(error, labelColor)}>
              {label}
            </s.Text>
          ) : null}
          <s.InputWrapper
            error={error}
            height={height}
            width={width}
            disabled={disabled}
            onClick={() => {
              inputRef && inputRef.current && inputRef.current.focus()
            }}
            {...rest}
          >
            {prefix && <s.InputAddon id="prefix">{prefix}</s.InputAddon>}
            <s.Input
              ref={inputRef}
              maxLength={maxLength}
              type={type}
              name={name}
              disabled={disabled}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              data-testid={testId !== '' ? testId : label?.toLowerCase()}
            />
            {suffix && <s.InputAddon id="suffix">{suffix}</s.InputAddon>}
          </s.InputWrapper>
        </s.InputGroupWrapper>
        {error && typeof error === 'object' && (
          <s.ErrorMessage>
            {error.icon ? <s.ErrorIcon name={error.icon} width="16px" /> : null}
            <s.TextError
              type="tag"
              fontWeight={400}
              color="cancelledStatusTextColor"
            >
              {error.message}
            </s.TextError>
          </s.ErrorMessage>
        )}
      </s.InputFieldGroup>
    )
  }
)

export default InputText
