import { ActionInterface, Types, StoresState } from './types'

const INITIAL_STATE: StoresState = {
  stores: { count: 0, data: [], offset: 0, total: -1 },
  isLoading: false,
  hasError: false,
}

const storesReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): StoresState => {
  switch (action.type) {
    case Types.GET_STORES:
      return {
        ...state,
        stores: action.payload,
        isLoading: false,
        hasError: false,
      }
    case Types.GET_STORES_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_STORES_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export default storesReducer
