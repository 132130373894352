import React from 'react'

import {
  baseURLRegistrationPlatformCost,
  baseURLRegistrationV1SplitFee,
  baseURLRegistrationV2DestinationAccounts,
  post,
  get,
} from 'services/api'

import { formatPixKey, getPixKeyType } from 'helpers/pix'
import { applyCnpjMask, formatMoney, formatPercentage } from 'helpers/masks'
import DestinationAccountCard from './DestinationAccountCard'
import FeePercentageSimulator from './FeePercentageSimulator'
import useDebounce from 'hooks/useDebounce'

import Toast, { showToast } from 'components/Toast'

import { errorTranslatorHandler } from 'helpers'
import * as s from './styles'

const FirstRegistration: React.FC<{
  setHasSplitFee: () => void
}> = ({ setHasSplitFee }) => {
  const [account, setAccount] = React.useState({
    cpf_cnpj: '',
    pix_dict_key: '',
    name: '',
  })
  const [splitFee, setSplitFee] = React.useState({
    total_split_fee_value: '',
    split_fee_type: 'fixed',
    minimum_fee: '',
  })
  const [splitFeeValue, setSplitFeeValue] = React.useState(0)
  const [minimumFee, setMinimumFee] = React.useState(0)
  const debouncedSplitFeeValue = useDebounce(setSplitFeeValue, 500)
  const debouncedMinimumFee = useDebounce(setMinimumFee, 500)
  const [showAccountForm, setShowAccountForm] = React.useState<boolean>(true)
  const [platformCost, setPlatformCost] = React.useState<number>(0)
  const [isLoading, setIsLoading] = React.useState(false)
  const [platformCostIsLoading, setPlatformCostIsLoading] = React.useState(
    false
  )
  const [splitType, setSplitType] = React.useState<'fixed' | 'percentage'>(
    'fixed'
  )

  const overpricingConvertToString = () => {
    return formatMoney(
      (
        parseFloat(
          splitFee.total_split_fee_value
            .replace(/[R$.]/g, '')
            .trim()
            .replace(',', '.')
        ) - platformCost
      ).toFixed(2)
    )
  }

  const overpricingConvertToFloat = () => {
    const value = +(
      parseFloat(
        splitFee.total_split_fee_value
          .replace(/[R$.]/g, '')
          .trim()
          .replace(',', '.')
      ) - platformCost
    ).toFixed(2)

    return value
  }

  const firstStepValidationSaga = async () => {
    setIsLoading(true)
    const payload: any = {
      ...account,
      entity_type: 'pos',
      pix_dict_key_type: getPixKeyType(account.pix_dict_key),
    }

    await post(`${baseURLRegistrationV2DestinationAccounts}`, payload)
      .then((res) => {
        splitFeeCreation(res.data.destination_account_id)
      })
      .catch((e) => {
        const error = JSON.parse(e.request.response).detail
        if (error[0].msg) {
          showToast({
            type: 'error',
            message: errorTranslatorHandler(error[0].msg),
          })
        } else {
          showToast({
            type: 'error',
            message: JSON.parse(e.request.response).detail,
          })
        }
        setIsLoading(false)
      })
  }

  const splitFeeCreation = async (id: string) => {
    const { minimum_fee, ...splitFeeExpectMinimumFee } = splitFee
    const payload: any = {
      ...(splitType === 'fixed'
        ? splitFeeExpectMinimumFee
        : {
            ...splitFee,
            minimum_fee: parseFloat(
              splitFee.minimum_fee
                .replace(/[R$.]/g, '')
                .trim()
                .replace(',', '.')
            ),
          }),
      total_split_fee_value: parseFloat(
        splitFee.total_split_fee_value
          .replace(/[R$.]/g, '')
          .trim()
          .replace(',', '.')
      ),
      split_commissions: [
        {
          destination_account_id: id,
          value: splitType === 'fixed' ? overpricingConvertToFloat() : 100,
          split_value_type: splitFee.split_fee_type,
        },
      ],
    }
    await post(baseURLRegistrationV1SplitFee, payload)
      .then((res) => {
        setHasSplitFee()
      })
      .catch((e) => {
        showToast({
          type: 'error',
          message: JSON.parse(e.request.response).detail,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const checkOverpricingNegative = React.useCallback(() => {
    return overpricingConvertToString().includes('-')
  }, [splitFee])

  const checkIfAllInputsAreValid = React.useCallback(() => {
    const accountIsValid = Object.values(account).every((item, index) => {
      return item !== null && item !== '' && item !== undefined
    })
    const { minimum_fee, ...splitFeeExpectMinimumFee } = splitFee
    const splitFeeIsValid =
      Object.values({
        ...(splitType === 'fixed' ? splitFeeExpectMinimumFee : splitFee),
      }).every((item) => {
        return item !== null && item !== '' && item !== 'R$ '
      }) &&
      !splitFee.total_split_fee_value.includes('-') &&
      !overpricingConvertToString().includes('-')
    return accountIsValid && splitFeeIsValid
  }, [account, splitFee])

  const checkIfSplitPercentageInputsAreValid = React.useCallback(() => {
    const splitFeeIsValid = Object.values(splitFee).every((item) => {
      return item !== null && item !== '' && item !== 'R$ '
    })

    return splitFeeIsValid
  }, [splitFee])

  const getPlatformCost = async () => {
    setPlatformCostIsLoading(true)
    await get(baseURLRegistrationPlatformCost)
      .then((res) => {
        setPlatformCost(res.data.value)
      })
      .catch((e) => {
        showToast({
          type: 'error',
          message: JSON.parse(e.request.response).detail,
        })
      })
      .finally(() => {
        setPlatformCostIsLoading(false)
      })
  }

  React.useEffect(() => {
    getPlatformCost()
  }, [])

  return (
    <s.Container>
      <s.Text bold type="heading3" color="maincolor">
        Configuração de Tarifa do Pix Qualquer Banco Itaú
      </s.Text>
      <s.Wrapper>
        <div>
          <s.SplitFeeCard width={508} height={606}>
            <s.WrapperText>
              <s.Icon fill="maincolor" name="payments" />
              <s.Text bold color="maincolor" type="headline">
                Tarifa Padrão
              </s.Text>
            </s.WrapperText>
            <s.Text color="graytheme6">
              Tarifa que será descontada do lojista por Pix liquidado. A tarifa
              padrão incidirá sobre todos os clientes da automação, sendo
              possível personalizar uma tarifa para um cliente específico
              acessando a página de Pix, na área do cliente.
            </s.Text>
            <s.Text bold type="headline" color="maincolor">
              Modalidade da Comissão
            </s.Text>
            <s.ComissionType>
              <s.RadioWrapper
                disabled={splitType === 'percentage' && showAccountForm}
              >
                <s.RadioButton
                  id={'1'}
                  checked={splitType === 'fixed'}
                  handleChange={() => {
                    setSplitType('fixed')
                    setSplitFee({
                      minimum_fee: '',
                      split_fee_type: 'fixed',
                      total_split_fee_value: '',
                    })
                    setAccount({ cpf_cnpj: '', pix_dict_key: '', name: '' })
                    setShowAccountForm(true)
                  }}
                  name="fixedValue"
                  disabled={splitType === 'percentage' && showAccountForm}
                  value={splitType === 'fixed'}
                />
                <s.Text bold>Valor Fixo</s.Text>
              </s.RadioWrapper>
              <s.RadioWrapper
                disabled={splitType === 'percentage' && showAccountForm}
              >
                <s.RadioButton
                  id={'2'}
                  checked={splitType === 'percentage'}
                  handleChange={() => {
                    setSplitType('percentage')
                    setSplitFee({
                      minimum_fee: '',
                      split_fee_type: 'percentage',
                      total_split_fee_value: '',
                    })
                    setAccount({ cpf_cnpj: '', pix_dict_key: '', name: '' })
                    setShowAccountForm(false)
                  }}
                  name="percentageValue"
                  disabled={splitType === 'percentage' && showAccountForm}
                  value={splitType === 'percentage'}
                />
                <s.Text bold>Percentual</s.Text>
              </s.RadioWrapper>
            </s.ComissionType>
            {splitType === 'fixed' ? (
              <>
                <s.InputText
                  label="Tarifa Padrão"
                  value={splitFee.total_split_fee_value}
                  disabled={platformCostIsLoading}
                  onChange={(e) => {
                    setSplitFee({
                      ...splitFee,
                      total_split_fee_value: formatMoney(e.target.value),
                    })
                  }}
                />
                <s.InputText
                  label="Custo Plataforma Pix Itaú"
                  value={formatMoney(platformCost + '')}
                  disabled
                  suffix={
                    platformCostIsLoading ? <s.Loading type="spinner" /> : null
                  }
                />
                <s.InputText
                  label="Seu Ganho (Overpricing)"
                  value={
                    checkOverpricingNegative() || !overpricingConvertToFloat()
                      ? '-'
                      : overpricingConvertToString()
                  }
                  disabled
                />
              </>
            ) : (
              <>
                <s.InputText
                  label="Tarifa Padrão"
                  value={splitFee.total_split_fee_value}
                  disabled={platformCostIsLoading || showAccountForm}
                  maxLength={5}
                  suffix={
                    <s.Text bold type="headline" color="maincolor">
                      %
                    </s.Text>
                  }
                  onChange={(e) => {
                    setSplitFee({
                      ...splitFee,
                      total_split_fee_value: formatPercentage(e.target.value),
                    })
                    debouncedSplitFeeValue(
                      parseFloat(
                        formatPercentage(e.target.value)
                          .replace(/[R$.]/g, '')
                          .trim()
                          .replace(',', '.')
                      )
                    )
                  }}
                />
                <div>
                  <s.Align>
                    <s.Text
                      margin="0 0 8px 0"
                      bold
                      color="maincolor"
                      type="headline"
                    >
                      Tarifa Mínima
                    </s.Text>
                    <s.Hover>
                      <s.Icon fill="maincolor" name="questionmarkcircle" />
                      <s.TooltipText>
                        Caso o percentual da tarifa resulte em menos do que este
                        valor, será cobrada a tarifa mínima. Sua tarifa mínima
                        deverá ser superior ao Custo Plataforma Pix Itaú para
                        continuar.
                      </s.TooltipText>
                    </s.Hover>
                  </s.Align>
                  <s.InputText
                    disabled={showAccountForm}
                    value={splitFee.minimum_fee}
                    onChange={(e) => {
                      setSplitFee({
                        ...splitFee,
                        minimum_fee: formatMoney(e.target.value),
                      })
                      debouncedMinimumFee(
                        parseFloat(
                          formatMoney(e.target.value)
                            .replace(/[R$.]/g, '')
                            .trim()
                            .replace(',', '.')
                        )
                      )
                    }}
                  />
                </div>
              </>
            )}
          </s.SplitFeeCard>
          {splitType === 'percentage' && !showAccountForm ? (
            <s.Button
              onClick={() => {
                if (minimumFee <= platformCost) {
                  showToast({
                    type: 'error',
                    message: `Sua tarifa mínima
                    deverá ser superior ao Custo Plataforma Pix Itaú para
                    continuar.`,
                  })
                } else {
                  setShowAccountForm(true)
                }
              }}
              disabled={
                !checkIfSplitPercentageInputsAreValid() ||
                isLoading ||
                platformCostIsLoading
              }
              width="508px"
              height="60px"
            >
              <s.Text bold color="white">
                Confirmar Tarifa
              </s.Text>
              {isLoading ? (
                <s.Loading type="spinner" color="white" />
              ) : (
                <s.Icon name="arrowright" fill="white" />
              )}
            </s.Button>
          ) : (
            <></>
          )}
        </div>
        {splitType === 'percentage' && !showAccountForm ? (
          <FeePercentageSimulator
            minimumFee={minimumFee}
            feeValue={splitFeeValue}
            platformCost={platformCost}
          />
        ) : (
          <div>
            <DestinationAccountCard
              account={account}
              setAccount={setAccount}
              checkIfAllInputsAreValid={checkIfAllInputsAreValid}
              firstStepValidationSaga={firstStepValidationSaga}
              isLoading={isLoading}
              platformCostIsLoading={platformCostIsLoading}
            />
            {splitType === 'percentage' ? (
              <s.BackButton
                onClick={() => {
                  setShowAccountForm(false)
                }}
                color="transparent"
                outline
                disabled={isLoading || platformCostIsLoading}
                width="508px"
                height="60px"
              >
                <s.Text bold color="maincolor">
                  Voltar
                </s.Text>
              </s.BackButton>
            ) : (
              <></>
            )}
          </div>
        )}
      </s.Wrapper>
    </s.Container>
  )
}

export default FirstRegistration
