import styled, { css } from 'styled-components'
import { Container as ContainerCmp } from 'styled-bootstrap-grid'

import dashedLine from 'assets/icons/divisoria_pontilhada.svg'

import TableCmp from 'components/Table'
import TextCmp from 'components/Text'
import SelectCmp from 'components/Inputs/Select'
import InputTextCmp from 'components/Inputs/Text'
import IconCmp from 'components/IconExporter'
import ModalCmp from 'components/Modal'
import CheckboxCmp from 'components/Inputs/Checkbox'
import RadioButtonCmp from 'components/Inputs/RadioButton'
import LoadingCmp from 'components/Loading'
import ButtonCmp from 'components/Button'
import PaginationCmp, {
  CursorPagination as CursorPaginationCmp,
} from 'components/Pagination'
import DatepickerCmp from 'components/Datepicker'
import IconExporter from 'components/IconExporter'

import { InputFieldGroup } from 'components/Inputs/Text/styles'

export const PanelQueryButton = styled(ButtonCmp)`
  margin-right: 20px;
`

export const PanelQueryConfirmModalText = styled(TextCmp)`
  text-align: center;
`

export const PanelQueryModal = styled(ModalCmp)`
  z-index: 14;
`

export const PanelQueryModalContent = styled.div`
  width: 450px;
  text-align: center;
`

export const Loading = styled(LoadingCmp)`
  margin-left: 16px;
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Container = styled(ContainerCmp)`
  max-height: calc(100vh - 100px);
  max-width: 1500px;
  position: relative;

  margin-top: ${({ theme }) => theme.spacing(4)}px;
`

export const Wrapper = styled(ContainerCmp)`
  max-width: 1500px;
  position: relative;

  & > * {
    margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  }
`

export const CashoutWrapper = styled(Wrapper)``

export const TooltipText = styled.span`
  visibility: hidden;
  width: 290px;
  background-color: ${({ theme }) => theme.colors.graytheme6};
  color: white;
  max-height: 56px;
  text-align: center;
  border-radius: 6px;
  padding: 8px 8px;
  position: absolute;
  z-index: 10;
  right: 272px;
  bottom: -16px;
`

export const Hover = styled.div`
  position: relative;
  margin-left: auto;
  :hover {
    cursor: help;
    ${TooltipText} {
      visibility: visible;
    }
  }
`

export const OrdersHeader = styled.div`
  ${({ theme }) => theme.assets.orders.pageHeader};
`

export const Text = styled(TextCmp)`
  > svg {
    vertical-align: middle;
    display: inline-block;
  }
  > em {
    font-style: normal;
    color: ${({ theme }) => theme.colors.maincolor};
  }
`
export const StatusText = styled(TextCmp)`
  ${({ theme }) => theme.assets.orders.columns.status}
`

export const StatusContainer = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, color }) => theme.colors[color]};
  width: fit-content;
  border-radius: 8px;
  padding: 4px;
`

export const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`

export const SearchTextWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`

export const SearchSelectWrapper = styled.div`
  > div {
    margin-top: 15px;
  }
`

export const ButtonsWrapperHeader = styled.div`
  position: absolute;
  flex-direction: row;
  padding-top: 20px;
  right: 10px;

  > :first-child {
    margin-right: 16px;
  }
`

export const InputsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
    
  ${InputFieldGroup} {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
    padding-top: ${({ theme }) => theme.spacing(1)}px;
  }
`

export const Table = styled(TableCmp)`
  ${({ theme }) => theme.assets.orders.table};
`

export const OrdersSummaryFilesTable = styled(TableCmp)`
  width: 700px;
  * > th {
    :first-child {
      width: 35%;
    }
    :nth-child(3) {
      width: 15%;
    }
  }
`

export const Select = styled(SelectCmp)`
  width: 200px;
  margin-top: 8px;
`

export const InputText = styled(InputTextCmp)`
  margin-top: 8px;
`

export const Icon = styled(IconCmp)`
  margin-right: 5px;
`

export const Image = styled.img``

export const IconClearDate = styled(IconCmp)`
  padding-top: 7px;

  :hover {
    cursor: pointer;
  }
`
export const IdWrapper = styled.div`
  width: 75%;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const IdContainer = styled.div`
  ${({ theme }) => theme.assets.orders.columns.uuidContainer};
`
export const CollapseWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

export const Modal = styled(ModalCmp)`
  > div:nth-child(2) {
    padding: 0px 32px;
  }

  div::-webkit-scrollbar-track {
    margin: 32px 0px;
  }

  header {
    height: 86px;
    background: ${({ theme }) => theme.colors.white};
    margin: 0px -40px;
    padding: 0px 32px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};

    h5 {
      color: ${({ theme }) => theme.colors.maincolor};
      margin-left: 24px;
    }
  }
`

interface IModalContainer {
  width?: number
  height?: number
}

export const ModalContainer = styled.div<IModalContainer>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  padding: 0 16px;
`

export const ModalDetails = styled.div`
  display: flex;
  padding: 0 4px;
  margin: 32px 0px;
`

export const ModalDetailsLabel = styled.div`
  > p {
    :nth-child(2),
    :nth-child(4),
    :nth-child(7),
    :last-child {
      margin-bottom: 8px;
    }
  }
`
export const ModalDetailsValue = styled.div`
  margin-left: ${({ theme }) => theme.spacing(6)}px;

  > p {
    :nth-child(2),
    :nth-child(4),
    :nth-child(7),
    :last-child {
      margin-bottom: 8px;
    }
  }
`

export const CashoutModalBankAccountDetailsLabel = styled.div`
  > p {
    :nth-child(3),
    :nth-child(6) {
      margin-bottom: 8px;
    }
  }
`
export const CashoutModalBankAccountDetailsValue = styled.div`
  margin-left: ${({ theme }) => theme.spacing(6)}px;

  > p {
    :nth-child(3),
    :nth-child(6) {
      margin-bottom: 8px;
    }
  }
`

export const CashoutModalTransactionDetailsLabel = styled.div`
  > p {
    :nth-child(2),
    :nth-child(4),
    :nth-child(7),
    :nth-child(10) {
      margin-bottom: 8px;
    }
  }
`
export const CashoutModalTransactionDetailsValue = styled.div`
  margin-left: ${({ theme }) => theme.spacing(6)}px;

  > p {
    :nth-child(2),
    :nth-child(4),
    :nth-child(7),
    :nth-child(10) {
      margin-bottom: 8px;
    }
  }
`

export const DottedLine = styled.div`
  //Por conta de um bug visual, é necessario usar background-image
  //Pois o border dashed apresentava uma linha solid ao ser usado em sequencia
  height: 1px;
  background-image: url(${dashedLine});
`
export const DottedBorderLine = styled.div`
  width: calc(100% + 50px);
  height: 0px;
  padding: 0px;
  border: 1px dashed;
  color: ${({ theme }) => theme.colors.gray2};
`

interface IModalItemsDropdown {
  isDropdownOpen: boolean
}

export const ModalItemsDropdown = styled.div<IModalItemsDropdown>`
  margin: ${({ theme }) => theme.spacing(1)}px 0
    ${({ theme }) => theme.spacing(1)}px 0;
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  justify-content: space-between;
  ${({ isDropdownOpen, theme }) =>
    isDropdownOpen
      ? css`
          background: ${theme.colors.graytheme1};
        `
      : css`
          background: inherit;
        `};

  :hover {
    background: ${({ theme }) => theme.colors.lightmaincolor3};
  }

  > svg {
    transition: transform 0.3s linear;
    ${({ isDropdownOpen }) =>
      isDropdownOpen
        ? css`
            transform: rotate(180deg);
          `
        : css`
            transform: rotate(0deg);
          `};
  }
`

export const ModalTransitionLog = styled.div``

export const ModalTransitionWrapper = styled.div`
  background: ${({ theme }) => theme.colors.darkmaincolor3};
  border-radius: 8px;
  padding: 8px;
  min-height: 400px;
  color: ${({ theme }) => theme.colors.white};
`

interface IListItems {
  isDropdownOpen: boolean
}

export const ListItems = styled.div<IListItems>`
  padding: 0 8px;
  display: flex;
`
export const TotalWrapper = styled.div`
  padding-left: 24px;
  margin-top: 10px;
  display: flex;
`

export const QuantityList = styled.div``

export const ItemHeaderText = styled(TextCmp)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const ItemQuantityWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(0.5)}px;
  width: 40px;
  text-align: center;
  background: ${({ theme }) => theme.colors.graytheme1};
  border-radius: 2px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`

export const ItemNameList = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`

export const ItemNameWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(0.5)}px;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`

export const ItemNameWrapperSplitFees = styled.div`
  padding: ${({ theme }) => theme.spacing(0.5)}px;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(0.2)}px;
`

export const ItemValueWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(0.5)}px;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`

export const ItemValueWrapperSplitFee = styled.div`
  padding: ${({ theme }) => theme.spacing(0.5)}px;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(0.2)}px;
`

export const ValueList = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
`

interface FilterAccordion {
  isFilterAccordionOpen: boolean
}

export const FilterAccordionContainer = styled.div<FilterAccordion>`
  /* height: 0px; */
  /* overflow: hidden; */
  display: none;
  opacity: 0;
  margin: 0;
  visibility: hidden;
  transition: visibility 1s, opacity 0.5s linear;
  ${({ isFilterAccordionOpen }) =>
    isFilterAccordionOpen &&
    css`
      visibility: visible;
      transition: visibility 1s, opacity 0.5s linear;
      display: flex;
      align-items: flex-end;
      opacity: 1;
    `}
`

export const ClearFilters = styled.div`
  margin: 0 8px 8px auto;
  padding: 8px;
  color: red;
`

export const Checkbox = styled(CheckboxCmp)`
  margin-bottom: 8px;
`

export const RadioButton = styled(RadioButtonCmp)``

export const ValueInput = styled.input`
  width: 54px;
  height: 20px;
  border: none;
  border-radius: 2px;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
  border: 2px solid ${({ theme }) => theme.colors.lightmaincolor2};
  box-shadow: 0px 1px 8px 1px #cce9ff;

  :hover {
    box-shadow: 0px 1px 8px 2px rgba(92, 170, 229, 0.3);
  }

  :focus {
    border: 2px solid ${({ theme }) => theme.colors.maincolor};
  }
`

export const DateInput = styled.input`
  width: 80px;
  height: 20px;
  border: none;
  border-radius: 2px;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
  border: 2px solid ${({ theme }) => theme.colors.lightmaincolor2};
  box-shadow: 0px 1px 8px 1px #cce9ff;

  :hover {
    box-shadow: 0px 1px 8px 2px rgba(92, 170, 229, 0.3);
  }

  :focus {
    border: 2px solid ${({ theme }) => theme.colors.maincolor};
  }

  ::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`
export const FilterHeader = styled.h4`
  position: absolute;
  top: 180px;
  padding-bottom: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
`

export const FilterWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px;

  :nth-child(1) {
    min-width: 15%;
  }
  :nth-child(2) {
    min-width: 12%;
  }
  :nth-child(3) {
    min-width: 26.5%;
  }
  :nth-child(4) {
    min-width: 1%;
  }
  :nth-child(5) {
    min-width: 14%;
  }
`

export const DateContainer = styled.div`
  display: flex;

  > p {
    :nth-child(2) {
      white-space: break-spaces;
    }
  }
`

export const StoreWrapper = styled.div`
  display: flex;
`

export const WalletWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ModalTransitionText = styled.div`
  font-family: Consolas;
`

export const ModalTransitionHeaderFormatter = styled.div`
  cursor: pointer;
  color: #02cddb;
  font-weight: bold;
  padding: 20px 0px 10px 0px;
  font-family: Consolas;
  font-size: 18px;
`

export const ModalTransitionFormatter = styled.pre`
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-break: break-all;
`

export const ModalTransitionLogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 8px 32px 8px;
  font-size: 16px;

  button {
    margin-left: 16px;
  }
`

export const ButtonWrapper = styled.div``

export const Button = styled(ButtonCmp)``

export const ButtonIcon = styled(IconExporter)`
  margin-right: 8px;
`

export const UpdateButton = styled(ButtonCmp)`
  margin-left: 16px;
`

export const SalesFilesButton = styled(ButtonCmp)`
  height: 40px;
  margin: auto 0 auto auto;
  top: 8px;

  svg {
    fill: ${({ theme }) => theme.colors.maincolor};
  }
`

export const CursorPagination = styled(CursorPaginationCmp)``

export const Datepicker = styled(DatepickerCmp)`
  display: inline-block;
  width: 80px;
  margin-right: 8px;
  margin-bottom: 8px;
`

export const ClearDatepicker = styled.div`
  display: inline-block;
`

export const FilterButton = styled.button`
  border-radius: 8px;
  border: 0;
  padding: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
  display: flex;
  height: 40px;
  width: 162px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.gray2};
  margin: 0 16px 8px auto;
  padding: 8px;

  :hover {
    cursor: pointer;
    box-shadow: ${({ theme }) => theme.shadows.blue3hover};

    > h5 {
      color: ${({ theme }) => theme.colors.maincolor};
    }
  }

  :active {
    background-color: ${({ theme }) => theme.colors.lightmaincolor3};
    box-shadow: ${({ theme }) => theme.shadows.blue4pressed};
  }

  > * {
    :first-child {
      margin-right: ${({ theme }) => theme.spacing(1)}px;
    }
  }
`

export const FilterApplyButton = styled.button`
  border-radius: 8px;
  border: 0;
  padding: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
  display: flex;
  height: 40px;
  width: 162px;
  background-color: ${({ theme }) => theme.colors.maincolor};
  box-shadow: ${({ theme }) => theme.shadows.gray2};
  margin: 0 16px 8px auto;
  padding: 8px;

  :hover {
    cursor: pointer;
    box-shadow: ${({ theme }) => theme.shadows.blue3hover};

    > h5 {
      color: ${({ theme }) => theme.colors.lightmaincolor1};
    }
  }

  :active {
    background-color: ${({ theme }) => theme.colors.lightmaincolor3};
    box-shadow: ${({ theme }) => theme.shadows.blue4pressed};
  }

  > * {
    :first-child {
      margin-right: ${({ theme }) => theme.spacing(1)}px;
    }
  }
`

export const ButtonsWrapper = styled.div`
  margin: 0 16px 8px auto;
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const ModalHeader = styled.div`
  display: flex;
`

export const EmptyOrdersSummaryFiles = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  height: 343px;
  width: 681px;
  padding: ${({ theme }) => theme.spacing(4)}px;

  > ${Text} {
    margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  }
`
export const ModalPagination = styled(PaginationCmp)``

export const Submenu = styled.div`
  display: flex;
  gap: 32px;
  position: absolute;
  z-index: 2;

  margin-top: 34px;
`

export const SubmenuItem = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 29px;
    font-weight: 300;
  }

  :hover {
    cursor: pointer;
  }
`

export const SubmenuItemSelected = styled.div`
  margin-top: 16px;
  border-radius: 5px;
  height: 3px;
  border-bottom: solid 3px ${({ theme }) => theme.colors.maincolor};
`
