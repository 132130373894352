import React from 'react'

import useGetCustomers from 'hooks/useGetCustomer'
import useDebounce from 'hooks/useDebounce'
import useImpersonate from 'hooks/useImpersonate'

import { isConexaoItau, isSpacePay } from 'helpers'

import Toast, { showToast } from 'components/Toast'
import RegistrationModal from './RegistrationModal'
import UserDetailsModal from './UserDetailsModal'

import dict from 'components/IconExporter'

import * as s from './styles'

const Admin: React.FC = () => {
  const { customers, isLoading, hasError, loadCustomers } = useGetCustomers()
  const [searchTextFilter, setSearchTextFilter] = React.useState<string>('')
  const [displaySearchTextFilter, setDisplaySearchTextFilter] =
    React.useState<string>('')
  const debouncedSearchTextFilter = useDebounce(setSearchTextFilter, 1000)
  const [searchTypeFilter, setSearchTypeFilter] =
    React.useState<string>('customer_name')
  const [selected, setSelected] = React.useState({
    id: 1,
    name: 'Nome do cliente',
  })
  const [offset, setOffset] = React.useState<string>('0')
  const { postImpersonate } = useImpersonate()
  const [registrationModal, setRegistrationModal] = React.useState(false)
  const [userDetailsId, setUserDetailsId] = React.useState('')
  const [userDetailsModal, setUserDetailsModal] = React.useState(false)

  React.useEffect(() => {
    if ((searchTypeFilter && searchTextFilter) || offset) {
      loadCustomers({
        limit: '50',
        offset: offset,
        filter: searchTextFilter || '',
        filter_type: searchTypeFilter || '',
      })
    }
  }, [searchTextFilter, searchTypeFilter, offset])

  React.useEffect(() => {
    if (hasError) {
      showToast({
        type: 'error',
        message: 'Erro na API, tente novamente mais tarde',
      })
    }
  }, [hasError])

  const idRender = ({ children }) => {
    return (
      <s.IdContainer
        onClick={(e) => {
          e.stopPropagation()
          navigator.clipboard.writeText(children as string)
          showToast({ type: 'success', message: 'ID Copiado!' })
        }}
      >
        <s.IdWrapper>{children}</s.IdWrapper>
        <s.Icon width={24} height={24} fill="maincolor" name="clipboard" />
      </s.IdContainer>
    )
  }

  const dateRender = ({ children }) => {
    if (!!children) {
      const dateMaskedFirstPart = (children as string)
        .replace(/\D/g, '')
        .replace(/(\d{4})(\d{2})(\d{2})(\d{6})/g, `$3/$2/$1`)

      const dateMaskedSecondPart = (children as string)
        .replace(/\D/g, '')
        .replace(/(\d{8})(\d{2})(\d{2})(\d{2})/g, '$2:$3')

      return (
        <s.DateContainer>
          <s.Text bold color="graytheme6">
            {dateMaskedFirstPart || ''}
          </s.Text>{' '}
          <s.Text color="graytheme6"> às </s.Text>
          <s.Text bold color="graytheme6">
            {dateMaskedSecondPart || ''}
          </s.Text>
        </s.DateContainer>
      )
    } else {
      return children
    }
  }

  const impersonateButton = () => {
    return isSpacePay() ? (
      <s.Button width="90px" color="maincolor">
        <s.Text bold color="white">
          Acessar
        </s.Text>
      </s.Button>
    ) : (
      <s.Button color="maincolor">
        <s.ButtonIcon name="personify" color="white" />
        <s.Text bold color="white">
          Acessar
        </s.Text>
      </s.Button>
    )
  }

  const renderTable = React.useMemo(() => {
    let showExternalCrmId = false
    if (customers.data.length > 0) {
      showExternalCrmId = Object.hasOwn(customers.data[0], 'external_crm_id')
    }

    const customerColumns = [
      { headerName: 'Nome', objectName: 'customer_name' },
      { headerName: 'Sistema', objectName: 'pos_product_name' },
      {
        headerName: isConexaoItau() ? 'Grupo' : 'Rede',
        objectName: 'retail_chain_name',
      },
      {
        headerName: 'Data de criação',
        objectName: 'customer_created_at',
        AltRender: dateRender,
      },
      {
        headerName: 'Código UUID',
        objectName: 'uuid',
        AltRender: idRender,
      },
    ]
    if (showExternalCrmId) {
      customerColumns.push({
        headerName: 'ID do CRM',
        objectName: 'external_crm_id',
        AltRender: idRender,
      })
    }

    return (
      <s.Table
        isLoading={isLoading}
        seeMoreButton
        detailsIdState={[userDetailsId, setUserDetailsId]}
        isModalOpenState={[userDetailsModal, setUserDetailsModal]}
        columns={customerColumns}
        data={customers.data}
        onRowHandleClick={(id: string) => {
          alert(id)
        }}
        actionButtonsConfig={[
          {
            onClick: (customerId: string) => {
              postImpersonate(customerId)
            },
            iconName: 'person' as keyof typeof dict,
            ButtonRender: impersonateButton,
            actionName: '',
          },
        ]}
      />
    )
  }, [
    isLoading,
    userDetailsId,
    setUserDetailsId,
    userDetailsModal,
    setUserDetailsModal,
    customers,
  ])

  return (
    <>
      <Toast />
      <RegistrationModal
        isOpen={registrationModal}
        handleClose={() => setRegistrationModal(false)}
      />
      {userDetailsModal ? (
        <UserDetailsModal
          isOpen={userDetailsModal}
          handleClose={() => {
            setUserDetailsModal(false)
          }}
          userId={userDetailsId}
        />
      ) : null}
      <s.Container>
        <s.Text color="maincolor" bold>
          Buscar por
        </s.Text>
        <s.SearchWrapper>
          <s.InputsWrapper>
            <s.Select
              options={[
                {
                  id: 1,
                  text: 'Nome do cliente',
                  onClick: () => {
                    setSearchTypeFilter('customer_name')
                    setSelected({ id: 3, name: 'Nome do cliente' })
                    setDisplaySearchTextFilter('')
                    setSearchTextFilter('')
                  },
                },

                {
                  id: 2,
                  text: 'CNPJ/CPF da loja',
                  onClick: () => {
                    setSearchTypeFilter('store_cnpj_cpf')
                    setSelected({ id: 2, name: 'CNPJ/CPF da loja' })
                    setDisplaySearchTextFilter('')
                    setSearchTextFilter('')
                  },
                },
                {
                  id: 3,
                  text: 'Nome da loja',
                  onClick: () => {
                    setSearchTypeFilter('store_name')
                    setSelected({ id: 1, name: 'Nome da loja' })
                    setDisplaySearchTextFilter('')
                    setSearchTextFilter('')
                  },
                },
                {
                  id: 4,
                  text: 'PDV',
                  onClick: () => {
                    setSearchTypeFilter('pos_product_name')
                    setSelected({ id: 4, name: 'PDV' })
                    setDisplaySearchTextFilter('')
                    setSearchTextFilter('')
                  },
                },
              ]}
              selected={selected}
            />
            <s.InputText
              suffix={<s.Icon id="icon" name="search" />}
              value={displaySearchTextFilter}
              placeholder="Pesquisar"
              onChange={(e) => {
                if (searchTypeFilter === 'store_cnpj_cpf') {
                  setDisplaySearchTextFilter(e.target.value.trim())
                  const valueBasedOnTypeFilter = e.target.value
                    .replace(/[./-]+/g, '')
                    .trim()
                  debouncedSearchTextFilter(valueBasedOnTypeFilter)
                } else {
                  setDisplaySearchTextFilter(e.target.value)
                  const valueBasedOnTypeFilter = e.target.value
                  debouncedSearchTextFilter(valueBasedOnTypeFilter)
                }
              }}
            />
          </s.InputsWrapper>
          <s.ButtonsWrapper>
            <s.Button
              color="maincolor"
              onClick={() => setRegistrationModal(true)}
            >
              <s.ButtonIcon name="addcustomer" color="white" />
              <s.Text bold color="white">
                Novo Cadastro
              </s.Text>
            </s.Button>
          </s.ButtonsWrapper>
        </s.SearchWrapper>
        {renderTable}
        {customers.total > 0 ? (
          <s.Pagination
            count={50}
            offset="0"
            total={customers.total}
            handleChange={(offset) => {
              setOffset(offset)
            }}
          />
        ) : null}
      </s.Container>
    </>
  )
}

export default Admin
