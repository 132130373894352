import React from 'react'

import * as s from '../styles'
import { applyNumberOnlyMask } from 'helpers/masks'
import { AxiosPromise, AxiosResponse } from 'axios'
import { showToast } from 'components/Toast'
import usePostAuth from 'hooks/usePostAuth'
import { useNonInitialEffect } from 'hooks/utils'
import { baseURLResetPasswordEmail, post } from 'services/api'
import { isValidEmail } from 'helpers/validators'

interface IProps {
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  password: string
  setPassword: React.Dispatch<React.SetStateAction<string>>
  accessCode: string
  setAccessCode: React.Dispatch<React.SetStateAction<string>>
  resendCode: (email: string) => AxiosPromise
  setFlippedCard: React.Dispatch<React.SetStateAction<boolean>>
}

const INITIAL_RESET_PASSWORD_STATE = {
  sendEmail: false,
  loading: false,
  success: false,
  error: false,
}

const InfoBox: React.FC<{
  error?: { title: string; message: React.ReactElement }
}> = ({ error }) => {
  if (error?.title) {
    return (
      <s.InfoBox error>
        <s.Text bold type="paragraph" color="redshipay">
          {error.title}
        </s.Text>
        <s.Text type="paragraph" color="cancelledStatusTextColor">
          {error.message}
        </s.Text>
      </s.InfoBox>
    )
  }

  return (
    <s.InfoBox>
      <s.Text bold type="paragraph" color="graytheme6">
        Um código de acesso foi enviado para seu e-mail
      </s.Text>
      <s.Text type="paragraph" color="graytheme6">
        Caso não encontre, confira o e-mail digitado e sua caixa de spam ou
        envie um código de acesso.
      </s.Text>
    </s.InfoBox>
  )
}

const RenderResendButton: React.FC<{
  useAsResetPassword?: boolean
  resendCode: (email) => AxiosPromise
  resendResetPasswordEmail?: (email) => AxiosPromise
  email: string
  resendSentState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  countdownState: [number, React.Dispatch<React.SetStateAction<number>>]
  resendIsLoadingState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  countdownIntervalRefState: React.MutableRefObject<NodeJS.Timeout | null>
}> = ({
  useAsResetPassword = false,
  resendCode,
  resendResetPasswordEmail,
  email,
  resendSentState,
  countdownState,
  resendIsLoadingState,
  countdownIntervalRefState,
}) => {
  const [resendSent, setResendSent] = resendSentState
  const [countdown, setCountdown] = countdownState
  const [resendIsLoading, setResendIsLoading] = resendIsLoadingState
  const countdownIntervalRef = countdownIntervalRefState
  const startCountdown = (seconds: number) => {
    setCountdown(seconds || countdown)
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current)
    }

    countdownIntervalRef.current = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 0) {
          clearInterval(countdownIntervalRef.current as NodeJS.Timeout)
          setResendSent(false)
          return 0
        }
        return prevCountdown - 1
      })
    }, 1000)
  }

  if (resendIsLoading) {
    return (
      <s.Align>
        <s.Loading width={20} height={20} color="graytheme6" type="spinner" />
        <s.Text
          type="headline"
          margin="0 0 0 8px"
          color="graytheme6"
          fontWeight={500}
        >
          {useAsResetPassword
            ? 'Enviando novo link...'
            : 'Enviando novo código...'}
        </s.Text>
      </s.Align>
    )
  }

  if (countdown >= (useAsResetPassword ? 60 : 10) && resendSent) {
    return (
      <s.Align>
        <s.Icon
          height={20}
          width={20}
          name="checkmarkcircleoutline"
          fill="graytheme6"
        />
        <s.Text
          type="headline"
          margin="0 0 0 8px"
          color="graytheme6"
          fontWeight={500}
        >
          {useAsResetPassword
            ? 'Novo link enviado por e-mail'
            : 'Novo código enviado por e-mail'}
        </s.Text>
      </s.Align>
    )
  }

  if (countdown > 0 && resendSent) {
    return (
      <s.Align>
        <s.Icon height={20} width={20} name="clock" fill="graytheme6" />
        <div style={{ width: '50%' }}>
          <s.Text
            type="headline"
            margin="0 0 0 8px"
            color="graytheme6"
            fontWeight={500}
          >
            {`${countdown}s para solicitar novo ${
              useAsResetPassword ? 'link' : 'código'
            }`}
          </s.Text>
        </div>
      </s.Align>
    )
  }

  if (useAsResetPassword) {
    return (
      <s.ButtonLinkWrapper>
        <s.ButtonLink
          onClick={() => {
            setResendIsLoading(true)
            resendResetPasswordEmail(email)
              .then(() => {
                setResendSent(true)
                startCountdown(65)
              })
              .catch((e: AxiosResponse) => {
                if (e.request.status < 500) {
                  showToast({
                    type: 'error',
                    message:
                      JSON.parse(e.request?.response)?.message ||
                      'Erro no servidor, tente novamente mais tarde',
                  })
                } else {
                  showToast({
                    type: 'error',
                    message: 'Erro no servidor, tente novamente mais tarde',
                  })
                }
              })
              .finally(() => setResendIsLoading(false))
          }}
        >
          <s.Icon name="emailoutline" fill="maincolor" />
          <s.Text
            margin="0 0 0 8px"
            type="headline"
            color="maincolor"
            fontWeight={500}
          >
            Enviar novo link por e-mail
          </s.Text>
        </s.ButtonLink>
      </s.ButtonLinkWrapper>
    )
  }

  return (
    <s.ButtonLinkWrapper>
      <s.ButtonLink
        data-testid="resend-code"
        onClick={() => {
          setResendIsLoading(true)
          resendCode(email)
            .then(() => {
              setResendSent(true)
              startCountdown(15)
            })
            .catch((e: AxiosResponse) => {
              if (e.request.status < 500) {
                showToast({
                  type: 'error',
                  message:
                    JSON.parse(e.request?.response)?.message ||
                    'Erro no servidor, tente novamente mais tarde',
                })
              } else {
                showToast({
                  type: 'error',
                  message: 'Erro no servidor, tente novamente mais tarde',
                })
              }
            })
            .finally(() => setResendIsLoading(false))
        }}
      >
        <s.Icon name="emailoutline" fill="maincolor" />
        <s.Text
          margin="0 0 0 8px"
          type="headline"
          color="maincolor"
          fontWeight={500}
        >
          Enviar novo código por e-mail
        </s.Text>
      </s.ButtonLink>
    </s.ButtonLinkWrapper>
  )
}

const Password: React.FC<IProps> = ({
  email,
  setEmail,
  password,
  setPassword,
  accessCode,
  setAccessCode,
  resendCode,
  setFlippedCard,
}) => {
  const [inputError, setInputError] = React.useState({
    email: false,
    password: false,
    accessCode: false,
  })
  const [infoError, setInfoError] = React.useState({
    title: '',
    message: <></>,
  })
  const [resetPasswordStep, setResetPasswordStep] = React.useState(
    INITIAL_RESET_PASSWORD_STATE
  )

  const [resendSent, setResendSent] = React.useState<boolean>(false)
  const [countdown, setCountdown] = React.useState<number>(0)
  const [resendIsLoading, setResendIsLoading] = React.useState<boolean>(false)
  const countdownIntervalRef = React.useRef<NodeJS.Timeout | null>(null)

  const { hasError, isLoading, errorMessage, postAuth } = usePostAuth()

  const resetPasswordEmail = async (email) => {
    setResetPasswordStep({
      ...INITIAL_RESET_PASSWORD_STATE,
      loading: true,
    })
    await post(baseURLResetPasswordEmail, { user_email: email })
      .then(() => {
        setResetPasswordStep({
          ...INITIAL_RESET_PASSWORD_STATE,
          success: true,
        })
      })
      .catch((e) => {
        setResetPasswordStep({
          ...INITIAL_RESET_PASSWORD_STATE,
          error: true,
        })
      })
  }

  const resendResetPasswordEmail = (email: string) => {
    return post(baseURLResetPasswordEmail, { user_email: email })
  }

  const isFormValid = () => {
    if (password === '' && accessCode === '') {
      setInfoError({
        title: 'Campos obrigatórios não preenchidos',
        message: (
          <>
            Preencha os campos de <u>Senha</u> e <u>Código de acesso</u> para
            prosseguir.
          </>
        ),
      })
      setInputError({ ...inputError, password: true, accessCode: true })
      return false
    } else if (password === '') {
      setInfoError({
        title: 'Campo obrigatório não preenchido',
        message: (
          <>
            Preencha o campo de <u>Senha</u> para prosseguir.
          </>
        ),
      })
      setInputError({ ...inputError, password: true })
      return false
    } else if (accessCode === '') {
      setInfoError({
        title: 'Campo obrigatório não preenchido',
        message: (
          <>
            Preencha o campo de <u>Código de acesso</u> para prosseguir.
          </>
        ),
      })
      setInputError({ ...inputError, accessCode: true })
      return false
    } else {
      setInfoError({
        title: '',
        message: <></>,
      })
      setInputError({ ...inputError, password: false, accessCode: false })
      return true
    }
  }

  useNonInitialEffect(() => {
    if (hasError) {
      switch (errorMessage) {
        case 'O token de autenticação expirou, solicite um novo código de acesso.':
          setInfoError({
            title: 'O token de autenticação expirou',
            message: <>Por favor, solicite um novo código de acesso.</>,
          })
          break
        case 'Você excedeu o limite de tentativas. Por motivos de segurança, você está temporariamente bloqueado. Por favor, aguarde 60 segundos e solicite um novo código de acesso.':
          setInfoError({
            title: 'Você excedeu o limite de tentativas.',
            message: (
              <>
                Por motivos de segurança, você está temporariamente bloqueado.
                Por favor, aguarde 60 segundos e solicite um novo código de
                acesso.
              </>
            ),
          })
          break
        case 'Limite de tentativas atingido. Tente novamente mais tarde.':
          setInfoError({
            title: errorMessage,
            message: (
              <>
                Por favor, aguarde 60 segundos e solicite um novo código de
                acesso
              </>
            ),
          })
          break

        case 'Usuário não autenticado':
          setInfoError({
            title: 'Não foi possível acessar sua conta.',
            message: (
              <>
                E-mail, senha ou código de acesso incorretos. Confira suas
                informações e tente novamente.
              </>
            ),
          })
          break
        default:
          setInfoError({
            title: 'Não foi possível acessar sua conta.',
            message: (
              <>
                E-mail, senha ou código de acesso incorretos. Confira suas
                informações e tente novamente.
              </>
            ),
          })
      }
      setInputError({ email: true, password: true, accessCode: true })
    } else {
      setInfoError({
        title: '',
        message: <></>,
      })
      setInputError({ email: false, password: false, accessCode: false })
    }
  }, [hasError, errorMessage])

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (isFormValid()) {
        postAuth({
          auth_token: +accessCode,
          password: password,
          username: email,
        })
      }
    }
  }

  const handleContent = () => {
    if (resetPasswordStep.sendEmail) {
      return (
        <s.CardWrapper>
          <s.TitleWrapper>
            <s.Text
              margin="24px 0 32px 8px"
              textAlign="left"
              bold
              type="headline"
            >
              Esqueceu a senha?
            </s.Text>
          </s.TitleWrapper>
          <s.PasswordWrapper>
            <>
              {infoError.title ? <InfoBox error={infoError} /> : <></>}
              <s.Text type="paragraph" color="graytheme6">
                Confirme seu e-mail para receber um link de alteração de senha.
                Caso não se lembre de seu e-mail cadastrado, entre em contato
                com o suporte.
              </s.Text>
              <s.SectionLine>
                <s.Text
                  fontWeight={600}
                  type="headline"
                  data-testid="email-label"
                  color={inputError.email ? 'redshipay' : 'graytheme6'}
                >
                  E-mail
                </s.Text>
                <s.InputText
                  testId={`email-test`}
                  width={304}
                  type="email"
                  name="email"
                  error={!!inputError.email}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />
              </s.SectionLine>
            </>
          </s.PasswordWrapper>
          <s.ButtonWrapper>
            <s.Button
              onClick={(e) => {
                e.preventDefault()
                if (isValidEmail(email)) {
                  resetPasswordEmail(email)
                } else {
                  setInputError({
                    ...inputError,
                    email: true,
                  })
                  if (!email) {
                    setInfoError({
                      title: 'Campo obrigatório não preenchido',
                      message: (
                        <>
                          Preencha o campo de <u>E-mail</u> para prosseguir.
                        </>
                      ),
                    })
                    return
                  }

                  setInfoError({
                    title: 'Email inválido',
                    message: (
                      <>
                        Preencha o campo de <u>E-mail</u> corretamente.
                      </>
                    ),
                  })
                }
              }}
              data-testid="button-send-password-reset-link"
              color="primary"
              width="100%"
            >
              <s.Text margin="0 8px 0 0" fontWeight={600} color="white">
                Enviar link
              </s.Text>
            </s.Button>
          </s.ButtonWrapper>
        </s.CardWrapper>
      )
    }
    if (resetPasswordStep.success) {
      return (
        <s.CardWrapper>
          <s.TitleWrapper>
            <s.Text
              margin="24px 0 32px 8px"
              textAlign="left"
              bold
              type="headline"
            >
              Esqueceu a senha?
            </s.Text>
          </s.TitleWrapper>
          <s.PasswordWrapper>
            <s.AlignStart>
              <s.Text
                margin="8px 0 0 0"
                fontWeight={600}
                type="heading4"
                bold
                color="graytheme6"
              >
                Link enviado por email
              </s.Text>
            </s.AlignStart>

            <s.Text margin="16px 0 16px 0" type="paragraph" color="graytheme6">
              Um link de acesso foi enviado para o seu e-mail. Caso não
              encontre, confira sua caixa de spam ou envie um novo link.
            </s.Text>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                data-testid="email-label"
                color="graytheme6"
              >
                E-mail
              </s.Text>
              <s.InputText
                testId={`email-test`}
                width={304}
                type="email"
                name="email"
                disabled
                value={email}
                onChange={(e) => {}}
              />
            </s.SectionLine>
            <RenderResendButton
              useAsResetPassword={true}
              countdownIntervalRefState={countdownIntervalRef}
              countdownState={[countdown, setCountdown]}
              resendIsLoadingState={[resendIsLoading, setResendIsLoading]}
              resendSentState={[resendSent, setResendSent]}
              email={email}
              resendCode={resendCode}
              resendResetPasswordEmail={resendResetPasswordEmail}
            />
          </s.PasswordWrapper>
          <s.ButtonWrapper>
            <s.Button
              disabled={countdown > 0}
              onClick={(e) => {
                e.preventDefault()
                setResetPasswordStep({
                  ...INITIAL_RESET_PASSWORD_STATE,
                  sendEmail: true,
                })
              }}
              data-testid="button-entrar-1"
              color="whitedetailed"
              width="100%"
            >
              <s.Icon width={20} name="arrowleft" />
              <s.Text
                margin="0 0 0 8px"
                type="headline"
                fontWeight={600}
                color="graytheme6"
              >
                Voltar
              </s.Text>
            </s.Button>
          </s.ButtonWrapper>
        </s.CardWrapper>
      )
    }

    if (resetPasswordStep.loading) {
      return (
        <s.CardWrapper data-testid="loading-reset-password">
          <s.TitleWrapper>
            <s.Text
              margin="24px 0 32px 8px"
              textAlign="left"
              color="graytheme6"
              bold
              type="headline"
            >
              Alterar senha
            </s.Text>
          </s.TitleWrapper>
          <s.PasswordWrapper>
            <s.Loading width={90} type="bigspinner" />
          </s.PasswordWrapper>
          <s.ButtonWrapper></s.ButtonWrapper>
        </s.CardWrapper>
      )
    }

    return (
      <s.CardWrapper>
        <s.TitleWrapper>
          <s.Text
            margin="24px 0 32px 8px"
            textAlign="left"
            bold
            type="headline"
          >
            Acesso ao painel do cliente
          </s.Text>
        </s.TitleWrapper>
        <s.PasswordWrapper>
          {isLoading ? (
            <s.Loading width={90} type="bigspinner" />
          ) : (
            <>
              {infoError.title ? <InfoBox error={infoError} /> : <></>}
              <InfoBox />
              <s.SectionLine>
                <s.Text
                  fontWeight={600}
                  type="headline"
                  data-testid="email-label"
                  color={inputError.email ? 'redshipay' : 'graytheme6'}
                >
                  E-mail
                </s.Text>
                <s.InputText
                  testId={`email-test`}
                  width={304}
                  type="email"
                  name="email"
                  disabled
                  value={email}
                  onChange={(e) => {}}
                />
              </s.SectionLine>
              <s.SectionLine>
                <s.Text
                  fontWeight={600}
                  type="headline"
                  data-testid="password-label"
                  color={inputError.password ? 'redshipay' : 'graytheme6'}
                >
                  Senha
                </s.Text>
                <s.InputTextPassword
                  height={40}
                  width={304}
                  testId="input-password"
                  value={password}
                  error={!!inputError.password}
                  onChange={(e) => {
                    setInputError({ ...inputError, password: false })
                    setPassword(e.target.value)
                  }}
                  onKeyDown={handleKeyDown}
                />
              </s.SectionLine>

              <s.ButtonLinkWrapper>
                <s.ButtonLink
                  data-testid="forgot-password"
                  onClick={(e) => {
                    e.preventDefault()
                    setResetPasswordStep({
                      ...resetPasswordStep,
                      sendEmail: true,
                    })
                  }}
                >
                  <s.Text type="headline" color="maincolor" fontWeight={500}>
                    Esqueceu a senha?
                  </s.Text>
                </s.ButtonLink>
              </s.ButtonLinkWrapper>

              <s.SectionLine>
                <s.Text
                  fontWeight={600}
                  type="headline"
                  data-testid="access-code-label"
                  color={inputError.accessCode ? 'redshipay' : 'graytheme6'}
                >
                  Código de acesso
                </s.Text>
                <s.InputText
                  height={40}
                  width={304}
                  maxLength={6}
                  testId="input-accesscode"
                  value={accessCode}
                  placeholder="XXXXXX"
                  error={!!inputError.accessCode}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    setInputError({ ...inputError, accessCode: false })
                    setAccessCode(applyNumberOnlyMask(e.target.value))
                  }}
                />
              </s.SectionLine>
              <RenderResendButton
                countdownIntervalRefState={countdownIntervalRef}
                countdownState={[countdown, setCountdown]}
                resendIsLoadingState={[resendIsLoading, setResendIsLoading]}
                resendSentState={[resendSent, setResendSent]}
                email={email}
                resendCode={resendCode}
              />
            </>
          )}
        </s.PasswordWrapper>
        <s.ButtonWrapper>
          {isLoading ? (
            <></>
          ) : (
            <>
              <s.Button
                onClick={(e) => {
                  setPassword('')
                  setAccessCode('')
                  setEmail('')
                  setFlippedCard(false)
                }}
                data-testid="button-voltar-1"
                color="whitedetailed"
                width="48%"
              >
                <s.Icon name="arrowleft" fill="maincolor" />
                <s.Text margin="0 0 0 8px" fontWeight={600} color="graytheme6">
                  Voltar
                </s.Text>
              </s.Button>
              <s.Button
                onClick={(e) => {
                  e.preventDefault()
                  if (isFormValid()) {
                    postAuth({
                      auth_token: +accessCode,
                      password: password,
                      username: email,
                    })
                  }
                }}
                data-testid="button-entrar-1"
                color="primary"
                width="48%"
              >
                <s.Text margin="0 8px 0 0" fontWeight={600} color="white">
                  Continuar
                </s.Text>
                <s.Icon name="arrowright" fill="whiteshipay" />
              </s.Button>
            </>
          )}
        </s.ButtonWrapper>
      </s.CardWrapper>
    )
  }

  return handleContent()
}

export default Password
