import React, { useEffect, useState } from 'react'

import * as s from './styles'
import dict from 'components/IconExporter'
import { useGetUsers, useDelUser, usePatchUser, usePostUser } from 'hooks/user'

import { useGetRoles } from 'hooks/role'
import { showToast } from 'components/Toast'
import { useNonInitialEffect } from 'hooks/utils'
import UserForm from './UserForm'

interface IProps {
  level?: string
  targetId?: string
}

const UserTable: React.FC<IProps> = ({ level, targetId }) => {
  const { users, loadUsers, getUsersIsLoading, cleanUpUserList } = useGetUsers()
  const { delResponse, delError, deleteUser } = useDelUser()
  const { patchResponse } = usePatchUser()
  const { postResponse } = usePostUser()
  const [offset, setOffset] = useState('0')
  const [userFormModal, setUserFormModal] = useState(false)
  const [formMode, setFormMode] = useState('')
  const { roles, loadRoles } = useGetRoles()
  const [userDeleteConfirmationModal, setUserDeleteConfirmationModal] =
    useState(false)
  const [userSelected, setUserSelected] = useState('')

  useEffect(() => {
    if (level && targetId) {
      loadUsers(level, targetId, {
        limit: '50',
        offset: offset,
      })
    }

    return () => {
      cleanUpUserList()
    }
  }, [offset, delResponse, patchResponse, postResponse, targetId])

  useEffect(() => {
    if (level === 'retail-chain') loadRoles('RetailChain')
    if (level === 'customer') loadRoles('Customer')
  }, [])

  useNonInitialEffect(() => {
    if (delResponse.code !== -1) {
      showToast({
        type: 'success',
        message: 'Usuário deletado com sucesso',
      })
    }
  }, [delResponse])

  useNonInitialEffect(() => {
    if (delError) {
      showToast({
        type: 'error',
        message: 'Problema ao criar usuário',
      })
    }
  }, [delError])

  const handleClose = () => {
    setUserDeleteConfirmationModal(false)
    setUserSelected('')
  }

  const renderUserDeleteConfirmationModal = () => {
    return (
      <s.Modal
        placeModalOnCenter
        isOpen={userDeleteConfirmationModal}
        handleClose={() => {
          handleClose()
        }}
      >
        <s.Text type="headline">
          Você tem certeza que deseja deletar permanentemente esse usuário?
        </s.Text>
        <s.ButtonWrapper>
          <s.Button
            onClick={() => {
              handleClose()
            }}
            color="white"
            outline
          >
            Voltar
          </s.Button>
          <s.Button
            onClick={() => {
              deleteUser(userSelected)
              handleClose()
            }}
          >
            Confirmar
          </s.Button>
        </s.ButtonWrapper>
      </s.Modal>
    )
  }

  const renderUserFormModal = () => {
    return (
      <UserForm
        isOpen={userFormModal}
        mode={formMode}
        roles={roles}
        targetId={targetId}
        level={level}
        handleClose={() => {
          setUserFormModal(false)
          setFormMode('')
        }}
      />
    )
  }

  const deleteButton = () => {
    return (
      <s.Button color="graytheme3">
        <s.Text bold color="whiteshipay">
          Deletar
        </s.Text>
      </s.Button>
    )
  }

  const editButton = () => {
    return (
      <s.Button color="maincolor">
        <s.Icon name="personify" color="white" />
        <s.Text bold color="whiteshipay">
          Editar
        </s.Text>
      </s.Button>
    )
  }

  const createButton = () => {
    return (
      <s.CreateButton>
        <s.Button
          color="maincolor"
          onClick={() => {
            setFormMode('create')
            setUserFormModal(true)
          }}
        >
          <s.Icon name="personify" color="white" />
          <s.Text bold color="whiteshipay">
            Novo Usuário
          </s.Text>
        </s.Button>
      </s.CreateButton>
    )
  }

  const idRender = ({ children }) => {
    return (
      <s.IdContainer
        onClick={(e) => {
          e.stopPropagation()
          navigator.clipboard.writeText(children as string)
          showToast({ type: 'success', message: 'ID Copiado!' })
        }}
      >
        <s.IdWrapper>{children}</s.IdWrapper>
        <s.Icon width={24} height={24} fill="maincolor" name="clipboard" />
      </s.IdContainer>
    )
  }

  const renderUsersTable = () => {
    let showExternalCrmId = false
    if (users.data.length > 0) {
      showExternalCrmId = Object.hasOwn(users.data[0], 'external_crm_id')
    }

    const userColumn = [
      { headerName: 'Nome', objectName: 'first_name' },
      { headerName: 'Sobrenome', objectName: 'last_name' },
      {
        headerName: 'ID',
        objectName: 'id',
        AltRender: idRender,
      },
      { headerName: 'E-mail', objectName: 'email' },
      { headerName: 'Telefone', objectName: 'phone' },
      { headerName: 'Role', objectName: 'role' },
    ]

    if (showExternalCrmId) {
      userColumn.push({
        headerName: 'ID do CRM',
        objectName: 'external_crm_id',
        AltRender: idRender,
      })
    }

    return (
      <>
        <s.Table
          data={users.data}
          isLoading={getUsersIsLoading}
          columns={userColumn}
          actionButtonsConfig={[
            {
              onClick: (value) => {
                setFormMode(value)
                setUserFormModal(true)
              },
              iconName: 'person' as keyof typeof dict,
              ButtonRender: editButton,
              actionName: 'Editar',
            },
            {
              onClick: (value) => {
                setUserSelected(value)
                setUserDeleteConfirmationModal(true)
              },
              iconName: 'person' as keyof typeof dict,
              ButtonRender: deleteButton,
              actionName: 'Deletar',
            },
          ]}
        />
        {users.total > 0 ? (
          <s.Pagination
            count={50}
            offset="0"
            total={users.total}
            handleChange={(offset) => {
              setOffset(offset)
            }}
          />
        ) : null}
      </>
    )
  }

  return (
    <>
      {renderUserDeleteConfirmationModal()}
      {renderUserFormModal()}
      {createButton()}
      {renderUsersTable()}
    </>
  )
}

export default UserTable
