import { useState } from 'react'
import { get, baseURLPaymentV1 } from '../services/api'
import { showToast } from 'components/Toast'

interface PayloadCashoutPayment {
  external_id: string
	payment_id: string
	status: string
	created_at: string
	updated_at: string
	amount: number
	payment_date: string
	psp_payment_id: string
	payment_type: string
	message: string
  system_wallet_name: string
}

interface UseGetCashoutPayment {
  cashoutPayment: PayloadCashoutPayment
  cashoutPaymentIsLoading: boolean
  cashoutPaymentError: boolean
  getCashoutPayment: (id: string) => Promise<void>
}

const useGetCashoutPayment = (): UseGetCashoutPayment => {
  const [cashoutPayment, setCashoutPayment] = useState<PayloadCashoutPayment>({
    external_id : '',
    payment_id: '',
    status: '',
    created_at: '',
    updated_at: '',
    amount: -1,
    payment_date: '',
    psp_payment_id: '',
    payment_type: '',
    message: '',
    system_wallet_name: '',
  })

  const [cashoutPaymentIsLoading, setCashoutPaymentIsLoading] = useState<boolean>(false)
  const [cashoutPaymentError, setCashoutPaymentError] = useState<boolean>(false)
  const getCashoutPayment = async (id: string) => {
    setCashoutPaymentIsLoading(true)
    await get(`${baseURLPaymentV1}/${id}`)
      .then((response) => {
        setCashoutPayment(response.data)
      })
      .catch((e) => {
        showToast({
          type: 'error',
          message: JSON.parse(e.request.response)?.message,
        })
        setCashoutPaymentError(true)
      })
      .finally(() => {
        setCashoutPaymentIsLoading(false)
      })
  }
  return { cashoutPayment, cashoutPaymentIsLoading, cashoutPaymentError, getCashoutPayment }
}

export default useGetCashoutPayment