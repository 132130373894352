export const Types = {
  GET_GEO_ADDRESS: 'GET_GEO_ADDRESS',
  GET_GEO_ADDRESS_LOADING: 'GET_GEO_ADDRESS_LOADING',
  GET_GEO_ADDRESS_ERROR: 'GET_GEO_ADDRESS_ERROR',
  RESET_GEO_ADDRESS: 'RESET_GEO_ADDRESS',
}

export interface PayloadInterface {
  city: string
  neighborhood: string
  postal_code: string
  state: string
  street: string
}

export interface ActionInterface {
  type: keyof typeof Types
  payload: PayloadInterface
}

export interface GeoAddressState {
  geoAddress: PayloadInterface
  isLoading: boolean
  hasError: boolean
}
