import styled from 'styled-components'

import TextCmp from 'components/Text'
import ButtonCmp from 'components/Button'
import LoadingCmp from 'components/Loading'

export const Button = styled(ButtonCmp)``

export const ButtonGroup = styled.div`
  text-align: right;
  margin-top: 20px;
  button {
    :first-child:not(:only-of-type) {
      margin-right: 40px;
    }
  }
`
export const Loading = styled(LoadingCmp)``

export const Text = styled(TextCmp)``
