import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'store/modules/rootState'

import {
  PostRetailChain,
  PostRetailChainError,
  PostRetailChainIsLoading,
} from 'store/modules/retailChain/action'

import { RetailChainState } from 'store/modules/retailChain/types'

import retailChainService, { RetailChainInterface } from 'services/retailChain'

import { Partial } from 'helpers/partial'

interface UsePostRetailChain extends RetailChainState {
  createRetailChain: (retailChain: RetailChainInterface) => Promise<void>,
}

export const usePostRetailChain = (): Partial<UsePostRetailChain> => {
  const dispatch = useDispatch()
  const { postResponse, postIsLoading, postError } = useTypedSelector((store) => ({
    postResponse: store.retailChain.postResponse,
    postIsLoading: store.retailChain.postIsLoading,
    postError: store.retailChain.postError,
  }))

  const createRetailChain = useCallback(async (retailChain): Promise<void> => {
    dispatch(PostRetailChainIsLoading())
    await retailChainService.createRetailChain(retailChain)
      .then((response) => {
        dispatch(PostRetailChain(response.data))
      })
      .catch(() => {
        dispatch(PostRetailChainError())
      })
  }, [dispatch])

  return { postResponse, postError, postIsLoading, createRetailChain}
}
