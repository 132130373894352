import React from 'react'

import { baseURLStorePos, post } from 'services/api'

import useStorePos from 'hooks/useStorePos'

import { showToast } from 'components/Toast'

import * as s from './styles'

interface IProps {
  isOpen: boolean
  handleClose: () => any
  storePosTypes: { id: number; text: string }[]
  storeId: string
  offset: string
  searchText: string
}

const CreateStorePosModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  storePosTypes,
  storeId,
  offset,
  searchText,
}) => {
  const [selected, setSelected] = React.useState({ id: '0', name: '' })
  const [options, setOptions] = React.useState([])
  const [name, setName] = React.useState('')
  const [inputPressedValidator, setInputPressedValidator] = React.useState({
    name: false,
  })
  const [isLoadingPost, setIsLoadingPost] = React.useState(false)
  const { loadStorePosPerStoreId } = useStorePos()
  const postStorePos = async (data: {
    active: boolean
    category: string
    name: string
    store_id: string
  }) => {
    setIsLoadingPost(true)
    await post(baseURLStorePos, data)
      .then(() => {
        showToast({
          type: 'success',
          message: 'Caixa criado com sucesso',
        })
        setIsLoadingPost(false)
        loadStorePosPerStoreId(storeId, {
          limit: 10,
          offset: offset,
          column_name: 'store_pos_name',
          filterby: searchText,
        })
        handleClose()
      })
      .catch((e) => {
        setIsLoadingPost(false)
        showToast({
          type: 'error',
          message: `${JSON.parse(e.request.response)}`,
        })
      })
  }

  React.useEffect(() => {
    if (storePosTypes.length > 0) {
      let auxOptions = storePosTypes.map((type, index) => {
        return {
          id: index,
          text: type.text,
          onClick: () => {
            setSelected({
              id: `${index}`,
              name: type.text,
            })
          },
        }
      })
      setOptions(auxOptions)
    }
  }, [storePosTypes])

  return (
    <s.Modal
      placeModalOnCenter
      noChildrenPadding
      modalSubtitle="Criar caixa"
      handleClose={handleClose}
      isOpen={isOpen}
    >
      <s.ModalContainer>
        <s.InputText
          label="Nome"
          maxLength={34}
          value={name}
          onChange={(e) => {
            setName(e.target.value)
            setInputPressedValidator({
              name: true,
            })
          }}
          error={
            name === '' && inputPressedValidator.name
              ? {
                  message: 'Campo Obrigatório',
                }
              : null
          }
        />
        <s.Select
          label="Categoria"
          optionBoxSize={200}
          selected={selected}
          options={options}
        />

        <s.ButtonModalContainer>
          <s.Button
            color="white"
            outline
            onClick={() => {
              handleClose()
            }}
          >
            Cancelar
          </s.Button>
          <s.Button
            color="maincolor"
            onClick={() => {
              postStorePos({
                active: true,
                category: selected.name,
                name: name,
                store_id: storeId,
              })
            }}
            disabled={name === '' || selected.name === ''}
          >
            Criar
          </s.Button>
        </s.ButtonModalContainer>
        {isLoadingPost ? <s.Loading type="spinner" color="maincolor" /> : null}
      </s.ModalContainer>
    </s.Modal>
  )
}

export default CreateStorePosModal
