import { useState, useEffect } from 'react'

import { usePatchRetailChain } from 'hooks/retailChain'
import { useNonInitialEffect } from 'hooks/utils'

import { isNotEmpty, isValidEmail } from 'helpers/validators'
import { isConexaoItau } from 'helpers'

import Modal from 'components/Modal'
import { showToast } from 'components/Toast'

import * as s from './styles'

interface IProps {
  isOpen: boolean
  handleClose: () => any
  retailChainInfo?: any
}

const EditRetailChain: React.FC<IProps> = ({
  isOpen,
  handleClose,
  retailChainInfo,
}) => {
  const level = 'retail-chain'
  const { patchResponse, patchIsLoading, patchError, editRetailChain } =
    usePatchRetailChain()
  const [form, setForm] = useState<{
    name: string
    email: string
    external_crm_id?: string
  }>({
    name: '',
    email: '',
  })
  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
  })

  const _setFormData = (obj) => {
    setForm({ ...form, ...obj })
    _validateForm(obj)
  }

  const _validateForm = (obj) => {
    const { name, email } = obj
    const errObj = {}
    if (name !== undefined) {
      const err = isNotEmpty(name) ? '' : 'Campo Obrigatório'
      errObj['name'] = err
    }
    if (email !== undefined) {
      const err = isValidEmail(email) ? '' : 'Email Inválido'
      errObj['email'] = err
    }
    setFormErrors({ ...formErrors, ...errObj })

    if (!Object.values(errObj)) {
      return false
    }
    return true
  }

  const _clearForm = () => {
    _setFormData({
      name: '',
      email: '',
    })
    setFormErrors({
      name: '',
      email: '',
    })
  }

  const onEditRetailChain = () => {
    if (!_validateForm(form)) {
      showToast({
        type: 'error',
        message: 'Por favor preencha o formulário corretamente',
      })
      return
    }

    editRetailChain(retailChainInfo.retail_chain_id, form)
  }

  const _handleClose = () => {
    _clearForm()
    handleClose()
  }

  useEffect(() => {
    if (retailChainInfo) {
      const newForm: { name: string; email: string; external_crm_id?: string } =
        {
          name: retailChainInfo.retail_chain_name || '',
          email: retailChainInfo.retail_chain_email || '',
        }

      if (Object.hasOwn(retailChainInfo, 'external_crm_id')) {
        newForm.external_crm_id = retailChainInfo.external_crm_id || ''
      }
      setForm({
        ...newForm,
      })
    }
  }, [retailChainInfo])

  useNonInitialEffect(() => {
    if (patchResponse.code !== -1) {
      showToast({
        type: 'success',
        message: `${
          isConexaoItau() ? 'Grupo editado' : 'Rede editada'
        } com sucesso`,
      })
    }
  }, [patchResponse])

  useNonInitialEffect(() => {
    if (patchError) {
      showToast({
        type: 'error',
        message: `Problema ao editar ${isConexaoItau() ? 'grupo' : 'rede'}`,
      })
    }
  }, [patchError])

  const renderForm = () => {
    return (
      <>
        <s.InputGroup>
          <s.InputText
            maxLength={100}
            label={isConexaoItau() ? 'Nome do Grupo' : 'Nome da Rede'}
            value={form.name}
            onChange={(e) => _setFormData({ name: e.target.value })}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputText
            maxLength={100}
            label="E-mail"
            value={form.email}
            onChange={(e) => _setFormData({ email: e.target.value })}
          />
        </s.InputGroup>
        {Object.hasOwn(form, 'external_crm_id') ? (
          <s.InputGroup>
            <s.InputText
              maxLength={100}
              label="ID do CRM"
              value={form.external_crm_id}
              onChange={(e) =>
                _setFormData({ external_crm_id: e.target.value })
              }
            />
          </s.InputGroup>
        ) : (
          <></>
        )}
        <s.ButtonGroup>
          <s.Button
            data-testid="edit-retail-chain-button"
            onClick={() => onEditRetailChain()}
          >
            Editar
            {patchIsLoading ? <s.Loading type="spinner" color="white" /> : null}
          </s.Button>
        </s.ButtonGroup>
      </>
    )
  }
  return (
    <s.Modal
      handleClose={_handleClose}
      isOpen={isOpen}
      modalSubtitle={isConexaoItau() ? 'Editar Grupo' : 'Editar Rede'}
    >
      <s.ModalContent>
        <s.Card>{renderForm()}</s.Card>
      </s.ModalContent>
      <s.UserTable
        level={level}
        targetId={retailChainInfo.retail_chain_id || ''}
      ></s.UserTable>
    </s.Modal>
  )
}

export default EditRetailChain
