import { baseURLReconciliation, patch, post } from 'services/api'

export async function solveElement(element: any, walletSettingId: string) {
  await patch(
    `${baseURLReconciliation}/receipt/${element.id}?wallet_setting_id=${walletSettingId}`,
    {
      solved: !element.solved,
      origin: element.origin,
    }
  ).then((res) => {})
}
