import React from 'react'

import SummaryCashin from './SummaryCashin'
import SummaryCashout from './SummaryCashout'
import * as s from './styles'
import customerService from 'services/customer'
import { checkUserClaim } from 'helpers/claims'
import { getValueFromLocalStorage } from 'helpers/localStorage'
import { UserSessionInfo } from 'store/modules/auth/types'

interface IProps {
  currentUserSession?: UserSessionInfo,
}

const Summary: React.FC<IProps> = ({
  currentUserSession = JSON.parse(getValueFromLocalStorage('currentUserSession')),
}) => {
  const [summaryType, setSummaryType] = React.useState<string>('cashin')
  const [customerName, setCustomerName] = React.useState('')
  const [customerIsLoading, setCustomerIsLoading] = React.useState(false)

  React.useEffect(() => {
    setCustomerIsLoading(true)
    customerService
      .getCurrentCustomer()
      .then((currentCustomerRes) => {
        setCustomerName(currentCustomerRes.data.name)
      })
      .finally(() => {
        setCustomerIsLoading(false)
      })
  }, [])

  return (
    <s.Container>
      {checkUserClaim(currentUserSession?.user_claims, 'payments_show_customer_name') ? (
        <s.Text
          data-testid="payments-customer-name"
          type="heading3"
          bold
          color="graytheme6"
        >
          {customerIsLoading ? 'Carregando...' : customerName}
        </s.Text>
      ) : (
        <></>
      )}
      <s.Submenu>
        <div>
          <s.SubmenuItem
            data-testid="cashin-submenu"
            onClick={() => {
              setSummaryType('cashin')
            }}
          >
            <s.Icon name="cashin" fill="maincolor" />
            <s.Text
              color={summaryType === 'cashin' ? 'maincolor' : 'graytheme8'}
            >
              Cobrança
            </s.Text>
          </s.SubmenuItem>
          {summaryType === 'cashin' ? <s.SubmenuItemSelected /> : null}
        </div>
        <div>
          <s.SubmenuItem
            data-testid="cashout-submenu"
            onClick={() => {
              setSummaryType('cashout')
            }}
          >
            <s.Icon name="cashout" fill="maincolor" />
            <s.Text
              color={summaryType === 'cashout' ? 'maincolor' : 'graytheme8'}
            >
              Envios
            </s.Text>
          </s.SubmenuItem>
          {summaryType === 'cashout' ? <s.SubmenuItemSelected /> : null}
        </div>
      </s.Submenu>
      {summaryType === 'cashin' ? <SummaryCashin /> : <SummaryCashout />}
    </s.Container>
  )
}

export default Summary
