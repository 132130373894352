import React from 'react'

import * as s from './styles'

const PixAnyBankErrorMessageModal: React.FC<{
  isOpen: boolean
  handleClose: () => void
  message: string
}> = ({ isOpen, handleClose, message }) => {
  return (
    <s.Modal isOpen={isOpen} handleClose={() => handleClose()}>
      <s.Container>
        <s.Text type="headline">{JSON.stringify(message)}</s.Text>
      </s.Container>
    </s.Modal>
  )
}

export default PixAnyBankErrorMessageModal
