import { useEffect, useState } from 'react'
import * as s from './styles'
import { applyCnpjMask } from 'helpers/masks'
import { isNotEmpty, isValidCnpj } from 'helpers/validators'
import pixWSService, { PixWSInterface } from 'services/pixWalletSettings'
import {
  INFO_INITIAL_STATE,
  Info,
  STEP_INITIAL_STATE,
} from './WalletSettingsFormTbanksCashout'
import customerService from 'services/customer'
import { useNonInitialEffect, useSSE } from 'hooks/utils'
import Toast, { showToast } from 'components/Toast'
import { usePostPixWS } from 'hooks/pixWS'
import Loading from './Loading'

interface OnRegisterStepProps {
  info: Info
  setInfo: (info: Info) => void
  setStep: (step) => void
  closeModal: () => void
}

export const OnRegisterStep: React.FC<OnRegisterStepProps> = ({
  info,
  setInfo,
  setStep,
  closeModal,
}) => {
  delete INFO_INITIAL_STATE.cpf
  const [infoAccountFormError, setInfoAccountFormError] = useState(
    INFO_INITIAL_STATE
  )
  const [customerUUID, setCustomerUUID] = useState<string>('')
  const { openConnection, notifications } = useSSE()
  const { postError } = usePostPixWS()

  const [onLoading, setOnLoading] = useState(false)

  useEffect(() => {
    if (notifications) {
      const isSuccessful = notifications[customerUUID].message
        .toLowerCase()
        .includes('sucesso')
      if (!isSuccessful) {
        setOnLoading(false)
        setStep({
          ...STEP_INITIAL_STATE,
          apiError: true,
        })
      } else {
        setOnLoading(false)
        setStep({
          ...STEP_INITIAL_STATE,
          legalRepresentantConfirmation: true,
        })
      }
    }
  }, [notifications])

  useNonInitialEffect(() => {
    if (postError) {
      showToast({
        type: 'error',
        message: 'Problema ao criar carteira',
      })
      closeModal()
    }
  }, [postError])

  const handlePost = () => {
    const err = { ...infoAccountFormError }
    for (const [key, value] of Object.entries(info)) {
      if (!err[key] && key !== 'document_number')
        err[key] = isNotEmpty(value) ? '' : 'Campo Obrigatório'
      if (key === 'cpf') err[key] = ''
      if (key === 'document_number')
        err[key] = isNotEmpty(value)
          ? isValidCnpj(value)
            ? ''
            : 'CNPJ Inválido'
          : 'Campo Obrigatório'
    }
    setInfoAccountFormError(err)

    const errList = Object.values(err).filter((x) => x !== '')
    if (!errList || errList.length === 0) {
      const payload = {
        name: info.name,
        wallet_name: 'pix',
        psp_provider: 'tbanks',
        transaction_type: 'cashout',
        client_id: info.client_id,
        client_secret: info.client_secret,
        document_number: info.document_number.replace(/[./-]+/g, ''),
        account_id: info.account_id,
        active: true,
        default: false,
      } as Partial<PixWSInterface>

      createPixCashout(payload)
    }
  }

  const createPixCashout = async (payload: Partial<PixWSInterface>) => {
    setOnLoading(true)
    await pixWSService
      .createPixWS(payload)
      .then((res) => {
        customerService.getCurrentCustomer().then((currentCustomerRes) => {
          setCustomerUUID(currentCustomerRes.data.uuid)
          openConnection(currentCustomerRes.data.uuid)
        })
      })
      .catch((error) => {
        const response = JSON.parse(error.request.response)
        showToast({
          type: 'error',
          message: `${response.message}`,
        })
        setOnLoading(false)
        closeModal()
      })
  }

  const handleCNPJError = () => {
    return !infoAccountFormError.document_number ||
      (info.document_number != '' && isValidCnpj(info.document_number))
      ? false
      : { message: infoAccountFormError.document_number }
  }

  return (
    <s.Content height={761} width={304} data-testid="content-on-register">
      {onLoading ? (
        <Loading />
      ) : (
        <>
          <s.WarningCard>
            <s.Text color="graytheme6" margin="16px">
              Esta conta faz apenas envios, se deseja receber pagamentos
              cadastre uma conta tipo cobrança.
            </s.Text>
          </s.WarningCard>
          <s.InputsWrapper>
            <s.InputText
                label="Client ID"
                width="304"
                error={
                  !infoAccountFormError.client_id || info.client_id != ''
                      ? false
                      : {message: infoAccountFormError.client_id}
                }
                value={info.client_id}
                onChange={(e) => {
                  setInfo({
                    ...info,
                    client_id: e.target.value,
                  })
                }}
            />
            <s.InputText
                label="Client Secret"
                width="304"
                error={
                  !infoAccountFormError.client_secret || info.client_secret != ''
                      ? false
                      : {message: infoAccountFormError.client_secret}
                }
                value={info.client_secret}
                onChange={(e) => {
                  setInfo({
                    ...info,
                    client_secret: e.target.value,
                  })
                }}
            />
            <s.InputText
                label="CNPJ da conta bancária"
                width="304"
                maxLength={18}
                value={applyCnpjMask(info.document_number)}
                error={handleCNPJError()}
                onChange={(e) => {
                  setInfo({
                    ...info,
                    document_number: e.target.value,
                  })
                }}
            />
            <s.Text
                type="tag"
                fontWeight={600}
                margin={
                  handleCNPJError() === false ? '-28px 0 0 0' : '-16px 0 0 0'
                }
                color="graytheme6"
            >
              Somente um usuário de um representante legal deste CNPJ pode
              finalizar a habilitação desta conta.
            </s.Text>
            <s.InputText
                label="Apelido da conta Pix"
                width="304"
                value={info.name}
                error={
                  !infoAccountFormError.name || info.name != ''
                      ? false
                      : {message: infoAccountFormError.name}
                }
                onChange={(e) => {
                  setInfo({
                    ...info,
                    name: e.target.value,
                  })
                }}
            />
            <s.InputText
                label="Account ID"
                width="304"
                value={info.account_id}
                error={
                  !infoAccountFormError.account_id || info.account_id != ''
                      ? false
                      : {message: infoAccountFormError.account_id}
                }
                onChange={(e) => {
                  setInfo({
                    ...info,
                    account_id: e.target.value,
                  })
                }}
            />
            <s.ButtonWrapper data-testid="button-on-register">
              <s.Button
                  data-testid="register-button"
                  onClick={() => {
                    handlePost()
                  }}
                  height="42px"
                  color="maincolor"
              >
                <s.Icon name="pixiconoutline" fill="white"/>
                <s.Text type="headline" color="white" bold>
                  Cadastrar
                </s.Text>
              </s.Button>
              <s.Button
                  height="42px"
                  onClick={() => closeModal()}
                  color="white"
              >
                <s.Text type="headline" color="maincolor" bold>
                  Cancelar
                </s.Text>
              </s.Button>
            </s.ButtonWrapper>
          </s.InputsWrapper>
        </>
      )}
    </s.Content>
  )
}
