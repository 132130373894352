import React from 'react'

import useWalletsSettings from 'hooks/useWalletsSettings'
import { useCrypto } from 'hooks/useCrypto'

import { showToast } from 'components/Toast'

import * as s from '../styles'

const CoinPaymentsConfigIPNWallet: React.FC<{
  handleClose: () => void
  isModalOpen: boolean
  ipn: string
}> = ({ handleClose, isModalOpen, ipn }) => {
  const { loadWalletsSettings } = useWalletsSettings()
  const { patchCryptoValidade, isLoading, response } = useCrypto()
  const [openModal, setOpenModal] = React.useState(false)

  React.useEffect(() => {
    if (response.message) {
      loadWalletsSettings({
        limit: '10',
        offset: '0',
        filters: window.btoa(
          JSON.stringify({ wallet_type: ['c-wallet', 'e-wallet'] })
        ),
      })
      setOpenModal(true)
    }
  }, [response])

  const closeAllModals = () => {
    setOpenModal(false)
    handleClose()
  }

  return (
    <>
      <s.Modal
        handleClose={() => closeAllModals()}
        isOpen={openModal}
        placeModalOnCenter
        isHeaderComponent={false}
      >
        <s.MessageContainer>
          <s.Text color="graytheme6" bold type="headline" textAlign="center">
            Obrigado! Iremos verificar se as credenciais informadas nos passos
            anteriores estão corretas.
          </s.Text>
          <s.Text color="graytheme6" bold type="headline" textAlign="center">
            Se sim, dentro de alguns instantes sua conta CoinPayments estará
            pronta para ser associada à uma loja e transacionar.
          </s.Text>
          <s.Button
            color="maincolor"
            onClick={() => {
              closeAllModals()
            }}
            width="200px"
          >
            OK
          </s.Button>
        </s.MessageContainer>
      </s.Modal>
      <s.FormContainer height={450}>
        <s.Text bold>
          Para finalizar o cadastro da sua conta CoinPayments, siga os passos a
          seguir:
        </s.Text>
        <s.List>
          <s.ListItem>
            <s.Text color="maincolor" bold>
              1.
            </s.Text>
            <s.Text color="graytheme6">Copie o "Segredo do IPN" abaixo</s.Text>
          </s.ListItem>
          <s.ListItem>
            <s.Text color="maincolor" bold>
              2.
            </s.Text>
            <s.Text color="graytheme6">Acesse o portal da CoinPayments</s.Text>
          </s.ListItem>
          <s.ListItem>
            <s.Text color="maincolor" bold>
              3.
            </s.Text>
            <s.Text color="graytheme6">
              No portal da coinPayments, acesse "Conta" → "Configurações de
              Conta" → "Configurações do operador" → "Segredo do IPN"
            </s.Text>
          </s.ListItem>
          <s.ListItem>
            <s.Text color="maincolor" bold>
              4.
            </s.Text>
            <s.Text color="graytheme6">
              Insira o "Segredo do IPN" e salve as alterações
            </s.Text>
          </s.ListItem>
          <s.ListItem>
            <s.Text color="maincolor" bold>
              5.
            </s.Text>
            <s.Text color="graytheme6">
              Clique no botão abaixo: "Já configurei o segredo do IPN"
            </s.Text>
          </s.ListItem>
        </s.List>

        <s.Text bold color="maincolor" type="headline">
          Segredo do IPN
        </s.Text>
        <s.IPNContainer
          onClick={(e) => {
            e.stopPropagation()
            if (ipn) {
              navigator.clipboard.writeText(ipn)
              showToast({ type: 'success', message: 'ID Copiado!' })
            }
          }}
        >
          <s.IdWrapper>
            {ipn ? ipn : 'Carregando... Aguarde um momento'}
          </s.IdWrapper>
          {ipn ? (
            <s.Icon width={24} height={24} fill="maincolor" name="clipboard" />
          ) : (
            <s.Loading type="spinner" color="maincolor" />
          )}
        </s.IPNContainer>
        <s.FooterButtonWrapper>
          <s.Button
            disabled={!ipn || isLoading}
            color="maincolor"
            onClick={() => patchCryptoValidade(ipn)}
          >
            Já configurei o segredo do IPN
          </s.Button>
          {isLoading ? <s.Loading type="spinner" color="maincolor" /> : null}
        </s.FooterButtonWrapper>
      </s.FormContainer>
    </>
  )
}

export default CoinPaymentsConfigIPNWallet
