import { useState } from 'react'

import summaryCashoutService from 'services/summaryCashout'

interface UseGetSummaryCashout {
  loadSummaryCashout: (params) => Promise<void>
  summaryCashout: any
  isLoading: boolean
  hasError: boolean
}

const useGetSummaryCashout = (): UseGetSummaryCashout => {
  const [summaryCashout, setSummaryCashout] = useState({
    count: 0,
    data: [],
    offset: 0,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  const loadSummaryCashout = async (params) => {
    setIsLoading(true)
    await summaryCashoutService
      .getSummaryCashout(params)
      .then((res) => setSummaryCashout(res.data))
      .catch((error) => {
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  return { summaryCashout, isLoading, hasError, loadSummaryCashout }
}

export default useGetSummaryCashout
