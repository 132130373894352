import React from 'react'

import verificationFailed from 'assets/images/verification-failed.png'

import * as s from './styles'
import { baseURLVerifyUsersV1, get } from 'services/api'
import { showToast } from 'components/Toast'
import { Steps } from './WalletSettingsFormTbanksCashout'

interface IProps {
  step: Steps
  setStep: (step: Steps) => void
}

const KBAUserVerficationFailed: React.FC<IProps> = ({ step, setStep }) => {
  const restartQuestionnaire = async () => {
    setStep({ ...step, KBAIntroduction: true })
  }

  return (
    <s.Content width={520} data-testid="content-on-user-verification-failed">
      <s.HeaderStepsContainer>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="graytheme8">
            <s.NumberStep>1</s.NumberStep>
          </s.HeaderStepCircle>
          <s.Text>Introdução</s.Text>
        </s.HeaderStepWrapper>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="navbarvector1">
            <s.NumberStep>2</s.NumberStep>
          </s.HeaderStepCircle>
          <s.Text>Questionário</s.Text>
        </s.HeaderStepWrapper>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="graytheme8">
            <s.NumberStep>3</s.NumberStep>
          </s.HeaderStepCircle>
          <s.Text>Documento</s.Text>
        </s.HeaderStepWrapper>
      </s.HeaderStepsContainer>
      <s.Text margin="64px 0 16px 0" color="graytheme6" bold type="heading4">
        Não foi possível verificar sua identidade pelo questionário
      </s.Text>
      <s.Text margin="0 0 40px 0" color="graytheme6" type="headline">
        Certifique-se de que seus dados cadastrais estão corretos e tente
        novamente. Em caso de dúvidas entre em contato com o suporte.
      </s.Text>
      <img width={500} src={verificationFailed} color="graytheme6" />
      <s.ButtonWrapper margin="92px 0 0 0" data-testid="button-on-restart">
        <s.Button
          data-testid="restart-button"
          onClick={() => {
            restartQuestionnaire()
          }}
          height="42px"
          color="maincolor"
        >
          <s.Text
            margin="0 8px 0 0"
            type="headline"
            color="white"
            fontWeight={600}
          >
            Refazer questionário
          </s.Text>
        </s.Button>
      </s.ButtonWrapper>
    </s.Content>
  )
}

export default KBAUserVerficationFailed
