import React from 'react'
import { themeColors } from 'styles/theme'

import loadingbigger from 'assets/images/loading-bigger.png'
import { dict } from 'components/IconExporter'

import * as s from './styles'

interface IProps {
  color?: keyof typeof themeColors
  width?: number
  height?: number
  type?: 'spinner' | 'bar' | 'arrowspinner' | 'bigspinner'
  legend?: string
  placement?: 'top' | 'bottom'
  textAlign?: 'left' | 'center' | 'right'
  dataTestId?: 'loading'
}

const Loading = ({
  color = 'maincolor',
  width = 28,
  type = 'spinner',
  legend = '',
  textAlign = 'left',
  placement = 'top',
  ...rest
}: IProps) => {
  if (type === 'spinner' || type === 'arrowspinner') {
    return (
      <s.IconAnimated
        name={type as keyof typeof dict}
        fill={color}
        width={width}
        data-testid="loading-spinner"
        {...rest}
      />
    )
  }

  if (type === 'bigspinner') {
    return (
      <s.LoadingGradient data-testid="loading-big-spinner">
        <img width={width} src={loadingbigger} />
      </s.LoadingGradient>
    )
  }

  return (
    <s.ComponentContainer {...rest}>
      {legend && (
        <s.LegendText
          type="headline"
          color="gray4"
          placement={placement}
          textAlign={textAlign}
          data-testid="legend"
        >
          {legend}
        </s.LegendText>
      )}
      <s.LoadingContainer>
        <s.LoadingBar color={color} data-testid="loading-bar" />
      </s.LoadingContainer>
    </s.ComponentContainer>
  )
}

export default Loading
