import { useState, useEffect } from 'react'

import { isConexaoItau } from 'helpers'
import { useGetCustomer } from 'hooks/customer'

import Modal from 'components/Modal'

import * as s from './styles'
import { patch } from 'services/api'
import Toast, { showToast } from 'components/Toast'

interface IProps {
  isOpen: boolean
  handleClose: () => any
  userId: string
}

const UserDetailsModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  userId,
}) => {
  const level = 'customer'
  const { customer, loadCustomer } = useGetCustomer()
  const [editMode, setEditMode] = useState(false)
  const [externalCrmId, setExternalCrmId] = useState('')
  const [patchIsLoading, setPatchIsLoading] = useState(false)

  const showExternalCrmId =
    customer && Object.hasOwn(customer, 'external_crm_id')

  useEffect(() => {
    if (userId) {
      loadCustomer(userId)
    }
    if (customer.id) {
      setExternalCrmId(customer.external_crm_id)
    }
  }, [userId, customer.id])

  const handlePatch = async () => {
    setPatchIsLoading(true)
    await patch(`/customer/${customer.id}`, {
      external_crm_id: externalCrmId,
    })
      .then((res) => {
        showToast({
          type: 'success',
          message: 'ID Freshdesk alterado com sucesso!',
        })
      })
      .catch((e) => {
        showToast({
          type: 'error',
          message:
            JSON.parse(e.request.response)?.detail || 'Problema ao editar',
        })
      })
      .finally(() => setPatchIsLoading(false))
  }

  const renderHeader = () => {
    return (
      <s.HeaderGrid>
        <s.Card>
          <s.CustomerTextDisplayGroup>
            <s.Text bold color="maincolor" type="headline">
              Cliente:
            </s.Text>
            <s.Text type="headline">{customer.name}</s.Text>
          </s.CustomerTextDisplayGroup>
          <s.CustomerTextDisplayGroup>
            <s.Text bold color="maincolor" type="headline">
              ID:
            </s.Text>
            <s.Text type="headline">{customer.id}</s.Text>
          </s.CustomerTextDisplayGroup>
          <s.CustomerTextDisplayGroup>
            <s.Text bold color="maincolor" type="headline">
              E-mail:
            </s.Text>
            <s.Text type="headline">{customer.email}</s.Text>
          </s.CustomerTextDisplayGroup>
        </s.Card>
        <s.Card>
          <s.CustomerTextDisplayGroup>
            <s.Text bold color="maincolor" type="headline">
              PDV vendor:
            </s.Text>
            <s.Text type="headline">{customer.pos_vendor_name}</s.Text>
          </s.CustomerTextDisplayGroup>
          <s.CustomerTextDisplayGroup>
            <s.Text bold color="maincolor" type="headline">
              PDV:
            </s.Text>
            <s.Text type="headline">{customer.pos_product_name}</s.Text>
          </s.CustomerTextDisplayGroup>
          <s.CustomerTextDisplayGroup>
            <s.Text bold color="maincolor" type="headline">
              {isConexaoItau() ? 'Grupo:' : 'Rede:'}
            </s.Text>
            <s.Text type="headline">{customer.retail_chain_name}</s.Text>
          </s.CustomerTextDisplayGroup>
          {showExternalCrmId && (
            <s.CustomerTextDisplayGroup>
              <s.Text bold color="maincolor" type="headline">
                ID do CRM:
              </s.Text>
              <s.InputText
                disabled={!editMode}
                onChange={(e) => {
                  setExternalCrmId(e.target.value)
                }}
                suffix={
                  <s.EditButtonWrapper
                    data-testid="external-crm-button-test"
                    onClick={() => {
                      setEditMode(!editMode)
                      if (
                        editMode &&
                        externalCrmId !== customer.external_crm_id
                      ) {
                        handlePatch()
                      } else {
                        setEditMode(!editMode)
                      }
                    }}
                  >
                    {patchIsLoading ? (
                      <s.Loading />
                    ) : editMode ? (
                      <s.Icon name="checkmarkcirclefilled" fill="maincolor" />
                    ) : (
                      <s.Icon name="pencil" fill="maincolor" />
                    )}
                  </s.EditButtonWrapper>
                }
                value={externalCrmId}
              />
            </s.CustomerTextDisplayGroup>
          )}
        </s.Card>
      </s.HeaderGrid>
    )
  }

  return (
    <>
      <Modal
        handleClose={handleClose}
        isOpen={isOpen}
        modalSubtitle="Dados do Cliente"
      >
        <Toast />
        <s.ModalContent>{renderHeader()}</s.ModalContent>
        <s.UserTable level={level} targetId={customer.id}></s.UserTable>
      </Modal>
    </>
  )
}

export default UserDetailsModal
