import React, { SetStateAction } from 'react'
import { useTheme } from 'styled-components'

import { useNonInitialEffect } from 'hooks/utils'
import useGetStores from 'hooks/useGetStores'

import { baseURLPixAnyBankCustomerUserValidation, post } from 'services/api'

import { getBanks } from 'helpers/banks'
import { getValueFromLocalStorage } from 'helpers/localStorage'
import { applyAccountMask, formatMoney, applyCpfCnpjMask } from 'helpers/masks'

import * as s from '../styles'
import { showToast } from 'components/Toast'
import { UserSessionInfo } from 'store/modules/auth/types'

const CompanyForm: React.FC<{
  nextStep: () => void
  previousStep: () => void
  isFrom: string
  pixFormState: [any, React.Dispatch<SetStateAction<any>>]
}> = ({ nextStep, previousStep, isFrom, pixFormState }) => {
  const { stores, isLoading, loadStores } = useGetStores()
  const [form, setForm] = pixFormState
  const [selectedCnpj, setSelectedCnpj] = React.useState({ id: -1, name: '' })
  const [CNPJOptions, setCNPJOptions] = React.useState([])
  const [selectedBank, setSelectedBank] = React.useState({ id: -1, name: '' })
  const banks = getBanks()
    .sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
    .map((bank, index) => {
      return {
        id: index,
        text: `${bank.code} - ${bank.name}`,
        onClick: () => {
          setSelectedBank({ id: index, name: `${bank.code} - ${bank.name}` })
        },
      }
    })
  const [monthlyRevenue, setMonthlyRevenue] = React.useState<string>('')
  const [employeesNumber, setEmployeesNumber] = React.useState<string>('')
  const [agency, setAgency] = React.useState<string>('')
  const [account, setAccount] = React.useState<string>('')
  const [
    isValidateCustomerUserLoading,
    setIsValidateCustomerUserLoading,
  ] = React.useState(false)
  const theme = useTheme()

  const validateCustomerUser = async () => {
    setIsValidateCustomerUserLoading(true)
    const currentUserSession: UserSessionInfo = JSON.parse(getValueFromLocalStorage('currentUserSession'))

    setForm({
      ...form,
      cnpj: selectedCnpj.name,
      bank: selectedBank.name,
      monthlyRevenue,
      employeesNumber,
      agency,
      account,
    })
    await post(`${baseURLPixAnyBankCustomerUserValidation}`, {
      cnpj: selectedCnpj.name.replace(/[./-]+/g, ''),
      user_id: currentUserSession.id,
      pix_any_bank_identifier: {
        order_type: isFrom === 'original' ? 'cob' : 'cobv',
        psp_provider: isFrom,
      },
    })
      .then(() => {
        nextStep()
      })
      .catch((err) => {
        if (window['env']['name'] !== 'production') {
          nextStep()
        }

        if (
          JSON.parse(err.request.response).message ===
          'User is not the company owner'
        ) {
          showToast({
            type: 'error',
            message: `O usuário ${currentUserSession.name} não consta no QSA do CNPJ selecionado. Entre em contato com o representante do seu sistema de PDV ou com o time comercial da Shipay para mais detalhes`,
          })
        } else {
          showToast({
            type: 'error',
            message: `${JSON.parse(err.request.response).message}`,
          })
        }
      })
      .finally(() => {
        setIsValidateCustomerUserLoading(false)
      })
  }

  React.useEffect(() => {
    loadStores({ limit: '200', offset: '0', active: true })
  }, [])

  React.useEffect(() => {
    if (form.cnpj && CNPJOptions.length > 0) {
      setAccount(form.account)
      setAgency(form.agency)
      setSelectedBank({
        id: banks.find((bank) => bank.text === form.bank).id,
        name: form.bank,
      })
      setSelectedCnpj({
        id: CNPJOptions.find((cnpj) => cnpj.text === form.cnpj).id,
        name: form.cnpj,
      })
      setMonthlyRevenue(form.monthlyRevenue)
      setEmployeesNumber(form.employeesNumber)
    }
  }, [form, CNPJOptions])

  useNonInitialEffect(() => {
    setCNPJOptions([
      ...stores.data
        .filter((store) => store.cnpj_cpf.length > 11 && store.active)
        .map((store, index) => {
          return {
            id: index,
            text: applyCpfCnpjMask(store.cnpj_cpf),
            onClick: () => {
              setSelectedCnpj({
                id: index,
                name: applyCpfCnpjMask(store.cnpj_cpf),
              })
            },
          }
        }),
    ])
  }, [stores])

  return (
    <>
      <s.ModalWrapper>
        <s.HeaderWrapper>
          <s.Text bold type="headline">
            Insira os dados da conta bancária de destino e de sua empresa.
          </s.Text>
        </s.HeaderWrapper>
        <s.CompanyFormWrapper>
          <s.BankAccount>
            <s.Text bold type="headline">
              Conta bancária destino
            </s.Text>
            <s.SearchSelect
              label="Banco"
              options={banks}
              selectedState={[selectedBank, setSelectedBank]}
            />
            <s.InputText
              width="273"
              maxLength={4}
              label="Agência (sem dígito)"
              value={agency}
              onChange={(e) => {
                setAgency(e.target.value.replace(/[^0-9]/g, ''))
              }}
            />
            <s.InputText
              width="273"
              label="Conta"
              onChange={(e) => {
                setAccount(e.target.value)
              }}
              value={applyAccountMask(account)}
            />
            <s.Select
              label="CNPJ da Conta"
              isLoading={isLoading}
              options={CNPJOptions}
              selected={selectedCnpj}
            />
            <s.SubText>
              Essa conta de destino precisa ser uma conta Pessoa Jurídica de um
              CNPJ já cadastrado{' '}
              {theme.domain === 'Shipay' ? 'na Shipay' : 'no Conexão Itaú'}.
            </s.SubText>
          </s.BankAccount>

          <s.Company>
            <s.Text bold type="headline">
              Empresa
            </s.Text>
            <s.InputText
              width="273"
              label="Faturamento Mensal"
              value={monthlyRevenue}
              onChange={(e) => {
                const value = e.target.value
                setMonthlyRevenue(formatMoney(value))
              }}
            />
            <s.InputText
              width="273"
              label="Quantidade de funcionários"
              value={employeesNumber}
              onChange={(e) =>
                setEmployeesNumber(e.target.value.replace(/[^0-9]+/g, ''))
              }
            />
          </s.Company>
        </s.CompanyFormWrapper>
      </s.ModalWrapper>
      <s.Footer>
        <s.LeftButtonAnybank
          onClick={() => {
            previousStep()
          }}
          color="whitedetailed"
          width="150px"
        >
          <s.Icon name="arrowleft" fill="maincolor" />
          <s.Text bold color="graytheme6">
            Voltar
          </s.Text>
        </s.LeftButtonAnybank>
        <s.RightButtonAnybank
          disabled={
            selectedBank.name === '' ||
            selectedCnpj.name === '' ||
            monthlyRevenue === '' ||
            employeesNumber === '' ||
            agency === '' ||
            account === '' ||
            isValidateCustomerUserLoading
          }
          onClick={() => {
            validateCustomerUser()
          }}
          width="150px"
        >
          <s.Text bold color="white">
            Continuar
          </s.Text>
          <s.Icon name="arrowright" fill="white" />
          {isValidateCustomerUserLoading ? (
            <s.Loading type="spinner" color="white" />
          ) : null}
        </s.RightButtonAnybank>
      </s.Footer>
    </>
  )
}

export default CompanyForm
