import React, { useState, useEffect } from 'react'

import { useGetRetailChains, useDelRetailChain } from 'hooks/retailChain'
import useDebounce from 'hooks/useDebounce'
import { useNonInitialEffect } from 'hooks/utils'

import { isConexaoItau } from 'helpers'

import dict from 'components/IconExporter'
import PosProductService from 'services/posProduct'
import CreateRetailChain from './CreateRetailChain'
import EditRetailChain from './EditRetailChain'
import Toast, { showToast } from 'components/Toast'

import * as s from './styles'

const RetailChain: React.FC = () => {
  const { retailChains, getIsLoading, getError, loadRetailChains } =
    useGetRetailChains()
  const { delResponse, delError, deleteRetailChain } = useDelRetailChain()

  const [offset, setOffset] = useState<string>('0')
  const [selected, setSelected] = useState({
    id: 1,
    name: 'Nome',
  })
  const [orderby, setOrderby] = useState({
    field: 'retail_chain_name',
    sort: true,
  })

  const [searchText, setSearchText] = useState<string>('')
  const [filterType, setFilterType] = useState('retail_chain_name')
  const [inputDisplayText, setInputDisplayText] = useState('')
  const debouncedSearchText = useDebounce(setSearchText, 1000)

  const [createRetailChainModal, setCreateRetailChainModal] = useState(false)
  const [editRetailChainModal, setEditRetailChainModal] = useState(false)
  const [editRetailChainModalInfo, setEditRetailChainModalInfo] = useState({})
  const [posProductList, setPosProductList] = useState([])

  useNonInitialEffect(() => {
    if (Object.keys(editRetailChainModalInfo).length === 0)
      setEditRetailChainModal(false)
    else setEditRetailChainModal(true)
  }, [editRetailChainModalInfo])

  useEffect(() => {
    PosProductService.getPosProducts().then((response) => {
      setPosProductList(response.data.pos_products)
    })
  }, [])

  useNonInitialEffect(() => {
    if (getError) {
      showToast({
        type: 'error',
        message: `Problema ao carregar ${isConexaoItau() ? 'grupos' : 'redes'}`,
      })
    }
    if (delError) {
      showToast({
        type: 'error',
        message: `Problema ao deletar ${isConexaoItau() ? 'grupo' : 'rede'}`,
      })
    }
  }, [getError, delError])

  useNonInitialEffect(() => {
    if (delResponse.code !== -1) {
      showToast({
        type: 'success',
        message: `${
          isConexaoItau() ? 'Grupo deletado' : 'Rede deletada'
        } com sucesso`,
      })
    }
  }, [delResponse])

  useEffect(() => {
    if ((filterType && searchText) || offset) {
      loadRetailChains({
        limit: '50',
        offset: offset,
        orderby: orderby.sort ? 'asc' : 'desc',
        sort: orderby.field,
        filter: searchText,
        filter_type: filterType,
      })
    }
  }, [offset, searchText, filterType, orderby, delResponse])

  const renderCreateRetailChainModal = () => {
    if (createRetailChainModal) {
      return (
        <CreateRetailChain
          isOpen={createRetailChainModal}
          handleClose={() => setCreateRetailChainModal(false)}
          posProductList={posProductList}
        />
      )
    }
  }

  const renderEditRetailChainModal = () => {
    if (editRetailChainModal)
      return (
        <EditRetailChain
          isOpen={editRetailChainModal}
          handleClose={() => setEditRetailChainModalInfo({})}
          retailChainInfo={editRetailChainModalInfo}
        />
      )
  }

  const searchField = () => {
    return (
      <s.SearchInputsWrapper>
        <s.SelectWrapper>
          <s.Text color="maincolor" bold>
            Buscar por
          </s.Text>
          <s.Select
            options={[
              {
                id: 1,
                text: 'Nome',
                onClick: () => {
                  setFilterType('retail_chain_name')
                  setSelected({ id: 1, name: 'Nome' })
                },
              },
              {
                id: 2,
                text: 'E-mail',
                onClick: () => {
                  setFilterType('retail_chain_email')
                  setSelected({ id: 1, name: 'E-mail' })
                },
              },
              {
                id: 3,
                text: 'Sistema de PDV',
                onClick: () => {
                  setFilterType('pos_product_name')
                  setSelected({ id: 1, name: 'Sistema de PDV' })
                },
              },
              {
                id: 4,
                text: 'UUID',
                onClick: () => {
                  setFilterType('retail_chain_id')
                  setSelected({ id: 1, name: 'UUID' })
                },
              },
            ]}
            selected={selected}
          />
        </s.SelectWrapper>
        <s.InputText
          suffix={<s.Icon id="icon" name="search" />}
          value={inputDisplayText}
          placeholder="Pesquisar"
          onChange={(e) => {
            setInputDisplayText(e.target.value)
            debouncedSearchText(e.target.value)
          }}
        />
      </s.SearchInputsWrapper>
    )
  }

  const deleteButton = () => {
    return (
      <s.Button color="graytheme3">
        <s.Text bold color="whiteshipay">
          Deletar
        </s.Text>
      </s.Button>
    )
  }

  const editButton = () => {
    return (
      <s.Button color="maincolor">
        <s.Icon name="personify" color="white" />
        <s.Text bold color="whiteshipay">
          Editar
        </s.Text>
      </s.Button>
    )
  }

  const newRetailChainButton = () => {
    return (
      <s.Button
        color="maincolor"
        onClick={(e) => {
          setCreateRetailChainModal(true)
        }}
      >
        <s.Text bold color="whiteshipay">
          {isConexaoItau() ? 'Novo Grupo' : 'Nova Rede'}
        </s.Text>
      </s.Button>
    )
  }

  const renderRetailChainsTable = () => {
    const columns = [
      { headerName: 'Nome', objectName: 'retail_chain_name' },
      { headerName: 'E-mail', objectName: 'retail_chain_email' },
      { headerName: 'Sistema de PDV', objectName: 'pos_product_name' },
      { headerName: 'UUID', objectName: 'retail_chain_id' },
    ]

    if (
      retailChains.data.length > 0 &&
      Object.hasOwn(retailChains.data[0], 'external_crm_id')
    ) {
      columns.push({ headerName: 'ID do CRM', objectName: 'external_crm_id' })
    }
    return (
      <>
        <s.Table
          isLoading={getIsLoading}
          columns={columns}
          onHeaderClick={(value) => {
            const tableHeaderValues = {
              Nome: 'retail_chain_name',
              'E-mail': 'retail_chain_email',
              'Sistema de PDV': 'pos_product_name',
              UUID: 'retail_chain_id',
            }

            if (tableHeaderValues[value] === orderby.field) {
              setOrderby({ ...orderby, sort: !orderby.sort })
            } else {
              setOrderby({
                field: tableHeaderValues[value],
                sort: true,
              })
            }
          }}
          actionButtonsConfig={[
            {
              onClick: (value) => {
                const selectedRetailChain = retailChains.data.find(
                  (x) => x.retail_chain_id === value
                )
                setEditRetailChainModalInfo(selectedRetailChain)
              },
              iconName: 'person' as keyof typeof dict,
              ButtonRender: editButton,
              actionName: 'Editar',
            },
            {
              onClick: (value) => {
                deleteRetailChain(value)
              },
              iconName: 'person' as keyof typeof dict,
              ButtonRender: deleteButton,
              actionName: 'Deletar',
            },
          ]}
          data={retailChains.data}
        />
        {retailChains.total > 0 ? (
          <s.Pagination
            count={50}
            offset="0"
            total={retailChains.total}
            handleChange={(offset) => {
              setOffset(offset)
            }}
          />
        ) : null}
      </>
    )
  }

  return (
    <s.Container>
      <Toast />
      {renderCreateRetailChainModal()}
      {renderEditRetailChainModal()}
      <s.UserControlsGrid>
        {searchField()}
        {newRetailChainButton()}
      </s.UserControlsGrid>
      {renderRetailChainsTable()}
    </s.Container>
  )
}

export default RetailChain
