import React from 'react'

import useWalletsSettings from 'hooks/useWalletsSettings'
import { showToast } from 'components/Toast'

import { WalletSetting } from 'store/modules/walletSettings/types'

import * as s from './styles'

interface IProps {
  data: WalletSetting
  handleClose: () => void
  offset: string
}

const EditMercadoPagoWallet: React.FC<IProps> = ({
  data,
  handleClose,
  offset,
}) => {
  const { patchIsLoading, patchWalletSettings, loadWalletsSettings } =
    useWalletsSettings()
  const [inputEditable, setInputEditable] = React.useState<any>({
    ...Object.keys(data).reduce((acc, key) => {
      acc[key] = false
      return acc
    }, {}),
  })

  const [newWallet, setNewWallet] = React.useState<WalletSetting>(data)

  return (
    <s.FormContainer>
      <s.InputText label="ID" disabled value={data.id} />
      <s.InputText
        label="Apelido"
        disabled={!inputEditable.name}
        onChange={(e) => {
          setNewWallet({ ...newWallet, name: e.target.value })
        }}
        suffix={
          <s.EditButtonWrapper
            onClick={() => {
              setInputEditable({
                ...inputEditable,
                name: !inputEditable.name,
              })
              if (inputEditable.name) {
                setNewWallet({
                  ...newWallet,
                  name: data.name,
                })
              } else {
                setNewWallet({
                  ...newWallet,
                  name: '',
                })
              }
            }}
          >
            <s.Icon name="pencil" fill="maincolor" />
          </s.EditButtonWrapper>
        }
        placeholder={data.name}
        value={newWallet.name}
      />
      <s.InputText
        label="Access Token"
        disabled={!inputEditable.access_key}
        onChange={(e) => {
          setNewWallet({ ...newWallet, access_key: e.target.value })
        }}
        suffix={
          <s.EditButtonWrapper
            onClick={() => {
              setInputEditable({
                ...inputEditable,
                access_key: !inputEditable.access_key,
              })
              if (inputEditable.access_key) {
                setNewWallet({
                  ...newWallet,
                  access_key: data.access_key,
                })
              } else {
                setNewWallet({
                  ...newWallet,
                  access_key: '',
                })
              }
            }}
          >
            <s.Icon name="pencil" fill="maincolor" />
          </s.EditButtonWrapper>
        }
        placeholder={data.access_key}
        value={newWallet.access_key}
      />
      <s.InputText label="Collector id" disabled value={data.collector_id} />
      {Object.values(inputEditable).some((input) => input === true) ? (
        <s.FooterButtonWrapper>
          <s.Button
            disabled={patchIsLoading}
            width="100px"
            color="transparent"
            outline
            onClick={() => handleClose()}
          >
            Cancelar
          </s.Button>
          <s.Button
            width="120px"
            disabled={
              patchIsLoading ||
              (newWallet.name === data.name &&
                newWallet.access_key === data.access_key &&
                newWallet.collector_id === data.collector_id)
            }
            onClick={() => {
              if (
                newWallet.name === '' ||
                newWallet.access_key === '' ||
                newWallet.collector_id === ''
              ) {
                showToast({
                  type: 'error',
                  message: 'Você não pode deixar campos em branco',
                })
              } else {
                patchWalletSettings(newWallet.id, {
                  ...Object.keys(inputEditable).reduce((acc, key) => {
                    if (inputEditable[key] === true) {
                      if (key === 'access_key') {
                        acc['collector_id'] = newWallet[key].slice(
                          newWallet[key].lastIndexOf('-') + 1
                        )
                      }
                      acc[key] = newWallet[key]
                    }
                    return acc
                  }, {}),
                }).then((status) => {
                  if (status === '200') {
                    loadWalletsSettings({
                      limit: '10',
                      offset: offset,
                      filters: window.btoa(
                        unescape(
                          encodeURIComponent(
                            JSON.stringify({
                              wallet_type: ['c-wallet', 'e-wallet'],
                            })
                          )
                        )
                      ),
                    })
                    handleClose()
                  }
                })
              }
            }}
            color="maincolor"
          >
            Salvar
          </s.Button>
          {patchIsLoading ? (
            <s.Loading type="spinner" color="maincolor" />
          ) : null}
        </s.FooterButtonWrapper>
      ) : null}
    </s.FormContainer>
  )
}

export default EditMercadoPagoWallet
