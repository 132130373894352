import styled, { css } from 'styled-components'

import TextCmp from 'components/Text'
import LoadingCmp from 'components/Loading'
import IconCmp from 'components/IconExporter'

export const Icon = styled(IconCmp)``

export const RoundedSquareWrapper = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
  width: 271px;
  height: ${({ expanded }) => (expanded ? '276px' : '155px')};
  border: 2px solid ${({ theme }) => theme.colors.maincolor};
`

export const RoundedSquareContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  gap: 16px;
`

export const SeeMoreContainer = styled.div`
  max-width: 338px;
`

export const SeeMoreButton = styled.button`
  display: inline;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.maincolor};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  :hover {
    cursor: pointer;
  }
`

interface AddCheckButtonProps {
  checked: boolean
}

export const AddCheckButton = styled.button<AddCheckButtonProps>`
  margin-top: auto;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.maincolor};
  :hover {
    cursor: pointer;
  }

  ${({ checked }) =>
    checked
      ? css`
          background: ${({ theme }) => theme.colors.lightmaincolor3};
          div {
            background: ${({ theme }) => theme.colors.maincolor};
          }
        `
      : css`
          background: inherit;
        `};
`

export const CheckSquare = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 2px solid ${({ theme }) => theme.colors.maincolor};
  svg {
    width: 10px;
    height: 10px;
  }
`

export const Text = styled(TextCmp)``

export const Loading = styled(LoadingCmp)`
  margin-left: 16px;
`
