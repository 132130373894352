import React from 'react'
import MainStatusCard from '../components/MainStatusCard'
import { formatMoney } from 'helpers/masks'
import { baseURLReconciliation, get } from 'services/api'
import ReconciliationReturnedModal from './ReconciliationReturnedModal'

interface IProps {
  options: {
    start_date: string
    end_date: string
    wallet_setting_id: string
    consolidated_customer_value: string
  }
}

interface ConsolidatedValue {
  value_difference: string
  sum_orders_value: string
  discrepancy: boolean
}

const ReconciliationReturned: React.FC<IProps> = ({ options }) => {
  const [detailsModal, setDetailsModal] = React.useState(false)
  const [returnedData, setReturnedData] = React.useState<ConsolidatedValue>()

  const getConsolidated = async (origin: string) => {
    await get(`${baseURLReconciliation}/consolidated`, {
      params: { ...options, origin: origin },
    }).then((res) => {
      setReturnedData(res.data)
    })
  }

  React.useEffect(() => {
    if (options?.wallet_setting_id) {
      getConsolidated('Devolucao')
    }
  }, [options])

  return (
    <>
      <ReconciliationReturnedModal
        options={options}
        isOpen={detailsModal}
        handleClose={() => {
          setDetailsModal(false)
        }}
      />
      <>
        <MainStatusCard
          label={'Devoluções'}
          status={returnedData?.discrepancy ? 'warning' : 'success'}
          value={formatMoney(returnedData?.sum_orders_value)}
          openModal={() => {
            setDetailsModal(true)
          }}
        />
      </>
    </>
  )
}

export default ReconciliationReturned
