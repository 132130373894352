import {
  genHSLColor,
  getHSLParams,
  hexToHSL,
  HSLToHex,
} from 'helpers/colorsConverter'
import * as s from './styles'

interface IColorPallete {
  inputedColor: string
}

export function generateColorsPallete(hexInputedColor: string) {
  if (hexInputedColor === '#737373') {
    return {
      hsl: {
        darkmaincolor1: genHSLColor(0, 0, 35),
        darkmaincolor2: genHSLColor(0, 0, 25),
        darkmaincolor3: genHSLColor(0, 0, 15),
        maincolor: genHSLColor(0, 0, 45),
        lightmaincolor1: genHSLColor(0, 0, 65),
        lightmaincolor2: genHSLColor(0, 0, 75),
        lightmaincolor3: genHSLColor(0, 0, 90),
        lightmaincolor4: genHSLColor(0, 0, 45),
        navbarvector1: genHSLColor(0, 0, 65),
        transparentmaincolor: genHSLColor(0, 0, 45),
      },
      hex: {
        darkmaincolor1: HSLToHex(0, 0, 35),
        darkmaincolor2: HSLToHex(0, 0, 25),
        darkmaincolor3: HSLToHex(0, 0, 15),
        maincolor: HSLToHex(0, 0, 45),
        lightmaincolor1: HSLToHex(0, 0, 65),
        lightmaincolor2: HSLToHex(0, 0, 75),
        lightmaincolor3: HSLToHex(0, 0, 90),
        lightmaincolor4: HSLToHex(0, 0, 45),
        navbarvector1: HSLToHex(0, 0, 65),
        transparentmaincolor: HSLToHex(0, 0, 45),
      },
    }
  }
  const hslInputedColor = hexToHSL(hexInputedColor)
  const hslInputedColorParams = getHSLParams(hslInputedColor)
  const hslMainColor = genHSLColor(hslInputedColorParams.hue, 70, 45)
  const hslMainColorParams = getHSLParams(hslMainColor)

  return {
    hsl: {
      darkmaincolor1: genHSLColor(hslMainColorParams.hue, 75, 35),
      darkmaincolor2: genHSLColor(hslMainColorParams.hue, 80, 25),
      darkmaincolor3: genHSLColor(hslMainColorParams.hue, 85, 15),
      maincolor: genHSLColor(hslMainColorParams.hue, 70, 45),
      lightmaincolor1: genHSLColor(hslMainColorParams.hue, 65, 65),
      lightmaincolor2: genHSLColor(hslMainColorParams.hue, 60, 75),
      lightmaincolor3: genHSLColor(hslMainColorParams.hue, 55, 90),
      lightmaincolor4: genHSLColor(hslMainColorParams.hue, 70, 45),
      navbarvector1: genHSLColor(hslMainColorParams.hue, 65, 65),
      transparentmaincolor: genHSLColor(hslMainColorParams.hue, 70, 45),
    },
    hex: {
      darkmaincolor1: HSLToHex(hslMainColorParams.hue, 75, 35),
      darkmaincolor2: HSLToHex(hslMainColorParams.hue, 80, 25),
      darkmaincolor3: HSLToHex(hslMainColorParams.hue, 85, 15),
      maincolor: HSLToHex(hslMainColorParams.hue, 70, 45),
      lightmaincolor1: HSLToHex(hslMainColorParams.hue, 65, 65),
      lightmaincolor2: HSLToHex(hslMainColorParams.hue, 60, 75),
      lightmaincolor3: HSLToHex(hslMainColorParams.hue, 55, 90),
      lightmaincolor4: HSLToHex(hslMainColorParams.hue, 70, 45),
      navbarvector1: HSLToHex(hslMainColorParams.hue, 65, 65),
      transparentmaincolor: HSLToHex(hslMainColorParams.hue, 70, 45),
    },
  }
}

export default function ColorPallete({ inputedColor }: IColorPallete) {
  const colorPallete = generateColorsPallete(inputedColor)
  return (
    <s.Container>
      <s.Wrapper data-testid="pallete">
        <s.ColorSquare
          data-testid="lightmaincolor3"
          color={colorPallete.hex.lightmaincolor3}
        />
        <s.ColorSquare
          data-testid="lightmaincolor2"
          color={colorPallete.hex.lightmaincolor2}
        />
        <s.ColorSquare
          data-testid="lightmaincolor1"
          color={colorPallete.hex.lightmaincolor1}
        />
        <s.ColorSquare
          data-testid="maincolor"
          color={colorPallete.hex.maincolor}
        />
        <s.ColorSquare
          data-testid="darkmaincolor1"
          color={colorPallete.hex.darkmaincolor1}
        />
        <s.ColorSquare
          data-testid="darkmaincolor2"
          color={colorPallete.hex.darkmaincolor2}
        />
        <s.ColorSquare
          data-testid="darkmaincolor3"
          color={colorPallete.hex.darkmaincolor3}
        />
      </s.Wrapper>
    </s.Container>
  )
}
