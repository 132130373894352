import React from 'react'
import { useDispatch } from 'react-redux'

import { showToast } from 'components/Toast'

import { useTranslation } from 'react-i18next'

import { get, baseURLGeoAddress } from '../services/api'
import { useTypedSelector } from '../store/modules/rootState'
import {
  GetGeoAddress,
  GetGeoAddressLoading,
  GetGeoAddressError,
  ResetGeoAddress,
} from 'store/modules/geoAddress/action'

import { GeoAddressState } from 'store/modules/geoAddress/types'

interface IUseGetGeoAddress extends GeoAddressState {
  loadGeoAddress: (string) => Promise<void>
  resetGeoAddress: () => void
}

const useGetGeoAddress = (): IUseGetGeoAddress => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { geoAddress, isLoading, hasError } = useTypedSelector((store) => ({
    geoAddress: store.geoAddress.geoAddress,
    isLoading: store.geoAddress.isLoading,
    hasError: store.geoAddress.hasError,
  }))

  const loadGeoAddress = React.useCallback(
    async (cep): Promise<void> => {
      dispatch(GetGeoAddressLoading())
      await get(`${baseURLGeoAddress}/${cep}`)
        .then((response) => {
          dispatch(GetGeoAddress(response.data))
        })
        .catch((e) => {
          console.log(e)
          showToast({
            type: 'error',
            message: `${t(JSON.parse(e.request.response).detail)}`,
          })
          dispatch(GetGeoAddressError())
        })
    },
    [dispatch]
  )

  const resetGeoAddress = React.useCallback(() => {
    dispatch(ResetGeoAddress())
  }, [dispatch])

  return { geoAddress, hasError, isLoading, loadGeoAddress, resetGeoAddress }
}

export default useGetGeoAddress
