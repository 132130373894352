import { ActionInterface, Types, TransactionLogsState } from './types'

const INITIAL_STATE: TransactionLogsState = {
  transactions: { count: 0, data: [], offset: 0, total: -1 },
  isLoading: false,
  hasError: false,
}

const transactionLogsReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): TransactionLogsState => {
  switch (action.type) {
    case Types.GET_TRANSACTION_LOGS:
      return {
        ...state,
        transactions: action.payload,
        isLoading: false,
        hasError: false,
      }
    case Types.GET_TRANSACTION_LOGS_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_TRANSACTION_LOGS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export default transactionLogsReducer
