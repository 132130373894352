import * as s from './styles'

import { FilesListModalList, FilesModalListProps } from './FilesListModalList'

const FilesModalList: React.FC<FilesModalListProps> = (props) => {
  return (<s.FilesModalWrapper>
    <s.SectionHeader>
      <s.Icon name='paper' fill='gray1' />
      <s.Text type='paragraph' bold color='gray1'>
        Arquivos Gerados
      </s.Text>
      <s.Spacer />
      <s.UpdateButton
        onClick={props.refresh}
        color='whitedetailed'
      >
        <s.Icon name='arrowspinner' fill='maincolor' />
        <s.Text bold color='maincolor'>Atualizar</s.Text>
      </s.UpdateButton>
    </s.SectionHeader>
    <s.SectionContentWrapper>
      <FilesListModalList {...props} />
    </s.SectionContentWrapper>
  </s.FilesModalWrapper>
  )
}

export default FilesModalList
