import styled, { css } from 'styled-components'

import TextCmp from 'components/Text'
import ModalCmp from 'components/Modal'

export const Modal = styled(ModalCmp)``

export const Text = styled(TextCmp)``

export const Container = styled.div`
  padding: 32px 16px;
  width: 300px;
  word-wrap: break-word;
`
