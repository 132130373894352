import { useState } from 'react'
import { get, baseURLPaymentV1, bffv2URLSystemWallets } from '../services/api'
import { showToast } from 'components/Toast'

export interface SystemWalletBff {
  active: boolean
  cashout_active: boolean
  id: number
  single_certificate: boolean
  single_credentials: boolean
  wallet_friendly_name: string
  wallet_icon: string
  wallet_logo: string
  wallet_name: string
  wallet_type: string
  features: string[]
}

interface systemWallets {
  wallets: Array<SystemWalletBff>
}

interface UseGetSystemWalletsFromBff {
  systemWalletsFromBff: Array<SystemWalletBff>
  systemWalletsFromBffIsLoading: boolean
  systemWalletsFromHasError: boolean
  getSystemWalletsFromBff: () => Promise<void>
}

const useGetSystemWalletsFromBff = (): UseGetSystemWalletsFromBff => {
  const [systemWalletsFromBff, setSystemWalletsFromBff] = useState<
    Array<SystemWalletBff>
  >([])

  const [systemWalletsFromBffIsLoading, setSystemWalletsIsLoading] =
    useState<boolean>(false)
  const [systemWalletsFromHasError, setSystemWalletsHasError] =
    useState<boolean>(false)
  const getSystemWalletsFromBff = async () => {
    setSystemWalletsIsLoading(true)
    await get(`${bffv2URLSystemWallets}`)
      .then((response) => {
        setSystemWalletsFromBff(response.data.wallets)
      })
      .catch((e) => {
        showToast({
          type: 'error',
          message: JSON.parse(e.request.response)?.message,
        })
        setSystemWalletsHasError(true)
      })
      .finally(() => {
        setSystemWalletsIsLoading(false)
      })
  }
  return {
    systemWalletsFromBff,
    systemWalletsFromBffIsLoading,
    systemWalletsFromHasError,
    getSystemWalletsFromBff,
  }
}

export default useGetSystemWalletsFromBff
