export const Types = {
  POST_IMPERSONATE: 'POST_IMPERSONATE',
  POST_IMPERSONATE_RESPONSE: 'POST_IMPERSONATE_RESPONSE',
  POST_IMPERSONATE_ERROR: 'POST_IMPERSONATE_ERROR',
  IMPERSONATE_REMOVED: 'IMPERSONATE_REMOVED'
}

export interface RequestInterface {
}

export interface ResponseInterface {
  access_token: string
  email: string
  id: string
  name: string
  refresh_token: string
  roles: string[]
  impersonate: boolean
}

export interface ActionInterface {
  type: keyof typeof Types
  request: RequestInterface
  response: ResponseInterface
}

export interface ImpersonateState {
  request: RequestInterface
  isLoading: boolean
  hasError: boolean
  response: ResponseInterface
}
