import { get, baseURLClientCertificates, baseURLClientCert, post } from '../api'

export interface ClientCertInterface {
  created_at: string
  description: string | null
  expire_date: string
  id: string
  in_use: boolean
  name: string
  psp_provider: string
  status: string
}

export interface CreateClientCertInterface {
  ca: string,
  cert: string,
  description: string,
  name: string,
  password: string,
  priv: string,
  status: string,
} 

const getClientCerts = (params) => {
  return get(`${baseURLClientCertificates}`, { params: params || ' '})
}

const postClientCert = (payload: Partial<CreateClientCertInterface>) => {
  return post(`${baseURLClientCert}`, payload)
}

const clientCertService = {
  getClientCerts,
  postClientCert
}

export default clientCertService
