import { useState } from 'react'
import { get, baseURLSummaryStoreDestinationAccounts } from '../services/api'
import { showToast } from 'components/Toast'

export type TStatusAccountUnderReview =
  | 'in-progress'
  | 'finished'
  | 'rejected'
  | null

export interface ISummaryStoreDestinationAccount {
  store_cnpj_cpf: string | null
  pix_dict_key: string | null
  store_uuid: string
  status: TStatusAccountUnderReview | null
  destination_account_uuid?: string | null
}

interface PayloadSummary {
  count: number
  data: ISummaryStoreDestinationAccount[]
  offset: number
  total: number
}

interface UseGetSummaryStoreDestinationAccounts {
  summary: PayloadSummary
  isLoading: boolean
  hasError: boolean
  getSummary: () => Promise<void>
}

const useGetSummaryStoreDestinationAccounts =
  (): UseGetSummaryStoreDestinationAccounts => {
    const [summary, setSummary] = useState<PayloadSummary>({
      count: 0,
      data: [],
      offset: 0,
      total: -1,
    })
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [hasError, setHasError] = useState<boolean>(false)

    const getSummary = async () => {
      setIsLoading(true)
      await get(`${baseURLSummaryStoreDestinationAccounts}`)
        .then((response) => {
          setSummary(response.data)
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: JSON.parse(e.request.response)?.message,
          })
          setHasError(true)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    return { summary, isLoading, hasError, getSummary }
  }

export default useGetSummaryStoreDestinationAccounts
