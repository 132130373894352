import { Types, PayloadInterface } from './types'

export function GetProfile(
  payload: PayloadInterface
): {
  type: string
  payload: PayloadInterface
} {
  return {
    type: Types.GET_PROFILE,
    payload,
  }
}

export function GetProfileLoading(): {
  type: string
} {
  return {
    type: Types.GET_PROFILE_LOADING,
  }
}

export function GetProfileError(): {
  type: string
} {
  return {
    type: Types.GET_PROFILE_ERROR,
  }
}
