import React from 'react'

import paitausuccess2 from '../../../assets/images/pa-itau-success2.png'

import * as s from '../styles'

const AnyBankItauSuccessModal: React.FC<{ handleClose: () => void }> = ({
  handleClose,
}) => {
  return (
    <s.AnybankItauSuccessWrapper>
      <s.Text margin="32px 0 0 0" type="headline" bold color="graytheme6">
        Cadastro efetuado com sucesso!
      </s.Text>
      <s.InfoBox>
        <s.IconBox>
          <s.Icon fill="maincolor" name="clock" />
        </s.IconBox>
        <s.TextWrapper>
          <s.Text bold>Abertura de conta em análise</s.Text>
          <s.Text>
            Suas informações serão analisadas pelo Banco Itaú.
            <br />O resultado da analise leva até 3 dias úteis.
          </s.Text>
        </s.TextWrapper>
      </s.InfoBox>
      <s.InfoBox>
        <s.IconBox>
          <s.Icon fill="maincolor" name="eyeon" />
        </s.IconBox>
        <s.TextWrapper>
          <s.Text bold>Acompanhe seu cadastro</s.Text>
          <s.AnyBankSuccessPixText>
            Você pode acompanhar esse processo na aba{' '}
            <b onClick={() => handleClose()}>Pix↗</b> do Painel,
            <br />
            na seção <u>Contas em análise</u>.
          </s.AnyBankSuccessPixText>
        </s.TextWrapper>
      </s.InfoBox>
      <s.ImageWrapper width={323} height={160}>
        <img src={paitausuccess2} alt="Imagem de Sucesso Pix AnyBank" />
      </s.ImageWrapper>
    </s.AnybankItauSuccessWrapper>
  )
}

export default AnyBankItauSuccessModal
