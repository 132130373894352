import React from 'react'

import * as s from './styles'

const WhiteLabelLoading: React.FC = () => {
  return (
    <s.Container>
      <s.Loading color="black" />
    </s.Container>
  )
}

export default WhiteLabelLoading
