import { get, post, patch, remove, baseURLRetailChain } from '../api'
import { Partial } from 'helpers/partial'

export interface RetailChainInterface {
  name: string
  email: string
  pos_product_id: string
}

const getRetailChainsByPosProduct = (posProductId: string) => {
    return get(`${baseURLRetailChain}/pos/product/${posProductId}`)
}

const getRetailChains = (params = null) => {
  const { filter, filter_type, ..._params} = params
  if (filter_type)
    _params[filter_type] = filter
  return get(`${baseURLRetailChain}/summary`, { params: _params || ''})
}

const deleteRetailChain = (retailChainId: string) => {
  return remove(`${baseURLRetailChain}/${retailChainId}`)
}

const createRetailChain = (retailChain: RetailChainInterface) => {
  return post(`${baseURLRetailChain}`, retailChain)
}

const editRetailChain = (retailChainId: string,
  retailChain: Partial<RetailChainInterface>) => {
  return patch(`${baseURLRetailChain}/${retailChainId}`, retailChain)
}

const retailChainService = {
  getRetailChains,
  deleteRetailChain,
  createRetailChain,
  editRetailChain,
  getRetailChainsByPosProduct,
}

export default retailChainService
