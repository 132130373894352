import { Types, PayloadInterface } from './types'

export function GetTransactionLogs(
  payload: PayloadInterface
): {
  type: string
  payload: PayloadInterface
} {
  return {
    type: Types.GET_TRANSACTION_LOGS,
    payload,
  }
}

export function GetTransactionLogsIsLoading(): {
  type: string
} {
  return {
    type: Types.GET_TRANSACTION_LOGS_LOADING,
  }
}

export function GetTransactionLogsError(): {
  type: string
} {
  return {
    type: Types.GET_TRANSACTION_LOGS_ERROR,
  }
}
