import { createGlobalStyle } from 'styled-components'
import FontInter from '../assets/fonts/Inter-Regular.ttf'
import Consolas from '../assets/fonts/Consolas.ttf'

export default createGlobalStyle`
  @font-face {
    font-family: 'FontInter';
    src: url(${FontInter});
  }

  @font-face {
    font-family: 'Consolas';
    src: url(${Consolas});
  }

  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: 'FontInter', sans-serif;
  }

  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    overflow-x: hidden;
  }
  body {
    scroll-behavior: smooth;

  }
  html, body {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    /* background-color: ${(props) => props.theme.colors.whiteshipay}; */
  }

  html.noOverflow {
    overflow: hidden;
  }

  a {
    text-decoration: none;
    color: #000;
  }

  p {
    font-weight: 300;
  }

  ::selection {
    background: #018cb7;
    color: #fff;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
  }

  .main-content {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    justify-content: center;
    flex-direction: row;
    height: calc(100vh - 100px);
    overflow-y: auto;
    background-color: ${(props) => props.theme.colors.whiteshipay};
  }

  ::-webkit-scrollbar {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.graytheme1};
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.graytheme3};
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.graytheme4};
  }

  ::-webkit-scrollbar-thumb:active {
    background: ${({ theme }) => theme.colors.graytheme5};
  }
`


