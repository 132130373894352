import React from 'react'

import useWalletsSettings from 'hooks/useWalletsSettings'
import { showToast } from 'components/Toast'
import { getCryptos } from 'helpers/crypto'

import { WalletSetting } from 'store/modules/walletSettings/types'

import * as s from './styles'

interface IProps {
  data: WalletSetting
  handleClose: () => void
  offset: string
}

const EditFoxBitWallet: React.FC<IProps> = ({ data, handleClose, offset }) => {
  const { patchIsLoading, patchWalletSettings, loadWalletsSettings } =
    useWalletsSettings()

  const [inputEditable, setInputEditable] = React.useState<any>({
    ...Object.keys({ ...data, payer_crypto_coin: '' }).reduce((acc, key) => {
      acc[key] = false
      return acc
    }, {}),
  })

  const [newWallet, setNewWallet] = React.useState<WalletSetting>(data)
  const cryptos = [
    ...getCryptos().map((crypto) => {
      return {
        id: crypto.id,
        text: crypto.text,
        onClick: () => {
          setNewWallet({
            ...newWallet,
            payer_crypto_coin: crypto.text.replace(' ', '').split('-')[0],
          })
          setSelectedDefaultCrypto({
            id: crypto.id,
            name: crypto.text,
          })
        },
      }
    }),
  ]
  const [selectedDefaultCrypto, setSelectedDefaultCrypto] = React.useState({
    id: -1,
    name: '',
  })

  React.useEffect(() => {
    const crypto = cryptos.find((crypto) => {
      return crypto.text.replace(' ', '').split('-')[0] === 'BTC.LN'
    })
    setSelectedDefaultCrypto({ id: crypto.id, name: crypto.text })
    setNewWallet({
      ...newWallet,
      payer_crypto_coin: crypto.text,
    })
  }, [])

  return (
    <s.FormContainer>
      <s.InputText
        label="Apelido da Credencial "
        disabled={!inputEditable.name}
        onChange={(e) => {
          setNewWallet({ ...newWallet, name: e.target.value })
        }}
        suffix={
          <s.EditButtonWrapper
            onClick={() => {
              setInputEditable({
                ...inputEditable,
                name: !inputEditable.name,
              })
              if (inputEditable.name) {
                setNewWallet({
                  ...newWallet,
                  name: data.name,
                })
              } else {
                setNewWallet({
                  ...newWallet,
                  name: '',
                })
              }
            }}
          >
            <s.Icon name="pencil" fill="maincolor" />
          </s.EditButtonWrapper>
        }
        placeholder={data.name}
        value={newWallet.name}
      />
      <s.InputText
        disabled={!inputEditable.client_id}
        onChange={(e) => {
          setNewWallet({ ...newWallet, client_id: e.target.value })
        }}
        suffix={
          <s.EditButtonWrapper
            onClick={() => {
              setInputEditable({
                ...inputEditable,
                client_id: !inputEditable.client_id,
              })
              if (inputEditable.client_id) {
                setNewWallet({
                  ...newWallet,
                  client_id: data.client_id,
                })
              } else {
                setNewWallet({
                  ...newWallet,
                  client_id: '',
                })
              }
            }}
          >
            <s.Icon name="pencil" fill="maincolor" />
          </s.EditButtonWrapper>
        }
        label="Chave de acesso"
        placeholder={data.client_id}
        value={newWallet.client_id}
      />
      <s.InputText
        disabled={!inputEditable.client_secret}
        onChange={(e) => {
          setNewWallet({ ...newWallet, client_secret: e.target.value })
        }}
        suffix={
          <s.EditButtonWrapper
            onClick={() => {
              setInputEditable({
                ...inputEditable,
                client_secret: !inputEditable.client_secret,
              })
              if (inputEditable.client_secret) {
                setNewWallet({
                  ...newWallet,
                  client_secret: data.client_secret,
                })
              } else {
                setNewWallet({
                  ...newWallet,
                  client_secret: '',
                })
              }
            }}
          >
            <s.Icon name="pencil" fill="maincolor" />
          </s.EditButtonWrapper>
        }
        label="Chave secreta"
        placeholder={data.client_secret}
        value={newWallet.client_secret}
      />
      <s.SearchSelect
        label="Criptomoeda"
        optionBoxSize={100}
        disabled={!inputEditable.payer_crypto_coin}
        options={[cryptos[0]]}
        suffix={
          <s.EditButtonWrapper
            onClick={(e) => {
              e.stopPropagation()
              setInputEditable({
                ...inputEditable,
                payer_crypto_coin: !inputEditable.payer_crypto_coin,
              })
              if (inputEditable.payer_crypto_coin) {
                const crypto = cryptos.find((crypto) => {
                  return crypto.text.includes(data.payer_crypto_coin)
                })
                setSelectedDefaultCrypto({ id: crypto.id, name: crypto.text })
                setNewWallet({
                  ...newWallet,
                  payer_crypto_coin: data.payer_crypto_coin,
                })
              } else {
                setSelectedDefaultCrypto({ id: -1, name: '' })
              }
            }}
          >
            <s.Icon name="pencil" fill="maincolor" />
          </s.EditButtonWrapper>
        }
        selectedState={[selectedDefaultCrypto, setSelectedDefaultCrypto]}
      />
      {Object.values(inputEditable).some((input) => input === true) ? (
        <s.FooterButtonWrapper>
          <s.Button
            disabled={patchIsLoading}
            width="100px"
            color="transparent"
            outline
            onClick={() => handleClose()}
          >
            Cancelar
          </s.Button>
          <s.Button
            width="120px"
            disabled={
              patchIsLoading ||
              (newWallet.client_secret === data.client_secret &&
                newWallet.client_id === data.client_id &&
                newWallet.name === data.name &&
                newWallet.payer_crypto_coin.replace(' ', '').split('-')[0] ===
                  data.payer_crypto_coin)
            }
            onClick={() => {
              if (
                newWallet.client_secret === '' ||
                newWallet.client_id === '' ||
                newWallet.name === '' ||
                newWallet.payer_crypto_coin === ''
              ) {
                showToast({
                  type: 'error',
                  message: 'Você não pode deixar campos em branco',
                })
              } else {
                patchWalletSettings(newWallet.id, {
                  ...Object.keys(inputEditable).reduce((acc, key) => {
                    if (inputEditable[key] === true) {
                      acc[key] = newWallet[key]
                    }
                    return acc
                  }, {}),
                }).then((status) => {
                  if (status === '200') {
                    loadWalletsSettings({
                      limit: '10',
                      offset: offset,
                      filters: window.btoa(
                        unescape(
                          encodeURIComponent(
                            JSON.stringify({
                              wallet_type: ['c-wallet', 'e-wallet'],
                            })
                          )
                        )
                      ),
                    })
                    handleClose()
                  }
                })
              }
            }}
            color="maincolor"
          >
            Salvar
          </s.Button>
          {patchIsLoading ? (
            <s.Loading type="spinner" color="maincolor" />
          ) : null}
        </s.FooterButtonWrapper>
      ) : null}
    </s.FormContainer>
  )
}

export default EditFoxBitWallet
