import {
  ActionInterface,
  Types,
  WalletSetting,
  WalletSettingsInterface,
  WalletSettingsState,
} from './types'

const INITIAL_STATE: WalletSettingsState = {
  walletSettings: { count: -1, offset: -1, total: -1, data: [] },
  isLoading: false,
  hasError: false,
  patchResponse: null,
  patchIsLoading: false,
  patchHasError: false,
  patchHasErrorMessage: null,
  walletSetting: {
    active: null,
    client_id: null,
    client_secret: null,
    default: null,
    enabled_pix: null,
    id: null,
    name: null,
    psp_provider: null,
    wallet_name: null,
  },
  walletSettingHasError: false,
  walletSettingIsLoading: false,
  postResponse: null,
  postIsLoading: false,
  postHasError: false,
  postHasErrorMessage: null,
}

const walletsSettingsReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): WalletSettingsState => {
  switch (action.type) {
    case Types.GET_WALLETS_SETTINGS:
      return {
        ...state,
        walletSettings: action.payload as WalletSettingsInterface,
        isLoading: false,
        hasError: false,
      }
    case Types.GET_WALLETS_SETTINGS_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_WALLETS_SETTINGS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    case Types.PATCH_WALLET_SETTINGS_REQUEST:
      return {
        ...state,
        patchIsLoading: true,
        patchHasError: false,
      }
    case Types.PATCH_WALLET_SETTINGS_RESPONSE:
      return {
        ...state,
        patchResponse: action.response,
        patchIsLoading: false,
        patchHasError: false,
        patchHasErrorMessage: '',
      }
    case Types.PATCH_WALLET_SETTINGS_ERROR:
      return {
        ...state,
        patchIsLoading: false,
        patchHasError: true,
        patchHasErrorMessage: action.message,
      }
    case Types.GET_WALLET_SETTING:
      return {
        ...state,
        walletSetting: action.payload as WalletSetting,
        walletSettingIsLoading: false,
        walletSettingHasError: false,
      }
    case Types.GET_WALLET_SETTING_LOADING:
      return {
        ...state,
        walletSettingIsLoading: true,
        walletSettingHasError: false,
      }
    case Types.GET_WALLET_SETTING_ERROR:
      return {
        ...state,
        walletSettingHasError: true,
        walletSettingIsLoading: false,
      }
    case Types.POST_WALLET_SETTING_LOADING:
      return {
        ...state,
        postIsLoading: true,
        postHasError: false,
      }
    case Types.POST_WALLET_SETTING_RESPONSE:
      return {
        ...state,
        postResponse: action.postResponse,
        postIsLoading: false,
        postHasError: false,
      }
    case Types.POST_WALLET_SETTING_ERROR:
      return {
        ...state,
        postIsLoading: false,
        postHasError: true,
        postHasErrorMessage: action.postErrorMessage,
      }
    default:
      return state
  }
}

export default walletsSettingsReducer
