import React from 'react'

import { useTranslation } from 'react-i18next'

import { getValueFromLocalStorage } from 'helpers/localStorage'

import useWalletSettingsSaga from 'hooks/useWalletSettingsSaga'
import { useSSE } from 'hooks/utils/useSSE'
import useWalletsSettings from 'hooks/useWalletsSettings'
import { useNonInitialEffect } from 'hooks/utils'

import customerService from 'services/customer'

import { showToast } from 'components/Toast'

import { get } from 'services/api'
import { baseURL } from 'services/apiConfig'

import { isSpacePay } from 'helpers'

import * as s from '../styles'
import { UserSessionInfo } from 'store/modules/auth/types'

const MercadoPagoConfigWallet: React.FC<{
  handleClose: () => void
  isModalOpen: boolean
  offset?: string
}> = ({ handleClose, isModalOpen, offset }) => {
  const { t } = useTranslation()
  const { loadWalletsSettings } = useWalletsSettings()
  const { postIsLoading, postResponse, postEWalletWalletSettingsSaga } =
    useWalletSettingsSaga()

  const [showHelper, setShowHelper] = React.useState(false)

  const { openConnection, notifications } = useSSE()

  const [customerUUID, setCustomerUUID] = React.useState<string>('')
  const [isSSELoading, setSSEIsLoading] = React.useState<boolean>(false)

  const [isManual, setIsManual] = React.useState(false)
  const [input, setInput] = React.useState({
    access_key: '',
    active: true,
    collector_id: '',
    default: false,
    enable_pix: 'false',
    active_pix: false,
    name: '',
    public_key: '',
    wallet_name: 'mercadopago',
  })
  const [selected, setSelected] = React.useState({ id: 2, name: 'Não' })

  const [inputPressed, setInputPressed] = React.useState<any>({
    ...Object.keys(input).reduce((acc, key) => {
      acc[key] = false
      return acc
    }, {}),
  })

  const oauthGet = async (wallet_name: string) => {
    let oauthURL: any = {}
    await get(`${baseURL}/wallet/oauth/${wallet_name}`).then(
      (response) => (oauthURL = response.data)
    )

    window.open(oauthURL.redirect_to, '_blank')
  }

  const handlePost = async () => {
    postEWalletWalletSettingsSaga({
      ...input,
      collector_id: input.access_key.slice(
        input.access_key.lastIndexOf('-') + 1
      ),
    })
  }

  useNonInitialEffect(() => {
    if (postResponse.code !== -1) {
      showToast({
        type: 'success',
        message: t(postResponse.message),
      })
      setSSEIsLoading(true)
      customerService.getCurrentCustomer().then((currentCustomerRes) => {
        setCustomerUUID(currentCustomerRes.data.uuid)
        openConnection(currentCustomerRes.data.uuid)
      })
    }
  }, [postResponse])

  useNonInitialEffect(() => {
    if (notifications) {
      const status = notifications[customerUUID].message
        .toLowerCase()
        .includes('sucesso')
        ? 'success'
        : 'error'
      showToast({
        type: status,
        message: notifications[customerUUID].message,
      })
      setSSEIsLoading(false)
      if (status === 'success') {
        handleClose()
        loadWalletsSettings({
          limit: '10',
          offset: offset,
          filters: window.btoa(
            JSON.stringify({ wallet_type: ['c-wallet', 'e-wallet'] })
          ),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  React.useEffect(() => {
    if (!isModalOpen) {
      setInput({
        access_key: '',
        active: true,
        collector_id: '',
        default: false,
        enable_pix: 'false',
        active_pix: false,
        name: '',
        public_key: '',
        wallet_name: 'mercadopago',
      })
      setInputPressed({
        ...Object.keys(input).reduce((acc, key) => {
          acc[key] = false
          return acc
        }, {}),
      })
      var iframe = document.querySelector('iframe')
      if (iframe) {
        var iframeSrc = iframe.src
        iframe.src = iframeSrc
      }
      setShowHelper(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen])

  const renderForm = (height: number) => {
    return (
      <>
        {showHelper ? (
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/tDtoHSdA2CQ"
            title="YouTube video player ame"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          <s.FormContainer height={height}>
            {!isSpacePay() ? (
              <s.HelperWrapper>
                <s.Text>
                  Para ajudar você nesta tarefa preparamos um vídeo explicativo!
                </s.Text>
                <s.Button
                  onClick={() => {
                    setShowHelper(true)
                  }}
                  width="200px"
                >
                  Clique aqui
                </s.Button>
              </s.HelperWrapper>
            ) : (
              <></>
            )}
            <s.InputText
              label="Apelido da Credencial"
              placeholder="Ex: ”Loja Paulista”, ”loja@paulista.com”, etc."
              value={input.name}
              onChange={(e) => {
                setInput({ ...input, name: e.target.value })
                setInputPressed({ ...inputPressed, name: true })
              }}
              error={
                input.name === '' && inputPressed.name
                  ? {
                      message: 'Campo Obrigatório',
                    }
                  : null
              }
            />
            <s.InputText
              label="Access Token"
              value={input.access_key}
              onChange={(e) => {
                setInput({
                  ...input,
                  access_key: e.target.value,
                  collector_id: e.target.value,
                })
                setInputPressed({ ...inputPressed, access_key: true })
              }}
              error={
                input.access_key === '' && inputPressed.access_key
                  ? {
                      message: 'Campo Obrigatório',
                    }
                  : null
              }
            />
            <s.Select
              label="Ativar Pix"
              options={[
                {
                  id: 1,
                  text: 'Sim',
                  onClick: () => {
                    setInput({ ...input, enable_pix: 'true', active_pix: true })
                    setSelected({ id: 1, name: 'Sim' })
                  },
                },
                {
                  id: 2,
                  text: 'Não',
                  onClick: () => {
                    setInput({
                      ...input,
                      enable_pix: 'false',
                      active_pix: false,
                    })
                    setSelected({ id: 2, name: 'Não' })
                  },
                },
              ]}
              selected={selected}
            />
            <s.FooterButtonWrapper>
              <s.Button
                outline
                disabled={postIsLoading || isSSELoading}
                color="white"
                onClick={() => handleClose()}
              >
                Cancelar
              </s.Button>
              <s.Button
                color="maincolor"
                disabled={
                  input.name === '' ||
                  input.access_key === '' ||
                  postIsLoading ||
                  isSSELoading
                }
                onClick={() => handlePost()}
              >
                Salvar
              </s.Button>
              {postIsLoading || isSSELoading ? (
                <s.Loading type="spinner" color="maincolor" />
              ) : null}
            </s.FooterButtonWrapper>
          </s.FormContainer>
        )}
      </>
    )
  }

  const renderPathButton = () => {
    return (
      <s.PathContainer>
        <s.Button
          width="350px"
          color="maincolor"
          onClick={() => {
            oauthGet('mercadopago')
          }}
        >
          Pegar credenciais
        </s.Button>
        <s.ButtonLink
          onClick={() => {
            setIsManual(true)
          }}
        >
          Clique aqui se precisar fazer a configuração manual das credenciais
          mercadopago
        </s.ButtonLink>
      </s.PathContainer>
    )
  }

  return (
    <>{isManual ? renderForm(isSpacePay() ? 400 : 500) : renderPathButton()}</>
  )
}

export default MercadoPagoConfigWallet
