import { useEffect } from 'react'
import {
  Switch,
  Route as ReactRouterDomRoute,
  Redirect,
} from 'react-router-dom'

import { getValueFromLocalStorage } from 'helpers/localStorage'
import {
  getRouteUserClaims,
  hasCustomerUserClaim,
  hasImpersonateClaim,
} from 'helpers/claims'

import { ROUTES } from './routesDefs'

import Navbar from '../components/Navbar'
import Wizard from 'pages/Wizard'
import Login from 'pages/Login'
import ResetPassword from 'pages/Login/ResetPassword'
import { useGetShouldRedirectWizard } from 'hooks/wizard/useGetShouldRedirectWizard'
import { isConexaoItau } from 'helpers'
import { useTheme } from 'styled-components'
import LoadingTheme from 'pages/Loading'
import { UserSessionInfo } from 'store/modules/auth/types'
import useImpersonate from 'hooks/useImpersonate'

const DefaultRoute = ({ name, component, path, filteredRoutes, ...rest }) => {
  return (
    <>
      <Navbar menuItems={filteredRoutes} />
      <main className="main-content">
        <ReactRouterDomRoute
          path={path}
          exact={rest.exact}
          component={component}
        />
      </main>
    </>
  )
}

export default function Routes({ setTheme }) {
  const theme = useTheme()

  const currentUserSession: UserSessionInfo = JSON.parse(
    getValueFromLocalStorage('currentUserSession')
  )

  const isLoggedIn = currentUserSession && currentUserSession.id
  const {} = useImpersonate()
  const { shouldRedirect, loadShouldRedirect } = useGetShouldRedirectWizard()

  useEffect(() => {
    if (isLoggedIn) {
      loadShouldRedirect()
    }
    window.dispatchEvent(new Event('storage'))
  }, [isLoggedIn, loadShouldRedirect])

  if (isLoggedIn) {
    const routeClaims = getRouteUserClaims(currentUserSession)
    const isCustomer = hasCustomerUserClaim(currentUserSession?.user_claims)
    const impersonated = hasImpersonateClaim(currentUserSession)

    const filteredRoutes = ROUTES.filter((route) => {
      if (route.name === 'Lojas') {
        route.icon = theme.assets.orders.storeIcon
      }
      if (route.name === 'Rede' && isConexaoItau()) {
        route.name = 'Grupo'
        return route
      }
      if (
        route.link === '/lojas/:id' &&
        routeClaims.some((route) => route === 'lojas_<id>')
      ) {
        return route
      }
      if (routeClaims.includes(route.link.slice(1).replace(/[/-]/g, '_'))) {
        return route
      }
      return null
    })

    const routes = filteredRoutes.map((route, index) => {
      return (
        <DefaultRoute
          key={index}
          filteredRoutes={filteredRoutes}
          path={route.link}
          exact
          name={route.name}
          component={route.component}
        />
      )
    })

    return (
      <Switch>
        {routes}
        <ReactRouterDomRoute path="/wizard" exact component={Wizard} />
        <ReactRouterDomRoute
          path="/loading-theme"
          exact
          children={<LoadingTheme setTheme={setTheme} />}
        />
        <ReactRouterDomRoute path="/login" exact component={Login} />
        <ReactRouterDomRoute
          path="/resetPassword/:id"
          component={ResetPassword}
        />
        <ReactRouterDomRoute path="/">
          {isCustomer ? (
            shouldRedirect ? (
              <Redirect to="/wizard" />
            ) : (
              <Redirect to="/pagamentos" />
            )
          ) : impersonated ? (
            <Redirect to="/pagamentos" />
          ) : (
            <Redirect to="/admin" />
          )}
        </ReactRouterDomRoute>
      </Switch>
    )
  } else {
    return (
      <Switch>
        <ReactRouterDomRoute path="/login" exact component={Login} />
        <ReactRouterDomRoute
          path="/resetPassword/:id"
          component={ResetPassword}
        />
        <Redirect to="/login" />
      </Switch>
    )
  }
}
