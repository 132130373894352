import React from 'react'
import { useDispatch } from 'react-redux'

import { get, baseURLSummaryList, baseURLSummaryDetail } from 'services/api'
import { useTypedSelector } from 'store/modules/rootState'
import {
  GetSummaryList,
  GetSummaryListIsLoading,
  GetSummaryListError,
  GetSummaryDetail,
  GetSummaryDetailIsLoading,
  GetSummaryDetailError,
} from 'store/modules/summary/action'

import { SummaryState } from 'store/modules/summary/types'

interface ILoadSummary {
  limit?: number
  offset?: number
  sort?: string
  orderby?: string
  id?: number
  created_at?: string
  updated_at?: string
  wallet_name?: string
  store_name?: string
  store_pos_name?: string
  total_order?: number
  status?: string
  minimum_amount?: string
  maximum_amount?: string
  initial_date?: string
  final_date?: string
  params?: URLSearchParams
}

interface IUseGetSummary extends SummaryState {
  loadSummary: (params?: ILoadSummary) => Promise<void>
  loadSummaryDetail: (
    id: string,
    expirationDate: string,
    signal?: AbortSignal
  ) => Promise<void>
}

const UseGetSummary = (): IUseGetSummary => {
  const dispatch = useDispatch()

  const {
    summary,
    isLoading,
    hasError,
    summaryDetail,
    summaryDetailIsLoading,
    summaryDetailHasError,
  } = useTypedSelector((store) => ({
    summary: store.summary.summary,
    isLoading: store.summary.isLoading,
    hasError: store.summary.hasError,
    summaryDetail: store.summary.summaryDetail,
    summaryDetailIsLoading: store.summary.summaryDetailIsLoading,
    summaryDetailHasError: store.summary.summaryDetailHasError,
  }))

  const loadSummary = React.useCallback(
    async (params): Promise<void> => {
      dispatch(GetSummaryListIsLoading())
      await get(baseURLSummaryList, { params: params.params })
        .then((response) => {
          dispatch(GetSummaryList(response.data))
        })
        .catch(() => {
          dispatch(GetSummaryListError())
        })
    },
    [dispatch]
  )

  const loadSummaryDetail = React.useCallback(
    async (
      id,
      expirationDate,
      signal = new AbortController().signal
    ): Promise<void> => {
      const summaryDetailURL = expirationDate
        ? `${baseURLSummaryDetail}v`
        : baseURLSummaryDetail
      dispatch(GetSummaryDetailIsLoading())
      await get(`${summaryDetailURL}/${id}`, { signal: signal })
        .then((response) => {
          dispatch(GetSummaryDetail(response.data))
        })
        .catch(() => {
          dispatch(GetSummaryDetailError())
        })
    },
    [dispatch]
  )

  return {
    summary,
    loadSummary,
    isLoading,
    hasError,
    loadSummaryDetail,
    summaryDetail,
    summaryDetailIsLoading,
    summaryDetailHasError,
  }
}

export default UseGetSummary
