import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { showToast } from 'components/Toast'

import { baseURLAuth, postWithCredentials } from '../services/api'
import { RootState, useTypedSelector } from 'store/modules/rootState'
import {
  PostAuth,
  PostAuthResponse,
  PostAuthError,
} from 'store/modules/auth/action'

import {
  AccessTokenJSON,
  AuthState,
  RequestInterface,
  UserSessionData,
} from 'store/modules/auth/types'
import { setValueToLocalStorage } from 'helpers/localStorage'
import { isCustomerUser } from 'helpers/claims'
import wizardService from 'services/wizard'
import { useTheme } from 'styled-components'
import { isHTML, isWhiteLabel } from 'helpers'
import { parseJwt } from 'helpers/jwt'

interface IUsePostAuth extends AuthState {
  postAuth: (request: RequestInterface) => Promise<void>
}

export const parseUserSessionData = (userSessionData: UserSessionData) => {
  const { id, name } = userSessionData
  const access_token_json: AccessTokenJSON = parseJwt(
    userSessionData.access_token
  )
  return JSON.stringify({
    id,
    name,
    customer_id: access_token_json.identity.customer_id,
    impersonate: access_token_json.identity.impersonate,
    user_claims: access_token_json.user_claims,
  })
}

const usePostAuth = (): IUsePostAuth => {
  const history = useHistory()
  const dispatch = useDispatch()
  const theme = useTheme()
  const { request, isLoading, hasError, errorMessage, response } =
    useTypedSelector((store) => ({
      request: store.auth.request,
      isLoading: store.auth.isLoading,
      hasError: store.auth.hasError,
      errorMessage: store.auth.errorMessage,
      response: store.auth.response,
    }))

  const shouldRedirect = async () => {
    const response = await wizardService.getShouldRedirect()
    return response.data.should_init_wizard
  }

  const postAuth = React.useCallback(
    async (request): Promise<void> => {
      dispatch(PostAuth(request))
      await postWithCredentials(baseURLAuth, request)
        .then((response: any) => {
          setValueToLocalStorage(
            'currentUserSession',
            parseUserSessionData(response.data)
          )
          dispatch(PostAuthResponse(response.data))
          if (isCustomerUser(response.data.access_token)) {
            shouldRedirect().then((response) => {
              if (response) {
                if (isWhiteLabel() && theme.domain === 'Shipay') {
                  history.push('/loading-theme')
                } else {
                  history.push('/wizard')
                }
              } else {
                if (isWhiteLabel() && theme.domain === 'Shipay') {
                  history.push('/loading-theme')
                } else {
                  history.push('/')
                }
              }
            })
          } else if (isWhiteLabel() && theme.domain === 'Shipay') {
            history.push('/loading-theme')
          } else {
            history.push('/admin')
          }
        })
        .catch((e) => {
          let message
          if (!isHTML(e.request.response)) {
            switch (JSON.parse(e.request.response).message) {
              case 'Auth token expired, please request a new access code.':
                message =
                  'O token de autenticação expirou, solicite um novo código de acesso.'
                break
              case 'You have exceeded attempt limit. For security reasons, you are temporarily blocked. Please wait 60 seconds and request a new access code.':
                message =
                  'Você excedeu o limite de tentativas. Por motivos de segurança, você está temporariamente bloqueado. Por favor, aguarde 60 segundos e solicite um novo código de acesso.'
                break
              case 'Attempt limit reached. Try again later.':
                message =
                  'Limite de tentativas atingido. Tente novamente mais tarde.'
                break
              case 'Unauthenticated User':
                message = 'Senha ou código de autenticação incorretos'
                break
              default:
                message = JSON.parse(e.request.response).message
            }
          }
          dispatch(PostAuthError(message))
        })
    },
    [dispatch]
  )

  return {
    request,
    response,
    isLoading,
    hasError,
    errorMessage,
    postAuth,
  }
}

export default usePostAuth
