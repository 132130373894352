

import React, { useEffect, useState } from "react"
import {
  post,
  baseURLPrepayments,
} from 'services/api'
import {
  isNotEmpty,
  isValidEmail,
  isValidCpf
} from 'helpers/validators'
import {
  formatMoney,
  applyCpfCnpjMask,
  applyPhoneMask,
} from 'helpers/masks'
import {
  getValueFromLocalStorage, setValueToLocalStorage,
} from 'helpers/localStorage'
import { showToast } from 'components/Toast'
import useGetStores from 'hooks/useGetStores'
import useGetUser from 'hooks/useGetUser'
import { useNonInitialEffect } from 'hooks/utils'
import * as s from './styles'
import qflashTerms from 'assets/files/qflash_terms.pdf'
import { UserSessionInfo } from "store/modules/auth/types"

interface IProps {
  isOpen: string
  setCurrentStep: any
  handleClose: ()=> any
}


export const PrePaymentPreRegisterTwo: React.FC<IProps> = ({ isOpen, handleClose, setCurrentStep })=> {
  const formDefault = {
    cpf: { value: '', validator: isValidCpf, touched: false},
    cnpj: { value: '', validator: isNotEmpty, touched: false},
    email: { value: '', validator: isValidEmail, touched: false},
    phone: { value: '', validator: isNotEmpty, touched: false},
    annual_billing: { value: '', validator: isNotEmpty, touched: false},
    checkbox: { value: false, validator: isNotEmpty, touched: false},
    

  }
  const [ form, setForm ] = useState(formDefault)
  const [ formErrors, setFormErrors ] = useState<any>(
    Object.keys(formDefault).reduce((acc, cur) => (
      { ...acc, [cur]: ''}
    ), {})
  )
  const [cnpjSelectList, setCnpjSelectList] = useState([])
  const [selectedCnpj, setSelectedCnpj] = useState({id: '', name: ''})
  const { stores, loadStores } = useGetStores()
  const { user, loadUser } = useGetUser()

  const [
    isLoading,
    setIsLoading,
  ] = React.useState<boolean>(false)

  useEffect(() => {
    if(isOpen) {
      const currentUserSession: UserSessionInfo = JSON.parse(getValueFromLocalStorage('currentUserSession'))
      loadUser(currentUserSession.id)
    }
  }, [])

  useEffect(() => {
    _setForm({ 
      cpf: applyCpfCnpjMask(user.cpf), 
      phone: applyPhoneMask(user.phone),
      email: user.email,
      })
  }, [user])

  useEffect(() => {
    if (stores.data.length > 0) {
      const _list = stores.data.filter((x) => {
        if(x.cnpj_cpf.length > 11) return true;
        return false;
      }).map((x) => {
        return {id: x.cnpj_cpf, text: applyCpfCnpjMask(x.cnpj_cpf), onClick: () => {
          setSelectedCnpj({id: x.cnpj_cpf, name: applyCpfCnpjMask(x.cnpj_cpf)})
        }}
      })

      setCnpjSelectList(_list)
    }
  }, [stores])

  useEffect(() => {
    _setForm({ cnpj: selectedCnpj.id })
  }, [selectedCnpj])

  useNonInitialEffect(() => {
    _validateForm()
  }, [form])

  const _setForm = (obj) => {
    const formObj = Object.entries(obj).reduce((acc, cur) => {
      const [ key, value ] = cur
      return {
        ...acc,
        [key]: {
          value: value,
          validator: form[key].validator,
          touched: true,
        }
      }
    }, {})
    setForm({...form, ...formObj})
  }

  const _validateForm = (force = false) => {
    const invalidFields = Object.entries(form).reduce((acc, cur) => {
      const [ key, value ] = cur
      if(key === 'cpf' && user.cpf) {
        return {
          ...acc,
          [key]: '',
        }
      }
      if (value.validator &&
        (value.touched || force) &&
        !value.validator(value.value+'')) {
        return {
          ...acc,
          [key]: 'Campo obrigatório',
        }
      }
      return {
        ...acc,
        [key]: '',
      }
    }, {})
    
    setFormErrors({...formErrors, ...invalidFields})
    if (invalidFields && Object.values(invalidFields).every(x => !x)) return true
    return false;
  }

  const onFormSubmit = () => {
    setIsLoading(true)
    if(_validateForm(true)) {
      
      const _form = {
        annual_billing: parseInt(form.annual_billing.value.replace(/\D/g, '')),
        cnpj: form.cnpj.value,
        cpf: form.cpf.value.replace(/\D/g, ''),
        email: form.email.value,
        phone: form.phone.value.replace(/\D/g, ''),
      }
      
      post(`${baseURLPrepayments}/register`, {
        ..._form,
      }).then((r) => {
        setIsLoading(false)
        setCurrentStep(5)
        setValueToLocalStorage('hasAntecipationInProgress', 'true')
      }).catch(err => {
        console.log(err)
        setIsLoading(false)
        let errorMsg = 'Problema ao pré-processar cadastro'
        if(err.response) {
          errorMsg = JSON.stringify(JSON.parse(err.response.data.message).errors[0]).slice(1, -1);
        }
        showToast({
          type: 'error',
          message: errorMsg,
        })
      })
    }
    
  }

  return(
    <s.Modal 
      handleClose={handleClose}
      isOpen={(isOpen) ? true : false}
      modalSubtitle={"Simular taxa de antecipação"}>
      <s.BackBtn>
        <s.Icon onClick={(x) =>{setCurrentStep(3)}} name="backbtn" fill="white" />
      </s.BackBtn>   

      {/* <s.Line/> */}
      <s.ModalContent>
      <s.FormWrapper>
        <s.InputGroup>
          <s.Select
            label="Selecione o CNPJ da Loja"
            options={cnpjSelectList}
            selected={selectedCnpj}
            error={formErrors.cnpj}/>
        </s.InputGroup>
        <s.InputGroup>
          <s.InputText
            label="Faturamento anual"
            value={form.annual_billing.value}
            maxLength={30}
            onChange={(e) => _setForm({ 
              annual_billing: formatMoney(e.target.value),
            })}
            error={formErrors.annual_billing}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputText
            label="CPF"
            value={form.cpf.value}
            maxLength={14}
            onChange={(e) => _setForm({ 
              cpf: applyCpfCnpjMask(e.target.value),
            })}
            error={formErrors.cpf}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputText
            label="E-mail"
            value={form.email.value}
            maxLength={100}
            onChange={(e) => _setForm({ email: e.target.value })}
            error={formErrors.email}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputText
            label="Telefone"
            value={form.phone.value}
            onChange={(e) => _setForm({ 
              phone: applyPhoneMask(e.target.value),
            })}
            error={formErrors.phone}
          />
        </s.InputGroup>


        <s.InputGroup>
          <s.CheckboxWrapper>
            <div>
              <s.Checkbox
                onClick={(x) => {
                }}
                id={'agreementCheckbox'}
                checked={(form.checkbox.value ? true : false)}
                onChange={(e)=> _setForm({checkbox: !form.checkbox.value})}
              />
            </div>
            <s.Text textAlign="unset" color="maincolor">
              Concordo com os<s.ButtonLink href={qflashTerms} target="_blank">Termos e Condições de Uso do Quasar Flash Fomento Mercantil</s.ButtonLink>
              , parceiro responsável pela operação da Antecipação de Recebíveis
            </s.Text>
          </s.CheckboxWrapper>
        </s.InputGroup>

        {/* <s.Line/> */}
      </s.FormWrapper>
      <s.Button 
        disabled={!form.checkbox.value}
        onClick={()=> onFormSubmit()}  
        color="primary">
        {isLoading ? (
          <s.Loading
            type="spinner"
            data-testid="loading-spinner1"
            color="white"
          />
        ) : (
          <>
            <s.Text bold color="white">
              Continuar Cadastro
              <s.ButtonIcon name="arrowaltright" fill="maincolor" />
            </s.Text>
          </>
        )}

      </s.Button>
      <s.Button onClick={()=> setCurrentStep(3)}
        color="secondary" outline>
        <s.Text bold color="maincolor">
          Voltar
        </s.Text>
      </s.Button>
      </s.ModalContent>
    </s.Modal>
  )  
}



