import React, { createRef, forwardRef, InputHTMLAttributes } from 'react'
import { dict } from 'components/IconExporter'

import * as s from '../styles'

interface IProps {
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  error?: boolean | { icon?: keyof typeof dict; message: string }
  disabled?: boolean
  width?: number | string
  height?: number
  label?: string
  name?: string
  placeholder?: string
  maxLength?: number
  type?: string
}

const InputText = forwardRef<
  HTMLInputElement,
  IProps & Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'>
>(
  (
    {
      prefix,
      suffix,
      error,
      width,
      height = 100,
      maxLength,
      value,
      name = '',
      label,
      placeholder,
      disabled,
      ...rest
    },
    ref
  ) => {
    const inputRef = (ref || createRef()) as React.RefObject<HTMLInputElement>

    return (
      <s.InputFieldGroup height={height} hasLabel={!!label} disabled={disabled}>
        <s.InputGroupWrapper disabled={disabled}>
          {label ? (
            <s.Text
              bold
              type="headline"
              color={error ? 'lightred1' : 'maincolor'}
            >
              {label}
            </s.Text>
          ) : null}
          <s.InputWrapper
            error={error}
            height={height}
            width={width}
            disabled={disabled}
            onClick={() => {
              inputRef && inputRef.current && inputRef.current.focus()
            }}
            {...rest}
          >
            {prefix && <s.InputAddon id="prefix">{prefix}</s.InputAddon>}
            <s.InputArea
              maxLength={maxLength}
              name={name}
              disabled={disabled}
              value={value}
              placeholder={placeholder}
              data-testid={label?.toLowerCase()}
            />
            {suffix && <s.InputAddon id="suffix">{suffix}</s.InputAddon>}
          </s.InputWrapper>
        </s.InputGroupWrapper>
        {error && typeof error === 'object' && (
          <s.ErrorMessage>
            {error.icon ? <s.ErrorIcon name={error.icon} width="16px" /> : null}
            <s.TextError color="lightred1">{error.message}</s.TextError>
          </s.ErrorMessage>
        )}
      </s.InputFieldGroup>
    )
  }
)

export default InputText
