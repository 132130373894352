import React from 'react'

import { baseURLPublishPubSagaStore, post } from 'services/api'

import useStoreWallets from 'hooks/useStoreWallets'

import { showToast } from 'components/Toast'
import { WalletsSettingsList } from 'store/modules/walletSettings/types'

import * as s from './styles'

interface IProps {
  isOpen: boolean
  handleClose: () => any
  walletSettings: WalletsSettingsList[]
  storeId: string
  displayStoreWallets: any
}

const ImpersonateWalletModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  walletSettings,
  storeId,
  displayStoreWallets,
}) => {
  const {
    loadStoreWallets,
    postStoreWallet,
    postHasError,
    postIsLoading,
  } = useStoreWallets()
  const [selected, setSelected] = React.useState({ id: '0', name: '' })
  const [options, setOptions] = React.useState([])

  const postOnSagaStore = async (data: {
    wallet_setting_id: string
    store_id: string
  }) => {
    await post(baseURLPublishPubSagaStore, data)
      .then(() => {
        showToast({
          type: 'success',
          message: 'Carteira associada com sucesso',
        })
        loadStoreWallets(storeId)
        handleClose()
      })
      .catch((e) => {
        if (
          displayStoreWallets.findIndex(
            (wallet) => wallet.wallet_setting_id === data.wallet_setting_id
          ) !== -1
        ) {
          showToast({
            type: 'error',
            message: `Essa carteira já foi associada!`,
          })
        }
        showToast({
          type: 'error',
          message: `${JSON.parse(e.request.response)}`,
        })
      })
  }

  React.useEffect(() => {
    if (walletSettings.length > 0) {
      let auxOptions = walletSettings
        .filter((wallet) => wallet.active != false)
        .map((wallet) => {
          return {
            id: wallet.id,
            text: `${wallet.wallet_name} - ${wallet.name}`,
            onClick: () => {
              setSelected({
                id: wallet.id,
                name: `${wallet.wallet_name} - ${wallet.name}`,
              })
            },
          }
        })
      setOptions(auxOptions)
    }
  }, [walletSettings])

  return (
    <s.Modal
      placeModalOnCenter
      noChildrenPadding
      modalSubtitle="Associar carteira"
      handleClose={handleClose}
      isOpen={isOpen}
    >
      <s.ModalContainer>
        <s.Select optionBoxSize={200} selected={selected} options={options} />

        <s.Button
          color="maincolor"
          onClick={() => {
            const selectedWalletSetting = walletSettings.find(
              (wallet) => wallet.id === selected.id
            )
            if (
              selectedWalletSetting.psp_provider === 'mercadopago' ||
              selectedWalletSetting.wallet_name === 'mercadopago'
            ) {
              postOnSagaStore({
                store_id: storeId,
                wallet_setting_id: selectedWalletSetting.id,
              })
            } else {
              postStoreWallet({
                store_id: storeId,
                wallet_name: selectedWalletSetting.wallet_name,
                wallet_setting_id: selectedWalletSetting.id,
                wallet_setting_name: selectedWalletSetting.name,
              }).then(() => {
                loadStoreWallets(storeId)
                handleClose()
              })
            }
          }}
        >
          Associar
        </s.Button>
        {postIsLoading ? <s.Loading type="spinner" color="maincolor" /> : null}
      </s.ModalContainer>
    </s.Modal>
  )
}

export default ImpersonateWalletModal
