import { useState, useEffect } from 'react'

import { getStates } from 'helpers/geo'

import {
  applyCpfCnpjMask,
  applyNumberOnlyMask,
  applyCEPMask,
} from 'helpers/masks'
import { isNotEmpty, isValidCpfCnpj } from 'helpers/validators'
import { useNonInitialEffect } from 'hooks/utils'
import { useGetGeoAddress, useGetGeoCities } from 'hooks/geo'

import * as s from './styles'

interface IProps {
  onSubmit: (formData: any) => void
  submit: boolean
  clearForm: boolean
}

const StoreForm: React.FC<IProps> = ({ submit, onSubmit, clearForm }) => {
  const formDefault = {
    store_cnpj_cpf: { value: '', validator: isValidCpfCnpj, touched: false },
    store_name: { value: '', validator: isNotEmpty, touched: false },
    store_pos_names: { value: '', validator: isNotEmpty, touched: false },
    store_postal_code: { value: '', validator: isNotEmpty, touched: false },
    store_street: { value: '', validator: isNotEmpty, touched: false },
    store_street_number: { value: '', validator: isNotEmpty, touched: false },
    store_reference: { value: '', validator: null, touched: false },
    store_neighborhood: { value: '', validator: isNotEmpty, touched: false },
    store_city: { value: '', validator: isNotEmpty, touched: false },
    store_state: { value: '', validator: isNotEmpty, touched: false },
  }
  const [form, setForm] = useState(formDefault)
  const [formErrors, setFormErrors] = useState<any>(
    Object.keys(formDefault).reduce((acc, cur) => ({ ...acc, [cur]: '' }), {})
  )
  const [selectedState, setSelectedState] = useState<any>({})
  const [stateSelectList, setStateSelectlist] = useState<any[]>([])
  const [citiesSelectList, setCitiesSelectList] = useState<
    { id: string; text: string; onClick: () => void }[]
  >([])
  const [selectedCity, setSelectedCity] = useState<{
    name: string
    id: string
  }>({ name: '', id: '-1' })
  const { address, loadGeoAddress } = useGetGeoAddress()
  const { cities, isLoading, loadGeoCities } = useGetGeoCities()

  useNonInitialEffect(() => {
    if (_validateFormForce()) onSubmit(form)
    else onSubmit(null)
  }, [submit])

  useNonInitialEffect(() => {
    setForm(formDefault)
    setSelectedCity({ name: '', id: '-1' })
    setSelectedState({ name: '', id: '-1' })
  }, [clearForm])

  useNonInitialEffect(() => {
    setCitiesSelectList(
      cities.map((city, index) => {
        return {
          text: city,
          id: `${index}`,
          onClick: () => {
            setSelectedCity({ name: city, id: `${index}` })
          },
        }
      })
    )
    if (cities.length <= 0) {
      setSelectedCity({ name: '', id: '-1' })
    }
  }, [cities])

  useEffect(() => {
    setStateSelectlist(
      getStates().map((s) => {
        return {
          id: s.id,
          text: s.text,
          onClick: () => {
            loadGeoCities(s.text)
            setSelectedState({ id: s.id, name: s.text })
          },
        }
      })
    )
  }, [])

  useNonInitialEffect(() => {
    if (form.store_postal_code.value.length > 8) {
      loadGeoAddress(form.store_postal_code.value.replace(/\D/g, ''))
    }
  }, [form.store_postal_code.value])

  useNonInitialEffect(() => {
    _setForm({
      store_city: address.city,
      store_neighborhood: address.neighborhood,
      store_state: address.state,
      store_street: address.street,
    })
    const cityId = citiesSelectList.find((x) => x.text === address.city)?.id
    const stateId = stateSelectList.find((x) => x.text === address.state)?.id
    setSelectedCity({ name: address.city, id: cityId })
    setSelectedState({ id: stateId, name: address.state })
  }, [address])

  useNonInitialEffect(() => {
    _validateForm()
  }, [form])

  useNonInitialEffect(() => {
    _setForm({ store_state: selectedState.name })
  }, [selectedState])

  useNonInitialEffect(() => {
    _setForm({ store_city: selectedCity.name })
  }, [selectedCity])

  const _validateForm = () => {
    const invalidFields = Object.entries(form).reduce((acc, cur) => {
      const [key, value] = cur
      if (value.touched && value.validator && !value.validator(value.value)) {
        return {
          ...acc,
          [key]: 'Campo obrigatório',
        }
      }
      return {
        ...acc,
        [key]: '',
      }
    }, {})

    setFormErrors({ ...formErrors, ...invalidFields })
    if (invalidFields && Object.values(invalidFields).every((x) => !x))
      return true
    return false
  }

  const _validateFormForce = () => {
    const invalidFields = Object.entries(form).reduce((acc, cur) => {
      const [key, value] = cur
      if (value.validator && !value.validator(value.value)) {
        return {
          ...acc,
          [key]: ' ',
        }
      }
      return {
        ...acc,
        [key]: '',
      }
    }, {})

    setFormErrors({ ...formErrors, ...invalidFields })
    if (invalidFields && Object.values(invalidFields).every((x) => !x))
      return true
    return false
  }

  const _setForm = (obj) => {
    const formObj = Object.entries(obj).reduce((acc, cur) => {
      const [key, value] = cur
      return {
        ...acc,
        [key]: {
          value: value,
          validator: form[key].validator,
          touched: true,
        },
      }
    }, {})
    setForm({ ...form, ...formObj })
  }

  return (
    <s.FormSection>
      <s.FormSectionTitle type="headline" bold color="black">
        Loja
      </s.FormSectionTitle>
      <s.Grid3>
        <s.InputGroup>
          <s.InputText
            label="CNPJ/CPF"
            value={form.store_cnpj_cpf.value}
            placeholder="00.000.000/0000-00"
            onChange={(e) =>
              _setForm({ store_cnpj_cpf: applyCpfCnpjMask(e.target.value) })
            }
            error={
              formErrors.store_cnpj_cpf
                ? { message: formErrors.store_cnpj_cpf }
                : false
            }
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputText
            label="Nome da Loja"
            value={form.store_name.value}
            onChange={(e) => _setForm({ store_name: e.target.value })}
            error={
              formErrors.store_name ? { message: formErrors.store_name } : false
            }
          />
        </s.InputGroup>
        <s.InputGroupStorePos>
          <s.InfoWrapper>
            <s.Text type="headline" bold color="maincolor">
              Caixas
            </s.Text>
            <s.Icon name="infooutline" />
            <s.TooltipText>É permitido colocar de 1 à 30 caixas</s.TooltipText>
          </s.InfoWrapper>
          <s.InputTextStorePos
            value={form.store_pos_names.value}
            onChange={(e) => {
              let nString = applyNumberOnlyMask(e.target.value)
              if (!nString) nString = ''
              else if (parseInt(nString) > 30) nString = '30'
              _setForm({ store_pos_names: nString })
            }}
            error={
              formErrors.store_pos_names
                ? { message: formErrors.store_pos_names }
                : false
            }
          />
        </s.InputGroupStorePos>
        <s.InputGroup>
          <s.InputText
            label="CEP"
            value={form.store_postal_code.value}
            onChange={(e) =>
              _setForm({ store_postal_code: applyCEPMask(e.target.value) })
            }
            placeholder="00000-000"
            error={
              formErrors.store_postal_code
                ? { message: formErrors.store_postal_code }
                : false
            }
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputText
            label="Endereço"
            value={form.store_street.value}
            onChange={(e) => _setForm({ store_street: e.target.value })}
            error={
              formErrors.store_street
                ? { message: formErrors.store_street }
                : false
            }
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputText
            label="Número"
            value={form.store_street_number.value}
            onChange={(e) => _setForm({ store_street_number: e.target.value })}
            error={
              formErrors.store_street_number
                ? { message: formErrors.store_street_number }
                : false
            }
          />
        </s.InputGroup>
      </s.Grid3>
      <s.Grid4>
        <s.InputGroup>
          <s.InputText
            label="Complemento"
            value={form.store_reference.value}
            onChange={(e) => _setForm({ store_reference: e.target.value })}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputText
            label="Bairro"
            value={form.store_neighborhood.value}
            onChange={(e) => _setForm({ store_neighborhood: e.target.value })}
            error={
              formErrors.store_neighborhood
                ? { message: formErrors.store_neighborhood }
                : false
            }
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.Text bold color="maincolor" type="headline">
            Cidade
          </s.Text>
          <s.Select
            isLoading={isLoading}
            optionBoxDirection="top"
            disabled={cities.length <= 0}
            options={citiesSelectList}
            selected={selectedCity}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.Text bold color="maincolor" type="headline">
            Estado
          </s.Text>
          <s.Select
            optionBoxDirection="top"
            error={
              formErrors.store_state
                ? { message: formErrors.store_state }
                : false
            }
            options={stateSelectList}
            selected={selectedState}
          />
        </s.InputGroup>
      </s.Grid4>
    </s.FormSection>
  )
}

export default StoreForm
