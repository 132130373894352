import React from 'react'
import loadingbigger from 'assets/images/loading-bigger.png'
import * as s from './styles'

const Loading: React.FC = () => {
  return (
    <s.Sections  data-testid="content-on-loading">
      <s.LoadingWrapper>
        <s.LoadingGradient>
          <img width={110} src={loadingbigger} />
        </s.LoadingGradient>
      </s.LoadingWrapper>
    </s.Sections>
  )
}

export default Loading
