import React from 'react'
import { getYesterday, parseDMY } from 'helpers/date'
import useWalletsSettings from 'hooks/useWalletsSettings'
import { formatMoney, unformatMoney } from 'helpers/masks'

import * as s from './styles'

export interface IForwardProps {
  callback: (payload: object) => void
}

const ReconciliationSelector: React.FC<IForwardProps> = ({ callback }) => {
  const { walletSettings, loadWalletsSettings } = useWalletsSettings()

  const mappedWalletSettings = walletSettings.data.map((x) => {
    return {
      id: x.id,
      text: x.name,
      icon: x.wallet_icon,
      onClick: () => {
        setSelectedWallet({ id: x.id, name: x.name })
      },
    }
  })

  const [selectedWallet, setSelectedWallet] = React.useState({
    id: '',
    name: 'Selecione',
  })

  const [conciliationForm, setConciliationForm] = React.useState({
    start_date: getYesterday(),
    end_date: getYesterday(),
    consolidated_customer_value: '',
  })

  const _setConciliationForm = (obj) => {
    setConciliationForm({ ...conciliationForm, ...obj })
  }

  const payload = {
    wallet_setting_id: selectedWallet.id,
    start_date:
      parseDMY(conciliationForm.start_date).toISOString().split('T')[0] +
      'T00:00:00',
    end_date:
      parseDMY(conciliationForm.end_date).toISOString().split('T')[0] +
      'T23:59:59',
    consolidated_customer_value: unformatMoney(
      conciliationForm.consolidated_customer_value
    ),
  }

  React.useEffect(() => {
    loadWalletsSettings({
      limit: '500',
      offset: '0',
      filters: window.btoa(
        decodeURIComponent(
          encodeURIComponent(JSON.stringify({ wallet_type: ['pix'] }))
        )
      ),
    })
  }, [])

  return (
    <s.Card>
      <s.OneColGrid>
        <s.Select
          label="Conta Pix"
          options={mappedWalletSettings}
          selected={selectedWallet}
        />

        <s.Datepicker
          label="Data Inicial"
          width="290px"
          data-testid="datepicker-startdate"
          selected={conciliationForm.start_date}
          onChange={(date: Date) => {
            if (date) {
              callback({})
              _setConciliationForm({
                start_date: date.toLocaleDateString('pt-BR'),
                end_date: date.toLocaleDateString('pt-BR'),
              })
            }
          }}
          placeholder="Final"
          type="rounded"
        />
        <s.Datepicker
          label="Data Final"
          width="290px"
          selected={conciliationForm.end_date}
          placeholder="Início"
          onChange={(date: Date) => {
            callback({})
            if (date) {
              _setConciliationForm({
                end_date: date.toLocaleDateString('pt-BR'),
              })
            }
          }}
          type="rounded"
        ></s.Datepicker>

        <s.InputText
          label="Valor Consolidado"
          value={formatMoney(conciliationForm.consolidated_customer_value)}
          onChange={(e) => {
            _setConciliationForm({
              consolidated_customer_value: formatMoney(e.target.value),
            })
          }}
        />

        <div></div>
        <s.Button
          onClick={() => {
            callback(payload)
          }}
        >
          <s.Text color="white" bold>
            Conciliar
          </s.Text>
        </s.Button>
      </s.OneColGrid>
    </s.Card>
  )
}

export default ReconciliationSelector
