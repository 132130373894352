import styled from 'styled-components'

import ModalCmp from 'components/Modal'
import { ChildrenWrapper, ModalContainer } from 'components/Modal/styles'
import TextCmp from 'components/Text'
import ButtonCmp from 'components/Button'
import IconCmp from 'components/IconExporter'

export const Modal = styled(ModalCmp)`
  ${ModalContainer} {
    padding: 0px;
  }

  ${ChildrenWrapper} {
    padding: 0px;
    overflow-y: hidden;

    margin-right: 0px;

    ::-webkit-scrollbar-track {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
`

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 300px;
  width: 500px;
`

export const SucessWrapper = styled.div`
  overflow: hidden;
  padding: 32px;
`

export const Text = styled(TextCmp)``

export const PreviousNextButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  background: white;
  padding: 32px;

  div:first-child {
    width: calc(100%+32px);
    height: 1px;
    margin: 0px -32px 32px -32px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
  }
`

export const PreviousNextButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`

export const Button = styled(ButtonCmp)`
  width: 100%;
  box-shadow: 0px 1px 4px #b3b3b3;
  svg {
    margin-right: 8px;
  }
`

export const Icon = styled(IconCmp)`
  display: inline;
`
