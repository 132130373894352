import styled, { css } from 'styled-components'
import { themeColors } from 'styles/theme'
import IconCmp from 'components/IconExporter'

const blueShadow = css`
  box-shadow: 0px 1px 8px 2px rgba(92, 170, 229, 0.3);

  :hover {
    box-shadow: 0px 1px 8px 3px rgba(92, 170, 229, 0.5);
  }

  :active {
    box-shadow: maincolor;
  }
`

const grayShadow = css`
  box-shadow: ${({ theme }) => theme.assets.buttonComponent.shadow};
`

// functions
const handleBgGradient = (
  colors: { [key in keyof typeof themeColors]: string },
  type:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'hollow'
    | 'whitedetailed'
    | string,
  isDisabled: boolean = false
) => {
  if (isDisabled && type !== 'hollow') {
    return colors.graytheme4
  }
  switch (type) {
    case 'primary':
      return colors.maincolor
    case 'secondary':
      return colors.white
    case 'whitedetailed':
      return colors.white
    case 'hollow':
      return colors.transparent
    default:
      return colors[`${type}`]
  }
}

const handleColor = (
  colors: { [key in keyof typeof themeColors]: string },
  type: 'primary' | 'secondary' | 'tertiary' | 'whitedetailed' | string,
  isDisabled: boolean = false,
  isOutline: boolean
) => {
  if (isOutline && isDisabled) {
    return colors.white
  }

  if (type === 'whitedetailed') {
    return colors.maincolor
  }

  if (!isOutline) {
    return colors.white
  }

  return colors.maincolor
}

const handleBorder = (
  colors: { [key in keyof typeof themeColors]: string },
  isDisabled: boolean = false,
  isOutline: boolean
) => {
  if (!isOutline) return 'none'

  let borderColor = colors.maincolor

  return `2px solid ${!isDisabled ? borderColor : colors.graytheme4}`
}

interface IButtonProps {
  color: 'primary' | 'secondary' | 'tertiary' | 'whitedetailed' | string
  disabled?: boolean
  width?: string
  outline?: boolean
  height?: string
}

export const Button = styled.button<IButtonProps>`
  border: 0px;
  background: ${(props) =>
    handleBgGradient(props.theme.colors, props.color, props.disabled)};
  ${({ color }) => (color === 'whitedetailed' ? grayShadow : null)}
  background-repeat: no-repeat;
  border: ${(props) =>
    handleBorder(props.theme.colors, props.disabled, props.outline)};
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-block;
  color: ${(props) =>
    handleColor(
      props.theme.colors,
      props.color,
      props.disabled,
      props.outline
    )};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  overflow: hidden;
  position: relative;
  text-decoration: none;

  &:hover {
    &:active {
      pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
    }

    &:after {
      ${({ color }) =>
        color === 'hollow'
          ? css``
          : css`
              background-color: ${({ theme }) => theme.colors.black};
              background-size: 100%;
              opacity: 0.2;
            `}
    }

    ${(props) =>
      !props.disabled &&
      `
      &:before {
        width: 100%;
      }
    `}
  }

  &:before {
    ${({ color }) =>
      color === 'hollow'
        ? css``
        : css`
            background-color: ${({ theme }) => theme.colors.black};
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            opacity: 0.05;
            top: 0;
            width: 0;
          `}
  }
`
interface IContent {
  noPadding: boolean
}

export const Content = styled.span<IContent>`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: auto;
  height: 100%;
  ${({ noPadding }) =>
    noPadding
      ? css`
          padding: 0;
        `
      : css`
          padding: 0 ${({ theme }) => theme.spacing(1)}px;
        `}

  ${(props) => props.theme.fontTypes.button};
`

export const Icon = styled(IconCmp)``
