import { useState } from 'react'

import cryptoService from 'services/crypto'

interface IUseCrypto {
  patchCryptoValidade: (zipcode: string) => Promise<void>
  response: any
  isLoading: boolean
}

export const useCrypto = (): IUseCrypto => {
  const [response, setResponse] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const patchCryptoValidade = async (uuid: string) => {
    setIsLoading(true)
    const response = await cryptoService.patchWalletSettingsCryptoValidade(uuid)
    setResponse(response.data)
    setIsLoading(false)
  }
  return { patchCryptoValidade, response, isLoading }
}
