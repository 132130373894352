import React from 'react'
import { useDispatch } from 'react-redux'

import { get, baseURLStores } from '../services/api'
import { useTypedSelector } from '../store/modules/rootState'
import {
  GetStores,
  GetStoreIsLoading,
  GetStoresError,
} from 'store/modules/stores/actions'

import { StoresState } from 'store/modules/stores/types'

interface UseGetStores extends StoresState {
  loadStores: (params?: {
    filterby?: string
    column_name?: string
    limit: string
    offset: string
    active: boolean
  }) => Promise<void>
}

const useGetStores = (): UseGetStores => {
  const dispatch = useDispatch()

  const { stores, isLoading, hasError } = useTypedSelector((store) => ({
    stores: store.stores.stores,
    isLoading: store.stores.isLoading,
    hasError: store.stores.hasError,
  }))

  const loadStores = React.useCallback(
    async (params): Promise<void> => {
      dispatch(GetStoreIsLoading())
      await get(baseURLStores, { params: params || '' })
        .then((response) => {
          dispatch(GetStores(response.data))
        })
        .catch(() => {
          dispatch(GetStoresError())
        })
    },
    [dispatch]
  )

  return { stores, hasError, isLoading, loadStores }
}

export default useGetStores
