import Modal from 'components/Modal'
import * as s from '../styles'
import { usePostCertificate } from 'hooks/clientCert'
import React from 'react'
import { isNotEmpty } from 'helpers/validators'
import { showToast } from 'components/Toast'
import { useNonInitialEffect } from 'hooks/utils'
import useGetSystemWallets from 'hooks/useGetSystemWallets'

interface IProps {
  isOpen: boolean
  handleClose: () => any
}

export const NewCertificateModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
}) => {
  const { createCertificate } = usePostCertificate()
  const [selectedPspProvider, setSelectedPspProvider] = React.useState<any>({})
  const [filteredSystemWallets, setFilteredSystemWallets] = React.useState<
    any[]
  >([])

  const { systemWallets, loadSystemWallets } = useGetSystemWallets()

  React.useEffect(() => {
    if (isOpen) {
      loadSystemWallets()
    }
  }, [isOpen])

  const formDefault = {
    ca: '',
    cert: '',
    description: '',
    name: '',
    password: '',
    priv: '',
    status: 'ready',
    psp_provider: '',
  }

  const formDefaultErrors = {
    ca: '',
    cert: '',
    description: '',
    name: '',
    password: '',
    priv: '',
    status: '',
    psp_provider: '',
  }

  const [newCertForm, setNewCertForm] = React.useState({
    ca: '',
    cert: '',
    description: '',
    name: '',
    password: '',
    priv: '',
    status: 'ready',
    psp_provider: '',
  })

  const [newCertFormErrors, setNewCertFormErrors] = React.useState({
    ca: '',
    cert: '',
    description: '',
    name: '',
    password: '',
    priv: '',
    status: '',
    psp_provider: '',
  })

  const required = {
    ca: false,
    cert: true,
    description: true,
    name: true,
    password: false,
    priv: true,
    status: true,
    psp_provider: true,
  }

  const translation = {
    ca: 'arquivo de CA',
    cert: 'certificado',
    description: 'descrição',
    name: 'nome',
    password: 'senha',
    priv: 'chave privada',
    psp_provider: 'provedor PSP',
  }

  const handlePost = async () => {
    const err = { ...newCertFormErrors }
    for (const [key, value] of Object.entries(newCertForm)) {
      if (!err[key])
        err[key] = isNotEmpty(value)
          ? ''
          : required[key]
          ? translation[key]
          : ''
    }

    setNewCertFormErrors(err)
    const errList = Object.values(err).filter((x) => x !== '')

    if (!errList || errList.length === 0) {
      await createCertificate(newCertForm)
      handleClose()
      _clearForm()
    } else {
      showToast({
        type: 'error',
        message: `os campos:  ${errList} são obrigatórios!`,
      })
    }
  }

  const _handleClose = () => {
    _clearForm()
    handleClose()
  }

  const _clearForm = () => {
    setNewCertForm(formDefault)
    setNewCertFormErrors(formDefaultErrors)
    setSelectedPspProvider('')
  }

  const _setNewCertForm = (obj) => {
    setNewCertForm({ ...newCertForm, ...obj })
    Object.keys(obj).forEach((key) => {
      obj[key] = ''
      setNewCertFormErrors({ ...newCertFormErrors, ...obj })
    })
  }

  useNonInitialEffect(() => {
    setFilteredSystemWallets(
      systemWallets
        .filter((x) => {
          return x.active && x.wallet_type === 'pix'
        })
        .map((x) => {
          return {
            id: x.id,
            text: x.wallet_name,
            onClick: () => {
              setSelectedPspProvider({
                id: x.id,
                name: x.wallet_name,
              })
            },
          }
        })
    )
  }, [systemWallets])

  useNonInitialEffect(() => {
    _setNewCertForm({ psp_provider: selectedPspProvider.name })
  }, [selectedPspProvider])

  return (
    <s.EditModal
      handleClose={_handleClose}
      isOpen={isOpen}
      modalSubtitle={'Novo certificado digital'}
    >
      <s.ModalContent>
        <s.InputGroup>
          <s.Text bold color="maincolor" type="headline">
            Nome do certificado
          </s.Text>
          <s.InputTextArea
            value={newCertForm.name}
            onChange={(e) => {
              _setNewCertForm({ name: e.target.value })
            }}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.Text bold color="maincolor" type="headline">
            Provedor PSP
          </s.Text>
          <s.Select
            options={filteredSystemWallets}
            selected={selectedPspProvider}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.Text bold color="maincolor" type="headline">
            Descrição do certificado
          </s.Text>
          <s.InputTextArea
            value={newCertForm.description}
            onChange={(e) => {
              _setNewCertForm({ description: e.target.value })
            }}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.Text bold color="maincolor" type="headline">
            Conteúdo de chave privada
          </s.Text>
          <s.InputTextArea
            value={newCertForm.priv}
            onChange={(e) => {
              _setNewCertForm({ priv: e.target.value })
            }}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.Text bold color="maincolor" type="headline">
            Conteúdo do certificado público
          </s.Text>
          <s.InputTextArea
            value={newCertForm.cert}
            onChange={(e) => {
              _setNewCertForm({ cert: e.target.value })
            }}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.Text bold color="maincolor" type="headline">
            Conteúdo de CA intermediária (opcional)
          </s.Text>
          <s.InputTextArea
            value={newCertForm.ca}
            onChange={(e) => {
              _setNewCertForm({ ca: e.target.value })
            }}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.Text bold color="maincolor" type="headline">
            Senha da chave privada (opcional)
          </s.Text>
          <s.InputTextArea
            value={newCertForm.password}
            onChange={(e) => {
              _setNewCertForm({ password: e.target.value })
            }}
          />
        </s.InputGroup>
        <s.ButtonGroup>
          <s.Button onClick={() => handlePost()}>Cadastrar</s.Button>
          <s.Button color="tertiary" onClick={() => _handleClose()}>
            Cancelar
          </s.Button>
        </s.ButtonGroup>
      </s.ModalContent>
    </s.EditModal>
  )
}
