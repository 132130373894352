import React from 'react'

import { reconciliationFormatMoney } from 'helpers/masks'
import { baseURLReconciliation, get } from 'services/api'

import * as s from './styles'

interface IProps {
  totalValue: string
  isOpen: boolean
  handleClose: () => any
  options: {
    start_date: string
    end_date: string
    wallet_setting_id: string
    consolidated_customer_value: string
  }
}

const RenderValue = ({ children }) => {
  return <s.Text>{reconciliationFormatMoney(children)}</s.Text>
}

const ReconciliationDelayedModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  options,
  totalValue,
}) => {
  const [delayedDetailsData, setDelayedDetailsData] = React.useState([])

  const getDelayedDetails = async (offset: number) => {
    await get(`${baseURLReconciliation}/notified-overdue/details`, {
      params: {
        ...options,
        limit: 500,
        offset: offset,
      },
    }).then((res) => {
      setDelayedDetailsData(res.data)
    })
  }

  React.useEffect(() => {
    if (options?.wallet_setting_id) {
      getDelayedDetails(0)
    }
  }, [options])

  return (
    <s.Modal
      modalSubtitle="Pagamentos notificados em atraso"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <s.ModalContainer>
        <s.Summary>
          <s.Column>
            <s.Text type="headline" color="maincolor" bold>
              Valor total
            </s.Text>
            <s.Text type="headline" color="maincolor">
              {totalValue}
            </s.Text>
          </s.Column>
          <s.Column>
            <s.Text type="headline" color="maincolor" bold>
              Quantidade
            </s.Text>
            <s.Text type="headline" color="maincolor">
              {delayedDetailsData.length}
            </s.Text>
          </s.Column>
        </s.Summary>
        <s.Table
          data={delayedDetailsData ? delayedDetailsData : []}
          isLoading={false}
          columns={[
            { headerName: 'Nome do Pagador', objectName: 'payer_name' },
            { headerName: 'CPF/CNPJ', objectName: 'payer_cpf_cnpj' },
            {
              headerName: 'PSP Pagador',
              objectName: 'payer_financial_institute_name',
            },
            {
              headerName: 'Valor',
              objectName: 'total_order',
              AltRender: RenderValue,
            },
            { headerName: 'Data/Hora Pago', objectName: 'updated_at' },
            {
              headerName: 'Data/Hora criação pedido',
              objectName: 'created_at',
            },
            { headerName: 'End to End', objectName: 'e2eid' },
            { headerName: 'Código transação Pix', objectName: 'order_id' },
          ]}
        />
      </s.ModalContainer>
    </s.Modal>
  )
}

export default ReconciliationDelayedModal
