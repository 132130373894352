import {
  Types,
  PixActionInterface,
  PixWSState,
} from './types'

const INITIAL_STATE: PixWSState = {
  walletSettings: {
    apelido: null,
    chave_pix: null,
    client_id: null,
    client_secret: null,
  },
  isLoading: false,
  hasError: false,
  postResponse: null,
  postIsLoading: false,
  postHasError: false,
  postHasErrorMessage: null
}

const pixWalletSettingsReducer = (
  state = INITIAL_STATE,
  action: PixActionInterface
): PixWSState=> {
  switch(action.type) {
    case Types.POST_PIX_WALLET_SETTINGS_REQUEST:
      return {
        ...state,
        postIsLoading: true,
        postHasError: false,
        postHasErrorMessage: null
      }
    case Types.POST_PIX_WALLET_SETTINGS_RESPONSE:
      return {
        ...state,
        postResponse: action.response,
        postIsLoading: false,
        postHasError: false,
        postHasErrorMessage: '',
      }
    case Types.POST_PIX_WALLET_SETTINGS_ERROR:
      return {
        ...state,
        postIsLoading: false,
        postHasError: true,
        postHasErrorMessage: action.message
      }
    default:
      return state
  }
}

export default pixWalletSettingsReducer
