import React from 'react'

import AmeConfigWallet from './ConfigWalletModalPerProvider/AmeConfigWallet'
import CieloConfigWallet from './ConfigWalletModalPerProvider/CieloConfigWallet'
import MercadoPagoConfigWallet from './ConfigWalletModalPerProvider/MercadoPagoConfigWallet'
import PagseguroConfigWallet from './ConfigWalletModalPerProvider/PagseguroConfigWallet'
import PicPayConfigWallet from './ConfigWalletModalPerProvider/PicPayConfigWallet'
import CoinPaymentsConfigWallet from './ConfigWalletModalPerProvider/CoinPaymentsConfigWallet'
import TecbanConfigWallet from './ConfigWalletModalPerProvider/TecbanConfigWallet'
import NinetyninepayConfigWallet from './ConfigWalletModalPerProvider/NinetyninepayConfigWallet'
import FoxbitConfigWallet from './ConfigWalletModalPerProvider/FoxbitConfigWallet'

import * as s from './styles'

interface IProps {
  isModalOpen: boolean
  handleClose: () => void
  wallet: string
  offset?: string
}

const ConfigWalletModal: React.FC<IProps> = ({
  isModalOpen,
  handleClose,
  wallet,
  offset,
}) => {
  const [title, setTitle] = React.useState('Configurar Carteira')
  const renderForm = () => {
    switch (wallet) {
      case 'cielo':
        return (
          <>
            <CieloConfigWallet
              handleClose={handleClose}
              isModalOpen={isModalOpen}
              offset={offset}
            />
          </>
        )
      case 'ame':
        return (
          <AmeConfigWallet
            handleClose={handleClose}
            isModalOpen={isModalOpen}
            offset={offset}
          />
        )
      case 'mercadopago':
        return (
          <MercadoPagoConfigWallet
            handleClose={handleClose}
            isModalOpen={isModalOpen}
            offset={offset}
          />
        )
      case 'pagseguro':
        return (
          <PagseguroConfigWallet
            handleClose={handleClose}
            isModalOpen={isModalOpen}
            offset={offset}
          />
        )
      case 'picpay':
        return (
          <PicPayConfigWallet
            handleClose={handleClose}
            isModalOpen={isModalOpen}
            offset={offset}
          />
        )
      case 'coinpayments':
        return (
          <CoinPaymentsConfigWallet
            handleClose={handleClose}
            isModalOpen={isModalOpen}
            setTitle={setTitle}
          />
        )
      case 'tecban':
        return (
          <TecbanConfigWallet
            handleClose={handleClose}
            isModalOpen={isModalOpen}
            offset={offset}
          />
        )
      case 'ninetyninepay':
        return (
          <NinetyninepayConfigWallet
            handleClose={handleClose}
            isModalOpen={isModalOpen}
            offset={offset}
          />
        )
      case 'foxbit':
        return (
          <FoxbitConfigWallet
            handleClose={handleClose}
            isModalOpen={isModalOpen}
            offset={offset}
          />
        )
    }
  }

  return (
    <s.Modal
      modalSubtitle={title}
      isOpen={isModalOpen}
      handleClose={handleClose}
      placeModalOnCenter
    >
      {renderForm()}
    </s.Modal>
  )
}

export default ConfigWalletModal
