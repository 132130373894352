import React from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../store/modules/rootState'

import { UpdateForm, ResetForm } from 'store/modules/form/action'

export interface IUseForm<T> {
  form: T
  updateForm(updatedKeys: T): void
}

export default function useForm<T>() {
  const form: T = useTypedSelector((state: any) => state.form.form)
  const dispatch = useDispatch()

  const updateForm = React.useCallback(
    (updatedKeys: T) => {
      dispatch(UpdateForm(updatedKeys))
    },
    [dispatch]
  )

  const resetForm = React.useCallback(() => {
    dispatch(ResetForm())
  }, [dispatch])

  return { form, updateForm, resetForm }
}
