import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'store/modules/rootState'

import registrationService, { CustomerRegistration } from 'services/registration'

import {
  PostCustomerRegistration,
  PostCustomerRegistrationIsLoading,
  PostCustomerRegistrationError,
} from 'store/modules/customerRegistration/action'

import { CustomerRegistrationState } from 'store/modules/customerRegistration/types'

interface UsePostCustomerRegistration extends CustomerRegistrationState {
  createCustomer: (customer: CustomerRegistration) => Promise<void>
}

export const usePostCustomerRegistration = (): UsePostCustomerRegistration => {
  const dispatch = useDispatch()
  const { response, isLoading, error } = useTypedSelector((store) => ({
    response: store.customerRegistration.response,
    isLoading: store.customerRegistration.isLoading,
    error: store.customerRegistration.error,
  }))

  const createCustomer = useCallback(async (user) => {
    dispatch(PostCustomerRegistrationIsLoading())
    await registrationService.createCustomer(user)
      .then((response) => {
        dispatch(PostCustomerRegistration(response.data))
      })
      .catch(() => {
        dispatch(PostCustomerRegistrationError())
      })
  }
  , [dispatch])

  return { response, isLoading, error, createCustomer}
}

