import React from 'react'

import {
  applyPhoneMask,
  applyCEPMask,
  formatMoney,
  applyCnpjMask,
  applyNumberOnlyMask,
} from 'helpers/masks'

import useGetGeoAddress from 'hooks/useGetGeoAddress'

import * as s from '../styles'
import { removeAccents } from 'helpers'
import { isNotEmpty } from 'helpers/validators'

const AnyBankItauCompanyForm: React.FC<{
  companyDocumentNumber?: string
  nextStep: () => void
  previousStep: () => void
  callCompanyValidationState?: [any, React.Dispatch<React.SetStateAction<any>>]
  isFrom: string
  pixFormState: [any, React.Dispatch<React.SetStateAction<any>>]
}> = ({
  companyDocumentNumber,
  nextStep,
  previousStep,
  callCompanyValidationState,
  isFrom,
  pixFormState,
}) => {
  const [form, setForm] = pixFormState
  const [isLoading, setIsLoading] = React.useState(false)
  const {
    geoAddress,
    isLoading: isGeoAddressLoading,
    hasError: hasGeoAddressError,
    loadGeoAddress,
    resetGeoAddress,
  } = useGetGeoAddress()

  const [company, setCompany] = React.useState({
    company_name: '',
    fantasy_name: '',
    document_number: companyDocumentNumber
      ? applyCnpjMask(companyDocumentNumber)
      : '',
    income_invoicing: '',
    phone: '',
    email: '',
    zip_code: '',
    street: '',
    number: '',
    complementary: '',
    neighborhood: '',
    city: '',
    state: '',
  })

  const [companyFormErrors, setCompanyFormErrors] = React.useState({
    company_name: '',
    fantasy_name: '',
    income_invoicing: '',
    phone: '',
    zip_code: '',
    street: '',
    number: '',
    email: '',
    neighborhood: '',
    city: '',
    state: '',
  })

  const [callCompanyValidation, setCallCompanyValidation] =
    callCompanyValidationState

  React.useEffect(() => {
    if (form?.company?.company_name) {
      setCompany({
        company_name: form.company.company_name,
        fantasy_name: form.company.fantasy_name,
        document_number: applyCnpjMask(companyDocumentNumber),
        income_invoicing: String(form.company.income_invoicing),
        phone: `(${
          form.company.phone_ddd
        }) ${form.company.phone_number.substring(
          0,
          5
        )}-${form.company.phone_number.substring(5)}`,
        email: form.company.email,
        zip_code: form.company.address.zip_code,
        street: form.company.address.street,
        number: form.company.address.number,
        complementary: form.company.address.complementary,
        neighborhood: form.company.address.neighborhood,
        city: form.company.address.city,
        state: form.company.address.state,
      })
    }
  }, [])

  React.useEffect(() => {
    if (callCompanyValidation) {
      companyValidation()
      setCallCompanyValidation(false)
    }
  }, [callCompanyValidation])

  const companyValidation = async () => {
    const err = { ...companyFormErrors }
    const { complementary, document_number, ...requiredCompanyFields } = company
    for (const [key, value] of Object.entries(requiredCompanyFields)) {
      if (!err[key]) err[key] = isNotEmpty(value) ? '' : 'Campo Obrigatório'
    }
    setCompanyFormErrors(err)

    const errList = Object.values(err).filter((x) => x !== '')
    if (!errList || errList.length === 0) {
      const phoneNumberOnly = company.phone.replace(/\D/g, '')
      const companyInfo = {
        company: {
          document_number: company.document_number.replace(/[./-]+/g, ''),
          company_name: company.company_name,
          fantasy_name: company.fantasy_name,
          income_invoicing: parseFloat(
            company.income_invoicing
              .replace(/[R$.]/g, '')
              .trim()
              .replace(',', '.')
          ),
          address: {
            street: company.street,
            number: company.number,
            complementary: company.complementary ? company.complementary : '',
            neighborhood: company.neighborhood,
            city: company.city,
            state: company.state,
            zip_code: company.zip_code,
          },
          phone_ddd: phoneNumberOnly.substring(0, 2),
          phone_number: phoneNumberOnly.substring(2, phoneNumberOnly.length),
          email: company.email,
        },
      }

      setForm({
        ...form,
        ...companyInfo,
      })
      nextStep()
    }
  }

  React.useEffect(() => {
    if (geoAddress.city) {
      setCompany({
        ...company,
        zip_code: geoAddress?.postal_code,
        city: removeAccents(geoAddress?.city),
        state: geoAddress?.state,
        street: removeAccents(geoAddress?.street),
        neighborhood: removeAccents(geoAddress?.neighborhood),
        number: '',
        complementary: '',
      })

      setCompanyFormErrors({
        ...companyFormErrors,
        zip_code: '',
        city: '',
        state: '',
        street: '',
        neighborhood: '',
      })

      resetGeoAddress()
    }
  }, [geoAddress, resetGeoAddress])

  return (
    <s.Sections>
      <s.Section>
        <s.AnyBankItauRepresentantFormWrapper>
          <s.AnyBankSubHeaderText>
            <s.Icon name="company" fill="transparent" />
            <s.Text bold type="headline" color="graytheme6">
              Dados da empresa
            </s.Text>
          </s.AnyBankSubHeaderText>
          <s.AnyBankItauInputWrapper>
            <s.SectionLine>
              <s.Text fontWeight={600} type="headline" color="graytheme6">
                CNPJ
              </s.Text>
              <s.InputText
                testId="CNPJ-test"
                width={260}
                disabled
                value={company.document_number}
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  companyFormErrors.company_name ? 'redshipay' : 'graytheme6'
                }
              >
                Razão social
              </s.Text>
              <s.InputText
                testId="company-name-test"
                width={260}
                value={company.company_name}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    company_name: e.target.value,
                  })
                  if (companyFormErrors.company_name) {
                    setCompanyFormErrors({
                      ...companyFormErrors,
                      company_name: '',
                    })
                  }
                }}
                error={
                  companyFormErrors.company_name
                    ? { message: companyFormErrors.company_name }
                    : false
                }
                suffix={
                  companyFormErrors.company_name ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  companyFormErrors.fantasy_name ? 'redshipay' : 'graytheme6'
                }
              >
                Nome fantasia
              </s.Text>
              <s.InputText
                testId="fantasy-name-test"
                width={260}
                value={company.fantasy_name}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    fantasy_name: e.target.value,
                  })
                  if (companyFormErrors.fantasy_name) {
                    setCompanyFormErrors({
                      ...companyFormErrors,
                      fantasy_name: '',
                    })
                  }
                }}
                error={
                  companyFormErrors.fantasy_name
                    ? { message: companyFormErrors.fantasy_name }
                    : false
                }
                suffix={
                  companyFormErrors.fantasy_name ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  companyFormErrors.income_invoicing
                    ? 'redshipay'
                    : 'graytheme6'
                }
              >
                Faturamento mensal
              </s.Text>
              <s.InputText
                testId="income-invoicing-test"
                width={260}
                value={company.income_invoicing}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    income_invoicing: formatMoney(e.target.value),
                  })
                  if (companyFormErrors.income_invoicing) {
                    setCompanyFormErrors({
                      ...companyFormErrors,
                      income_invoicing: '',
                    })
                  }
                }}
                error={
                  companyFormErrors.income_invoicing
                    ? { message: companyFormErrors.income_invoicing }
                    : false
                }
                suffix={
                  companyFormErrors.income_invoicing ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={companyFormErrors.phone ? 'redshipay' : 'graytheme6'}
              >
                Telefone corporativo
              </s.Text>
              <s.InputText
                testId="phone-test"
                width={260}
                placeholder="(99) 99999-9999"
                value={company.phone}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    phone: applyPhoneMask(e.target.value),
                  })
                  if (companyFormErrors.phone) {
                    setCompanyFormErrors({
                      ...companyFormErrors,
                      phone: '',
                    })
                  }
                }}
                error={
                  companyFormErrors.phone
                    ? { message: companyFormErrors.phone }
                    : false
                }
                suffix={
                  companyFormErrors.phone ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={companyFormErrors.email ? 'redshipay' : 'graytheme6'}
              >
                E-mail corporativo
              </s.Text>
              <s.InputText
                testId="email-test"
                width={260}
                value={company.email}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    email: e.target.value,
                  })
                  if (companyFormErrors.email) {
                    setCompanyFormErrors({
                      ...companyFormErrors,
                      email: '',
                    })
                  }
                }}
                error={
                  companyFormErrors.email
                    ? { message: companyFormErrors.email }
                    : false
                }
                suffix={
                  companyFormErrors.email ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={companyFormErrors.zip_code ? 'redshipay' : 'graytheme6'}
              >
                CEP
              </s.Text>
              <s.InputText
                maxLength={9}
                testId="CEP-test"
                width={260}
                value={applyCEPMask(company.zip_code)}
                onBlur={() => {
                  if (
                    company?.zip_code?.replace(/-/g, '').length === 8 &&
                    company?.zip_code?.replace(/-/g, '') !==
                      geoAddress?.postal_code
                  ) {
                    loadGeoAddress(company?.zip_code?.replace(/-/g, ''))
                  }
                }}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    zip_code: e.target.value,
                  })
                  if (companyFormErrors.zip_code) {
                    setCompanyFormErrors({
                      ...companyFormErrors,
                      zip_code: '',
                    })
                  }
                }}
                error={
                  companyFormErrors.zip_code
                    ? { message: companyFormErrors.zip_code }
                    : false
                }
                suffix={
                  isGeoAddressLoading ? (
                    <s.Loading type="spinner" color="maincolor" />
                  ) : companyFormErrors.zip_code ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={companyFormErrors.street ? 'redshipay' : 'graytheme6'}
              >
                Endereço
              </s.Text>
              <s.InputText
                testId="street-test"
                disabled
                width={260}
                value={company.street}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    street: e.target.value,
                  })
                  if (companyFormErrors.street) {
                    setCompanyFormErrors({
                      ...companyFormErrors,
                      street: '',
                    })
                  }
                }}
                error={
                  companyFormErrors.street
                    ? { message: companyFormErrors.street }
                    : false
                }
                suffix={
                  isGeoAddressLoading ? (
                    <s.Loading type="spinner" color="maincolor" />
                  ) : companyFormErrors.street ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>

            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={companyFormErrors.number ? 'redshipay' : 'graytheme6'}
              >
                Número
              </s.Text>
              <s.InputText
                testId="number-test"
                width={260}
                value={company.number}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    number: applyNumberOnlyMask(e.target.value),
                  })
                  if (companyFormErrors.number) {
                    setCompanyFormErrors({
                      ...companyFormErrors,
                      number: '',
                    })
                  }
                }}
                error={
                  companyFormErrors.number
                    ? { message: companyFormErrors.number }
                    : false
                }
                suffix={
                  companyFormErrors.number ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text fontWeight={600} type="headline" color="graytheme6">
                Complemento
              </s.Text>
              <s.InputText
                testId="complementary-test"
                width={260}
                value={company.complementary}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    complementary: e.target.value,
                  })
                }}
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  companyFormErrors.neighborhood ? 'redshipay' : 'graytheme6'
                }
              >
                Bairro
              </s.Text>
              <s.InputText
                testId="neighborhood-test"
                disabled
                width={260}
                value={company.neighborhood}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    neighborhood: applyCnpjMask(e.target.value),
                  })
                  if (companyFormErrors.neighborhood) {
                    setCompanyFormErrors({
                      ...companyFormErrors,
                      neighborhood: '',
                    })
                  }
                }}
                error={
                  companyFormErrors.neighborhood
                    ? { message: companyFormErrors.neighborhood }
                    : false
                }
                suffix={
                  isGeoAddressLoading ? (
                    <s.Loading type="spinner" color="maincolor" />
                  ) : companyFormErrors.neighborhood ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={companyFormErrors.city ? 'redshipay' : 'graytheme6'}
              >
                Cidade
              </s.Text>
              <s.InputText
                testId="city-test"
                width={260}
                disabled
                value={company.city}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    city: e.target.value,
                  })
                  if (companyFormErrors.city) {
                    setCompanyFormErrors({
                      ...companyFormErrors,
                      city: '',
                    })
                  }
                }}
                error={
                  companyFormErrors.city
                    ? { message: companyFormErrors.city }
                    : false
                }
                suffix={
                  companyFormErrors.city ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={companyFormErrors.state ? 'redshipay' : 'graytheme6'}
              >
                Estado
              </s.Text>
              <s.InputText
                testId="state-test"
                disabled
                width={260}
                value={company.state}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    state: e.target.value,
                  })
                  if (companyFormErrors.state) {
                    setCompanyFormErrors({
                      ...companyFormErrors,
                      state: '',
                    })
                  }
                }}
                error={
                  companyFormErrors.state
                    ? { message: companyFormErrors.state }
                    : false
                }
                suffix={
                  companyFormErrors.state ? (
                    <s.Icon name="alertcirclefilled" fill="lightred2" />
                  ) : (
                    <></>
                  )
                }
              />
            </s.SectionLine>
          </s.AnyBankItauInputWrapper>
        </s.AnyBankItauRepresentantFormWrapper>
      </s.Section>
    </s.Sections>
  )
}

export default AnyBankItauCompanyForm
