import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { SSE } from 'sse.js'

import { baseURL_PROD } from 'environment/env'
import { useTypedSelector } from 'store/modules/rootState'
import {
  CloseConnection,
  OpenConnection,
} from 'store/modules/SSENotification/action'
import { baseURL } from 'services/apiConfig'

interface IUseSSE {
  opennedConnections: Set<any>
  openConnection: (channel: string) => void
  closeConnection: (channel: string) => void
  notifications: {
    [key: string]: { message: string; wallet_settings_uuid: string }
  }
}

export const useSSE = (): IUseSSE => {
  const TIMEOUT = 60000
  const dispatch = useDispatch()
  const [notifications, setNotifications] = useState<{
    [key: string]: { message: string; wallet_settings_uuid: string }
  }>(null)

  const { opennedConnections } = useTypedSelector((store) => ({
    opennedConnections: store.SSENotification.opennedConnections,
  }))

  const closeConnection = (channel: string) => {
    const conn = Array.from(opennedConnections).find(
      (x) => x.channel === channel
    )
    if (conn && conn.eventSource) conn.eventSource.close()
    dispatch(CloseConnection(channel))
  }

  const openConnection = (channel: string) => {
    if (opennedConnections.size > 0) {
      for (const conn of Array.from(opennedConnections)) {
        if (conn && conn.eventSource) conn.eventSource.close()
        dispatch(CloseConnection(conn))
      }
    }
    _buildConnection(channel)
  }

  const _buildConnection = async (channel: string) => {
    const url = `${baseURL_PROD}/v1/notification/sse_stream?channel=${channel}`

    const response = await fetch(`${baseURL}/v1/auth-session/token`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
      },
    })
      .then((response) => response.json())
      .catch((err) => {
        console.log(err)
      })

    const SSEOptions = {
      method: 'GET',
      headers: {
        authorization: `Bearer ${response ? response.access_token : ''}`,
      },
    }

    let message

    const eventSource = new SSE(url, SSEOptions)
    eventSource.onmessage = (e) => {
      setNotifications({ [channel]: JSON.parse(e.data) })
      message = { [channel]: JSON.parse(e.data) }
      eventSource.close()
      closeConnection(channel)
    }

    eventSource.onerror = (err) => {
      if (!message) {
        setNotifications({
          [channel]: {
            message: 'Erro na API, tente novamente mais tarde',
            wallet_settings_uuid: '',
          },
        })
      }
      console.error(err)
    }

    eventSource.stream()

    setTimeout(() => {
      try {
        closeConnection(channel)
      } catch (err) {
        console.error(err)
      }
    }, TIMEOUT)

    dispatch(OpenConnection({ channel, eventSource }))
  }

  return { opennedConnections, notifications, openConnection, closeConnection }
}
