import React from 'react'
import uncertainDocsDetected from 'assets/images/uncertain-docs-detected.png'
import * as s from './styles'
import { Steps } from './WalletSettingsFormTbanksCashout'

interface IProps {
  step: Steps
  setStep: (step: Steps) => void
}

const DocumentoscopyUncertaintyDetected: React.FC<IProps> = ({
  step,
  setStep,
}) => {
  return (
    <s.Content
      width={520}
      height={609}
      data-testid="content-on-uncertainty-detected"
    >
      <s.HeaderStepsContainer>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="graytheme8">
            <s.Icon name="checkmark" fill="white" />
          </s.HeaderStepCircle>
          <s.Text>Introdução</s.Text>
        </s.HeaderStepWrapper>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="graytheme8">
            <s.Icon name="checkmark" fill="white" />
          </s.HeaderStepCircle>
          <s.Text>Questionário</s.Text>
        </s.HeaderStepWrapper>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="navbarvector1">
            <s.NumberStep>3</s.NumberStep>
          </s.HeaderStepCircle>
          <s.Text>Documento</s.Text>
        </s.HeaderStepWrapper>
      </s.HeaderStepsContainer>
      <s.Text margin="64px 0 16px 0" color="graytheme6" bold type="heading4">
        Não foi possível verificar sua identidade
      </s.Text>
      <s.Text margin="0 0 96px 0" color="graytheme6" type="headline">
        Certifique-se de que o texto da imagem está legível e que a foto contém
        o documento inteiro e bem iluminado. Em caso de dúvidas entre em contato
        com o suporte.
      </s.Text>
      <img width={500} src={uncertainDocsDetected} color="graytheme6" />
      <s.ButtonWrapper margin="96px 0 0 0">
        <s.Button
          data-testid="resend-doc-button"
          onClick={() => {
            setStep({
              ...step,
              userVerificationDocumentSelection: true,
            })
          }}
          height="42px"
          color="maincolor"
        >
          <s.Text
            margin="0 8px 0 0"
            type="headline"
            color="white"
            fontWeight={600}
          >
            Reenviar Documento
          </s.Text>
        </s.Button>
      </s.ButtonWrapper>
    </s.Content>
  )
}

export default DocumentoscopyUncertaintyDetected
