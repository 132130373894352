import { useState, useEffect } from 'react'

import * as s from './styles'

import { useNonInitialEffect } from 'hooks/utils'
import { usePostCustomerRegistration } from 'hooks/registration'

import ShipayAccountForm from './ShipayAccountForm'
import { showToast } from 'components/Toast'

interface IProps {
  onSuccess: () => void
  onClose: boolean
}

const CustomerRegistration: React.FC<IProps> = ({ onSuccess, onClose })=> {
  const [ action, setAction ] = useState(false)
  const [ clearForm, setClearForm ] = useState(false)
  const [ _shipayAccountForm, _setShipayAccountForm ] = useState<any>()
  const { response, isLoading, error, createCustomer } = usePostCustomerRegistration()

  useNonInitialEffect(() => {
    setClearForm(!clearForm)
  }, [onClose])

  useNonInitialEffect(() => {
    if(error) {
      showToast({
        type: 'error',
        message: 'Problema ao criar cadastro completo',
      })
    }
  }, [error])

  useNonInitialEffect(() => {
    if (response.code !== -1) {
      showToast({
        type: 'success',
        message: 'Usuário criado com sucesso',
      })
      onSuccess()
      setClearForm(!clearForm)
    }
  }, [response])

  useEffect(() => {
    if(_shipayAccountForm) {
      const payload = {
        name: _shipayAccountForm.customer_name.value,
        email: _shipayAccountForm.customer_email.value,
        pos_product_id: _shipayAccountForm.pos_product_id.value,
        retail_chain_id: _shipayAccountForm.retail_chain_id.value,
      }
      createCustomer(payload)
    }
  }, [_shipayAccountForm])

  const onShipayAccountSubmit = (shipayAccountForm) => {
    _setShipayAccountForm(shipayAccountForm)
  }

  const submitForm = () => {
    setAction(!action)
  }

  return(
    <s.FormContainer>
      <ShipayAccountForm onSubmit={onShipayAccountSubmit} submit={action} clearForm={clearForm} />
      <s.Grid2>
        <div>
        </div>
        <s.Button onClick={() => submitForm()}>
          <s.Icon name="personify" color="white">
          </s.Icon>
          <s.Text bold color="white">
            Cadastrar Cliente
          </s.Text>
        </s.Button>
      </s.Grid2>
    </s.FormContainer>
  )
}

export default CustomerRegistration
