import { useState } from 'react'
import { useNonInitialEffect } from 'hooks/utils'
import { setValueToLocalStorage } from 'helpers/localStorage'
import { isCustomerUser } from 'helpers/claims'
import { useGetShouldRedirectWizard } from 'hooks/wizard/useGetShouldRedirectWizard'

interface UseAuth {
  checkURLAccessToken: (p: string) => void
  rUrl: string
}

export const useAuth = (): UseAuth => {
  const { shouldRedirect, loadShouldRedirect } = useGetShouldRedirectWizard()
  const [currentUser, setCurrentUser] = useState<any>()
  const [rUrl, setRUrl] = useState('')

  useNonInitialEffect(() => {
    if (shouldRedirect) {
      setRUrl('/wizard')
    } else {
      if (currentUser && isCustomerUser(JSON.parse(currentUser).access_token))
        setRUrl('/')
      else setRUrl('/admin')
    }
  }, [shouldRedirect])

  const checkURLAccessToken = async (p) => {
    const user: any = atob(decodeURIComponent(p))
    setCurrentUser(user)
    // setValueToLocalStorage('currentUserSession', user)
    loadShouldRedirect()
  }

  return { checkURLAccessToken, rUrl }
}
