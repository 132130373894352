import styled, { css } from 'styled-components'
import ModalCmp from 'components/Modal'
import IconCmp from 'components/IconExporter'
import TextCmp from 'components/Text'
import ButtonCmp from 'components/Button'
import InputTextCmp from 'components/Inputs/Text'
import { ModalContainer, ChildrenWrapper } from 'components/Modal/styles'
import { themeColors } from 'styles/theme'
import LoadingCmp from 'components/Loading'
import SelectCmp from 'components/Inputs/Select'

export const Modal = styled(ModalCmp)`
  header {
    background: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
    padding: 32px;
    h5 {
      color: ${({ theme }) => theme.colors.graytheme6};
    }
  }

  ${ModalContainer} {
    padding: 0px;
  }

  ${ChildrenWrapper} {
    padding: 0px;
    overflow-y: hidden;

    ::-webkit-scrollbar-track {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
`

interface StepPropsContent {
  formStep?: boolean
}
export const ModalContent = styled.div<StepPropsContent>`
  position: relative;
  width: 520px;
  margin: 0px 38px 32px 32px;
  height: 520px;

  @media (max-height: 600px) {
    height: 390px;
  }

  @media (min-height: 601px) and (max-height: 800px) {
    height: 430px;
  }

  @media (min-height: 801px) and (max-height: 940px) {
    height: 520px;
  }

  @media (min-height: 941px) and (max-height: 1920px) {
    height: 690px;
  }

  ${(props) =>
    props.formStep
      ? css`
          @media (min-height: 1081px) and (max-height: 1920px) {
            min-height: 920px;
          }
          @media (min-height: 1921px) {
            min-height: 1080px;
          }
        `
      : css``}
`

export const HeaderStepsContainer = styled.div`
  margin: 0px -32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 92px;
  padding: 16px 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
`

export const HeaderStepWrapper = styled.div`
  display: flex;
  align-items: left;
  justify-content: center;
  gap: 4px;
`

interface HeaderStepCircleProps {
  backgroundColor?: keyof typeof themeColors
  padding?: number
}

export const HeaderStepCircle = styled.div<HeaderStepCircleProps>`
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors[props.backgroundColor]};
  padding: ${({ padding }) => `${padding}px`};
`

export const NumberStep = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const LoadingGradient = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 134px;
  img {
    will-change: transform;
    transition-property: transform;
    animation-name: rotate;
    animation-duration: 1100ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  @media screen and (max-width: 1440px) {
    margin-top: 84px;
  }
`

export const SmallerLoadingGradient = styled(LoadingGradient)`
  margin-top: 32px;
`

export const Text = styled(TextCmp)``

export const Wrapper = styled.div`
  padding-top: 64px;
  p {
    margin-bottom: 16px;
  }

  h5 {
    margin-bottom: 16px;
  }

  > img {
    padding-top: 88px;
  }
`

export const SucessWrapper = styled.div`
  overflow: hidden;
  padding-top: 32px;
  img {
    padding-top: 88px;
  }

  @media screen and (max-width: 1440px) {
    img {
      padding: 68px 0px 32px 0px;
      width: 95%;
    }
  }
`

interface SectionsProps {
  showScroll?: boolean
}

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 532px;
  height: calc(100% - 130px);
  ::-webkit-scrollbar-track {
    margin-top: 32px;
    margin-bottom: 42px;
  }
  @media ${({ theme }) => theme.mediaQueries.xlUp} {
    height: calc(100% - 130px);
  }
`
export const Select = styled(SelectCmp)`
  div > div > p {
    line-height: 21px;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.graytheme6};
    font-weight: normal;
  }
  > div > span > p {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500px;
    color: ${({ theme }) => theme.colors.cancelledStatusTextColor};
  }
`
export const SectionsRegisterFormWrapper = styled.div`
  padding-right: 32px;
`
export const SectionsFeaturesSelectionWrapper = styled.div<SectionsProps>`
  padding-right: 32px;
  @media screen and (${({ theme }) => theme.mediaQueries.md}) {
    padding-right: 0px;
  }

  ${(props) =>
    props.showScroll
      ? css`
          padding-right: 32px;
        `
      : css``};
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
`

export const Line = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
`

export const Align = styled.div`
  display: flex;
  align-items: center;
`
interface ProviderContainerProps {
  margin?: string
}

export const ProviderContainer = styled.div<ProviderContainerProps>`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};
`

export const ProviderName = styled.div`
  display: flex;
  width: 260px;
  align-items: baseline;
`

export const SeeMoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const WrapperSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const RoundedSquareWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 8px;
  margin-top: 16px;
  border: 2px solid ${({ theme }) => theme.colors.maincolor};
`

export const SeeMoreContainer = styled.div`
  max-width: 338px;
`

export const RoundedSquareContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const SeeMoreButton = styled.button`
  display: inline;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.maincolor};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  :hover {
    cursor: pointer;
  }
`

interface AddCheckButtonProps {
  checked: boolean
}

export const AddCheckButton = styled.button<AddCheckButtonProps>`
  display: flex;
  align-items: center;
  width: 154px;
  padding: 10px;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.maincolor};
  :hover {
    cursor: pointer;
  }

  ${({ checked }) =>
    checked
      ? css`
          background: ${({ theme }) => theme.colors.lightmaincolor3};
          div {
            background: ${({ theme }) => theme.colors.maincolor};
          }
        `
      : css`
          background: inherit;
        `};
`
export const AddBankSlipButton = styled.button`
  display: flex;
  border: none;
  background: transparent;
  :hover {
    cursor: pointer;
  }
`
export const CheckSquare = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 2px solid ${({ theme }) => theme.colors.maincolor};
  svg {
    width: 10px;
    height: 10px;
  }
`

interface ButtonWrapperProps {
  margin?: string
}

export const Div = styled.div``

export const PreviousNextButtonContainer = styled.div<ButtonWrapperProps>`
  position: absolute;
  width: calc(100% + 1%);
  bottom: 0;
  background: white;

  div:first-child {
    width: calc(100%+32px);
    height: 1px;
    margin: 0px -32px 32px -32px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
  }
`

export const PreviousNextButtonWrapper = styled.div<ButtonWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: ${({ margin }) => margin};
`

export const Button = styled(ButtonCmp)`
  width: 100%;
  box-shadow: 0px 1px 4px #b3b3b3;
  svg {
    margin-right: 8px;
  }
`

export const Icon = styled(IconCmp)`
  display: inline;
`

export const ExternalLink = styled.a`
  color: ${({ theme }) => theme.colors.maincolor};
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
`

export const BanklineWrapper = styled.div`
  margin-top: 32px;
`
export const LoadingWrapper = styled.div`
  margin-top: 64px;
  overflow-y: hidden;
`
export const HeaderStepLoading = styled.div`
  margin: -32px;
`

export const OrderedList = styled.ol`
  margin-left: 24px;

  h5 {
    margin-bottom: 8px;
    line-height: 21px;
  }
`

export const ListItem = styled.li``

export const SectionLine = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 40px;
  margin-top: 32px;
`

export const InputText = styled(InputTextCmp)``

export const InfoBox = styled.div<{
  error: boolean
}>`
  display: flex;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.graytheme2};

  ${({ error, theme }) =>
    error
      ? css`
          background-color: ${theme.colors.cancelledStatusBackground};
          border: 0;
          flex-direction: column;
          margin-bottom: 8px;
        `
      : css``}
`

export const Loading = styled(LoadingCmp)``
