import React from 'react'

import * as s from './styles'

interface IProps {
  featureName: string
  onCheck: (value: string) => void
  checked: boolean
}
const FilterCards: React.FC<IProps> = ({ featureName, onCheck, checked }) => {
  const [seeMore, setSeeMore] = React.useState<boolean>(false)

  const titleBasedOnFeature = {
    cashin: 'Receber pagamentos',
    cashout: 'Enviar pagamentos',
    bank_slip_settings: 'Gerar boleto híbrido',
    anybank: 'Pix Qualquer Banco',
  }

  const descriptionBasedOnFeature = {
    cashin: 'Faça cobranças por QR Code ou Copia e Cola.',
    cashout: 'Faça transferências para terceiros pelo seu sistema.',
    bank_slip_settings: 'Gere cobranças com boleto e Pix ao mesmo tempo.',
    anybank:
      'Possibilita utilizar um banco parceiro para intermediar a operação.',
  }

  const seeMoreDescriptionBasedOnFeature = {
    cashin:
      'Receba diretamente na sua conta. Consulte as tarifas com o time comercial da Shipay.',
    cashout:
      'Faça transferências via Pix de maneira integrada com seu sistema de caixa utilizando a chave Pix do recebedor. Os valores saem da sua conta bancária cadastrada na Shipay e chegam na conta bancária do recebedor instantaneamente.',
    bank_slip_settings:
      'Registre boletos híbridos para cobrar seus clientes com um instrumento de cobrança que contém tanto um boleto, quanto um QR Code Pix. Caso o instrumento seja pago com Pix, o boleto é cancelado e vice-versa.',
    anybank:
      'O Pix Qualquer Banco possibilita utilizar um banco parceiro para intermediar a operação do Pix na Shipay, permitindo utilizar qualquer banco como banco destino.',
  }

  return (
    <s.RoundedSquareWrapper expanded={seeMore}>
      <s.RoundedSquareContainer>
        <s.SeeMoreContainer data-testid="bank-slip-feature">
          <s.Text bold type="headline" color="graytheme8">
            {titleBasedOnFeature[featureName]}
          </s.Text>

          <s.Text margin="8px 0 0 0" type="tag" color="graytheme8">
            {descriptionBasedOnFeature[featureName]}{' '}
            <s.SeeMoreButton
              data-testid="see-more-button-optional-feature"
              onClick={() => setSeeMore(!seeMore)}
            >
              {seeMore ? 'ocultar' : 'ver mais'}
            </s.SeeMoreButton>
          </s.Text>

          {seeMore ? (
            <s.Text
              margin="16px 0 0 0"
              data-testid="see-more-optional-text"
              type="tag"
              color="graytheme8"
            >
              {seeMoreDescriptionBasedOnFeature[featureName]}
            </s.Text>
          ) : (
            <></>
          )}
        </s.SeeMoreContainer>
        <s.AddCheckButton
          checked={checked}
          onClick={() => {
            onCheck(featureName)
          }}
          data-testid="check-button"
        >
          <s.CheckSquare>
            <s.Icon name="checkmark" fill="white" />
          </s.CheckSquare>
          <s.Text fontWeight={600} type="headline" color="maincolor">
            {checked ? 'Adicionado' : 'Adicionar'}
          </s.Text>
        </s.AddCheckButton>
      </s.RoundedSquareContainer>
    </s.RoundedSquareWrapper>
  )
}

export default FilterCards
