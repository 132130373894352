import React from 'react'
import {
  applyPhoneMask,
  applyCEPMask,
  formatMoney,
  applyCnpjMask,
  applyNumberOnlyMask,
} from 'helpers/masks'

import useGetGeoAddress from 'hooks/useGetGeoAddress'

import * as s from '../styles'
import { removeAccents } from 'helpers'

const CompanyForm: React.FC<{
  pixFormState?: [any, React.Dispatch<React.SetStateAction<any>>]
  errorFormState?: [any, React.Dispatch<React.SetStateAction<any>>]
}> = ({ pixFormState, errorFormState }) => {
  const [form, setForm] = pixFormState
  const [formErrors, setFormErrors] = errorFormState

  const {
    geoAddress,
    isLoading: isGeoAddressLoading,
    hasError: hasGeoAddressError,
    loadGeoAddress,
    resetGeoAddress,
  } = useGetGeoAddress()

  React.useEffect(() => {
    if (form.company.document_number) {
      setFormErrors({
        ...formErrors,
        company: {
          ...formErrors.company,
          document_number: '',
        },
      })
    }
    if (geoAddress.city) {
      setForm({
        ...form,
        company: {
          ...form.company,
          address: {
            ...form.company.address,
            city: removeAccents(geoAddress?.city),
            state: geoAddress?.state,
            street: removeAccents(geoAddress?.street),
            neighborhood: removeAccents(geoAddress?.neighborhood),
            number: '',
            complementary: '',
          },
        },
      })

      setFormErrors({
        ...formErrors,
        company: {
          ...formErrors.company,
          zip_code: '',
          city: '',
          state: '',
          street: '',
          neighborhood: '',
        },
      })

      resetGeoAddress()
    }
  }, [geoAddress, resetGeoAddress])

  return (
    <s.CompanyFormWrapper>
      <s.AnyBankSubHeaderText>
        <s.Icon name="company" fill="transparent" />
        <s.Text bold type="headline" color="graytheme6">
          Dados da empresa
        </s.Text>
      </s.AnyBankSubHeaderText>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={
            formErrors.company.document_number ? 'redshipay' : 'graytheme6'
          }
        >
          CNPJ
        </s.Text>
        <s.InputText
          testId="CNPJ-test"
          width={260}
          disabled
          value={form.company.document_number}
          onChange={(e) => {
            setForm({
              ...form,
              company: {
                ...form.company,
                document_number: applyCnpjMask(e.target.value),
              },
            })
            if (formErrors.company.document_number) {
              setFormErrors({
                ...formErrors,
                company: {
                  ...formErrors.company,
                  document_number: '',
                },
              })
            }
          }}
          error={
            formErrors.company.document_number
              ? { message: formErrors.company.document_number }
              : false
          }
          suffix={
            formErrors.company.document_number ? (
              <s.Icon name="alertcirclefilled" fill="lightred2" />
            ) : (
              <></>
            )
          }
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={formErrors.company.company_name ? 'redshipay' : 'graytheme6'}
        >
          Razão social
        </s.Text>
        <s.InputText
          testId="company-name-test"
          width={260}
          value={form.company.company_name}
          onChange={(e) => {
            setForm({
              ...form,
              company: {
                ...form.company,
                company_name: e.target.value,
              },
            })
            if (formErrors.company.company_name) {
              setFormErrors({
                ...formErrors,
                company: {
                  ...formErrors.company,
                  company_name: '',
                },
              })
            }
          }}
          error={
            formErrors.company.company_name
              ? { message: formErrors.company.company_name }
              : false
          }
          suffix={
            formErrors.company.company_name ? (
              <s.Icon name="alertcirclefilled" fill="lightred2" />
            ) : (
              <></>
            )
          }
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={
            formErrors.company.income_invoicing ? 'redshipay' : 'graytheme6'
          }
        >
          Faturamento mensal
        </s.Text>
        <s.InputText
          testId="income-invoicing-test"
          width={260}
          value={form.company.income_invoicing}
          onChange={(e) => {
            setForm({
              ...form,
              company: {
                ...form.company,
                income_invoicing: formatMoney(e.target.value),
              },
            })
            if (formErrors.company.income_invoicing) {
              setFormErrors({
                ...formErrors,
                company: {
                  ...formErrors.company,
                  income_invoicing: '',
                },
              })
            }
          }}
          error={
            formErrors.company.income_invoicing
              ? { message: formErrors.company.income_invoicing }
              : false
          }
          suffix={
            formErrors.company.income_invoicing ? (
              <s.Icon name="alertcirclefilled" fill="lightred2" />
            ) : (
              <></>
            )
          }
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={formErrors.company.company_type ? 'redshipay' : 'graytheme6'}
        >
          Tipo de negócio
        </s.Text>
        <s.InputText
          testId="company-type-test"
          width={260}
          value={form.company.company_type}
          onChange={(e) => {
            setForm({
              ...form,
              company: {
                ...form.company,
                company_type: e.target.value,
              },
            })
            if (formErrors.company.company_type) {
              setFormErrors({
                ...formErrors,
                company: {
                  ...formErrors.company,
                  company_type: '',
                },
              })
            }
          }}
          error={
            formErrors.company.company_type
              ? { message: formErrors.company.company_type }
              : false
          }
          suffix={
            formErrors.company.company_type ? (
              <s.Icon name="alertcirclefilled" fill="lightred2" />
            ) : (
              <></>
            )
          }
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={formErrors.company.zip_code ? 'redshipay' : 'graytheme6'}
        >
          CEP
        </s.Text>
        <s.InputText
          maxLength={9}
          testId="CEP-test"
          width={260}
          value={form.company.address.zip_code}
          onBlur={() => {
            if (
              form.company.address?.zip_code?.replace(/-/g, '').length === 8 &&
              form.company.address?.zip_code?.replace(/-/g, '') !==
                geoAddress?.postal_code
            ) {
              loadGeoAddress(form.company.address?.zip_code?.replace(/-/g, ''))
            }
          }}
          onChange={(e) => {
            setForm({
              ...form,
              company: {
                ...form.company,
                address: {
                  ...form.company.address,
                  zip_code: applyCEPMask(e.target.value),
                },
              },
            })
            if (formErrors.company.zip_code) {
              setFormErrors({
                ...formErrors,
                company: {
                  ...formErrors.company,
                  zip_code: '',
                },
              })
            }
          }}
          error={
            formErrors.company.zip_code
              ? { message: formErrors.company.zip_code }
              : false
          }
          suffix={
            isGeoAddressLoading ? (
              <s.Loading type="spinner" color="maincolor" />
            ) : formErrors.company.zip_code ? (
              <s.Icon name="alertcirclefilled" fill="lightred2" />
            ) : (
              <></>
            )
          }
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={formErrors.company.street ? 'redshipay' : 'graytheme6'}
        >
          Endereço
        </s.Text>
        <s.InputText
          testId="street-test"
          disabled
          width={260}
          value={form.company.address.street}
          onChange={(e) => {
            setForm({
              ...form,
              company: {
                ...form.company,
                address: {
                  ...form.company.address,
                  street: e.target.value,
                },
              },
            })
            if (formErrors.company.street) {
              setFormErrors({
                ...formErrors,
                company: {
                  ...formErrors.company,
                  street: '',
                },
              })
            }
          }}
          error={
            formErrors.company.street
              ? { message: formErrors.company.street }
              : false
          }
          suffix={
            isGeoAddressLoading ? (
              <s.Loading type="spinner" color="maincolor" />
            ) : formErrors.company.street ? (
              <s.Icon name="alertcirclefilled" fill="lightred2" />
            ) : (
              <></>
            )
          }
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={formErrors.company.number ? 'redshipay' : 'graytheme6'}
        >
          Número
        </s.Text>
        <s.InputText
          testId="number-test"
          width={260}
          value={form.company.address.number}
          onChange={(e) => {
            setForm({
              ...form,
              company: {
                ...form.company,
                address: {
                  ...form.company.address,
                  number: applyNumberOnlyMask(e.target.value),
                },
              },
            })
            if (formErrors.company.number) {
              setFormErrors({
                ...formErrors,
                company: {
                  ...formErrors.company,
                  number: '',
                },
              })
            }
          }}
          error={
            formErrors.company.number
              ? { message: formErrors.company.number }
              : false
          }
          suffix={
            formErrors.company.number ? (
              <s.Icon name="alertcirclefilled" fill="lightred2" />
            ) : (
              <></>
            )
          }
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text fontWeight={600} type="headline" color="graytheme6">
          Complemento
        </s.Text>
        <s.InputText
          testId="complementary-test"
          width={260}
          value={form.company.address.complementary}
          onChange={(e) => {
            setForm({
              ...form,
              company: {
                ...form.company,
                address: {
                  ...form.company.address,
                  complementary: e.target.value,
                },
              },
            })
          }}
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={formErrors.company.neighborhood ? 'redshipay' : 'graytheme6'}
        >
          Bairro
        </s.Text>
        <s.InputText
          testId="neighborhood-test"
          disabled
          width={260}
          value={form.company.address.neighborhood}
          onChange={(e) => {
            setForm({
              ...form,
              company: {
                ...form.company,
                address: {
                  ...form.company.address,
                  neighborhood: e.target.value,
                },
              },
            })
            if (formErrors.company.neighborhood) {
              setFormErrors({
                ...formErrors,
                company: {
                  ...formErrors.company,
                  neighborhood: '',
                },
              })
            }
          }}
          error={
            formErrors.company.neighborhood
              ? { message: formErrors.company.neighborhood }
              : false
          }
          suffix={
            isGeoAddressLoading ? (
              <s.Loading type="spinner" color="maincolor" />
            ) : formErrors.company.neighborhood ? (
              <s.Icon name="alertcirclefilled" fill="lightred2" />
            ) : (
              <></>
            )
          }
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={formErrors.company.city ? 'redshipay' : 'graytheme6'}
        >
          Cidade
        </s.Text>
        <s.InputText
          testId="city-test"
          width={260}
          disabled
          value={form.company.address.city}
          onChange={(e) => {
            setForm({
              ...form,
              company: {
                ...form.company,
                address: {
                  ...form.company.address,
                  city: e.target.value,
                },
              },
            })
            if (formErrors.company.city) {
              setFormErrors({
                ...formErrors,
                company: {
                  ...formErrors.company,
                  city: '',
                },
              })
            }
          }}
          error={
            formErrors.company.city
              ? { message: formErrors.company.city }
              : false
          }
          suffix={
            formErrors.company.city ? (
              <s.Icon name="alertcirclefilled" fill="lightred2" />
            ) : (
              <></>
            )
          }
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={formErrors.company.state ? 'redshipay' : 'graytheme6'}
        >
          Estado
        </s.Text>
        <s.InputText
          testId="state-test"
          disabled
          width={260}
          value={form.company.address.state}
          onChange={(e) => {
            setForm({
              ...form,
              company: {
                ...form.company,
                address: {
                  ...form.company.address,
                  state: e.target.value,
                },
              },
            })
            if (formErrors.company.state) {
              setFormErrors({
                ...formErrors,
                company: {
                  ...formErrors.company,
                  state: '',
                },
              })
            }
          }}
          error={
            formErrors.company.state
              ? { message: formErrors.company.state }
              : false
          }
          suffix={
            formErrors.company.state ? (
              <s.Icon name="alertcirclefilled" fill="lightred2" />
            ) : (
              <></>
            )
          }
        />
      </s.SectionLine>
    </s.CompanyFormWrapper>
  )
}

export default CompanyForm
