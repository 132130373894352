export const Types = {
  GET_USER: 'GET_USER',
  GET_USER_LOADING: 'GET_USER_LOADING',
  GET_USER_ERROR: 'GET_USER_ERROR',
  GET_USERS: 'GET_USERS',
  GET_USERS_LOADING: 'GET_USERS_LOADING',
  GET_USERS_ERROR: 'GET_USERS_ERROR',
  POST_USER: 'POST_USER',
  POST_USER_LOADING: 'POST_USER_LOADING',
  POST_USER_ERROR: 'POST_USER_ERROR',
  PATCH_USER: 'PATCH_USER',
  PATCH_USER_LOADING: 'PATCH_USER_LOADING',
  PATCH_USER_ERROR: 'PATCH_USER_ERROR',
  DEL_USER: 'DEL_USER',
  DEL_USER_LOADING: 'DEL_USER_LOADING',
  DEL_USER_ERROR: 'DEL_USER_ERROR',
}

export interface ActionInterface {
  type: keyof typeof Types
  payload:
    | UserInterface
    | GetUsersResponseInterface
    | PostUserResponseInterface
    | PatchUserResponseInterface
    | DelUserResponseInterface
}

export interface UserState {
  user: UserInterface
  isLoading: boolean
  hasError: boolean
  users: GetUsersResponseInterface
  getUsersIsLoading: boolean
  getUsersError: boolean
  postResponse: PostUserResponseInterface
  postIsLoading: boolean
  postError: boolean
  patchResponse: PatchUserResponseInterface
  patchIsLoading: boolean
  patchError: boolean
  delResponse: DelUserResponseInterface
  delIsLoading: boolean
  delError: boolean
}

//entity interface
export interface UserInterface {
  cpf: string
  degree: string
  email: string
  first_name: string
  id: string
  last_name: string
  level: string
  phone: string
  role_id: string
}

//GET USERS
export interface GetUsersResponseInterface {
  count: number
  data: UserInterface[]
  offset: number
  total: number
}

// POST
export interface PostUserResponseInterface {
  code: number
  message: string
}

// DELETE
export interface DelUserResponseInterface {
  code: number
  message: string
}

// PATCH
export interface PatchUserResponseInterface {
  code: number
  message: string
}
