export const Types = {
  PATCH_PIX_WS: 'PATCH_PIX_WS',
  PATCH_PIX_WS_LOADING: 'PATCH_PIX_WS_LOADING',
  PATCH_PIX_WS_ERROR: 'PATCH_PIX_WS_ERROR',
  GET_PIX_WS: 'GET_PIX_WS',
  GET_PIX_WS_LOADING: 'GET_PIX_WS_LOADING',
  GET_PIX_WS_ERROR: 'GET_PIX_WS_ERROR',
  POST_PIX_WS: 'POST_PIX_WS',
  POST_PIX_WS_LOADING: 'POST_PIX_WS_LOADING',
  POST_PIX_WS_ERROR: 'POST_PIX_WS_ERROR',
}

export interface ActionInterface {
  type: keyof typeof Types
  payload: ResponseInterface | GetPixWSResponseInterface
}

export interface PixWSState {
  pixWS: GetPixWSResponseInterface
  getIsLoading: boolean
  getError: boolean
  patchResponse: ResponseInterface
  patchIsLoading: boolean
  patchError: boolean
  postResponse: ResponseInterface
  postIsLoading: boolean
  postError: boolean
}

//get response
export interface GetPixWSResponseInterface {
  active: boolean
  client_id: string
  client_secret: string
  default: boolean
  dict_key: string
  enabled_pix: boolean
  id: string
  name: string
  pspProvider: string
  tls_certificate: string | null
  wallet_name: string
  is_default_split_fee?: boolean
  split_fee_id?: string
}

//default response interface
export interface ResponseInterface {
  code: number
  message: string
}
