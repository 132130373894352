import * as s from '../styles'
import { CustomCellComponent } from './CustomCellComponent'

export const CreatedAt: CustomCellComponent = ({ children }) => {
    return (
        <s.CreatedAtText>
            <s.Icon name='paper' fill='graytheme6' />
            <s.Text bold>{children}</s.Text>
        </s.CreatedAtText>
    )
}