export const getToday = () => {
  return new Date().toLocaleDateString('pt-BR')
}

export const getYesterday = () => {
  return new Date(
    new Date().valueOf() -
      1000 * 60 * 60 * 24 -
      1000 * 60 * (180 - new Date().getTimezoneOffset())
  ).toLocaleDateString('pt-BR')
}

export const getThisWeek = () => {
  var today = new Date()
  var day = today.getDay() || 7
  if (day !== 0) {
    today.setHours(-24 * day)
  }
  return today.toLocaleDateString('pt-BR')
}

export const getLast7Days = () => {
  return new Date(
    new Date().valueOf() -
      1000 * 60 * 60 * 24 * 7 -
      1000 * 60 * (180 - new Date().getTimezoneOffset())
  ).toLocaleDateString('pt-BR')
}

export const getThisMonth = () => {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() - 30
  ).toLocaleDateString('pt-BR')
}

export const getLast6Months = () => {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 6,
    new Date().getDate()
  ).toLocaleDateString('pt-BR')
}

export const getThisYear = () => {
  return new Date(new Date().getFullYear(), 0, 1).toLocaleDateString('pt-BR')
}

export const parseDMY = (s) => {
  var b = s.split(/\D+/)
  return new Date(b[2], b[1] - 1, b[0])
}

export const formatDateTime = (s) => {
  if (!s) return null
  return new Date(s).toLocaleString('pt-BR').replace(',', '')
}

export const formatDate = (value) => {
  if (value.length > 10) {
    return value.substring(0, 10)
  }

  switch (value.length) {
    case 1:
      if (value > 3) {
        value = '3'
      }
      break
    case 2:
      if (value > 31) {
        value = '31'
      }
      break
    case 3:
    case 4:
      if (value[2] !== '/') {
        value = value.substr(0, 2) + '/' + value[2]
      }
      if (value[3] > 1) {
        value = value.substr(0, 3) + '1'
      }
      break
    case 5:
      if (value.substr(3, 2) > 12) {
        value = value.substr(0, 3) + '12'
      }
      break
    case 6:
    case 7:
      if (value[5] !== '/') {
        value = value.substr(0, 5) + '/' + value[5]
      }
      if (value[6] < 1) {
        value = value.substr(0, 6) + '1'
      }
      break
    default:
      break
  }

  return value
}
