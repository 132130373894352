export const Types = {
  GET_TRANSACTION_LOGS: 'GET_TRANSACTION_LOGS',
  GET_TRANSACTION_LOGS_LOADING: 'GET_TRANSACTION_LOGS_LOADING',
  GET_TRANSACTION_LOGS_ERROR: 'GET_TRANSACTION_LOGS_ERROR',
}

interface TransactionLogsCalendarInterface {
  criacao: string
  dataDeVencimento: string
  validadeAposVencimento: number
}

interface TransactionLogsDebtorInterface {
  cpf: string
  email: string
  nome: string
}

interface TransactionLogsLocInterface {
  criacao: string
  id: number
  location: string
  tipoCob: string
}

interface TransactionLogsReceiverInterface {
  cep: string
  cidade: string
  cnpj: string
  logradouro: string
  nome: string
  nomeFantasia: string
  uf: string
}

interface TransactionLogsValueInterface {
  abatimento: { modalidade: string; valorPerc: string }
  desconto: {
    descontoDataFixa: { data: string; valorPerc: string }[]
    modalidade: string
  }
  juros: { modalidade: string; valorPerc: string }
  multa: { modalidade: string; valorPerc: string }
  original: string
}

export interface TransactionLogsWalletResponseInterface {
  calendario: TransactionLogsCalendarInterface
  chave: string
  devedor: TransactionLogsDebtorInterface
  loc: TransactionLogsLocInterface
  recebedor: TransactionLogsReceiverInterface
  revisao: number
  status: string
  txid: string
  valor: TransactionLogsValueInterface
}

export interface TransactionLogsInterface {
  created: string
  transaction_type: string
  wallet_response: TransactionLogsWalletResponseInterface
}

export interface PayloadInterface {
  count: number
  data: TransactionLogsInterface[]
  offset: number
  total: number
}

export interface ActionInterface {
  type: keyof typeof Types
  payload: PayloadInterface
}

export interface TransactionLogsState {
  transactions: PayloadInterface
  isLoading: boolean
  hasError: boolean
}
