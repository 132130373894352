export const getStates = (): { id: number; text: string }[] => {
  return [
    { id: 1, text: 'AC' },
    { id: 2, text: 'AL' },
    { id: 3, text: 'AP' },
    { id: 4, text: 'AM' },
    { id: 5, text: 'BA' },
    { id: 6, text: 'CE' },
    { id: 7, text: 'DF' },
    { id: 8, text: 'ES' },
    { id: 9, text: 'GO' },
    { id: 10, text: 'MA' },
    { id: 11, text: 'MT' },
    { id: 12, text: 'MS' },
    { id: 13, text: 'MG' },
    { id: 14, text: 'PA' },
    { id: 15, text: 'PB' },
    { id: 16, text: 'PR' },
    { id: 17, text: 'PE' },
    { id: 18, text: 'PI' },
    { id: 19, text: 'RJ' },
    { id: 20, text: 'RN' },
    { id: 21, text: 'RS' },
    { id: 22, text: 'RO' },
    { id: 23, text: 'RR' },
    { id: 24, text: 'SC' },
    { id: 25, text: 'SP' },
    { id: 26, text: 'SE' },
    { id: 27, text: 'TO' },
  ]
}
