import React from 'react'

import { useTranslation } from 'react-i18next'

import customerService from 'services/customer'

import useWalletSettingsSaga from 'hooks/useWalletSettingsSaga'
import { useSSE } from 'hooks/utils/useSSE'
import { useNonInitialEffect } from 'hooks/utils'

import { showToast } from 'components/Toast'
import useWalletsSettings from 'hooks/useWalletsSettings'

import * as s from '../styles'

const FoxbitConfigWallet: React.FC<{
  handleClose: () => void
  isModalOpen: boolean
  offset: string
}> = ({ handleClose, isModalOpen, offset }) => {
  const { t } = useTranslation()
  const { postIsLoading, postResponse, postEWalletWalletSettingsSaga } =
    useWalletSettingsSaga()

  const { openConnection, notifications } = useSSE()

  const [customerUUID, setCustomerUUID] = React.useState<string>('')
  const [isSSELoading, setSSEIsLoading] = React.useState<boolean>(false)
  const [input, setInput] = React.useState({
    name: '',
    default: true,
    active: true,
    wallet_name: 'foxbit',
    client_id: '',
    client_secret: '',
    payer_crypto_coin: '',
  })

  const [selectedDefaultCrypto, setSelectedDefaultCrypto] = React.useState({
    id: -1,
    name: '',
  })

  const [inputPressed, setInputPressed] = React.useState<any>({
    ...Object.keys(input).reduce((acc, key) => {
      acc[key] = false
      return acc
    }, {}),
  })

  const { loadWalletsSettings } = useWalletsSettings()

  const handlePost = async () => {
    postEWalletWalletSettingsSaga({
      ...input,
      payer_crypto_coin: selectedDefaultCrypto.name
        .replace(' ', '')
        .split('-')[0],
    })
  }

  useNonInitialEffect(() => {
    if (notifications) {
      const status = notifications[customerUUID].message
        .toLowerCase()
        .includes('sucesso')
        ? 'success'
        : 'error'
      showToast({
        type: status,
        message: notifications[customerUUID].message,
      })
      setSSEIsLoading(false)
      if (status === 'success') {
        handleClose()
        loadWalletsSettings({
          limit: '10',
          offset: offset,
          filters: window.btoa(
            JSON.stringify({ wallet_type: ['c-wallet', 'e-wallet'] })
          ),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  useNonInitialEffect(() => {
    if (postResponse.code !== -1) {
      showToast({
        type: 'success',
        message: t(postResponse.message),
      })
      setSSEIsLoading(true)
      customerService.getCurrentCustomer().then((currentCustomerRes) => {
        setCustomerUUID(currentCustomerRes.data.uuid)
        openConnection(currentCustomerRes.data.uuid)
      })
    }
  }, [postResponse])

  React.useEffect(() => {
    if (!isModalOpen) {
      setInput({
        name: '',
        default: true,
        active: true,
        wallet_name: 'foxbit',
        payer_crypto_coin: '',
        client_id: '',
        client_secret: '',
      })
      setInputPressed({
        ...Object.keys(input).reduce((acc, key) => {
          acc[key] = false
          return acc
        }, {}),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen])

  const renderForm = (height: number) => {
    return (
      <s.FormContainer height={height}>
        <s.InputText
          label="Apelido da Credencial"
          placeholder="Ex: ”Loja Paulista”, ”loja@paulista.com”, etc."
          value={input.name}
          onChange={(e) => {
            setInput({ ...input, name: e.target.value })
            setInputPressed({ ...inputPressed, name: true })
          }}
          error={
            input.name === '' && inputPressed.name
              ? {
                  message: 'Campo Obrigatório',
                }
              : null
          }
        />
        <s.InputText
          label="Chave de acesso"
          value={input.client_id}
          onChange={(e) => {
            setInput({ ...input, client_id: e.target.value })
            setInputPressed({ ...inputPressed, client_id: true })
          }}
          error={
            input.client_id === '' && inputPressed.client_id
              ? {
                  message: 'Campo Obrigatório',
                }
              : null
          }
        />
        <s.InputText
          label="Chave secreta"
          value={input.client_secret}
          onChange={(e) => {
            setInput({ ...input, client_secret: e.target.value })
            setInputPressed({ ...inputPressed, client_secret: true })
          }}
          error={
            input.client_secret === '' && inputPressed.client_secret
              ? {
                  message: 'Campo Obrigatório',
                }
              : null
          }
        />
        <s.SearchSelect
          label="Criptomoeda"
          optionBoxSize={100}
          options={[
            {
              id: 1,
              text: 'BTC.LN - Bitcoin Lightning',
              onClick: () => {
                setSelectedDefaultCrypto({
                  id: 1,
                  name: 'BTC.LN - Bitcoin Lightning',
                })
              },
            },
          ]}
          selectedState={[selectedDefaultCrypto, setSelectedDefaultCrypto]}
        />
        <s.FooterButtonWrapper>
          <s.Button
            outline
            color="white"
            disabled={isSSELoading || postIsLoading}
            onClick={() => handleClose()}
          >
            Cancelar
          </s.Button>
          <s.Button
            color="maincolor"
            disabled={
              input.name === '' ||
              input.client_id === '' ||
              input.client_secret === '' ||
              selectedDefaultCrypto.name === '' ||
              isSSELoading ||
              postIsLoading
            }
            onClick={() => handlePost()}
          >
            Salvar
          </s.Button>
          {postIsLoading || isSSELoading ? (
            <s.Loading type="spinner" color="maincolor" />
          ) : null}
        </s.FooterButtonWrapper>
      </s.FormContainer>
    )
  }

  return <>{renderForm(500)}</>
}

export default FoxbitConfigWallet
