import React, { forwardRef } from 'react'

import * as s from './styles'

interface IProps {
  id: string
  label?: string | React.ReactNode
  checked: boolean
  disabled?: boolean
}

const Checkbox = forwardRef<
  HTMLInputElement,
  IProps & React.HTMLAttributes<HTMLInputElement>
>(({ id, label, checked, disabled, className, ...rest }, ref) => {
  return (
    <s.CheckboxWrapper className={className}>
      <s.Input
        ref={ref}
        type="checkbox"
        id={id}
        checked={checked}
        disabled={disabled}
        {...rest}
      />
      <s.Label htmlFor={id}>{label}</s.Label>
    </s.CheckboxWrapper>
  )
})

export default Checkbox
