import styled from 'styled-components'

import ButtonCmp from 'components/Button'
import TextCmp from 'components/Text'
import IconExporter from 'components/IconExporter'

export const Icon = styled(IconExporter)``

export const Text = styled(TextCmp)``

export const Title = styled(TextCmp)`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 12px;
`

export const Button = styled(ButtonCmp)`
  border: 2px dashed ${({ theme }) => theme.colors.maincolor};

  span {
    svg {
      margin-right: 5px;
    }
  }

  span {
    p {
      color: ${(props) => props.theme.colors.maincolor};
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
    }
  }
`

export const UploadedImageButton = styled(ButtonCmp)`
  :hover {
    background-color: none;
  }
  span {
    svg {
      margin: 0 5px 0 5px;
    }
  }
`

export const InputUploadImage = styled.input`
  display: none;
`
export const UploadContainer = styled.div``

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 520px;
  height: 64px;
  background: #ffffff;
  border-radius: 8px;

  /* > svg:first-child {
    path {
      fill: ${(props) => props.theme.colors.maincolor};
    }
  }

  > svg:last-child {
    cursor: pointer;
    path {
      fill: #475966;
      transition: all 0.15s ease-in;
    }
  }

  > svg:last-child:hover {
    path {
      fill: #231f20;
    }
  } */
`

export const Space = styled.div`
  padding: 0px;
  width: 0px;
  height: 0px;
  margin-bottom: 16px;
`

export const UploadFormatRules = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 8px;
  padding: 0px;
`

export const UploadFormatErrors = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
`

export const TextError = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #d41411;
`

export const UploadContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const InputText = styled(TextCmp)`
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const InputUploadedImage = styled.input`
  display: none;
  cursor: pointer;
  &[type='file']::file-selector-button {
    display: none;
    visibility: hidden;
    color: transparent;
  }

  &[type='file'] {
    color: transparent;
  }

  &[type='file']::-webkit-file-upload-button {
    visibility: hidden;
    color: transparent;
    display: none;
  }
`
