export const Types = {
  GET_CUSTOMERS: 'GET_CUSTOMERS',
  GET_CUSTOMERS_LOADING: 'GET_CUSTOMERS_LOADING',
  GET_CUSTOMERS_ERROR: 'GET_CUSTOMERS_ERROR',
}

export interface CustomerInterface {
  customer_created_at: string
  customer_name: string
  pos_product_name: string
  retail_chains_name: string
  uuid: string
}

export interface PayloadInterface {
  count: number
  data: CustomerInterface[]
  offset: number
  total: number
}

export interface ActionInterface {
  type: keyof typeof Types
  payload: PayloadInterface
}

export interface CustomersState {
  customers: PayloadInterface
  isLoading: boolean
  hasError: boolean
}
