import styled from 'styled-components'

import { Container as ContainerCmp } from 'styled-bootstrap-grid'

import TextCmp from 'components/Text'
import TableCmp from 'components/Table'
import ButtonCmp from 'components/Button'
import IconExporterCmp from 'components/IconExporter'
import ToggleSwitchCmp from 'components/Switch'
import LoadingCmp from 'components/Loading'
import ModalCmp from 'components/Modal'
import InputTextCmp from 'components/Inputs/Text'
import SelectCmp from 'components/Inputs/Select'
import PaginationCmp from 'components/Pagination'

export const StoreAddress = styled.div`
  display: flex;
`

export const StoreHeaderSubtitle = styled.div`
  margin-top: -25px;
  margin-bottom: 20px;
`

export const Container = styled(ContainerCmp)`
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  height: 100vh;

  > p {
    margin: ${({ theme }) => theme.spacing(4)}px 0
      ${({ theme }) => theme.spacing(3)}px 0;
  }

  > div {
    :nth-child(5),
    :nth-child(7) {
      > button > span > svg {
        margin-right: 8px;
      }
    }
  }
`

export const Text = styled(TextCmp)``

export const ButtonText = styled(TextCmp)`
  width: 75%;
`

export const StorePosTable = styled(TableCmp)`
  > tbody > tr {
    height: 56px;
  }

  > thead > tr > th {
    :nth-child(2) {
      width: 20%;
    }
    :nth-child(3) {
      width: 30%;
    }
    :nth-child(4) {
      width: 10%;
    }
    :nth-child(5) {
      width: 10%;
    }
  }
`

export const StoreWalletsTable = styled(TableCmp)`
  > tbody > tr {
    height: 56px;

    > td {
      :nth-child(2) {
        > p {
          font-weight: bold;
        }
      }
    }
  }

  > thead > tr > th {
    :nth-child(2) {
      width: 30%;
    }
    :nth-child(3) {
      width: 20%;
    }
    :nth-child(4) {
      width: 11%;
    }
  }
`

export const CliendIdContainer = styled.div`
  color: ${({ theme }) => theme.colors.graytheme6};
  padding: ${({ theme }) => theme.spacing(1)}px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.lightmaincolor3};
  display: flex;
  align-items: center;
  width: 200px;

  > svg {
    margin-left: 8px;
  }
`

export const CopyKeyButton = styled.div`
  color: ${({ theme }) => theme.colors.graytheme6};
  padding: ${({ theme }) => theme.spacing(1)}px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.lightmaincolor3};
  display: flex;
  align-items: center;
  width: 100px;
  height: 40px;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.lightmaincolor2};
    color: ${({ theme }) => theme.colors.maincolor};
  }

  :active {
    background-color: ${({ theme }) => theme.colors.lightmaincolor1};
  }

  > svg {
    margin-left: 8px;
  }
`

export const Button = styled(ButtonCmp)`
  > span > svg {
    margin-left: 8px;
  }
`

export const Icon = styled(IconExporterCmp)``

export const ToggleSwitch = styled(ToggleSwitchCmp)``

export const Loading = styled(LoadingCmp)`
  margin-right: 8px;
`

export const WalletWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Modal = styled(ModalCmp)`
  > div > div {
    overflow-y: hidden;
  }
`

export const ModalContainer = styled.div`
  width: 500px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    margin-bottom: 32px;
  }

  > textarea {
    width: 300px;
    border: 2px solid ${({ theme }) => theme.colors.maincolor};
    border-radius: 4px;
    height: 100px;
    margin-bottom: 32px;
  }
`

export const ButtonModalContainer = styled.div`
  > button {
    :first-child {
      margin-right: 16px;
    }
  }
`

export const InputText = styled(InputTextCmp)`
  margin-top: 16px;
`

export const InputTextSearch = styled(InputTextCmp)`
  border-radius: 0;
  width: 100%;
  height: 56px;
  box-shadow: none;
  background-color: ${({ theme }) => theme.colors.graytheme1};

  :hover {
    box-shadow: none;
  }

  :active {
    box-shadow: none;
  }

  :focus-within {
    box-shadow: none;
  }

  > input {
    background-color: ${({ theme }) => theme.colors.graytheme1};
  }
`

export const Select = styled(SelectCmp)`
  margin: 32px 0 32px 0;
  width: 270px;
`

export const Pagination = styled(PaginationCmp)``

export const Footer = styled.div`
  width: 100%;
  > p {
    margin-bottom: 8px;
  }
  padding-bottom: 64px;
`

export const KeyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const AccessKeyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    height: 40px;
    align-items: flex-end;
    margin: 16px 8px 0 0;
    > * {
      margin-right: 16px;
    }
  }
`

export const SecretKeyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    height: 40px;
    align-items: flex-end;
    margin: 16px 8px 0 0;
    > * {
      margin-right: 16px;
    }
  }
`

export const InputSearchWrapper = styled.div`
  width: 100%;
  height: 56px;

  > svg {
    position: absolute;
    z-index: 2;
  }
`

export const StoreWalletsHeader = styled.div`
  margin: 48px 0 16px 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const StorePosHeader = styled.div`
  margin: 48px 0 16px 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`
