import React from 'react';

import * as s from "./styles";

const AccessManagement: React.FC = () => {
  return (
    <s.Container>
      Associar Papel
    </s.Container>
  )
}

export default AccessManagement;