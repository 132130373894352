import React from 'react'

import paitausuccess from 'assets/images/pa-itau-success.png'

import * as s from './styles'

interface Props {
  goToStores: () => void
}

const Success: React.FC<Props> = ({ goToStores }) => {
  return (
    <s.Content width={304} height={386} data-testid="content-on-success">
      <s.Text margin="0 0 16px 0" color="graytheme6" bold type="heading4">
        Cadastro efetuado com sucesso!
      </s.Text>
      <s.Text margin="0 0 16px 0" color="graytheme6" type="headline">
        Associe sua conta Pix TbankS às suas lojas na Shipay para poder enviar
        pagamentos via Pix!
      </s.Text>
      <s.ImageWrapper>
        <img width={304} src={paitausuccess} />
      </s.ImageWrapper>
      <s.ButtonWrapper data-testid="button-on-success">
        <s.Button
          data-testid="go-to-stores-button"
          onClick={() => goToStores()}
          disabled={false}
          height="42px"
          color="maincolor"
        >
          <s.Text color="white">Ir para Lojas</s.Text>
        </s.Button>
      </s.ButtonWrapper>
    </s.Content>
  )
}

export default Success
