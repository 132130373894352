import React from 'react'
import { useDispatch } from 'react-redux'

import { get, baseURLUser } from '../services/api'
import { useTypedSelector } from '../store/modules/rootState'
import {
  GetUser,
  GetUserLoading,
  GetUserError,
} from 'store/modules/user/action'

import { UserState } from 'store/modules/user/types'

interface UseGetUser extends UserState {
  loadUser: (string) => Promise<void>
}

const useGetUser = (): UseGetUser | any => {
  const dispatch = useDispatch()

  const { user, isLoading, hasError } = useTypedSelector((store) => ({
    user: store.user.user,
    isLoading: store.user.isLoading,
    hasError: store.user.hasError,
  }))

  const loadUser = React.useCallback(
    async (id): Promise<void> => {
      dispatch(GetUserLoading())
      await get(`${baseURLUser}/${id}`)
        .then((response) => {
          dispatch(GetUser(response.data))
        })
        .catch(() => {
          dispatch(GetUserError())
        })
    },
    [dispatch]
  )

  return { user, hasError, isLoading, loadUser }
}

export default useGetUser
