import React from 'react'

import loadingbigger from 'assets/images/loading-bigger.png'
import { dict } from 'components/IconExporter'
import { SectionWelcome } from 'hooks/useGetWalletSettingAnybankModalConfig'

import * as s from '../styles'

const TextFormat: React.FC<{ text: string }> = ({ text }) => {
  const regex = /\[(.*?)\]\((.*?)\)/
  const match = text.match(regex)
  if (match) {
    const anchorText = match[1]
    const url = match[2]

    return (
      <s.Text>
        {text.replace(regex, '')}
        <s.ButtonLink href={url} color="maincolor" target="_blank">
          {anchorText}
        </s.ButtonLink>
      </s.Text>
    )
  } else {
    return <s.Text>{text}</s.Text>
  }
}

const TextBasedOnType = ({ value, textType }) => {
  switch (textType) {
    case 'plain_text':
      return <TextFormat text={value} />
    case 'bullet_list':
      return (
        <ul>
          {value.map((listItem, index) => (
            <li key={index}>
              <TextFormat text={listItem} />
            </li>
          ))}
        </ul>
      )
    default:
      return null
  }
}

const WelcomeStep: React.FC<{
  section: SectionWelcome
  isLoading: boolean
}> = ({ section, isLoading }) => {
  if (isLoading) {
    return (
      <s.WelcomeWrapper>
        <s.LoadingWrapper>
          <s.Text textAlign="center" color="graytheme6" bold type="heading4">
            Carregando...
          </s.Text>
          <s.LoadingGradient>
            <img width={110} src={loadingbigger} />
          </s.LoadingGradient>
        </s.LoadingWrapper>
      </s.WelcomeWrapper>
    )
  }
  return (
    <s.WelcomeWrapper>
      <s.Text margin="0 0 24px 0" type="headline" bold color="graytheme6">
        {section.texts.main_description}
      </s.Text>
      {section.texts.info_box.map((info, index) => (
        <s.InfoBox key={index}>
          <s.IconBox>
            <s.Icon fill="maincolor" name={info.icon as keyof typeof dict} />
          </s.IconBox>
          <s.TextWrapper>
            <s.Text bold>{info.title}</s.Text>
            {info.content.map((text, index) => (
              <TextBasedOnType
                key={index}
                value={text.value}
                textType={text.value_type}
              />
            ))}
          </s.TextWrapper>
        </s.InfoBox>
      ))}
    </s.WelcomeWrapper>
  )
}

export default WelcomeStep
