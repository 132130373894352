import React from 'react'
import * as s from './styles'

interface IProps {
  url?: string
  testId?: string
}

export const MessageBox: React.FC<IProps> = ({ url, testId }) => {
  return (
    <s.MessageBoxOutline>
      <s.Text color="gray1">
        Está com dúvidas para configurar sua conta pix? Preparamos um material
        para ajudar você nesta tarefa!
      </s.Text>
      <s.MessageBoxButton>
        <s.Button
          disabled={url ? false : true}
          onClick={() => {
            window.open(url, '_blank')
          }}
          data-testid={testId}
        >
          Clique Aqui
          <s.Icon name="arrowaltright" fill="white" />
        </s.Button>
      </s.MessageBoxButton>
    </s.MessageBoxOutline>
  )
}
