import { ActionInterface, Types, ImpersonateState } from './types'

const INITIAL_STATE: ImpersonateState = {
  request: {},
  isLoading: false,
  hasError: false,
  response: {
    access_token: '',
    email: '',
    id: '',
    name: '',
    refresh_token: '',
    roles: [],
    impersonate: false,
  },
  
}

const ImpersonateReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): ImpersonateState=> {
  switch (action.type) {
    case Types.POST_IMPERSONATE:
      return {
        ...state,
        request: action.request,
        isLoading: true,
        hasError: false,
      }
    case Types.POST_IMPERSONATE_RESPONSE:
      return {
        ...state,
        request: INITIAL_STATE.request,
        response: action.response,
        isLoading: false,
        hasError: false,
      }
    case Types.POST_IMPERSONATE_ERROR:
      return {
        ...state,
        request: INITIAL_STATE.request,
        isLoading: false,
        hasError: true,
      }
    case Types.IMPERSONATE_REMOVED:
      return {
        ...state,
      }
    default:
      return state
  }
}

export default ImpersonateReducer
