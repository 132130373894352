import React, { useCallback } from 'react'

import { showToast } from 'components/Toast'

import * as s from './styles'
import FileModalList from './FilesModalList'
import FilesModalForm from './FilesModalForm'
import { listFiles, getFileUrl, requestFile as apiRequestFile, ApiRequestFilePayload, ApiListFileReference } from './FileApi'

interface FilesModalProps extends ApiRequestFilePayload {
    title?: string
    isOpen?: boolean
    size?: number
    fileType: "payments_file" | "sales_file"
    handleClose?: () => any
    ordersCount?: number
    initialDate: string
    finalDate: string
    maxPeriodInDays: number
}

export const FilesModal: React.FC<FilesModalProps> = ({
    title,
    isOpen,
    handleClose,
    size = 5,
    fileType,
    format,
    filters,
    orderBy,
    sort,
    ordersCount,
    initialDate,
    finalDate,
    maxPeriodInDays
}) => {
    const [loading, setLoading] = React.useState<boolean>(false)
    const [offset, setOffset] = React.useState<string>('0')
    const [error, setError] = React.useState<string>(null)
    const [response, setResponse] = React.useState<ApiListFileReference>({
        data: [],
        offset: 0,
        total: 0,
        count: 0
    })
    const paginationUpdateRef = React.useRef(false)

    React.useEffect(() => {
        showToast({
            type: 'error',
            message: error,
        })
    }, [error])

    const ApiCallWrapper = React.useCallback(async function <T>(promise: Promise<T>) {
        try {
            setLoading(true)
            const res = await promise
            return res
        } catch (error) {
            setError(error.response.data.detail[0])
        } finally {
            setLoading(false)
        }
    }, [])

    React.useEffect(() => {
        if (!isOpen) {
            return
        }
        if (offset === '-1') {
            setOffset('0')
            return
        }
        ApiCallWrapper(
            listFiles(size, parseInt(offset), fileType)
                .then(setResponse)
                .catch(() => { setError("Error loading files") })
        )
    }, [offset])

    const downloadFile = React.useCallback((file: string) => {
        ApiCallWrapper(
            getFileUrl(fileType, file)
                .then(response => window.open(response.file_url, '_blank'))
                .catch(() => setError("Error downloading file"))
        )
    }, [])

    const requestFile = React.useCallback(() => {
        const payload: ApiRequestFilePayload = {
            format: format,
            filters: filters,
            orderBy: orderBy,
            sort: sort
        }
        ApiCallWrapper(apiRequestFile(fileType, payload).then(refresh))
    }, [])

    const refresh = useCallback(() => {
        setOffset('-1')
        paginationUpdateRef.current = true
    }, [])

    return (
        <s.Modal
            handleClose={handleClose}
            isOpen={!!isOpen}
            modalSubtitle={title}
            placeModalOnCenter
            data-testid='files-modal'
        >
            <s.ModalContainer>
                <FilesModalForm
                    requestFile={requestFile}
                    ordersCount={ordersCount}
                    initialDate={initialDate}
                    finalDate={finalDate}
                    maxIntervalInDays={maxPeriodInDays}
                />
                <s.Line />
                <FileModalList
                    isLoading={loading}
                    listOffset={offset}
                    setListOffset={setOffset}
                    downloadFile={downloadFile}
                    size={size}
                    paginationUpdateRef={paginationUpdateRef}
                    refresh={refresh}
                    {...response}
                />
            </s.ModalContainer>
        </s.Modal>
    )
}
