import React from 'react'
import * as s from '../styles'

import { IconName, ThemeColorNames } from 'components/IconExporter'

import { CustomCellComponent } from './CustomCellComponent'

const FileSatusMessage: Record<string, {
    color: ThemeColorNames,
    text: string,
    icon?: IconName,
    disabled?: boolean
}> = {
    done: {
        color: 'maincolor',
        text: 'Baixar',
        icon: 'downloadarrow'
    },
    pending: {
        color: 'gray1',
        text: 'Pendente',
        disabled: true
    },
    running: {
        color: 'approvedStatusTextColor',
        text: 'Em execução',
        disabled: true
    },
    error: {
        color: 'indianred',
        text: 'Erro',
        disabled: true
    },
}

export const DownloadButton: CustomCellComponent<{ downloadFile?(fileName: string): void }> = ({ children, downloadFile }) => {
    const [status, filePath] = children.split('::')
    const [_, fileName] = filePath.split('/')
    const statusMessage = FileSatusMessage[status]
    const handleClick = React.useCallback((fileName: string) => {
        if (status == 'done') {
            downloadFile && downloadFile(fileName)
        }
    }, [status])
    return <s.DownloadFilesButton
        onClick={() => handleClick(fileName)}
        disabled={statusMessage.disabled}
        data-testid={`${fileName}_${status}_button`}
    >
        <s.Text color={statusMessage.color} bold>
            {statusMessage.text}
        </s.Text>
        {statusMessage.icon ? <s.Icon name={statusMessage.icon} fill={statusMessage.color} /> : null}
    </s.DownloadFilesButton>
}