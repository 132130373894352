import React from 'react'
import Modal from 'components/Modal'
import { MessageBox, WarningBox, PixKeyInput } from './components'
import { useNonInitialEffect } from 'hooks/utils'
import { PixWSInterface } from 'services/pixWalletSettings'
import { Partial } from 'helpers/partial'
import { usePostPixWS } from 'hooks/pixWS'
import { isNotEmpty } from 'helpers/validators'
import { showToast } from 'components/Toast'
import * as s from './styles'
import { isSpacePay } from 'helpers'

interface IProps {
  isOpen: string
  handleClose: () => any
}

const BRADESCO_DOC_URL =
  'https://shipaysupport.zendesk.com/hc/pt-br/articles/4411305193623-Bradesco-'

export const WalletSettingsFormBradesco: React.FC<IProps> = ({
  isOpen,
  handleClose,
}) => {
  const [walletSettingsForm, setWalletSettingsForm] = React.useState({
    apelido: '',
    chave_pix: '',
  })

  const [
    walletSettingsFormErrors,
    setWalletSettingsFormErrors,
  ] = React.useState({
    apelido: '',
    chave_pix: '',
    chave_pix_confirmation: '',
  })

  const selectedTypeId = React.useRef<number>(4)

  const { postResponse, postError, createPixWS } = usePostPixWS()

  useNonInitialEffect(() => {
    if (postResponse.code !== -1) {
      // showToast({
      //   type: 'success',
      //   message: 'Criação de carteira em processamento',
      // })
      handleClose()
    }
  }, [postResponse])

  // useNonInitialEffect(() => {
  //   if (postError) {
  //     showToast({
  //       type: 'error',
  //       message: 'Problema ao editar carteira',
  //     })
  //   }
  // }, [postError])

  const handlePost = () => {
    const err = { ...walletSettingsFormErrors }
    for (const [key, value] of Object.entries(walletSettingsForm)) {
      if (!err[key]) err[key] = isNotEmpty(value) ? '' : 'Campo Obrigatório'
    }
    setWalletSettingsFormErrors(err)

    const errList = Object.values(err).filter((x) => x !== '')
    if (!errList || errList.length === 0) {
      let pixKey = walletSettingsForm.chave_pix.trim()
      const type = selectedTypeId.current

      // 2: CNPJ, 3: PhoneNumber
      if (type === 2 || type === 3) {
        pixKey =
          type === 3
            ? '+55' + pixKey.replace(/[^a-zA-Z0-9]/g, '')
            : pixKey.replace(/[^a-zA-Z0-9]/g, '')
      }

      const payload = {
        active: true,
        default: false,
        dict_key: pixKey,
        name: walletSettingsForm.apelido,
        psp_provider: 'bradesco',
        wallet_name: 'pix',
      } as Partial<PixWSInterface>
      createPixWS(payload)
    }
  }

  const _setWalletSettingsForm = (obj) => {
    setWalletSettingsForm({ ...walletSettingsForm, ...obj })
    Object.keys(obj).forEach((key) => {
      obj[key] = ''
      setWalletSettingsFormErrors({ ...walletSettingsFormErrors, ...obj })
    })
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={!!isOpen}
      modalSubtitle="Conta Pix"
    >
      <s.ModalContent>
        {!isSpacePay() ? <MessageBox url={BRADESCO_DOC_URL} /> : <></>}
        <s.InputGroup>
          <s.InputText
            maxLength={100}
            label="Apelido"
            value={walletSettingsForm.apelido}
            onChange={(e) =>
              _setWalletSettingsForm({ apelido: e.target.value })
            }
            error={
              walletSettingsFormErrors.apelido
                ? { message: walletSettingsFormErrors.apelido }
                : false
            }
          />
        </s.InputGroup>
        <PixKeyInput
          value={walletSettingsForm.chave_pix}
          pixKeyErr={walletSettingsFormErrors.chave_pix}
          onPixKeyErr={(err) => {
            setWalletSettingsFormErrors({
              ...walletSettingsFormErrors,
              chave_pix: err,
            })
          }}
          selectedTypeId={selectedTypeId}
          onChange={(value) => _setWalletSettingsForm({ chave_pix: value })}
        />
        <WarningBox />
        <s.ButtonGroup>
          <s.Button onClick={() => handlePost()}>Cadastrar</s.Button>
          <s.Button color="tertiary" onClick={() => handleClose()}>
            Cancelar
          </s.Button>
        </s.ButtonGroup>
      </s.ModalContent>
    </Modal>
  )
}
