import React, { useEffect, useContext } from 'react'
import ReactDOM from 'react-dom'
import { ThemeContext } from 'styled-components'

import Icon, { dict } from 'components/IconExporter'

import { themeColors } from 'styles/theme'

import * as s from './styles'

interface IProps {
  headerIcon?: keyof typeof dict
  modalTitle?: string
  modalSubtitle?: string
  handleClose: () => void
  isOpen: boolean
  footer?: React.ReactNode
  isHeaderComponent?: boolean
  header?: React.ReactNode
  noChildrenPadding?: boolean
  placeModalOnCenter?: boolean
  children: React.ReactNode | React.ReactNode[]
}

const modalRoot = document.getElementById('modal')

const Modal: React.FC<IProps> = ({
  headerIcon = null,
  modalTitle = '',
  modalSubtitle = '',
  placeModalOnCenter = false,
  handleClose,
  isOpen,
  isHeaderComponent = true,
  noChildrenPadding = false,
  children,
  footer,
  header,
  ...rest
}) => {
  const themeContext = useContext(ThemeContext)
  const [domReady, setDomReady] = React.useState(false)

  React.useEffect(() => {
    setDomReady(true)
  })

  useEffect(() => {
    const body = document.querySelector<HTMLInputElement>('body')
    const html = document.querySelector<HTMLInputElement>('html')
    if (isOpen) {
      body!.style.overflow = 'hidden'
      html!.style.overflowY = 'hidden'
    }
    function handleOverflow() {
      body!.style.overflow = 'unset'
      html!.style.overflowY = 'unset'
    }
    return () => handleOverflow()
  }, [isOpen])

  useEffect(() => {
    const handler = function handleEscClose(event: KeyboardEvent) {
      if (isOpen && event.key === 'Escape') {
        handleClose()
      }
    }
    document.addEventListener('keyup', handler)
    return () => document.removeEventListener('keyup', handler)
  }, [handleClose, isOpen])

  const renderHeader = () => {
    if (header) {
      return header
    }
    return (
      <>
        {headerIcon && <Icon width="28" fill="maincolor" name={headerIcon} />}
        {!!modalSubtitle ? (
          <s.TitleWrapper>
            <s.ModalTitle>{modalTitle}</s.ModalTitle>
            <s.Text type="headline" bold color="maincolor">
              {modalSubtitle}
            </s.Text>
          </s.TitleWrapper>
        ) : (
          <s.ModalTitle>{modalTitle}</s.ModalTitle>
        )}
        <s.CloseButton
          data-gtm-subname={`modal ${modalTitle}`}
          data-gtm-name="botao fechar"
          data-gtm-type="click"
          data-gtm-clicktype="button"
          onClick={handleClose}
          name="closesquare"
          color="gray4"
          width={28}
          height={28}
        />
      </>
    )
  }
  return domReady && isOpen
    ? ReactDOM.createPortal(
        <s.ModalWrapper isOpen={isOpen} {...rest}>
          <s.ModalOverlay isOpen={isOpen} onClick={handleClose} />
          <s.ModalContainer placeModalOnCenter={placeModalOnCenter}>
            {isHeaderComponent ? (
              <>
                <s.ModalHeader>{renderHeader()}</s.ModalHeader>
              </>
            ) : null}
            <s.ChildrenWrapper noChildrenPadding={noChildrenPadding}>
              {children}
            </s.ChildrenWrapper>
            {footer && (
              <>
                <s.ModalFooter>{footer}</s.ModalFooter>
              </>
            )}
          </s.ModalContainer>
        </s.ModalWrapper>,
        modalRoot
      )
    : null
}

export default Modal
