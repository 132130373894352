import { ActionInterface, Types, ThemeState } from './types'

const INITIAL_STATE: ThemeState = {
  theme: {
    domain: '',
    pagetitle: '',
    images: null,
    colors: null,
  },
  isLoading: false,
  hasError: false,
}

const themeReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): ThemeState => {
  switch (action.type) {
    case Types.GET_THEME:
      return {
        ...state,
        theme: {
          ...action.payload,
        },
        isLoading: false,
        hasError: false,
      }
    case Types.GET_THEME_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_THEME_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export default themeReducer
