import {
  ActionInterface,
  Types,
  CustomerRegistrationState,
} from './types'

const INITIAL_STATE: CustomerRegistrationState = {
  error: false,
  isLoading: false,
  response: { code: -1, message: ''},
}

const CustomerRegistrationReducer = (
  state = INITIAL_STATE,
  action: ActionInterface,
): CustomerRegistrationState => {
  switch (action.type) {
    case Types.POST_RESPONSE:
      return {
        error: false,
        isLoading: false,
        response: action.payload,
      }
    case Types.POST_LOADING:
      return {
        ...state,
        error: false,
        isLoading: true,
      }
    case Types.POST_ERROR:
      return {
        ...state,
        error: true,
        isLoading: false,
      }
    default:
      return state
  }
}

export default CustomerRegistrationReducer
