import { baseURL } from 'services/apiConfig'
import { get, baseURLCustomer } from '../api'
import { getValueFromLocalStorage } from 'helpers/localStorage'
import { UserSessionInfo } from 'store/modules/auth/types'

export interface Customer {
  email: string
  id: string
  name: string
  pos_product_id: string
  pos_product_name: string
  pos_vendor_id: string
  pos_vendor_name: string
  retail_chain_id: string
  retail_chain_name: string
}

const getCustomer = (userId) => {
  return get(`${baseURLCustomer}/${userId}`)
}

const getCurrentCustomer = () => {
  const currentUserSession: UserSessionInfo = JSON.parse(
    getValueFromLocalStorage('currentUserSession')
  )
  if (currentUserSession && currentUserSession.customer_id) {
    return get(`/customer-id/${currentUserSession.customer_id}`)
  }
  return null
}

const getCustomerId = (customerId) => {
  return get(`${baseURL}/customer-id/${customerId}`)
}

const customerService = {
  getCustomer,
  getCurrentCustomer,
  getCustomerId,
}

export default customerService
