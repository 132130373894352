import React from 'react'
import { useDispatch } from 'react-redux'

import { get, baseURLRegistrationStatusList } from '../services/api'
import { useTypedSelector } from '../store/modules/rootState'
import {
  GetRegistrationStatusList,
  GetRegistrationStatusListIsLoading,
  GetRegistrationStatusListError,
} from 'store/modules/registrationStatus/action'

import { RegistrationStatusState } from 'store/modules/registrationStatus/types'

interface IUseGetRegistrationStatus extends RegistrationStatusState {
  loadRegistrationStatus: () => Promise<void>
}

const useGetRegistrationStatus = (): IUseGetRegistrationStatus => {
  const dispatch = useDispatch()
  const { registrationStatus, isLoading, hasError } = useTypedSelector(
    (store) => ({
      registrationStatus: store.registrationStatus.registrationStatus,
      isLoading: store.registrationStatus.isLoading,
      hasError: store.registrationStatus.hasError,
    })
  )
  const loadRegistrationStatus = React.useCallback(async (): Promise<void> => {
    dispatch(GetRegistrationStatusListIsLoading())
    await get(baseURLRegistrationStatusList, { params: { limit: '500' } })
      .then((response) => {
        dispatch(GetRegistrationStatusList(response.data))
      })
      .catch(() => {
        dispatch(GetRegistrationStatusListError())
      })
  }, [dispatch])

  return { registrationStatus, hasError, isLoading, loadRegistrationStatus }
}

export default useGetRegistrationStatus
