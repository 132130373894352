import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'store/modules/rootState'

import {
  DelRetailChain,
  DelRetailChainError,
  DelRetailChainIsLoading,
} from 'store/modules/retailChain/action'

import { RetailChainState } from 'store/modules/retailChain/types'

import retailChainService from 'services/retailChain'

import { Partial } from 'helpers/partial'

interface UseDelRetailChain extends RetailChainState {
  deleteRetailChain: (retailChainId: string) => Promise<void>,
}

export const useDelRetailChain = (): Partial<UseDelRetailChain> => {
  const dispatch = useDispatch()
  const { delResponse, delIsLoading, delError } = useTypedSelector((store) => ({
    delResponse: store.retailChain.delResponse,
    delIsLoading: store.retailChain.delIsLoading,
    delError: store.retailChain.delError,
  }))

  const deleteRetailChain = useCallback(async (retailChainId): Promise<void> => {
    dispatch(DelRetailChainIsLoading())
    await retailChainService.deleteRetailChain(retailChainId)
      .then((response) => {
        dispatch(DelRetailChain(response.data))
      })
      .catch(() => {
        dispatch(DelRetailChainError())
      })
  }, [dispatch])

  return { delResponse, delIsLoading, delError, deleteRetailChain}
}
