import { ActionInterface, Types, StorePosState } from './types'

const INITIAL_STATE: StorePosState = {
  storePosPerStoreId: { count: 0, data: [], offset: 0, total: -1 },
  storePosPerStoreIdIsLoading: false,
  storePosPerStoreIdHasError: false,
  storePos: {
    active: null,
    client_id: null,
    id: null,
    meta_data: {},
    name: null,
    store_id: null,
  },
  storePosIsLoading: false,
  storePosHasError: false,
  postStorePosIsLoading: false,
  postStorePosHasError: false,
}

const storesPosReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): StorePosState => {
  switch (action.type) {
    case Types.GET_STORE_POS_PER_STORE_ID:
      return {
        ...state,
        storePosPerStoreId: action.perStoreIdPayload,
        storePosPerStoreIdIsLoading: false,
        storePosPerStoreIdHasError: false,
      }
    case Types.GET_STORE_POS_PER_STORE_ID_LOADING:
      return {
        ...state,
        storePosPerStoreIdIsLoading: true,
        storePosPerStoreIdHasError: false,
      }
    case Types.GET_STORE_POS_PER_STORE_ID_ERROR:
      return {
        ...state,
        storePosPerStoreIdIsLoading: false,
        storePosPerStoreIdHasError: true,
      }
    case Types.GET_STORE_POS:
      return {
        ...state,
        storePos: action.storePosPayload,
        storePosIsLoading: false,
        storePosHasError: false,
      }
    case Types.GET_STORE_POS_LOADING:
      return {
        ...state,
        storePosIsLoading: true,
        storePosHasError: false,
      }
    case Types.GET_STORE_POS_ERROR:
      return {
        ...state,
        storePosIsLoading: false,
        storePosHasError: true,
      }
    case Types.POST_STORE_POS:
      return {
        ...state,
        postStorePosIsLoading: false,
        postStorePosHasError: false,
      }
    case Types.POST_STORE_POS_LOADING:
      return {
        ...state,
        postStorePosIsLoading: true,
        postStorePosHasError: false,
      }
    case Types.POST_STORE_POS_ERROR:
      return {
        ...state,
        postStorePosIsLoading: false,
        postStorePosHasError: true,
      }
    default:
      return state
  }
}

export default storesPosReducer
