import Wallets from 'pages/Wallets'
import SystemWallets from 'pages/SystemWallets'

import Summary from 'pages/SummaryPayments'
import PosVendor from 'pages/PosVendor'
import Admin from 'pages/Admin'
import PosProduct from 'pages/PosProduct'
import RetailChain from 'pages/RetailChain'
import AccessManagement from 'pages/AccessManagement'
import Pix from 'pages/Pix'
import Stores from 'pages/Stores'
import StoreDetail from 'pages/StoreDetail'
import FeeSplitterManagement from 'pages/FeeSplitterManagement'
import Reconciliation from 'pages/Reconciliation'
import Customization from 'pages/Customization'
import LoadingTheme from 'pages/Loading'

export const ROUTES = [
  {
    link: '/pagamentos',
    name: 'Pagamentos',
    component: Summary,
    icon: 'payments',
  },
  {
    link: '/carteiras/painel',
    name: 'Carteiras Digitais',
    component: Wallets,
    icon: 'pixcard',
  },
  {
    link: '/lojas',
    name: 'Lojas',
    component: Stores,
    icon: 'store',
  },
  {
    link: '/lojas/:id',
    name: 'Detalhamento de Lojas',
    component: StoreDetail,
    icon: '',
  },
  {
    link: '/pix',
    name: 'Pix',
    component: Pix,
    icon: 'pixiconoutline',
  },
  {
    link: '/admin',
    name: 'Clientes',
    component: Admin,
    icon: 'customer',
  },
  {
    link: '/system-wallets',
    name: 'System Wallets',
    component: SystemWallets,
    icon: '',
  },
  {
    link: '/pos/vendor',
    name: 'Fabricantes de Software',
    component: PosVendor,
    icon: '',
  },
  {
    link: '/pos/product',
    name: 'Softwares de PDV',
    component: PosProduct,
    icon: '',
  },
  {
    link: '/retail-chain',
    name: 'Rede',
    component: RetailChain,
    icon: 'network',
  },
  {
    link: '/access-management',
    name: 'Controle de Acesso',
    component: AccessManagement,
    icon: '',
  },
  {
    link: '/fee-splitter',
    name: 'Gerenciamento de splits',
    component: FeeSplitterManagement,
    icon: '',
  },
  {
    link: '/reconciliation',
    name: 'conciliador',
    component: Reconciliation,
    icon: '',
  },
  {
    link: '/wl-settings',
    name: 'Customização',
    component: Customization,
    icon: '',
  },
  {
    link: '/loading-theme',
    name: 'Carregando tema',
    component: LoadingTheme,
    icon: '',
  },
]
