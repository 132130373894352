import {
  ActionInterface,
  Types,
  CertificateState,
  ResponseInterface,
} from './types'



const INITIAL_STATE: CertificateState = {
  postResponse: {code: -1, message: ''},
  postIsLoading: false,
  postHasError: false,
}

const certificateReducer = (
  state = INITIAL_STATE,
  action: ActionInterface 
): CertificateState => {
  switch (action.type) {
    case Types.POST_CERTIFICATE:
      return {
        postResponse: action.payload as ResponseInterface,
        postIsLoading: false,
        postHasError: false,
      }

    case Types.POST_CERTIFICATE_LOADING:
      return {
        ...state,
        postIsLoading: true,
      }

    case Types.POST_CERTIFICATE_ERROR:
      return {
        ...state,
        postHasError: true
      }
    default:
      return state
  }
}

export default certificateReducer