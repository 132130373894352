import * as s from './styles'

interface Props {
  closeModal: () => void
}

const RegisterPixRequestSent: React.FC<Props> = ({ closeModal }) => {
  return (
    <s.Content width={304} height={386}>
      <s.Text margin="0 0 32px 0" color="graytheme6" bold type="heading4">
        Solicitação enviada.
      </s.Text>
      <s.Text margin="0 0 32px 0" color="graytheme6" type="headline">
        Entraremos em contato por e-mail o mais brevemente possível para o envio
        dos dados de identidade.
      </s.Text>
      <s.Text margin="0 0 157px 0" color="graytheme6" type="headline">
        O processo total de verificação pode levar até 2 dias.
      </s.Text>
      <s.Button
        onClick={() => closeModal()}
        height="42px"
        color="whitedetailed"
      >
        <s.Text>Fechar</s.Text>
      </s.Button>
    </s.Content>
  )
}

export default RegisterPixRequestSent
