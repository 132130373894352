import React from 'react';

import * as s from "./styles";

const PosVendor: React.FC = () => {
  return (
    <s.Container>
      Fabricantes de Software (Software Houses)
    </s.Container>
  )
}

export default PosVendor;