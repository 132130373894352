export const Types = {
  GET_LOGO: 'GET_LOGO',
  GET_LOGO_LOADING: 'GET_LOGO_LOADING',
  GET_LOGO_ERROR: 'GET_LOGO_ERROR',
}

export interface PayloadInterface {
  is_logo_default: boolean
  pos_product_logo: any
  pos_product_name: String
}

export interface ActionInterface {
  type: keyof typeof Types
  payload: PayloadInterface
}

export interface LogoState {
  logo: PayloadInterface
  isLoading: boolean
  hasError: boolean
}
