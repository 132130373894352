import styled, { css, keyframes } from 'styled-components'
import LoadingCmp from 'components/Loading'
import IconCmp from 'components/IconExporter'
import TextCmp from 'components/Text'
import CheckboxCmp from 'components/Inputs/Checkbox'
import RadioButtonCmp from 'components/Inputs/RadioButton'
import InputTextCmp from 'components/Inputs/Text'
import { InputFieldGroup } from 'components/Inputs/Text/styles'
import DatepickerCmp from 'components/Datepicker'

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

const fade = keyframes`
  0% {
      height: 0;
      opacity: 0;
      visibility: hidden;
  }

  50% {
      opacity: 0.1;
      visibility: hidden;
  }


  100% {
      height: 60px;
      opacity: 1;
      visibility: visible;
  }
`

export const Text = styled(TextCmp)``

export const Tr = styled.tr``

export const THeadTr = styled.tr`
  ${({ theme }) => theme.assets.tableComponent.tableHeader}
`

export const TBodyTr = styled.tr<IExpandable>`
  max-height: 100px;
  width: min-content;
  background: ${({ theme }) => theme.colors.tableColor7};
  ${({ isCollapsable, isExpanded, theme }) =>
    isCollapsable
      ? css`
          :nth-child(4n + 1) {
            background: ${theme.colors.tableColor8};
          }
          :hover {
            position: ${isExpanded ? 'block' : 'relative'};
            box-shadow: ${isExpanded
              ? `box-shadow: 0px 1px 8px 4px rgba(92, 170, 229, 0.75)`
              : `0px 1px 8px 2px ${theme.colors.graytheme3}`};
            -webkit-box-shadow: ${isExpanded
              ? `box-shadow: 0px 1px 8px 4px rgba(92, 170, 229, 0.75)`
              : `0px 1px 8px 2px ${theme.colors.graytheme3}`};
            -moz-box-shadow: ${isExpanded
              ? `box-shadow: 0px 1px 8px 4px rgba(92, 170, 229, 0.75)`
              : `0px 1px 8px 2px ${theme.colors.graytheme3}`};
            cursor: pointer;
          }
        `
      : css`
          :nth-child(2n+1) {
            background: ${theme.colors.tableColor8};
          }
        `}
  ${(props) =>
    props.isExpanded
      ? `box-shadow: 0px 1px 8px 4px ${props.theme.colors.transparentmaincolor};
      z-index: 1;
      background: ${props.theme.colors.whiteshipay} ;
      position: relative;`
      : null};
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${({ theme }) => theme.assets.orders.tableBorderColor};

  table-layout: fixed;
  th,
  tr {
    border-top: 1px solid ${({ theme }) => theme.assets.orders.tableBorderColor};
  }
`
interface ITHead {
  isSticky: boolean
}

export const THead = styled.thead<ITHead>`
  color: ${({ theme }) => theme.colors.maincolor};
  text-align: left;
  vertical-align: bottom;
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'relative')};
  top: 0;
  z-index: 2;
`

export const TBody = styled.tbody``

interface IExpandable {
  isExpanded?: boolean
  isCollapsable?: boolean
}

export const ExpandableContainer = styled.div`
  height: 100%;
  padding-left: ${({ theme }) => theme.spacing(1)}px;
`

export const TrExpandable = styled.tr<IExpandable>`
  border-top: 1px solid ${({ theme }) => theme.colors.graytheme3};
  ${(props) =>
    props.isExpanded
      ? css`
          display: table-row;
        `
      : css`
          display: none;
        `}
`

export const TdExpandable = styled.td<IExpandable>`
  background-color: ${({ theme }) => theme.colors.lightmaincolor3};
  border: 0;
  animation: ${fade} 0.5s;
  position: relative;
  padding: 0;
  ${(props) =>
    props.isExpanded
      ? css`
          height: 60px;
          visibility: visible;
          opacity: 1;
          padding: 0px !important;
        `
      : css`
          height: 0px;
          visibility: hidden;
          opacity: 0;
          display: none;
        `};
`

export const Th = styled.th`
  font-weight: bold;
  padding: ${({ theme }) => theme.spacing(1)}px;
  vertical-align: middle;
  > svg {
    vertical-align: middle;
  }
`

export const Td = styled.td`
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(2)}px;
`

export const ActionButtonsTd = styled.td`
  text-align: center;
`

export const ActionItem = styled.div`
  cursor: pointer;
  :hover {
    animation: ${shake} 1s linear both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
`

export const Icon = styled(IconCmp)``

export const FilterWrapper = styled.div``

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
`

export const HeaderButton = styled.button<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: ${({ theme }) => theme.spacing(1)}px;
  border-radius: 8px;
  border: 0;
  background-color: ${(props) =>
    props.isOpen ? `${props.theme.colors.graytheme3}` : 'transparent'};
  width: fit-content;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.lightmaincolor3};
  }

  :active {
    background-color: ${({ theme }) => theme.colors.lightmaincolor2};
  }
`

export const FilterOptions = styled.div`
  position: relative;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing(1)}px;
  position: absolute;
  width: 256px;
  z-index: 3;
  box-shadow: ${({ theme }) => theme.shadows.gray2};
  max-height: 256px;
  overflow-y: hidden;
  background-color: ${({ theme }) => theme.colors.whiteshipay};
`

export const FilterList = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px;
  max-height: 200px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  background-color: ${({ theme }) => theme.colors.whiteshipay};

  ${InputFieldGroup} {
    height: 48px;
    position: sticky;
    z-index: 2px;
  }

  /* width */
  ::-webkit-scrollbar {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.graytheme1};
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 20px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.graytheme3};
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.graytheme4};
  }

  ::-webkit-scrollbar-thumb:active {
    background: ${({ theme }) => theme.colors.graytheme5};
  }
`

export const Overlay = styled.div`
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
`

export const SelectAllCheckbox = styled(CheckboxCmp)`
  margin-bottom: 8px;
  > label {
    font-weight: bold;
  }
`

export const Checkbox = styled(CheckboxCmp)`
  margin: 16px 0px;
`

export const RadioButton = styled(RadioButtonCmp)`
  margin-bottom: 8px;
`

export const InputText = styled(InputTextCmp)`
  height: 32px;
`

export const seeMoreButton = styled.button`
  ${({ theme }) => theme.assets.orders.columns.seeMoreButton};
`

export const FilterButton = styled.button`
  border-radius: 8px;
  border: 0;
  padding: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.gray2};

  :hover {
    cursor: pointer;
    box-shadow: ${({ theme }) => theme.shadows.blue3hover};

    > h5 {
      color: ${({ theme }) => theme.colors.maincolor};
    }
  }

  :active {
    background-color: ${({ theme }) => theme.colors.lightmaincolor3};
    box-shadow: ${({ theme }) => theme.shadows.blue4pressed};
  }

  > * {
    :first-child {
      margin-right: ${({ theme }) => theme.spacing(1)}px;
    }
  }
`

interface FilterAccordion {
  isFilterAccordionOpen: boolean
}

export const FilterAccordionContainer = styled.div<FilterAccordion>`
  /* height: 0px; */
  /* overflow: hidden; */
  opacity: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 0.5s linear;
  ${({ isFilterAccordionOpen }) =>
    isFilterAccordionOpen &&
    css`
      visibility: visible;
      transition: visibility 1s, opacity 0.5s linear;
      display: flex;
      opacity: 1;
    `}
`

export const MinimumValueInput = styled.input`
  width: 80px;
  height: 20px;
  border: none;
  border-radius: 2px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
  border: 2px solid ${({ theme }) => theme.colors.lightmaincolor2};
  box-shadow: 0px 1px 8px 1px #cce9ff;

  :hover {
    box-shadow: 0px 1px 8px 2px rgba(92, 170, 229, 0.3);
  }

  :focus {
    border: 2px solid ${({ theme }) => theme.colors.maincolor};
  }
`

export const MaximumValueInput = styled.input`
  width: 80px;
  height: 20px;
  border: none;
  border-radius: 2px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
  border: 2px solid ${({ theme }) => theme.colors.lightmaincolor2};
  box-shadow: 0px 1px 8px 1px #cce9ff;

  :hover {
    box-shadow: 0px 1px 8px 2px rgba(92, 170, 229, 0.3);
  }

  :focus {
    border: 2px solid ${({ theme }) => theme.colors.maincolor};
  }
`

export const ApplyButton = styled.button`
  position: absolute;
  transition: bottom ease 0.5s;
  background-color: ${({ theme }) => theme.colors.maincolor};
  margin-bottom: 4px;
  border-radius: 2px;
  border: 0;
  width: 88%;
  bottom: 8px;
  padding: 4px;
  color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 8px 1px ${({ theme }) => theme.colors.lightmaincolor1};

  :hover {
    box-shadow: 0px 1px 8px 3px rgba(92, 170, 229, 0.5);
    cursor: pointer;
  }

  :active {
    background: ${({ theme }) => theme.colors.darkmaincolor1};
    box-shadow: 0px 1px 8px 4px rgba(92, 170, 229, 0.75);
  }
`

export const OptionWrapper = styled.div``

export const StoreNameWrapper = styled.div`
  display: flex;
`
export const Loading = styled(LoadingCmp)`
  margin-left: 16px;
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`

export const Datepicker = styled(DatepickerCmp)`
  display: inline-block;
  width: 80px;
  margin-right: 8px;
  margin-bottom: 16px;

  .react-datepicker-popper {
    padding-top: 4px;
    position: fixed !important;
  }
`
