import { useState } from 'react'

import roleService from 'services/role'

interface UseGetRoles {
  loadRoles: (level: string) => Promise<void>
  roles: any[]
  isLoading: boolean
}

export const useGetRoles = (): UseGetRoles => {
  const [roles, setRoles] = useState([])
  const [isLoading, setisLoading] = useState(false)

  const loadRoles = async (level: string) => {
    setisLoading(true)
    const response = await roleService.getRoles(level)
    if (response && response.data) setRoles(response.data)
    setisLoading(false)
  }
  return { roles, isLoading, loadRoles }
}
