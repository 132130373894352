import React from 'react'
import * as s from './styles'

const UserVerificationDocumentSelection: React.FC<{
  docType: (type) => void
}> = ({ docType }) => {
  return (
    <s.Content
      height={609}
      width={520}
      data-testid="content-on-document-selection"
    >
      <s.HeaderStepsContainer>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="graytheme8">
            <s.Icon name="checkmark" fill="white" />
          </s.HeaderStepCircle>
          <s.Text>Introdução</s.Text>
        </s.HeaderStepWrapper>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="graytheme8">
            <s.Icon name="checkmark" fill="white" />
          </s.HeaderStepCircle>
          <s.Text>Questionário</s.Text>
        </s.HeaderStepWrapper>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="navbarvector1">
            <s.NumberStep>3</s.NumberStep>
          </s.HeaderStepCircle>
          <s.Text>Documento</s.Text>
        </s.HeaderStepWrapper>
      </s.HeaderStepsContainer>
      <s.Text
        color="graytheme8"
        type="heading4"
        bold
        margin="64px 0 32px 0"
        data-testid="question-number-counter"
      >
        Escolha um documento para fazer upload
      </s.Text>

      <s.CardsWrapper>
        <s.Button
          onClick={() => docType('rg')}
          width="520"
          height="226"
          color="whitedetailed"
          data-testid="rg-doc-button"
        >
          <s.CardWrapper>
            <s.CardIconFrame>
              <s.Icon name="rgdoc" fill="maincolor" />
            </s.CardIconFrame>
            <s.Text margin="16px 0 0 0" type="heading4" color="maincolor" bold>
              RG
            </s.Text>
          </s.CardWrapper>
        </s.Button>
        <s.Button
          onClick={() => docType('cnh')}
          width="520"
          height="226"
          color="whitedetailed"
          data-testid="cnh-doc-button"

        >
          <s.CardWrapper>
            <s.CardIconFrame>
              <s.Icon name="cnhdoc" fill="maincolor" />
            </s.CardIconFrame>
            <s.Text margin="16px 0 0 0" type="heading4" color="maincolor" bold>
              CNH
            </s.Text>
          </s.CardWrapper>
        </s.Button>
      </s.CardsWrapper>
    </s.Content>
  )
}

export default UserVerificationDocumentSelection
