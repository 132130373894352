import React from 'react'

import * as s from './styles'

interface IProps {
  alreadyExistsError: boolean
}

const Error: React.FC<IProps> = ({ alreadyExistsError }) => {
  return (
    <s.Sections data-testid="content-on-error">
      <s.Wrapper>
        <s.Text color="graytheme6" bold type="heading4">
          Não foi possível cadastrar sua Conta Pix
        </s.Text>
        {alreadyExistsError ? (
          <>
            <s.Text color="graytheme6" type="headline">
              Parece que essa Conta Pix já foi configurada na Shipay e ela só
              pode ser configurada uma vez.
            </s.Text>
            <s.Text color="graytheme6" type="headline">
              Verifique se essa Conta Pix está ativa e associada à uma Loja para
              poder transacionar.
            </s.Text>
            <s.Text color="graytheme6" type="headline">
              Caso não encontre sua Conta Pix entre em contato com nosso
              suporte.
            </s.Text>
          </>
        ) : (
          <>
            <s.Text color="graytheme6" type="headline">
              Por favor, verifique os dados da sua Conta Pix e tente fazer o
              cadastro novamente.
            </s.Text>
            <s.Text color="graytheme6" type="headline">
              Caso continue encontrando problemas com sua Conta Pix entre em
              contato com nosso suporte.
            </s.Text>
          </>
        )}
      </s.Wrapper>
    </s.Sections>
  )
}

export default Error
