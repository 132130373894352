import React, { useEffect, useState } from "react"
import * as s from './styles'
import { isNotEmpty } from "helpers/validators"
import {
  baseURLPrepayments,
  get,
  post
} from 'services/api'
import { useNonInitialEffect } from 'hooks/utils'
import { 
  formatMoney,
} from 'helpers/masks'
import { showToast } from 'components/Toast'
interface IProps {
  isOpen: string
  setCurrentStep: any
  setSimulationData: any
  handleClose: ()=> any
}


export const PrePaymentSimulation: React.FC<IProps> = ({ isOpen, handleClose, setCurrentStep, setSimulationData })=> {


  const formDefault = {
    amount: {value:'', validator: isNotEmpty, touched: false},
    days: {value:'', validator: isNotEmpty, touched: false},
    checkbox: {value: 'false', validator: (v) => {
      if(v == 'false') {
        showToast({
          type: 'error',
          message: 'Todos os campos são obrigatórios',
        })
      }
      return ( v === 'true' ? true : false)
    }, touched: false}
  }
  const [ form, setForm ] = useState(formDefault)
  const [ formErrors, setFormErrors ] = useState<any>(
    Object.keys(formDefault).reduce((acc, cur) => (
      { ...acc, [cur]: ''}
    ), {})
  )

  interface daySpan {
    id: string;
    text: string;
  }

  const [daysSpan, setDaysSpan] = React.useState<daySpan[]>([])
  const [ selectedSpan, setSelectedSpan ] = useState({ id: '', name: ''})
 

  const _setForm = (obj) => {
    const formObj = Object.entries(obj).reduce((acc, cur) => {
      const [ key, value ] = cur
      return {
        ...acc,
        [key]: {
          value: value,
          validator: form[key].validator,
          touched: form[key].touched,
        }
      }
    }, {})
    setForm({...form, ...formObj})
  }

  const _validateForm = (force = false) => {
    const invalidFields = Object.entries(form).reduce((acc, cur) => {
      const [ key, value ] = cur
      if (value.validator &&
        (value.touched || force) &&
        !value.validator(value.value)) {
        return {
          ...acc,
          [key]: 'Campo obrigatório',
        }
      }
      return {
        ...acc,
        [key]: '',
      }
    }, {})
    
    setFormErrors({...formErrors, ...invalidFields})
    if (invalidFields && Object.values(invalidFields).every(x => !x)) return true
    return false;
  }

  const getDaySpanList = async () => {
    get(`${baseURLPrepayments}/days`).then((res) => {
      setDaysSpan(res.data.map((x) => {
        return {id: x,
                text: x.replace('to', 'a')+" dias"
              } 
      }))
    }).catch((err) => {})
  }

  const newSimulation = () => {
    if(_validateForm(true)) {
      post(`${baseURLPrepayments}/simulate`, {
        "amount": parseInt(form.amount.value.replace(/\D/g, '')),
        "days": parseInt(form.days.value.match(/to (\d+)/)[1])
      }).then((r) => {
        setSimulationData(r.data)
        setCurrentStep(3)
      })
    }
  }

  React.useEffect(() => {
    getDaySpanList()
  }, [])

  useNonInitialEffect(() => {
    _validateForm()
  }, [form])

  return(
    <s.Modal 
      handleClose={handleClose}
      isOpen={(isOpen) ? true : false}
      modalSubtitle={"Simular taxa de antecipação"}>
      <s.BackBtn>
        <s.Icon onClick={(x) =>{setCurrentStep(1)}} name="backbtn" fill="white" />
      </s.BackBtn>   
      <s.Line/>
      <s.ModalContent>
        <s.InputGroup>
          <s.InputText
            label="Valor estimado a ser antecipado"
            value={form.amount.value}
            onChange={(e)=> _setForm({amount: formatMoney(e.target.value)})}
            error={formErrors.amount}
          />
        </s.InputGroup>
        <s.InputGroup>
          
          <s.Select
            optionBoxSize={200}
            label="Selecione o prazo médio para receber o valor antecipados"
            options={daysSpan.map((x) =>{
              return {
                id: x.id,
                text: x.text,
                onClick: ()=>{
                  setSelectedSpan({ id: x.id, name: x.text })
                  _setForm({days: x.id})
                }
              }
            })}
            selected={selectedSpan}
            error={formErrors.days}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.CheckboxWrapper>
            <div>
              <s.Checkbox
                onClick={(x) => {
                }}
                id={'agreementCheckbox'}
                checked={(form.checkbox.value === 'true' ? true : false)}
                onChange={(e)=> _setForm({checkbox: (form.checkbox.value === 'true') ? 'false' : 'true'})}
              />
            </div>
            <s.Text textAlign="justify" color="maincolor">
              Estou ciente de que as taxas e condições originados pela simulação
              devem ser utilizados meramente como base de comparação com os valores
              praticados pelo mercado. As taxas e condições reais podem variar e
              serão apresentadas na Proposta de Antecipação de Recebíveis.
            </s.Text>
          </s.CheckboxWrapper>
        </s.InputGroup>
      </s.ModalContent>
      <s.Line/>
      <s.Button onClick={()=> {
        newSimulation()
      }}
        color="primary">
        <s.Text bold color="white">
          Simular
        </s.Text>
      </s.Button>
    </s.Modal>
  )
}



