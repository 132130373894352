import React from 'react'
import ReconciliationDelayed from './Delayed'
import ReconciliationDetails from './ReconciliationDetails'
import ReconciliationSelector from './ReconciliationSelector'
import ReconciliationReturned from './Returned'
import ReconciliationDuplicated from './Duplicated'
import ReconciliationTransfered from './Transfer'

import * as s from './styles'

const Reconciliation: React.FC = () => {
  const [selectedOptions, setSelectedOptions] = React.useState<any>({})

  return (
    <s.Container>
      <s.GradientText type="heading3" bold color="lightmaincolor4">
        Conciliação Pix
      </s.GradientText>

      <s.Columns>
        <div>
          <ReconciliationSelector
            callback={(x) => {
              setSelectedOptions(x)
            }}
          />
        </div>
        <div>
          {selectedOptions.wallet_setting_id ? (
            <ReconciliationDetails options={selectedOptions} />
          ) : (
            <></>
          )}
          {selectedOptions.wallet_setting_id ? (
            <ReconciliationTransfered options={selectedOptions} />
          ) : (
            <></>
          )}
          {selectedOptions.wallet_setting_id ? (
            <ReconciliationDuplicated options={selectedOptions} />
          ) : (
            <></>
          )}
          {selectedOptions.wallet_setting_id ? (
            <ReconciliationDelayed options={selectedOptions} />
          ) : (
            <></>
          )}
          {selectedOptions.wallet_setting_id ? (
            <ReconciliationReturned options={selectedOptions} />
          ) : (
            <></>
          )}
        </div>
      </s.Columns>
    </s.Container>
  )
}

export default Reconciliation
