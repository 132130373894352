import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useTypedSelector } from 'store/modules/rootState'

import {
  GetUsers,
  GetUsersIsLoading,
  GetUsersError,
} from 'store/modules/user/action'

import { UserState } from 'store/modules/user/types'

import userService from 'services/user'

import { Partial } from 'helpers/partial'

interface UseGetUsers extends UserState {
  loadUsers: (level: string, targetId: string, params?: {
    limit: string
    offset: string
    orderby?: string,
    sort?: string,
    filter?: string,
    filter_type?: string
  }) => Promise<void>,
  cleanUpUserList: () => void
}


export const useGetUsers = (): Partial<UseGetUsers> => {
  const dispatch = useDispatch()

  const { users, getUsersIsLoading, getUsersError} = useTypedSelector((store) => ({
    users: store.user.users,
    getUsersIsLoading: store.user.getUsersIsLoading,
    getUsersError: store.user.getUsersError,
  }))

  const loadUsers = useCallback(async (level, targetId, params): Promise<void> => {
    dispatch(GetUsersIsLoading())
    if(level === 'retail-chain') {
      await userService.getUsers(level, targetId, params)
        .then((response) => {
          dispatch(GetUsers(response.data))
        })
        .catch(() => {
          dispatch(GetUsersError())
        })
    }
    if(level === 'customer') {
      await userService.getUsersByCostumer(targetId, params)
        .then((response) => {
          dispatch(GetUsers(response.data))
        })
        .catch(() => {
          dispatch(GetUsersError())
        })
    }
  }, [dispatch])

  const cleanUpUserList = () => {
    dispatch(GetUsers({ data: []} as any))
  }

  return { users, getUsersIsLoading, getUsersError, loadUsers, cleanUpUserList }
}
