import Cookies from 'js-cookie'

export const getCookieValue = (key: string) => {
  return Cookies.get(key)
}

export const setCookieValue = (key: string, value: string) => {
  let domain = ''

  if (
    window['env']['name'] !== 'development' &&
    window['env']['name'] !== 'local'
  ) {
    domain = window.location.hostname
  }

  Cookies.set(key, value, { domain })
}

export const removeCookieValue = (key: string) => {
  let domain = ''

  if (
    window['env']['name'] !== 'development' &&
    window['env']['name'] !== 'local'
  ) {
    domain = window.location.hostname
  }

  Cookies.remove(key, { domain })
}
