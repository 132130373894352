import styled from 'styled-components'
import { Container as ContainerCmp } from 'styled-bootstrap-grid'

import TextCmp from 'components/Text'
import ButtonCmp from 'components/Button'
import InputTextCmp from 'components/Inputs/Text'
import IconExporter from 'components/IconExporter'
import SelectCmp from 'components/Inputs/Select'
import LoadingCmp from 'components/Loading'
import ModalCmp from 'components/Modal'
import TableCmp from 'components/Table'
import PaginationCmp from 'components/Pagination'

export const Icon = styled(IconExporter)``

export const ButtonIcon = styled(IconExporter)``

export const Container = styled(ContainerCmp)`
  width: 1800px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  height: 100vh;

  max-width: 1680px;
  & > div {
    margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  }
`

export const Text = styled(TextCmp)``

export const GradientText = styled(TextCmp)`
  background: linear-gradient(
    ${({ theme }) => theme.colors.lightmaincolor4},
    ${({ theme }) => theme.colors.maincolor}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const Button = styled(ButtonCmp)``

export const ClientHeader = styled.div`
  display: flex;
  align-items: center;
  height: 50px;

  & > * {
    margin-right: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const InputText = styled(InputTextCmp)`
  width: 100%;
  margin-left: 16px;
`

export const Select = styled(SelectCmp)`
  width: 228px;
`

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`

export const InputsWrapper = styled.div`
  display: flex;
`

export const ButtonsWrapper = styled.div`
  display: flex;
`

export const Loading = styled(LoadingCmp)``

export const Modal = styled(ModalCmp)``

export const Table = styled(TableCmp)`
  > thead > tr > th {
    padding-left: ${({ theme }) => theme.spacing(2)}px;
    :first-child {
      width: 200px;
    }
    :nth-child(2) {
      width: 120px;
    }
    :nth-child(3) {
      width: 80px;
    }
    :nth-child(4) {
      width: 100px;
    }
    :nth-child(5) {
      width: 90px;
    }
    :nth-child(6) {
      width: 80px;
    }
    :nth-child(7) {
      width: 70px;
    }
    :nth-child(8) {
      width: 70px;
    }
  }
`

export const IdWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75%;
`

export const IdContainer = styled.div`
  ${({ theme }) => theme.assets.orders.columns.uuidContainer};
`

export const DateContainer = styled.div`
  display: flex;

  > p {
    :nth-child(2) {
      white-space: break-spaces;
    }
  }
`

export const Pagination = styled(PaginationCmp)``
