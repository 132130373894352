import { showToast } from 'components/Toast'
import { isHTML } from 'helpers'
import React from 'react'
import { bffv2URLWalletSettings, get } from 'services/api'

interface Field {
  key: string
  friendlyName: string
  valueType: string
  value?: string
  options?: Array<string>
  inputType: string
  placeholder?: string
  validators?: string[]
  maxLength?: number
  info?: string
}

interface Section {
  id: string
  key: string
  friendlyName: string
  icon?: string
  toggled?: boolean
  toggleButton?: any
  fields: Field[]
}

export interface ModalConfig {
  name?: string
  wallet_icon?: string
  wallet_friendly_name?: string
  sections: Section[]
}

interface IUseGetWalletSettingsModalEditConfig {
  modalConfig: ModalConfig
  setModalConfig: React.Dispatch<React.SetStateAction<any>>
  isLoading: boolean
  hasError: boolean
  hasSuccess: boolean
  getEditModalConfig: (pspProvider) => void
}

const useGetWalletSettingsModalEditConfig =
  (): IUseGetWalletSettingsModalEditConfig => {
    const [modalConfig, setModalConfig] = React.useState<ModalConfig>({
      sections: [],
    })
    const [isLoading, setIsLoading] = React.useState(false)
    const [hasError, setHasError] = React.useState(false)
    const [hasSuccess, setHasSuccess] = React.useState(false)

    const getEditModalConfig = async (walleSettingId: string) => {
      setIsLoading(true)
      const params = new URLSearchParams()

      await get(`${bffv2URLWalletSettings}/${walleSettingId}`, {
        params: params,
      })
        .then((response) => {
          setHasError(false)
          let modalConfig = {
            ...response.data,
            sections: response.data.sections.filter((section) => section),
          }
          setModalConfig(modalConfig)
          setHasSuccess(true)
        })
        .catch((e) => {
          if (!isHTML(e.request.response) && e.request.status < 500) {
            showToast({
              type: 'error',
              message: JSON.parse(e.request.response).message,
            })
          } else {
            showToast({
              type: 'error',
              message: `Erro no servidor, tente novamente mais tarde.`,
            })
          }
          setHasError(true)
          setModalConfig({ sections: [] })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    return {
      modalConfig,
      setModalConfig,
      hasError,
      isLoading,
      getEditModalConfig,
      hasSuccess,
    }
  }

export default useGetWalletSettingsModalEditConfig
