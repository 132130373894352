import React, { useState } from 'react'
import wizardService from 'services/wizard'

interface UseGetShouldRedirectWizard {
  loadShouldRedirect: () => Promise<void>
  shouldRedirect: boolean
}

export const useGetShouldRedirectWizard = (): UseGetShouldRedirectWizard => {
  const [shouldRedirect, setShouldRedirect] = useState()

  const loadShouldRedirect = React.useCallback(async () => {
    const response = await wizardService.getShouldRedirect()
    setShouldRedirect(response.data.should_init_wizard)
  }, [setShouldRedirect])

  return { shouldRedirect, loadShouldRedirect }
}
