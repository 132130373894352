import styled from 'styled-components'
import ButtonCmp from 'components/Button'
import TextCmp from 'components/Text'
import ModalCmp from 'components/Modal'
import TableCmp from 'components/Table'
import IconCmp from 'components/IconExporter'
import ExportCsv from 'components/ExportCsv'
import CheckboxCmp from 'components/Inputs/Checkbox'
import PaginationCmp from 'components/Pagination'

export const Modal = styled(ModalCmp)`
  position: relative;
  > div > div {
    overflow-y: scroll;
  }
`
export const Checkbox = styled(CheckboxCmp)``

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;

  > button {
    :first-child {
      margin-right: 16px;
    }
  }
`

export const ExportButton = styled(ExportCsv)``

export const ModalContainer = styled.div`
  max-width: 1700px;
  height: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${ButtonsWrapper} {
    margin-top: 56px;
    width: 30%;
  }
`

export const Button = styled(ButtonCmp)``

export const Icon = styled(IconCmp)``

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  flex-direction: row;
`

export const Text = styled(TextCmp)``

export const Table = styled(TableCmp)`
  max-width: 1650px;
`

export const Pagination = styled(PaginationCmp)``

export const Summary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  width: 100%;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  > h5 {
    padding-bottom: 20px;
  }
`
