import React from 'react'
import * as s from '../styles'
import { baseURLPixAnyBankResendToken, post } from 'services/api'
import { showToast } from 'components/Toast'
import { v4 as uuid } from 'uuid'

interface TokenFormProps {
  tokenFormState?: [string, React.Dispatch<React.SetStateAction<string>>]
  legalRepresentativeName: string
  registrationId: string
  cnpj: string
  canSendToken: (canSand: boolean) => void
}

interface TokenStatusProps {
  isSendingToken: boolean
  tokenSent: boolean
  resendTokenCountDown: number
}

const TokenForm: React.FC<TokenFormProps> = ({
  tokenFormState,
  legalRepresentativeName,
  registrationId,
  cnpj,
  canSendToken,
}) => {
  const [token, setToken] = tokenFormState
  const [tokenRequestStatus, setTokenRequestStatus] =
    React.useState<TokenStatusProps>({
      isSendingToken: false,
      tokenSent: false,
      resendTokenCountDown: 0,
    })
  const countdownIntervalRef = React.useRef<NodeJS.Timeout | null>(null)

  const [checks, setChecks] = React.useState<{
    firstCheck: boolean
    secondCheck: boolean
    thirdCheck: boolean
  }>({
    firstCheck: false,
    secondCheck: false,
    thirdCheck: false,
  })

  const checkAllConditions = React.useCallback(() => {
    const allChecksTrue =
      checks.firstCheck && checks.secondCheck && checks.thirdCheck
    const tokenStatusReady =
      !tokenRequestStatus.isSendingToken &&
      !tokenRequestStatus.tokenSent &&
      tokenRequestStatus.resendTokenCountDown === 0
    canSendToken(allChecksTrue && tokenStatusReady && !!token)
  }, [checks, token, tokenRequestStatus, canSendToken])

  React.useEffect(() => {
    checkAllConditions()
  }, [checkAllConditions])

  const startCountdown = () => {
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current)
    }

    setTokenRequestStatus((prevState) => ({
      ...prevState,
      resendTokenCountDown: 53,
    }))

    countdownIntervalRef.current = setInterval(() => {
      setTokenRequestStatus((prevState) => {
        if (prevState.resendTokenCountDown <= 0) {
          clearInterval(countdownIntervalRef.current as NodeJS.Timeout)
          return prevState
        }
        return {
          ...prevState,
          resendTokenCountDown: prevState.resendTokenCountDown - 1,
        }
      })
    }, 1000)
  }

  React.useEffect(() => {
    return () => {
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current)
        setTokenRequestStatus({
          ...tokenRequestStatus,
          isSendingToken: false,
          tokenSent: false,
          resendTokenCountDown: 0,
        })
      }
    }
  }, [])

  const resendToken = async () => {
    setTokenRequestStatus({
      ...tokenRequestStatus,
      isSendingToken: true,
    })
    await post(`${baseURLPixAnyBankResendToken}`, {
      psp_provider: 'picpay_anybank',
      document: cnpj,
      registration_id: registrationId,
    })
      .then(() => {
        showToast({
          type: 'success',
          message: 'Token Re-enviado',
        })
        setTokenRequestStatus({
          ...tokenRequestStatus,
          isSendingToken: false,
          tokenSent: true,
        })
        startCountdown()
      })
      .catch((error) => {
        showToast({
          type: 'error',
          message:
            `${JSON.parse(error.request?.response)?.message} ` ||
            'Algo deu errado',
        })
        setTokenRequestStatus({
          ...tokenRequestStatus,
          isSendingToken: false,
          tokenSent: false,
        })
      })
  }

  const renderTokenButton = (tokenStatus: TokenStatusProps) => {
    if (tokenStatus.tokenSent && tokenStatus.resendTokenCountDown <= 0) {
      setTokenRequestStatus({
        isSendingToken: false,
        tokenSent: false,
        resendTokenCountDown: 0,
      })
    }

    if (tokenStatus.tokenSent && tokenStatus.resendTokenCountDown > 50) {
      return (
        <s.TokenButton
          data-testid="resend-token-button-test"
          color="hollow"
          disabled={tokenRequestStatus.tokenSent}
          onClick={() => {
            resendToken()
          }}
          width="auto"
        >
          <s.Icon name="checkmarkcircleoutline" fill="graytheme6" />
          <s.Text color="graytheme6" type="paragraph">
            Novo token enviado por SMS
          </s.Text>
          {tokenRequestStatus.isSendingToken ? (
            <s.Loading type="spinner" color="white" />
          ) : null}
        </s.TokenButton>
      )
    }

    if (tokenStatus.tokenSent && tokenStatus.resendTokenCountDown <= 50) {
      return (
        <s.TokenButton
          data-testid="resend-token-button-test"
          color="hollow"
          disabled={tokenStatus.resendTokenCountDown != 0}
          onClick={() => {
            resendToken()
          }}
          width="auto"
        >
          <s.Icon name="clock" fill="graytheme6" />
          <s.Text color="graytheme6" type="paragraph">
            <s.resendTokenCounter>
              {tokenRequestStatus.resendTokenCountDown}
            </s.resendTokenCounter>{' '}
            s para solicitar novo token
          </s.Text>
          {tokenRequestStatus.isSendingToken ? (
            <s.Loading type="spinner" color="white" />
          ) : null}
        </s.TokenButton>
      )
    }

    return (
      <s.TokenButton
        data-testid="resend-token-button-test"
        color="hollow"
        disabled={tokenRequestStatus.isSendingToken}
        onClick={() => {
          resendToken()
        }}
        width="auto"
      >
        <s.Icon name="phone" fill="maincolor" />
        <s.Text color="maincolor" type="paragraph">
          {!tokenRequestStatus.isSendingToken
            ? 'Enviar novo token por SMS'
            : 'Reenviando...'}
        </s.Text>
        {tokenRequestStatus.isSendingToken ? (
          <s.Loading type="spinner" color="white" />
        ) : null}
      </s.TokenButton>
    )
  }
  return (
    <s.TokenFormContainer>
      <s.Text bold type="heading4" color="graytheme6">
        Confirmação por token
      </s.Text>
      <s.Text
        margin="32px 0 32px 0"
        fontWeight={600}
        type="headline"
        color="graytheme6"
      >
        Confirme o token enviado por SMS para o telefone do representante legal.
        Caso seja necessário, altere o número na etapa anterior.
      </s.Text>
      <s.SectionLine>
        <s.Text fontWeight={600} color={'graytheme6'} type="headline">
          Representante legal
        </s.Text>
        <s.InputText
          testId="name-test"
          width={290}
          disabled
          value={legalRepresentativeName}
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text fontWeight={600} type="headline" color={'graytheme6'}>
          Token
        </s.Text>
        <s.InputText
          testId="token-test"
          width={290}
          maxLength={14}
          placeholder="XXXXXXXX-XXXX-XXXX-XXXX"
          value={token}
          onChange={(e) => {
            setToken(e.target.value)
          }}
        />
      </s.SectionLine>
      <s.SectionLine>
        <div></div>
        {renderTokenButton(tokenRequestStatus)}
      </s.SectionLine>
      <s.Line margin="16px 0 0 0" />
      <s.CheckBoxContainer>
        <s.CheckboxWrapper>
          <s.Checkbox
            onChange={() => {
              setChecks({ ...checks, firstCheck: !checks.firstCheck })
            }}
            id={uuid()}
            checked={checks.firstCheck}
          />
          <s.Text>
            Concordo com as
            <s.ButtonLink href={''} target="_blank">
              Condições Gerais de Proposta da Abertura de Conta de Pagamento
              Pré-Paga.
            </s.ButtonLink>
          </s.Text>
        </s.CheckboxWrapper>
        <s.CheckboxWrapper>
          <s.Checkbox
            onChange={() => {
              setChecks({ ...checks, secondCheck: !checks.secondCheck })
            }}
            id={uuid()}
            checked={checks.secondCheck}
          />
          <s.Text>
            Concordo com as condições comerciais e com as
            <s.ButtonLink href={''} target="_blank">
              Condições Gerais do Serviço de Cobrança Pix.
            </s.ButtonLink>
          </s.Text>
        </s.CheckboxWrapper>
        <s.CheckboxWrapper>
          <s.Checkbox
            onChange={() => {
              setChecks({ ...checks, thirdCheck: !checks.thirdCheck })
            }}
            id={uuid()}
            checked={checks.thirdCheck}
          />
          <s.Text>
            Autorizo o cadastro de uma chave aleatória para identificar a Conta
            PicPay e está ciente e de acordo com o
            <s.ButtonLink href={''} target="_blank">
              Termo de Consentimento.
            </s.ButtonLink>
          </s.Text>
        </s.CheckboxWrapper>
      </s.CheckBoxContainer>
    </s.TokenFormContainer>
  )
}
export default TokenForm
