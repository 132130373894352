import { patch, baseURLWalletsSettings } from '../api'

const patchWalletSettingsCryptoValidade = (uuid: string) => {
  return patch(`${baseURLWalletsSettings}/crypto/validate/${uuid}`, {})
}

const cryptoService = {
  patchWalletSettingsCryptoValidade,
}

export default cryptoService
