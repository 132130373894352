import { useEffect, EffectCallback, DependencyList, useRef } from 'react'

export const useNonInitialEffect = (effect: EffectCallback, deps: DependencyList) => {
  const initialRender = useRef(true)

  useEffect(() => {
    let effectRet: void | (() => void | undefined) = () => {}

    if (initialRender.current) {
      initialRender.current = false
    }
    else {
      effectRet = effect()
    }

    if(effectRet && typeof effectRet === 'function') {
      return effectRet
    }
  }, deps)
}
