import React, { useCallback, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { useNonInitialEffect } from 'hooks/utils'

import * as s from './styles'
import * as t from './types'

export default function SearchSelect({
  selectedState,
  options,
  label,
  disabled = false,
  isLoading = false,
  optionBoxSize = 400,
  optionBoxDirection = 'bottom',
  emptyText = 'Não há opções disponíveis',
  suffix = undefined,
  handlerSuffix,
  error,
  ...rest
}: t.IProps) {
  const [isOptionsOpen, setOptionsOpen] = useState(false)
  const [selected, setSelected] = selectedState
  const [searchText, setSearchText] = useState(
    selected.id !== -1 ? selected.name : ''
  )

  useNonInitialEffect(() => {
    if (
      !isOptionsOpen &&
      options.findIndex(
        (option) => option.text.toLowerCase() === searchText.toLowerCase()
      ) === -1
    ) {
      setSearchText('')
      setSelected({ id: -1, name: '' })
    }
  }, [isOptionsOpen])

  const renderOptions = useCallback(() => {
    if (!options.length) {
      return (
        <s.OptionsItem data-testid="option-empty" key={uuid()}>
          <s.Text type="body" color="maincolor" bold>
            {emptyText}
          </s.Text>
        </s.OptionsItem>
      )
    }
    return options
      .filter((option) =>
        option.text.toLowerCase().includes(searchText.toLowerCase())
      )
      .map((option) => (
        <s.OptionsItem
          selected={option.id === selected.id}
          onClick={() => {
            if (option.onClick) {
              option.onClick()
              setSearchText(option.text)
            }
            setOptionsOpen(false)
          }}
          data-testid={`option-${option.text.replace(/\s/gs, '-')}`}
          key={uuid()}
        >
          {option.icon && (
            <s.Icon name={option.icon} color="gray2" width={21} height={21} />
          )}
          <s.Text type="body" color="maincolor" bold>
            {option.text}
          </s.Text>
        </s.OptionsItem>
      ))
  }, [options, selected, searchText, emptyText])

  const suffixRender = () => {
    if (isLoading) {
      return <s.Loading type="spinner" color="maincolor" />
    }

    if (suffix) {
      return suffix
    }

    return (
      <s.Icon
        name={isOptionsOpen ? 'arrowup' : 'arrowdown'}
        fill={disabled ? 'gray4' : 'maincolor'}
        width={21}
        height={21}
      />
    )
  }

  return (
    <s.Container disabled={disabled} isOptionsOpen={isOptionsOpen} {...rest}>
      {label ? (
        <s.LabelText
          bold
          type="headline"
          color={error && !disabled ? 'lightred1' : 'maincolor'}
        >
          {label}
        </s.LabelText>
      ) : null}
      <s.InputText
        value={searchText}
        data-testid="select-search-container"
        onClick={() => {
          if (!isLoading && !disabled) {
            setOptionsOpen((currentState) => !currentState)
          }
          if (suffix && handlerSuffix) {
            handlerSuffix()
          }
        }}
        onChange={(e) => setSearchText(e.target.value)}
        suffix={suffixRender()}
        error={disabled ? false : error}
        disabled={disabled}
      />

      {isOptionsOpen && (
        <>
          <s.OptionsWrapper
            optionBoxDirection={optionBoxDirection}
            optionBoxSize={optionBoxSize}
            hasLabel={!!label}
          >
            {renderOptions()}
          </s.OptionsWrapper>
          <s.Overlay
            data-testid="select-overlay"
            onClick={() => {
              setOptionsOpen(false)
            }}
          />
        </>
      )}
    </s.Container>
  )
}
