import styled from 'styled-components'

import CardCmp from 'components/Card'
import TextCmp from 'components/Text'
import UserTableCmp from 'components/UserTable'
import InputTextCmp from 'components/Inputs/Text'
import IconExporterCmp from 'components/IconExporter'
import LoadingCmp from 'components/Loading'

export const UserTable = styled(UserTableCmp)``

export const Text = styled(TextCmp)`
  margin-right: 10px;
  line-height: 40px;
`

export const CustomerTextDisplayGroup = styled.div`
  display: flex;
`

export const DisplayGroup = styled.div``

export const ModalContent = styled.div`
  width: inherit;
  max-height: 90vh;
`

export const Card = styled(CardCmp)`
  width: 100%;
  max-height: unset;
  height: unset;
  padding: 20px;
  margin-bottom: 40px;
`

export const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
`
export const InputText = styled(InputTextCmp)`
  #suffix {
    padding-right: 0px;
  }
`

export const EditButtonWrapper = styled.div`
  justify-content: center;
  display: flex;
  :hover {
    cursor: pointer;
  }
`
export const Icon = styled(IconExporterCmp)`
  margin-left: 8px;
  width: 32px;
  padding: 0px;
`

export const Loading = styled(LoadingCmp)`
  margin-left: 8px;
  width: 32px;
  padding: 0px;
`
