import { css, DefaultTheme } from 'styled-components'
import theme, {
  themeColors,
  assets as themeAssets,
  fontTypes,
  gridConfig,
  mediaQueries,
  shadows,
} from 'styles/theme'
import shipayLogo from 'assets/logos/logo.svg'
import loginBackground from 'assets/images/login-background-ndd.svg'
import wizardBackgroundShipay from 'assets/images/wizard-background-ndd.svg'
import {
  baseURLRegistrationSummaryPosProductComponents,
  get,
} from '../services/api'
import { useState, useEffect } from 'react'
import { assetsHandler } from 'helpers/whitelabel'
import { showToast } from 'components/Toast'

interface IUseGetTheme {
  userTheme: DefaultTheme
  isLoading: boolean
  hasError: boolean
  hasSuccess: boolean
  getTheme: () => void
}

const useGetTheme = (): IUseGetTheme => {
  const [userTheme, setUserTheme] = useState<DefaultTheme>(theme)
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [hasSuccess, setHasSuccess] = useState(false)

  const getTheme = async () => {
    setIsLoading(true)
    await get(`${baseURLRegistrationSummaryPosProductComponents}`)
      .then((response) => {
        setHasError(false)
        const {
          colors,
          domain,
          images,
          pagetitle,
          components_id,
        } = response.data
        const assets = assetsHandler(
          themeColors,
          colors,
          shipayLogo,
          loginBackground,
          wizardBackgroundShipay
        )
        setUserTheme({
          ...theme,
          assets: assets,
          colors: {
            ...theme.colors,
            graytheme3: '#CCCCCC',
            graytheme6: '#595959',
            ...colors,
          },
          domain,
          images,
          pageTitle: pagetitle,
          components_id,
        })
        setHasSuccess(true)
      })
      .catch((e) => {
        showToast({
          type: 'error',
          message: JSON.parse(e.request.response).message,
        })
        setHasError(true)
        setUserTheme(theme)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return { userTheme, hasError, isLoading, getTheme, hasSuccess }
}

export default useGetTheme
