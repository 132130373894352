import {
  Types,
  ResponseInterface,
} from './types'


export function PostCertificate(
  payload
): {
  payload: ResponseInterface
  type: string
} {
  return {
    type: Types.POST_CERTIFICATE,
    payload,
  }
}

export function PostCertificateIsLoading() {
  return {
    type: Types.POST_CERTIFICATE_LOADING,
  }
}

export function PostCertificateError() {
  return {
    type: Types.POST_CERTIFICATE_ERROR,
  }
}
