import React from 'react'

import {
  applyBankAccountItauMask,
  applyCnpjMask,
  applyCpfCnpjMask,
  applyNumberOnlyMask,
  applyPhoneMask,
} from 'helpers/masks'
import { SectionForm } from 'hooks/useGetWalletSettingAnybankModalConfig'

import * as s from '../styles'
import { dict } from 'components/IconExporter'
import { FormProps } from '..'
import { TSelected } from 'components/Inputs/Select/types'

const applyFieldMask = (validators, value) => {
  if (validators) {
    if (validators.includes('isValidCpfCnpj')) return applyCpfCnpjMask(value)
    if (validators.includes('isValidCnpj')) return applyCnpjMask(value)
    if (validators.includes('isValidPhone')) return applyPhoneMask(value)
    if (validators.includes('isNumberOnly')) return applyNumberOnlyMask(value)
    if (validators.includes('isBankAccountItau'))
      return applyBankAccountItauMask(value)
  }
  return value
}

const InputElement: React.FC<{
  key: string
  friendlyName: string
  valueType: string
  value?: string
  options?: Array<string>
  inputType: string
  placeholder: string
  maxLength: number
  validators: string[]
  selectState: [
    { id: string | number; name: string },
    React.Dispatch<React.SetStateAction<{ id: string | number; name: string }>>
  ]
  form: FormProps
  setForm: React.Dispatch<React.SetStateAction<FormProps>>
  formErrors: FormProps
  setFormErrors: React.Dispatch<React.SetStateAction<FormProps>>
}> = ({
  key,
  friendlyName,
  valueType,
  value,
  options,
  inputType,
  placeholder,
  maxLength,
  validators,
  selectState,
  form,
  setForm,
  formErrors,
  setFormErrors,
}) => {
  switch (inputType) {
    case 'text':
      return (
        <s.SectionLine>
          <s.Text
            fontWeight={600}
            type="headline"
            color={
              formErrors.destination_account[key] ? 'redshipay' : 'graytheme6'
            }
          >
            {friendlyName}
          </s.Text>
          <s.InputText
            placeholder={placeholder}
            maxLength={maxLength ?? undefined}
            testId={`${key}-test`}
            width={280}
            value={form.destination_account[key] || ''}
            onChange={(e) => {
              setForm({
                ...form,
                destination_account: {
                  ...form.destination_account,
                  [key]: applyFieldMask(validators, e.target.value),
                },
              })
              if (formErrors.destination_account[key]) {
                setFormErrors({
                  ...formErrors,
                  destination_account: {
                    ...formErrors.destination_account,
                    [key]: '',
                  },
                })
              }
            }}
            error={
              formErrors.destination_account[key]
                ? { message: formErrors.destination_account[key] }
                : false
            }
            suffix={
              formErrors.destination_account[key] ? (
                <s.Icon name="alertcirclefilled" fill="lightred2" />
              ) : (
                <></>
              )
            }
          />
        </s.SectionLine>
      )

    case 'search-select':
      const optionsList = options.map((value, index) => {
        return {
          id: String(index + 1),
          text: value,
          onClick: () => {
            selectState[1]({ id: index, name: value })
            setForm({
              ...form,
              destination_account: {
                ...form.destination_account,
                [key]: applyFieldMask(validators, value),
              },
            })
            if (formErrors.destination_account[key]) {
              setFormErrors({
                ...formErrors,
                destination_account: {
                  ...formErrors.destination_account,
                  [key]: '',
                },
              })
            }
          },
        }
      })

      if (form.destination_account[key] && selectState[0].name === '') {
        const selectedFromListBasedOnForm = optionsList.find(
          (item) => item.text === form.destination_account[key]
        )
        if (selectedFromListBasedOnForm) {
          selectState[1]({
            id: selectedFromListBasedOnForm.id,
            name: selectedFromListBasedOnForm.text,
          })
        }
      }

      return (
        <s.SectionLine>
          <s.Text
            fontWeight={600}
            type="headline"
            color={
              formErrors.destination_account[key] ? 'redshipay' : 'graytheme6'
            }
          >
            {friendlyName}
          </s.Text>
          <s.SearchSelect
            data-testid={`${key}-test`}
            options={optionsList}
            optionBoxSize={120}
            error={
              formErrors.destination_account[key]
                ? { message: formErrors.destination_account[key] }
                : false
            }
            selectedState={
              selectState as [
                TSelected,
                React.Dispatch<React.SetStateAction<TSelected>>
              ]
            }
          />
        </s.SectionLine>
      )

    case 'select': {
      let list = []
      const optionsList = options.map((value, index) => {
        return {
          id: String(index + 1),
          text: value,
        }
      })

      if (optionsList.length > 0) {
        list = optionsList.map((option) => {
          return {
            ...option,
            onClick: () => {
              selectState[1]({ id: option.id, name: option.text })
            },
          }
        })
      }

      if (form.destination_account[key] && selectState[0].name === '') {
        const selectedFromListBasedOnForm = list.find(
          (item) => item.text === form.destination_account[key]
        )
        if (selectedFromListBasedOnForm) {
          selectState[1]({
            id: selectedFromListBasedOnForm.id,
            name: selectedFromListBasedOnForm.text,
          })
        }
      }

      return (
        <s.SectionLine>
          <s.Text
            fontWeight={600}
            type="headline"
            color={
              formErrors.destination_account[key] ? 'redshipay' : 'graytheme6'
            }
          >
            {friendlyName}
          </s.Text>
          <s.Select
            optionBoxSize={150}
            width={280}
            testId={`${key}-test`}
            optionBoxDirection="bottom"
            options={list}
            error={
              formErrors.destination_account[key]
                ? { message: formErrors.destination_account[key] }
                : false
            }
            selected={selectState[0]}
            onSelectedOption={(selectedOption) => {
              if (selectedOption.id) {
                setForm({
                  ...form,
                  destination_account: {
                    ...form.destination_account,
                    [key]: applyFieldMask(validators, selectedOption.text),
                  },
                })
                if (formErrors.destination_account[key]) {
                  setFormErrors({
                    ...formErrors,
                    destination_account: {
                      ...formErrors.destination_account,
                      [key]: '',
                    },
                  })
                }
              }
            }}
          />
        </s.SectionLine>
      )
    }

    default:
      return <></>
  }
}

interface IProps {
  section: SectionForm
  formState: [FormProps, React.Dispatch<React.SetStateAction<FormProps>>]
  formErrorState: [FormProps, React.Dispatch<React.SetStateAction<FormProps>>]
}

const DestinationAccount: React.FC<IProps> = ({
  section,
  formState,
  formErrorState,
}) => {
  const [form, setForm] = formState
  const [formErrors, setFormErrors] = formErrorState
  const [selectedBank, setSelectedBank] = React.useState({
    id: '',
    name: '',
  })
  const [selectedCNPJ, setSelectedCNPJ] = React.useState({
    id: '',
    name: '',
  })

  const selectState = {
    document_number: [selectedCNPJ, setSelectedCNPJ],
    bank_institution: [selectedBank, setSelectedBank],
  }

  const sectionForDestinationAccount = {
    ...section,
    fields: section.fields.map((field) =>
      field.key === 'bank_institution'
        ? { ...field, inputType: 'search-select' }
        : field
    ),
  }

  return (
    <s.DestinationAccountWrapper>
      <s.ListBoxOutline>
        <s.Text type="paragraph" color="graytheme6">
          {section.disclaimer.value}
        </s.Text>
      </s.ListBoxOutline>
      <s.Align>
        <s.Icon name={section.icon as keyof typeof dict} fill="graytheme6" />
        <s.Text
          margin="0 0 0 8px"
          type="headline"
          fontWeight={600}
          color="graytheme6"
        >
          {section.friendlyName}
        </s.Text>
      </s.Align>
      {sectionForDestinationAccount.fields.map((field, index) => (
        <React.Fragment key={field.key}>
          {InputElement({
            key: field.key,
            friendlyName: field.friendlyName,
            valueType: field.valueType,
            value: field.value,
            options: field.options,
            inputType: field.inputType,
            placeholder: field.placeholder,
            maxLength: field.maxLength,
            validators: field.validators,
            selectState: selectState[field.key],
            form: form,
            setForm: setForm,
            formErrors: formErrors,
            setFormErrors: setFormErrors,
          })}
        </React.Fragment>
      ))}
    </s.DestinationAccountWrapper>
  )
}

export default DestinationAccount
