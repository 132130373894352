import React from 'react'
import { isNotEmpty } from 'helpers/validators'
import { formatPixKey, getPixKeyType } from 'helpers/pix'
import { baseURLRegistrationV2DestinationAccounts, post } from 'services/api'

import { showToast } from 'components/Toast'

import * as s from '../styles'
import { applyCpfCnpjMask } from 'helpers/masks'
import { errorTranslatorHandler } from 'helpers'

interface IProps {
  isOpen: boolean
  handleClose: () => any
}

const INITIAL_VALUE = {
  name: '',
  cpf_cnpj: '',
  pix_dict_key: '',
}

export const AddSplitAccount: React.FC<IProps> = ({ isOpen, handleClose }) => {
  const [splitAccountForm, setSplitAccountForm] = React.useState(INITIAL_VALUE)

  const [splitAccountFormErrors, setSplitAccountFormErrors] = React.useState(
    INITIAL_VALUE
  )

  const [isLoading, setIsLoading] = React.useState(false)

  const createSplitAccount = async (payload) => {
    setIsLoading(true)
    await post(`${baseURLRegistrationV2DestinationAccounts}`, payload)
      .then((res) => {
        showToast({
          type: 'success',
          message: 'Conta cadastrada com sucesso',
        })
        handleClose()
      })
      .catch((e) => {
        const error = JSON.parse(e.request.response).detail
        if (error[0].msg) {
          showToast({
            type: 'error',
            message: errorTranslatorHandler(error[0].msg),
          })
        } else {
          showToast({
            type: 'error',
            message: JSON.parse(e.request.response).detail,
          })
        }
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handlePost = () => {
    const err = { ...splitAccountFormErrors }
    for (const [key, value] of Object.entries(splitAccountForm)) {
      if (!err[key]) err[key] = isNotEmpty(value) ? '' : 'Campo Obrigatório'
    }

    setSplitAccountFormErrors(err)
    const errList = Object.values(err).filter((x) => x !== '')

    if (!errList || errList.length === 0) {
      const payload = {
        name: splitAccountForm.name,
        cpf_cnpj: splitAccountForm.cpf_cnpj,
        pix_dict_key: splitAccountForm.pix_dict_key.trim(),
        pix_dict_key_type: getPixKeyType(splitAccountForm.pix_dict_key.trim()),
        entity_type: 'reseller',
      }

      createSplitAccount(payload)
    }
  }

  const closeModal = () => {
    setSplitAccountForm(INITIAL_VALUE)
    setSplitAccountFormErrors(INITIAL_VALUE)
    handleClose()
  }

  const _setSplitAccountForm = (obj) => {
    setSplitAccountForm({ ...splitAccountForm, ...obj })
    Object.keys(obj).forEach((key) => {
      obj[key] = ''
      setSplitAccountFormErrors({ ...splitAccountFormErrors, ...obj })
    })
  }

  return (
    <s.Modal
      headerIcon="person"
      modalSubtitle="Cadastrar Nova Conta de Recebimento"
      handleClose={closeModal}
      isOpen={isOpen}
    >
      <s.ModalContainer>
        <s.Text>
          Esta conta receberá os valores referentes a eventuais comissões
          cobradas na taxa de transações do Pix Qualquer Banco Itaú realizadas
          pelos clientes do PDV.
        </s.Text>

        <s.InputGroup>
          <s.InputText
            label="Apelido da Conta"
            value={splitAccountForm.name}
            onChange={(e) => _setSplitAccountForm({ name: e.target.value })}
            error={
              splitAccountFormErrors.name
                ? { message: splitAccountFormErrors.name }
                : false
            }
          />
        </s.InputGroup>

        <s.InputGroup>
          <s.InputText
            label="Chave Pix"
            value={splitAccountForm.pix_dict_key}
            onChange={(e) =>
              _setSplitAccountForm({
                pix_dict_key: formatPixKey(e.target.value),
              })
            }
            error={
              splitAccountFormErrors.pix_dict_key
                ? { message: splitAccountFormErrors.pix_dict_key }
                : false
            }
          />
        </s.InputGroup>

        <s.InputGroup>
          <s.InputText
            label="CPF ou CNPJ da Conta Bancária"
            value={splitAccountForm.cpf_cnpj}
            onChange={(e) =>
              _setSplitAccountForm({
                cpf_cnpj: applyCpfCnpjMask(e.target.value),
              })
            }
            error={
              splitAccountFormErrors.cpf_cnpj
                ? { message: splitAccountFormErrors.cpf_cnpj }
                : false
            }
          />
        </s.InputGroup>

        <s.ButtonGroup>
          <s.Button
            onClick={() => {
              closeModal()
            }}
            disabled={isLoading}
            color="white"
          >
            <s.Text color="maincolor" bold>
              Cancelar
            </s.Text>
          </s.Button>
          <s.Button disabled={isLoading} onClick={() => handlePost()}>
            <s.Text color="white" bold>
              Confirmar
            </s.Text>
            {isLoading ? <s.Loading type="spinner" /> : null}
          </s.Button>
        </s.ButtonGroup>
      </s.ModalContainer>
    </s.Modal>
  )
}
