import React, { useEffect, useState } from 'react'

import loadingbigger from 'assets/images/loading-bigger.png'

import * as s from './styles'
import { STEP_INITIAL_STATE, Steps } from './WalletSettingsFormTbanksCashout'
import { useNonInitialEffect } from 'hooks/utils'

const ONE_MINUTE = 60 * 1000

interface LoadingDocumentSentProps {
  documentscopyStatus: 'valid' | 'invalid' | 'uncertain' | 'error' | 'valid_with_error'| '' 
  step: Steps
  setStep: (step: Steps) => void
}

const LoadingOnDocumentSent: React.FC<LoadingDocumentSentProps> = ({
  documentscopyStatus,
  step,
  setStep,
}) => {
  const [hasOneMinutePassed, setHasOneMinutePassed] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setHasOneMinutePassed(true), ONE_MINUTE)
    return () => clearTimeout(timer)
  }, [])

  useNonInitialEffect(() => {
    if (documentscopyStatus) {
      if (documentscopyStatus === 'valid') {
        setStep({
          ...STEP_INITIAL_STATE,
          validDocumentscopyStatus: true,
        })
      }
      if (documentscopyStatus === 'invalid') {
        setStep({
          ...STEP_INITIAL_STATE,
          invalidDocumentscopyStatus: true,
        })
      }
      if (documentscopyStatus === 'uncertain') {
        setStep({
          ...STEP_INITIAL_STATE,
          uncertainDocumentscopyStatus: true,
        })
      }
      if (documentscopyStatus === 'error') {
        setStep({
          ...STEP_INITIAL_STATE,
          unavailableDocumentscopyStatus: true,
        })
      }

      if (documentscopyStatus === 'valid_with_error') {
        setStep({
          ...STEP_INITIAL_STATE,
          uncertainDocumentscopyStatus: true,
        })
      }
    }
  }, [documentscopyStatus])

  return (
    <s.Content height={609} width={520} data-testid="content-on-loading">
      <s.HeaderStepsContainer>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="graytheme8">
            <s.Icon name="checkmark" fill="white" />
          </s.HeaderStepCircle>
          <s.Text>Introdução</s.Text>
        </s.HeaderStepWrapper>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="graytheme8">
            <s.Icon name="checkmark" fill="white" />
          </s.HeaderStepCircle>
          <s.Text>Questionário</s.Text>
        </s.HeaderStepWrapper>
        <s.HeaderStepWrapper>
          <s.HeaderStepCircle backgroundColor="navbarvector1">
            <s.NumberStep>3</s.NumberStep>
          </s.HeaderStepCircle>
          <s.Text>Documento</s.Text>
        </s.HeaderStepWrapper>
      </s.HeaderStepsContainer>
      <s.Text
        margin="141px 0px 0px 0px"
        textAlign="center"
        color="graytheme6"
        bold
        type="heading4"
      >
        Verificando usuário...
      </s.Text>
      {hasOneMinutePassed ? (
        <s.Text
          data-testid="one-minute-passed"
          margin="16px 0px 0px 0px"
          textAlign="left"
          color="graytheme6"
          type="headline"
        >
          Infelizmente a verificação está levando mais tempo do que o esperado.
          Por favor, permaneça nessa página, em caso de dúvidas entre em contato
          com o suporte.
        </s.Text>
      ) : (
        <s.Text
          data-testid="until-one-minute"
          margin="16px 0px 0px 0px"
          textAlign="center"
          color="graytheme6"
          type="headline"
        >
          Deve levar alguns segundos. <br /> Por favor, permaneça nessa página.
        </s.Text>
      )}
      <s.LoadingGradient>
        <img width={114} src={loadingbigger} />
      </s.LoadingGradient>
    </s.Content>
  )
}

export default LoadingOnDocumentSent
