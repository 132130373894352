import React from 'react'
import { useTheme } from 'styled-components'

import { baseURLLoadLogo, putFormData, remove } from 'services/api'

import useGetLogo from 'hooks/useGetLogo'

import { showToast } from 'components/Toast'

import * as s from './styles'

interface IProps {
  isOpen: boolean
  handleClose: () => void
}

const UploadFileModal: React.FC<IProps> = ({ isOpen, handleClose }) => {
  const [userFile, setUserFile] = React.useState<File>()
  const [deleteFileConfirmationModal, setDeleteFileConfirmationModal] =
    React.useState<boolean>()
  const [userLogoName, setUserLogoName] = React.useState<string>('')
  const { loadLogo, logo } = useGetLogo()
  const theme = useTheme()

  React.useEffect(() => {
    if (isOpen) {
      setUserFile(null)
      const logoArray = logo?.pos_product_logo?.split('/')
      const logoName = logoArray ? logoArray[logoArray.length - 1] : null
      setUserLogoName(logoName ?? null)
    } else {
      loadLogo()
    }
  }, [isOpen])

  const uploadRules =
    (userFile?.type !== 'image/png' &&
      userFile?.type !== 'image/jpeg' &&
      userFile?.type !== 'image/jpg' &&
      userFile?.type !== 'image/svg') ||
    userFile?.size >= 8000

  const handleImage = async () => {
    var data = new FormData()
    data.append('pos_product_logo', userFile)
    await putFormData(baseURLLoadLogo, data)
      .then(() => {
        showToast({
          type: 'success',
          message: 'Imagem enviada com sucesso',
        })
        handleClose()
      })
      .catch((e) => {
        showToast({
          type: 'error',
          message: e.response.data.detail[0],
        })
      })
  }

  const deleteImage = async () => {
    remove(`${baseURLLoadLogo}`)
      .then((response) => {
        showToast({ type: 'success', message: response.data })
        handleClose()
      })
      .catch((e) => {
        showToast({ type: 'error', message: e.response.data.detail[0] })
      })
  }

  const onFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      const file = event.target.files[0]
      setUserFile(file)
      event.target.value = ''
    }
  }

  return (
    <s.Modal
      handleClose={handleClose}
      isOpen={isOpen}
      modalSubtitle="Configurar logo do sistema de PDV"
    >
      <s.ConfirmationModal
        handleClose={() => {
          setDeleteFileConfirmationModal(false)
        }}
        isOpen={deleteFileConfirmationModal}
      >
        <s.Text type="headline">
          Tem certeza que deseja deletar a logo customizada e usar a logo{' '}
          {theme.domain === 'Shipay' ? 'Shipay' : 'Conexão Itaú'}?
        </s.Text>
        <s.ButtonWrapper>
          <s.Button
            onClick={() => {
              setDeleteFileConfirmationModal(false)
            }}
            color="white"
            outline
          >
            Voltar
          </s.Button>
          <s.Button
            onClick={() => {
              deleteImage()
              setDeleteFileConfirmationModal(false)
            }}
          >
            Confirmar
          </s.Button>
        </s.ButtonWrapper>
      </s.ConfirmationModal>
      <s.Line />
      <s.ModalContent>
        <s.Text>
          Configure aqui a logo do seu sistema de PDV que será visualizada por
          todos os seus clientes que acessarem o painel. <br />
          <b>Resolução recomendada: 158px x 30px.</b>
        </s.Text>
        <s.UploadContent>
          <s.InputUpload type="file" onChange={onFileSelect} />
          <s.Icon name="upload" fill="graytheme6" width={21} height={21} />
          <s.Text bold margin="0px 0px 0px 10px">
            Selecione o arquivo
          </s.Text>
        </s.UploadContent>
        {userFile && (
          <>
            {uploadRules ? (
              <s.TextFailFile>
                Formatos de arquivo aceitos: ".png", ".svg", ".jpeg" e ".jpg".
                Tamanho máximo permitido: 8kB.
              </s.TextFailFile>
            ) : (
              <s.RulesContain>
                <s.TextParagraph>
                  <b>Arquivo selecionado:</b> {userFile.name.substr(0, 30)}
                  {userFile.name.length > 30 &&
                    '...' +
                      userFile.name.substr(
                        userFile.name.length - 5,
                        userFile.name.length
                      )}{' '}
                  - {(userFile.size / 1000000).toFixed(3)} MB
                </s.TextParagraph>
                <s.Icon
                  name="close"
                  fill="redshipay"
                  onClick={() => {
                    setUserFile(null)
                  }}
                />
              </s.RulesContain>
            )}
          </>
        )}
        <s.Space />
      </s.ModalContent>
      <s.ButtonModal
        disabled={uploadRules}
        onClick={() => handleImage()}
        color="primary"
      >
        <s.Text bold color="white">
          Confirmar
        </s.Text>
      </s.ButtonModal>
      {!logo.is_logo_default ? (
        <>
          <s.Line />
          <s.RulesContain>
            <s.DeleteLogoButton
              width="100%"
              outline
              color="white"
              disabled={logo.is_logo_default}
              onClick={() => {
                setDeleteFileConfirmationModal(true)
              }}
            >
              <s.Icon
                name="trash"
                fill={logo.is_logo_default ? 'white' : 'maincolor'}
              />
              <s.Text bold color={logo.is_logo_default ? 'white' : 'maincolor'}>
                Deletar logo customizado
              </s.Text>
            </s.DeleteLogoButton>
          </s.RulesContain>
        </>
      ) : null}
    </s.Modal>
  )
}

export default UploadFileModal
