import React from 'react'

import { ApiListFileReference } from '../FileApi'

import { DownloadButton, CreatedAt } from './CustomCells'

import * as s from './styles'

export interface FilesModalListProps extends Partial<ApiListFileReference> {
  isLoading?: boolean
  downloadFile?(fileName: string): void
  listOffset?: string
  setListOffset?(offset: string): void
  size?: number
  paginationUpdateRef?: React.MutableRefObject<any>
  refresh?(): void
}

function date_str_to_local_date_str(date: string) {
  return date.split(' ')[0]
    .split('-')
    .reverse()
    .join('/')
}

// List content states

export const FilesListModalList: React.FC<FilesModalListProps> = ({
  isLoading, downloadFile, data = [], listOffset = '0', total, setListOffset, size = 0, paginationUpdateRef
}) => {
  const displayData = React.useMemo(() => {
    return data.map((file) => {
      const created_at = date_str_to_local_date_str(file.created_at)
      let display_file_name = created_at
      if (file.part) { display_file_name += `(${file.part})` }
      return {
        ...file,
        created_at: created_at,
        display_file_name: display_file_name,
        date_range: `${date_str_to_local_date_str(file.start_date)} até
          ${date_str_to_local_date_str(file.end_date)}`,
        status_and_file_name: `${file.status}::${file.file_name}`
      }
    })
  }, [data])
  return (<>
    <s.FilesTable
      data={displayData}
      isLoading={isLoading}
      customEmptyMessage={"Nenhum arquivo encontrado"}
      columns={[
        {
          headerName: 'Criação do arquivo',
          objectName: 'display_file_name',
          AltRender: CreatedAt,
        },
        { headerName: 'Intervalo de datas', objectName: 'date_range' },
        { headerName: 'Tipo', objectName: 'extension' },
        {
          headerName: ' ',
          objectName: 'status_and_file_name',
          AltRender: ({ children }) => <DownloadButton downloadFile={downloadFile}>{children}</DownloadButton>,
        },
      ]}
    />
    <s.Spacer />
    <s.StepWrapper>
      <s.Pagination
        count={size}
        offset={listOffset}
        total={total}
        handleChange={(offset: string) => setListOffset && setListOffset(offset)}
        paginationUpdateRef={paginationUpdateRef}
      />
    </s.StepWrapper>
  </>)
}