import React from 'react'
import Modal from 'components/Modal'
import { MessageBox } from './components'
import { isNotEmpty } from 'helpers/validators'
import { useNonInitialEffect } from 'hooks/utils'
import { PixWSInterface } from 'services/pixWalletSettings'
import { Partial } from 'helpers/partial'
import { usePostPixWS } from 'hooks/pixWS'
import { showToast } from 'components/Toast'
import * as s from './styles'
import { isSpacePay } from 'helpers'

interface IProps {
  isOpen: string
  handleClose: () => any
}

const MP_DOC_URL =
  'https://shipay.freshdesk.com/support/solutions/articles/154000127010-mercado-pago-pix-'

export const WalletSettingsFormMP: React.FC<IProps> = ({
  isOpen,
  handleClose,
}) => {
  const [walletSettingsForm, setWalletSettingsForm] = React.useState({
    apelido: '',
    access_token: '',
  })

  const [walletSettingsFormErrors, setWalletSettingsFormErrors] =
    React.useState({
      apelido: '',
      access_token: '',
    })

  const { postResponse, postError, createPixWS } = usePostPixWS()

  useNonInitialEffect(() => {
    if (postResponse.code !== -1) {
      handleClose()
    }
  }, [postResponse])
  const handlePost = () => {
    const err = { ...walletSettingsFormErrors }
    for (const [key, value] of Object.entries(walletSettingsForm)) {
      if (!err[key]) err[key] = isNotEmpty(value) ? '' : 'Campo Obrigatório'
    }
    setWalletSettingsFormErrors(err)

    const errList = Object.values(err).filter((x) => x !== '')
    if (!errList || errList.length === 0) {
      const payload = {
        access_key: walletSettingsForm.access_token,
        active: true,
        collector_id: walletSettingsForm.access_token.slice(
          walletSettingsForm.access_token.lastIndexOf('-') + 1
        ),
        default: false,
        enabled_pix: true,
        name: walletSettingsForm.apelido,
        psp_provider: 'mercadopago',
        public_key: '',
        wallet_name: 'pix',
      } as Partial<PixWSInterface>
      createPixWS(payload)
    }
  }

  const _setWalletSettingsForm = (obj) => {
    setWalletSettingsForm({ ...walletSettingsForm, ...obj })
    Object.keys(obj).forEach((key) => {
      obj[key] = ''
      setWalletSettingsFormErrors({ ...walletSettingsFormErrors, ...obj })
    })
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={!!isOpen}
      modalSubtitle="Conta Pix"
    >
      <s.ModalContent>
        {!isSpacePay() ? <MessageBox url={MP_DOC_URL} /> : <></>}
        <s.InputGroup>
          <s.InputText
            maxLength={100}
            label="Apelido"
            placeholder={'Ex:"Loja Paulista", "loja@paulista.com", etc.'}
            onChange={(e) =>
              _setWalletSettingsForm({ apelido: e.target.value })
            }
            error={
              walletSettingsFormErrors.apelido
                ? { message: walletSettingsFormErrors.apelido }
                : false
            }
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputText
            maxLength={100}
            label={'Access Token'}
            onChange={(e) =>
              _setWalletSettingsForm({ access_token: e.target.value })
            }
            error={
              walletSettingsFormErrors.access_token
                ? { message: walletSettingsFormErrors.access_token }
                : false
            }
          />
        </s.InputGroup>
        <s.ButtonGroup>
          <s.Button onClick={() => handlePost()}>Cadastrar</s.Button>
          <s.Button color="tertiary" onClick={() => handleClose()}>
            Cancelar
          </s.Button>
        </s.ButtonGroup>
      </s.ModalContent>
    </Modal>
  )
}
