import React from 'react'
import loadingbigger from 'assets/images/loading-bigger.png'
import * as s from './styles'

const Loading: React.FC = () => {
  return (
    <s.Sections  data-testid="content-on-loading">
      <s.LoadingWrapper>
        <s.Text color="graytheme6" bold type="heading4">
          Cadastrando conta...
        </s.Text>
        <s.Text margin='32px 0 32px 0' color="graytheme6" type="headline">
          Deve levar alguns segundos. <br /> Por favor, permaneça nessa página.
        </s.Text>
        <s.LoadingGradient>
          <img width={110} src={loadingbigger} />
        </s.LoadingGradient>
      </s.LoadingWrapper>
    </s.Sections>
  )
}

export default Loading
