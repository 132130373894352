import styled from 'styled-components'

interface IAnchorProps {
  disabled: boolean
}
export const Link = styled.a<IAnchorProps>`
  align-items: center;
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.graytheme3
      : props.theme.colors.maincolor};
  cursor: pointer;
  display: inline-flex;
  ${(props) => props.theme.fontTypes.button}

  svg {
    height: 21px;
    path {
      fill: currentColor;
    }
  }
`
