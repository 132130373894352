import React from 'react'

import * as s from './styles'

interface IProps {
  isLoading?: boolean
  closeModal: () => void
  handleAction: () => void
  closeText?: string
  confirmText?: string
  hideButtons?: 'none' | 'first' | 'second'
  disabled?: boolean
}

const ModalButtonFooter: React.FC<IProps> = ({
  isLoading = false,
  closeModal = null,
  handleAction,
  closeText = '',
  confirmText = '',
  hideButtons = 'none',
  disabled = false,
  ...rest
}) => {
  const renderButtons = () => {
    switch (hideButtons) {
      case 'none':
        return (
          <>
            <s.Button
              onClick={() => {
                closeModal()
              }}
              disabled={isLoading}
              color="white"
              width="120px"
            >
              <s.Text color="maincolor" bold>
                {closeText ? closeText : 'Cancelar'}
              </s.Text>
            </s.Button>
            <s.Button
              width="150px"
              disabled={isLoading || disabled}
              onClick={() => handleAction()}
            >
              <s.Text color="white" bold>
                {confirmText ? confirmText : 'Confirmar'}
              </s.Text>
              {isLoading ? <s.Loading type="spinner" /> : null}
            </s.Button>
          </>
        )
      case 'first':
        return (
          <>
            <div></div>
            <s.Button
              width="150px"
              disabled={isLoading || disabled}
              onClick={() => handleAction()}
            >
              <s.Text color="white" bold>
                {confirmText ? confirmText : 'Confirmar'}
              </s.Text>
              {isLoading ? <s.Loading type="spinner" /> : null}
            </s.Button>
          </>
        )
      case 'second':
        return (
          <>
            <s.Button
              onClick={() => {
                closeModal()
              }}
              disabled={isLoading}
              color="white"
              width="120px"
            >
              <s.Text color="maincolor" bold>
                {closeText ? closeText : 'Cancelar'}
              </s.Text>
            </s.Button>
            <div></div>
          </>
        )
    }
  }

  return <s.ButtonGroup {...rest}>{renderButtons()}</s.ButtonGroup>
}

export default ModalButtonFooter
