import { ActionInterface, Types, StoreWalletsState } from './types'

const INITIAL_STATE: StoreWalletsState = {
  storeWallets: { count: -1, data: [], offset: 0, total: -1 },
  isLoading: false,
  hasError: false,
  deleteHasError: false,
  deleteIsLoading: false,
  patchHasError: false,
  patchIsLoading: false,
  postHasError: false,
  postIsLoading: false,
}

const storesReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): StoreWalletsState => {
  switch (action.type) {
    case Types.GET_STORE_WALLETS:
      return {
        ...state,
        storeWallets: action.payload,
        isLoading: false,
        hasError: false,
      }
    case Types.GET_STORE_WALLETS_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_STORE_WALLETS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    case Types.DELETE_STORE_WALLET_SUCCESS:
      return {
        ...state,
        deleteIsLoading: false,
        deleteHasError: false,
      }
    case Types.DELETE_STORE_WALLET_LOADING:
      return {
        ...state,
        deleteIsLoading: true,
        deleteHasError: false,
      }
    case Types.DELETE_STORE_WALLET_ERROR:
      return {
        ...state,
        deleteHasError: true,
        deleteIsLoading: false,
      }
    case Types.PATCH_STORE_WALLET:
      return {
        ...state,
        patchIsLoading: false,
        patchHasError: false,
      }
    case Types.PATCH_STORE_WALLET_LOADING:
      return {
        ...state,
        patchIsLoading: true,
        patchHasError: false,
      }
    case Types.PATCH_STORE_WALLET_ERROR:
      return {
        ...state,
        patchIsLoading: false,
        patchHasError: true,
      }
    case Types.POST_STORE_WALLET:
      return {
        ...state,
        postIsLoading: false,
        postHasError: false,
      }
    case Types.POST_STORE_WALLET_LOADING:
      return {
        ...state,
        postIsLoading: true,
        postHasError: false,
      }
    case Types.POST_STORE_WALLET_ERROR:
      return {
        ...state,
        postIsLoading: false,
        postHasError: true,
      }
    default:
      return state
  }
}

export default storesReducer
