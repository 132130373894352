import {
  patch,
  get,
  post,
  baseURLPixWalletSettings,
  baseURLWalletSettingsSaga,
} from '../api'
import { Partial } from 'helpers/partial'

export interface PixWSInterface {
  client_id: string
  client_secret: string
  dict_key: string
  pix_dict_key_type: string
  transaction_type: string
  name: string
  access_key: string
  collector_id: string
  psp_provider: string
  wallet_name: string
  default: boolean
  active: boolean
  enabled_pix: boolean
  public_key: string
  tls_certificate: string
  temporary_token: string
  bank_slip_settings?: {
    recipient_id: string
    wallet_code: string
  }
}

export interface PixWSBFFInterface {
  wallet_settings: {
    client_id?: string
    client_secret?: string
    pix_dict_key: string
    pix_dict_key_type: string
    transaction_type: string
    name: string
    access_key: string
    collector_id?: string
    psp_provider: string
    wallet_name: string
    default: boolean
    active: boolean
    enabled_pix?: boolean
    public_key?: string
    tls_certificate?: string
    temporary_token?: string
  }
  bank_slip_settings?: {
    recipient_id?: string
    wallet_code: string
  }
}

const createPixWS = (ws: Partial<PixWSInterface>) => {
  return post(`${baseURLWalletSettingsSaga}`, ws)
}

const getPixWS = (id: string) => {
  return get(`${baseURLPixWalletSettings}/${id}`)
}

const editPixWS = (id: string, ws: Partial<PixWSInterface>) => {
  return patch(`${baseURLPixWalletSettings}/${id}`, ws)
}

const pixWSService = {
  getPixWS,
  editPixWS,
  createPixWS,
}

export default pixWSService
