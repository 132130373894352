import React from 'react'

import { useTranslation } from 'react-i18next'

import { isSpacePay } from 'helpers'

import useWalletSettingsSaga from 'hooks/useWalletSettingsSaga'
import { useSSE } from 'hooks/utils/useSSE'
import useWalletsSettings from 'hooks/useWalletsSettings'
import { useNonInitialEffect } from 'hooks/utils'

import customerService from 'services/customer'

import { showToast } from 'components/Toast'

import * as s from '../styles'

const AmeConfigWallet: React.FC<{
  handleClose: () => void
  isModalOpen: boolean
  offset?: string
}> = ({ handleClose, isModalOpen, offset }) => {
  const { t } = useTranslation()
  const { loadWalletsSettings } = useWalletsSettings()
  const { postIsLoading, postResponse, postEWalletWalletSettingsSaga } =
    useWalletSettingsSaga()

  const [input, setInput] = React.useState({
    active: true,
    client_id: '',
    client_secret: '',
    default: false,
    name: '',
    wallet_name: 'ame',
  })

  const [inputPressed, setInputPressed] = React.useState<any>({
    ...Object.keys(input).reduce((acc, key) => {
      acc[key] = false
      return acc
    }, {}),
  })

  const [showHelper, setShowHelper] = React.useState(false)

  const { openConnection, notifications } = useSSE()

  const [customerUUID, setCustomerUUID] = React.useState<string>('')
  const [isSSELoading, setSSEIsLoading] = React.useState<boolean>(false)

  const handlePost = async () => {
    postEWalletWalletSettingsSaga({ ...input })
  }

  useNonInitialEffect(() => {
    if (notifications) {
      const status = notifications[customerUUID].message
        .toLowerCase()
        .includes('sucesso')
        ? 'success'
        : 'error'
      showToast({
        type: status,
        message: notifications[customerUUID].message,
      })
      setSSEIsLoading(false)
      if (status === 'success') {
        handleClose()
        loadWalletsSettings({
          limit: '10',
          offset: offset,
          filters: window.btoa(
            JSON.stringify({ wallet_type: ['c-wallet', 'e-wallet'] })
          ),
        })
      }
    }
  }, [notifications])

  useNonInitialEffect(() => {
    if (postResponse.code !== -1) {
      showToast({
        type: 'success',
        message: t(postResponse.message),
      })
      setSSEIsLoading(true)
      customerService.getCurrentCustomer().then((currentCustomerRes) => {
        setCustomerUUID(currentCustomerRes.data.uuid)
        openConnection(currentCustomerRes.data.uuid)
      })
    }
  }, [postResponse])

  React.useEffect(() => {
    if (!isModalOpen) {
      setInput({
        active: true,
        client_id: '',
        client_secret: '',
        default: false,
        name: '',
        wallet_name: 'ame',
      })
      setInputPressed({
        ...Object.keys(input).reduce((acc, key) => {
          acc[key] = false
          return acc
        }, {}),
      })
      var iframe = document.querySelector('iframe')
      if (iframe) {
        var iframeSrc = iframe.src
        iframe.src = iframeSrc
      }
      setShowHelper(false)
    }
  }, [isModalOpen])

  const renderForm = (height: number) => {
    return (
      <>
        {showHelper ? (
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/m5lXJSdae6Y"
            title="YouTube video player ame"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          <s.FormContainer height={height}>
            {!isSpacePay() ? (
              <s.HelperWrapper>
                <s.Text>
                  Para ajudar você nesta tarefa preparamos um vídeo explicativo!
                </s.Text>
                <s.Button
                  onClick={() => {
                    setShowHelper(true)
                  }}
                  width="200px"
                >
                  Clique aqui
                </s.Button>
              </s.HelperWrapper>
            ) : (
              <></>
            )}
            <s.InputText
              label="Apelido da Credencial"
              placeholder="Ex: ”Loja Paulista”, ”loja@paulista.com”, etc."
              value={input.name}
              onChange={(e) => {
                setInput({ ...input, name: e.target.value })
                setInputPressed({ ...inputPressed, name: true })
              }}
              error={
                input.name === '' && inputPressed.name
                  ? {
                      message: 'Campo Obrigatório',
                    }
                  : null
              }
            />
            <s.InputText
              label="Application Key"
              value={input.client_id}
              onChange={(e) => {
                setInput({ ...input, client_id: e.target.value })
                setInputPressed({ ...inputPressed, client_id: true })
              }}
              error={
                input.client_id === '' && inputPressed.client_id
                  ? {
                      message: 'Campo Obrigatório',
                    }
                  : null
              }
            />
            <s.InputText
              label="Application Token"
              value={input.client_secret}
              onChange={(e) => {
                setInput({ ...input, client_secret: e.target.value })
                setInputPressed({ ...inputPressed, client_secret: true })
              }}
              error={
                input.client_secret === '' && inputPressed.client_secret
                  ? {
                      message: 'Campo Obrigatório',
                    }
                  : null
              }
            />
            <s.FooterButtonWrapper>
              <s.Button
                outline
                disabled={postIsLoading || isSSELoading}
                color="white"
                onClick={() => handleClose()}
              >
                Cancelar
              </s.Button>
              <s.Button
                color="maincolor"
                disabled={
                  input.name === '' ||
                  input.client_id === '' ||
                  input.client_secret === '' ||
                  postIsLoading ||
                  isSSELoading
                }
                onClick={() => handlePost()}
              >
                Salvar
              </s.Button>
              {postIsLoading || isSSELoading ? (
                <s.Loading type="spinner" color="maincolor" />
              ) : null}
            </s.FooterButtonWrapper>
          </s.FormContainer>
        )}
      </>
    )
  }

  return <>{renderForm(isSpacePay() ? 400 : 500)}</>
}

export default AmeConfigWallet
