import { ActionInterface, Types, ProfileState } from './types'

const INITIAL_STATE: ProfileState = {
  profile: {
    access_key: null,
    customer_name: null,
    impersonate: null,
    pos_product_name: null,
    secret_key: null,
    rede: null,
  },
  isLoading: false,
  hasError: false,
}

const profileReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): ProfileState => {
  switch (action.type) {
    case Types.GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
        isLoading: false,
        hasError: false,
      }
    case Types.GET_PROFILE_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export default profileReducer
