import { showToast } from 'components/Toast'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  baseURLBffPanelWalletSettings,
  baseURLBffPanelDestinationAccountTransactionData,
  get,
  patch,
  baseURLRegistrationSummarySplitFee,
} from 'services/api'
import * as s from './styles'
import React from 'react'
import { useNonInitialEffect } from 'hooks/utils'
import { useGetPixWS } from 'hooks/pixWS'
import pixWSService from 'services/pixWalletSettings'
import { applyCnpjMask } from 'helpers/masks'
import { formatPixKey, getPixKeyType, normalize } from 'helpers/pix'
import { SelectToggle } from './components/SelectToggle'
import SplitFeeManager from 'pages/FeeSplitterManagement/Management/components/SplitFeeManager'
import { ISplit } from 'pages/FeeSplitterManagement/types'

interface IProps {
  isOpen: boolean
  handleClose: () => any
  editSettingsInfo: { id: string; pspProvider: string }
  certList: { id: string; text: string }[]
}

const INITIAL_INFO_STATE: WallletSettingInfo = {
  destination_account: {
    cpf_cnpj: '',
    pix_dict_key: '',
    id: '',
  },
  fee_value: null,
  minimum_fee: null,
  more_details: {
    tls_certificate: '',
    wallet_setting_dict_key: '',
  },
  pix_account_details: {
    wallet_setting_name: '',
  },
  split_fee_type: '',
  wallet_icon: '',
  wallet_friendly_name: '',
  wallet_setting_transaction_type: '',
  wallet_setting_uuid: '',
}

interface WallletSettingInfo {
  destination_account: {
    cpf_cnpj: string
    pix_dict_key: string
    id: string
  }
  fee_value: number
  minimum_fee: number
  more_details: {
    tls_certificate: string | null
    wallet_setting_dict_key: string | null
  }
  pix_account_details: {
    wallet_setting_name: string
  }
  split_fee_type: string
  wallet_friendly_name: string
  wallet_icon: string
  wallet_setting_transaction_type: string
  wallet_setting_uuid: string
}

interface FirstSectionProps
  extends Pick<
    WallletSettingInfo,
    | 'fee_value'
    | 'minimum_fee'
    | 'split_fee_type'
    | 'wallet_friendly_name'
    | 'wallet_icon'
    | 'wallet_setting_transaction_type'
  > {
  pspProvider: string
}

const FirstSession: React.FC<FirstSectionProps> = ({
  pspProvider,
  fee_value,
  minimum_fee,
  split_fee_type,
  wallet_friendly_name,
  wallet_icon,
  wallet_setting_transaction_type,
}) => {
  const isPspProviderAnybank = (
    pspProvider: string,
    transactionType: string
  ) => {
    if (transactionType === 'cashout') return false
    return (
      pspProvider === 'itau_anybank' ||
      pspProvider === 'original' ||
      pspProvider === 'tbanks'
    )
  }

  const handleTransactionTypeTags = (transactionType: string | null) => {
    if (!transactionType) {
      return null
    }

    if (transactionType === 'cashin') {
      return (
        <>
          <s.Icon name="cashinarrowdown" fill="gray1" />
          <s.Text type="tag" fontWeight={400} color="graytheme6">
            cobrança
          </s.Text>
        </>
      )
    }
    if (transactionType === 'cashout') {
      return (
        <>
          <s.Icon name="cashoutarrowup" fill="gray1" />
          <s.Text type="tag" fontWeight={400} color="graytheme6">
            Envio
          </s.Text>
        </>
      )
    }
    if (transactionType === 'hybrid') {
      return (
        <s.TransactionTagWrapper>
          <s.Icon name="cashinarrowdown" fill="gray1" />
          <s.Text type="tag" fontWeight={400} color="graytheme6">
            cobrança
          </s.Text>
          <s.Text margin="0px 2px 0px 2px">+</s.Text>
          <s.Icon name="cashoutarrowup" fill="gray1" />
          <s.Text type="tag" fontWeight={400} color="graytheme6">
            envio
          </s.Text>
        </s.TransactionTagWrapper>
      )
    }
  }

  const handleAnybankTag = (
    pspProvider: string,
    transactionType: string | null
  ) => {
    if (isPspProviderAnybank(pspProvider, transactionType)) {
      return (
        <>
          <s.TextTag type="tag" fontWeight={400} color="graytheme6">
            receba em qualquer banco
          </s.TextTag>
        </>
      )
    }
    return <></>
  }

  return (
    <s.Section data-testid="first-section">
      <s.ProviderLine>
        <s.Text type="headline" color="graytheme6">
          Provedor
        </s.Text>
        <s.ProviderWrapper data-testid="provider-walletSettingInfo">
          <img width={34} src={wallet_icon} />
          <s.Text type="headline" fontWeight={400} color="graytheme6">
            {wallet_friendly_name}
          </s.Text>
        </s.ProviderWrapper>
      </s.ProviderLine>
      <s.SectionLine>
        <s.Text type="headline" color="graytheme6">
          Modalidade
        </s.Text>
        <s.TagWrapper>
          <s.TransactionTypeTag>
            {handleTransactionTypeTags(wallet_setting_transaction_type)}
          </s.TransactionTypeTag>
          <s.AnybankTag>
            {handleAnybankTag(pspProvider, wallet_setting_transaction_type)}
          </s.AnybankTag>
        </s.TagWrapper>
      </s.SectionLine>
      <s.SectionLine>
        <s.Text type="headline" color="graytheme6">
          Tarifa por transação
        </s.Text>
        <s.InputText
          width={304}
          value={
            split_fee_type == 'percentage' && fee_value
              ? String(fee_value + '%')
              : fee_value
              ? Number(fee_value).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
              : ''
          }
          disabled
        />
      </s.SectionLine>
      {split_fee_type == 'percentage' && (
        <s.SectionLine>
          <s.Align>
            <s.Text margin="0 0 8px 0" type="headline">
              Tarifa Mínima
            </s.Text>
            <s.Hover>
              <s.Icon fill="maincolor" name="questionmarkcircle" />
              <s.TooltipText>
                Caso o percentual da tarifa resulte em menos do que este valor,
                será cobrada a tarifa mínima. Sua tarifa mínima deverá ser
                superior ao Custo Plataforma Pix Itaú para continuar.
              </s.TooltipText>
            </s.Hover>
          </s.Align>
          <s.InputText
            width={304}
            value={
              minimum_fee
                ? Number(minimum_fee).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : ''
            }
            disabled
          />
        </s.SectionLine>
      )}
    </s.Section>
  )
}

interface SecondSectionProps
  extends Pick<
    WallletSettingInfo,
    'pix_account_details' | 'wallet_setting_uuid'
  > {
  currentWalletSettingInfo: WallletSettingInfo
  updateWalletSettingInfo: Dispatch<SetStateAction<WallletSettingInfo>>
}

const SecondSection: React.FC<SecondSectionProps> = ({
  pix_account_details,
  wallet_setting_uuid,
  currentWalletSettingInfo,
  updateWalletSettingInfo,
}) => {
  const [enableEdit, setEnableEdit] = useState<boolean>(false)
  const [patchIsLoading, setPatchIsLoading] = useState<boolean>(false)
  const [walletName, setWalletName] = useState<string>(
    pix_account_details.wallet_setting_name
  )

  const handleUpdate = async () => {
    setPatchIsLoading(true)
    await pixWSService
      .editPixWS(wallet_setting_uuid, { name: walletName })
      .then((response) => {
        updateWalletSettingInfo({
          ...currentWalletSettingInfo,
          pix_account_details: {
            wallet_setting_name: walletName,
          },
        })
        showToast({
          type: 'success',
          message: 'Apelido alterado com sucesso',
        })
      })
      .catch((e) => {
        setWalletName(pix_account_details.wallet_setting_name)
        showToast({
          type: 'error',
          message: 'Problema ao editar apelido',
        })
      })
      .finally(() => {
        setPatchIsLoading(false)
      })
  }

  return (
    <s.Section data-testid="second-section">
      <s.SectionHeader>
        <s.TitleWrapper>
          <s.Icon name="accountwallet" fill="gray1" />
          <s.Text type="headline" color="graytheme6">
            Detalhes da conta Pix
          </s.Text>
        </s.TitleWrapper>
        {!enableEdit ? (
          <s.Button
            onClick={() => setEnableEdit(!enableEdit)}
            width="98px"
            color="white"
            data-testid="edit-button-second-section"
          >
            <s.Icon name="pencil" fill="maincolor" />
            <s.Text color="maincolor" type="headline" fontWeight={600}>
              Editar
            </s.Text>
          </s.Button>
        ) : (
          <></>
        )}
      </s.SectionHeader>
      <s.SectionLine>
        <s.Text type="headline" color="graytheme6">
          Apelido
        </s.Text>
        <s.InputText
          width="304"
          testId="input-apelido"
          error={!walletName ? { message: 'campo obrigatório' } : false}
          value={walletName}
          onChange={(e) => setWalletName(e.target.value)}
          disabled={!enableEdit || patchIsLoading}
        />
      </s.SectionLine>
      {enableEdit ? (
        <s.SectionLine>
          <div></div>
          <s.ButtonWrapper>
            <s.CancelButton
              color="white"
              data-testid="cancel-button-second-section"
              disabled={patchIsLoading}
              onClick={() => {
                setEnableEdit(false)
                setWalletName(pix_account_details.wallet_setting_name)
              }}
            >
              <s.Text type="headline" fontWeight={600} color="maincolor">
                Cancelar
              </s.Text>
            </s.CancelButton>
            <s.Button
              data-testid="save-button-second-section"
              disabled={
                patchIsLoading ||
                pix_account_details.wallet_setting_name === walletName ||
                !walletName
              }
              onClick={() => handleUpdate()}
            >
              {patchIsLoading ? (
                <s.Loading type="spinner" color="white" />
              ) : (
                <s.Icon name="pencilUnderline" fill="white" />
              )}
              <s.Text type="headline" fontWeight={600} color="white">
                Salvar edição
              </s.Text>
            </s.Button>
          </s.ButtonWrapper>
        </s.SectionLine>
      ) : (
        <></>
      )}
    </s.Section>
  )
}

interface ThirdSectionProps
  extends Pick<WallletSettingInfo, 'destination_account'> {
  currentWalletSettingInfo: WallletSettingInfo
  updateWalletSettingInfo: Dispatch<SetStateAction<WallletSettingInfo>>
  pspProvider: string
}

interface PayloadPatchDestinationAccount {
  pix_dict_key: string
  pix_dict_key_type: 'cpf' | 'cnpj' | 'phone' | 'email' | 'random_key'
}

const ThirdSection: React.FC<ThirdSectionProps> = ({
  destination_account,
  currentWalletSettingInfo,
  updateWalletSettingInfo,
  pspProvider,
}) => {
  const [destinationAccount, setDestinationAccount] =
    useState(destination_account)

  const [onEditMode, setOnEditMode] = useState(false)
  const [patchIsLoading, setPatchIsLoading] = useState(false)

  const updatePixDictKey = async () => {
    setPatchIsLoading(true)

    const payload: PayloadPatchDestinationAccount = {
      pix_dict_key: normalize(destinationAccount.pix_dict_key),
      pix_dict_key_type: getPixKeyType(destinationAccount.pix_dict_key),
    }

    await patch(
      `${baseURLBffPanelDestinationAccountTransactionData}/${destination_account.id}`,
      payload
    )
      .then((res) => {
        showToast({
          type: 'success',
          message: 'Chave Pix alterada com sucesso!',
        })
        updateWalletSettingInfo({
          ...currentWalletSettingInfo,
          destination_account: destinationAccount,
        })
      })
      .catch((e) => {
        showToast({
          type: 'error',
          message: e.response.data.detail[0]?.msg || e.response.data?.detail,
        })
        setDestinationAccount({
          ...destination_account,
        })
      })
      .finally(() => {
        setPatchIsLoading(false)
      })
  }

  return (
    <s.Section data-testid="third-section">
      <s.SectionHeader>
        <s.TitleWrapper>
          <s.Icon name="bank" fill="gray1" />
          <s.Text type="headline" color="graytheme6">
            Conta Destino
          </s.Text>
        </s.TitleWrapper>
        {!onEditMode && pspProvider !== 'itau_anybank_v2' ? (
          <s.Button
            onClick={() => setOnEditMode(true)}
            width="98px"
            color="white"
            data-testid="edit-third-section-button"
          >
            <s.Icon name="pencil" fill="maincolor" />
            <s.Text color="maincolor" type="headline" fontWeight={600}>
              Editar
            </s.Text>
          </s.Button>
        ) : (
          <></>
        )}
      </s.SectionHeader>
      <s.SectionLine>
        <s.Text margin="0px 0px 16px 0px" type="headline" color="graytheme6">
          CNPJ
        </s.Text>
        <s.InputText
          data-testid="cnpj-input"
          width="300"
          value={applyCnpjMask(destinationAccount.cpf_cnpj)}
          disabled={true}
          onChange={() => {}}
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text margin="0px 0px 16px 0px" type="headline" color="graytheme6">
          Chave Pix
        </s.Text>
        <s.InputText
          data-testid="pix-dict-key-input"
          width="300"
          value={formatPixKey(destinationAccount.pix_dict_key)}
          error={
            !destinationAccount.pix_dict_key
              ? { message: 'campo obrigatório' }
              : false
          }
          disabled={!onEditMode || patchIsLoading}
          onChange={(e) => {
            setDestinationAccount({
              ...destinationAccount,
              pix_dict_key: formatPixKey(e.target.value),
            })
          }}
        />
      </s.SectionLine>
      {onEditMode ? (
        <s.SectionLine>
          <div></div>
          <s.ButtonWrapper>
            <s.CancelButton
              disabled={patchIsLoading}
              onClick={() => {
                setOnEditMode(false)
                setDestinationAccount(destination_account)
              }}
              width="98px"
              color="white"
            >
              <s.Text color="maincolor" type="headline" fontWeight={600}>
                Cancelar
              </s.Text>
            </s.CancelButton>

            <s.Button
              onClick={() => {
                updatePixDictKey()
              }}
              disabled={
                !destinationAccount.pix_dict_key ||
                patchIsLoading ||
                normalize(destinationAccount.pix_dict_key) ==
                  normalize(destination_account.pix_dict_key)
              }
              color="pixitau"
            >
              {patchIsLoading ? (
                <s.Loading type="spinner" color="white" />
              ) : (
                <s.Icon name="pencilUnderline" fill="white" />
              )}
              <s.Text color="white" type="headline" fontWeight={600}>
                Salvar edição
              </s.Text>
            </s.Button>
          </s.ButtonWrapper>
        </s.SectionLine>
      ) : (
        <></>
      )}
      {onEditMode && (
        <s.SectionLine>
          <s.WarningCard>
            <s.Text type="body" fontWeight={400}>
              Sua chave deve ser vinculada a uma conta de mesmo CNPJ do
              contratante.
            </s.Text>
          </s.WarningCard>
        </s.SectionLine>
      )}
    </s.Section>
  )
}

interface FourthSectionProps
  extends Pick<WallletSettingInfo, 'more_details' | 'wallet_setting_uuid'> {
  certList: Array<{ id: string; text: string }>
  currentWalletSettingInfo: WallletSettingInfo
  updateWalletSettingInfo: Dispatch<SetStateAction<WallletSettingInfo>>
}

const FourthSection: React.FC<FourthSectionProps> = ({
  more_details,
  wallet_setting_uuid,
  certList,
  currentWalletSettingInfo,
  updateWalletSettingInfo,
}) => {
  const [enableEdit, setEnableEdit] = useState<boolean>(false)
  const [walletPixDictKey, setWalletPixDictKey] = useState<string>(
    more_details.wallet_setting_dict_key
  )

  const [selectedCert, setSelectedCert] = useState({ id: '', name: '' })
  const [certSelectList, setCertSelectList] = useState([])
  const [customSplitsModal, setCustomSplitsModal] = useState(false)
  const { pixWS, loadPixWS } = useGetPixWS()
  const [splitFee, setSplitFee] = useState<ISplit | {}>({})
  const [hasSplitFee, setHasSplitFee] = useState<boolean>(false)
  const [patchIsLoading, setPatchIsLoading] = useState<boolean>(false)

  interface PatchProps {
    dict_key?: string | null
    tls_certificate?: string | null
  }

  let payload = {} as PatchProps

  useEffect(() => {
    if (certList.length > 0) {
      const _list = certList.map((x) => {
        return {
          ...x,
          onClick: () => {
            setSelectedCert({ id: x.id, name: x.text })
          },
        }
      })
      setCertSelectList(_list)
      if (more_details.tls_certificate) {
        const c = certList.find((x) => x.id === more_details.tls_certificate)
        if (c) setSelectedCert({ id: c.id, name: c.text })
      }
    }
  }, [certList])

  useEffect(() => {
    if (wallet_setting_uuid && !customSplitsModal)
      loadPixWS(wallet_setting_uuid)
  }, [wallet_setting_uuid, customSplitsModal])

  useEffect(() => {
    if (splitFee) {
      const { minimum_fee, split_fee_value } = splitFee as ISplit
      updateWalletSettingInfo({
        ...currentWalletSettingInfo,
        minimum_fee: minimum_fee,
        fee_value: split_fee_value,
      })
    }
  }, [splitFee])

  const handleUpdate = async () => {
    if (more_details.tls_certificate && more_details.wallet_setting_dict_key) {
      if (more_details.tls_certificate != selectedCert.id) {
        Object.assign(payload, { tls_certificate: selectedCert.id })
      }

      if (more_details.wallet_setting_dict_key != walletPixDictKey) {
        Object.assign(payload, { dict_key: walletPixDictKey })
      }
    }

    const handleSuccessMessage = (payload: PatchProps): string => {
      if ('dict_key' in payload && 'tls_certificate' in payload)
        return 'Chave Pix e Certificado alterados com sucesso!'
      if ('dict_key' in payload && !('tls_certificate' in payload))
        return 'Chave Pix alterada com sucesso!'
      return 'Certificado alterado com sucesso!'
    }

    setPatchIsLoading(true)
    await pixWSService
      .editPixWS(wallet_setting_uuid, payload)
      .then((response) => {
        updateWalletSettingInfo({
          ...currentWalletSettingInfo,
          more_details: {
            tls_certificate: selectedCert.id,
            wallet_setting_dict_key: walletPixDictKey,
          },
        })
        showToast({
          type: 'success',
          message: handleSuccessMessage(payload),
        })
      })
      .catch((e) => {
        resetData()
        showToast({
          type: 'error',
          message: e.response.data?.detail || e.response.data?.message,
        })
      })
      .finally(() => {
        setPatchIsLoading(false)
      })
  }

  const resetData = () => {
    setWalletPixDictKey(more_details.wallet_setting_dict_key)
    const c = certList.find((x) => x.id === more_details.tls_certificate)
    if (c) setSelectedCert({ id: c.id, name: c.text })
  }

  useNonInitialEffect(() => {
    if (pixWS.tls_certificate) {
      const c = certSelectList.find((x) => x.id === pixWS.tls_certificate)
      if (c) setSelectedCert({ id: c.id, name: c.text })
    }
    checkIfHasSplitFee()
  }, [pixWS])

  const checkIfHasSplitFee = async () => {
    await get(`${baseURLRegistrationSummarySplitFee}/${pixWS.split_fee_id}`)
      .then((res) => {
        if (res.data.split_fee_id) {
          setSplitFee(res.data)
          setHasSplitFee(true)
        } else {
          setHasSplitFee(false)
        }
      })
      .catch((e) => {
        setHasSplitFee(false)
        if (e.request.status !== 404 && e.request.status !== 403) {
          showToast({
            type: 'error',
            message: JSON.parse(e.request.response).detail,
          })
        }
      })
  }

  const customSplitModal = () => {
    return (
      <>
        {customSplitsModal ? (
          <s.ModalSplits
            handleClose={() => {
              setCustomSplitsModal(false)
            }}
            isOpen={customSplitsModal}
            modalSubtitle="Configurar Tarifa Customizada"
          >
            <s.ModalContentSplits>
              <s.Text>
                Tarifa customizada que será descontada desta conta Pix por
                transação liquidada.
              </s.Text>
              {hasSplitFee ? (
                <SplitFeeManager
                  handleClose={() => {
                    setCustomSplitsModal(false)
                  }}
                  walletSettingId={wallet_setting_uuid}
                  isDefaultSplitFee={pixWS.is_default_split_fee}
                  splitFee={splitFee as ISplit}
                  isCustomFee={true}
                />
              ) : (
                <></>
              )}
            </s.ModalContentSplits>
          </s.ModalSplits>
        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <>
      {customSplitModal()}
      <s.Section data-testid="fourth-section">
        <s.SectionHeader>
          <s.TitleWrapper>
            <s.Icon name="gear" fill="lightred1" />
            <s.Text type="headline" color="lightred1">
              Mais Detalhes
            </s.Text>
          </s.TitleWrapper>
          {!enableEdit ? (
            <s.Button
              onClick={() => setEnableEdit(!enableEdit)}
              width="98px"
              color="white"
              outline={false}
            >
              <s.Icon name="pencil" fill="maincolor" />
              <s.Text color="maincolor" type="headline" fontWeight={600}>
                Editar
              </s.Text>
            </s.Button>
          ) : (
            <></>
          )}
        </s.SectionHeader>
        <s.SectionLine>
          <s.IdContainer>
            <s.Text type="headline" color="graytheme6">
              ID
            </s.Text>
            <s.IdWrapper
              onClick={(e) => {
                e.stopPropagation()
                navigator.clipboard.writeText(wallet_setting_uuid as string)
                showToast({ type: 'success', message: 'ID Copiado!' })
              }}
            >
              {wallet_setting_uuid}
              <s.Icon
                width={24}
                height={24}
                fill="maincolor"
                name="clipboard"
              />
            </s.IdWrapper>
          </s.IdContainer>
        </s.SectionLine>
        <div></div>
        <s.SectionLine>
          <s.Text type="headline" color="graytheme6">
            Chave Pix de Passagem
          </s.Text>
          <s.InputText
            width="304"
            testId="input-dict-key"
            error={!walletPixDictKey ? { message: 'campo obrigatório' } : false}
            value={walletPixDictKey}
            onChange={(e) => setWalletPixDictKey(e.target.value)}
            disabled={!enableEdit || patchIsLoading}
          />
        </s.SectionLine>
        <s.SectionLine>
          <s.Text type="headline" color="graytheme6">
            Certificado TLS
          </s.Text>
          <SelectToggle
            options={certSelectList}
            selected={selectedCert}
            disabled={!enableEdit || patchIsLoading}
          />
        </s.SectionLine>
        <s.SectionLine>
          <s.EditFeeWraper>
            <s.Text type="headline" color="graytheme6">
              Configuração de tarifa
            </s.Text>
            <s.Button
              disabled={patchIsLoading}
              onClick={() => setCustomSplitsModal(true)}
            >
              {patchIsLoading ? (
                <s.Loading type="spinner" color="white" />
              ) : (
                <s.Icon name="pencilUnderline" fill="white" />
              )}
              <s.Text type="headline" fontWeight={600} color="white">
                Editar tarifa
              </s.Text>
            </s.Button>
          </s.EditFeeWraper>
        </s.SectionLine>
        {enableEdit ? (
          <s.SectionLine>
            <div></div>
            <s.ButtonWrapper>
              <s.CancelButton
                color="white"
                data-testid="cancel-button-second-section"
                disabled={patchIsLoading}
                onClick={() => {
                  setEnableEdit(false)
                  resetData()
                }}
              >
                <s.Text type="headline" fontWeight={600} color="maincolor">
                  Cancelar
                </s.Text>
              </s.CancelButton>
              <s.Button
                data-testid="save-button-second-section"
                disabled={
                  patchIsLoading ||
                  (selectedCert.id === more_details.tls_certificate &&
                    walletPixDictKey ===
                      more_details.wallet_setting_dict_key) ||
                  !walletPixDictKey ||
                  !selectedCert.id
                }
                onClick={() => handleUpdate()}
              >
                {patchIsLoading ? (
                  <s.Loading type="spinner" color="white" />
                ) : (
                  <s.Icon name="pencilUnderline" fill="white" />
                )}
                <s.Text type="headline" fontWeight={600} color="white">
                  Salvar edição
                </s.Text>
              </s.Button>
            </s.ButtonWrapper>
          </s.SectionLine>
        ) : (
          <></>
        )}
      </s.Section>
    </>
  )
}

export const SettingsWSFormItauAnybank: React.FC<IProps> = ({
  isOpen,
  handleClose,
  editSettingsInfo,
  certList,
}) => {
  const [walletSettingInfo, setWalletSettingInfo] =
    React.useState(INITIAL_INFO_STATE)
  const [onLoadingDataInfo, setOnLoadingDataInfo] = useState(true)
  const loadWlSettingsInfo = (id: string, isMounted: boolean) => {
    get(`${baseURLBffPanelWalletSettings}/${id}/splits`)
      .then((res) => {
        setWalletSettingInfo(res.data)
        setOnLoadingDataInfo(false)
      })
      .catch((e) => {
        showToast({
          type: 'error',
          message: e.request.response?.detail
            ? JSON.parse(e.request.response)?.detail
            : 'Erro na API, tente novamente mais tarde',
        })
        if (isMounted) {
          setOnLoadingDataInfo(false)
        }
        // handleClose()
      })
  }

  const showMoreDetails = (): boolean => {
    if (walletSettingInfo.more_details)
      return !!(
        walletSettingInfo.more_details.tls_certificate &&
        walletSettingInfo.more_details.wallet_setting_dict_key
      )
    return false
  }

  useEffect(() => {
    let isMounted = true

    loadWlSettingsInfo(editSettingsInfo.id, isMounted)
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <s.Modal
      handleClose={() => handleClose()}
      isOpen={isOpen}
      noChildrenPadding
      modalSubtitle="Configurações"
      showScroll={showMoreDetails()}
    >
      <s.ModalContentItauAnybank data-testid="settings-modal-content-itau-anybank">
        {onLoadingDataInfo ? (
          <s.Loading data-testid="on-loading" />
        ) : (
          <s.SectionsWrapper data-testid="in-content-section">
            <FirstSession
              pspProvider={editSettingsInfo.pspProvider}
              fee_value={walletSettingInfo.fee_value}
              minimum_fee={walletSettingInfo.minimum_fee}
              split_fee_type={walletSettingInfo.split_fee_type}
              wallet_friendly_name={walletSettingInfo.wallet_friendly_name}
              wallet_icon={walletSettingInfo.wallet_icon}
              wallet_setting_transaction_type={
                walletSettingInfo.wallet_setting_transaction_type
              }
            />
            <s.SectionLineDivider />
            <SecondSection
              wallet_setting_uuid={walletSettingInfo.wallet_setting_uuid}
              pix_account_details={walletSettingInfo.pix_account_details}
              currentWalletSettingInfo={walletSettingInfo}
              updateWalletSettingInfo={setWalletSettingInfo}
            />
            <s.SectionLineDivider />
            <ThirdSection
              destination_account={walletSettingInfo.destination_account}
              currentWalletSettingInfo={walletSettingInfo}
              updateWalletSettingInfo={setWalletSettingInfo}
              pspProvider={editSettingsInfo.pspProvider}
            />
            {showMoreDetails() ? (
              <>
                <s.SectionLineDivider />
                <FourthSection
                  wallet_setting_uuid={walletSettingInfo.wallet_setting_uuid}
                  more_details={walletSettingInfo.more_details}
                  currentWalletSettingInfo={walletSettingInfo}
                  updateWalletSettingInfo={setWalletSettingInfo}
                  certList={certList}
                />
              </>
            ) : null}
          </s.SectionsWrapper>
        )}
      </s.ModalContentItauAnybank>
    </s.Modal>
  )
}
