import styled from 'styled-components'
import { Container as ContainerCmp } from 'styled-bootstrap-grid'

import CardCmp from 'components/Card'
import TextCmp from 'components/Text'
import ButtonCmp from 'components/Button'
import InputTextCmp from 'components/Inputs/Text'
import SelectCmp from 'components/Inputs/Select'
import DatepickerCmp from 'components/Datepicker'
import IconCmp from 'components/IconExporter'

export const Container = styled(ContainerCmp)`
  /* /* height: 100vh; */
  max-height: calc(100vh - 100px);
  max-width: 1500px;
  position: relative;

  margin-top: ${({ theme }) => theme.spacing(3)}px;

  & > * {
    margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  }
`

export const Columns = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1.1fr;
`

export const Card = styled(CardCmp)`
  padding: 60px 80px 60px 80px;
  height: 450px;
  transition: all ease 0.5s;
  width: 900px;
  margin-bottom: 2rem;
`

export const Text = styled(TextCmp)`
  font-size: 18px;
`

export const GradientText = styled(TextCmp)`
  background: linear-gradient(
    ${({ theme }) => theme.colors.lightmaincolor4},
    ${({ theme }) => theme.colors.maincolor}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const InputText = styled(InputTextCmp)``

export const Select = styled(SelectCmp)``

export const Button = styled(ButtonCmp)``

export const DateInputWrapper = styled.div``

export const Datepicker = styled(DatepickerCmp)`
  display: inline-block;
  width: auto;
  height: 40px;
  border;
`

export const Icon = styled(IconCmp)``

export const InputWrapper = styled.div`
  margin-bottom: 20px;
`
