import React from 'react'
import { v4 as uuid } from 'uuid'

import useGetSummary from 'hooks/useGetSummary'
import { useNonInitialEffect } from 'hooks/utils'

import Toast, { showToast } from 'components/Toast'
import PanelQueryConfirmModal from '../PanelQueryConfirmModal'
import { usePanelQuery } from 'hooks/panelQuery'

import * as s from '../styles'
import useGetTransactionLogs from 'hooks/useGetTransactionLogs'
import BeautifyJson from 'components/BeautifyJson'
import { formatDateTime } from 'helpers/date'
import { useTranslation } from 'react-i18next'

interface IProps {
  isOpen: boolean
  handleClose: () => any
  onRealoadOrder: () => void
  detailsId: string
}

interface IDescriptionItemsMapping {
  description: string
  entity_type: string
  name?: string
  value: number
}

interface ISplits {
  amount: number
  entity_type: string
  name: string
  pix_dict_key: string
}

const SummaryModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  detailsId,
  onRealoadOrder,
}) => {
  const { t } = useTranslation()
  const {
    summaryDetail,
    summaryDetailIsLoading,
    summaryDetailHasError,
  } = useGetSummary()

  const [isDropdownItemsOpen, setIsDropdownItemsOpen] = React.useState<boolean>(
    false
  )
  const [
    isDropdownSplitFeesOpen,
    setIsDropdownSplitFeesOpen,
  ] = React.useState<boolean>(false)
  const [isQueryButtonDisabled, setIsQueryButtonDisabled] = React.useState(
    false
  )
  const [isPanelQueryModalOpen, setIsPanelQueryModalOpen] = React.useState(
    false
  )
  const [offset, setOffset] = React.useState('0')
  const {
    newStatus,
    isStatusChanged,
    refresh,
    patchResponse,
    isLoading,
    checkPanelQuery,
    patchPanelQuery,
    postShipayCallback,
  } = usePanelQuery()

  const abortControllerRef = React.useRef<AbortController>(
    new AbortController()
  )

  const {
    transactions,
    isLoading: transactionLogsIsLoading,
    hasError: transactionLogsHasError,
    loadTransactionLogs,
  } = useGetTransactionLogs()

  React.useEffect(() => {
    const controller = abortControllerRef.current
    if (isOpen && detailsId) {
      loadTransactionLogs(
        detailsId,
        { limit: '20', offset: offset },
        controller.signal
      )
    }
  }, [isOpen, offset])

  React.useEffect(() => {
    const controller = abortControllerRef.current
    return () => {
      controller.abort()
    }
  }, [])

  useNonInitialEffect(() => {
    if (newStatus !== 'cancelled' && isStatusChanged) {
      setIsPanelQueryModalOpen(true)
    }
  }, [newStatus, refresh, isStatusChanged])

  useNonInitialEffect(() => {
    onRealoadOrder()
    loadTransactionLogs(detailsId, { limit: '20', offset: offset })
  }, [refresh])

  useNonInitialEffect(() => {
    if (patchResponse) {
      showToast({
        type: 'success',
        message: 'Pedido atualizado com sucesso',
      })
      onRealoadOrder()
    }
  }, [patchResponse])

  const renderTransactionLogs = () => {
    if (transactionLogsIsLoading) {
      return (
        <>
          <s.Text color="maincolor" type="headline">
            Carregando...
          </s.Text>
          <s.Loading type="spinner" color="maincolor" />
        </>
      )
    }

    if (transactionLogsHasError) {
      return <></>
    }

    return (
      <>
        {transactions?.total > 0 ? (
          <s.ModalTransitionLog>
            <s.ModalTransitionLogHeader>
              <s.Text type="headline" bold color="darkmaincolor1">
                Log de Transações
              </s.Text>
              <s.ButtonWrapper>
                <s.Button
                  color="darkmaincolor2"
                  disabled={isLoading}
                  onClick={() => {
                    postShipayCallback(summaryDetail.order_id)
                  }}
                >
                  <s.Text color="white">Reenv. Callback</s.Text>
                </s.Button>
                <s.Button
                  color="darkmaincolor2"
                  disabled={isLoading}
                  onClick={() => {
                    checkPanelQuery(summaryDetail.order_id)
                  }}
                >
                  <s.Text color="white">Consultar</s.Text>
                </s.Button>
              </s.ButtonWrapper>
            </s.ModalTransitionLogHeader>
            <s.ModalTransitionWrapper>
              {transactions.data.map((transaction) => {
                return (
                  <React.Fragment key={uuid()}>
                    <s.ModalTransitionFormatter>
                      <BeautifyJson
                        header={`${JSON.stringify(
                          transaction.transaction_type
                        )} — ${JSON.stringify(transaction.created)}`}
                        json={transaction.wallet_response}
                      />
                    </s.ModalTransitionFormatter>
                  </React.Fragment>
                )
              })}
            </s.ModalTransitionWrapper>
          </s.ModalTransitionLog>
        ) : null}
      </>
    )
  }

  const renderModal = () => {
    if (summaryDetailIsLoading) {
      return (
        <s.ModalDetails>
          <s.Text color="maincolor" type="headline">
            Carregando...
          </s.Text>
          <s.Loading type="spinner" color="maincolor" />
        </s.ModalDetails>
      )
    }

    if (summaryDetailHasError) {
      return (
        <s.ModalDetails>
          <s.Text color="maincolor" type="headline">
            Erro na API, tente novamente mais tarde.
          </s.Text>
        </s.ModalDetails>
      )
    }

    const getDescriptionFeesMapper = (key: string) => {
      const descriptionMapper = {
        seller_cost: 'Tarifa Aplicada após pagamento',
        psp_service: 'Iaas',
        pos: 'Automação',
        reseller: 'Repasse Revenda',
        psp: 'Conexão',
        gateway: 'Shipay',
        platform: 'Custo de Plataforma',
      }
      return descriptionMapper[key] ? descriptionMapper[key] : key
    }

    const descriptionItemsMapping = (
      arr: ISplits
    ): Array<IDescriptionItemsMapping> => {
      if (Array.isArray(arr)) {
        const descriptionMapped = arr.map((item) => {
          return {
            entity_type: item.entity_type,
            value: item.amount,
            description: `${getDescriptionFeesMapper(item.entity_type)} (${
              item.name
            })`,
          }
        })
        return descriptionMapped
      }

      return [
        {
          ...arr,
          name: arr.entity_type,
          value: Number(arr.amount),
          description: getDescriptionFeesMapper(arr.entity_type),
        },
      ]
    }

    const total = summaryDetail?.splits_fee?.splits.reduce(
      (acc, curr) => (acc += Number(curr.amount)),
      0
    )

    const renderFeesdDescriptionByUserRole = (splits_fee: any) => {
      if (!isOpen) return <></>
      const descriptionItems = descriptionItemsMapping(splits_fee.splits)

      const typeValueObj = descriptionItems.reduce(
        (acc, curr) => ({ ...acc, [curr.entity_type]: curr.value }),
        {}
      )

      const profit = summaryDetail.splits_fee.fee_value - total

      const checkIfExists = (checker: any) => {
        return descriptionItems.some(checker)
      }

      const sellerCost = (obj: any) => obj.entity_type === 'seller_cost'
      const PSPService = (obj: any) => obj.entity_type === 'psp_service'
      const POS = (obj: any) => obj.entity_type === 'pos'
      const reseller = (obj: any) => obj.entity_type === 'reseller'
      const PSP = (obj: any) => obj.entity_type === 'psp'
      const gateway = (obj: any) => obj.entity_type === 'gateway'
      const platform = (obj: any) => obj.entity_type === 'platform'
      if (
        descriptionItems.length <= 3 &&
        checkIfExists(POS) &&
        checkIfExists(platform)
      ) {
        return (
          <>
            <s.ItemNameList>
              <s.ItemHeaderText bold color="black">
                Descrição
              </s.ItemHeaderText>
              <s.ItemNameWrapperSplitFees key={uuid()}>
                <s.Text bold color="graytheme6">
                  {'Custo de Plataforma'}
                </s.Text>
              </s.ItemNameWrapperSplitFees>
              {profit > 0 ? (
                <s.ItemNameWrapperSplitFees key={uuid()}>
                  <s.Text bold color="graytheme6">
                    {'Seu Ganho'}
                  </s.Text>
                </s.ItemNameWrapperSplitFees>
              ) : null}
              <s.DottedBorderLine />
              {descriptionItems
                .filter((item) => item.entity_type != 'platform')
                .map((item) => {
                  return (
                    <s.ItemNameWrapperSplitFees key={uuid()}>
                      <s.Text bold color="graytheme6">
                        {item.description}
                      </s.Text>
                    </s.ItemNameWrapperSplitFees>
                  )
                })}
              <s.DottedBorderLine />
            </s.ItemNameList>
            <s.ValueList>
              <s.ItemHeaderText bold color="black">
                Valor
              </s.ItemHeaderText>
              <s.ItemValueWrapperSplitFee key={uuid()}>
                <s.Text bold color="graytheme6">
                  {Number(typeValueObj['platform']).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </s.Text>
              </s.ItemValueWrapperSplitFee>
              <s.DottedBorderLine />
              {profit > 0 ? (
                <s.ItemValueWrapperSplitFee key={uuid()}>
                  <s.Text bold color="graytheme6">
                    {profit.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </s.Text>
                </s.ItemValueWrapperSplitFee>
              ) : null}

              {descriptionItems
                .filter((item) => item.entity_type != 'platform')
                .map((item) => {
                  return (
                    <s.ItemValueWrapperSplitFee key={uuid()}>
                      <s.Text bold color="graytheme6">
                        {Number(item.value).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </s.Text>
                    </s.ItemValueWrapperSplitFee>
                  )
                })}
              <s.DottedBorderLine />
            </s.ValueList>
          </>
        )
      }

      if (
        descriptionItems.length >= 4 &&
        checkIfExists(gateway) &&
        checkIfExists(PSPService) &&
        checkIfExists(POS) &&
        checkIfExists(PSP)
      ) {
        return (
          <>
            <s.ItemNameList>
              <s.ItemHeaderText bold color="black">
                Descrição
              </s.ItemHeaderText>
              <s.ItemNameWrapperSplitFees key={uuid()}>
                <s.Text bold color="graytheme6">
                  {'Conexão'}
                </s.Text>
              </s.ItemNameWrapperSplitFees>
              <s.ItemNameWrapperSplitFees key={uuid()}>
                <s.Text bold color="graytheme6">
                  {'IaaS'}
                </s.Text>
              </s.ItemNameWrapperSplitFees>
              <s.ItemNameWrapperSplitFees key={uuid()}>
                <s.Text bold color="graytheme6">
                  {'Shipay'}
                </s.Text>
              </s.ItemNameWrapperSplitFees>
              <s.DottedBorderLine />
              {descriptionItems
                .filter(
                  (item) =>
                    item.entity_type != 'psp' &&
                    item.entity_type != 'psp_service' &&
                    item.entity_type != 'gateway'
                )
                .map((item) => {
                  return (
                    <s.ItemNameWrapperSplitFees key={uuid()}>
                      <s.Text bold color="graytheme6">
                        {item.description}
                      </s.Text>
                    </s.ItemNameWrapperSplitFees>
                  )
                })}
              <s.DottedBorderLine />
            </s.ItemNameList>

            <s.ValueList>
              <s.ItemHeaderText bold color="black">
                Valor
              </s.ItemHeaderText>
              <s.ItemValueWrapperSplitFee key={uuid()}>
                <s.Text bold color="graytheme6">
                  {Number(typeValueObj['psp']).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </s.Text>
              </s.ItemValueWrapperSplitFee>
              <s.ItemValueWrapperSplitFee key={uuid()}>
                <s.Text bold color="graytheme6">
                  {Number(typeValueObj['psp_service']).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </s.Text>
              </s.ItemValueWrapperSplitFee>
              <s.ItemValueWrapperSplitFee key={uuid()}>
                <s.Text bold color="graytheme6">
                  {Number(typeValueObj['gateway']).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </s.Text>
              </s.ItemValueWrapperSplitFee>
              <s.DottedBorderLine />
              {descriptionItems
                .filter(
                  (item) =>
                    item.entity_type != 'psp' &&
                    item.entity_type != 'psp_service' &&
                    item.entity_type != 'gateway'
                )
                .map((item) => {
                  return (
                    <s.ItemValueWrapperSplitFee key={uuid()}>
                      <s.Text bold color="graytheme6">
                        {Number(item.value).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </s.Text>
                    </s.ItemValueWrapperSplitFee>
                  )
                })}
              <s.DottedBorderLine />
            </s.ValueList>
          </>
        )
      }

      if (descriptionItems.length <= 2 && checkIfExists(sellerCost)) {
        return (
          <>
            <s.ItemNameList>
              <s.ItemHeaderText bold color="black">
                Descrição
              </s.ItemHeaderText>
              <s.ItemNameWrapperSplitFees key={uuid()}>
                <s.Text bold color="graytheme6">
                  {'Tarifa aplicada após pagamento'}
                </s.Text>
              </s.ItemNameWrapperSplitFees>
            </s.ItemNameList>

            <s.ValueList>
              <s.ItemHeaderText bold color="black">
                Valor
              </s.ItemHeaderText>
              <s.ItemValueWrapperSplitFee key={uuid()}>
                <s.Text bold color="graytheme6">
                  {Number(typeValueObj['seller_cost']).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </s.Text>
              </s.ItemValueWrapperSplitFee>
            </s.ValueList>
          </>
        )
      }

      return <></>
    }

    return (
      <>
        <s.ModalDetails>
          <s.ModalDetailsLabel>
            <s.Text bold color="graytheme6">
              Status
            </s.Text>
            <s.Text bold color="graytheme6">
              Data de atualização
            </s.Text>
            <s.Text bold color="graytheme6">
              Conta Digital
            </s.Text>
            <s.Text bold color="graytheme6">
              ID externo do pedido
            </s.Text>
            <s.Text bold color="graytheme6">
              Valor Total
            </s.Text>
            <s.Text bold color="graytheme6">
              Data de criação
            </s.Text>
            <s.Text bold color="graytheme6">
              ID do pedido
            </s.Text>
            <s.Text bold color="graytheme6">
              Valor Pago
            </s.Text>
            <s.Text bold color="graytheme6">
              Data do pagamento
            </s.Text>
            <s.Text bold color="graytheme6">
              ID do pagamento
            </s.Text>
            {summaryDetail.balance ? (
              <s.Text bold color="graytheme6">
                Saldo do pedido
              </s.Text>
            ) : null}
          </s.ModalDetailsLabel>
          <s.ModalDetailsValue>
            <s.Text color="graytheme6">{t(summaryDetail.status)}</s.Text>
            <s.Text color="graytheme6">
              {formatDateTime(summaryDetail.updated_at)}
            </s.Text>
            <s.Text color="graytheme6">
              {summaryDetail.wallet} - {summaryDetail.pix_psp}
            </s.Text>
            <s.Text color="graytheme6">{summaryDetail.external_id}</s.Text>
            <s.Text color="graytheme6">{summaryDetail.total_order}</s.Text>
            <s.Text color="graytheme6">
              {formatDateTime(summaryDetail.created_at)}
            </s.Text>
            <s.Text color="graytheme6">{summaryDetail.order_id}</s.Text>
            <s.Text color="graytheme6">{summaryDetail.paid_amount}</s.Text>
            <s.Text color="graytheme6">{summaryDetail.payment_date}</s.Text>
            <s.Text color="graytheme6">
              {summaryDetail.wallet_payment_id ?? <>&nbsp;</>}
            </s.Text>
            {summaryDetail.balance ? (
              <s.Text color="graytheme6">{summaryDetail.balance}</s.Text>
            ) : null}
          </s.ModalDetailsValue>
        </s.ModalDetails>
        <s.DottedLine />
        <s.ModalItemsDropdown
          isDropdownOpen={isDropdownItemsOpen}
          onClick={() => setIsDropdownItemsOpen(!isDropdownItemsOpen)}
        >
          <s.Text type="headline" bold color="maincolor">
            Itens do Pedido
          </s.Text>
          <s.Icon name="arrowdown" fill="maincolor" />
        </s.ModalItemsDropdown>
        {isDropdownItemsOpen ? (
          <s.ListItems isDropdownOpen={isDropdownItemsOpen}>
            <s.QuantityList>
              <s.ItemHeaderText bold color="graytheme5">
                Quant.
              </s.ItemHeaderText>
              {summaryDetail.items.map((item) => {
                return (
                  <s.ItemQuantityWrapper key={uuid}>
                    <s.Text bold color="graytheme6">
                      {item.quantity}
                    </s.Text>
                  </s.ItemQuantityWrapper>
                )
              })}
            </s.QuantityList>
            <s.ItemNameList>
              <s.ItemHeaderText bold color="graytheme5">
                Nome do item
              </s.ItemHeaderText>
              {summaryDetail.items.map((item) => {
                return (
                  <s.ItemNameWrapper key={uuid()}>
                    <s.Text bold color="graytheme6">
                      {item.name}
                    </s.Text>
                  </s.ItemNameWrapper>
                )
              })}
            </s.ItemNameList>
            <s.ValueList>
              <s.ItemHeaderText bold color="graytheme5">
                Valor unitário
              </s.ItemHeaderText>
              {summaryDetail.items.map((item) => {
                return (
                  <s.ItemValueWrapper key={uuid()}>
                    <s.Text bold color="graytheme6">
                      {item.unit_price.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </s.Text>
                  </s.ItemValueWrapper>
                )
              })}
            </s.ValueList>
          </s.ListItems>
        ) : null}
        <s.DottedLine />

        {summaryDetail?.splits_fee &&
        summaryDetail.pix_psp === 'itau_anybank' ? (
          <>
            <s.ModalItemsDropdown
              isDropdownOpen={isDropdownSplitFeesOpen}
              onClick={() =>
                setIsDropdownSplitFeesOpen(!isDropdownSplitFeesOpen)
              }
            >
              <s.Text type="headline" bold color="maincolor">
                Tarifas do Pedido
              </s.Text>
              <s.Icon name="arrowdown" fill="maincolor" />
            </s.ModalItemsDropdown>
            {isDropdownSplitFeesOpen ? (
              <>
                <s.ListItems isDropdownOpen={isDropdownSplitFeesOpen}>
                  {renderFeesdDescriptionByUserRole(summaryDetail?.splits_fee)}
                </s.ListItems>

                {Array.isArray(summaryDetail.splits_fee.splits) ? (
                  <s.TotalWrapper>
                    <s.ItemHeaderText bold color="black">
                      Total
                    </s.ItemHeaderText>
                    <s.ValueList>
                      <s.Text bold margin="0px 6px 0px 0px" color="black">
                        {summaryDetail?.splits_fee.split_fee_type ===
                        'percentage'
                          ? Number(total).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })
                          : Number(
                              summaryDetail?.splits_fee.fee_value
                            ).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                      </s.Text>
                    </s.ValueList>
                  </s.TotalWrapper>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}
        <s.DottedLine />
        {renderTransactionLogs()}
        {transactions.total > 0 ? (
          <s.ModalPagination
            count={20}
            offset={offset}
            total={transactions.total}
            handleChange={(offset) => {
              setOffset(offset)
            }}
          />
        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <>
      <PanelQueryConfirmModal
        isOpen={isPanelQueryModalOpen}
        newStatus={newStatus}
        handleClose={(res) => {
          if (res) {
            patchPanelQuery(summaryDetail.order_id)
          }
          setIsPanelQueryModalOpen(false)
        }}
      />
      <s.Modal
        noChildrenPadding
        modalSubtitle="Detalhes do Pedido"
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <s.ModalContainer width={632} height={600}>
          {renderModal()}
        </s.ModalContainer>
      </s.Modal>
    </>
  )
}

export default SummaryModal
