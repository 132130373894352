import styled from 'styled-components'

import ModalCmp from 'components/Modal'
import LoadingCmp from 'components/Loading'
import TextCmp from 'components/Text'
import SelectCmp from 'components/Inputs/Select'
import InputTextCmp from 'components/Inputs/Text'
import IconExporterCmp from 'components/IconExporter'
import ButtonCmp from 'components/Button'
import ButtonLinkCmp from 'components/Button/ButtonLink'
import SearchSelectCmp from 'components/Inputs/SearchSelect'
import InputTextAreaCmp from 'components/Inputs/Text/Area'

export const Modal = styled(ModalCmp)``

export const Loading = styled(LoadingCmp)`
  margin-left: 16px;
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 500px;
`

export const Text = styled(TextCmp)``

export const FormContainer = styled.div<{ height?: number }>`
  width: 500px;
  height: ${({ height }) => (height ? `${height}px` : '500px')};

  > div {
    margin-bottom: 24px;
  }
`

export const PathContainer = styled.div`
  width: 500px;
  height: 150px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > * {
    margin-bottom: 24px;
  }
`

export const Select = styled(SelectCmp)``

export const InputText = styled(InputTextCmp)``

export const InputTextArea = styled(InputTextAreaCmp)``

// export const InputTextArea = styled.textarea`
//   width: 500px;
//   border: 2px solid ${({ theme }) => theme.colors.maincolor};
//   border-radius: 4px;
//   height: 100px;
//   margin-bottom: 32px;
// `

export const InputGroup = styled.div`
  margin-bottom: 30px;
`

export const EditButtonWrapper = styled.div`
  justify-content: center;
  display: flex;
  :hover {
    cursor: pointer;
  }
`

export const Icon = styled(IconExporterCmp)``

export const Button = styled(ButtonCmp)``

export const FooterButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;

  > button {
    :first-child {
      margin-right: 16px;
    }
  }
`

export const ButtonLink = styled(ButtonLinkCmp)`
  text-decoration: underline;
  text-align: center;
`

export const HelperWrapper = styled.div`
  display: flex;
  width: 500px;
  height: 80px;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 16px;
  border-radius: 8px;
  margin-bottom: 32px;
  border: 1px solid ${({ theme }) => theme.colors.graytheme3};
  > button {
    margin-left: 56px;
  }
`

export const VideoContainer = styled.div`
  width: 500px;
  height: 400px;
`

export const SearchSelect = styled(SearchSelectCmp)``

export const SearchSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const IdWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75%;
`

export const IPNContainer = styled.div`
  color: ${({ theme }) => theme.colors.graytheme6};
  padding: ${({ theme }) => theme.spacing(1)}px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.lightmaincolor3};
  display: flex;
  align-items: center;
  width: 450px;
  justify-content: space-between;
  margin-top: 8px;

  :hover {
    background-color: ${({ theme }) => theme.colors.lightmaincolor2};
    color: ${({ theme }) => theme.colors.maincolor};
    cursor: pointer;
  }

  :active {
    background-color: ${({ theme }) => theme.colors.lightmaincolor1};
  }
`

export const List = styled.ul`
  margin: 20px 0px;
`

export const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 20px;

  > {
    :nth-child(n) {
      margin-right: 5px;
    }
  }
  :last-child {
    margin-bottom: 0px;
  }
`

export const MessageContainer = styled.div`
  height: 230px;
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    margin-top: 21px;
  }
`
