import React from 'react'

import * as s from './styles'
import { ThemeColorNames } from 'components/IconExporter'
import type { MessageBoxOutlineProps } from './styles'

export interface MessageBoxProps extends React.PropsWithChildren<MessageBoxOutlineProps> {
  level: 'error' | 'warning' | 'info' | 'success'
  marginBottom?: string
  marginTop?: string
  title?: React.ReactNode
}

interface MessageBoxColorScheme {
  color?: ThemeColorNames
  bgColor?: ThemeColorNames
  borderColor?: ThemeColorNames
  headerColor?: ThemeColorNames
}

const colorMapping: Record<MessageBoxProps['level'], MessageBoxColorScheme> = {
  error: {
    color: 'redshipay',
    bgColor: 'redshipay',
    headerColor: 'redshipay',
  },
  warning: {
    color: 'yellow1',
    bgColor: 'yellow1',
    headerColor: 'yellow1',
  },
  info: {
    color: 'gray1',
    headerColor: 'gray1',
    borderColor: 'gray1'
  },
  success: {
    color: 'green1',
    bgColor: 'green1',
    headerColor: 'green1',
  },
}


export const MessageBox: React.FC<MessageBoxProps> = ({
  level,
  marginBottom = '0',
  marginTop = '0',
  title,
  children
}) => {
  const color = React.useMemo(() => {
    return colorMapping[level]
  }, [level])
  return (<s.MessageBoxOutline
    borderColor={color.borderColor}
    bgColor={color.bgColor}
    headerColor={color.headerColor}
    color={color.color}
    margin={`${marginTop} 0 ${marginBottom} 0`}
  >
    <s.MessageBoxOutlineTitle>{title}</s.MessageBoxOutlineTitle>
    <s.MessageBoxOutlineContent>{children}</s.MessageBoxOutlineContent>
  </s.MessageBoxOutline>)
}
