import React from 'react';

import * as s from "./styles";

const PosProduct: React.FC = () => {
  return (
    <s.Container>
      Softwares de PDV
    </s.Container>
  )
}

export default PosProduct;