import styled from 'styled-components'
import IconCmp from 'components/IconExporter'
import TextCmp from 'components/Text'

export const Icon = styled(IconCmp)`
  padding-left: 3px;
`

export const Image = styled.img`
  max-width: 158px;
  max-height: 30px;
`

export const LogoWrapper = styled.div`
  width: 258px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const NavbarContainer = styled.div`
  background: rgba(255, 255, 255, 0.75);
  transition: width 0.8s;
  height: 100px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
`

export const ShipayLogo = styled.div`
  > svg {
    margin-left: 8px;
  }
`
export const PoweredWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const Text = styled(TextCmp)`
  font-size: 12px;
`
