import { Types, PayloadInterface } from './types'

export function GetCustomers(
  payload: PayloadInterface
): {
  type: string
  payload: PayloadInterface
} {
  return {
    type: Types.GET_CUSTOMERS,
    payload,
  }
}

export function GetCustomersIsLoading(): {
  type: string
} {
  return {
    type: Types.GET_CUSTOMERS_LOADING,
  }
}

export function GetCustomersError(): {
  type: string
} {
  return {
    type: Types.GET_CUSTOMERS_ERROR,
  }
}
