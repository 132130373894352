import { Types, PayloadInterface } from './types'

export function GetStores(
  payload: PayloadInterface
): {
  type: string
  payload: PayloadInterface
} {
  return {
    type: Types.GET_STORES,
    payload,
  }
}

export function GetStoreIsLoading(): {
  type: string
} {
  return {
    type: Types.GET_STORES_LOADING,
  }
}

export function GetStoresError(): {
  type: string
} {
  return {
    type: Types.GET_STORES_ERROR,
  }
}
