import React from 'react'
import FirstRegistration from 'pages/FeeSplitterManagement/FirstRegistration'
import { baseURLRegistrationSummarySplitFee, get } from 'services/api'
import Toast, { showToast } from 'components/Toast'
import Management from './Management'

import * as s from './styles'
import { ISplit } from './types'

const FeeSplitterManagement: React.FC = () => {
  const [hasSplitFee, setHasSplitFee] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [splitFee, setSplitFee] = React.useState<ISplit | {}>({})
  const checkIfHasSplitFee = async () => {
    await get(baseURLRegistrationSummarySplitFee)
      .then((res) => {
        if (res.data.split_fee_id) {
          setSplitFee(res.data)
          setHasSplitFee(true)
        } else {
          setHasSplitFee(false)
        }
      })
      .catch((e) => {
        if (e.request.status !== 404) {
          showToast({
            type: 'error',
            message: JSON.parse(e.request.response).detail,
          })
        } else if (e.request.status === 404) {
          setHasSplitFee(false)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  React.useEffect(() => {
    checkIfHasSplitFee()
  }, [])

  const renderManager = () => {
    if (hasSplitFee) {
      return <Management splitFee={splitFee as ISplit} />
    }
    if (hasSplitFee === false && !isLoading) {
      return (
        <FirstRegistration
          setHasSplitFee={() => {
            checkIfHasSplitFee()
          }}
        />
      )
    }
    if (isLoading) {
      return (
        <s.Container>
          <s.WrapperText>
            <s.Text bold type="heading3" color="maincolor">
              Carregando...
            </s.Text>
            <s.Loading type="spinner" />
          </s.WrapperText>
        </s.Container>
      )
    }
    return (
      <s.Container>
        <s.WrapperText>
          <s.Text bold type="heading3" color="maincolor">
            Erro no servidor, tente novamente mais tarde.
          </s.Text>
        </s.WrapperText>
      </s.Container>
    )
  }

  return (
    <>
      <Toast />
      {renderManager()}
    </>
  )
}

export default FeeSplitterManagement
