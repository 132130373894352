import React from 'react'
import { useDispatch } from 'react-redux'

import { ResetStore } from 'store/modules/resetStore/actions'

interface IUseResetApp {
  resetStore: () => void
}

const useResetStore = (): IUseResetApp => {
  const dispatch = useDispatch()

  const resetStore = React.useCallback((): void => {
    dispatch(ResetStore())
  }, [dispatch])

  return { resetStore }
}

export default useResetStore
