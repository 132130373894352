import { showCustomErrorToast } from 'components/Toast'
import { errorObjectHandler } from 'helpers'
import { formatMoney } from 'helpers/masks'
import useDebounce from 'hooks/useDebounce'
import React from 'react'
import { baseURLRegistrationV1SplitFeeSimulator, post } from 'services/api'

import * as s from './styles'

interface IProps {
  platformCost: number
  minimumFee: number
  feeValue: number
}

const FeePercentageSimulator: React.FC<IProps> = ({
  platformCost,
  minimumFee,
  feeValue,
}) => {
  const [averageTicket, setAverageTicket] = React.useState(0)
  const [displayAverageTicket, setDisplayAverageTicket] = React.useState('')
  const debouncedAverageTicket = useDebounce(setAverageTicket, 500)
  const [overpricing, setOverpricing] = React.useState('')

  const calculateComission = async () => {
    await post(`${baseURLRegistrationV1SplitFeeSimulator}`, {
      amount: averageTicket,
      percentage_pos_commission: feeValue,
      minimum_fee: minimumFee,
      platform_cost: platformCost,
    })
      .then((res) => {
        setOverpricing(formatMoney(res.data.pos_commission.toFixed(2)))
      })
      .catch((e) => {
        showCustomErrorToast(errorObjectHandler(e.request.response))
        setOverpricing('-')
      })
  }

  React.useEffect(() => {
    if (minimumFee > 0 && feeValue > 0 && averageTicket > 0) {
      calculateComission()
    }
  }, [minimumFee, feeValue, averageTicket])

  return (
    <s.DestinationAccountCardWrapper>
      <s.DestinationAccountCard width={508} height={400}>
        <s.WrapperText>
          <s.Icon fill="maincolor" name="settings" />
          <s.Text bold color="maincolor" type="headline">
            Simulação da Tarifa
          </s.Text>
        </s.WrapperText>
        <s.InputText
          label="Ticket Médio"
          value={displayAverageTicket}
          onChange={(e) => {
            setDisplayAverageTicket(formatMoney(e.target.value))
            debouncedAverageTicket(
              parseFloat(
                formatMoney(e.target.value)
                  .replace(/[R$.]/g, '')
                  .trim()
                  .replace(',', '.')
              )
            )
          }}
        />
        <s.InputText
          label="Custo Plataforma Pix Itaú"
          value={formatMoney(platformCost.toFixed(2))}
          disabled
          onChange={(e) => {}}
        />
        <s.InputText
          label="Overpricing"
          value={overpricing}
          disabled
          onChange={(e) => {}}
        />
      </s.DestinationAccountCard>
    </s.DestinationAccountCardWrapper>
  )
}

export default FeePercentageSimulator
