import styled from 'styled-components'

export const SwitchContainer = styled.div`
  position: relative;
`

interface ICheckBoxLabel {
  disabled: boolean
}

export const CheckBoxLabel = styled.label<ICheckBoxLabel>`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`

export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 64px;
  height: 32px;
  &:checked + ${CheckBoxLabel} {
    background:  ${({ theme }) => theme.colors.maincolor};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-left: 19px;
      transition: 0.2s;
    }
  }
`
