import { formatMoney, formatPercentage } from 'helpers/masks'
import React from 'react'
import { ISplit } from '../../types'
import useDebounce from 'hooks/useDebounce'
import paitausuccess from 'assets/images/pa-itau-success2.png'

import { showToast } from 'components/Toast'
import { baseURLRegistrationV1SplitFee, post, remove } from 'services/api'

import * as s from '../../styles'

const InfoBox: React.FC<{
  error: { title: string; message: React.ReactElement }
}> = ({ error }) => {
  return (
    <s.InfoBox error>
      <s.Text bold type="paragraph" color="redshipay">
        {error.title}
      </s.Text>
      <s.Text type="paragraph" color="cancelledStatusTextColor">
        {error.message}
      </s.Text>
    </s.InfoBox>
  )
}

const SuccessMessage: React.FC<{ type: string; handleClose: () => void }> = ({
  type,
  handleClose,
}) => {
  const successMessage = {
    post: {
      title: 'Tarifa atualizada com sucesso!',
      description: 'A tarifa do lojista foi customizada com sucesso!',
    },
    remove: {
      title: 'Tarifa redefinida com sucesso!',
      description: 'A tarifa padrão foi restaurada com sucesso!',
    },
  }

  return (
    <s.SucessWrapper>
      <s.Text color="graytheme6" bold type="heading4">
        {successMessage[type].title}
      </s.Text>
      <s.Text margin="32px 0 0 0" color="graytheme6" type="headline">
        {successMessage[type].description}
      </s.Text>
      <s.ImageWrapper>
        <img src={paitausuccess} />
      </s.ImageWrapper>
      <s.PreviousNextButtonContainer>
        <s.Button
          data-testid="verify-documents-button"
          width="100%"
          height="42px"
          color="whitedetailed"
          onClick={() => {
            handleClose()
          }}
        >
          <s.Text
            margin="0 8px 0 0"
            fontWeight={600}
            color="graytheme6"
            type="headline"
          >
            Fechar
          </s.Text>
        </s.Button>
      </s.PreviousNextButtonContainer>
    </s.SucessWrapper>
  )
}

const LoadingMessage: React.FC = () => {
  return (
    <s.LoadingWrapper>
      <s.Text color="graytheme6" bold type="heading4">
        Editando tarifa...
      </s.Text>
      <s.Text margin="32px 0 32px 0" color="graytheme6" type="headline">
        Deve levar alguns segundos. <br /> Por favor, permaneça nessa página.
      </s.Text>
      <s.Loading type="bigspinner" width={120} />
      <s.PreviousNextButtonContainer>
        <div style={{ height: '74px' }} />
        <div style={{ height: '74px' }} />
      </s.PreviousNextButtonContainer>
    </s.LoadingWrapper>
  )
}

const SplitFeeManager: React.FC<{
  splitFee: ISplit
  isCustomFee: boolean
  walletSettingId?: string
  isDefaultSplitFee?: boolean
  handleClose?: () => void
}> = ({
  splitFee,
  isCustomFee,
  walletSettingId,
  isDefaultSplitFee,
  handleClose,
}) => {
  const [restoreDefaultConfirmModal, setRestoreDefaultConfirmModal] =
    React.useState<boolean>(false)
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false)
  const [enableEdit, setEnableEdit] = React.useState<boolean>(isCustomFee)
  const [splitFeeCopy, setSplitFeeCopy] = React.useState({ ...splitFee })
  const debouncedSplitFeeValue = useDebounce(setSplitFeeCopy, 100)
  const [displaySplitFeeValue, setDisplayFeeValue] = React.useState('0')
  const [postIsLoading, setPostIsLoading] = React.useState<boolean>(false)
  const [postSuccess, setPostSuccess] = React.useState<boolean>(false)
  const [removeIsLoading, setRemoveIsLoading] = React.useState<boolean>(false)
  const [removeSuccess, setRemoveSuccess] = React.useState<boolean>(false)
  const [formError, setFormError] = React.useState({ split_fee_value: '' })
  const [infoError, setInfoError] = React.useState({
    title: '',
    message: <></>,
  })

  React.useEffect(() => {
    setSplitFeeCopy(splitFee)
  }, [splitFee])

  const restoreDefaultFee = async () => {
    setRemoveIsLoading(true)
    setRestoreDefaultConfirmModal(false)
    remove(`${baseURLRegistrationV1SplitFee}/${walletSettingId}`)
      .then((response) => {
        setRemoveSuccess(true)
      })
      .catch((e) => {
        setInfoError({
          title: 'Não foi possível realizar alteração.',
          message: (
            <>{e.response?.data?.detail || 'Tente novamente mais tarde.'}</>
          ),
        })
      })
      .finally(() => {
        setRemoveIsLoading(false)
      })
  }

  const restoreDefaultFeeConfirmationModal = () => {
    return (
      <s.ConfirmationModal
        header={<></>}
        handleClose={() => setRestoreDefaultConfirmModal(false)}
        isOpen={restoreDefaultConfirmModal}
      >
        <s.ConfirmationModalContent>
          Deseja restaurar e aplicar os valores e splits da Tarifa Padrão para
          esta conta Pix? Valores customizados vigentes serão substituídos pela
          Tarifa Padrão.
        </s.ConfirmationModalContent>
        <s.ButtonGroup>
          <s.Button
            color="white"
            onClick={() => {
              setRestoreDefaultConfirmModal(false)
            }}
          >
            <s.Text color="maincolor" bold>
              Cancelar
            </s.Text>
          </s.Button>
          <s.Button
            data-testid="confirm-restore-button"
            onClick={() => {
              restoreDefaultFee()
            }}
          >
            <s.Text color="white" bold>
              Confirmar
            </s.Text>
          </s.Button>
        </s.ButtonGroup>
      </s.ConfirmationModal>
    )
  }

  const saveSplitChanges = async () => {
    setPostIsLoading(true)
    if (!splitFeeCopy.split_fee_value) {
      setFormError({ split_fee_value: ' ' })
      setInfoError({
        title: 'Não foi possível realizar a alteração.',
        message: <>{`Sua tarifa deverá ser válida para concluir a mudança.`}</>,
      })
      setPostIsLoading(false)
    } else {
      const payload: any = {
        total_split_fee_value: splitFeeCopy.split_fee_value,
        split_fee_type: splitFeeCopy.split_fee_type,
      }

      let url
      if (isCustomFee && isDefaultSplitFee) {
        url = `${baseURLRegistrationV1SplitFee}`
        Object.assign(payload, {
          is_custom: true,
          wallet_setting_id: walletSettingId,
        })
      } else {
        url = `${baseURLRegistrationV1SplitFee}/${splitFeeCopy.split_fee_id}`
      }

      await post(url, payload)
        .then((res) => {
          setPostSuccess(true)
          setSplitFeeCopy({
            ...splitFeeCopy,
            split_fee_id: res.data.split_fee_id,
          })
          if (!isCustomFee) {
            setEnableEdit(false)
          }
        })
        .catch((e) => {
          setInfoError({
            title: 'Não foi possível realizar alteração',
            message: (
              <>
                {JSON.parse(e.request?.response)?.detail ||
                  'Tente novamente mais tarde'}
              </>
            ),
          })
        })
        .finally(() => {
          setPostIsLoading(false)
        })
    }
  }

  React.useEffect(() => {
    if (splitFeeCopy.split_fee_type === 'fixed') {
      setDisplayFeeValue(formatMoney(splitFee?.split_fee_value?.toFixed(2)))
    } else {
      setDisplayFeeValue(formatPercentage(splitFee?.split_fee_value.toFixed(2)))
    }
  }, [splitFee])

  if (postIsLoading || removeIsLoading) {
    return <LoadingMessage />
  }

  if (postSuccess || removeSuccess) {
    return (
      <SuccessMessage
        type={removeSuccess ? 'remove' : 'post'}
        handleClose={handleClose}
      />
    )
  }

  return (
    <>
      {infoError.title ? <InfoBox error={infoError} /> : <></>}
      <s.WrapperText>
        <s.Icon name="payments" fill="gray1" />
        <s.Text type="headline" color="graytheme6" fontWeight={600}>
          Tarifa customizada
        </s.Text>
      </s.WrapperText>
      <s.Text margin="32px 0 0 0">
        Tarifa customizada que será descontada desta conta Pix por transação
        liquidada.
      </s.Text>
      <s.Section>
        <s.SectionLine>
          <s.Text
            fontWeight={600}
            type="headline"
            color={formError.split_fee_value ? 'redshipay' : 'graytheme6'}
          >
            Tarifa
          </s.Text>
          <s.FeeInputText
            width={250}
            testId="split-fee-value-input"
            value={displaySplitFeeValue}
            disabled={!enableEdit || postIsLoading}
            onChange={(e) => {
              setDisplayFeeValue(formatMoney(e.target.value))
              debouncedSplitFeeValue({
                ...splitFeeCopy,
                split_fee_value: parseFloat(
                  formatMoney(e.target.value)
                    .replace(/[R$.]/g, '')
                    .trim()
                    .replace(',', '.')
                ),
              })
            }}
            error={
              formError.split_fee_value
                ? { message: formError.split_fee_value }
                : false
            }
            suffix={
              formError.split_fee_value ? (
                <s.Icon name="alertcirclefilled" fill="lightred2" />
              ) : (
                <></>
              )
            }
          />
        </s.SectionLine>
      </s.Section>
      {isCustomFee ? (
        <s.PreviousNextButtonContainer>
          {!isDefaultSplitFee ? (
            <s.Button
              color="whitedetailed"
              onClick={() => {
                setRestoreDefaultConfirmModal(true)
              }}
              data-testid="restore-default-button"
            >
              <s.Text color="graytheme6" fontWeight={600}>
                Restaurar Tarifa Padrão
              </s.Text>
            </s.Button>
          ) : (
            <></>
          )}
          <s.Button
            onClick={() => {
              if (splitFeeCopy.split_fee_value !== splitFee.split_fee_value) {
                saveSplitChanges()
              }
            }}
            data-testid="save-changes-button"
          >
            <s.Text color="white" fontWeight={600}>
              Salvar
            </s.Text>
          </s.Button>
        </s.PreviousNextButtonContainer>
      ) : (
        <></>
      )}
      {restoreDefaultFeeConfirmationModal()}
    </>
  )
}

export default SplitFeeManager
